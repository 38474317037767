import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerModal = styled.div`
  padding: 40px;
`;

export const StyledGrid = styled(Grid)`
  height: 83px;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : 0)};

  input {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const ActionsBar = styled(Grid)`
  margin-top: 16px;

  button {
    padding: 0 20px;
    font-weight: 700;
    font-size: 14px;
  }
  button:nth-child(1) {
    opacity: 0.5;
  }
`;

export const StyledImg = styled.img`
  width: 90%;
`;
