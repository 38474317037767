import {
  addDays,
  differenceInMinutes,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  set,
} from 'date-fns';
import { isEqual } from 'lodash';

const calculateDifference = (start, end, mainBreak, daysWorkedOnWeek) => {
  const absoluteTimeDifferenceInMinutes = Math.abs(
    differenceInMinutes(start, end),
  );

  let timeDifferenceExcludingMainBreak =
    absoluteTimeDifferenceInMinutes - Number(mainBreak);

  if (daysWorkedOnWeek) {
    timeDifferenceExcludingMainBreak *= daysWorkedOnWeek;
  }

  if (timeDifferenceExcludingMainBreak < 0) {
    timeDifferenceExcludingMainBreak = 0;
  }

  const hours = Math.floor(timeDifferenceExcludingMainBreak / 60);
  const minutes = timeDifferenceExcludingMainBreak % 60;

  return { hours, minutes };
};

export const calculateTimeDifference = (
  start,
  end,
  mainBreak,
  daysWorkedOnWeek,
) => {
  if (isValid(start) && isValid(end)) {
    let endDay = end;
    if (
      set(end, { seconds: 0, milliseconds: 0 }).getTime() <
      set(start, { seconds: 0, milliseconds: 0 }).getTime()
    ) {
      endDay = addDays(end, 1);
    }

    const { hours, minutes } = calculateDifference(
      new Date(endDay).setSeconds(0, 0),
      new Date(start).setSeconds(0, 0),
      mainBreak,
      daysWorkedOnWeek,
    );
    return {
      hours,
      minutes,
    };
  }
  return { hours: 0, minutes: 0 };
};

export const calculateNightAdditional = (
  start,
  end,
  mainBreak,
  daysWorkedOnWeek,
) => {
  let totalNightAdditionalHours = 0;
  let totalNightAdditionalMinutes = 0;
  let endDay = end;
  if (
    set(end, { seconds: 0, milliseconds: 0 }).getTime() <
    set(start, { seconds: 0, milliseconds: 0 }).getTime()
  ) {
    endDay = addDays(end, 1);
  }

  const nextDay = addDays(start, 1);
  const sameDay = isSameDay(start, endDay);
  const startAfter5AndBefore22 =
    isBefore(
      set(start, { seconds: 0, milliseconds: 0 }),
      set(start, { hours: 22, minutes: 1, seconds: 0, milliseconds: 0 }),
    ) &&
    isAfter(
      set(start, { seconds: 0, milliseconds: 0 }),
      set(start, { hours: 4, minutes: 59, seconds: 0, milliseconds: 0 }),
    );
  const exitAfter22AndBefore5OfNextDay =
    isAfter(
      set(endDay, { seconds: 0, milliseconds: 0 }),
      set(start, { hours: 22, minutes: 0, seconds: 0, milliseconds: 0 }),
    ) &&
    isBefore(
      set(endDay, { seconds: 0, milliseconds: 0 }),
      set(endDay, { hours: 5, minutes: 1, seconds: 0, milliseconds: 0 }),
    );
  const exitAfter5OfNextDay = isAfter(
    set(endDay, { seconds: 0, milliseconds: 0 }),
    set(nextDay, { hours: 5, minutes: 0, seconds: 0, milliseconds: 0 }),
  );

  const startBetween22And5 =
    isAfter(
      set(start, { seconds: 0, milliseconds: 0 }),
      set(start, { hours: 21, minutes: 59, seconds: 0, milliseconds: 0 }),
    ) ||
    isBefore(
      set(start, { seconds: 0, milliseconds: 0 }),
      set(start, { hours: 5, minutes: 0, seconds: 0, milliseconds: 0 }),
    );

  const exitAfter5AndBefore22 =
    isAfter(
      set(endDay, { seconds: 0, milliseconds: 0 }),
      set(endDay, { hours: 4, minutes: 59, seconds: 0, milliseconds: 0 }),
    ) &&
    isBefore(
      set(endDay, { seconds: 0, milliseconds: 0 }),
      set(endDay, { hours: 22, minutes: 0, seconds: 0, milliseconds: 0 }),
    );

  const exitBefore5 = isBefore(
    set(endDay, { seconds: 0, milliseconds: 0 }),
    set(endDay, { hours: 5, minutes: 0, seconds: 0, milliseconds: 0 }),
  );

  const exitAfter22OfTheEnterDayAndBefore5OfNextDay =
    (sameDay ||
      isEqual(
        set(endDay, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
        set(endDay, { seconds: 0, milliseconds: 0 }),
      )) &&
    isAfter(
      set(endDay, { seconds: 0, milliseconds: 0 }),
      set(end, { hours: 22, minutes: 0, seconds: 0, milliseconds: 0 }),
    ) &&
    isBefore(
      set(endDay, { seconds: 0, milliseconds: 0 }),
      set(nextDay, { hours: 0, minutes: 1, seconds: 0, milliseconds: 0 }),
    );

  if (startAfter5AndBefore22) {
    if (exitAfter22AndBefore5OfNextDay) {
      const { hours, minutes } = calculateDifference(
        set(endDay, { seconds: 0, milliseconds: 0 }),
        set(start, { hours: 22, minutes: 0, seconds: 0, milliseconds: 0 }),
        mainBreak,
        daysWorkedOnWeek,
      );

      totalNightAdditionalHours = hours;
      totalNightAdditionalMinutes = minutes;
    }
    if (exitAfter5OfNextDay) {
      if (daysWorkedOnWeek) {
        totalNightAdditionalHours = 7 * daysWorkedOnWeek;
      } else {
        totalNightAdditionalHours = 7;
      }
      totalNightAdditionalMinutes = 0;
    }
  } else if (startBetween22And5) {
    if (exitAfter5AndBefore22) {
      const { hours, minutes } = calculateDifference(
        set(start, { seconds: 0, milliseconds: 0 }),
        set(endDay, { hours: 5, minutes: 0, seconds: 0, milliseconds: 0 }),
        mainBreak,
        daysWorkedOnWeek,
      );

      totalNightAdditionalHours = hours;
      totalNightAdditionalMinutes = minutes;
    }
    if (exitBefore5) {
      const { hours, minutes } = calculateDifference(
        set(start, { seconds: 0, milliseconds: 0 }),
        set(endDay, { seconds: 0, milliseconds: 0 }),
        mainBreak,
        daysWorkedOnWeek,
      );
      totalNightAdditionalHours = hours;
      totalNightAdditionalMinutes = minutes;
    }
    if (exitAfter22OfTheEnterDayAndBefore5OfNextDay) {
      const { hours: enterHours, minutes: enterMinutes } = calculateDifference(
        set(start, { seconds: 0, milliseconds: 0 }),
        set(start, { hours: 5, seconds: 0, milliseconds: 0 }),
        mainBreak,
        daysWorkedOnWeek,
      );
      totalNightAdditionalHours = enterHours;
      totalNightAdditionalMinutes = enterMinutes;

      const { hours: exitHours, minutes: exitMinutes } = calculateDifference(
        set(start, { hours: 22, seconds: 0, milliseconds: 0 }),
        set(endDay, { seconds: 0, milliseconds: 0 }),
        0,
        daysWorkedOnWeek,
      );

      totalNightAdditionalHours += exitHours;
      totalNightAdditionalMinutes += exitMinutes;
    }
  }

  return {
    hours: totalNightAdditionalHours,
    minutes: totalNightAdditionalMinutes,
  };
};
