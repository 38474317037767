import { Button, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, ModalSkeleton } from '../../../../components';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import { ActionsBar, ContainerModal, StyledGrid } from '../styles';

const ReprovedModal = ({
  handleOnClose,
  values,
  reasonLabel,
  setForceGetRequests,
  setForceGetCollaboratorsCompTime,
}) => {
  const { reason, date, justification, _id } = values;
  const [disapprovalReason, setDisapprovalReason] = useState('');
  const [hasError, setHasError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const dispatch = useDispatch();

  const handleChangeDisapprovalReason = event => {
    setDisapprovalReason(event.target.value);
  };

  const verifyErrors = () => {
    if (!disapprovalReason.trim()) {
      setHasError(true);
      setHelperText('Campo obrigatório');
      return true;
    }
    setHasError(false);
    setHelperText('');
    return false;
  };

  const sendReprovedRequest = async () => {
    try {
      const api = AuthenticatedHttpClient();
      await api.put(`point-solicitation/reprove/${_id}`, {
        disapprovalReason,
      });
      setForceGetRequests(prevState => !prevState);
      setForceGetCollaboratorsCompTime(prevState => !prevState);
      dispatch(setSnackbar(true, 'success', 'Solicitação Reprovada'));
    } catch (error) {
      dispatch(
        setSnackbar(true, 'error', 'Não foi possível reprovar a solicitação'),
      );
    } finally {
      handleOnClose();
    }
  };

  const handleReproveRequest = () => {
    const hasErrors = verifyErrors();
    if (hasErrors) return;
    sendReprovedRequest();
  };

  return (
    <ModalSkeleton
      title={reasonLabel[reason].toUpperCase()}
      handleClickOnClose={handleOnClose}
      maxWidth="916px"
    >
      <ContainerModal>
        <Grid container alignItems="center" justify="space-between" spacing={3}>
          <StyledGrid item xs={6}>
            <TextField
              name="date"
              label="Data da solicitação"
              variant="filled"
              value={date}
              disabled
              fullWidth
            />
          </StyledGrid>
          <StyledGrid item xs={6}>
            <TextField
              name="reason"
              label="Motivo"
              variant="filled"
              value={reasonLabel[reason]}
              disabled
              fullWidth
            />
          </StyledGrid>
          <StyledGrid item xs={12}>
            <TextField
              name="justification"
              label="Justificativa"
              variant="filled"
              disabled
              value={justification}
              fullWidth
            />
          </StyledGrid>
          <StyledGrid item xs={12}>
            <TextField
              name="disapprovalReason"
              label="Motivo da reprovação*"
              variant="filled"
              onChange={handleChangeDisapprovalReason}
              value={disapprovalReason}
              error={hasError}
              helperText={helperText}
              fullWidth
            />
          </StyledGrid>
        </Grid>
        <ActionsBar container alignItems="center" justify="flex-end">
          <StyledGrid mt="32px">
            <Button
              onClick={handleReproveRequest}
              color="secondary"
              size="small"
            >
              Enviar
            </Button>
          </StyledGrid>
        </ActionsBar>
      </ContainerModal>
    </ModalSkeleton>
  );
};

ReprovedModal.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  reasonLabel: PropTypes.objectOf(PropTypes.any).isRequired,
  setForceGetRequests: PropTypes.func.isRequired,
  setForceGetCollaboratorsCompTime: PropTypes.func.isRequired,
};

export default ReprovedModal;
