/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import cep from 'cep-promise';
import axios from 'axios';
import {
  Box,
  TextField as TextFieldMaterial,
  MenuItem,
  Button,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';

import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import addressSchema from '../../../validations/address';
import { AuthenticatedHttpClient } from '../../../services/api';

import {
  TabPanel,
  Downloader,
  CEPInput,
  CPFInput,
  Grid,
} from '../../../components';

import {
  TabContainer,
  ContainerInput,
  SubTitleForm,
  StyledCheckbox,
} from '../styles';

const Address = props => {
  const { value, index, data, setUser, ...other } = props;
  const [address, setAddress] = useState({
    cep: '',
    state: null,
    city: null,
    publicPlace: '',
    number: '',
    complement: '',
    neighborhood: '',
    type: '',
    cpfOwner: '',
    rgOwner: '',
    proofOfAddress: false,
  });
  const [userCep, setUserCep] = useState('');
  const [ufs, setUfs] = useState([]);
  const [cities, setCities] = useState([]);
  const [ufSelected, setUfSelected] = useState('');

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();
  const { receipt } = data;

  useEffect(() => {
    async function getUserData() {
      try {
        const response = await axios.get(
          'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
        );

        const sortedOptions = response.data.sort((stateA, stateB) =>
          stateA.sigla > stateB.sigla ? 1 : -1,
        );

        const ufInitials = sortedOptions.map(uf => ({
          label: uf.nome,
          value: uf.sigla,
        }));
        setUfs(ufInitials);

        if (Object.keys(data).length) {
          const {
            state,
            city,
            publicPlace,
            number,
            complement,
            neighborhood,
            type,
            proofOfAddress,
            cpfOwner,
            rgOwner,
          } = data;

          const userState = ufInitials.find(uf => uf.value === state);

          try {
            const citiesResponse = await axios.get(
              `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`,
            );
            const citiesOptions = citiesResponse.data.map(
              cityOption => cityOption.nome,
            );
            setCities(citiesOptions);
          } catch (error) {
            dispatch(setSnackbar(true, 'error', 'Falha ao carregar cidades'));
          }

          setAddress({
            cep: data.cep,
            state: userState,
            city,
            publicPlace,
            number,
            complement,
            neighborhood,
            type,
            proofOfAddress,
            cpfOwner: cpfOwner || '',
            rgOwner: rgOwner || '',
          });
        }
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao buscar informações'));
      }
    }

    getUserData();
  }, [data, dispatch]);

  useEffect(() => {
    if (!ufSelected) return;
    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufSelected}/municipios`,
      )
      .then(response => {
        const citiesOptions = response.data.map(city => city.nome);
        setCities(citiesOptions);
      })
      .catch(() => {
        dispatch(setSnackbar(true, 'error', 'Falha ao carregar cidades'));
      });
  }, [ufSelected, dispatch]);

  useEffect(() => {
    if (userCep.length < 8) return;
    async function getAddressByCep() {
      try {
        const response = await cep(userCep);

        const state = ufs.find(uf => uf.value === response.state);

        setUfSelected(response.state);

        setAddress(oldState => ({
          ...oldState,
          cep: response.cep,
          city: response.city,
          neighborhood: response.neighborhood,
          state,
          publicPlace: response.street,
        }));
      } catch (err) {
        dispatch(setSnackbar(true, 'error', 'CEP inválido ou não encontrado'));
      }
    }
    getAddressByCep();
  }, [userCep, dispatch, ufs]);

  const handleSubmitAddress = useCallback(
    async (values, { setSubmitting }) => {
      try {
        dispatch(setLoading(true));

        const api = AuthenticatedHttpClient();

        const addressUpdated = {
          ...values,
          state: values.state.value,
        };

        if (values.proofOfAddress) {
          addressUpdated.cpfOwner = '';
          addressUpdated.rgOwner = '';
        }

        const response = await api.put(`users/${collaboratorId}`, {
          address: addressUpdated,
        });

        setUser(response.data);
        dispatch(setSnackbar(true, 'success', 'Dados alterados'));
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possível alterar os dados'),
        );
      } finally {
        setSubmitting(false);
        dispatch(setLoading(false));
      }
    },
    [dispatch, collaboratorId, setUser],
  );

  const handleChangeState = useCallback((fieldValue, setField) => {
    if (fieldValue) {
      setField('state', fieldValue);
      setField('city', null);
      setUfSelected(fieldValue.value);
    } else {
      setField('state', null);
      setField('city', null);
      setUfSelected('');
      setCities([]);
    }
  }, []);

  const handleChangeCep = useCallback((event, setField) => {
    setField('cep', event.target.value);
    setUserCep(event.target.value);
  }, []);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Formik
          initialValues={address}
          onSubmit={handleSubmitAddress}
          validationSchema={addressSchema}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue, touched, errors, values }) => (
            <Form>
              <Box
                component={Grid}
                container
                alignItems="center"
                justify="space-between"
                spacing={2}
                p={1}
              >
                <SubTitleForm>Endereço do colaborador</SubTitleForm>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="cep"
                    label="CEP"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      inputComponent: CEPInput,
                      onChange: e => handleChangeCep(e, setFieldValue),
                    }}
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    select
                    name="type"
                    label="Tipo"
                    variant="filled"
                    fullWidth
                  >
                    <MenuItem value="Alamenda">Alamenda</MenuItem>
                    <MenuItem value="Avenida">Avenida</MenuItem>
                    <MenuItem value="Chácara">Chácara</MenuItem>
                    <MenuItem value="Condomínio">Condomínio</MenuItem>
                    <MenuItem value="Conjunto">Conjunto</MenuItem>
                    <MenuItem value="Distrito">Distrito</MenuItem>
                    <MenuItem value="Estrada">Estrada</MenuItem>
                    <MenuItem value="Rodovia">Rodovia</MenuItem>
                    <MenuItem value="Rua">Rua</MenuItem>
                  </Field>
                </ContainerInput>
                <ContainerInput item lg={6}>
                  <Field
                    component={TextField}
                    name="publicPlace"
                    label="Logradouro"
                    variant="filled"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="number"
                    label="Número"
                    variant="filled"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="complement"
                    label="Complemento"
                    variant="filled"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="neighborhood"
                    label="Bairro"
                    variant="filled"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    name="city"
                    component={Autocomplete}
                    options={cities}
                    fullWidth
                    renderInput={params => (
                      <TextFieldMaterial
                        {...params}
                        error={touched.city && !!errors.city}
                        helperText={touched.city && errors.city}
                        label="Cidade"
                        variant="filled"
                        disabled={isSubmitting}
                      />
                    )}
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    name="state"
                    component={Autocomplete}
                    options={ufs}
                    getOptionLabel={option => option.label}
                    getOptionSelected={(option, fieldValue) =>
                      option.value === fieldValue.value
                    }
                    fullWidth
                    renderInput={params => (
                      <TextFieldMaterial
                        {...params}
                        error={touched.state && !!errors.state}
                        helperText={touched.state && errors.state}
                        label="Estado"
                        variant="filled"
                      />
                    )}
                    onChange={(e, fieldValue) =>
                      handleChangeState(fieldValue, setFieldValue)
                    }
                  />
                </ContainerInput>
                {!values.proofOfAddress && (
                  <>
                    <ContainerInput item lg={3}>
                      <Field
                        component={TextField}
                        name="cpfOwner"
                        label="CPF do Proprietário"
                        variant="filled"
                        fullWidth
                        InputProps={{
                          inputComponent: CPFInput,
                        }}
                      />
                    </ContainerInput>
                    <ContainerInput item lg={3}>
                      <Field
                        component={TextField}
                        name="rgOwner"
                        label="RG do Proprietário"
                        variant="filled"
                        fullWidth
                      />
                    </ContainerInput>
                    <ContainerInput item lg={3} />
                  </>
                )}
                <Box width={1} alignItems="center">
                  <Field
                    component={StyledCheckbox}
                    name="proofOfAddress"
                    color="primary"
                    type="checkbox"
                    Label={{
                      label: 'Comprovante está em nome do colaborador',
                    }}
                  />
                </Box>
                {receipt && (
                  <>
                    <SubTitleForm>Comprovante de endereço</SubTitleForm>
                    <ContainerInput item lg={3}>
                      <Downloader
                        file={{
                          name: receipt.name,
                          size: receipt.size,
                          key: receipt.key,
                        }}
                      />
                    </ContainerInput>
                  </>
                )}
              </Box>
              <Box width={1} display="flex" justifyContent="flex-end" my={2}>
                <Button type="submit" color="secondary" disabled={isSubmitting}>
                  ALTERAR DADOS
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </TabContainer>
    </TabPanel>
  );
};

Address.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
};

Address.defaultProps = {
  data: {},
};

export default Address;
