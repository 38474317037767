import { MenuItem } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { SelectDate } from '../../styles';

const PayslipSelects = ({ generateMonthOptions, generateYearOptions }) => {
  const { values, setFieldValue } = useFormikContext();
  const [monthSelectOptions, setMonthSelectOptions] = useState([]);

  useEffect(() => {
    setMonthSelectOptions(generateMonthOptions(values.year));
    setFieldValue('month', '');
  }, [generateMonthOptions, values.year, setFieldValue]);

  const yearSelectOptions = generateYearOptions();

  return (
    <>
      <SelectDate item lg={2}>
        <Field
          component={TextField}
          name="year"
          select
          label="Ano"
          variant="filled"
          fullWidth
        >
          {yearSelectOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>
      </SelectDate>
      <SelectDate item lg={2}>
        <Field
          component={TextField}
          select
          name="month"
          label="Mês"
          variant="filled"
          fullWidth
        >
          {monthSelectOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>
      </SelectDate>
    </>
  );
};

PayslipSelects.propTypes = {
  generateMonthOptions: PropTypes.func.isRequired,
  generateYearOptions: PropTypes.func.isRequired,
};

export default PayslipSelects;
