import {
  ButtonBase,
  Checkbox,
  DialogContent,
  IconButton,
  Menu,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import eyeIcon from '../../../assets/eye.svg';
import { EmptyState, Grid, Tooltip, Typography } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import formatPhone from '../../../utils/formatPhone';
import getActivityFieldData from '../../../utils/getActivityFieldData';
import ConfirmReturnFromActivityModal from '../ConfirmReturnFromActivityModal';
import { StyledIconButton, StyledMenuItem, StyledTableCell } from './styles';

const headCells = [
  {
    id: 'fullName',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'jobRole.name',
    numeric: false,
    disablePadding: false,
    label: 'Cargo',
  },
  {
    id: 'department.name',
    numeric: false,
    disablePadding: false,
    label: 'Departamento',
  },
  { id: 'phone', numeric: false, disablePadding: false, label: 'Celular' },
  {
    id: 'statusActive',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'startedIn',
    numeric: false,
    disablePadding: false,
    label: 'Iniciado em',
  },
  {
    id: 'endsIn',
    numeric: false,
    disablePadding: false,
    label: 'Finaliza em',
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Ação</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTable = ({
  textFilter,
  jobRoleFilter,
  departmentFilter,
  statusFilter,
}) => {
  const [dataTable, setDataTable] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pageUsersCount, setPageUsersCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activitySelected, setActivitySelected] = useState(null);

  const dispatch = useDispatch();
  const { user } = useAuth();
  const history = useHistory();

  const statusActive = useCallback(
    status =>
      ({
        WORK_EXPERIENCE: 'Experiência',
        HIRED: 'Efetivado',
        MEDICAL_CERTIFICATE: 'Atestado',
        INSS_REMOVAL: 'Afastamento INSS',
        DAY_OFF: 'Folga',
        VACATION: 'Férias',
        WEEKLY_REST: 'Descanso semanal',
        SUSPENSION: 'Suspensão',
        MATERNITY_LEAVE: 'Licença maternidade',
        PATERNITY_LEAVE: 'Licença paternidade',
        UNPAID_LEAVE: 'Licença não remunerada',
      }[status]),
    [],
  );

  const workingStatuses = ['Experiência', 'Efetivado'];

  const getUsers = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      setIsLoading(true);
      const api = AuthenticatedHttpClient();
      const response = await api.get(`users/company/${user.company._id}`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          status: 'ACTIVE',
          text: textFilter,
          jobRole: jobRoleFilter.trim(),
          department: departmentFilter.trim(),
          statusActive: statusFilter.trim(),
          order,
          orderBy,
        },
      });

      const responseData = await Promise.all(
        response.data.docs.map(async userInfo => {
          const startedIn = await getActivityFieldData(
            userInfo,
            'startDate',
            userInfo.statusActive,
          );
          const endsIn = await getActivityFieldData(
            userInfo,
            'finalDate',
            userInfo.statusActive,
          );

          const { data: activeActivity } = await api.get(
            `/activity/${userInfo._id}`,
          );

          return {
            _id: userInfo._id,
            fullName: userInfo.fullName,
            jobRole: userInfo.jobRole.name,
            department: (userInfo.department && userInfo.department.name) || '',
            phone: formatPhone(userInfo.phone),
            statusActive: statusActive(userInfo.statusActive) || '',
            startedIn,
            endsIn,
            activeActivity,
          };
        }),
      );
      setUsersCount(response.data.total);
      setPageUsersCount(responseData.length);
      setDataTable(responseData);
    } catch (error) {
      dispatch(
        setSnackbar(true, 'error', 'Não foi possível buscar os colaboradores'),
      );
    } finally {
      dispatch(setLoading(false));
      setIsLoading(false);
    }
  }, [
    departmentFilter,
    dispatch,
    jobRoleFilter,
    order,
    orderBy,
    page,
    rowsPerPage,
    statusActive,
    statusFilter,
    textFilter,
    user.company._id,
  ]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleRemoveUsers = useCallback(async () => {
    try {
      const api = AuthenticatedHttpClient();

      await api.delete('/users', {
        data: { usersId: selected },
      });

      setDataTable(prevState =>
        prevState.filter(userData => !selected.includes(userData._id)),
      );

      dispatch(setSnackbar(true, 'success', 'Operação realizada com sucesso'));
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Falha ao remover usuários'));
    } finally {
      setAnchorEl(null);
    }
  }, [dispatch, selected]);

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRedirect = useCallback(
    userId => {
      history.push(`active/collaborator/${userId}`);
    },
    [history],
  );

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const handleSelectAllClick = useCallback(
    event => {
      if (event.target.checked) {
        const newSelecteds = dataTable.map(n => n._id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    [dataTable],
  );

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const MAX_STRING_LENGTH = 35;

  const returnCollaboratorFromActivity = clickedUser => {
    setIsModalOpen(true);
    setActivitySelected(clickedUser.activeActivity);
  };

  const confirmCollaboratorReturn = async () => {
    try {
      dispatch(setLoading(true));
      const api = AuthenticatedHttpClient();
      await api.put('/activity/end-activity-with-no-forecast', {
        activityId: activitySelected._id,
      });
      dispatch(
        setSnackbar(
          true,
          'success',
          'O afastamento do colaborador foi removido! Ele retornará amanhã.',
        ),
      );
      setIsModalOpen(false);
      getUsers();
    } catch (e) {
      dispatch(
        setSnackbar(true, 'error', 'Não foi possivel voltar o colaborator.'),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  if (isLoading) return '';

  return (
    <>
      {!usersCount ? (
        <EmptyState description="Nenhum colaborador encontrado!" />
      ) : (
        <div>
          <Paper elevation={0}>
            <Grid container alignItems="center" justify="space-between">
              <Typography variant="h3">COLABORADORES ATIVOS</Typography>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClickMenu}
              >
                <Settings />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <StyledMenuItem onClick={handleRemoveUsers}>
                  EXCLUIR SELECIONADOS
                </StyledMenuItem>
              </Menu>
            </Grid>
            <TableContainer>
              <Table size="small">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={pageUsersCount}
                />
                <TableBody>
                  {dataTable.map(row => {
                    const isItemSelected = isSelected(row._id);

                    const isUserOff = !workingStatuses.includes(
                      row.statusActive,
                    );
                    const canBeCanceled =
                      isUserOff && row.activeActivity?.withoutForecastOfEnding;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                      >
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            onClick={event => handleClick(event, row._id)}
                            checked={isItemSelected}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <ButtonBase onClick={() => handleRedirect(row._id)}>
                            <Tooltip
                              fullText={row.fullName}
                              maxStringLength={MAX_STRING_LENGTH}
                            />
                          </ButtonBase>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Tooltip
                            fullText={row.jobRole}
                            maxStringLength={MAX_STRING_LENGTH}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Tooltip
                            fullText={row.department}
                            maxStringLength={MAX_STRING_LENGTH}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.phone}
                        </StyledTableCell>

                        <StyledTableCell align="left" vacation={isUserOff}>
                          {isUserOff ? (
                            <ButtonBase
                              onClick={() =>
                                returnCollaboratorFromActivity(row)
                              }
                              disabled={!canBeCanceled}
                            >
                              <Tooltip
                                fullText={row.statusActive}
                                maxStringLength={MAX_STRING_LENGTH}
                                bold
                                error
                              />
                            </ButtonBase>
                          ) : (
                            <Tooltip
                              fullText={row.statusActive}
                              maxStringLength={MAX_STRING_LENGTH}
                            />
                          )}
                        </StyledTableCell>

                        <StyledTableCell
                          align="left"
                          opacity={row.startedIn === '-----'}
                        >
                          {row.startedIn}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          opacity={row.endsIn === '-----'}
                        >
                          {row.endsIn === '-----' ? (
                            row.endsIn
                          ) : (
                            <>{row.endsIn}</>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <StyledIconButton
                            onClick={() => handleRedirect(row._id)}
                          >
                            <img src={eyeIcon} alt="" />
                          </StyledIconButton>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={usersCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <DialogContent>
                <ConfirmReturnFromActivityModal
                  title="COLABORADOR AFASTADO"
                  handleClose={() => setIsModalOpen(false)}
                  handleConfirmChange={confirmCollaboratorReturn}
                  activity={activitySelected}
                  statusActiveLabel={statusActive}
                />
              </DialogContent>
            </Modal>
          </Paper>
        </div>
      )}
    </>
  );
};

EnhancedTable.propTypes = {
  textFilter: PropTypes.string.isRequired,
  jobRoleFilter: PropTypes.string.isRequired,
  departmentFilter: PropTypes.string.isRequired,
  statusFilter: PropTypes.string.isRequired,
};

export default EnhancedTable;
