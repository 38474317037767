import { Box, Paper } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Return from '../../../assets/arrow-left.svg';
import { Grid, Tab, Tabs, Typography } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { SCALE_TYPES } from '../../../utils/enums';
import { validateBreaks } from '../../../utils/journeyBreaksValidation';
import validateJourney from '../../../utils/journeyScaleValidationFunctions';
import { StyledButtonBase } from './styles';
import { Breaks, JourneyDetails, Scale } from './Tabs/index';

const journeyDetailsValues = {
  name: '',
  pointType: '',
  intervalCompensation: '',
  entryDelayTime: '',
  breakDelayTime: '',
  anticipationExitTime: '',
  breakAnticipationTime: '',
  limited: '',
  limitTime: '',
  extraHourPerDay: '',
};

const scaleValues = {
  scaleType: '',
  totalHours: '00:00',
  nightAdditional: '0',
  holidays: '',
  standardWeekScale: {
    isEqualTime: false,
    monday: {
      enterTime: null,
      exitTime: null,
      mainBreak: '',
    },
    tuesday: {
      enterTime: null,
      exitTime: null,
      mainBreak: '',
    },
    wednesday: {
      enterTime: null,
      exitTime: null,
      mainBreak: '',
    },
    thursday: {
      enterTime: null,
      exitTime: null,
      mainBreak: '',
    },
    friday: {
      enterTime: null,
      exitTime: null,
      mainBreak: '',
    },
    saturday: {
      enterTime: null,
      exitTime: null,
      mainBreak: '',
    },
    sunday: {
      enterTime: null,
      exitTime: null,
      mainBreak: '',
    },
    isMonday: true,
    isTuesday: true,
    isWednesday: true,
    isThursday: true,
    isFriday: true,
    isSaturday: false,
    isSunday: false,
    standardTime: {
      enterTime: null,
      exitTime: null,
      mainBreak: '',
    },
  },
  standardDayScale: {
    standardDay: {
      enterTime: null,
      exitTime: null,
      mainBreak: '',
    },
  },
};

const breaksValues = {
  numberOfBreaks: '',
  breaks: [],
};

const NewJourney = () => {
  const [tabValue, setTabValue] = useState(0);
  const [journeyValues, setJourneyValues] = useState({});
  const [
    journeyDetailsInitialValues,
    setJourneyDetailsInitialValues,
  ] = useState(journeyDetailsValues);
  const [scaleInitialValues, setScaleInitialValues] = useState(scaleValues);
  const [breaksInitialValues, setBreaksInitialValues] = useState(breaksValues);

  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useAuth();

  const handleChangeTab = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const handleBack = () => {
    const newTabValue = tabValue - 1;
    handleChangeTab(null, newTabValue);
  };

  const handleSaveJourneyDetails = async (values, { setFieldError }) => {
    try {
      const api = AuthenticatedHttpClient();
      const { data: hasJourneyName } = await api.get('/workday/validate/name', {
        params: {
          name: values.name,
          company: user.company._id,
        },
      });
      if (hasJourneyName) {
        setFieldError('name', 'Já existe uma jornada com esse nome');
        return;
      }
      setJourneyValues(prevState => {
        return {
          ...prevState,
          ...values,
        };
      });
      setJourneyDetailsInitialValues({ ...values });
      handleChangeTab(null, 1);
    } catch (e) {
      dispatch(setSnackbar(true, 'error', 'Falha ao salvar detalhes jornada'));
    }
  };

  const dayDetailsFactory = weekDay => ({
    enterTime: weekDay.enterTime ? format(weekDay.enterTime, 'HH:mm') : '',
    exitTime: weekDay.exitTime ? format(weekDay.exitTime, 'HH:mm') : '',
    mainBreak: weekDay.mainBreak || '',
  });

  const handleSaveScale = (values, { setSubmitting }) => {
    const validatedJourney = validateJourney(values);
    if (!validatedJourney.valid) {
      dispatch(setSnackbar(true, 'error', validatedJourney.message));
      setSubmitting(false);
      return;
    }
    if (
      values.scaleType === SCALE_TYPES['12/36'] ||
      values.scaleType === SCALE_TYPES['24/48'] ||
      values.scaleType === SCALE_TYPES['5x1']
    ) {
      const {
        standardDayScale: { standardDay },
      } = values;
      setJourneyValues(prevState => {
        const updatedJourneyValues = {
          ...prevState,
          ...values,
          standardDayScale: {
            standardDay: dayDetailsFactory(standardDay),
          },
        };

        delete updatedJourneyValues.standardWeekScale;

        return updatedJourneyValues;
      });
    }

    if (
      values.scaleType === SCALE_TYPES['5x2'] ||
      values.scaleType === SCALE_TYPES['6x1'] ||
      values.scaleType === SCALE_TYPES.CALL_CENTER_30 ||
      values.scaleType === SCALE_TYPES.CALL_CENTER_36
    ) {
      if (!values.standardWeekScale.isEqualTime) {
        const {
          standardWeekScale: {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
          },
        } = values;

        setJourneyValues(prevState => {
          const updatedJourneyValues = {
            ...prevState,
            ...values,
            standardWeekScale: {
              ...values.standardWeekScale,
              monday: dayDetailsFactory(monday),
              tuesday: dayDetailsFactory(tuesday),
              wednesday: dayDetailsFactory(wednesday),
              thursday: dayDetailsFactory(thursday),
              friday: dayDetailsFactory(friday),
              saturday: dayDetailsFactory(saturday),
              sunday: dayDetailsFactory(sunday),
            },
          };

          delete updatedJourneyValues.standardDayScale;
          delete updatedJourneyValues.standardWeekScale.standardTime;

          return updatedJourneyValues;
        });
      } else {
        setJourneyValues(prevState => {
          const {
            standardWeekScale: { standardTime },
          } = values;

          const updatedJourneyValues = {
            ...prevState,
            ...values,
            standardWeekScale: {
              ...values.standardWeekScale,
              standardTime: dayDetailsFactory(standardTime),
            },
          };

          delete updatedJourneyValues.standardWeekScale.monday;
          delete updatedJourneyValues.standardWeekScale.tuesday;
          delete updatedJourneyValues.standardWeekScale.wednesday;
          delete updatedJourneyValues.standardWeekScale.thursday;
          delete updatedJourneyValues.standardWeekScale.friday;
          delete updatedJourneyValues.standardWeekScale.saturday;
          delete updatedJourneyValues.standardWeekScale.sunday;
          delete updatedJourneyValues.standardDayScale;

          return updatedJourneyValues;
        });
      }
    }
    setScaleInitialValues({ ...values });
    handleChangeTab(null, 2);
  };

  const handleSubmitJourney = async (values, { setSubmitting }) => {
    try {
      const scale = {
        type: journeyValues.scaleType,
        times:
          journeyValues.standardDayScale || journeyValues.standardWeekScale,
      };

      const validatedBreaks = validateBreaks(values.breaks, scale);
      if (!validatedBreaks.valid) {
        dispatch(setSnackbar(true, 'error', validatedBreaks.message));
        setSubmitting(false);

        return;
      }
      dispatch(setLoading(true));

      const { breaks } = values;

      const formattedBreaks = breaks.map(({ description, time, start }) => ({
        description,
        start: format(start, 'HH:mm'),
        time,
      }));

      const api = AuthenticatedHttpClient();

      await api.post('workday', {
        ...values,
        breaks: formattedBreaks,
        ...journeyValues,
        company: user.company._id,
      });

      setJourneyDetailsInitialValues(journeyDetailsValues);
      setScaleInitialValues(scaleValues);
      setBreaksInitialValues(breaksValues);

      dispatch(setSnackbar(true, 'success', 'Jornada salva com sucesso!'));
      history.goBack();
    } catch (error) {
      dispatch(
        setSnackbar(
          true,
          'error',
          'Não foi possível salvar a jornada, tente novamente.',
        ),
      );
    } finally {
      dispatch(setLoading(false));
      setSubmitting(false);
    }
  };

  return (
    <Box p={3}>
      <StyledButtonBase onClick={() => history.goBack()}>
        <img src={Return} alt="" />
      </StyledButtonBase>
      <Box mt={2}>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h2">NOVA JORNADA</Typography>
        </Grid>
      </Box>
      <Box mt={2}>
        <Paper square elevation={1}>
          <Tabs value={tabValue} onChange={handleChangeTab}>
            <Tab
              label="DETALHES DA JORNADA"
              index={0}
              disabled={tabValue !== 0}
            />
            <Tab label="ESCALA" index={1} disabled={tabValue !== 1} />
            <Tab label="PAUSAS" index={2} disabled={tabValue !== 2} />
          </Tabs>
          <JourneyDetails
            value={tabValue}
            index={0}
            handleSaveJourneyDetails={handleSaveJourneyDetails}
            initialValues={journeyDetailsInitialValues}
          />
          <Scale
            value={tabValue}
            index={1}
            handleSaveScale={handleSaveScale}
            initialValues={scaleInitialValues}
            handleBack={handleBack}
          />
          <Breaks
            value={tabValue}
            index={2}
            handleSubmitJourney={handleSubmitJourney}
            initialValues={breaksInitialValues}
            handleBack={handleBack}
          />
        </Paper>
      </Box>
    </Box>
  );
};

export default NewJourney;
