import { Avatar, ButtonBase, Grid } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const AlertCardContainer = styled(Grid)`
  padding: 12px;
  width: 100%;
  height: 73px;
  margin-top: 16px;
  background-color: #ffffff;
  border-radius: 6px;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  span {
    margin-left: 8px;
    ${({ theme }) => css`
    color: ${theme.palette.secondary.main};
    font-weight: 300;
    font-size: 12px;
    margin-top: '8px'
    opacity: 0.5;
  `}
  }
`;

export const StyledButton = styled(ButtonBase)`
  width: 151px;
  height: 35px;
  border: 0.5px solid #05202a;
  border-radius: 4px;
`;

export const StyledAvatar = styled(Avatar)`
  width: 41px;
  height: 41px;
  color: #bdbdbd;
  background-color: #fafafa;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
`;
