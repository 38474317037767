import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

const FormattedMoneyPlainText = ({ value }) => {
  return (
    <NumberFormat
      value={value}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      displayType="text"
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
      prefix="R$ "
    />
  );
};

FormattedMoneyPlainText.propTypes = {
  value: PropTypes.number.isRequired,
};

export default FormattedMoneyPlainText;
