import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import formatTimeString from '../../../../utils/formatTimeString';
import { StyledGrid } from '../styles';

const HoursCompensation = () => {
  const { values, setFieldValue } = useFormikContext();
  const validDate = isValid(values.hoursCompensation?.date);
  const dispatch = useDispatch();
  const { userId } = useParams();

  useEffect(() => {
    async function getPlannedHoursFromSelectedDay() {
      try {
        if (validDate) {
          const api = AuthenticatedHttpClient();
          const { data: minutesWorkedOnDay } = await api.get(
            `comp-time/workTimeByDay/${userId}`,
            {
              params: {
                date: values.hoursCompensation.date,
              },
            },
          );
          const formattedHoursCompensated = formatTimeString(
            minutesWorkedOnDay,
          );
          setFieldValue(
            'hoursCompensation.dayHoursCompensated',
            formattedHoursCompensated,
          );
        }
      } catch (error) {
        if (error.response?.data?.message) {
          dispatch(setSnackbar(true, 'error', error.response.data.message));
        } else {
          dispatch(
            setSnackbar(
              true,
              'error',
              'Não foi possivel calcular o horario compensado',
            ),
          );
        }
        setFieldValue('hoursCompensation.dayHoursCompensated', '00:00');
      }
    }
    getPlannedHoursFromSelectedDay();
  }, [
    dispatch,
    setFieldValue,
    userId,
    validDate,
    values.hoursCompensation.date,
  ]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <StyledGrid item lg={6}>
        <Field
          component={KeyboardDatePicker}
          name="hoursCompensation.date"
          label="Data"
          invalidDateMessage="Data inválida"
          inputVariant="filled"
          cancelLabel="CANCELAR"
          format="dd/MM/yyyy"
          placeholder="dd/mm/aaaa"
          fullWidth
        />
      </StyledGrid>

      {validDate && (
        <StyledGrid item lg={12}>
          <Field
            component={TextField}
            name="hoursCompensation.dayHoursCompensated"
            label="Horas a serem compensadas"
            variant="filled"
            fullWidth
            disabled
          />
        </StyledGrid>
      )}

      <StyledGrid item lg={12}>
        <Field
          component={TextField}
          name="hoursCompensation.justification"
          label="Justificativa"
          variant="filled"
          fullWidth
        />
      </StyledGrid>
    </MuiPickersUtilsProvider>
  );
};

export default HoursCompensation;
