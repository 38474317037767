import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../services/api';

export default function useUser(userId) {
  const [userInfo, setUserInfo] = useState(null);
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();

  const getUserData = useCallback(async () => {
    try {
      const api = AuthenticatedHttpClient();

      const { data } = await api.get(`users/${userId}`);

      const { fullName, email, phone, isWhatsApp } = data;

      setUserInfo({
        fullName,
        phone,
        email,
        isWhatsApp: isWhatsApp || false,
      });

      setUser(data);
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Falha ao buscar dados'));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return { userInfo, user, getUserData };
}
