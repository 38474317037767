import { Box, DialogContent, Modal, Paper } from '@material-ui/core';
import { format, set } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Return from '../../../assets/arrow-left.svg';
import { Grid, Tab, Tabs, Typography } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { SCALE_TYPES } from '../../../utils/enums';
import { validateBreaks } from '../../../utils/journeyBreaksValidation';
import validateJourney from '../../../utils/journeyScaleValidationFunctions';
import { StyledButtonBase } from '../NewJourney/styles';
import { Breaks, JourneyDetails, Scale } from '../NewJourney/Tabs/index';
import ConfirmChangeModal from './ConfirmChangeScaleModal';

const EditJourney = ({ location }) => {
  const {
    state: { selectedJourney: selectJourney },
  } = location;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [
    scaleValuesWhenAcceptedChange,
    setScaleValuesWhenAcceptedChange,
  ] = useState(false);
  const { user } = useAuth();

  const detailsInitial = {
    id: selectJourney._id,
    name: selectJourney.name,
    pointType: selectJourney.pointType,
    intervalCompensation: selectJourney.intervalCompensation,
    entryDelayTime: selectJourney.entryDelayTime,
    breakDelayTime: selectJourney.breakDelayTime,
    anticipationExitTime: selectJourney.anticipationExitTime,
    breakAnticipationTime: selectJourney.breakAnticipationTime,
    limited: selectJourney.limited ? 'true' : 'false',
    limitTime: selectJourney.limitTime,
    extraHourPerDay: selectJourney.extraHourPerDay,
  };

  const getHoursAndMinutes = timeInString => {
    const [hours, minutes] = timeInString.split(':');
    return [hours, minutes];
  };

  const createDateWithHoursAndMinutes = (hours, minutes) => {
    return set(new Date(), {
      hours,
      minutes,
    });
  };

  const formatDayFactory = day => {
    if (day.enterTime) {
      const [hoursEnter, minutesEnter] = getHoursAndMinutes(day.enterTime);
      const [hoursExit, minutesExit] = getHoursAndMinutes(day.exitTime);
      return {
        enterTime: set(new Date(), {
          hours: hoursEnter,
          minutes: minutesEnter,
        }),
        exitTime: set(new Date(), {
          hours: hoursExit,
          minutes: minutesExit,
        }),
        mainBreak: day.mainBreak,
      };
    }
    return {
      enterTime: null,
      exitTime: null,
      mainBreak: '',
    };
  };

  const getFormattedScale = () => {
    if (
      selectJourney.scaleType === SCALE_TYPES['12/36'] ||
      selectJourney.scaleType === SCALE_TYPES['24/48'] ||
      selectJourney.scaleType === SCALE_TYPES['5x1']
    ) {
      return {
        scaleType: selectJourney.scaleType,
        holidays: selectJourney.holidays ? 'true' : 'false',
        totalHours: selectJourney.totalHours,
        nightAdditional: selectJourney.nightAdditional,
        standardDayScale: {
          standardDay: formatDayFactory(
            selectJourney.standardDayScale.standardDay,
          ),
        },
        standardWeekScale: {
          isEqualTime: false,
          monday: {
            enterTime: null,
            exitTime: null,
            mainBreak: '',
          },
          tuesday: {
            enterTime: null,
            exitTime: null,
            mainBreak: '',
          },
          wednesday: {
            enterTime: null,
            exitTime: null,
            mainBreak: '',
          },
          thursday: {
            enterTime: null,
            exitTime: null,
            mainBreak: '',
          },
          friday: {
            enterTime: null,
            exitTime: null,
            mainBreak: '',
          },
          saturday: {
            enterTime: null,
            exitTime: null,
            mainBreak: '',
          },
          sunday: {
            enterTime: null,
            exitTime: null,
            mainBreak: '',
          },
          isMonday: true,
          isTuesday: true,
          isWednesday: true,
          isThursday: true,
          isFriday: true,
          isSaturday: false,
          isSunday: false,
          standardTime: {
            enterTime: null,
            exitTime: null,
            mainBreak: '',
          },
        },
      };
    }
    if (!selectJourney.standardWeekScale.isEqualTime) {
      return {
        scaleType: selectJourney.scaleType,
        holidays: selectJourney.holidays ? 'true' : 'false',
        totalHours: selectJourney.totalHours,
        nightAdditional: selectJourney.nightAdditional,
        standardWeekScale: {
          ...selectJourney.standardWeekScale,
          monday: formatDayFactory(selectJourney.standardWeekScale.monday),
          tuesday: formatDayFactory(selectJourney.standardWeekScale.tuesday),
          wednesday: formatDayFactory(
            selectJourney.standardWeekScale.wednesday,
          ),
          thursday: formatDayFactory(selectJourney.standardWeekScale.thursday),
          friday: formatDayFactory(selectJourney.standardWeekScale.friday),
          saturday: formatDayFactory(selectJourney.standardWeekScale.saturday),
          sunday: formatDayFactory(selectJourney.standardWeekScale.sunday),
          standardTime: {
            enterTime: null,
            exitTime: null,
            mainBreak: '',
          },
        },
        standardDayScale: {
          standardDay: {
            enterTime: null,
            exitTime: null,
            mainBreak: '',
          },
        },
      };
    }
    return {
      scaleType: selectJourney.scaleType,
      holidays: selectJourney.holidays ? 'true' : 'false',
      totalHours: selectJourney.totalHours,
      nightAdditional: selectJourney.nightAdditional,
      standardWeekScale: {
        ...selectJourney.standardWeekScale,
        standardTime: formatDayFactory(
          selectJourney.standardWeekScale.standardTime,
        ),
        monday: {
          enterTime: null,
          exitTime: null,
          mainBreak: '',
        },
        tuesday: {
          enterTime: null,
          exitTime: null,
          mainBreak: '',
        },
        wednesday: {
          enterTime: null,
          exitTime: null,
          mainBreak: '',
        },
        thursday: {
          enterTime: null,
          exitTime: null,
          mainBreak: '',
        },
        friday: {
          enterTime: null,
          exitTime: null,
          mainBreak: '',
        },
        saturday: {
          enterTime: null,
          exitTime: null,
          mainBreak: '',
        },
        sunday: {
          enterTime: null,
          exitTime: null,
          mainBreak: '',
        },
      },
      standardDayScale: {
        standardDay: {
          enterTime: null,
          exitTime: null,
          mainBreak: '',
        },
      },
    };
  };

  const scaleInitial = getFormattedScale();

  const formatBreaks = breaks => {
    return breaks.map((breakValue, index) => {
      const [hoursStart, minutesStart] = getHoursAndMinutes(breakValue.start);
      return {
        ...breakValue,
        start: createDateWithHoursAndMinutes(hoursStart, minutesStart),
        id: index,
      };
    });
  };

  const breaksInitial = {
    numberOfBreaks: selectJourney.numberOfBreaks,
    breaks: formatBreaks(selectJourney.breaks),
  };

  const [tabValue, setTabValue] = useState(0);

  const [journeyDetailsInitialValues] = useState(detailsInitial);
  const [scaleInitialValues] = useState(scaleInitial);
  const [breaksInitialValues] = useState(breaksInitial);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleChangeTab = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const handleBack = () => {
    const newTabValue = tabValue - 1;
    handleChangeTab(null, newTabValue);
  };

  const updateDetails = async values => {
    try {
      dispatch(setLoading(true));
      const api = AuthenticatedHttpClient();
      await api.put('/workday/update/details', {
        journeyId: selectJourney._id,
        journeyDetails: values,
        company: user.company._id,
      });
      dispatch(setSnackbar(true, 'success', 'Jornada alterada com sucesso'));
    } catch (e) {
      dispatch(setSnackbar(true, 'error', 'Falha ao alterar jornada'));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const dayDetailsFactory = weekDay => ({
    enterTime: weekDay.enterTime ? format(weekDay.enterTime, 'HH:mm') : '',
    exitTime: weekDay.exitTime ? format(weekDay.exitTime, 'HH:mm') : '',
    mainBreak: weekDay.mainBreak || '',
  });

  const formatScaleToSend = values => {
    let scaleValues = { ...values };
    if (
      values.scaleType === SCALE_TYPES['12/36'] ||
      values.scaleType === SCALE_TYPES['24/48'] ||
      values.scaleType === SCALE_TYPES['5x1']
    ) {
      const {
        standardDayScale: { standardDay },
      } = values;
      scaleValues = {
        ...scaleValues,
        standardDayScale: {
          standardDay: dayDetailsFactory(standardDay),
        },
      };

      delete scaleValues.standardWeekScale;
    }

    if (
      values.scaleType === SCALE_TYPES['5x2'] ||
      values.scaleType === SCALE_TYPES['6x1'] ||
      values.scaleType === SCALE_TYPES.CALL_CENTER_30 ||
      values.scaleType === SCALE_TYPES.CALL_CENTER_36
    ) {
      if (!values.standardWeekScale.isEqualTime) {
        const {
          standardWeekScale: {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
          },
        } = values;

        scaleValues = {
          ...scaleValues,
          standardWeekScale: {
            ...values.standardWeekScale,

            monday: dayDetailsFactory(monday),
            tuesday: dayDetailsFactory(tuesday),
            wednesday: dayDetailsFactory(wednesday),
            thursday: dayDetailsFactory(thursday),
            friday: dayDetailsFactory(friday),
            saturday: dayDetailsFactory(saturday),
            sunday: dayDetailsFactory(sunday),
          },
        };

        delete scaleValues.standardDayScale;
        delete scaleValues.standardWeekScale.standardTime;
      } else {
        const {
          standardWeekScale: { standardTime },
        } = values;

        scaleValues = {
          ...scaleValues,
          standardWeekScale: {
            ...values.standardWeekScale,
            standardTime: dayDetailsFactory(standardTime),
          },
        };
        delete scaleValues.standardWeekScale.monday;
        delete scaleValues.standardWeekScale.tuesday;
        delete scaleValues.standardWeekScale.wednesday;
        delete scaleValues.standardWeekScale.thursday;
        delete scaleValues.standardWeekScale.friday;
        delete scaleValues.standardWeekScale.saturday;
        delete scaleValues.standardWeekScale.sunday;
        delete scaleValues.standardDayScale;
      }
    }

    return scaleValues;
  };

  const updateScale = async values => {
    try {
      dispatch(setLoading(true));
      const api = AuthenticatedHttpClient();
      const scaleValues = formatScaleToSend(values);
      await api.put('/workday/update/scale', {
        journeyId: selectJourney._id,
        journeyScale: scaleValues,
      });
      dispatch(setSnackbar(true, 'success', 'Jornada alterada com sucesso'));
    } catch (e) {
      dispatch(setSnackbar(true, 'error', 'Falha ao alterar jornada'));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateBreaks = async values => {
    try {
      dispatch(setLoading(true));
      const { breaks } = values;

      const formattedBreaks = breaks.map(({ description, time, start }) => ({
        description,
        start: format(start, 'HH:mm'),
        time,
      }));
      const api = AuthenticatedHttpClient();
      await api.put('/workday/update/breaks', {
        journeyId: selectJourney._id,
        journeyBreaks: { ...values, breaks: formattedBreaks },
      });
      dispatch(setSnackbar(true, 'success', 'Jornada alterada com sucesso'));
    } catch (e) {
      dispatch(setSnackbar(true, 'error', 'Falha ao alterar jornada'));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleUpdateDetailsAndRedirect = async (
    formikValues,
    { setFieldError },
  ) => {
    try {
      const api = AuthenticatedHttpClient();
      const { data: hasJourneyName } = await api.get('/workday/validate/name', {
        params: {
          id: selectJourney._id,
          name: formikValues.name,
          company: user.company._id,
        },
      });
      if (hasJourneyName) {
        setFieldError('name', 'Já existe uma jornada com esse nome');
        return;
      }
      updateDetails(formikValues);
      history.goBack(-1);
    } catch (e) {
      dispatch(setSnackbar(true, 'error', 'Falha ao alterar jornada'));
    }
  };
  const handleUpdateScalesAndRedirect = (formikValues, { setSubmitting }) => {
    const validatedJourney = validateJourney(formikValues);
    if (!validatedJourney.valid) {
      dispatch(setSnackbar(true, 'error', validatedJourney.message));
      setSubmitting(false);
      return;
    }
    const formattedScaleToSend = formatScaleToSend(formikValues);
    const scale = {
      type: formattedScaleToSend.scaleType,
      times:
        formattedScaleToSend.standardDayScale ||
        formattedScaleToSend.standardWeekScale,
    };

    const validatedBreaks = validateBreaks(breaksInitial.breaks, scale);
    if (!validatedBreaks.valid) {
      setSubmitting(false);
      setIsModalOpen(true);
      setScaleValuesWhenAcceptedChange(formattedScaleToSend);
      return;
    }
    updateScale(formikValues);
    history.goBack(-1);
  };

  const updateScaleAndBreaks = async () => {
    try {
      const newBreaks = {
        numberOfBreaks: '0Breaks',
        breaks: [],
      };
      const api = AuthenticatedHttpClient();
      await api.put('/workday/update/scaleAndBreaks', {
        journeyId: selectJourney._id,
        journeyBreaks: newBreaks,
        journeyScale: scaleValuesWhenAcceptedChange,
      });
      dispatch(setSnackbar(true, 'success', 'Jornada alterada com sucesso'));
    } catch (e) {
      dispatch(setSnackbar(true, 'error', 'Falha ao alterar jornada'));
    }
  };

  const acceptUpdateScaleAndBreaksAndRedirect = () => {
    updateScaleAndBreaks();
    history.goBack(-1);
  };

  const handleUpdateBreaksAndRedirect = (formikValues, { setSubmitting }) => {
    const scale = {
      type: selectJourney.scaleType,
      times: selectJourney.standardDayScale || selectJourney.standardWeekScale,
    };

    const validatedBreaks = validateBreaks(formikValues.breaks, scale);

    if (!validatedBreaks.valid) {
      dispatch(setSnackbar(true, 'error', validatedBreaks.message));
      setSubmitting(false);
      return;
    }

    updateBreaks(formikValues);
    history.goBack(-1);
  };

  return (
    <Box p={3}>
      <StyledButtonBase onClick={() => history.go(-1)}>
        <img src={Return} alt="" />
      </StyledButtonBase>

      <Box mt={2}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h3">{selectJourney.name}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography fontSize="12px">
              INICIADO EM:{' '}
              {format(new Date(selectJourney.createdAt), 'dd/MM/yyyy')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Paper square elevation={1}>
          <Tabs value={tabValue} onChange={handleChangeTab}>
            <Tab label="DETALHES DA JORNADA" index={0} />
            <Tab label="ESCALA" index={1} />
            <Tab label="PAUSAS" index={2} />
          </Tabs>
          <JourneyDetails
            value={tabValue}
            index={0}
            handleSaveJourneyDetails={handleUpdateDetailsAndRedirect}
            initialValues={journeyDetailsInitialValues}
            isUpdate
          />
          <Scale
            value={tabValue}
            index={1}
            handleSaveScale={handleUpdateScalesAndRedirect}
            initialValues={scaleInitialValues}
            handleBack={handleBack}
            isUpdate
          />
          <Breaks
            value={tabValue}
            index={2}
            handleSubmitJourney={handleUpdateBreaksAndRedirect}
            initialValues={breaksInitialValues}
            handleBack={handleBack}
            isUpdate
          />
        </Paper>
      </Box>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogContent>
          <ConfirmChangeModal
            title="AVISO"
            handleClose={() => setIsModalOpen(false)}
            handleConfirmChange={acceptUpdateScaleAndBreaksAndRedirect}
          />
        </DialogContent>
      </Modal>
    </Box>
  );
};

EditJourney.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default EditJourney;
