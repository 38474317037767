import { Box, DialogContent, Grid, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RefusedStepModal, TabPanel, Typography } from '../../../components';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { STEPS } from '../../../utils/enums';
import formatCpf from '../../../utils/formatCpf';
import ApprovalStep from '../ApprovalStep';
import {
  ContainerInput,
  DependentContainer,
  StyledButtonFail,
  StyledButtonPass,
  StyledTextField,
  StyledTypography,
  SubTitleForm,
  TabContainer,
} from '../styles';

const FamilyData = props => {
  const {
    value,
    index,
    stepStatus,
    data,
    setUser,
    handleChangeTab,
    ...other
  } = props;
  const [familyDataStatus, setFamilyDataStatus] = useState({});
  const [openRefusedStepModal, setOpenRefusedStepModal] = useState(false);
  const [familyData, setFamilyData] = useState({
    maritalStatus: '',
    mothersName: '',
    fathersName: '',
    hasDependents: false,
    dependents: [
      {
        dependentName: '',
        dependentType: '',
        dependentbirthDate: '',
        dependentCpf: '',
      },
    ],
  });

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();

  useEffect(() => {
    if (Object.keys(data).length) {
      if (data.dependents.length > 0) {
        const dependentsFormated = data.dependents.map(dependent => ({
          ...dependent,
          dependentbirthDate: new Date(
            dependent.dependentbirthDate,
          ).toLocaleDateString('pt-br'),
        }));

        setFamilyData({
          ...data,
          dependents: dependentsFormated,
        });
      } else {
        setFamilyData(data);
      }
    }
    if (stepStatus) {
      setFamilyDataStatus(stepStatus);
    }
  }, [data, stepStatus]);

  const handleApproveStatus = useCallback(async () => {
    try {
      dispatch(setLoading(true));

      const api = AuthenticatedHttpClient();
      const response = await api.put(`users/update-step/${collaboratorId}`, {
        stepName: STEPS.FAMILY_DATA,
        status: 'approved',
      });

      setUser(response.data);
      dispatch(setSnackbar(true, 'success', 'Dados aprovados'));
      handleChangeTab(null, value + 1);
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Falha ao aprovar'));
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch, collaboratorId, setUser, value, handleChangeTab]);

  const handleCloseModal = useCallback(
    userUpdated => {
      setOpenRefusedStepModal(false);
      if (userUpdated) {
        setUser(userUpdated);
      }
    },
    [setUser],
  );

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Box
          component={Grid}
          container
          alignItems="center"
          justify="space-between"
          spacing={2}
          p={1}
        >
          <SubTitleForm>Estado civil e Filiação</SubTitleForm>

          <ContainerInput item lg={3}>
            <StyledTextField
              name="maritalStatus"
              label="Estado civil"
              variant="filled"
              fullWidth
              value={familyData.maritalStatus}
              disabled
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="mothersName"
              label="Nome da mãe"
              variant="filled"
              fullWidth
              value={familyData.mothersName}
              disabled
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="fathersName"
              label="Nome do pai"
              variant="filled"
              fullWidth
              value={familyData.fathersName}
              disabled
            />
          </ContainerInput>
          <ContainerInput item lg={3} />

          <SubTitleForm>Dependentes</SubTitleForm>
          <Box display="flex" flexDirection="column" width="600px" mb={2}>
            {familyData.hasDependents ? (
              familyData.dependents.map(dependent => (
                <DependentContainer key={dependent.dependentCpf}>
                  <Box display="flex" flexDirection="column">
                    <Typography opacity="0.5" fontSize="14px">
                      {dependent.dependentName}
                    </Typography>
                    <Box display="flex" flexDirection="row">
                      <Typography
                        opacity="0.5"
                        fontSize="12px"
                        fontWeight={300}
                        mr="16px"
                      >
                        Tipo: {dependent.dependentType}
                      </Typography>
                      <Typography
                        opacity="0.5"
                        fontSize="12px"
                        fontWeight={300}
                        mr="16px"
                      >
                        Data de nascimento: {dependent.dependentbirthDate}
                      </Typography>
                      <Typography
                        opacity="0.5"
                        fontSize="12px"
                        fontWeight={300}
                        mr="16px"
                      >
                        CPF: {formatCpf(dependent.dependentCpf)}
                      </Typography>
                    </Box>
                  </Box>
                </DependentContainer>
              ))
            ) : (
              <Typography
                fontSize="18px"
                fontWeight="bold"
                opacity={0.5}
                mt="16px"
                ml="8px"
              >
                Sem dependentes
              </Typography>
            )}
          </Box>
        </Box>
        {(familyDataStatus.status === 'approved' ||
          familyDataStatus.status === 'disapproved') && (
          <ApprovalStep
            status={familyDataStatus.status}
            reason={familyDataStatus.reason}
          />
        )}
        {familyDataStatus.status === 'underAnalysis' && (
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <StyledButtonFail onClick={() => setOpenRefusedStepModal(true)}>
              <StyledTypography color="error">REPROVAR</StyledTypography>
            </StyledButtonFail>
            <StyledButtonPass onClick={handleApproveStatus}>
              <StyledTypography color="secondary">APROVAR</StyledTypography>
            </StyledButtonPass>
          </Box>
        )}
      </TabContainer>
      <Modal open={openRefusedStepModal} onClose={handleCloseModal}>
        <DialogContent>
          <RefusedStepModal
            stepTitle="Dados da família"
            stepName={STEPS.FAMILY_DATA}
            handleClickOnClose={handleCloseModal}
          />
        </DialogContent>
      </Modal>
    </TabPanel>
  );
};

FamilyData.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  stepStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
};

FamilyData.defaultProps = {
  data: {},
};

export default FamilyData;
