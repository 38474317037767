/* eslint-disable react/jsx-curly-newline */
import { Box, Button, Grid } from '@material-ui/core';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { DateInput, PhoneInput, TabPanel } from '../../../components';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import {
  ContainerInput,
  StyledCheckbox,
  SubTitleForm,
  TabContainer,
} from '../styles';

const PersonalData = props => {
  const { value, index, data, userInfo, setUser, ...other } = props;
  const [initialValues, setInitialValues] = useState({
    fullName: '',
    email: '',
    phone: '',
    isWhatsApp: false,
    nationality: '',
    state: null,
    cityOfBirth: null,
    birthDate: '',
    gender: '',
    raceColor: '',
    deficiency: '',
  });

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();

  useEffect(() => {
    async function getUFS() {
      try {
        const response = await axios.get(
          'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
        );

        const sortedOptions = response.data.sort((stateA, stateB) =>
          stateA.sigla > stateB.sigla ? 1 : -1,
        );

        const ufInitials = sortedOptions.map(uf => ({
          label: uf.nome,
          value: uf.sigla,
        }));

        if (Object.keys(userInfo).length && Object.keys(data).length) {
          const userState =
            ufInitials.find(uf => uf.value === data.state)?.label || data.state;
          setInitialValues({
            ...userInfo,
            ...data,
            state: userState,
            birthDate: new Date(data.birthDate).toLocaleDateString('pt-br'),
          });
        }
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao buscar Estados'));
      }
    }

    getUFS();
  }, [userInfo, data, dispatch]);

  const handleSubmitPersonalData = useCallback(
    async (values, { setSubmitting }) => {
      try {
        dispatch(setLoading(true));

        const { email, phone, isWhatsApp } = values;

        const api = AuthenticatedHttpClient();

        const user = {
          email,
          phone,
          isWhatsApp: JSON.parse(isWhatsApp),
        };

        const response = await api.put(`users/${collaboratorId}`, {
          ...user,
        });

        setUser(response.data);
        dispatch(setSnackbar(true, 'success', 'Dados alterados'));
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possível alterar os dados'),
        );
      } finally {
        dispatch(setLoading(false));
        setSubmitting(false);
      }
    },
    [dispatch, collaboratorId, setUser],
  );

  const personalDataSchema = Yup.object().shape({
    email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
    phone: Yup.string()
      .required('Campo obrigatório')
      .min(10, 'Telefone inválido')
      .max(13, 'Telefone inválido'),
  });

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmitPersonalData}
          validationSchema={personalDataSchema}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <Box
                component={Grid}
                container
                alignItems="center"
                justify="space-between"
                spacing={2}
                p={1}
              >
                <SubTitleForm>Dados pessoais</SubTitleForm>

                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="phone"
                    label="Telefone ou Celular"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      inputComponent: PhoneInput,
                    }}
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="email"
                    label="E-mail"
                    variant="filled"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    disabled
                    name="gender"
                    label="Gênero"
                    variant="filled"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    disabled
                    name="raceColor"
                    label="Cor ou raça"
                    variant="filled"
                    fullWidth
                  />
                </ContainerInput>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  width={1}
                  mt="-8px"
                >
                  <Field
                    component={StyledCheckbox}
                    name="isWhatsApp"
                    color="primary"
                    type="checkbox"
                    Label={{
                      label: 'Whatsapp',
                    }}
                  />
                </Box>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="nationality"
                    label="Nacionalidade"
                    variant="filled"
                    disabled
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    name="state"
                    disabled
                    component={TextField}
                    label="Estado que nasceu"
                    variant="filled"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    name="cityOfBirth"
                    component={TextField}
                    label="Cidade nascimento"
                    variant="filled"
                    disabled
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    disabled
                    name="birthDate"
                    label="Data nascimento"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      inputComponent: DateInput,
                    }}
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    disabled
                    name="deficiency"
                    label="Possui deficiência"
                    variant="filled"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={3} />
                <Box width={1} display="flex" justifyContent="flex-end" my={2}>
                  <Button
                    type="submit"
                    color="secondary"
                    disabled={isSubmitting}
                  >
                    ALTERAR DADOS
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </TabContainer>
    </TabPanel>
  );
};

PersonalData.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
};

PersonalData.defaultProps = {
  data: {},
};

export default PersonalData;
