import styled, { css } from 'styled-components';
import { Grid, Typography, FormControlLabel } from '@material-ui/core';

export const StyledTypography = styled(Typography)`
  opacity: 0.5;
  ${props => css`
    font-size: ${props.fontSize};
    font-weight: ${props.fontWeight};
    margin: ${props.m};
    margin-top: ${props.mt};
    margin-right: ${props.mr};
    margin-bottom: ${props.mb};
    margin-left: ${props.ml};
  `}
`;

export const DivCheckbox = styled.div`
  width: 90%;
  height: 70px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 20px;
  }
`;

export const StyledGridButton = styled(Grid)`
  margin-top: 51px;
  margin-bottom: 23px;
`;
