import { Box, Button, Modal } from '@material-ui/core';
import { AccountBox } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Return from '../../../assets/arrow-left.svg';
import CloseX from '../../../assets/CloseX.svg';
import { Grid, Tab, Tabs, Typography } from '../../../components';
import getFileUrlFor from '../../../utils/getFIleUrlFor';
import {
  ImageContainer,
  StyledButtonBase,
  StyledButtonModalOpen,
  StyledDivModal,
  StyledDivModalClose,
  StyledImgExpanded,
  TabsPaper,
  UserInfo,
} from './styles';
import RegisterPoints from './Tabs/RegisterPoints';

const PointMirror = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    state: { user },
  } = location;

  const [isPhotoExpanded, setIsPhotoExpanded] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const handleOpenPhotoExpanded = () => {
    setIsPhotoExpanded(true);
  };

  const handleClosePhotoExpanded = () => {
    setIsPhotoExpanded(false);
  };

  const photoExpanded = (
    <StyledDivModal>
      <StyledDivModalClose>
        <Button onClick={handleClosePhotoExpanded}>
          <img src={CloseX} alt="" />
        </Button>
      </StyledDivModalClose>
      {user.profilePicture && (
        <StyledImgExpanded
          src={getFileUrlFor(user.profilePicture.key)}
          alt=""
        />
      )}
    </StyledDivModal>
  );

  return (
    <Box p={3}>
      <StyledButtonBase onClick={() => history.goBack()}>
        <img src={Return} alt="" />
      </StyledButtonBase>
      <Grid container alignItems="center" mt="8px">
        {user._id && (
          <UserInfo>
            {user.profilePicture ? (
              <StyledButtonModalOpen onClick={handleOpenPhotoExpanded}>
                <ImageContainer
                  image={getFileUrlFor(user.profilePicture.key)}
                />
              </StyledButtonModalOpen>
            ) : (
              <AccountBox color="primary" fontSize="large" />
            )}
            <Modal open={isPhotoExpanded} onClose={handleClosePhotoExpanded}>
              {photoExpanded}
            </Modal>
          </UserInfo>
        )}

        <Grid item ml="16px" mt="16px" xs={10}>
          <Typography fontWeight="bold">{user.fullName}</Typography>
          <Grid container>
            <Grid item xs={2}>
              <Typography fontSize="12px">
                JORNADA: {user.workday.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TabsPaper square elevation={1}>
        <Tabs value={tabValue} onChange={handleChangeTab}>
          <Tab label="REGISTROS DE PONTOS" index={0} />
        </Tabs>
        <RegisterPoints value={tabValue} index={0} />
      </TabsPaper>
    </Box>
  );
};

export default PointMirror;
