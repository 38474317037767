const countryList = [
  'Áustria',
  'Afeganistão',
  'Albânia',
  'Alemanha',
  'Andorra',
  'Angola',
  'Anguila',
  'Antártida',
  'Antígua e Barbuda',
  'Argentina',
  'Argélia',
  'Armênia',
  'Aruba',
  'Arábia Saudita',
  'Austrália',
  'Azerbaijão',
  'Bahamas',
  'Bahrein',
  'Bangladesh',
  'Barbados',
  'Belize',
  'Benin',
  'Bermudas',
  'Bielorrússia',
  'Bolívia',
  'Bonaire',
  'Botsuana',
  'Brasil',
  'Brunei',
  'Bulgária',
  'Burkina Faso',
  'Burundi',
  'Butão',
  'Bélgica',
  'Bósnia e Herzegovina',
  'Cabo Verde',
  'Camarões',
  'Camboja',
  'Canadá',
  'Catar',
  'Cazaquistão',
  'Chade',
  'Chile',
  'China',
  'Chipre',
  'Colômbia',
  'Comores',
  'Congo',
  'Coreia do Norte',
  'Coreia do Sul',
  'Costa Rica',
  'Costa do Marfim',
  'Croácia',
  'Cuba',
  'Curaçao',
  'Dinamarca',
  'Djibuti',
  'Dominica',
  'Egito',
  'El Salvador',
  'Emirados árabes Unidos',
  'Equador',
  'Eritreia',
  'Eslováquia',
  'Eslovênia',
  'Espanha',
  'Estados Unidos',
  'Estônia',
  'Etiópia',
  'Fiji',
  'Filipinas',
  'Finlândia',
  'França',
  'Gabão',
  'Gana',
  'Geórgia',
  'Gibraltar',
  'Granada',
  'Groelândia',
  'Grécia',
  'Guadalupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guiana',
  'Guiana Francesa',
  'Guiné',
  'Guiné Equatorial',
  'Guiné-Bissau',
  'Gâmbia',
  'Haiti',
  'Holanda',
  'Honduras',
  'Hong Kong',
  'Hungria',
  'Ilha Bouvet',
  'Ilha Christmas',
  'Ilha Heard e Ilhas McDonald',
  'Ilha Norfolk',
  'Ilha de Man',
  'Ilhas Cayman',
  'Ilhas Cocos',
  'Ilhas Cook',
  'Ilhas Faroé',
  'Ilhas Geórgias do Sul e Sandwich do Sul',
  'Ilhas Malvinas',
  'Ilhas Marianas',
  'Ilhas Marshall',
  'Ilhas Menores Distantes dos Estados Unidos',
  'Ilhas Pitcairn',
  'Ilhas Salomão',
  'Ilhas Turcas e Caicos',
  'Ilhas Virgens Americanas',
  'Ilhas Virgens Britânicas',
  'Ilhas de Aland',
  'Índia',
  'Indonésia',
  'Iraque',
  'Irlanda',
  'Irã',
  'Islândia',
  'Israel',
  'Itália',
  'Iêmen',
  'Jamaica',
  'Japão',
  'Jersey',
  'Jordânia',
  'Kiribati',
  'Kosovo',
  'Kuwait',
  'Laos',
  'Lesoto',
  'Letônia',
  'Libéria',
  'Liechtenstein',
  'Lituânia',
  'Luxemburgo',
  'Líbano',
  'Líbia',
  'Macau',
  'Macedônia',
  'Madagascar',
  'Malawi',
  'Maldivas',
  'Mali',
  'Malta',
  'Malásia',
  'Marrocos',
  'Martinica',
  'Mauritânia',
  'Maurício',
  'Mayotte',
  'Micronésia',
  'Moldávia',
  'Mongólia',
  'Montenegro',
  'Montserrat',
  'Moçambique',
  'Myanmar',
  'México',
  'Mônaco',
  'Namíbia',
  'Nauru',
  'Nepal',
  'Nicarágua',
  'Nigéria',
  'Niue',
  'Noruega',
  'Nova Caledônia',
  'Nova Zelândia',
  'Níger',
  'Omã',
  'Palau',
  'Palestina',
  'Panamá',
  'Papua Nova Guiné',
  'Paquistão',
  'Paraguai',
  'Peru',
  'Polinésia Francesa',
  'Polônia',
  'Porto Rico',
  'Portugal',
  'Quirguistão',
  'Quênia',
  'RD Congo',
  'Reino Unido',
  'República Centro-Africana',
  'República Dominicana',
  'República Sul-Africana',
  'República Tcheca',
  'Reunião',
  'Romênia',
  'Ruanda',
  'Rússia',
  'Saara Ocidental',
  'Saint Martin',
  'Saint-Pierre e Miquelon',
  'Samoa',
  'Samoa Americana',
  'San Marino',
  'Santa Helena',
  'Santa Lúcia',
  'Seicheles',
  'Senegal',
  'Serra Leoa',
  'Singapura',
  'Sint Maarten',
  'Somália',
  'Sri Lanka',
  'Suazilândia',
  'Sudão',
  'Sudão do Sul',
  'Suriname',
  'Suécia',
  'Suíça',
  'Svalbard',
  'São Bartolomeu',
  'São Cristóvão e Neves',
  'São Tomé e Príncipe',
  'São Vicente e Granadinas',
  'Sérvia',
  'Síria',
  'Tailândia',
  'Taiwan',
  'Tajiquistão',
  'Tanzânia',
  'Terras Austrais e Antárticas Francesas',
  'Território Britânico do Oceano íÍdico',
  'Timor Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad e Tobago',
  'Tunísia',
  'Turcomenistão',
  'Turquia',
  'Tuvalu',
  'Ucrânia',
  'Uganda',
  'Uruguai',
  'Uzbequistão',
  'Vanuatu',
  'Vaticano',
  'Venezuela',
  'Vietnã',
  'Wallis e Futuna',
  'Zimbabwe',
  'Zâmbia',
];

export default countryList;
