import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from '@material-ui/core';

import formatPhone from '../../utils/formatPhone';

import Typography from '../Typography';

const UserInfo = ({ userData, setValue }) => {
  const [isWhatsApp, setIsWhatsapp] = useState(userData.isWhatsApp);

  const handleChange = useCallback(
    event => {
      setIsWhatsapp(event.target.value);
      setValue(event.target.value);
    },
    [setValue],
  );

  return (
    <Box
      component={Paper}
      elevation={4}
      p={3}
      mb={2}
      display="flex"
      justifyContent="space-between"
      style={{ opacity: 0.5 }}
    >
      <Box>
        <Typography fontWeight={300} fontSize="12px">
          Nome completo
        </Typography>
        <Typography fontWeight="bold" fontSize="18px" mt="8px">
          {userData.fullName}
        </Typography>
      </Box>

      <Box>
        <Typography fontWeight={300} fontSize="12px">
          E-mail
        </Typography>
        <Typography fontWeight="bold" fontSize="18px" mt="8px">
          {userData.email}
        </Typography>
      </Box>

      <Box>
        <Typography fontWeight={300} fontSize="12px">
          Telefone ou celular
        </Typography>
        <Typography fontWeight="bold" fontSize="18px" mt="8px">
          {formatPhone(userData.phone)}
        </Typography>
      </Box>

      <Box>
        <FormControl component="fieldset" required>
          <FormLabel style={{ fontSize: '12px', fontWeight: 300 }}>
            Este número é WhatsApp?
          </FormLabel>
          <RadioGroup
            name="isWhatsapp"
            value={isWhatsApp}
            onChange={handleChange}
            row
            color="primary"
          >
            <FormControlLabel value="true" control={<Radio />} label="Sim" />
            <FormControlLabel value="false" control={<Radio />} label="Não" />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

UserInfo.propTypes = {
  userData: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    isWhatsApp: PropTypes.string,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
};

export default UserInfo;
