import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { Typography } from '../../../../../components/index';

const Additional = ({ type, context, unhealthy, percentage }) => {
  return (
    <Box
      component={Grid}
      item
      xs={12}
      bgcolor="#F8F8F8"
      color="secondary"
      px={2}
      py={2}
    >
      {unhealthy ? (
        <>
          <Typography fontWeight="bold" fontSize="16px">
            {type}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              mt="5px"
              fontWeight="300"
              fontSize="14px"
              opacity="0.5"
              width="82%"
            >
              {context}
            </Typography>
            <Typography fontWeight="bold" fontSize="18px">
              Porcentagem: {percentage}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Typography fontWeight="bold" fontSize="16px">
            {type}
          </Typography>
          <Typography mt="5px" fontWeight="300" fontSize="14px" opacity="0.5">
            {context}
          </Typography>
        </>
      )}
    </Box>
  );
};

Additional.defaultProps = {
  type: false,
  context: false,
  unhealthy: false,
  percentage: false,
};

Additional.propTypes = {
  type: PropTypes.node,
  context: PropTypes.node,
  unhealthy: PropTypes.bool,
  percentage: PropTypes.node,
};

export default Additional;
