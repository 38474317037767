import { AuthenticatedHttpClient } from '../services/api';
import { ACTIVITY_PERIOD } from './enums';

export default async function getActivityFieldData(
  userInfo,
  dateType,
  activityType,
) {
  const api = AuthenticatedHttpClient();
  let activity;
  try {
    const { data: activeActivity } = await api.get(`/activity/${userInfo._id}`);

    if (activeActivity) {
      activity = activeActivity;
    }

    if (activity.period === ACTIVITY_PERIOD.ONE_DAY) {
      return new Date(activity.date).toLocaleDateString('pt-br');
    }
    if (
      activity.period === ACTIVITY_PERIOD.MORE_THAN_ONE_DAY &&
      activity.withoutForecastOfEnding &&
      dateType === 'finalDate'
    ) {
      return 'Sem previsão';
    }
    const formatedDate = new Date(activity[dateType]).toLocaleDateString(
      'pt-br',
    );

    const hiringDataMap = {
      startDate: 'beginningOfFirstPeriod',
      finalDate: 'endOfSecondPeriod',
    };

    const hiringDataDateType = hiringDataMap[dateType];

    switch (activityType) {
      case 'WORK_EXPERIENCE':
        return new Date(
          userInfo.hiringData[hiringDataDateType],
        ).toLocaleDateString('pt-br');
      case 'HIRED':
        return '--';
      default:
        return formatedDate;
    }
  } catch (e) {
    return '--';
  }
}
