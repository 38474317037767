import DateFnsUtils from '@date-io/date-fns';
import { Divider, Grid, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardTimePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React from 'react';
import Clock from '../../../../assets/Clock.svg';
import { Typography, Uploader } from '../../../../components';
import { ALERT_TYPES, REQUEST_CHANGES } from '../../../../utils/enums';
import { StyledGrid } from '../styles';

const AlertInfo = props => {
  const { reasonOptions, setAlertFile, values, alertType } = props;
  const isAttachmentNeeded =
    values.reason === REQUEST_CHANGES.MEDICAL_CERTIFICATE ||
    values.reason === REQUEST_CHANGES.PARTIAL_MEDICAL_CERTIFICATE;

  const isPendent = alertType === ALERT_TYPES.PENDENT;

  const isPendentAndPointAdjust =
    alertType === ALERT_TYPES.PENDENT &&
    values.reason === REQUEST_CHANGES.POINTS_ADJUST;

  const isPartialMedicalCertificate =
    values.reason === REQUEST_CHANGES.PARTIAL_MEDICAL_CERTIFICATE;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <>
        <StyledGrid item lg={6}>
          <Field
            component={TextField}
            name="date"
            label="Data"
            variant="filled"
            fullWidth
            disabled
          />
        </StyledGrid>
        <StyledGrid item lg={6}>
          <Field
            component={TextField}
            name="alertDescription"
            label="Alerta"
            variant="filled"
            fullWidth
            disabled
          />
        </StyledGrid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <StyledGrid item lg={6}>
          <Field
            component={TextField}
            select
            name="reason"
            label="Motivo"
            variant="filled"
            fullWidth
          >
            {reasonOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </StyledGrid>

        {isPendent && (
          <StyledGrid item lg={6}>
            <Field
              component={TextField}
              name="point"
              label="Ponto"
              variant="filled"
              fullWidth
              disabled
            />
          </StyledGrid>
        )}
        {!isPendent && <StyledGrid item lg={6} />}
        {isPendentAndPointAdjust && (
          <StyledGrid item lg={12}>
            <Field
              component={KeyboardTimePicker}
              keyboardIcon={<img src={Clock} alt="" />}
              placeholder="00:00"
              name="registerTime"
              label="Informe a hora deseja registrar"
              inputVariant="filled"
              cancelLabel="CANCELAR"
              fullWidth
              invalidDateMessage="Horário Invalido"
              ampm={false}
            />
          </StyledGrid>
        )}

        {isPartialMedicalCertificate && (
          <>
            <StyledGrid item lg={6}>
              <Field
                component={KeyboardTimePicker}
                keyboardIcon={<img src={Clock} alt="" />}
                placeholder="00:00"
                name="partialMedicalCertificateStartHour"
                label="Início do horário de validade do atestado parcial"
                inputVariant="filled"
                cancelLabel="CANCELAR"
                fullWidth
                invalidDateMessage="Horário Invalido"
                ampm={false}
              />
            </StyledGrid>
            <StyledGrid item lg={6}>
              <Field
                component={KeyboardTimePicker}
                keyboardIcon={<img src={Clock} alt="" />}
                placeholder="00:00"
                name="partialMedicalCertificateEndHour"
                label="Final do horário de validade do atestado parcial"
                inputVariant="filled"
                cancelLabel="CANCELAR"
                fullWidth
                invalidDateMessage="Horário Invalido"
                ampm={false}
              />
            </StyledGrid>
          </>
        )}

        <StyledGrid item lg={12}>
          <Field
            component={TextField}
            name="justification"
            label="Justificativa"
            variant="filled"
            fullWidth
          />
        </StyledGrid>
        {isAttachmentNeeded && (
          <Grid item lg={12}>
            <Typography opacity={0.5} fontSize="14px">
              Anexo (* obrigatório para atestados)
            </Typography>
            <Uploader
              accept="application/pdf"
              dropText="Anexar"
              onFileUploaded={setAlertFile}
            />
          </Grid>
        )}
      </>
    </MuiPickersUtilsProvider>
  );
};

AlertInfo.propTypes = {
  reasonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  setAlertFile: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  alertType: PropTypes.string.isRequired,
};

export default AlertInfo;
