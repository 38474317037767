import { Box } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tab, Tabs, Typography } from '../../components';
import { useAuth } from '../../hooks/auth';
import { setLoading } from '../../redux/ducks/loading';
import { setSnackbar } from '../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../services/api';
import { Container, RequestsCount, TabsPaper } from './styles';
import { BankOfHours, GeneralRecords, Requests } from './Tabs/index';

const RecordPoints = () => {
  const [tabValue, setTabValue] = useState(0);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [
    forceGetCollaboratorsCompTime,
    setForceGetCollaboratorsCompTime,
  ] = useState(false);

  const handleChangeTab = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    async function getNotifications() {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();
        const { data: numberOfNotifications } = await api.get(
          `point-solicitation/notifications/${user.company._id}`,
        );
        setNotificationsCount(numberOfNotifications);
      } catch (e) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possivel obter notificações!'),
        );
      } finally {
        dispatch(setLoading(false));
      }
    }

    getNotifications();
  }, [dispatch, user.company._id]);

  return (
    <Container>
      <Typography variant="h2" mt="16px">
        Registro de pontos
      </Typography>
      <TabsPaper square elevation={1}>
        <Tabs value={tabValue} onChange={handleChangeTab}>
          <Tab
            label={
              notificationsCount !== 0 ? (
                <Box display="flex" alignItems="center">
                  <RequestsCount>{notificationsCount}</RequestsCount>
                  <div className="MuiTabItem-subLabel">Solicitações</div>
                </Box>
              ) : (
                'SOLICITAÇÕES'
              )
            }
            index={0}
          />
          <Tab label="REGISTROS GERAIS" index={1} />
          <Tab label="BANCO DE HORAS" index={2} />
        </Tabs>
        <Requests
          value={tabValue}
          index={0}
          setNotificationsCount={setNotificationsCount}
          setForceGetCollaboratorsCompTime={setForceGetCollaboratorsCompTime}
        />
        <GeneralRecords value={tabValue} index={1} />
        <BankOfHours
          value={tabValue}
          index={2}
          forceGetCollaboratorsCompTime={forceGetCollaboratorsCompTime}
        />
      </TabsPaper>
    </Container>
  );
};

export default RecordPoints;
