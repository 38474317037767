import DateFnsUtils from '@date-io/date-fns';
import { Button, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import OnVacation from '../../../../assets/OnVacation.svg';
import { Grid, ModalSkeleton, Typography } from '../../../../components';
import { useAuth } from '../../../../hooks/auth';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import { ACTIVITY_PERIOD, STATUS_ACTIVE } from '../../../../utils/enums';
import getISOStartOfDayDate from '../../../../utils/getISOStartOfDayDate';
import dayOffSchema from '../../../../validations/dayOffSchema';
import NumberOfDaysInput from '../NumberOfDaysInput';
import { StyledGridButton } from './styles';

const DayOffModal = ({
  handleClickOnClose,
  setUser,
  modalType,
  message,
  modalValue,
}) => {
  const [registeredRest, setRegisteredRest] = useState(false);

  const formInitialValues = {
    startDate: null,
    finalDate: null,
    numberOfDays: '',
    period: '',
    date: null,
  };

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();
  const { user } = useAuth();

  const handleRest = useCallback(
    async (values, { setSubmitting }) => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        const { startDate, finalDate, date, period, numberOfDays } = values;
        const restDetails = {
          user: collaboratorId,
          company: user.company._id,
          type: modalValue,
          period,
          numberOfDays,
        };

        if (period === ACTIVITY_PERIOD.ONE_DAY) {
          const formattedDate = getISOStartOfDayDate(date);
          restDetails.date = formattedDate;
        } else {
          const formattedStartDate = getISOStartOfDayDate(startDate);

          const formattedEndDate = getISOStartOfDayDate(finalDate);
          if (
            new Date(formattedStartDate).getTime() ===
            new Date(formattedEndDate).getTime()
          ) {
            dispatch(
              setSnackbar(
                true,
                'error',
                'A data de inicio e fim não podem ser iguais',
              ),
            );
            return;
          }

          restDetails.startDate = formattedStartDate;
          restDetails.finalDate = formattedEndDate;
        }

        const response = await api.post('activity', restDetails);

        setUser(response.data);
        setRegisteredRest(true);
        dispatch(setSnackbar(true, 'success', message.success));
      } catch (error) {
        dispatch(setSnackbar(true, 'error', error?.response?.data?.message));
      } finally {
        setSubmitting(false);
        dispatch(setLoading(false));
      }
    },
    [collaboratorId, dispatch, setUser, user.company._id, message, modalValue],
  );

  const periodOptions = [
    {
      label: '1 dia',
      value: ACTIVITY_PERIOD.ONE_DAY,
    },
    { label: 'Mais de 1 dia', value: ACTIVITY_PERIOD.MORE_THAN_ONE_DAY },
  ];

  const getModalTitle = () => {
    return registeredRest
      ? `${modalType.toUpperCase()} 'REGISTRADA'  `
      : `REGISTRAR ${modalType.toUpperCase()}`;
  };

  const RegisteredDayOffComponent = () => {
    return (
      <Grid container pa="18px">
        <Grid container justify="center" spacing={5}>
          <Grid mt="50px">
            <Grid item lg={6}>
              <img src={OnVacation} alt="" />
            </Grid>
          </Grid>
          <Grid item lg={6}>
            <Typography mt="50px" variant="h3" fontWeight="bold" align="left">
              {message.success}
            </Typography>
            <Typography opacity="0.4" mt="20px" fontSize="20px">
              {modalValue === STATUS_ACTIVE.WEEKLY_REST ? (
                <span>
                  Informe ao colaborador sobre a <br /> data de descanso
                  semanal!
                </span>
              ) : (
                <span>
                  Informe ao colaborador sobre a <br /> data
                  {modalValue === STATUS_ACTIVE.VACATION
                    ? ` das ${modalType.toLowerCase()}!`
                    : ` da ${modalType.toLowerCase()}!`}
                </span>
              )}
            </Typography>
          </Grid>
        </Grid>
        <StyledGridButton
          container
          justify="flex-end"
          alignContent="space-between"
        >
          <Grid item lg={2}>
            <Button onClick={() => handleClickOnClose(false)}>
              <Typography variant="h3" fontWeight="bold">
                CONFIRMAR
              </Typography>
            </Button>
          </Grid>
        </StyledGridButton>
      </Grid>
    );
  };

  const UnregisteredDayOffComponent = () => {
    return (
      <Grid pa="18px" mt="24px">
        <Grid
          container
          justify="center"
          alignContent="space-between"
          spacing={3}
        />
        <Formik
          initialValues={formInitialValues}
          validationSchema={dayOffSchema}
          onSubmit={handleRest}
        >
          {({ isSubmitting, values }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <Form>
                <Grid
                  container
                  justify="flex-start"
                  alignContent="center"
                  spacing={3}
                >
                  <Grid item lg={12}>
                    <Field
                      component={TextField}
                      select
                      name="period"
                      variant="filled"
                      fullWidth
                      label="Período da Folga"
                      placeholder="Período da Folga"
                    >
                      {periodOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  {values.period === ACTIVITY_PERIOD.ONE_DAY && (
                    <Grid item lg={12}>
                      <Field
                        component={KeyboardDatePicker}
                        name="date"
                        label="Data da folga"
                        inputVariant="filled"
                        cancelLabel="CANCELAR"
                        invalidDateMessage="Data inválida"
                        format="dd/MM/yyyy"
                        fullWidth
                      />
                    </Grid>
                  )}
                  {values.period === ACTIVITY_PERIOD.MORE_THAN_ONE_DAY && (
                    <>
                      <Grid item lg={6}>
                        <Field
                          component={KeyboardDatePicker}
                          name="startDate"
                          label="Data de ínicio"
                          invalidDateMessage="Data inválida"
                          inputVariant="filled"
                          cancelLabel="CANCELAR"
                          format="dd/MM/yyyy"
                          fullWidth
                        />
                      </Grid>

                      <Grid item lg={6}>
                        <Field
                          component={KeyboardDatePicker}
                          name="finalDate"
                          label="Data de término"
                          inputVariant="filled"
                          cancelLabel="CANCELAR"
                          invalidDateMessage="Data inválida"
                          format="dd/MM/yyyy"
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}

                  <NumberOfDaysInput />
                </Grid>

                <StyledGridButton
                  container
                  justify="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button onClick={handleClickOnClose}>
                      <Typography opacity="0.4" fontWeight="bold">
                        CANCELAR
                      </Typography>
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button type="submit" disabled={isSubmitting}>
                      <Typography fontWeight="bold">
                        CONFIRMAR {modalType.toUpperCase()}
                      </Typography>
                    </Button>
                  </Grid>
                </StyledGridButton>
              </Form>
            </MuiPickersUtilsProvider>
          )}
        </Formik>
      </Grid>
    );
  };

  return (
    <ModalSkeleton
      title={getModalTitle()}
      handleClickOnClose={handleClickOnClose}
      maxWidth="750px"
    >
      {registeredRest ? (
        <RegisteredDayOffComponent />
      ) : (
        <UnregisteredDayOffComponent />
      )}
    </ModalSkeleton>
  );
};

DayOffModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  modalValue: PropTypes.string.isRequired,
  message: PropTypes.objectOf(
    PropTypes.shape({
      success: PropTypes.string,
      error: PropTypes.string,
    }),
  ).isRequired,
};

export default DayOffModal;
