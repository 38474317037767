import * as Yup from 'yup';

const unpaidLeaveSchema = Yup.object().shape({
  startDate: Yup.date()
    .required('Campo obrigatório')
    .typeError('Data inválida'),
  finalDate: Yup.date().when('withoutForecastOfEnding', {
    is: true,
    then: Yup.date().nullable(),
    otherwise: Yup.date()
      .min(Yup.ref('startDate'), 'Informe uma data posterior a data de início.')
      .required('Campo obrigatório')
      .typeError('Data inválida'),
  }),
  withoutForecastOfEnding: Yup.boolean().required(),
  reason: Yup.string().required('Informe o motivo'),
});

export default unpaidLeaveSchema;
