import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import truncateString from '../../utils/truncateString';
import { StyledSpan } from './styles';

const CustomTooltip = props => {
  const {
    fullText,
    maxStringLength,
    placement,
    bold,
    underline,
    error,
  } = props;

  const needsTooltip = fullText.length > maxStringLength;

  return needsTooltip ? (
    <Tooltip title={fullText} placement={placement}>
      <StyledSpan bold={bold} underline={underline} error={error}>
        {truncateString(fullText, maxStringLength)}
      </StyledSpan>
    </Tooltip>
  ) : (
    <StyledSpan bold={bold} underline={underline} error={error}>
      {fullText}
    </StyledSpan>
  );
};

CustomTooltip.propTypes = {
  fullText: PropTypes.string.isRequired,
  maxStringLength: PropTypes.number.isRequired,
  placement: PropTypes.string,
  bold: PropTypes.bool,
  underline: PropTypes.bool,
  error: PropTypes.bool,
};

CustomTooltip.defaultProps = {
  placement: 'bottom',
  bold: false,
  underline: false,
  error: false,
};

export default CustomTooltip;
