import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ImgPromotion from '../../../assets/promotionModal.svg';
import { Avatar, ModalSkeleton, Typography } from '../../../components';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import FormattedMoneyPlainText from '../FormattedMoneyComponent';
import { CheckCard } from './styles';

const PromotionModal = ({
  handleClose,
  activePromotion,
  user,
  setActivePromotion,
}) => {
  const [isAgreed, setIsAgreed] = useState(false);

  const dispatch = useDispatch();

  const confirmPromotion = async () => {
    try {
      dispatch(setLoading(true));
      if (!isAgreed) {
        dispatch(
          setSnackbar(true, 'error', 'Por favor, concorde com as informações.'),
        );
        return;
      }
      const api = AuthenticatedHttpClient();
      await api.put(`/promotion/${activePromotion._id}`);
      dispatch(setSnackbar(true, 'success', 'Promoção confirmada'));
      handleClose();
      setActivePromotion(null);
    } catch (e) {
      dispatch(
        setSnackbar(true, 'error', 'Não foi possível confirmar a promoção'),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <ModalSkeleton
      title="PROMOVIDO COM SUCESSO!"
      handleClickOnClose={handleClose}
    >
      <Box m={4}>
        <Grid container spacing={2} alignItems="center" justify="space-around">
          <Grid item xs={6}>
            <img src={ImgPromotion} alt="" />
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction="column"
            alignItems="flex-start"
          >
            <Typography fontSize="18px" fontWeight="bold" mb="10px">
              {`PARABÉNS ${user.fullName}, VOCÊ ACABOU DE SER PROMOVIDO!`}
            </Typography>
            <Typography
              fontSize="16px"
              fontWeight="400"
              opacity="0.5"
              mb="10px"
            >
              Veja abaixo os detalhes da sua promoção e confirme!
            </Typography>
            <Box
              bgcolor="primary.main"
              color="white"
              width="90%"
              borderRadius={10}
              display="flex"
              alignItems="center"
              p={2}
              mt={1}
              mb={2}
            >
              <Avatar src="" text="logo" />
              <Box ml={2}>
                <Typography fontSize="12px" fontWeight="400">
                  Promovido pela
                </Typography>
                <Typography fontSize="22px" fontWeight="bold">
                  {user.company.name}
                </Typography>
              </Box>
            </Box>
            <Box
              bgcolor="primary.main"
              color="white"
              width="90%"
              borderRadius={10}
              display="flex"
              alignItems="center"
              p={3}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography fontSize="16px" fontWeight="bold">
                    Cargo
                  </Typography>
                  <Typography fontSize="16px" fontWeight="300">
                    {activePromotion.jobRole.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize="16px" fontWeight="bold">
                    Departamento
                  </Typography>
                  <Typography fontSize="16px" fontWeight="300">
                    {activePromotion.department.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize="16px" fontWeight="bold">
                    Salário
                  </Typography>
                  <Typography fontSize="16px" fontWeight="300">
                    <FormattedMoneyPlainText value={activePromotion.salary} />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize="16px" fontWeight="bold">
                    Regime trabalhista
                  </Typography>
                  <Typography fontSize="16px" fontWeight="300">
                    {activePromotion.regime}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize="16px" fontWeight="bold">
                    Vale Transporte
                  </Typography>
                  <Typography fontSize="16px" fontWeight="300">
                    <FormattedMoneyPlainText value={activePromotion.vt} />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize="16px" fontWeight="bold">
                    Vale Alimentação
                  </Typography>
                  <Typography fontSize="16px" fontWeight="300">
                    <FormattedMoneyPlainText value={activePromotion.va} />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize="16px" fontWeight="bold">
                    Vale Refeição
                  </Typography>
                  <Typography fontSize="16px" fontWeight="300">
                    <FormattedMoneyPlainText value={activePromotion.vr} />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CheckCard
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    borderRadius={10}
                    color="white"
                  >
                    <Box display="flex" alignItems="center" mx={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isAgreed}
                            onChange={() =>
                              setIsAgreed(prevState => !prevState)
                            }
                          />
                        }
                        color="secondary"
                        label={
                          <Typography fontSize="14px" fontWeight="300">
                            Concordo com as informações apresentadas acima
                          </Typography>
                        }
                      />
                    </Box>
                  </CheckCard>
                </Grid>
              </Grid>
            </Box>
            <Grid container justify="flex-end">
              <Box mt={4}>
                <Button onClick={confirmPromotion}>
                  <Typography fontSize="16px" fontWeight="bold">
                    CONFIRMAR PROMOÇÃO
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ModalSkeleton>
  );
};

PromotionModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  activePromotion: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  setActivePromotion: PropTypes.func.isRequired,
};

export default PromotionModal;
