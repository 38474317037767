import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { mask } from 'remask';
import { TabContainer, ContainerInput, StyledTextField } from '../styles';
import {
  Typography,
  TabPanel,
  Downloader,
  CEPInput,
  CPFInput,
} from '../../../components/index';

const Address = props => {
  const { value, index, stepStatus, data, setUser, ...other } = props;
  const rg = ['99.999-99'];
  const [proofOfResidence, setProofOfResidence] = useState({});
  const [address, setAddress] = useState({
    cep: '',
    city: '',
    complement: '',
    cpfOwner: '',
    neighborhood: '',
    number: '',
    proofOfAddress: false,
    publicPlace: '',
    rgOwner: '',
    state: '',
    type: '',
  });

  useEffect(() => {
    if (Object.keys(data).length) {
      setAddress(data);
      setProofOfResidence(data.receipt);
    }
  }, [data]);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography fontSize="16px" color="secondary" fontWeight="300">
              Endereço do colaborador
            </Typography>
          </Grid>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="cep"
              label="CEP"
              variant="filled"
              fullWidth
              value={address.cep}
              InputProps={{
                inputComponent: CEPInput,
              }}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="type"
              label="Tipo"
              variant="filled"
              fullWidth
              value={address.type}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={6}>
            <StyledTextField
              name="publicPlace"
              label="Longradouro"
              variant="filled"
              fullWidth
              value={address.publicPlace}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="number"
              label="Número"
              variant="filled"
              fullWidth
              value={address.number}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="complement"
              label="Complemento"
              variant="filled"
              fullWidth
              value={address.complement}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="neighborhood"
              label="Bairro"
              variant="filled"
              fullWidth
              value={address.neighborhood}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="city"
              label="Cidade"
              variant="filled"
              fullWidth
              value={address.city}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="state"
              label="Estado"
              variant="filled"
              fullWidth
              value={address.state}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="cpfOwner"
              label="CPF do proprietário"
              variant="filled"
              fullWidth
              value={address.cpfOwner}
              InputProps={{
                inputComponent: CPFInput,
              }}
              disabled
            />
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="proofOfAddress"
                    color="primary"
                    checked={address.proofOfAddress}
                  />
                }
                label={
                  <Typography opacity="0.5" fontSize="13px" fontWeight="300">
                    Comprovante não está em do colaborador
                  </Typography>
                }
              />
            </Grid>
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="rgOwner"
              label="RG do proprietário"
              variant="filled"
              fullWidth
              value={mask(address.rgOwner, rg)}
              disabled
            />
          </ContainerInput>
          <Grid item xs={12}>
            <Typography fontSize="16px" color="secondary" fontWeight="300">
              Comprovante de endereço
            </Typography>
          </Grid>
          <ContainerInput item lg={4} xl={2}>
            <Downloader
              file={{
                key: proofOfResidence.key,
                name: proofOfResidence.name,
                size: proofOfResidence.size,
              }}
            />
          </ContainerInput>
        </Grid>
      </TabContainer>
    </TabPanel>
  );
};

Address.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  stepStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
};

Address.defaultProps = {
  data: {},
};

export default Address;
