import { Button, Table, TableCell } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export const StyledIconButtonBlue = styled(Button)`
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.primary.main};

  transition: opacity 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.8;
  }

  span {
    width: 25px;
    height: 25px;
  }

  img {
    filter: brightness(100);
    width: 15px;
    height: 15px;
  }
`;

export const StyledIconButtonRed = styled(Button)`
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.error.main};
  margin-right: 16px;
  transition: opacity 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.error.main};
    opacity: 0.8;
  }

  span {
    width: 25px;
    height: 25px;
  }

  img {
    filter: brightness(100);
    width: 15px;
    height: 15px;
  }
`;

export const StyledTableCell = styled(({ opacity, ...props }) => (
  <TableCell {...props} />
))`
  opacity: ${props => (props.opacity ? '0.5' : '1')};
  font-weight: 300;
`;

export const TableWithMorePaddingRightForTheLastElement = styled(Table)`
  .MuiTableCell-sizeSmall:last-child {
    padding-right: 57px;
  }
`;
