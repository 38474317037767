import styled from 'styled-components';
import { ButtonBase } from '@material-ui/core';

export const StyledImage = styled.img`
  width: 100%;
  max-width: 450px;
  margin: 24px 0;

  @media screen and (min-width: 1600px) {
    margin: 50px 0;
    max-width: 550px;
  }
`;

export const StyledName = styled.span`
  color: ${({ theme }) => theme.palette.primary.main} !important;
  font-weight: 700;
`;

export const Company = styled.div`
  width: 100%;
  padding: 16px;

  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 6px;

  display: flex;
  align-items: center;

  div + div {
    margin-left: 18px;
  }
`;

export const HiringInfo = styled.div`
  width: 100%;
  padding: 26px;
  margin-top: 24px;

  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 6px;

  display: flex;
  align-items: center;
`;

export const CheckBoxApproval = styled.div`
  width: 100%;
  padding: 0 14px;
  margin-top: 12px;
  color: white;
  font-weight: 300;

  background-color: ${props => props.theme.palette.primary.main};
  border-radius: 6px;

  .MuiCheckbox-root {
    color: white;
  }

  .Mui-checked {
    color: ${props => props.theme.palette.secondary.main};
  }
`;

export const StyledButton = styled(ButtonBase)`
  width: 200px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
  font-size: 12px;
  background-color: white;
  height: 45px;
  border-radius: 23px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  @media screen and (max-width: 1439px) {
    width: 150px;
  }
`;
