import styled, { css } from 'styled-components';
import { Box, Button } from '@material-ui/core';

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    border: none;
    border-radius: 0;
    background: red;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    text-transform: none;
    background: rgba(5, 32, 42, 0.04);
    ${colaborator =>
      colaborator &&
      css`
        opacity: 0.5;
      `}
    ${props =>
      props.$ativo &&
      css`
        background: rgba(5, 32, 42, 0.06);
        opacity: 100%;
      `}
  }
  &.MuiButton-root:hover {
    background: rgba(5, 32, 42, 0.06);
    ${colaborator =>
      colaborator &&
      css`
        opacity: 100%;
      `}
  }

  &.MuiButton-root:focus {
    background: rgba(5, 32, 42, 0.06);
    ${colaborator =>
      colaborator &&
      css`
        opacity: 100%;
      `}
  }
`;

export const AcordionDiv = styled(Box)`
  width: 100%;
  display: flex;
`;
