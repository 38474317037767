import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: "Averta", sans-serif;
    outline: 0;
    box-sizing: border-box;
  }

  body, input {
    color: ${({ theme }) => theme.palette.secondary.main}
  }

  .MuiFilledInput-underline:before {
    border: 0;
  }
  .MuiFilledInput-root,
  .MuiInputBase-adornedEnd,
  .MuiInputBase-adornedStart,
  .MuiFilledInput-adornedEnd,
  .MuiFilledInput-adornedStart {
    background: #f6f8f9;
  }
  .MuiTableCell-root {
    @media screen and (max-width: 1600px) {
      font-size: 12px;
      padding: 8px;
    }
  }
`;

export default GlobalStyle;
