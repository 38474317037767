import React from 'react';
import styled from 'styled-components';
import { Button, TableCell, Typography, Grid } from '@material-ui/core';

export const StyledIconButton = styled(Button)`
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 16px;
  transition: opacity 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.8;
  }

  span {
    width: 25px;
    height: 25px;
  }

  img {
    filter: brightness(100);
    width: 15px;
    height: 15px;
  }
`;

export const StyledTableCell = styled(({ opacity, ...props }) => (
  <TableCell {...props} />
))`
  opacity: ${props => (props.opacity ? '0.5' : '1')};
  font-weight: 300;
`;

export const StyledTypography = styled(Typography)`
  font-size: 10px;
  font-weight: light;
`;

export const ImageStyledGrid = styled(Grid)`
  min-height: 500px;
`;

export const TypoGrid = styled(Grid)`
  height: 48px;
`;
