import styled, { css } from 'styled-components';

export const UploaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 8px;
  width: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
    `}

  ${({ size }) => {
    if (size === 'sm')
      return css`
        max-width: 200px;
      `;
    if (size === 'md')
      return css`
        max-width: 358px;
      `;
    if (size === 'xl')
      return css`
        max-width: 655px;
      `;
    return css`
      max-width: 360px;
    `;
  }}

  span {
    ${({ theme, size }) => css`
      color: ${theme.palette.secondary.main};
      font-weight: 300;
      font-size: ${size === 'sm' || size === 'xl' ? '12px' : '16px'};
      opacity: ${size === 'sm' || size === 'xl' ? 0.5 : 1};
    `}
  }
`;

export const ContainerDropzone = styled.div`
  display: flex;
  align-items: center;
  ${({ size }) => {
    return css`
      justify-content: ${size === 'xl' ? 'flex-start' : 'center'};
    `;
  }}
  padding: 16px;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${({ size }) => {
    if (size === 'sm')
      return css`
        height: 76px;
        margin-top: 8px;
      `;
    if (size === 'md')
      return css`
        height: 218px;
        margin-top: 16px;
        flex-direction: column;
      `;
    if (size === 'xl')
      return css`
        height: 76px;
        margin-top: 8px;
      `;
    return css`
      height: 430px;
      margin-top: 16px;
      flex-direction: column;
    `;
  }}

  ${({ theme }) => css`
    border: 1px dashed ${theme.palette.primary.main};
  `}

  span {
    margin-left: 8px;
    ${({ theme, size }) => css`
      color: ${size === 'sm'
        ? theme.palette.secondary.main
        : theme.palette.primary.main};
      font-weight: ${size === 'sm' || size === 'xl' ? 300 : 400};
      font-size: 12px;
      margin-top: ${size !== 'sm' || size === 'xl' ? '8px' : 0};
      opacity: 0.5;
    `}
  }
  img {
    box-sizing: border-box;
    max-width: ${({ img }) => (img ? '100%' : '100%')};
    max-height: 100%;
  }
`;
