import * as Yup from 'yup';
import validateOnlyLetters from '../utils/validateOnlyLetters';

const generalRecordSchema = Yup.object().shape({
  maritalStatus: Yup.string().required('Campo obrigatório'),
  mothersName: Yup.string()
    .required('Campo obrigatório')
    .test(
      'Validate only letters',
      'Somente letras são aceitas',
      validateOnlyLetters,
    ),
  fathersName: Yup.string().test(
    'Validate only letters',
    'Somente letras são aceitas',
    validateOnlyLetters,
  ),
  hasDependents: Yup.boolean().required('Campo obrigatório'),
});

export default generalRecordSchema;
