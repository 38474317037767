import { Box } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  CollaboratorButton,
  Grid,
  Typography,
  UploaderExam,
} from '../../../../components';
import withCollaboratorSkeleton from '../../../../components/CollaboratorSkeleton';
import { useAuth } from '../../../../hooks/auth';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';

const AdmissionExam = () => {
  const [examRequired, setExamRequired] = useState(false);
  const [uploaderClinical, setUploaderClinical] = useState(null);
  const [uploaderToxicological, setUploaderToxicological] = useState(null);
  const [firstRender, setFirstRender] = useState(true);

  const dispatch = useDispatch();
  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    async function getUserData() {
      try {
        const api = AuthenticatedHttpClient();
        const { data } = await api.get(`users/${user._id}`);
        const { admissionExam, toxicologicalExam, jobRole } = data;
        const { toxicologicalExam: toxicologicalRequired } = jobRole;
        if (toxicologicalRequired) {
          setExamRequired(toxicologicalRequired);
        }
        if (admissionExam) {
          setUploaderClinical(admissionExam);
        }
        if (toxicologicalExam) {
          setUploaderToxicological(toxicologicalExam);
        }
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao buscar exames'));
      } finally {
        if (firstRender) {
          setFirstRender(false);
        }
      }
    }

    getUserData();
  }, [dispatch, user, firstRender]);

  useEffect(() => {
    if (uploaderClinical) {
      if (examRequired) {
        if (uploaderToxicological) {
          history.push('/contract');
        }
      } else {
        history.push('/contract');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  const enableButton = useCallback(() => {
    if (examRequired) {
      if (uploaderClinical && uploaderToxicological) {
        return true;
      }
      return false;
    }
    if (uploaderClinical) {
      return true;
    }
    return false;
  }, [examRequired, uploaderClinical, uploaderToxicological]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      width={1}
      overflow="auto"
      p={5}
    >
      <Typography fontWeight="bold" fontSize="25px" mb="68px">
        Anexar os resultados dos exames admissionais
      </Typography>
      <Typography fontSize="14px" opacity="0.6" mb="20px">
        Exame clínico
      </Typography>

      <UploaderExam
        setFile={setUploaderClinical}
        urlPath="users/admission-exam"
        file={uploaderClinical}
        fieldName="admissionExam"
      />

      {examRequired && (
        <>
          <Box
            component={Grid}
            item
            xs={12}
            display="flex"
            direction="column"
            justifyContent="flex-start"
          >
            <Typography
              fontSize="14px"
              opacity="0.6"
              mb="20px"
              fontWeight="bold"
              mt="20px"
            >
              Para motorista
            </Typography>
            <Typography fontSize="14px" opacity="0.6" mb="20px">
              Caso o cargo que você está sendo admitido seja para trabalhos como
              motorista, é obrigatório a realização de um exame toxicológico
            </Typography>
            <Typography fontSize="14px" opacity="0.6" mb="20px">
              Exame toxicológico
            </Typography>
            <UploaderExam
              setFile={setUploaderToxicological}
              urlPath="users/toxicological-exam"
              file={uploaderToxicological}
              fieldName="toxicologicalExam"
            />
          </Box>
        </>
      )}
      {enableButton() && (
        <Box width={1} display="flex" justifyContent="flex-end" mt="auto">
          <CollaboratorButton
            text="IR PARA CONTRATO"
            component={Link}
            to="/contract"
          />
        </Box>
      )}
    </Box>
  );
};

export default withCollaboratorSkeleton(AdmissionExam);
