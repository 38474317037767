import { ButtonBase, Divider, Grid } from '@material-ui/core';
import { TimelineConnector, TimelineDot, TimelineItem } from '@material-ui/lab';
import styled, { css } from 'styled-components';
import { SITUATION_POINTS } from '../../../../utils/enums';

export const RegisterPointContainer = styled(Grid)`
  padding: 12px;
  width: 100%;
  height: 73px;
  margin-top: 16px;
  background-color: #ffffff;
  border-radius: 6px;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  span {
    margin-left: 8px;
    ${({ theme }) => css`
    color: ${theme.palette.secondary.main};
    font-weight: 300;
    font-size: 12px;
    margin-top: '8px'
    opacity: 0.5;
  `}
  }
`;

export const DividerWithMargin = styled(Divider)`
  margin: 0px 20px;
`;

export const StyledButton = styled(ButtonBase)`
  width: 151px;
  color: white;
  font-weight: 700;
  font-size: 10px;
  background-color: ${({ $isWaiting }) => ($isWaiting ? '#40A6FF' : '#FF6B6B')};
  height: 35px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
`;

export const StyledITimelineItem = styled(TimelineItem)`
  &.MuiTimelineItem-missingOppositeContent:before {
    flex: 0;
    padding: 0;
  }
`;

export const StyledTimelineDot = styled(TimelineDot)`
  border-color: transparent;

  ${({ situation }) => {
    if (situation === SITUATION_POINTS.REGISTERED)
      return css`
        background-color: #40a6ff;
      `;
    if (situation === SITUATION_POINTS.PENDING)
      return css`
        background-color: #ff6b6b;
      `;
    if (situation === SITUATION_POINTS.WAITING)
      return css`
        background-color: #e8e8e8;
      `;
    return css`
      background-color: #e8e8e8;
    `;
  }};
`;

export const StyledTimelineConnector = styled(TimelineConnector)`
  &.MuiTimelineConnector-root {
    width: 2px;
    flex-grow: 1;
    background-color: #bdbdbd;
    opacity: 0.2;
  }
`;
