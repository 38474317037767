import { Box, Button, Table, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export const StyledIconButtonBlue = styled(Button)`
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  transition: opacity 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.8;
  }

  span {
    width: 25px;
    height: 25px;
  }

  img {
    filter: brightness(100);
    width: 15px;
    height: 15px;
  }
`;

export const StyledIconButtonRed = styled(Button)`
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.error.main};
  margin-right: 16px;
  transition: opacity 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.error.main};
    opacity: 0.8;
  }

  span {
    width: 25px;
    height: 25px;
  }

  img {
    filter: brightness(100);
    width: 15px;
    height: 15px;
  }
`;

export const StyledTableCell = styled(({ opacity, ...props }) => (
  <TableCell {...props} />
))`
  opacity: ${props => (props.opacity ? '0.2' : '1')};
  font-weight: ${props => (props.$alert ? 'bold' : '300')};
  color: ${props => (props.$alert ? '#FF6B6B' : '')};
`;

export const TableWithMorePaddingRightForTheLastElement = styled(Table)`
  .MuiTableCell-sizeSmall:last-child {
    padding-right: 57px;
  }
`;
export const TableRowWithFixedHeight = styled(TableRow)`
  height: 45px;
  background-color: ${({ $today }) => ($today ? 'rgba(0, 0, 0, 0.02)' : '')};
`;

export const BoxBackgroundColor = styled(Box)`
  background-color: rgba(0, 0, 0, 0.02);
  height: 21px;
`;

export const TableCellWithNoPadding = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 0;
  }
`;
