import { Box, DialogContent, Modal, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Grid, SelectFilter, TabPanel, Typography } from '../../../components';
import { REQUEST_CHANGES, REQUEST_STATUS } from '../../../utils/enums';
import RequestsTable from '../Components/RequestsTable';
import RequestChangeModal from '../Modals/RequestChangeModal';
import { RequestButton, RequestCard, TabContainer } from '../styles';

const Requests = props => {
  const { value, index, ...other } = props;
  const [newSolicitation, setNewSolicitation] = useState(null);
  const [reason, setReason] = useState(' ');
  const [status, setStatus] = useState(' ');
  const [requestChangeModalState, setRequestChangeModalState] = useState(false);
  const hasMaxWidthOf1323Px = useMediaQuery('(max-width:1323px)');
  const reasonOptions = [
    {
      label: 'MOTIVO',
      value: ' ',
    },
    {
      label: 'Atestado',
      value: REQUEST_CHANGES.MEDICAL_CERTIFICATE,
    },
    // {
    //   label: 'Atestado Parcial',
    //   value: REQUEST_CHANGES.PARTIAL_MEDICAL_CERTIFICATE,
    // },
    {
      label: 'Ajuste de ponto',
      value: REQUEST_CHANGES.POINTS_ADJUST,
    },
    {
      label: 'Compensação de Horas',
      value: REQUEST_CHANGES.HOURS_COMPENSATION,
    },
    {
      label: 'Pedido de Folga',
      value: REQUEST_CHANGES.REQUEST_DAY_OFF,
    },
    // {
    //   label: 'Troca de folga',
    //   value: REQUEST_CHANGES.CHANGE_DAY_OFF,
    // },
  ];

  const statusOptions = [
    {
      label: 'STATUS',
      value: ' ',
    },
    {
      label: 'Em análise',
      value: REQUEST_STATUS.IN_ANALYSIS,
    },
    {
      label: 'Reprovado',
      value: REQUEST_STATUS.DISAPPROVED,
    },
    {
      label: 'Aprovado',
      value: REQUEST_STATUS.APPROVED,
    },
  ];

  const handleCloseRequestsModal = useCallback(solicitationCreated => {
    setRequestChangeModalState(false);
    setNewSolicitation(solicitationCreated);
  }, []);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Box p={3}>
          <Modal
            open={requestChangeModalState}
            onClose={handleCloseRequestsModal}
          >
            <DialogContent>
              <RequestChangeModal
                handleClickOnClose={handleCloseRequestsModal}
              />
            </DialogContent>
          </Modal>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-start"
            spacing={4}
          >
            <Grid item xs={12}>
              <RequestCard
                justify="space-between"
                alignItems="center"
                container
                hasMaxWidthOf1323Px={hasMaxWidthOf1323Px}
                spacing={4}
              >
                <Grid item>
                  <Typography
                    fontSize="16px"
                    textcolor="#343434"
                    fontWeight="700"
                    mb="7px"
                  >
                    Precisa solicitar alguma mudança no seu ponto como troca de
                    folga ou compensação de horas?
                  </Typography>
                  <Typography fontSize="16px" textcolor="#05202A">
                    {hasMaxWidthOf1323Px
                      ? 'Clique no botão a baixo \n "solicitar"\n e informe o motivo e a justificativa para o ajuste'
                      : 'Clique no botão ao lado \n "solicitar"\n e informe o motivo e a justificativa para o ajuste'}
                  </Typography>
                </Grid>
                <Grid item>
                  <RequestButton
                    onClick={() => setRequestChangeModalState(true)}
                  >
                    SOLICITAR
                  </RequestButton>
                </Grid>
              </RequestCard>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={4}>
                <Grid item>
                  <SelectFilter
                    selectOptions={reasonOptions}
                    value={reason}
                    handleChange={reasonValue => setReason(reasonValue)}
                  />
                </Grid>
                <Grid item>
                  <SelectFilter
                    selectOptions={statusOptions}
                    value={status}
                    handleChange={statusValue => setStatus(statusValue)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <RequestsTable
                newPointSolicitation={newSolicitation}
                reason={reason}
                status={status}
              />
            </Grid>
          </Grid>
        </Box>
      </TabContainer>
    </TabPanel>
  );
};

Requests.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default Requests;
