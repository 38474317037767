import { AccordionSummary, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Clock from '../../assets/Clock.svg';
import Contract from '../../assets/contract.svg';
import Import from '../../assets/Import.svg';
import Logout from '../../assets/logout.svg';
import MyData from '../../assets/myData.svg';
import Page1 from '../../assets/Page-1-1.svg';
import Score from '../../assets/score.svg';
import { useAuth } from '../../hooks/auth';
import { setSnackbar } from '../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../services/api';
import truncateString from '../../utils/truncateString';
import Avatar from '../Avatar';
import Typography from '../Typography';
import AcordionItem from './components/AcordionItem';
import {
  ButtonDiv,
  StyledAccordionPanel,
  StyledAccordionPanelDetails,
  StyledDrawer,
} from './styles';

function Sidebar({ colaborator }) {
  const { signOut, user } = useAuth();
  const [userData, setUserData] = useState({});
  const dispatch = useDispatch();
  const { _id: userId } = user;

  useEffect(() => {
    async function getUserData() {
      try {
        const api = AuthenticatedHttpClient();
        const { data } = await api.get(`/users/${userId}`);

        const formattedData = data;

        const formattedAdmissionDate = format(
          new Date(data.admissionDate),
          'dd/MM/yyyy',
        );
        formattedData.admissionDate = formattedAdmissionDate;
        setUserData(formattedData);
      } catch (e) {
        dispatch(setSnackbar(true, 'error', e.message));
      }
    }
    if (colaborator) {
      getUserData();
    }
  }, [dispatch, userId, colaborator]);
  const statusMap = {
    WORK_EXPERIENCE: 'Experiência',
    HIRED: 'Efetivado',
    MEDICAL_CERTIFICATE: 'Atestado Médico',
    INSS_REMOVAL: 'Afastado INSS',
    DAY_OFF: 'Folga',
    VACATION: 'Férias',
    WEEKLY_REST: 'Descanso semanal',
    SUSPENSION: 'Suspendido',
    MATERNITY_LEAVE: 'Licença maternidade',
    PATERNITY_LEAVE: 'Licença paternidade',
    UNPAID_LEAVE: 'Licença não remunerada',
  };
  return (
    <StyledDrawer variant="permanent" anchor="left">
      <Box bgcolor="primary.main" color="white" boxShadow={1}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          mx={2}
          my={2}
        >
          <Avatar src="" text="logo" />
          <Box textOverflow="ellipsis" mx={2}>
            <Typography fontSize="14px" fontWeight="bold">
              {truncateString(user.fullName, 10)}
            </Typography>
            <Typography fontSize="10px" fontWeight="light">
              {truncateString(user.company.name, 12)}
            </Typography>
          </Box>
          <ButtonDiv onClick={signOut}>
            <img src={Logout} alt="Logout" />
          </ButtonDiv>
        </Box>
        {colaborator ? (
          <Box bgcolor="third.dark" color="white" py={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mx={1}
              mt={1}
              mb={1}
            >
              <Box>
                <Typography fontSize="10px" opacity="0.5">
                  Status
                </Typography>
                <Typography fontSize="12px">
                  {`${statusMap[userData.statusActive]}`}
                </Typography>
              </Box>
              <Typography ml="35px" fontSize="10px" opacity="0.5">
                {`ADMISSÃO EM ${userData.admissionDate}`}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box bgcolor="primary.main" color="white" borderTop={1}>
            {/* <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              mx={2}
              mt={1}
              mb={1}
            >
              <ButtonDiv onClick={() => {}}>
                <img src={Gear} alt="Gear" />
                <Typography ml="10px" fontSize="12px" fontWeight="light">
                  Configurações gerais
                </Typography>
              </ButtonDiv>
            </Box> */}
          </Box>
        )}
      </Box>
      <Box>
        {colaborator ? (
          <>
            <AcordionItem
              src={Score}
              link={`/colaborator-home/score/${userId}`}
              text="MEU PONTO"
              colaborator
            />
            <AcordionItem
              src={MyData}
              link={`/colaborator-home/data/${userId}`}
              text="MEUS DADOS"
              colaborator
            />
            <AcordionItem
              src={Contract}
              link={`/colaborator-home/contracts/${userId}`}
              text="CONTRATOS"
              colaborator
            />
          </>
        ) : (
          <>
            <StyledAccordionPanel>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <img src={Page1} alt="" />
                <Typography
                  fontSize="12px"
                  textcolor="#05202A"
                  ml="20px"
                  fontWeight="bold"
                  opacity="0.7"
                >
                  COLABORADORES
                </Typography>
              </AccordionSummary>
              <StyledAccordionPanelDetails>
                <AcordionItem link="/home/admissions" text="ADMISSÃO" />
                <AcordionItem link="/home/active" text="ATIVOS" />
                <AcordionItem link="/home/dismissal" text="DEMISSÃO" />
                <AcordionItem link="/home/career" text="CARGOS" />
                <AcordionItem link="/home/departments" text="DEPARTAMENTOS" />
              </StyledAccordionPanelDetails>
            </StyledAccordionPanel>

            <StyledAccordionPanel>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <img src={Clock} alt="" />
                <Typography
                  fontSize="12px"
                  textcolor="#05202A"
                  ml="20px"
                  fontWeight="bold"
                  opacity="0.7"
                >
                  PONTOS
                </Typography>
              </AccordionSummary>
              <StyledAccordionPanelDetails>
                <AcordionItem
                  link="/home/record-points"
                  text="REGISTRO DE PONTOS"
                />
                <AcordionItem link="/home/journey" text="JORNADAS" />
                <AcordionItem link="/home/holidays" text="FERIADOS" />
                <AcordionItem disabled link="/" text="RELATÓRIOS" />
              </StyledAccordionPanelDetails>
            </StyledAccordionPanel>

            <StyledAccordionPanel disabled>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <img src={Import} alt="" />
                <Typography
                  fontSize="12px"
                  textcolor="#05202A"
                  ml="20px"
                  fontWeight="bold"
                  opacity="0.7"
                >
                  IMPORTAR
                </Typography>
              </AccordionSummary>
              <StyledAccordionPanelDetails>
                <AcordionItem link="/" text="COLABORADORES EM MASSA" />
                <AcordionItem link="/" text="REGISTRO DE PONTOS EM MASSA" />
                <AcordionItem link="/" text="DADOS GERAIS" />
              </StyledAccordionPanelDetails>
            </StyledAccordionPanel>
          </>
        )}
      </Box>
    </StyledDrawer>
  );
}

Sidebar.defaultProps = {
  colaborator: false,
};

Sidebar.propTypes = {
  colaborator: PropTypes.bool,
};

export default Sidebar;
