import React from 'react';
import PropTypes from 'prop-types';

import { StyledTabs } from './styles';

const Tabs = ({ children, ...rest }) => (
  <StyledTabs {...rest} indicatorColor="primary">
    {children}
  </StyledTabs>
);

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tabs;
