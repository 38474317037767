import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  MenuItem,
  Button,
  FormControlLabel,
  Radio,
  FormHelperText,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField, RadioGroup } from 'formik-material-ui';
import { parse, formatISO } from 'date-fns';

import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import shutdownSchema from '../../../validations/shutdown';
import generateDepartureDate from '../../../utils/generateDepartureDate';

import { TabPanel, Grid, Typography, DateInput } from '../../../components';

import { TabContainer, ContainerInput, StyledFormLabel } from '../styles';

const Shutdown = props => {
  const {
    value,
    index,
    data,
    setUser,
    admissionDate: PAdmissionDate,
    ...other
  } = props;
  const [shutDownValues, setShutDownValues] = useState({
    justCause: '',
    dismissalOrigin: '',
    notice: '',
    warningDefinitions: '',
    increasedCompanyTime: '',
    admissionDate: new Date(PAdmissionDate).toLocaleDateString('pt-br'),
    resignationDate: '',
    departureDate: '',
    reason: '',
    observations: '',
  });

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();

  useEffect(() => {
    if (Object.keys(data).length) {
      const {
        justCause,
        dismissalOrigin,
        notice,
        warningDefinitions,
        increasedCompanyTime,
        admissionDate,
        resignationDate,
        departureDate,
        reason,
        observations,
      } = data;

      setShutDownValues({
        justCause: String(justCause),
        dismissalOrigin,
        notice,
        warningDefinitions,
        increasedCompanyTime: String(increasedCompanyTime),
        admissionDate: new Date(admissionDate).toLocaleDateString('pt-br'),
        resignationDate: new Date(resignationDate).toLocaleDateString('pt-br'),
        departureDate: new Date(departureDate).toLocaleDateString('pt-br'),
        reason,
        observations,
      });
    }
  }, [data]);

  const handleChangeShutdown = useCallback(
    async (values, { setSubmitting }) => {
      try {
        dispatch(setLoading(true));

        const {
          admissionDate,
          resignationDate,
          departureDate,
          justCause,
          increasedCompanyTime,
          ...rest
        } = values;

        const api = AuthenticatedHttpClient();

        const parsedAdmissionDate = parse(
          admissionDate,
          'dd/MM/yyyy',
          new Date(),
        );
        const parsedResignationDate = parse(
          resignationDate,
          'dd/MM/yyyy',
          new Date(),
        );
        const parsedDepartureDate = parse(
          departureDate,
          'dd/MM/yyyy',
          new Date(),
        );

        const form = {
          shutDownData: {
            ...rest,
            justCause: JSON.parse(justCause),
            increasedCompanyTime: JSON.parse(increasedCompanyTime),
            admissionDate: formatISO(parsedAdmissionDate),
            resignationDate: formatISO(parsedResignationDate),
            departureDate: formatISO(parsedDepartureDate),
          },
        };

        const response = await api.put(
          `users/dismissal-details/${collaboratorId}`,
          form,
        );

        setUser(response.data);
        dispatch(setSnackbar(true, 'success', 'Dados alterados'));
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possível alterar os dados'),
        );
      } finally {
        dispatch(setLoading(false));
        setSubmitting(false);
      }
    },
    [collaboratorId, dispatch, setUser],
  );

  const handleFields = useCallback((values, setField, field, fieldValue) => {
    if (field === 'notice' && fieldValue === 'indemnifiedNotice') {
      const updatedValues = {
        ...values,
        warningDefinitions: '',
        [field]: fieldValue,
      };

      const formatedDate = generateDepartureDate(updatedValues);

      setField('warningDefinitions', '');
      setField(field, values[field]);
      setField('departureDate', formatedDate);
      return;
    }

    const updatedValues = {
      ...values,
      [field]: fieldValue,
    };

    const formatedDate = generateDepartureDate(updatedValues);

    setField(field, fieldValue);
    setField('departureDate', formatedDate);
  }, []);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Formik
          initialValues={shutDownValues}
          onSubmit={handleChangeShutdown}
          validationSchema={shutdownSchema}
          enableReinitialize
        >
          {({ isSubmitting, values, setFieldValue, errors, touched }) => (
            <Form>
              <Grid container alignItems="center" spacing={2}>
                <Grid item lg={12}>
                  <Typography fontSize="18px" fontWeight="300">
                    Dados do desligamento
                  </Typography>
                </Grid>
                <ContainerInput item lg={3}>
                  <StyledFormLabel component="legend">
                    Justa causa?
                  </StyledFormLabel>
                  <Field component={RadioGroup} name="justCause" row>
                    <FormControlLabel
                      value="true"
                      control={<Radio disabled={isSubmitting} />}
                      label={<Typography fontSize="13px">SIM</Typography>}
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio disabled={isSubmitting} />}
                      label={<Typography fontSize="13px">NÃO</Typography>}
                      disabled={isSubmitting}
                    />
                  </Field>
                  <FormHelperText
                    error={touched.justCause && !!errors.justCause}
                  >
                    {touched.justCause && errors.justCause}
                  </FormHelperText>
                </ContainerInput>

                <ContainerInput item lg>
                  <StyledFormLabel component="legend">
                    Origem da demissão?
                  </StyledFormLabel>
                  <Field component={RadioGroup} name="dismissalOrigin" row>
                    <FormControlLabel
                      value="company"
                      control={<Radio disabled={isSubmitting} />}
                      label={<Typography fontSize="13px">EMPRESA</Typography>}
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value="collaborator"
                      control={<Radio disabled={isSubmitting} />}
                      label={
                        <Typography fontSize="13px">COLABORADOR</Typography>
                      }
                      disabled={isSubmitting}
                    />
                  </Field>
                  <FormHelperText
                    error={touched.dismissalOrigin && !!errors.dismissalOrigin}
                  >
                    {touched.dismissalOrigin && errors.dismissalOrigin}
                  </FormHelperText>
                </ContainerInput>
                <Grid item lg={12}>
                  <Typography fontSize="14px" fontWeight="400">
                    Aviso prévio
                  </Typography>
                </Grid>
                <ContainerInput item>
                  <StyledFormLabel component="legend">Aviso</StyledFormLabel>
                  <Field component={RadioGroup} name="notice" row>
                    <FormControlLabel
                      value="complyNotice"
                      control={<Radio />}
                      label={
                        <Typography fontSize="13px">CUMPRIR AVISO</Typography>
                      }
                      onChange={({ target }) =>
                        handleFields(
                          values,
                          setFieldValue,
                          'notice',
                          target.value,
                        )
                      }
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value="indemnifiedNotice"
                      control={<Radio />}
                      label={
                        <Typography fontSize="13px">
                          AVISO INDENIZADO
                        </Typography>
                      }
                      onChange={({ target }) =>
                        handleFields(
                          values,
                          setFieldValue,
                          'notice',
                          target.value,
                        )
                      }
                      disabled={isSubmitting}
                    />
                  </Field>
                  <FormHelperText error={touched.notice && !!errors.notice}>
                    {touched.notice && errors.notice}
                  </FormHelperText>
                </ContainerInput>

                <ContainerInput item lg>
                  <StyledFormLabel component="legend">
                    Definições do aviso:
                  </StyledFormLabel>
                  <Field component={RadioGroup} name="warningDefinitions" row>
                    <FormControlLabel
                      value="twoHoursEarlier"
                      control={<Radio />}
                      label={
                        <Typography fontSize="13px">
                          SAIR 2 HORAS MAIS CEDO
                        </Typography>
                      }
                      onChange={({ target }) =>
                        handleFields(
                          values,
                          setFieldValue,
                          'warningDefinitions',
                          target.value,
                        )
                      }
                      disabled={
                        isSubmitting || values.notice === 'indemnifiedNotice'
                      }
                    />
                    <FormControlLabel
                      value="sevenDaysBeforeEndDate"
                      control={<Radio />}
                      label={
                        <Typography fontSize="13px">
                          SAIR 7 DIAS ANTES DA DATA FINAL
                        </Typography>
                      }
                      onChange={({ target }) =>
                        handleFields(
                          values,
                          setFieldValue,
                          'warningDefinitions',
                          target.value,
                        )
                      }
                      disabled={
                        isSubmitting || values.notice === 'indemnifiedNotice'
                      }
                    />
                  </Field>
                  <FormHelperText
                    error={
                      touched.warningDefinitions && !!errors.warningDefinitions
                    }
                  >
                    {touched.warningDefinitions && errors.warningDefinitions}
                  </FormHelperText>
                </ContainerInput>

                <Grid item lg={12}>
                  <Typography fontSize="14px" fontWeight="400">
                    Datas e Acréscimos
                  </Typography>
                </Grid>
                <ContainerInput item lg={3}>
                  <StyledFormLabel component="legend">
                    Acréscimo tempo de empresa?
                  </StyledFormLabel>
                  <Field component={RadioGroup} name="increasedCompanyTime" row>
                    <FormControlLabel
                      value="true"
                      control={<Radio disabled={isSubmitting} />}
                      label={<Typography fontSize="13px">SIM</Typography>}
                      onChange={({ target }) =>
                        handleFields(
                          values,
                          setFieldValue,
                          'increasedCompanyTime',
                          target.value,
                        )
                      }
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio disabled={isSubmitting} />}
                      label={<Typography fontSize="13px">NÃO</Typography>}
                      onChange={({ target }) =>
                        handleFields(
                          values,
                          setFieldValue,
                          'increasedCompanyTime',
                          target.value,
                        )
                      }
                      disabled={isSubmitting}
                    />
                  </Field>
                  <FormHelperText
                    error={
                      touched.increasedCompanyTime &&
                      !!errors.increasedCompanyTime
                    }
                  >
                    {touched.increasedCompanyTime &&
                      errors.increasedCompanyTime}
                  </FormHelperText>
                </ContainerInput>

                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="admissionDate"
                    label="Data admissão"
                    variant="filled"
                    fullWidth
                    disabled
                    InputProps={{
                      inputComponent: DateInput,
                    }}
                  />
                </ContainerInput>

                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="resignationDate"
                    label="Data de Demissão"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      inputComponent: DateInput,
                      onChange: ({ target }) =>
                        handleFields(
                          values,
                          setFieldValue,
                          'resignationDate',
                          target.value,
                        ),
                    }}
                  />
                </ContainerInput>

                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="departureDate"
                    label="Data de Saída"
                    variant="filled"
                    fullWidth
                    disabled
                    InputProps={{
                      inputComponent: DateInput,
                    }}
                  />
                </ContainerInput>

                <Grid item lg={12}>
                  <Typography fontSize="14px" fontWeight="400">
                    Motivo da demissão
                  </Typography>
                </Grid>
                <ContainerInput item lg={12}>
                  <Field
                    component={TextField}
                    select
                    name="reason"
                    variant="filled"
                    label="Selecione"
                    fullWidth
                  >
                    <MenuItem value="Motivo primeiro">Motivo 1</MenuItem>
                    <MenuItem value="Motivo segundo">Motivo 2</MenuItem>
                  </Field>
                </ContainerInput>
                <Grid item lg={12}>
                  <Typography fontSize="14px" fontWeight="400">
                    Observação
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Field
                    component={TextField}
                    multiline
                    rows={8}
                    name="observations"
                    label="Registre informações relevantes sobre esse desligamento"
                    variant="filled"
                    fullWidth
                  />
                </Grid>

                <Grid
                  container
                  item
                  lg={12}
                  justify="flex-end"
                  alignItems="center"
                >
                  <Button type="submit" disabled={isSubmitting}>
                    <Typography variant="h3" fontWeight="bold">
                      SALVAR
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </TabContainer>
    </TabPanel>
  );
};

Shutdown.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  admissionDate: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
};

Shutdown.defaultProps = {
  data: {},
};

export default Shutdown;
