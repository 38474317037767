import DateFnsUtils from '@date-io/date-fns';
import { Button } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Withdrawal from '../../../../assets/Withdrawal.svg';
import { Grid, ModalSkeleton, Typography } from '../../../../components';
import { useAuth } from '../../../../hooks/auth';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import { ACTIVITY_PERIOD, STATUS_ACTIVE } from '../../../../utils/enums';
import toFormData from '../../../../utils/formData';
import getISOStartOfDayDate from '../../../../utils/getISOStartOfDayDate';
import unpaidLeaveSchema from '../../../../validations/unpaidLeaveScema';
import { StyledGridButton } from '../styles';

const UnpaidLeaveModal = ({
  handleClickOnClose,
  setUser,
  modalType,
  message,
  modalValue,
}) => {
  const [registeredSuspension, setRegisteredSuspension] = useState(false);

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();
  const { user } = useAuth();

  const formInitialValues = {
    startDate: null,
    finalDate: null,
    withoutForecastOfEnding: false,
    reason: '',
  };

  const handleUnpaidLeave = useCallback(
    async (values, { setSubmitting }) => {
      try {
        dispatch(setLoading(true));
        const {
          startDate,
          finalDate,
          withoutForecastOfEnding,
          reason,
        } = values;

        const api = AuthenticatedHttpClient();
        const formattedDateStart = getISOStartOfDayDate(startDate);

        const details = {
          startDate: formattedDateStart,
          withoutForecastOfEnding,
          reason,
          user: collaboratorId,
          company: user.company._id,
          type: modalValue,
          period: ACTIVITY_PERIOD.MORE_THAN_ONE_DAY,
        };

        if (finalDate) {
          const formattedDateEnd = getISOStartOfDayDate(finalDate);
          if (
            new Date(formattedDateStart).getTime() ===
            new Date(formattedDateEnd).getTime()
          ) {
            dispatch(
              setSnackbar(
                true,
                'error',
                'A data de inicio e fim não podem ser iguais',
              ),
            );
            return;
          }
          details.finalDate = formattedDateEnd;
        }

        const form = toFormData(details);

        const response = await api.post('activity', form);

        setUser(response.data);
        setRegisteredSuspension(true);
        dispatch(setSnackbar(true, 'success', message.success));
      } catch (error) {
        dispatch(setSnackbar(true, 'error', error?.response?.data?.message));
      } finally {
        setSubmitting(false);
        dispatch(setLoading(false));
      }
    },
    [collaboratorId, dispatch, setUser, user.company._id, message, modalValue],
  );

  const handleChangeCheckbox = useCallback((e, setFieldValue) => {
    if (e.target.value) {
      setFieldValue('finalDate', null);
    }
  }, []);

  const getModalTitle = () => {
    return registeredSuspension
      ? `${modalType.toUpperCase()} REGISTRADA`
      : `REGISTRAR ${modalType.toUpperCase()}`;
  };

  const RegisteredUnpaidLeaveComponent = () => {
    return (
      <Grid container pa="18px">
        <Grid>
          <Grid container justify="center" alignItems="space" spacing={5}>
            <Grid mt="50px">
              <Grid item leg={6}>
                <img src={Withdrawal} alt="" />
              </Grid>
            </Grid>
            <Grid item lg={6}>
              <Typography mt="50px" variant="h3" fontWeight="bold" align="left">
                {message.success}
              </Typography>
              <Typography opacity="0.4" mt="20px" fontSize="20px">
                {modalValue === STATUS_ACTIVE.SUSPENSION ? (
                  <span>Informe ao colaborador sobre a suspensão!</span>
                ) : (
                  <span>
                    Informe ao colaborador sobre <br /> a sua licença!
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
          <StyledGridButton container justify="flex-end" alignItems="center">
            <Grid item lg={2}>
              <Button onClick={() => handleClickOnClose(false)}>
                <Typography variant="h3" fontWeight="bold">
                  CONFIRMAR
                </Typography>
              </Button>
            </Grid>
          </StyledGridButton>
        </Grid>
      </Grid>
    );
  };

  const UnregisteredSuspensionComponent = () => {
    return (
      <Grid pa="18px" mt="24px">
        <Grid
          container
          justify="center"
          alignContent="space-between"
          spacing={3}
        />
        <Formik
          initialValues={formInitialValues}
          validationSchema={unpaidLeaveSchema}
          onSubmit={handleUnpaidLeave}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <Form>
                <Grid
                  container
                  justify="flex-start"
                  alignContent="center"
                  spacing={3}
                >
                  <Grid item lg={6}>
                    <Field
                      component={KeyboardDatePicker}
                      name="startDate"
                      label="Data de início "
                      inputVariant="filled"
                      cancelLabel="CANCELAR"
                      format="dd/MM/yyyy"
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <Field
                      component={KeyboardDatePicker}
                      name="finalDate"
                      label="Data de término "
                      inputVariant="filled"
                      cancelLabel="CANCELAR"
                      format="dd/MM/yyyy"
                      disabled={values.withoutForecastOfEnding}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <Field
                      component={CheckboxWithLabel}
                      name="withoutForecastOfEnding"
                      checked={values.withoutForecastOfEnding}
                      color="primary"
                      type="checkbox"
                      onClick={e => handleChangeCheckbox(e, setFieldValue)}
                      Label={{
                        label: 'Sem previsão de término',
                      }}
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <Field
                      component={TextField}
                      multiline
                      rows={5}
                      name="reason"
                      label="Motivo"
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <StyledGridButton
                  container
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <Button onClick={() => handleClickOnClose(false)}>
                      <Typography opacity="0.4" fontWeight="bold">
                        CANCELAR
                      </Typography>
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button type="submit" disabled={isSubmitting}>
                      <Typography fontWeight="bold">
                        CONFIRMAR LICENÇA
                      </Typography>
                    </Button>
                  </Grid>
                </StyledGridButton>
              </Form>
            </MuiPickersUtilsProvider>
          )}
        </Formik>
      </Grid>
    );
  };

  return (
    <ModalSkeleton
      title={getModalTitle()}
      handleClickOnClose={handleClickOnClose}
      maxWidth="850px"
    >
      {registeredSuspension ? (
        <RegisteredUnpaidLeaveComponent />
      ) : (
        <UnregisteredSuspensionComponent />
      )}
    </ModalSkeleton>
  );
};

UnpaidLeaveModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  modalValue: PropTypes.string.isRequired,
  message: PropTypes.objectOf(
    PropTypes.shape({
      success: PropTypes.string,
      error: PropTypes.string,
    }),
  ).isRequired,
};

export default UnpaidLeaveModal;
