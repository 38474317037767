import React from 'react';
import PropTypes from 'prop-types';

import { StyledAvatar } from './styles';

const Avatar = ({ text, ...props }) => (
  <StyledAvatar {...props}>{text}</StyledAvatar>
);

Avatar.defaultProps = {
  text: false,
};

Avatar.propTypes = {
  text: PropTypes.string,
};

export default Avatar;
