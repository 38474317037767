import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Dependents } from './components/index';
import { Typography, TabPanel } from '../../../components/index';
import { TabContainer, ContainerInput, StyledTextField } from '../styles';

const FamilyData = props => {
  const { value, index, stepStatus, data, setUser, ...other } = props;
  const [isHasDependents, setIsHasDependents] = useState(false);
  const [dependents, setDependents] = useState([]);
  const [familyData, setFamilyData] = useState({
    maritalStatus: '',
    mothersName: '',
    fathersName: '',
  });

  useEffect(() => {
    if (Object.keys(data).length) {
      setFamilyData(data);
      setIsHasDependents(data.hasDependents);
      setDependents(data.dependents);
    }
  }, [data]);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography fontSize="16px" color="secondary" fontWeight="300">
              Estado civil e Filiação
            </Typography>
          </Grid>
          <ContainerInput item xs={2}>
            <StyledTextField
              name="maritalStatus"
              label="Estado civil"
              variant="filled"
              fullWidth
              value={familyData.maritalStatus}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={5}>
            <StyledTextField
              name="mothersName"
              label="Nome da mãe"
              variant="filled"
              fullWidth
              value={familyData.mothersName}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={5}>
            <StyledTextField
              name="fathersName"
              label="Nome da pai"
              variant="filled"
              fullWidth
              value={familyData.fathersName}
              disabled
            />
          </ContainerInput>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          justify="space-around"
          spacing={2}
        >
          {isHasDependents && (
            <>
              <Grid item xs={12}>
                <Typography fontSize="16px" color="secondary" fontWeight="300">
                  Dependentes
                </Typography>
              </Grid>
              <Grid container direction="column" spacing={1}>
                {dependents.map(dependent => {
                  return (
                    <Grid item lg={5} xl={2}>
                      <Dependents
                        name={dependent.dependentName}
                        type={dependent.dependentType}
                        birth={dependent.dependentbirthDate}
                        cpf={dependent.dependentCpf}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
        </Grid>
      </TabContainer>
    </TabPanel>
  );
};

FamilyData.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  stepStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),

  setUser: PropTypes.func.isRequired,
};

FamilyData.defaultProps = {
  data: {},
};

export default FamilyData;
