import DateFnsUtils from '@date-io/date-fns';
import { Box, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { formatISO, set, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Grid, ModalSkeleton, Typography } from '../../../../components';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';

const LimitDateModal = ({ handleClose, collaboratorId, getUsers }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [initialState] = useState({
    limitDate: null,
  });

  const yupSchema = Yup.object().shape({
    limitDate: Yup.date()
      .required('Campo obrigatório')
      .typeError('Data inválida'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const api = AuthenticatedHttpClient();

    const limitDateMidNight = set(values.limitDate, {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });

    const ISOLimitDate = formatISO(limitDateMidNight);

    try {
      setLoading(true);
      await api.put(`/users/${collaboratorId}`, {
        limitDate: ISOLimitDate,
        disabled: false,
      });
      getUsers();
      handleClose();
      dispatch(setSnackbar(true, 'success', 'Data alterada com sucesso!'));
    } catch (e) {
      dispatch(setSnackbar(true, 'error', 'Não foi possível alterar a data'));
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <ModalSkeleton
      title="Alterar limite de preenchimento"
      handleClickOnClose={handleClose}
      maxWidth="600px"
    >
      <Box
        component={Grid}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        m="10px"
        width="100%"
      >
        <Formik
          initialValues={initialState}
          onSubmit={handleSubmit}
          validationSchema={yupSchema}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Form style={{ width: '95%' }}>
              <Field
                component={KeyboardDatePicker}
                name="limitDate"
                label="Data Limite"
                inputVariant="filled"
                disablePast
                cancelLabel="CANCELAR"
                format="dd/MM/yyyy"
                invalidDateMessage="Formato de data inválido"
                fullWidth
              />
              <Box mt="30px" display="flex" justifyContent="flex-end">
                <Button disabled={loading} onClick={handleClose}>
                  <Typography fontSize="14px" fontWeight={700} opacity={0.5}>
                    Cancelar
                  </Typography>
                </Button>
                <Button disabled={loading} type="submit" color="secondary">
                  <Typography fontSize="14px" fontWeight={700}>
                    Alterar data
                  </Typography>
                </Button>
              </Box>
            </Form>
          </MuiPickersUtilsProvider>
        </Formik>
      </Box>
    </ModalSkeleton>
  );
};

LimitDateModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  collaboratorId: PropTypes.string.isRequired,
  getUsers: PropTypes.func.isRequired,
};

export default LimitDateModal;
