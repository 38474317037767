import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton } from './styles';

const CollaboratorButton = ({ text, ...rest }) => (
  <StyledButton {...rest}>{text}</StyledButton>
);

CollaboratorButton.propTypes = {
  text: PropTypes.string,
};

CollaboratorButton.defaultProps = {
  text: 'PRÓXIMO',
};

export default CollaboratorButton;
