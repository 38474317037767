import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Checkbox } from '@material-ui/core';

import { DateInput, PhoneInput, TabPanel } from '../../../components';

import {
  TabContainer,
  ContainerInput,
  SubTitleForm,
  StyledFormControlLabel,
  StyledTextField,
} from '../styles';

const PersonalData = props => {
  const { value, index, data, userInfo, ...other } = props;
  const [userInfos, setUserInfos] = useState({
    fullName: '',
    email: '',
    phone: '',
    isWhatsApp: false,
  });
  const [personalData, setPersonalData] = useState({
    nationality: '',
    state: '',
    cityOfBirth: '',
    birthDate: '',
    gender: '',
    raceColor: '',
    deficiency: '',
  });

  useEffect(() => {
    if (Object.keys(userInfo).length) {
      setUserInfos(userInfo);
    }
    if (Object.keys(data).length) {
      setPersonalData({
        ...data,
        birthDate: new Date(data.birthDate).toLocaleDateString('pt-br'),
      });
    }
  }, [userInfo, data]);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Box
          component={Grid}
          container
          alignItems="center"
          justify="space-between"
          spacing={2}
          p={1}
        >
          <SubTitleForm>Dados pessoais</SubTitleForm>

          <ContainerInput item lg={6}>
            <StyledTextField
              name="fullName"
              label="Nome Completo"
              variant="filled"
              fullWidth
              value={userInfos.fullName}
              disabled
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="email"
              label="E-mail"
              variant="filled"
              fullWidth
              value={userInfos.email}
              disabled
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="phone"
              label="Telefone ou Celular"
              variant="filled"
              fullWidth
              value={userInfos.phone}
              disabled
              InputProps={{
                inputComponent: PhoneInput,
              }}
            />
          </ContainerInput>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width={1}
            mt="-8px"
          >
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={userInfos.isWhatsApp}
                  name="isWhatsApp"
                  color="primary"
                />
              }
              label="Whatsapp"
            />
          </Box>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="nationality"
              label="Nacionalidade"
              variant="filled"
              fullWidth
              value={personalData.nationality}
              disabled
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="state"
              label="UF"
              variant="filled"
              fullWidth
              value={personalData.state}
              disabled
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="cityOfBirth"
              label="Cidade nascimento"
              variant="filled"
              fullWidth
              value={personalData.cityOfBirth}
              disabled
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="birthDate"
              label="Data nascimento"
              variant="filled"
              fullWidth
              value={personalData.birthDate}
              disabled
              InputProps={{
                inputComponent: DateInput,
              }}
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="gender"
              label="Gênero"
              variant="filled"
              fullWidth
              value={personalData.gender}
              disabled
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="raceColor"
              label="Cor ou raça"
              variant="filled"
              fullWidth
              value={personalData.raceColor}
              disabled
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="deficiency"
              label="Possui deficiência"
              variant="filled"
              fullWidth
              value={personalData.deficiency}
              disabled
            />
          </ContainerInput>
          <ContainerInput item lg={3} />
        </Box>
      </TabContainer>
    </TabPanel>
  );
};

PersonalData.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

PersonalData.defaultProps = {
  data: {},
};

export default PersonalData;
