import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Box } from '@material-ui/core';

import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';

import { Typography, Grid, ModalSkeleton } from '../../../components';

import completeResignation from '../../../assets/completeResignation.svg';

const ResignationColaboratorModal = React.forwardRef(
  ({ handleClickOnClose }, ref) => {
    const dispatch = useDispatch();
    const { userId: collaboratorId } = useParams();

    const handleCollaboratorResign = useCallback(async () => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        await api.put(`users/conclude-dismissal/${collaboratorId}`);

        dispatch(setSnackbar(true, 'success', 'Colaborador desligado'));
        handleClickOnClose(true);
      } catch (error) {
        dispatch(
          setSnackbar(
            true,
            'error',
            'Falha ao desligador colaborador.\nVerifique se todos os dados necessários foram preenchidos',
          ),
        );
        handleClickOnClose(false);
      } finally {
        dispatch(setLoading(false));
      }
    }, [collaboratorId, dispatch, handleClickOnClose]);

    return (
      <ModalSkeleton
        title="Reativar Colaborador"
        handleClickOnClose={() => handleClickOnClose(false)}
        ref={ref}
      >
        <Box m={4}>
          <Grid container alignItems="center" justify="center">
            <Grid item lg={6}>
              <img src={completeResignation} alt="" />
            </Grid>

            <Grid item lg={6}>
              <Typography variant="h3" fontWeight="bold" align="left">
                VOCÊ TEM CERTEZA QUE DESEJA CONCLUIR O PROCESSO DE DEMISSÃO?
              </Typography>
              <Typography opacity="0.4" mt="20px" fontSize="20px">
                Antes de finalizar o processo, confirme se todos os dados foram
                preenchidos corretamente, verifique se todos os documentos de
                rescisão foram anexos e foram assinados corretamente e por fim
                avise com antecedência ao funcionário que o seu acesso será
                bloqueado.
              </Typography>
            </Grid>
          </Grid>

          <Box mt={3} mb={3}>
            <Grid container item lg={12} justify="flex-end" alignItems="center">
              <Box mr={4}>
                <Button
                  type="buton"
                  color="secondary"
                  onClick={() => handleClickOnClose(false)}
                  size="small"
                >
                  <Typography
                    fontSize="0.9375rem"
                    fontWeight="bold"
                    opacity="0.7"
                  >
                    CANCELAR
                  </Typography>
                </Button>
              </Box>

              <Button
                color="secondary"
                type="submit"
                onClick={handleCollaboratorResign}
                size="small"
              >
                <Typography fontSize="0.9375rem" fontWeight="bold">
                  TUDO CORRETO! CONCLUIR DESLIGAMENTO
                </Typography>
              </Button>
            </Grid>
          </Box>
        </Box>
      </ModalSkeleton>
    );
  },
);

ResignationColaboratorModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
};

export default ResignationColaboratorModal;
