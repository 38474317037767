import { Avatar } from '@material-ui/core';
import styled from 'styled-components';

export const StyledAvatar = styled(Avatar)`
  width: 50px;
  height: 50px;
  color: #bdbdbd;
  background-color: #fafafa;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
`;
