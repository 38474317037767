import { Box, ButtonBase, Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Deleter,
  SelectFilter,
  TabPanel,
  Typography,
  Uploader,
} from '../../../components';
import PaySlipList from '../../../components/PaySlipList/index';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import toFormData from '../../../utils/formData';
import PayslipSelects from '../Components/PayslipSelects';
import {
  DivSize,
  PayslipButton,
  SelectDiv,
  SubTitleForm,
  TabContainer,
} from '../styles';

const NUMBER_GET_ALL_PAYSLIPS = -1;
const DateReducer = (currentDate, action) => {
  switch (action.type) {
    case 'clearDate':
      return {
        ...currentDate,
        month: NUMBER_GET_ALL_PAYSLIPS,
        year: NUMBER_GET_ALL_PAYSLIPS,
      };
    case 'setMonth':
      return { ...currentDate, month: action.month };
    case 'setYear':
      return { ...currentDate, year: action.year };
    case 'setMonthAndYear':
      return { ...currentDate, year: action.year, month: action.month };

    default:
      return { currentDate };
  }
};

const PaySlip = props => {
  const { value, index, admissionDate, ...other } = props;
  const [date, dispatchDate] = useReducer(DateReducer, {
    month: NUMBER_GET_ALL_PAYSLIPS,
    year: NUMBER_GET_ALL_PAYSLIPS,
  });
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [payslipFile, setPayslipFile] = useState(null);
  const [payslips, setPayslips] = useState([]);
  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();

  const organizePaySlip = arrayPayslips => {
    const newPayslips = arrayPayslips.map(element => {
      const namedDate = format(new Date(element.date), 'MMMM - yyyy', {
        locale: ptBR,
      });
      const formatedDate =
        namedDate.charAt(0).toUpperCase() + namedDate.slice(1);
      return {
        ...element,
        date: formatedDate,
      };
    });
    return newPayslips;
  };

  const getPaySlips = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const api = AuthenticatedHttpClient();
      let route;
      let correctionMonth;
      if (
        date.year === NUMBER_GET_ALL_PAYSLIPS &&
        date.month === NUMBER_GET_ALL_PAYSLIPS
      ) {
        route = `payslip/${collaboratorId}`;
      } else if (
        date.year === NUMBER_GET_ALL_PAYSLIPS &&
        date.month !== NUMBER_GET_ALL_PAYSLIPS
      ) {
        correctionMonth = date.month + 1;
        route = `payslip/${collaboratorId}?month=${correctionMonth}`;
      } else if (
        date.year !== NUMBER_GET_ALL_PAYSLIPS &&
        date.month === NUMBER_GET_ALL_PAYSLIPS
      ) {
        route = `payslip/${collaboratorId}?year=${date.year}`;
      } else {
        correctionMonth = date.month + 1;
        route = `payslip/${collaboratorId}?month=${correctionMonth}&year=${date.year}`;
      }
      const { data } = await api.get(route);
      if (data.length === 0) {
        setPayslips([]);
      } else {
        const formatedPayslips = organizePaySlip(data[0].payslips);
        setPayslips(formatedPayslips);
      }
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Não foi possível pegar os dados'));
    } finally {
      dispatch(setLoading(false));
    }
  }, [collaboratorId, date, dispatch]);

  useEffect(() => {
    getPaySlips();
  }, [collaboratorId, getPaySlips]);

  const handlePayslip = useCallback(
    async (values, { setSubmitting, resetForm }) => {
      try {
        setSubmitting(true);
        dispatch(setLoading(true));
        const dateSubmit = format(
          new Date(values.year, values.month),
          'yyyy/MM/dd',
        );
        const reqBody = {
          file: payslipFile,
          date: dateSubmit,
        };
        const formData = toFormData(reqBody);
        const api = AuthenticatedHttpClient();
        await api.post(`payslip/${collaboratorId}`, formData);
        dispatch(setSnackbar(true, 'success', 'Dados alterados'));
        setIsFileUploaded(false);
        resetForm({ values: '' });
        dispatchDate({ type: 'clearDate' });
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possível inserir o holerite'),
        );
      } finally {
        setSubmitting(false);
        dispatch(setLoading(false));
      }
    },
    [payslipFile, dispatch, collaboratorId],
  );

  const payslipSchema = Yup.object().shape({
    month: Yup.string().required('Campo obrigatório'),
    year: Yup.string().required('Campo obrigatório'),
  });

  const formInitialValues = {
    month: '',
    year: '',
  };

  const generateYearOptions = () => {
    const actualYear = new Date().getFullYear();
    const admissionYear = new Date(admissionDate).getFullYear();
    const years = [];
    for (let i = admissionYear; i <= actualYear; i += 1) {
      years.push({
        label: i.toString(),
        value: i,
      });
    }
    return years;
  };

  const generateMonthOptions = year => {
    const months = [];
    const admissionMonth = new Date(admissionDate).getMonth();
    const admissionYear = new Date(admissionDate).getFullYear();
    const actualYear = new Date().getFullYear();
    const actualMonth = new Date().getMonth();
    let finalMonth = 11;
    let initialMonth = 0;

    if (admissionYear === year) {
      initialMonth = admissionMonth;
    }
    if (year === actualYear) {
      finalMonth = actualMonth;
    }
    for (let i = initialMonth; i <= finalMonth; i += 1) {
      const namedDate = format(new Date(2020, i), 'MMMM', {
        locale: ptBR,
      });
      const formatedDate =
        namedDate.charAt(0).toUpperCase() + namedDate.slice(1);

      months.push({
        label: formatedDate,
        value: i,
      });
    }
    return months;
  };

  const monthFilterOptions = generateMonthOptions(date.year);
  monthFilterOptions.unshift({
    label: 'MES',
    value: NUMBER_GET_ALL_PAYSLIPS,
  });

  const yearFilterOptions = generateYearOptions();
  yearFilterOptions.unshift({
    label: 'ANO',
    value: NUMBER_GET_ALL_PAYSLIPS,
  });

  const handleClosePayslip = () => {
    setIsFileUploaded(false);
  };

  const handleSetFile = file => {
    setPayslipFile(file);
    setIsFileUploaded(true);
  };

  const handleSetMonth = monthValue => {
    dispatchDate({ type: 'setMonth', month: monthValue });
  };

  const handleSetYear = yearValue => {
    const monthsThatExistsOnYear = generateMonthOptions(yearValue);
    monthsThatExistsOnYear.unshift({
      label: 'MES',
      value: NUMBER_GET_ALL_PAYSLIPS,
    });
    const hasMonthOnYear = monthsThatExistsOnYear.some(
      actualMonth => actualMonth.value === date.month,
    );
    if (!hasMonthOnYear) {
      dispatchDate({ type: 'setMonth', month: NUMBER_GET_ALL_PAYSLIPS });
    }

    dispatchDate({ type: 'setYear', year: yearValue });
  };

  const removePayslip = async idPayslipToDelete => {
    try {
      dispatch(setLoading(true));
      const api = AuthenticatedHttpClient();
      const reqBody = {
        data: { payslipId: idPayslipToDelete },
      };
      await api.delete(`payslip/${collaboratorId}`, reqBody);
      dispatch(setSnackbar(true, 'success', 'Holerite Removido'));
      dispatchDate({ type: 'clearDate' });
    } catch (error) {
      dispatch(
        setSnackbar(true, 'error', 'Não foi possivel deletar o holerite'),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <SubTitleForm>Adicionar</SubTitleForm>
        <Box mb={2}>
          <DivSize container justify="space-between">
            {isFileUploaded ? (
              <Grid container spacing={3} justify="flex-start">
                <Grid item lg={2}>
                  <Deleter
                    file={{
                      name: payslipFile.name,
                      size: payslipFile.size,
                    }}
                    removeFn={handleClosePayslip}
                  />
                </Grid>
                <Grid item lg={10}>
                  <Formik
                    initialValues={formInitialValues}
                    onSubmit={handlePayslip}
                    validationSchema={payslipSchema}
                  >
                    {({ submitForm, isSubmitting }) => (
                      <Form>
                        <Grid container spacing={3}>
                          <PayslipSelects
                            generateMonthOptions={generateMonthOptions}
                            generateYearOptions={generateYearOptions}
                          />

                          <PayslipButton item lg={2}>
                            <ButtonBase
                              onClick={submitForm}
                              disabled={isSubmitting}
                            >
                              <Typography
                                color="black"
                                fontSize="18px"
                                fontWeight="bold"
                              >
                                Enviar Holerite
                              </Typography>
                            </ButtonBase>
                          </PayslipButton>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            ) : (
              <Grid item lg={12}>
                <Uploader
                  accept="application/pdf"
                  title="Holerite"
                  dropText="Adicionar Holerite"
                  size="xl"
                  onFileUploaded={file => handleSetFile(file)}
                />
              </Grid>
            )}
          </DivSize>
        </Box>
        <SubTitleForm>Relações de Holerite</SubTitleForm>
        <SelectDiv>
          <SelectFilter
            selectOptions={yearFilterOptions}
            value={date.year}
            handleChange={handleSetYear}
          />
          <SelectFilter
            selectOptions={monthFilterOptions}
            value={date.month}
            handleChange={handleSetMonth}
          />
        </SelectDiv>
        <Grid container alignItems="flex-start" justify="flex-start">
          <PaySlipList paySlips={payslips} removeFn={removePayslip} />
        </Grid>
      </TabContainer>
    </TabPanel>
  );
};

PaySlip.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  admissionDate: PropTypes.string.isRequired,
};

export default PaySlip;
