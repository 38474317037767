import { Box, Button, Grid } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, Typography } from '../../components';
import { Container, StyledLink, TabsPaper } from './styles';
import { PointMirror, Records, Requests } from './Tabs';

const ColaboratorScore = () => {
  const { userId } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);
  return (
    <Container>
      <Box py={2}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          pa="16px"
          pb="16px"
        >
          <Grid item>
            <Typography fontSize="40px" fontWeight="bold">
              Meu ponto
            </Typography>
          </Grid>
          <Grid item>
            <StyledLink to={`/colaborator-home/score/journey/${userId}`}>
              <Button>
                <Typography
                  text-align="center"
                  fontWeight="bold"
                  fontSize="14px"
                >
                  VER DETALHES DA JORNADA
                </Typography>
              </Button>
            </StyledLink>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <TabsPaper square elevation={1}>
          <Tabs value={tabValue} onChange={handleChangeTab}>
            <Tab label="REGISTROS" index={0} />
            <Tab label="SOLICITAÇÕES" index={1} />
            <Tab label="ESPELHO DE PONTO" index={2} />
          </Tabs>
          <Records value={tabValue} index={0} />
          <Requests data="" value={tabValue} index={1} />
          <PointMirror value={tabValue} index={2} />
        </TabsPaper>
      </Box>
    </Container>
  );
};

export default ColaboratorScore;
