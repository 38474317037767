import DateFnsUtils from '@date-io/date-fns';
import { Box, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardTimePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Clock from '../../../../../assets/Clock.svg';
import { Grid, Typography } from '../../../../../components';
import {
  calculateNightAdditional,
  calculateTimeDifference,
} from '../../../../../utils/journeyScaleCalculateFunctions';
import {
  ContainerInput,
  StyledCheckbox,
  StyledCheckboxWithOpacity,
} from '../../styles';

const StandardWeekScale = props => {
  const { values, handleOpacity, opacity, timeOptions, setFieldValue } = props;

  useEffect(() => {
    const {
      isFriday,
      isMonday,
      isSunday,
      isThursday,
      isTuesday,
      isWednesday,
      isSaturday,
      monday,
      friday,
      saturday,
      sunday,
      thursday,
      tuesday,
      wednesday,
      isEqualTime,
      standardTime,
    } = values.standardWeekScale;
    let weekWorkedHours = 0;
    let weekWorkedMinutes = 0;
    let weekNightWorkedHours = 0;
    let weekNightWorkedMinutes = 0;
    if (isEqualTime) {
      const allDays = {
        isMonday,
        isTuesday,
        isWednesday,
        isThursday,
        isFriday,
        isSaturday,
        isSunday,
      };
      const daysWorked = Object.keys(allDays).filter(
        property => allDays[property],
      );

      const countDaysWorked = daysWorked.length;

      const timeDifference = calculateTimeDifference(
        standardTime.enterTime,
        standardTime.exitTime,
        standardTime.mainBreak,
        countDaysWorked,
      );

      weekWorkedHours = timeDifference.hours;
      weekWorkedMinutes = timeDifference.minutes;

      const nightAdditional = calculateNightAdditional(
        standardTime.enterTime,
        standardTime.exitTime,
        standardTime.mainBreak,
        countDaysWorked,
      );
      weekNightWorkedHours = nightAdditional.hours;
      weekNightWorkedMinutes = nightAdditional.minutes;
    } else {
      if (monday && isMonday) {
        const timeDifference = calculateTimeDifference(
          monday.enterTime,
          monday.exitTime,
          monday.mainBreak,
        );
        const nightAdditional = calculateNightAdditional(
          monday.enterTime,
          monday.exitTime,
          monday.mainBreak,
        );

        weekWorkedHours += timeDifference.hours;
        weekWorkedMinutes += timeDifference.minutes;
        weekNightWorkedHours += nightAdditional.hours;
        weekNightWorkedMinutes += nightAdditional.minutes;
      }
      if (tuesday && isTuesday) {
        const timeDifference = calculateTimeDifference(
          tuesday.enterTime,
          tuesday.exitTime,
          tuesday.mainBreak,
        );
        const nightAdditional = calculateNightAdditional(
          tuesday.enterTime,
          tuesday.exitTime,
          tuesday.mainBreak,
        );
        weekWorkedHours += timeDifference.hours;
        weekWorkedMinutes += timeDifference.minutes;
        weekNightWorkedHours += nightAdditional.hours;
        weekNightWorkedMinutes += nightAdditional.minutes;
      }

      if (wednesday && isWednesday) {
        const timeDifference = calculateTimeDifference(
          wednesday.enterTime,
          wednesday.exitTime,
          wednesday.mainBreak,
        );
        const nightAdditional = calculateNightAdditional(
          wednesday.enterTime,
          wednesday.exitTime,
          wednesday.mainBreak,
        );
        weekWorkedHours += timeDifference.hours;
        weekWorkedMinutes += timeDifference.minutes;
        weekNightWorkedHours += nightAdditional.hours;
        weekNightWorkedMinutes += nightAdditional.minutes;
      }

      if (thursday && isThursday) {
        const timeDifference = calculateTimeDifference(
          thursday.enterTime,
          thursday.exitTime,
          thursday.mainBreak,
        );
        const nightAdditional = calculateNightAdditional(
          thursday.enterTime,
          thursday.exitTime,
          thursday.mainBreak,
        );
        weekWorkedHours += timeDifference.hours;
        weekWorkedMinutes += timeDifference.minutes;
        weekNightWorkedHours += nightAdditional.hours;
        weekNightWorkedMinutes += nightAdditional.minutes;
      }

      if (friday && isFriday) {
        const timeDifference = calculateTimeDifference(
          friday.enterTime,
          friday.exitTime,
          friday.mainBreak,
        );
        const nightAdditional = calculateNightAdditional(
          friday.enterTime,
          friday.exitTime,
          friday.mainBreak,
        );
        weekWorkedHours += timeDifference.hours;
        weekWorkedMinutes += timeDifference.minutes;
        weekNightWorkedHours += nightAdditional.hours;
        weekNightWorkedMinutes += nightAdditional.minutes;
      }

      if (saturday && isSaturday) {
        const timeDifference = calculateTimeDifference(
          saturday.enterTime,
          saturday.exitTime,
          saturday.mainBreak,
        );
        const nightAdditional = calculateNightAdditional(
          saturday.enterTime,
          saturday.exitTime,
          saturday.mainBreak,
        );
        weekWorkedHours += timeDifference.hours;
        weekWorkedMinutes += timeDifference.minutes;
        weekNightWorkedHours += nightAdditional.hours;
        weekNightWorkedMinutes += nightAdditional.minutes;
      }

      if (sunday && isSunday) {
        const timeDifference = calculateTimeDifference(
          sunday.enterTime,
          sunday.exitTime,
          sunday.mainBreak,
        );
        const nightAdditional = calculateNightAdditional(
          sunday.enterTime,
          sunday.exitTime,
          sunday.mainBreak,
        );
        weekWorkedHours += timeDifference.hours;
        weekWorkedMinutes += timeDifference.minutes;
        weekNightWorkedHours += nightAdditional.hours;
        weekNightWorkedMinutes += nightAdditional.minutes;
      }

      if (weekWorkedMinutes >= 60) {
        weekWorkedHours += Math.floor(weekWorkedMinutes / 60);
        weekWorkedMinutes %= 60;
      }

      if (weekNightWorkedMinutes >= 60) {
        weekNightWorkedHours += Math.floor(weekNightWorkedMinutes / 60);
        weekNightWorkedMinutes %= 60;
      }
    }

    const formattedStringTime = `${weekWorkedHours
      .toString()
      .padStart(2, '0')}:${weekWorkedMinutes.toString().padStart(2, '0')}`;

    const formattedStringNightAdditionalTime = `${weekNightWorkedHours
      .toString()
      .padStart(2, '0')}:${weekNightWorkedMinutes.toString().padStart(2, '0')}`;

    setFieldValue('nightAdditional', formattedStringNightAdditionalTime);
    setFieldValue('totalHours', formattedStringTime);
  }, [values, setFieldValue]);

  return (
    <>
      <Grid container spacing={3} alignItems="center" mt="40px">
        <Grid item xs={12}>
          <Typography color="secondary" fontSize="0.875rem">
            Dias e Horários
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Field
            component={StyledCheckboxWithOpacity}
            opacity={opacity}
            name="standardWeekScale.isEqualTime"
            checked={values.standardWeekScale.isEqualTime}
            color="primary"
            type="checkbox"
            size="small"
            onClick={e => handleOpacity(e)}
            Label={{
              label: 'Mesmo horário todos os dias',
            }}
          />
        </Grid>
        <Grid
          container
          spacing={3}
          item
          xs={9}
          alignItems="center"
          justify="flex-end"
        >
          <Grid item>
            <Typography color="secondary" fontSize="0.875rem">
              Total de horas semanais:
            </Typography>
          </Grid>
          <Grid item>
            <Typography opacity={0.5} color="secondary" fontSize="1.25rem">
              {values.totalHours} horas
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="secondary" fontSize="0.875rem">
              Adicional noturno:
            </Typography>
          </Grid>
          <Grid item>
            <Typography opacity={0.5} color="secondary" fontSize="1.25rem">
              {values.nightAdditional} horas
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {!values.standardWeekScale.isEqualTime ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <Box mt={5}>
            <Grid container justify="space-between">
              <Grid container item xs={6} spacing={3} alignItems="center">
                <Grid item xs={2}>
                  <Field
                    component={StyledCheckbox}
                    name="standardWeekScale.isMonday"
                    checked={values.standardWeekScale.isMonday}
                    color="primary"
                    type="checkbox"
                    size="small"
                    Label={{
                      label: 'SEG',
                    }}
                  />
                </Grid>
                <ContainerInput item xs>
                  <Field
                    disabled={!values.standardWeekScale.isMonday}
                    component={KeyboardTimePicker}
                    keyboardIcon={<img src={Clock} alt="" />}
                    inputVariant="filled"
                    cancelLabel="CANCELAR"
                    invalidDateMessage="Horário Invalido"
                    ampm={false}
                    placeholder="00:00"
                    name="standardWeekScale.monday.enterTime"
                    label="Entrada"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item xs>
                  <Field
                    disabled={!values.standardWeekScale.isMonday}
                    component={KeyboardTimePicker}
                    keyboardIcon={<img src={Clock} alt="" />}
                    inputVariant="filled"
                    cancelLabel="CANCELAR"
                    invalidDateMessage="Horário Invalido"
                    ampm={false}
                    placeholder="00:00"
                    name="standardWeekScale.monday.exitTime"
                    label="Saida"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item xs>
                  <Field
                    disabled={!values.standardWeekScale.isMonday}
                    component={TextField}
                    select
                    name="standardWeekScale.monday.mainBreak"
                    label="Intervalo principal"
                    variant="filled"
                    fullWidth
                  >
                    {timeOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </ContainerInput>
              </Grid>
              <Grid container item spacing={3} alignItems="center" xs={6}>
                <Grid item xs={2}>
                  <Field
                    component={StyledCheckbox}
                    name="standardWeekScale.isFriday"
                    checked={values.standardWeekScale.isFriday}
                    color="primary"
                    type="checkbox"
                    size="small"
                    Label={{
                      label: 'SEX',
                    }}
                  />
                </Grid>
                <ContainerInput item xs>
                  <Field
                    disabled={!values.standardWeekScale.isFriday}
                    component={KeyboardTimePicker}
                    keyboardIcon={<img src={Clock} alt="" />}
                    inputVariant="filled"
                    cancelLabel="CANCELAR"
                    invalidDateMessage="Horário Invalido"
                    ampm={false}
                    placeholder="00:00"
                    name="standardWeekScale.friday.enterTime"
                    label="Entrada"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item xs>
                  <Field
                    disabled={!values.standardWeekScale.isFriday}
                    component={KeyboardTimePicker}
                    keyboardIcon={<img src={Clock} alt="" />}
                    inputVariant="filled"
                    cancelLabel="CANCELAR"
                    invalidDateMessage="Horário Invalido"
                    ampm={false}
                    placeholder="00:00"
                    name="standardWeekScale.friday.exitTime"
                    label="Saida"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item xs>
                  <Field
                    disabled={!values.standardWeekScale.isFriday}
                    component={TextField}
                    select
                    name="standardWeekScale.friday.mainBreak"
                    label="Intervalo principal"
                    variant="filled"
                    fullWidth
                  >
                    {timeOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </ContainerInput>
              </Grid>
            </Grid>
          </Box>

          <Grid container justify="space-between" mt="40px">
            <Grid container item spacing={3} xs={6} alignItems="center">
              <Grid item xs={2}>
                <Field
                  component={StyledCheckbox}
                  name="standardWeekScale.isTuesday"
                  checked={values.standardWeekScale.isTuesday}
                  color="primary"
                  type="checkbox"
                  size="small"
                  Label={{
                    label: 'TER',
                  }}
                />
              </Grid>
              <ContainerInput item xs>
                <Field
                  disabled={!values.standardWeekScale.isTuesday}
                  component={KeyboardTimePicker}
                  keyboardIcon={<img src={Clock} alt="" />}
                  inputVariant="filled"
                  cancelLabel="CANCELAR"
                  invalidDateMessage="Horário Invalido"
                  ampm={false}
                  placeholder="00:00"
                  name="standardWeekScale.tuesday.enterTime"
                  label="Entrada"
                  fullWidth
                />
              </ContainerInput>
              <ContainerInput item xs>
                <Field
                  disabled={!values.standardWeekScale.isTuesday}
                  component={KeyboardTimePicker}
                  keyboardIcon={<img src={Clock} alt="" />}
                  inputVariant="filled"
                  cancelLabel="CANCELAR"
                  invalidDateMessage="Horário Invalido"
                  ampm={false}
                  placeholder="00:00"
                  name="standardWeekScale.tuesday.exitTime"
                  label="Saida"
                  fullWidth
                />
              </ContainerInput>
              <ContainerInput item xs>
                <Field
                  disabled={!values.standardWeekScale.isTuesday}
                  component={TextField}
                  select
                  name="standardWeekScale.tuesday.mainBreak"
                  label="Intervalo principal"
                  variant="filled"
                  fullWidth
                >
                  {timeOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </ContainerInput>
            </Grid>
            <Grid container item spacing={3} alignItems="center" xs={6}>
              <Grid item xs={2}>
                <Field
                  component={StyledCheckbox}
                  name="standardWeekScale.isSaturday"
                  checked={values.standardWeekScale.isSaturday}
                  color="primary"
                  type="checkbox"
                  size="small"
                  Label={{
                    label: 'SAB',
                  }}
                />
              </Grid>
              <ContainerInput item xs>
                <Field
                  component={KeyboardTimePicker}
                  keyboardIcon={<img src={Clock} alt="" />}
                  inputVariant="filled"
                  cancelLabel="CANCELAR"
                  invalidDateMessage="Horário Invalido"
                  ampm={false}
                  placeholder="00:00"
                  name="standardWeekScale.saturday.enterTime"
                  label="Entrada"
                  fullWidth
                  disabled={!values.standardWeekScale.isSaturday}
                />
              </ContainerInput>
              <ContainerInput item xs>
                <Field
                  component={KeyboardTimePicker}
                  keyboardIcon={<img src={Clock} alt="" />}
                  inputVariant="filled"
                  cancelLabel="CANCELAR"
                  invalidDateMessage="Horário Invalido"
                  ampm={false}
                  placeholder="00:00"
                  name="standardWeekScale.saturday.exitTime"
                  label="Saida"
                  fullWidth
                  disabled={!values.standardWeekScale.isSaturday}
                />
              </ContainerInput>
              <ContainerInput item xs>
                <Field
                  component={TextField}
                  select
                  name="standardWeekScale.saturday.mainBreak"
                  label="Intervalo principal"
                  variant="filled"
                  fullWidth
                  disabled={!values.standardWeekScale.isSaturday}
                >
                  {timeOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </ContainerInput>
            </Grid>
          </Grid>

          <Grid container justify="space-between" mt="40px">
            <Grid container item xs={6} spacing={3} alignItems="center">
              <Grid item xs={2}>
                <Field
                  component={StyledCheckbox}
                  name="standardWeekScale.isWednesday"
                  checked={values.standardWeekScale.isWednesday}
                  color="primary"
                  type="checkbox"
                  size="small"
                  Label={{
                    label: 'QUA',
                  }}
                />
              </Grid>
              <ContainerInput item xs>
                <Field
                  disabled={!values.standardWeekScale.isWednesday}
                  component={KeyboardTimePicker}
                  keyboardIcon={<img src={Clock} alt="" />}
                  inputVariant="filled"
                  cancelLabel="CANCELAR"
                  invalidDateMessage="Horário Invalido"
                  ampm={false}
                  placeholder="00:00"
                  name="standardWeekScale.wednesday.enterTime"
                  label="Entrada"
                  fullWidth
                />
              </ContainerInput>
              <ContainerInput item xs>
                <Field
                  disabled={!values.standardWeekScale.isWednesday}
                  component={KeyboardTimePicker}
                  keyboardIcon={<img src={Clock} alt="" />}
                  inputVariant="filled"
                  cancelLabel="CANCELAR"
                  invalidDateMessage="Horário Invalido"
                  ampm={false}
                  placeholder="00:00"
                  name="standardWeekScale.wednesday.exitTime"
                  label="Saida"
                  fullWidth
                />
              </ContainerInput>
              <ContainerInput item xs>
                <Field
                  disabled={!values.standardWeekScale.isWednesday}
                  component={TextField}
                  select
                  name="standardWeekScale.wednesday.mainBreak"
                  label="Intervalo principal"
                  variant="filled"
                  fullWidth
                >
                  {timeOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </ContainerInput>
            </Grid>
            <Grid container item spacing={3} alignItems="center" xs={6}>
              <Grid item xs={2}>
                <Field
                  component={StyledCheckbox}
                  name="standardWeekScale.isSunday"
                  checked={values.standardWeekScale.isSunday}
                  color="primary"
                  type="checkbox"
                  size="small"
                  Label={{
                    label: 'DOM',
                  }}
                />
              </Grid>
              <ContainerInput item xs>
                <Field
                  disabled={!values.standardWeekScale.isSunday}
                  component={KeyboardTimePicker}
                  keyboardIcon={<img src={Clock} alt="" />}
                  inputVariant="filled"
                  cancelLabel="CANCELAR"
                  invalidDateMessage="Horário Invalido"
                  ampm={false}
                  placeholder="00:00"
                  name="standardWeekScale.sunday.enterTime"
                  label="Entrada"
                  fullWidth
                />
              </ContainerInput>
              <ContainerInput item xs>
                <Field
                  disabled={!values.standardWeekScale.isSunday}
                  component={KeyboardTimePicker}
                  keyboardIcon={<img src={Clock} alt="" />}
                  inputVariant="filled"
                  cancelLabel="CANCELAR"
                  invalidDateMessage="Horário Invalido"
                  ampm={false}
                  placeholder="00:00"
                  name="standardWeekScale.sunday.exitTime"
                  label="Saida"
                  fullWidth
                />
              </ContainerInput>
              <ContainerInput item xs>
                <Field
                  disabled={!values.standardWeekScale.isSunday}
                  select
                  component={TextField}
                  name="standardWeekScale.sunday.mainBreak"
                  label="Intervalo principal"
                  variant="filled"
                  fullWidth
                >
                  {timeOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </ContainerInput>
            </Grid>
          </Grid>

          <Grid container item xs={6} spacing={3} alignItems="center" mt="40px">
            <Grid item xs={2}>
              <Field
                component={StyledCheckbox}
                name="standardWeekScale.isThursday"
                checked={values.standardWeekScale.isThursday}
                color="primary"
                type="checkbox"
                size="small"
                Label={{
                  label: 'QUI',
                }}
              />
            </Grid>
            <ContainerInput item xs>
              <Field
                disabled={!values.standardWeekScale.isThursday}
                component={KeyboardTimePicker}
                keyboardIcon={<img src={Clock} alt="" />}
                inputVariant="filled"
                cancelLabel="CANCELAR"
                invalidDateMessage="Horário Invalido"
                ampm={false}
                placeholder="00:00"
                name="standardWeekScale.thursday.enterTime"
                label="Entrada"
                fullWidth
              />
            </ContainerInput>
            <ContainerInput item xs>
              <Field
                disabled={!values.standardWeekScale.isThursday}
                component={KeyboardTimePicker}
                keyboardIcon={<img src={Clock} alt="" />}
                inputVariant="filled"
                cancelLabel="CANCELAR"
                invalidDateMessage="Horário Invalido"
                ampm={false}
                placeholder="00:00"
                name="standardWeekScale.thursday.exitTime"
                label="Saida"
                fullWidth
              />
            </ContainerInput>
            <ContainerInput item xs>
              <Field
                disabled={!values.standardWeekScale.isThursday}
                select
                component={TextField}
                name="standardWeekScale.thursday.mainBreak"
                label="Intervalo principal"
                variant="filled"
                fullWidth
              >
                {timeOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
            </ContainerInput>
          </Grid>
        </MuiPickersUtilsProvider>
      ) : (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <Grid container spacing={3} item alignItems="center" xs={6}>
            <Grid item xs={2}>
              <Typography color="secondary" fontSize="0.875rem">
                Horário Padrão
              </Typography>
            </Grid>
            <ContainerInput item xs>
              <Field
                component={KeyboardTimePicker}
                keyboardIcon={<img src={Clock} alt="" />}
                inputVariant="filled"
                cancelLabel="CANCELAR"
                invalidDateMessage="Horário Invalido"
                ampm={false}
                placeholder="00:00"
                name="standardWeekScale.standardTime.enterTime"
                label="Entrada"
                fullWidth
              />
            </ContainerInput>
            <ContainerInput item xs>
              <Field
                component={KeyboardTimePicker}
                keyboardIcon={<img src={Clock} alt="" />}
                inputVariant="filled"
                cancelLabel="CANCELAR"
                invalidDateMessage="Horário Invalido"
                ampm={false}
                placeholder="00:00"
                name="standardWeekScale.standardTime.exitTime"
                label="Saida"
                fullWidth
              />
            </ContainerInput>
            <ContainerInput item xs>
              <Field
                component={TextField}
                select
                name="standardWeekScale.standardTime.mainBreak"
                label="Intervalo principal"
                variant="filled"
                fullWidth
              >
                {timeOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
            </ContainerInput>
          </Grid>
          <Box mt={2}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Typography color="secondary" fontSize="0.875rem">
                  Dias da semana trabalhados
                </Typography>
              </Grid>
              <Grid item>
                <Field
                  component={StyledCheckbox}
                  name="standardWeekScale.isMonday"
                  checked={values.standardWeekScale.isMonday}
                  color="primary"
                  type="checkbox"
                  size="small"
                  Label={{
                    label: 'SEG',
                  }}
                />
              </Grid>
              <Grid item>
                <Field
                  component={StyledCheckbox}
                  name="standardWeekScale.isTuesday"
                  checked={values.standardWeekScale.isTuesday}
                  color="primary"
                  type="checkbox"
                  size="small"
                  Label={{
                    label: 'TER',
                  }}
                />
              </Grid>
              <Grid item>
                <Field
                  component={StyledCheckbox}
                  name="standardWeekScale.isWednesday"
                  checked={values.standardWeekScale.isWednesday}
                  color="primary"
                  type="checkbox"
                  size="small"
                  Label={{
                    label: 'QUA',
                  }}
                />
              </Grid>
              <Grid item>
                <Field
                  component={StyledCheckbox}
                  name="standardWeekScale.isThursday"
                  checked={values.standardWeekScale.isThursday}
                  color="primary"
                  type="checkbox"
                  size="small"
                  Label={{
                    label: 'QUI',
                  }}
                />
              </Grid>
              <Grid item>
                <Field
                  component={StyledCheckbox}
                  name="standardWeekScale.isFriday"
                  checked={values.standardWeekScale.isFriday}
                  color="primary"
                  type="checkbox"
                  size="small"
                  Label={{
                    label: 'SEX',
                  }}
                />
              </Grid>
              <Grid item>
                <Field
                  component={StyledCheckbox}
                  name="standardWeekScale.isSaturday"
                  checked={values.standardWeekScale.isSaturday}
                  color="primary"
                  type="checkbox"
                  size="small"
                  Label={{
                    label: 'SAB',
                  }}
                />
              </Grid>
              <Grid item>
                <Field
                  component={StyledCheckbox}
                  name="standardWeekScale.isSunday"
                  checked={values.standardWeekScale.isSunday}
                  color="primary"
                  type="checkbox"
                  size="small"
                  Label={{
                    label: 'DOM',
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </MuiPickersUtilsProvider>
      )}
    </>
  );
};

StandardWeekScale.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  opacity: PropTypes.number.isRequired,
  handleOpacity: PropTypes.func.isRequired,
  timeOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default StandardWeekScale;
