import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  width: 100%;
  ${({ isCollaborator }) => css`
    background: ${isCollaborator ? '#fbfbfb' : '#fff'};
    height: ${isCollaborator ? '76px' : '52px'};
    img {
      width: 100%;
      max-width: ${isCollaborator ? '30px' : '19px'};
      max-height: 100%;
    }
  `}
  box-shadow: ${props =>
    `0px 3px 6px #00000029, 0 4px 2px -2px ${props.color}`};
  border-radius: 6px;


`;

export const StyledSpan = styled.span`
  ${props => css`
    font-weight: ${props.fontWeight};
    font-size: ${props.fontSize}px;
  `}
`;
