import { Box } from '@material-ui/core';
import { endOfMonth, startOfMonth } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TabPanel } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { FilterText, TabContainer } from '../styles';
import TableFilter from '../TableFilter';
import TableGeneralRecords from '../TableGeneralRecords';

const GeneralRecords = props => {
  const { value, index, ...other } = props;

  const [filters, setFilters] = useState({
    department: '',
    jobRole: '',
    limit: 5,
    page: 1,
    order: 'asc',
    orderBy: 'fullName',
    periodStartDate: startOfMonth(new Date()),
    periodEndDate: endOfMonth(new Date()),
    text: '',
  });
  const [recordsData, setRecordsData] = useState({
    docs: [],
    page: 1,
    limit: 5,
    pages: 1,
    total: 0,
  });

  const { user } = useAuth();
  const dispatch = useDispatch();

  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleClick = useCallback(event => {
  //   setAnchorEl(event.currentTarget);
  // }, []);

  // const handleClose = useCallback(() => {
  //   setAnchorEl(null);
  // }, []);

  useEffect(() => {
    async function getRecordsData() {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();
        const { data } = await api.get(
          `/point-registers/records/${user.company._id}`,
          {
            params: filters,
          },
        );
        setRecordsData(data);
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao buscar dados'));
      } finally {
        dispatch(setLoading(false));
      }
    }
    getRecordsData();
  }, [dispatch, filters, user.company._id]);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <TableFilter setFilters={setFilters} filters={filters} />
        <Box mt={4}>
          <FilterText>Colaboradores</FilterText>
          <TableGeneralRecords
            setFilters={setFilters}
            recordsData={recordsData}
          />
        </Box>
      </TabContainer>
    </TabPanel>
  );
};

GeneralRecords.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default GeneralRecords;
