import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Checkbox, Button, Box } from '@material-ui/core';

import { AuthenticatedHttpClient } from '../../services/api';
import { setLoading } from '../../redux/ducks/loading';
import { setSnackbar } from '../../redux/ducks/snackbar';

import TimeManagement from '../../assets/Time-management.svg';

import ModalSkeleton from '../ModalSkeleton';
import {
  DivCheckbox,
  StyledTypography,
  StyledFormControlLabel,
} from './styles';

const ActiveColaboratorModal = ({ handleClickOnClose, collaboratorId }) => {
  const [recordPoints, setRecordPoints] = useState(false);

  const dispatch = useDispatch();

  const handleActivateCollaborator = useCallback(async () => {
    try {
      dispatch(setLoading(true));

      const api = AuthenticatedHttpClient();
      await api.put(`users/activate/${collaboratorId}`, {
        recordPoints,
      });

      dispatch(setSnackbar(true, 'success', 'Colaborador ativado'));
      handleClickOnClose(true);
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Falha ao ativar o colaborador'));
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch, collaboratorId, recordPoints, handleClickOnClose]);

  return (
    <ModalSkeleton
      title="Ativar colaborador"
      handleClickOnClose={() => handleClickOnClose(false)}
      maxWidth="900px"
    >
      <Box display="flex" flexDirection="column" p={4}>
        <StyledTypography
          align="left"
          fontSize="18px"
          fontWeight="light"
          color="secondary"
        >
          Você está prestes a ativar esse colaborador, a partir do momento que
          confirmar sua ativação será contado o período de experiência,
          portanto, certifique-se que todos os dados estão corretos!
        </StyledTypography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent="center"
          my={4}
        >
          <img src={TimeManagement} alt="TimeManagement" />
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            ml={2}
          >
            <Typography variant="h3">MÓDULO DE REGISTRO DE PONTOS</Typography>
            <StyledTypography
              mt="28px"
              mb="25px"
              align="left"
              fontSize="18px"
              fontWeight="light"
              color="secondary"
            >
              Ipsam neque eos ea et ullam ducimus veniam quod possimus.
              Repudiandae et corporis. Qui vitae est accusantium est quaerat
              vero. Est vel unde officia recusandae velit aut omnis.
            </StyledTypography>
            <DivCheckbox>
              <StyledFormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    checked={recordPoints}
                    onChange={e => setRecordPoints(e.target.checked)}
                  />
                }
                label="Ativar módulo de pontos"
              />
            </DivCheckbox>
          </Box>
        </Box>
        <Box
          display="flex"
          width={1}
          alignItems="center"
          justifyContent="flex-end"
          mt={2}
        >
          <Button onClick={() => handleClickOnClose(false)}>
            <StyledTypography variant="h3" fontWeight="bold">
              CANCELAR
            </StyledTypography>
          </Button>
          <Button onClick={handleActivateCollaborator}>
            <Typography fontWeight="bold" variant="h3">
              ATIVAR COLABORADOR
            </Typography>
          </Button>
        </Box>
      </Box>
    </ModalSkeleton>
  );
};

ActiveColaboratorModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
  collaboratorId: PropTypes.string.isRequired,
};
export default ActiveColaboratorModal;
