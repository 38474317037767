import { Button, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, ModalSkeleton } from '../../../components';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { REQUEST_CHANGES } from '../../../utils/enums';
import CompensationHoursInfo from './Infos/CompensationHoursInfo';
import MedicalCertificateOrDayOffInfo from './Infos/MedicalCertificateOrDayOffInfo';
import PointAdjustInfo from './Infos/PointAdjustInfo';
import {
  ActionsBar,
  ButtonWithColor,
  ContainerModal,
  StyledGrid,
} from './styles';

const RequestModal = ({
  handleOnClose,
  values,
  reasonLabel,
  setForceGetRequests,
  setReprovedModalOpen,
  setForceGetCollaboratorsCompTime,
}) => {
  const { reason, date, _id } = values;
  const dispatch = useDispatch();

  const handleApproveRequest = async () => {
    try {
      const api = AuthenticatedHttpClient();
      await api.put(`point-solicitation/approve/${_id}`);
      setForceGetRequests(prevState => !prevState);
      setForceGetCollaboratorsCompTime(prevState => !prevState);
      dispatch(setSnackbar(true, 'success', 'Solicitação Aprovada'));
    } catch (error) {
      dispatch(
        setSnackbar(true, 'error', 'Não foi possível aprovar a solicitação'),
      );
    } finally {
      handleOnClose();
    }
  };

  const isMedicalCertificateOrDayOff =
    reason === REQUEST_CHANGES.REQUEST_DAY_OFF ||
    reason === REQUEST_CHANGES.MEDICAL_CERTIFICATE;

  return (
    <ModalSkeleton
      title={reasonLabel[reason].toUpperCase()}
      handleClickOnClose={handleOnClose}
      maxWidth="916px"
    >
      <ContainerModal>
        <Grid container alignItems="center" justify="space-between" spacing={3}>
          <StyledGrid item xs={6}>
            <TextField
              name="date"
              label="Data da solicitação"
              variant="filled"
              value={date}
              disabled
              fullWidth
            />
          </StyledGrid>
          <StyledGrid item xs={6}>
            <TextField
              name="reason"
              label="Motivo"
              variant="filled"
              value={reasonLabel[reason]}
              disabled
              fullWidth
            />
          </StyledGrid>
          {reason === REQUEST_CHANGES.POINTS_ADJUST && (
            <PointAdjustInfo values={values} />
          )}
          {isMedicalCertificateOrDayOff && (
            <MedicalCertificateOrDayOffInfo reason={reason} values={values} />
          )}
          {reason === REQUEST_CHANGES.HOURS_COMPENSATION && (
            <CompensationHoursInfo values={values} />
          )}
        </Grid>
        <ActionsBar container alignItems="center" justify="flex-end">
          <StyledGrid mt="32px">
            <ButtonWithColor onClick={setReprovedModalOpen} size="small">
              REPROVAR
            </ButtonWithColor>
            <Button
              onClick={handleApproveRequest}
              color="secondary"
              size="small"
            >
              {reason === REQUEST_CHANGES.POINTS_ADJUST ? 'AJUSTAR' : 'APROVAR'}
            </Button>
          </StyledGrid>
        </ActionsBar>
      </ContainerModal>
    </ModalSkeleton>
  );
};

RequestModal.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  reasonLabel: PropTypes.objectOf(PropTypes.any).isRequired,
  setForceGetRequests: PropTypes.func.isRequired,
  setReprovedModalOpen: PropTypes.func.isRequired,
  setForceGetCollaboratorsCompTime: PropTypes.func.isRequired,
};

export default RequestModal;
