import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
} from '@material-ui/core';

import { StyledIconButton, StyledTableCell } from './styles';

import editIcon from '../../assets/edit.svg';

import truncateString from '../../utils/truncateString';

function createData(
  date,
  input,
  output,
  hours,
  pause,
  justify,
  attachment,
  reason,
  approval,
) {
  return {
    date,
    input,
    output,
    hours,
    pause,
    justify,
    attachment,
    reason,
    approval,
  };
}

const rows = [
  createData(
    '27/06/2020',
    '08:00',
    '18:00',
    '08:00',
    '01:00',
    'Fiquei doente',
    'atestado.jpg',
    'Atestado',
    'Aprovado',
  ),
  createData(
    '26/06/2020',
    '08:00',
    '18:00',
    '08:00',
    '01:00',
    'Fiquei doente Fiquei doente Fiquei doente Fiquei doente Fiquei doente Fiquei doente ',
    'atestado.jpg',
    'Atestado',
    'Aprovado',
  ),
  createData(
    '25/06/2020',
    '08:00',
    '18:00',
    '08:00',
    '01:00',
    'Fiquei doente',
    'atestado.jpg',
    'Atestado',
    'Aprovado',
  ),
  createData(
    '24/06/2020',
    '08:00',
    '18:00',
    '08:00',
    '01:00',
    'Fiquei doente',
    'atestado.jpg',
    'Atestado',
    'Aprovado',
  ),
  createData(
    '23/06/2020',
    '08:00',
    '18:00',
    '08:00',
    '01:00',
    'Fiquei doente',
    'atestado.jpg',
    'Atestado',
    'Aprovado',
  ),
  createData(
    '22/06/2020',
    '08:00',
    '18:00',
    '08:00',
    '01:00',
    'Fiquei doente',
    'atestado.jpg',
    'Atestado',
    'Aprovado',
  ),
];

const headCells = [
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Data',
  },
  { id: 'input', numeric: false, disablePadding: false, label: 'Entrada' },
  { id: 'output', numeric: false, disablePadding: false, label: 'Saída' },
  { id: 'hours', numeric: false, disablePadding: false, label: 'Horas' },
  { id: 'pause', numeric: false, disablePadding: false, label: 'Pausa' },
  {
    id: 'justify',
    numeric: false,
    disablePadding: false,
    label: 'Justificativa',
  },
  {
    id: 'attachment',
    numeric: false,
    disablePadding: false,
    label: 'Anexos',
  },
  {
    id: 'reason',
    numeric: false,
    disablePadding: false,
    label: 'Motivo',
  },
  { id: 'approval', numeric: false, disablePadding: false, label: 'Aprovação' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Ação</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTable = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    console.log(event.target.value, name);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Paper elevation={0}>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.date}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          onClick={event => handleClick(event, row.name)}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" id={labelId} scope="row">
                        {row.date}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.input}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.output}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.hours}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.pause}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <u>{truncateString(row.justify, 15)}</u>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.attachment}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.reason}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.approval}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <StyledIconButton>
                          <img src={editIcon} alt="" />
                        </StyledIconButton>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default EnhancedTable;
