import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Avatar,
  useTheme,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

import { setSnackbar } from '../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../services/api';
import { useAuth } from '../../hooks/auth';
import formatMoney from '../../utils/formatMoney';

import CollaboratorBackground from '../../assets/collaborator-home.svg';

import { Typography, Grid } from '../../components';

import {
  StyledImage,
  StyledName,
  Company,
  HiringInfo,
  CheckBoxApproval,
  StyledButton,
} from './styles';

const AdmissionHome = () => {
  const [userData, setUserData] = useState({});
  const [userApproval, setUserApproval] = useState(false);

  const history = useHistory();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    async function getUserData() {
      try {
        const api = AuthenticatedHttpClient();

        const { data } = await api.get(`users/${user._id}`);
        const { hiringData } = data;

        const formatedUserData = {
          ...data,
          hiringData: {
            regime: hiringData.regime,
            salary: formatMoney(hiringData.salary),
            va: formatMoney(hiringData.va),
            vt: formatMoney(hiringData.vt),
            vr: formatMoney(hiringData.vr),
          },
          limitDate: new Date(data.limitDate).toLocaleDateString('pt-br'),
        };

        setUserData(formatedUserData);
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possível carregar os dados'),
        );
      }
    }

    getUserData();
  }, [user, dispatch]);

  const handleChange = useCallback(event => {
    setUserApproval(event.target.checked);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!userApproval) {
      dispatch(
        setSnackbar(true, 'error', 'Para avançar concorde com os termos'),
      );
      return;
    }
    try {
      const api = AuthenticatedHttpClient();

      await api.put(`invite/${user.invite._id}`, {
        accepted: true,
      });

      dispatch(setSnackbar(true, 'success', 'Termos aceitos'));

      history.push(`/steps/1`);
    } catch (error) {
      dispatch(
        setSnackbar(
          true,
          'error',
          'Falha na requisição, tente novamente mais tarde',
        ),
      );
    }
  }, [history, userApproval, dispatch, user]);

  return (
    <Grid container alignItems="center" height="100vh">
      <Grid
        item
        lg={7}
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Typography variant="h2" fontWeight={300}>
          Olá, <StyledName>{userData.fullName}</StyledName>.
        </Typography>
        <Typography align="center" fontWeight="bold" fontSize="16px" mt="24px">
          Pronto para começar?
        </Typography>
        <Typography
          width="530px"
          align="center"
          fontWeight={300}
          fontSize="16px"
          mt="24px"
        >
          O processo é simples, você entrará em um passo a passo onde deverá
          preencher alguns dados pessoais ao qual serão arquivados em sua ficha,
          portanto é muito importante você preencher todos os dados
          corretamente, caso identificarmos algum erro, você será notificado e
          deverá preencher novamente de forma correta.
        </Typography>
        <StyledImage src={CollaboratorBackground} alt="" />
      </Grid>
      <Grid
        item
        lg={5}
        container
        direction="column"
        justify="center"
        bgcolor={palette.primary.main}
        height="100%"
        pr="5%"
        pl="5%"
      >
        <Typography
          mb="24px"
          fontSize="16px"
          fontWeight={300}
          textcolor="#fff"
          textalign="justify"
        >
          Antes de iniciarmos o processo de preenchimento do formulário, veja
          abaixo as informações da vaga que estamos lhe contratando:
        </Typography>
        <Company>
          <Avatar />
          <div>
            <Typography fontWeight="700" fontSize="12px" textcolor="#fff">
              Admitido pela
            </Typography>
            <Typography fontWeight="700" fontSize="22px" textcolor="#fff">
              {userData.company && userData.company.name}
            </Typography>
          </div>
        </Company>
        <HiringInfo>
          <Grid container alignItems="center" justify="space-between">
            <Grid container direction="column" item lg={6}>
              <Typography fontSize="16px" fontWeight={700} textcolor="#fff">
                Cargo
              </Typography>
              <Typography fontSize="16px" fontWeight={300} textcolor="#fff">
                {userData.jobRole && userData.jobRole.name}
              </Typography>
            </Grid>
            <Grid container direction="column" item lg={6} mt="8px">
              <Typography fontSize="16px" fontWeight={700} textcolor="#fff">
                Salário
              </Typography>
              <Typography fontSize="16px" fontWeight={300} textcolor="#fff">
                {userData.hiringData && userData.hiringData.salary}
              </Typography>
            </Grid>
            <Grid container direction="column" item lg={6} mt="8px">
              <Typography fontSize="16px" fontWeight={700} textcolor="#fff">
                Regime trabalhista
              </Typography>
              <Typography fontSize="16px" fontWeight={300} textcolor="#fff">
                {userData.hiringData && userData.hiringData.regime}
              </Typography>
            </Grid>
            <Grid container direction="column" item lg={6} mt="8px">
              <Typography fontSize="16px" fontWeight={700} textcolor="#fff">
                Vale transporte
              </Typography>
              <Typography fontSize="16px" fontWeight={300} textcolor="#fff">
                {userData.hiringData && userData.hiringData.vt}
              </Typography>
            </Grid>
            <Grid container direction="column" item lg={6} mt="8px">
              <Typography fontSize="16px" fontWeight={700} textcolor="#fff">
                Vale alimentação
              </Typography>
              <Typography fontSize="16px" fontWeight={300} textcolor="#fff">
                {userData.hiringData && userData.hiringData.va}
              </Typography>
            </Grid>
            <Grid container direction="column" item lg={6} mt="8px">
              <Typography fontSize="16px" fontWeight={700} textcolor="#fff">
                Vale Refeição
              </Typography>
              <Typography fontSize="16px" fontWeight={300} textcolor="#fff">
                {userData.hiringData && userData.hiringData.vr}
              </Typography>
            </Grid>
            <CheckBoxApproval>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={userApproval}
                    onChange={handleChange}
                    name="userApproval"
                  />
                }
                label="Concordo com as informações apresentadas acima"
              />
            </CheckBoxApproval>
          </Grid>
        </HiringInfo>
        <Grid container justify="space-between" alignItems="center" mt="40px">
          <Typography fontSize="14px" fontWeight={300} textcolor="#fff">
            Data limite para preencher: <b>{userData.limitDate}</b>
          </Typography>
          <StyledButton onClick={handleSubmit}>VAMOS COMEÇAR</StyledButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdmissionHome;
