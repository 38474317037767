import {
  Box,
  Button,
  DialogContent,
  Grid,
  Modal,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { AccountBox } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Return from '../../assets/arrow-left.svg';
import CloseX from '../../assets/CloseX.svg';
import {
  ActiveColaboratorModal,
  CollaboratorButton,
  ProgressBar,
  Stepper,
  Tab,
  Tabs,
  Typography,
} from '../../components';
import useUser from '../../hooks/useUser';
import { STEPS } from '../../utils/enums';
import getFileUrlFor from '../../utils/getFIleUrlFor';
import ResetPasswordModal from './ResetPasswordModal';
import {
  Container,
  DetailItem,
  DetailPaper,
  DivProgressBar,
  ImageContainer,
  StyledButtonBase,
  StyledButtonModalOpen,
  StyledDivModal,
  StyledDivModalClose,
  StyledGrid,
  StyledImgExpanded,
  TabsPaper,
  UserInfo,
} from './styles';
import {
  Address,
  BankData,
  Documentation,
  FamilyData,
  HiringData,
  PersonalData,
} from './Tabs/index';

const AdmissionColaborator = () => {
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [openActiveModal, setOpenActiveModal] = useState(false);
  const [user, setUser] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);

  const { palette } = useTheme();
  const history = useHistory();
  const { userId: collaboratorId } = useParams();

  const matches = useMediaQuery('(min-width:1600px)');

  const { user: userData, userInfo: userInfoData, getUserData } = useUser(
    collaboratorId,
  );

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
    if (userInfoData) {
      setUserInfo(userInfoData);
    }
  }, [userData, userInfoData]);

  useEffect(() => {
    const { steps } = user;
    if (steps) {
      const allAproved = Object.values(steps).every(
        step => step.status === 'approved',
      );
      if (allAproved) setOpenActiveModal(true);
    }
  }, [user]);

  const admissionDate = new Date(user.admissionDate).toLocaleDateString(
    'pt-br',
  );

  const handleChangeTab = useCallback((_event, newValue) => {
    setTabValue(newValue);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCloseActiveModal = useCallback(
    active => {
      setOpenActiveModal(false);
      if (active) {
        history.replace('/home/admissions');
      }
    },
    [history],
  );

  const photoExpanded = (
    <StyledDivModal>
      <StyledDivModalClose>
        <Button onClick={handleClose}>
          <img src={CloseX} alt="" />
        </Button>
      </StyledDivModalClose>
      {user.personalData && user.personalData.profilePicture && (
        <StyledImgExpanded
          src={getFileUrlFor(user.personalData.profilePicture.key)}
          alt=""
        />
      )}
    </StyledDivModal>
  );

  return (
    <>
      <Container>
        <StyledButtonBase onClick={() => history.goBack()}>
          <img src={Return} alt="" />
        </StyledButtonBase>
        <Grid container alignItems="center" justify="space-between">
          {user._id && (
            <UserInfo>
              {user.personalData && user.personalData.profilePicture ? (
                <StyledButtonModalOpen onClick={handleOpen}>
                  {user.personalData && user.personalData.profilePicture && (
                    <ImageContainer
                      image={getFileUrlFor(
                        user.personalData.profilePicture.key,
                      )}
                    />
                  )}
                </StyledButtonModalOpen>
              ) : (
                <AccountBox color="primary" fontSize="large" />
              )}

              <Modal open={open} onClose={handleClose}>
                {photoExpanded}
              </Modal>

              <div>
                <strong>{user.fullName.toUpperCase()}</strong>
                <span>{user.jobRole.name.toUpperCase()}</span>
                <span style={{ fontWeight: 300 }}>
                  {user.department.name.toUpperCase()}
                </span>
              </div>
            </UserInfo>
          )}
          <Box display="flex" justify="center" alignItems="center">
            <div>
              <Typography
                fontSize="18px"
                display="inline"
                align="center"
                pa="16px"
                fontWeight={800}
              >
                Não recebeu o email?
              </Typography>
              <CollaboratorButton
                text="REENVIAR CONVITE"
                onClick={() => setIsResetPasswordOpen(true)}
              />
            </div>
          </Box>
          <Modal
            open={isResetPasswordOpen}
            onClose={() => setIsResetPasswordOpen(false)}
            aria-labelledby="add-collaborator-title"
            aria-describedby="simple-modal-description"
          >
            <DialogContent>
              <ResetPasswordModal
                handleOnClose={() => setIsResetPasswordOpen(false)}
                userId={collaboratorId}
              />
            </DialogContent>
          </Modal>
        </Grid>
        <DetailPaper elevation={1}>
          <Typography color="primary" variant="h3">
            PROCESSO DE ADMISSÃO
          </Typography>
          {user._id && (
            <StyledGrid
              container
              alignItems="flex-start"
              justify="space-between"
              mt={16}
            >
              <DetailItem>
                <span>Regime trabalhista</span>
                <p>{user.hiringData.regime}</p>
              </DetailItem>

              <DetailItem>
                <span>Data admissão</span>
                <p>{admissionDate}</p>
              </DetailItem>

              <DetailItem>
                <span>Qualificação E-Social</span>
                <p>???</p>
              </DetailItem>

              <DetailItem>
                <span>Limite de preenchimento</span>
                <p>{new Date(user.limitDate).toLocaleDateString('pt-br')}</p>
              </DetailItem>

              <DetailItem>
                <span>Preenchimento</span>
                <Box
                  fontSize="12px"
                  component={Grid}
                  mt={1}
                  flexDirection="row"
                  display="flex"
                >
                  <span>{user.percentageFilled}%</span>
                  <ProgressBar
                    value={user.percentageFilled}
                    width={matches ? '150px' : '120px'}
                    color={palette.primary.main}
                  />
                </Box>
              </DetailItem>
              <DetailItem>
                <span>Etapas</span>
                <DivProgressBar mb="5px" />
                <Stepper
                  statusStepper={{
                    HIRING_DATA: user.steps.HIRING_DATA.status,
                    PERSONAL_DATA: user.steps.PERSONAL_DATA.status,
                    FAMILY_DATA: user.steps.FAMILY_DATA.status,
                    ADDRESS: user.steps.ADDRESS.status,
                    DOCUMENTS: user.steps.DOCUMENTS.status,
                    BANK_DATA: user.steps.BANK_DATA.status,
                  }}
                />
              </DetailItem>
            </StyledGrid>
          )}
        </DetailPaper>
        <TabsPaper square elevation={1}>
          <Tabs value={tabValue} onChange={handleChangeTab}>
            <Tab label="DADOS CONTRATAÇÃO" index={0} />
            <Tab label="DADOS PESSOAIS" index={1} />
            <Tab label="DADOS DA FAMÍLIA" index={2} />
            <Tab label="DOCUMENTAÇÃO" index={3} />
            <Tab label="ENDEREÇO" index={4} />
            <Tab label="DADOS BANCÁRIOS" index={5} />
          </Tabs>
          {user.steps && (
            <>
              <HiringData
                value={tabValue}
                index={0}
                admissionDate={admissionDate}
                setUser={setUser}
                stepStatus={user.steps[STEPS.HIRING_DATA]}
                data={user.hiringData}
                limitDate={user.limitDate}
                getUserData={getUserData}
                handleChangeTab={(e, value) => handleChangeTab(e, value)}
              />
              <PersonalData
                value={tabValue}
                index={1}
                stepStatus={user.steps[STEPS.PERSONAL_DATA]}
                data={user.personalData}
                userInfo={userInfo}
              />
              <FamilyData
                value={tabValue}
                index={2}
                stepStatus={user.steps[STEPS.FAMILY_DATA]}
                data={user.familyData}
                setUser={setUser}
                handleChangeTab={(e, value) => handleChangeTab(e, value)}
              />
              <Documentation
                value={tabValue}
                index={3}
                stepStatus={user.steps[STEPS.DOCUMENTS]}
                data={user.documents}
                setUser={setUser}
                handleChangeTab={(e, value) => handleChangeTab(e, value)}
              />
              <Address
                value={tabValue}
                index={4}
                stepStatus={user.steps[STEPS.ADDRESS]}
                data={user.address}
                setUser={setUser}
                handleChangeTab={(e, value) => handleChangeTab(e, value)}
              />
              <BankData
                value={tabValue}
                index={5}
                stepStatus={user.steps[STEPS.BANK_DATA]}
                data={user.bankData}
                setUser={setUser}
              />
            </>
          )}
        </TabsPaper>
      </Container>
      <Modal open={openActiveModal} onClose={() => setOpenActiveModal(false)}>
        <DialogContent>
          <ActiveColaboratorModal
            handleClickOnClose={handleCloseActiveModal}
            collaboratorId={collaboratorId}
          />
        </DialogContent>
      </Modal>
    </>
  );
};

export default AdmissionColaborator;
