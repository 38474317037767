import DateFnsUtils from '@date-io/date-fns';
import { Box, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardTimePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React from 'react';
import Clock from '../../../../assets/Clock.svg';
import { Grid } from '../../../../components';
import { ContainerInput } from '../styles';

const BreaksInput = props => {
  const { breakOptions, values } = props;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      {values.breaks.map((pause, breakIndex) => {
        return (
          <Box mt={2} key={pause.id}>
            <Grid item container spacing={3}>
              <ContainerInput item xs={4}>
                <Field
                  component={TextField}
                  name={`breaks.${breakIndex}.description`}
                  label="Descrição"
                  variant="filled"
                  fullWidth
                />
              </ContainerInput>
              <ContainerInput item xs={4}>
                <Field
                  component={TextField}
                  select
                  name={`breaks.${breakIndex}.time`}
                  variant="filled"
                  fullWidth
                  label="Tempo"
                >
                  {breakOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </ContainerInput>
              <ContainerInput item xs={4}>
                <Field
                  component={KeyboardTimePicker}
                  keyboardIcon={<img src={Clock} alt="" />}
                  placeholder="00:00"
                  name={`breaks.${breakIndex}.start`}
                  label="Início"
                  inputVariant="filled"
                  cancelLabel="CANCELAR"
                  fullWidth
                  invalidDateMessage="Horário Inválido"
                  ampm={false}
                />
              </ContainerInput>
            </Grid>
          </Box>
        );
      })}
    </MuiPickersUtilsProvider>
  );
};

BreaksInput.propTypes = {
  breakOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default BreaksInput;
