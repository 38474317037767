import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';

export const StyledGrid = styled(Grid)`
  ${props => css`
    width: ${props.width};
    height: ${props.height};

    background-color: ${props.bgcolor};

    margin: ${props.ma};
    margin-left: ${props.ml};
    margin-right: ${props.mr};
    margin-bottom: ${props.mb};
    margin-top: ${props.mt};

    padding: ${props.pa};
    padding-top: ${props.pt};
    padding-left: ${props.pl};
    padding-right: ${props.pr};
    padding-bottom: ${props.pb};
  `}
`;
