import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;

  div {
    h1 {
      color: #343434;
      font-size: 18px;
      font-weight: bold;
    }

    p {
      text-align: justify;
      margin-top: 24px;
      color: ${({ theme }) => theme.palette.secondary.main};
      opacity: 0.5;
      font-size: 16px;
      font-weight: 300;
    }
  }
`;

export const CloseModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: 16px 32px;
`;

export const ButtonText = styled(Typography)`
  color: #343434;
  font-size: 16px;
  font-weight: bold;
`;
