import styled from 'styled-components';
import { Paper, Grid } from '@material-ui/core';

export const Container = styled.div`
  padding: 24px;
`;

export const TabsPaper = styled(Paper)`
  margin-top: 16px;
  min-height: 810px;
`;

export const TabContainer = styled.div`
  padding: 16px;
  padding-bottom: 50px;
`;

export const ImageStyledGrid = styled(Grid)`
  min-height: 750px;
`;
