import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setSnackbar } from '../redux/ducks/snackbar';
import { UnauthenticatedHttpClient } from '../services/api';
import { getToken, getUser, login, logout } from '../services/auth';
import { ROLES } from '../utils/enums';

const AuthContext = createContext({});

const api = UnauthenticatedHttpClient();

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = getToken();
    const user = getUser();

    if (token && user) {
      return { token, user };
    }

    return {};
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const signIn = useCallback(
    async ({ email, password }) => {
      try {
        const response = await api.post('auth', {
          email,
          password,
        });

        const { token, user } = response.data;

        if (
          user.role === ROLES.ADMINISTRATOR_COMPANY ||
          user.role === ROLES.HUMAN_RESOURCES_MANAGMENT
        ) {
          login({ user, token });
          setData({ user, token });
          history.push('/home/admissions');
          dispatch(setSnackbar(true, 'success', 'Login realizado com sucesso'));
        } else if (
          user.role === ROLES.ADMISSION_PROCESS ||
          user.role === ROLES.WAITING_CONTRACT_SIGN
        ) {
          history.push('/admission-process');
          dispatch(
            setSnackbar(true, 'info', 'Complete o processo de admissão'),
          );
        } else if (user.role === ROLES.EMPLOYEE) {
          login({ user, token });
          setData({ user, token });
          history.push('/colaborator-home');
          dispatch(setSnackbar(true, 'success', 'Login realizado com sucesso'));
        }
      } catch (error) {
        if (!error.response) {
          dispatch(setSnackbar(true, 'error', 'Serviço indisponível'));
        } else if (error.response.status === 403) {
          dispatch(setSnackbar(true, 'error', 'O usuário está desativado'));
        } else {
          dispatch(setSnackbar(true, 'error', 'Falha ao realizar login'));
        }
      }
    },
    [dispatch, history],
  );

  const signInInvite = useCallback(
    async ({ email, password }) => {
      try {
        const response = await api.post(`/invite/auth`, {
          email,
          password,
        });

        const { token, user, invite } = response.data;

        await api.put(`/invite/${invite._id}`, {
          opened: true,
        });

        const userWithInvite = {
          ...user,
          invite: {
            ...invite,
            opened: true,
          },
        };

        dispatch(setSnackbar(true, 'success', 'Login realizado com sucesso'));

        if (invite.concluded) {
          history.push('/');
          dispatch(
            setSnackbar(true, 'success', 'Processo de admissão já finalizado'),
          );
        } else if (invite.emailVerify) {
          login({ user: userWithInvite, token });
          setData({ user: userWithInvite, token });
          history.push('/steps/create-password');
        } else if (invite.accepted) {
          login({ user: userWithInvite, token });
          setData({ user: userWithInvite, token });
          history.push('/steps/1');
        } else {
          login({ user: userWithInvite, token });
          setData({ user: userWithInvite, token });
          history.push('/collaborator');
        }
      } catch (error) {
        if (!error.response) {
          dispatch(setSnackbar(true, 'error', 'Serviço indisponível'));
        } else {
          dispatch(setSnackbar(true, 'error', 'Falha ao realizar login'));
        }
      }
    },
    [dispatch, history],
  );

  const signOut = useCallback(() => {
    logout();
    setData({});
  }, []);

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, signInInvite }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('There is no context.');
  }

  return context;
}

export { AuthProvider, useAuth };
