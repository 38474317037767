const checkCpfValidation = (dependents = [], collaboratorCpf) => {
  const isDuplicated = dependents.filter(({ dependentCpf }) => {
    return collaboratorCpf === dependentCpf;
  });
  if (isDuplicated.length) {
    return false;
  }
  return true;
};

export default checkCpfValidation;
