import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Form } from 'formik';

export const ContainerInput = styled(Grid)`
  height: 80px;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : 0)};

  input {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;
