import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

function ProgressBar({ value, max, color, width, height }) {
  return (
    <Container $color={color} $width={width} $height={height}>
      <progress value={value} max={max} />
    </Container>
  );
}

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ProgressBar.defaultProps = {
  max: 100,
  color: 'blue',
  width: '250px',
  height: '17px',
};

export default ProgressBar;
