import { Box, Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  EmptyState,
  PaySlipList,
  SelectFilter,
  TabPanel,
  Typography,
} from '../../../components/index';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { TabContainer } from '../styles';

const NUMBER_GET_ALL_PAYSLIPS = -1;
const DateReducer = (currentDate, action) => {
  switch (action.type) {
    case 'clearDate':
      return {
        ...currentDate,
        month: NUMBER_GET_ALL_PAYSLIPS,
        year: NUMBER_GET_ALL_PAYSLIPS,
      };
    case 'setMonth':
      return { ...currentDate, month: action.month };
    case 'setYear':
      return { ...currentDate, year: action.year };
    case 'setMonthAndYear':
      return { ...currentDate, year: action.year, month: action.month };

    default:
      return { currentDate };
  }
};
const PaySlip = props => {
  const { value, index, stepStatus, setUser, admissionDate, ...other } = props;
  const [date, dispatchDate] = useReducer(DateReducer, {
    month: NUMBER_GET_ALL_PAYSLIPS,
    year: NUMBER_GET_ALL_PAYSLIPS,
  });
  const dispatch = useDispatch();
  const [payslips, setPayslips] = useState([]);
  const { userId: collaboratorId } = useParams();
  const organizePaySlip = arrayPayslips => {
    const newPayslips = arrayPayslips.map(element => {
      const namedDate = format(new Date(element.date), 'MMMM - yyyy', {
        locale: ptBR,
      });
      const formatedDate =
        namedDate.charAt(0).toUpperCase() + namedDate.slice(1);
      return {
        ...element,
        date: formatedDate,
      };
    });
    return newPayslips;
  };
  const getPaySlips = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const api = AuthenticatedHttpClient();
      let route;
      let correctionMonth;
      if (
        date.year === NUMBER_GET_ALL_PAYSLIPS &&
        date.month === NUMBER_GET_ALL_PAYSLIPS
      ) {
        route = `payslip/${collaboratorId}`;
      } else if (
        date.year === NUMBER_GET_ALL_PAYSLIPS &&
        date.month !== NUMBER_GET_ALL_PAYSLIPS
      ) {
        correctionMonth = date.month + 1;
        route = `payslip/${collaboratorId}?month=${correctionMonth}`;
      } else if (
        date.year !== NUMBER_GET_ALL_PAYSLIPS &&
        date.month === NUMBER_GET_ALL_PAYSLIPS
      ) {
        route = `payslip/${collaboratorId}?year=${date.year}`;
      } else {
        correctionMonth = date.month + 1;
        route = `payslip/${collaboratorId}?month=${correctionMonth}&year=${date.year}`;
      }
      const { data } = await api.get(route);
      if (data.length === 0) {
        setPayslips([]);
      } else {
        const formatedPayslips = organizePaySlip(data[0].payslips);
        setPayslips(formatedPayslips);
      }
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Não foi possível pegar os dados'));
    } finally {
      dispatch(setLoading(false));
    }
  }, [collaboratorId, date, dispatch]);

  useEffect(() => {
    getPaySlips();
  }, [getPaySlips]);

  const generateYearOptions = () => {
    const actualYear = new Date().getFullYear();
    const admissionYear = new Date(admissionDate).getFullYear();
    const years = [];
    for (let i = admissionYear; i <= actualYear; i += 1) {
      years.push({
        label: i.toString(),
        value: i,
      });
    }
    return years;
  };

  const generateMonthOptions = year => {
    const months = [];
    const admissionMonth = new Date(admissionDate).getMonth();
    const admissionYear = new Date(admissionDate).getFullYear();
    const actualYear = new Date().getFullYear();
    const actualMonth = new Date().getMonth();
    let finalMonth = 11;
    let initialMonth = 0;

    if (admissionYear === year) {
      initialMonth = admissionMonth;
    }
    if (year === actualYear) {
      finalMonth = actualMonth;
    }
    for (let i = initialMonth; i <= finalMonth; i += 1) {
      const namedDate = format(new Date(2020, i), 'MMMM', {
        locale: ptBR,
      });
      const formatedDate =
        namedDate.charAt(0).toUpperCase() + namedDate.slice(1);

      months.push({
        label: formatedDate,
        value: i,
      });
    }
    return months;
  };

  const monthFilterOptions = generateMonthOptions(date.year);
  monthFilterOptions.unshift({
    label: 'MES',
    value: NUMBER_GET_ALL_PAYSLIPS,
  });

  const yearFilterOptions = generateYearOptions();
  yearFilterOptions.unshift({
    label: 'ANO',
    value: NUMBER_GET_ALL_PAYSLIPS,
  });
  const handleSetMonth = monthValue => {
    dispatchDate({ type: 'setMonth', month: monthValue });
  };

  const handleSetYear = yearValue => {
    const monthsThatExistsOnYear = generateMonthOptions(yearValue);
    monthsThatExistsOnYear.unshift({
      label: 'MES',
      value: NUMBER_GET_ALL_PAYSLIPS,
    });
    const hasMonthOnYear = monthsThatExistsOnYear.some(
      actualMonth => actualMonth.value === date.month,
    );

    if (!hasMonthOnYear) {
      dispatchDate({ type: 'setMonth', month: NUMBER_GET_ALL_PAYSLIPS });
    }

    dispatchDate({ type: 'setYear', year: yearValue });
  };
  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography fontSize="16px" color="secondary" fontWeight="300">
              Relação de Holerites
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <SelectFilter
              ml="30px"
              selectOptions={yearFilterOptions}
              value={date.year}
              handleChange={handleSetYear}
            />
            <SelectFilter
              selectOptions={monthFilterOptions}
              value={date.month}
              handleChange={handleSetMonth}
              mr="30px"
            />
            <Box px={2} />
          </Grid>
          {payslips.length ? (
            <Grid container alignItems="flex-start" justify="flex-start">
              <PaySlipList paySlips={payslips} colaborator />
            </Grid>
          ) : (
            <EmptyState description="Nenhum holerite encontrado!" />
          )}
        </Grid>
      </TabContainer>
    </TabPanel>
  );
};

PaySlip.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  stepStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  setUser: PropTypes.func.isRequired,
  admissionDate: PropTypes.string.isRequired,
};

PaySlip.defaultProps = {};

export default PaySlip;
