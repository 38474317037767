import DateFnsUtils from '@date-io/date-fns';
import { Button } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { set } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import OnVacation from '../../../../assets/OnVacation.svg';
import { Grid, ModalSkeleton, Typography } from '../../../../components';
import { useAuth } from '../../../../hooks/auth';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import { ACTIVITY_PERIOD, STATUS_ACTIVE } from '../../../../utils/enums';
import NumberOfDaysInput from '../NumberOfDaysInput';
import { StyledGridButton } from './styles';

const restSchema = Yup.object().shape({
  startDate: Yup.date()
    .required('Campo obrigatório')
    .typeError('Data inválida'),
  finalDate: Yup.date()
    .min(Yup.ref('startDate'), 'Informe uma data posterior a data de início.')
    .required('Campo obrigatório')
    .typeError('Data inválida'),
  numberOfDays: Yup.number()
    .required('Informe a quantidade de dias')
    .min(1, 'Quantidade inválida')
    .max(999, 'Quantidade inválida'),
});

const VacationsModal = ({
  handleClickOnClose,
  setUser,
  modalType,
  message,
  modalValue,
}) => {
  const [registeredRest, setRegisteredRest] = useState(false);

  const formInitialValues = {
    startDate: null,
    finalDate: null,
    numberOfDays: '',
  };

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();
  const { user } = useAuth();

  const handleRest = useCallback(
    async (values, { setSubmitting }) => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        const { startDate, finalDate } = values;
        const formattedDateStart = set(startDate, {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        });
        const formattedDateEnd = set(finalDate, {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        });
        if (formattedDateStart.getTime() === formattedDateEnd.getTime()) {
          dispatch(
            setSnackbar(
              true,
              'error',
              'A data de inicio e fim não podem ser iguais',
            ),
          );
          return;
        }
        const restDetails = {
          ...values,
          startDate: formattedDateStart,
          finalDate: formattedDateEnd,
          user: collaboratorId,
          company: user.company._id,
          type: modalValue,
          period: ACTIVITY_PERIOD.MORE_THAN_ONE_DAY,
        };

        const response = await api.post('activity', restDetails);

        setUser(response.data);
        setRegisteredRest(true);
        dispatch(setSnackbar(true, 'success', message.success));
      } catch (error) {
        dispatch(setSnackbar(true, 'error', error?.response?.data?.message));
      } finally {
        setSubmitting(false);
        dispatch(setLoading(false));
      }
    },
    [collaboratorId, dispatch, setUser, user.company._id, message, modalValue],
  );

  return (
    <ModalSkeleton
      title={
        registeredRest
          ? `${modalType.toUpperCase()} ${
              modalValue === STATUS_ACTIVE.WEEKLY_REST
                ? 'REGISTRADO'
                : 'REGISTRADA'
            }  `
          : `REGISTRAR ${modalType.toUpperCase()}`
      }
      handleClickOnClose={handleClickOnClose}
      maxWidth="750px"
    >
      {registeredRest ? (
        <Grid container pa="18px">
          <Grid container justify="center" spacing={5}>
            <Grid mt="50px">
              <Grid item lg={6}>
                <img src={OnVacation} alt="" />
              </Grid>
            </Grid>
            <Grid item lg={6}>
              <Typography mt="50px" variant="h3" fontWeight="bold" align="left">
                {message.success}
              </Typography>
              <Typography opacity="0.4" mt="20px" fontSize="20px">
                {modalValue === STATUS_ACTIVE.WEEKLY_REST ? (
                  <span>
                    Informe ao colaborador sobre a <br /> data de descanso
                    semanal!
                  </span>
                ) : (
                  <span>
                    Informe ao colaborador sobre a <br /> data
                    {modalValue === STATUS_ACTIVE.VACATION
                      ? ` das ${modalType.toLowerCase()}!`
                      : ` da ${modalType.toLowerCase()}!`}
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
          <StyledGridButton
            container
            justify="flex-end"
            alignContent="space-between"
          >
            <Grid item lg={2}>
              <Button onClick={() => handleClickOnClose(false)}>
                <Typography variant="h3" fontWeight="bold">
                  CONFIRMAR
                </Typography>
              </Button>
            </Grid>
          </StyledGridButton>
        </Grid>
      ) : (
        <Grid pa="18px" mt="24px">
          <Grid
            container
            justify="center"
            alignContent="space-between"
            spacing={3}
          />
          <Formik
            initialValues={formInitialValues}
            validationSchema={restSchema}
            onSubmit={handleRest}
          >
            {({ isSubmitting }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <Form>
                  <Grid
                    container
                    justify="flex-start"
                    alignContent="center"
                    spacing={3}
                  >
                    <Grid item lg={6}>
                      <Field
                        component={KeyboardDatePicker}
                        name="startDate"
                        label="Data de ínicio"
                        invalidDateMessage="Data inválida"
                        inputVariant="filled"
                        cancelLabel="CANCELAR"
                        format="dd/MM/yyyy"
                        fullWidth
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <Field
                        component={KeyboardDatePicker}
                        name="finalDate"
                        label="Data de término"
                        inputVariant="filled"
                        cancelLabel="CANCELAR"
                        invalidDateMessage="Data inválida"
                        format="dd/MM/yyyy"
                        fullWidth
                      />
                    </Grid>
                    <NumberOfDaysInput />
                  </Grid>

                  <StyledGridButton
                    container
                    justify="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Button onClick={handleClickOnClose}>
                        <Typography opacity="0.4" fontWeight="bold">
                          CANCELAR
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button type="submit" disabled={isSubmitting}>
                        <Typography fontWeight="bold">
                          CONFIRMAR{' '}
                          {modalValue === STATUS_ACTIVE.WEEKLY_REST
                            ? modalType.toUpperCase().replace(/ .*/, '')
                            : modalType.toUpperCase()}
                        </Typography>
                      </Button>
                    </Grid>
                  </StyledGridButton>
                </Form>
              </MuiPickersUtilsProvider>
            )}
          </Formik>
        </Grid>
      )}
    </ModalSkeleton>
  );
};

VacationsModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  modalValue: PropTypes.string.isRequired,
  message: PropTypes.objectOf(
    PropTypes.shape({
      success: PropTypes.string,
      error: PropTypes.string,
    }),
  ).isRequired,
};

export default VacationsModal;
