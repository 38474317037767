import { Box } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, Typography } from '../../components/index';
import useUser from '../../hooks/useUser';
import { REGIME_TYPE } from '../../utils/enums';
import { Container, TabsPaper } from './styles';
import {
  AboutYou,
  Address,
  BankData,
  Documentation,
  FamilyData,
  HiringData,
  PaySlip,
} from './Tabs/index';

const ColaboratorData = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const { userId: collaboratorId } = useParams();
  const { user: userData } = useUser(collaboratorId);
  const [user, setUser] = useState({});
  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData]);

  const isCLT = user?.hiringData?.regime === REGIME_TYPE.CLT;

  return (
    <Container>
      <Box py={2}>
        <Typography fontSize="40px" fontWeight="bold">
          Meus dados
        </Typography>
      </Box>
      <Box>
        <TabsPaper square elevation={1}>
          <Tabs value={tabValue} onChange={handleChangeTab}>
            <Tab label="DADOS CONTRATAÇÃO" index={0} />
            <Tab label="SOBRE VOCÊ" index={1} />
            <Tab label="DADOS DA FAMÍLIA" index={2} />
            <Tab label="DOCUMENTAÇÃO" index={3} />
            <Tab label="ENDEREÇO" index={4} />
            <Tab label="DADOS BANCÁRIOS" index={5} />
            {isCLT && <Tab label="HOLERITES" index={6} />}
          </Tabs>

          <HiringData
            data={user.hiringData}
            depart={user.department}
            job={user.jobRole}
            value={tabValue}
            index={0}
          />
          <AboutYou
            data={user.personalData}
            fullName={user.fullName}
            emailAddress={user.email}
            personalPhone={user.phone}
            isWhatsApp={user.isWhatsApp}
            value={tabValue}
            index={1}
          />
          <FamilyData data={user.familyData} value={tabValue} index={2} />
          <Documentation data={user.documents} value={tabValue} index={3} />
          <Address data={user.address} value={tabValue} index={4} />
          <BankData data={user.bankData} value={tabValue} index={5} />
          {isCLT && (
            <PaySlip
              admissionDate={user.admissionDate}
              value={tabValue}
              index={6}
            />
          )}
        </TabsPaper>
      </Box>
    </Container>
  );
};

export default ColaboratorData;
