import { Box, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Grid, Typography } from '../../../../components';

const BreaksList = props => {
  const { breaks } = props;

  return (
    <>
      {breaks.map((actualBreak, index) => {
        return (
          <Fragment key={actualBreak.description}>
            <Grid
              container
              alignItems="center"
              xs={12}
              ml="16px"
              mr="16px"
              mt="16px"
            >
              <Grid xs={3}>
                <Typography color="secondary" opacity={0.5} fontSize="0.875rem">
                  Descrição
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography color="secondary" opacity={0.5} fontSize="0.875rem">
                  Tempo
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography color="secondary" opacity={0.5} fontSize="0.875rem">
                  Período
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography color="secondary" opacity={0.5} fontSize="0.875rem">
                  Início
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" ml="16px" mr="16px" xs={12}>
              <Grid xs={3}>
                <Typography
                  color="secondary"
                  fontWeight="bold"
                  opacity={0.5}
                  fontSize="1.25rem"
                >
                  {actualBreak.description}
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography
                  color="secondary"
                  fontWeight="bold"
                  opacity={0.5}
                  fontSize="1.25rem"
                >
                  {actualBreak.time} minutos
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography
                  color="secondary"
                  fontWeight="bold"
                  opacity={0.5}
                  fontSize="1.25rem"
                >
                  {actualBreak.period}
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography
                  color="secondary"
                  fontWeight="bold"
                  opacity={0.5}
                  fontSize="1.25rem"
                >
                  {actualBreak.start}
                </Typography>
              </Grid>
            </Grid>
            {index !== breaks.length - 1 ? (
              <Box mt={3}>
                <Divider />
              </Box>
            ) : (
              ''
            )}
          </Fragment>
        );
      })}
    </>
  );
};

BreaksList.propTypes = {
  breaks: PropTypes.arrayOf(PropTypes.any).isRequired,
};
export default BreaksList;
