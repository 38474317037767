import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { formatISO, parse, format } from 'date-fns';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { ModalSkeleton } from '../../../../components';
import { ALERT_TYPES, REQUEST_CHANGES } from '../../../../utils/enums';
import toFormData from '../../../../utils/formData';
import { AuthenticatedHttpClient } from '../../../../services/api';
import alertModalSchema from '../../../../validations/alertModal';
import AlertsInfo from '../../Components/AlertInfo';
import { ActionsBar, ContainerModal, StyledGrid } from '../styles';

const AlertModal = ({ handleOnClose, selectedValue, reasonOptions }) => {
  const alertValue =
    selectedValue.type === ALERT_TYPES.FAULT
      ? 'Falta - Nenhum ponto registrado'
      : 'Pendência de ponto';

  const reasonInitalValue =
    selectedValue.type === ALERT_TYPES.FAULT
      ? REQUEST_CHANGES.MEDICAL_CERTIFICATE
      : REQUEST_CHANGES.POINTS_ADJUST;

  const formInitialValues = {
    date: selectedValue.date,
    alertDescription: alertValue,
    point: selectedValue.description,
    reason: reasonInitalValue,
    registerTime: null,
    partialMedicalCertificateStartHour: null,
    partialMedicalCertificateEndHour: null,
    justification: '',
    type: selectedValue.type,
  };
  const [alertFile, setAlertFile] = useState(null);

  const dispatch = useDispatch();

  const handleSubmitForm = useCallback(
    async values => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        const { _id, date } = selectedValue;

        const {
          reason,
          justification,
          registerTime,
          partialMedicalCertificateStartHour,
          partialMedicalCertificateEndHour,
        } = values;

        const solicitationDetails = {
          pointAlert: _id,
          reason,
          justification,
        };

        if (partialMedicalCertificateEndHour) {
          Object.assign(solicitationDetails, {
            partialMedicalCertificateEndHour: format(
              partialMedicalCertificateEndHour,
              'HH:mm',
            ),
          });
        }
        if (partialMedicalCertificateStartHour) {
          Object.assign(solicitationDetails, {
            partialMedicalCertificateStartHour: format(
              partialMedicalCertificateStartHour,
              'HH:mm',
            ),
          });
        }
        if (alertFile) {
          Object.assign(solicitationDetails, {
            attachment: alertFile,
          });
        }
        if (registerTime) {
          const parsedDate = parse(date, 'dd/MM/yyyy', new Date());

          const registerDate = parsedDate.setHours(
            registerTime.getHours(),
            registerTime.getMinutes(),
          );

          Object.assign(solicitationDetails, {
            registerDate: formatISO(registerDate),
          });
        }

        const solicitationFormData = toFormData(solicitationDetails);

        await api.post('/point-solicitation/adjust', solicitationFormData);
        dispatch(setSnackbar(true, 'success', 'O ajuste foi solicitado'));
        handleOnClose(_id);
      } catch (error) {
        if (error.response?.data?.message) {
          dispatch(setSnackbar(true, 'error', error.response.data.message));
        } else {
          dispatch(
            setSnackbar(true, 'error', 'Não foi possível solicitar o ajuste'),
          );
        }
      } finally {
        dispatch(setLoading(false));
      }
    },
    [alertFile, dispatch, handleOnClose, selectedValue],
  );

  return (
    <ModalSkeleton
      title="AJUSTAR PONTO"
      handleClickOnClose={handleOnClose}
      maxWidth="956px"
    >
      <ContainerModal>
        <Formik
          initialValues={formInitialValues}
          onSubmit={handleSubmitForm}
          validationSchema={alertModalSchema}
        >
          {({ isSubmitting, values }) => {
            return (
              <Form>
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  spacing={2}
                >
                  <AlertsInfo
                    setAlertFile={setAlertFile}
                    reasonOptions={reasonOptions}
                    values={values}
                    alertType={selectedValue.type}
                  />
                </Grid>
                <ActionsBar container alignItems="center" justify="flex-end">
                  <StyledGrid mt="32px">
                    <Button
                      disabled={isSubmitting}
                      color="secondary"
                      onClick={handleOnClose}
                      size="small"
                    >
                      CANCELAR
                    </Button>
                    <Button
                      color="secondary"
                      disabled={isSubmitting}
                      type="submit"
                      size="small"
                    >
                      SOLICITAR
                    </Button>
                  </StyledGrid>
                </ActionsBar>
              </Form>
            );
          }}
        </Formik>
      </ContainerModal>
    </ModalSkeleton>
  );
};

AlertModal.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  selectedValue: PropTypes.objectOf(PropTypes.any).isRequired,
  reasonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

export default AlertModal;
