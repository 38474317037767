import { ButtonBase } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import PasswordCreatedImage from '../../../../../assets/password-created.svg';
import { ModalSkeleton } from '../../../../../components';
import { ButtonText, CloseModalButtonWrapper, ContainerModal } from './styles';

const AddCareerModal = ({ handleClickOnClose }) => {
  return (
    <ModalSkeleton
      title="SENHA CRIADA"
      handleClickOnClose={handleClickOnClose}
      maxWidth="800px"
    >
      <ContainerModal>
        <img src={PasswordCreatedImage} alt="" />
        <div>
          <h1>SUA SENHA FOI CRIADA COM SUCESSO!</h1>
          <p>
            A partir de agora você já poderá utilizar a sua nova senha para
            acessar sua conta!
          </p>
        </div>
      </ContainerModal>
      <div>
        <CloseModalButtonWrapper>
          <ButtonBase onClick={handleClickOnClose}>
            <ButtonText>FAZER LOGIN</ButtonText>
          </ButtonBase>
        </CloseModalButtonWrapper>
      </div>
    </ModalSkeleton>
  );
};

AddCareerModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
};

export default AddCareerModal;
