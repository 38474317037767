import {
  Box,
  DialogContent,
  Modal,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SearchIcon from '../../../assets/search-icon.svg';
import { EmptyState } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { REQUEST_CHANGES } from '../../../utils/enums';
import formatTimeString from '../../../utils/formatTimeString';
import RequestModal from '../Modals';
import ReprovedModal from '../Modals/ReprovedModal';
import {
  StyledIconButtonBlue,
  StyledTableCell,
  TableWithMorePaddingRightForTheLastElement,
} from './styles';

const headCells = [
  {
    id: 'user.fullName',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'reason',
    numeric: false,
    disablePadding: false,
    label: 'Motivo',
    disableSort: true,
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {!headCell.disableSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell align="right">Ação</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTable = ({
  searchText,
  reason,
  setNotificationsCount,
  setForceGetCollaboratorsCompTime,
}) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [requestsToAnswer, setRequestsToAnswer] = useState([]);
  const [requestCount, setRequestCount] = useState(0);
  const [forceGetRequests, setForceGetRequests] = useState(false);
  const [isReprovedModalOpen, setIsReprovedModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    async function getInAnalysisSolicitations() {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();
        const { data } = await api.get(
          `point-solicitation/in-analysis/${user.company._id}`,
          {
            params: {
              page: page + 1,
              limit: rowsPerPage,
              order,
              orderBy,
              reason: reason.trim(),
              text: searchText.trim(),
            },
          },
        );
        const formattedRequestToAnswer = data.docs.map(pointSolicitation => ({
          _id: pointSolicitation._id,
          date: format(new Date(pointSolicitation.createdAt), 'dd/MM/yyyy'),
          user: pointSolicitation.user,
          reason: pointSolicitation.reason,
          status: pointSolicitation.status,
          justification: pointSolicitation.justification,
          pointAlert: pointSolicitation.pointAlert,
          registerDate: pointSolicitation.registerDate,
          solicitationDate: pointSolicitation.solicitationDate,
          attachment: pointSolicitation.attachment,
          viewed: pointSolicitation.viewed,
          dayHoursCompensated: formatTimeString(
            pointSolicitation.totalMinutesCompensated,
          ),
          compensationDate: pointSolicitation.compensationDate,
        }));
        setRequestsToAnswer(formattedRequestToAnswer);
        setRequestCount(data.total);
      } catch (e) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possivel obter as solicitações!'),
        );
      } finally {
        dispatch(setLoading(false));
      }
    }

    getInAnalysisSolicitations();
  }, [
    dispatch,
    order,
    orderBy,
    page,
    rowsPerPage,
    reason,
    searchText,
    forceGetRequests,
    user.company._id,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');

    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const requestChangesLabel = {
    [REQUEST_CHANGES.CHANGE_DAY_OFF]: 'Troca de folga',
    [REQUEST_CHANGES.REQUEST_DAY_OFF]: 'Pedido de folga',
    [REQUEST_CHANGES.HOURS_COMPENSATION]: 'Compensação de horas',
    [REQUEST_CHANGES.POINTS_ADJUST]: 'Ajuste do ponto',
    [REQUEST_CHANGES.MEDICAL_CERTIFICATE]: 'Atestado',
  };

  const updateSolicitationToViewed = async request => {
    try {
      const api = AuthenticatedHttpClient();
      await api.put(`point-solicitation/update-notification/${request._id}`);
      setNotificationsCount(prevState => {
        return prevState > 0 ? prevState - 1 : 0;
      });
    } catch (e) {
      dispatch(
        setSnackbar(
          true,
          'error',
          'Não foi possível atualizar as notificações',
        ),
      );
      setIsModalOpen(false);
    }
  };

  const handleRequestInfoModalOpen = async request => {
    setSelectedRequest(request);
    setIsModalOpen(true);
    if (!request.viewed) {
      updateSolicitationToViewed(request);
    }
  };
  const handleRequestReprovedModalOpen = () => {
    setIsModalOpen(false);
    setIsReprovedModalOpen(true);
  };

  return (
    <div>
      {!requestCount ? (
        <EmptyState description="Nenhuma solicitação econtrada!" />
      ) : (
        <Box component={Paper} elevation={0} overflow="visible">
          <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <DialogContent>
              <RequestModal
                handleOnClose={() => setIsModalOpen(false)}
                values={selectedRequest}
                reasonLabel={requestChangesLabel}
                setForceGetRequests={setForceGetRequests}
                setReprovedModalOpen={handleRequestReprovedModalOpen}
                setForceGetCollaboratorsCompTime={
                  setForceGetCollaboratorsCompTime
                }
              />
            </DialogContent>
          </Modal>
          <Modal
            open={isReprovedModalOpen}
            onClose={() => setIsReprovedModalOpen(false)}
          >
            <DialogContent>
              <ReprovedModal
                handleOnClose={() => setIsReprovedModalOpen(false)}
                values={selectedRequest}
                reasonLabel={requestChangesLabel}
                setForceGetRequests={setForceGetRequests}
                setForceGetCollaboratorsCompTime={
                  setForceGetCollaboratorsCompTime
                }
              />
            </DialogContent>
          </Modal>
          <TableContainer>
            <TableWithMorePaddingRightForTheLastElement
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={requestsToAnswer.length}
              />
              <TableBody>
                {requestsToAnswer.map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      <StyledTableCell component="th" scope="row">
                        {row.user.fullName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {requestChangesLabel[row.reason]}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <StyledIconButtonBlue
                          onClick={() => handleRequestInfoModalOpen(row)}
                        >
                          <img src={SearchIcon} alt="" />
                        </StyledIconButtonBlue>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </TableWithMorePaddingRightForTheLastElement>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={requestCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </div>
  );
};

EnhancedTable.propTypes = {
  searchText: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  setNotificationsCount: PropTypes.func.isRequired,
  setForceGetCollaboratorsCompTime: PropTypes.func.isRequired,
};

export default EnhancedTable;
