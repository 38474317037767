import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { isBefore, isSameDay, set } from 'date-fns';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useState } from 'react';
import alertIcon from '../../../../assets/alert-table.svg';
import approvedIcon from '../../../../assets/approved-table.svg';
// import searchIcon from '../../../../assets/search-icon.svg';
import { EnhancedTableHead, Typography } from '../../../../components';
import { REQUEST_CHANGES } from '../../../../utils/enums';
// import PointMirrorModal from '../../Modals/PointMirrorModal';
import {
  BoxBackgroundColor,
  // StyledIconButtonBlue,
  StyledTableCell,
  TableCellWithNoPadding,
  TableRowWithFixedHeight,
  TableWithMorePaddingRightForTheLastElement,
} from './styles';

const headCells = [
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Data',
  },
  {
    id: 'reason',
    numeric: false,
    disablePadding: false,
    label: 'Motivos',
  },
  {
    id: 'enter',
    numeric: false,
    disablePadding: false,
    label: 'Entrada',
  },
  {
    id: 'exit',
    numeric: false,
    disablePadding: false,
    label: 'Saída',
  },
  {
    id: 'hours',
    numeric: false,
    disablePadding: false,
    label: 'Horas',
  },
  {
    id: 'breaks',
    numeric: false,
    disablePadding: false,
    label: 'Pausas',
  },
  // {
  //   id: 'extraHours',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'HE',
  // },
];

const Alert = ({ showAlertForPastDate, hasEnterTime, alert }) => {
  if (showAlertForPastDate) {
    return <img src={alertIcon} alt="" />;
  }
  if (hasEnterTime) {
    return <img src={alert ? alertIcon : approvedIcon} alt="" />;
  }
  return <></>;
};

Alert.propTypes = {
  showAlertForPastDate: PropTypes.bool.isRequired,
  hasEnterTime: PropTypes.bool.isRequired,
  alert: PropTypes.bool.isRequired,
};

const EnhancedTable = ({ points }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  // const [
  //   pointMirrorStatusModalState,
  //   setPointMirrorStatusModalState,
  // ] = useState(false);
  // const [selectedPoint, setSelectedPoint] = useState({});

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [orderBy, order],
  );

  const reasonLabel = {
    [REQUEST_CHANGES.PARTIAL_MEDICAL_CERTIFICATE]: 'Motivo: Atestado Parcial',
    [REQUEST_CHANGES.MEDICAL_CERTIFICATE]: 'Motivo: Atestado',
    [REQUEST_CHANGES.POINTS_ADJUST]: 'Motivo: Falta Justificada',
  };

  // const handleOpenPointMirrorStatusModal = selectedValues => {
  //   setSelectedPoint(selectedValues);
  //   setPointMirrorStatusModalState(true);
  // };

  const startOfToday = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const rowData = row => {
    return (
      <>
        <StyledTableCell component="th" scope="row">
          {row.dateFormatted}
        </StyledTableCell>
        {row.reason ? (
          <TableCellWithNoPadding align="left">
            <BoxBackgroundColor>
              <Typography
                fontSize="14px"
                fontWeight="700"
                opacity={0.2}
                textcolor="#263238"
                pt="4px"
                pb="4px"
                pl="6px"
                pr="4px"
              >
                {reasonLabel[row.reason]}
              </Typography>
            </BoxBackgroundColor>
          </TableCellWithNoPadding>
        ) : (
          <StyledTableCell align="left">{row.reason}</StyledTableCell>
        )}

        <StyledTableCell align="left" $alert={row.enterTime?.alert}>
          {row.enterTime?.value}
        </StyledTableCell>

        <StyledTableCell align="left" $alert={row.exitTime?.alert}>
          {row.exitTime?.value}
        </StyledTableCell>

        <StyledTableCell align="left" $alert={row.totalHours?.alert}>
          {row.totalHours?.value}
        </StyledTableCell>

        <StyledTableCell align="left" $alert={row.totalBreaks?.alert}>
          <u>{row.totalBreaks?.value}</u>
        </StyledTableCell>
        {/* <StyledTableCell align="left" $alert={row.extraHours?.alert}>
          <u>{row.extraHours?.value}</u>
        </StyledTableCell> */}
        {/* <StyledTableCell align="right">
          <StyledIconButtonBlue
            onClick={() => handleOpenPointMirrorStatusModal(row)}
          >
            <img src={searchIcon} alt="" />
          </StyledIconButtonBlue>
        </StyledTableCell> */}
      </>
    );
  };

  return (
    <Paper elevation={0}>
      {/* <Modal
        open={pointMirrorStatusModalState}
        onClose={() => setPointMirrorStatusModalState(false)}
      >
        <DialogContent>
          <PointMirrorModal
            handleClose={() => setPointMirrorStatusModalState(false)}
            selectedPoint={selectedPoint}
          />
        </DialogContent>
      </Modal> */}
      <TableContainer>
        <TableWithMorePaddingRightForTheLastElement size="small">
          <EnhancedTableHead
            pointMirror
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {points.map(row => {
              const showAlertForPastDate =
                isBefore(row.date, startOfToday) && !row.enterTime;
              return (
                <Fragment key={row.id}>
                  {row.isDayOff && (
                    <TableRowWithFixedHeight hover tabIndex={-1}>
                      <TableCell />
                      <StyledTableCell component="th" scope="row" opacity>
                        {row.dateFormatted}
                      </StyledTableCell>
                      <StyledTableCell align="left" />
                      <StyledTableCell align="left" opacity>
                        -----
                      </StyledTableCell>
                      <StyledTableCell align="left" opacity>
                        -----
                      </StyledTableCell>
                      <StyledTableCell align="left" opacity>
                        -----
                      </StyledTableCell>
                      <StyledTableCell align="left" opacity>
                        -----
                      </StyledTableCell>
                      {/* <StyledTableCell align="left" />
                      <StyledTableCell align="left" /> */}
                    </TableRowWithFixedHeight>
                  )}
                  {!row.isDayOff &&
                    (!row.reason ||
                      row.reason ===
                        REQUEST_CHANGES.PARTIAL_MEDICAL_CERTIFICATE) &&
                    (!isSameDay(row.date, startOfToday) ? (
                      <TableRow hover tabIndex={-1}>
                        <TableCell onClick={() => {}}>
                          <Alert
                            alert={row.alert || false}
                            hasEnterTime={!!row.enterTime}
                            showAlertForPastDate={showAlertForPastDate}
                          />
                        </TableCell>
                        {rowData(row)}
                      </TableRow>
                    ) : (
                      <TableRowWithFixedHeight hover tabIndex={-1} $today>
                        <TableCell>
                          <Typography fontSize="12px" fontWeight="800">
                            HOJE
                          </Typography>
                        </TableCell>
                        {rowData(row)}
                      </TableRowWithFixedHeight>
                    ))}
                  {!row.isDayOff &&
                    (row.reason === REQUEST_CHANGES.MEDICAL_CERTIFICATE ||
                      row.reason === REQUEST_CHANGES.POINTS_ADJUST) && (
                      <TableRow hover tabIndex={-1}>
                        <TableCell onClick={() => {}}>
                          <img src={approvedIcon} alt="" />
                        </TableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.dateFormatted}
                        </StyledTableCell>
                        <TableCellWithNoPadding align="left">
                          <BoxBackgroundColor>
                            <Typography
                              fontSize="14px"
                              fontWeight="700"
                              opacity={0.2}
                              textcolor="#263238"
                              pt="4px"
                              pb="4px"
                              pl="6px"
                              pr="4px"
                            >
                              {reasonLabel[row.reason]}
                            </Typography>
                          </BoxBackgroundColor>
                        </TableCellWithNoPadding>

                        <TableCellWithNoPadding align="left">
                          <BoxBackgroundColor>
                            {row.enterTime?.value}
                          </BoxBackgroundColor>
                        </TableCellWithNoPadding>

                        <TableCellWithNoPadding align="left">
                          <BoxBackgroundColor>
                            {row.exitTime?.value}
                          </BoxBackgroundColor>
                        </TableCellWithNoPadding>

                        <TableCellWithNoPadding align="left">
                          <BoxBackgroundColor>
                            {row.totalHours?.value}
                          </BoxBackgroundColor>
                        </TableCellWithNoPadding>

                        <TableCellWithNoPadding align="left">
                          <BoxBackgroundColor>
                            <u>{row.totalBreaks?.value}</u>
                          </BoxBackgroundColor>
                        </TableCellWithNoPadding>
                        {/* <StyledTableCell align="left">
                          <u>{row.extraHours?.value}</u>
                        </StyledTableCell> */}
                        {/* <StyledTableCell align="right">
                          <StyledIconButtonBlue
                            onClick={() => handleOpenPointMirrorStatusModal(row)}
                          >
                            <img src={searchIcon} alt="" />
                          </StyledIconButtonBlue>
                        </StyledTableCell> */}
                      </TableRow>
                    )}
                </Fragment>
              );
            })}
          </TableBody>
        </TableWithMorePaddingRightForTheLastElement>
      </TableContainer>
    </Paper>
  );
};

EnhancedTable.propTypes = {
  points: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default EnhancedTable;
