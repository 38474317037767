/* eslint-disable no-param-reassign */
import axios from 'axios';
import { getToken, logout } from './auth';

export const UnauthenticatedHttpClient = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
};

export const AuthenticatedHttpClient = () => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  });

  api.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 401) {
        logout();
        window.location.reload();
      }
      throw error;
    },
  );
  return api;
};
