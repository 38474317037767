import { TextField } from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { StyledGrid } from '../styles';

const HoursCompensationInfo = ({ values, reasonLabel }) => {
  return (
    <>
      <StyledGrid item xs={6}>
        <TextField
          name="date"
          label="Data da solicitação"
          variant="filled"
          value={values.date}
          disabled
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item xs={6}>
        <TextField
          name="reason"
          label="Motivo"
          variant="filled"
          value={reasonLabel[values.reason]}
          disabled
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <TextField
          name="compensationDate"
          label="Data do pedido de compensação"
          variant="filled"
          disabled
          value={format(new Date(values.compensationDate), 'dd/MM/yyyy')}
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item lg={12}>
        <TextField
          name="dayHoursCompensated"
          label="Horas a serem compensadas"
          variant="filled"
          fullWidth
          disabled
          value={values.dayHoursCompensated}
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <TextField
          name="justification"
          label="Justificativa"
          variant="filled"
          disabled
          value={values.justification}
          fullWidth
        />
      </StyledGrid>
    </>
  );
};

HoursCompensationInfo.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  reasonLabel: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default HoursCompensationInfo;
