import { Box, DialogContent, Modal } from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '../../../../components';
import { ALERT_TYPES, REQUEST_CHANGES } from '../../../../utils/enums';
import AlertModal from '../../Modals/AlertModal';
import { AlertCardContainer, StyledButton } from './styles';

const AlertCard = props => {
  const { alerts } = props;
  const [activeAlerts, setActiveAlerts] = useState([]);
  const [alertModalStatus, setAlertModalStatus] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState({});
  const [reasonOptions, setReasonOptions] = useState([]);

  useEffect(() => {
    if (alerts) {
      setActiveAlerts(alerts);
    }
  }, [alerts]);

  const createReasonOptions = alert => {
    const availablePendentReasonOptions = [
      {
        label: 'Ajuste de Ponto',
        value: REQUEST_CHANGES.POINTS_ADJUST,
      },
      // {
      //   label: 'Atestado Parcial',
      //   value: REQUEST_CHANGES.PARTIAL_MEDICAL_CERTIFICATE,
      // },
    ];

    const availableFaultReasonOptions = [
      {
        label: 'Ajuste de Ponto',
        value: REQUEST_CHANGES.POINTS_ADJUST,
      },
      {
        label: 'Atestado ',
        value: REQUEST_CHANGES.MEDICAL_CERTIFICATE,
      },
    ];
    const optionsToSet =
      alert.type === ALERT_TYPES.FAULT
        ? availableFaultReasonOptions
        : availablePendentReasonOptions;

    if (
      alert.type === ALERT_TYPES.FAULT &&
      alert.date === format(new Date(2020, 6, 20), 'dd/MM/yyyy')
    ) {
      availableFaultReasonOptions.push({
        label: 'Atestado Parcial',
        value: REQUEST_CHANGES.PARTIAL_MEDICAL_CERTIFICATE,
      });
    }

    setReasonOptions(optionsToSet);
  };
  const descriptionLabel = {
    [ALERT_TYPES.FAULT]: 'Falta',
    [ALERT_TYPES.PENDENT]: 'Pendente',
  };

  const handleOpenAlertModal = alert => {
    setSelectedAlert(alert);
    createReasonOptions(alert);
    setAlertModalStatus(true);
  };

  const handleCloseAlertModal = alertId => {
    if (alert) {
      const filteredAlerts = activeAlerts.filter(
        alert => alert._id !== alertId,
      );
      setActiveAlerts(filteredAlerts);
    }
    setAlertModalStatus(false);
  };

  return (
    <>
      <Modal open={alertModalStatus} onClose={() => setAlertModalStatus(false)}>
        <DialogContent>
          <AlertModal
            handleOnClose={handleCloseAlertModal}
            selectedValue={selectedAlert}
            reasonOptions={reasonOptions}
          />
        </DialogContent>
      </Modal>
      {activeAlerts.map(alert => {
        return (
          <Box mt={4} key={alert._id}>
            <AlertCardContainer
              container
              justify="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography
                  fontWeight={700}
                  fontSize="14px"
                  textcolor="#FF6B6B"
                  ml="3px"
                >
                  {`${alert.day} - ${alert.date}`}
                </Typography>
                <Typography fontSize="13px" color="secondary" ml="3px">
                  {`${descriptionLabel[alert.type]} - ${alert.description}`}
                </Typography>
              </Grid>
              <Grid item>
                <StyledButton onClick={() => handleOpenAlertModal(alert)}>
                  <Typography
                    fontWeight={700}
                    fontSize="10px"
                    textcolor="#05202A"
                  >
                    AJUSTAR PONTO
                  </Typography>
                </StyledButton>
              </Grid>
            </AlertCardContainer>
          </Box>
        );
      })}
    </>
  );
};

AlertCard.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AlertCard;
