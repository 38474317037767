import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputAdornment } from '@material-ui/core';
import debounce from 'lodash.debounce';

import searchIcon from '../../assets/search-icon.svg';

import { StyledFilledInput } from './styles';

const SearchInput = ({ value, handleInput }) => {
  const [userQuery, setUserQuery] = useState(value);
  const delayedQuery = useCallback(
    debounce(q => handleInput(q), 500),
    [],
  );

  const onChange = e => {
    setUserQuery(e.target.value);
    delayedQuery(e.target.value);
  };

  return (
    <FormControl variant="filled">
      <StyledFilledInput
        value={userQuery}
        placeholder="Pesquisar"
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <img src={searchIcon} alt="" />
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string,
  handleInput: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {
  value: '',
};

export default SearchInput;
