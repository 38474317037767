import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Checkbox,
  // Checkbox,
  Divider,
  FormControlLabel,
  // FormControlLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { format, getDate, lastDayOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '../../../../components';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { DividerWithMargin, GridWithSelfAlign, StyledButton } from './styles';

const CollaboratorFilter = props => {
  const { setFilterOptions, workedHours } = props;

  const [actualTimeOption, setActualTimeOption] = useState('actualMonth');
  const [actualTimeLabel, setActualTimeLabel] = useState('');
  const dispatch = useDispatch();
  const [beggingOfPeriod, setBeggingOfPeriod] = useState(null);
  const [endOfPeriod, setEndOfPeriod] = useState(null);
  const [isOnlyAlerts, setIsOnlyAlerts] = useState(false);
  const timeOptions = [
    { label: 'Mês atual', value: 'actualMonth' },
    { label: 'Período', value: 'period' },
  ];

  useEffect(() => {
    if (actualTimeOption === 'actualMonth') {
      const namedDate = format(new Date(), 'MMMM / yyyy', {
        locale: ptBR,
      });
      const formatedDate =
        namedDate.charAt(0).toUpperCase() + namedDate.slice(1);
      setActualTimeLabel(formatedDate);
    }
    if (actualTimeOption === 'period') {
      const lastDayOfMonthNumber = getDate(lastDayOfMonth(new Date()));
      const dateOfLastDayOfMonth = new Date();
      const dateOfFirstDayOfMonth = new Date();
      dateOfFirstDayOfMonth.setDate(1);
      dateOfLastDayOfMonth.setDate(lastDayOfMonthNumber);

      const formatedFirstDate = format(dateOfFirstDayOfMonth, 'dd/MM/yyyy', {
        locale: ptBR,
      });
      const formatedLastDate = format(dateOfLastDayOfMonth, 'dd/MM/yyyy', {
        locale: ptBR,
      });

      const fullDate = `${formatedFirstDate} - ${formatedLastDate}`;

      setActualTimeLabel(fullDate);
    }
  }, [actualTimeOption]);

  const handleTimeOptionChange = event => {
    setActualTimeOption(event.target.value);
  };

  const handleBeggingOfPeriodChange = date => {
    setBeggingOfPeriod(date);
  };

  const handleEndOfPeriodChange = date => {
    setEndOfPeriod(date);
  };

  const handleOnlyAlerts = event => {
    setIsOnlyAlerts(event.target.checked);
  };
  const handleSearch = () => {
    if (actualTimeOption === 'actualMonth') {
      setFilterOptions({
        periodStartDate: '',
        periodEndDate: '',
        actualMonthDate: new Date(),
        isOnlyAlerts,
      });
    } else {
      if (!endOfPeriod || !beggingOfPeriod) {
        dispatch(setSnackbar(true, 'error', 'Informe um período.'));
        return;
      }
      if (endOfPeriod < beggingOfPeriod) {
        dispatch(
          setSnackbar(
            true,
            'error',
            'A data final é maior que a data inicial. Verifique as datas.',
          ),
        );
        return;
      }
      const formatedFirstDate = format(beggingOfPeriod, 'dd/MM/yyyy', {
        locale: ptBR,
      });
      const formatedLastDate = format(endOfPeriod, 'dd/MM/yyyy', {
        locale: ptBR,
      });

      const fullDate = `${formatedFirstDate} - ${formatedLastDate}`;

      setActualTimeLabel(fullDate);

      setFilterOptions({
        periodStartDate: beggingOfPeriod,
        periodEndDate: endOfPeriod,
        actualMonthDate: '',
        isOnlyAlerts,
      });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <Grid container item alignItems="center" spacing={2} xl={10} lg={12}>
        <Grid item xl={2} lg={3}>
          <Typography fontSize="14px" color="secondary" fontWeight="300">
            Filtrar por
          </Typography>
        </Grid>
        {actualTimeOption === 'period' && (
          <Grid item xs={2} ml="41px">
            <Typography fontSize="14px" color="secondary" fontWeight="300">
              Informe o período
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item mt="4px" spacing={2} xl={10} lg={12}>
        <Grid item xl={2} lg={3}>
          <TextField
            select
            name="actualTimeOption"
            variant="filled"
            fullWidth
            onChange={handleTimeOptionChange}
            value={actualTimeOption}
          >
            {timeOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <DividerWithMargin flexItem orientation="vertical" />
        {actualTimeOption === 'period' && (
          <>
            <Grid item xs={2}>
              <KeyboardDatePicker
                format="dd/MM/yyyy"
                placeholder="__/__/____"
                name="beggingOfPeriod"
                label="Entre"
                inputVariant="filled"
                cancelLabel="CANCELAR"
                fullWidth
                value={beggingOfPeriod}
                onChange={handleBeggingOfPeriodChange}
                invalidDateMessage="Data Invalida"
              />
            </Grid>
            <Grid item xs={2}>
              <KeyboardDatePicker
                format="dd/MM/yyyy"
                placeholder="__/__/____"
                name="endOfPeriod"
                label="Até"
                inputVariant="filled"
                cancelLabel="CANCELAR"
                fullWidth
                value={endOfPeriod}
                onChange={handleEndOfPeriodChange}
                invalidDateMessage="Data Invalida"
              />
            </Grid>
          </>
        )}

        <Grid item>
          <StyledButton onClick={handleSearch}>BUSCAR</StyledButton>
        </Grid>
        <GridWithSelfAlign item xs>
          <FormControlLabel
            control={
              <Checkbox
                name="isOnlyAlerts"
                value={isOnlyAlerts}
                onChange={handleOnlyAlerts}
                size="small"
              />
            }
            label={
              <Typography fontSize="14px" color="secondary" fontWeight="300">
                Ver somente registros com alerta
              </Typography>
            }
          />
        </GridWithSelfAlign>
      </Grid>
      <Box mt={6}>
        <Divider />
      </Box>
      <Box p={3}>
        <Grid justify="space-between" container mt="4px">
          <Grid item>
            <Typography fontSize="14px" color="secondary" fontWeight="300">
              Período Informado: <b>{actualTimeLabel}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontSize="14px" color="secondary" fontWeight="300">
              Total de horas trabalhadas: <b>{workedHours}</b>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </MuiPickersUtilsProvider>
  );
};

CollaboratorFilter.propTypes = {
  setFilterOptions: PropTypes.func.isRequired,
  workedHours: PropTypes.string.isRequired,
};

export default CollaboratorFilter;
