import * as Yup from 'yup';

const newJourneySchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  pointType: Yup.string().required('Campo obrigatório'),
  intervalCompensation: Yup.string().required('Campo obrigatório'),
  entryDelayTime: Yup.string().required('Campo obrigatório'),
  breakDelayTime: Yup.string().required('Campo obrigatório'),
  anticipationExitTime: Yup.string().required('Campo obrigatório'),
  breakAnticipationTime: Yup.string().required('Campo obrigatório'),
  limited: Yup.string().required('Campo obrigatório'),
  limitTime: Yup.string().when('limited', {
    is: 'true',
    then: Yup.string().required('Campo obrigatório'),
    otherwise: Yup.string().notRequired(),
  }),
  extraHourPerDay: Yup.string().required('Campo obrigatório'),
});

export default newJourneySchema;
