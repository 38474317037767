import DateFnsUtils from '@date-io/date-fns';
import { Box, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { format, isAfter } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { SearchInput } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { AuthenticatedHttpClient } from '../../../services/api';
import {
  ContainerInput,
  FilterText,
  RecordsButton as SubmitFilters,
  StyledDivider,
  SubTitleForm,
} from '../styles';

const TableFilter = props => {
  const { setFilters, filters } = props;
  const [jobRoleOptions, setJobRoleOptions] = useState(() => [
    { label: 'CARGO', value: '' },
  ]);
  const [departmentOptions, setDepartmentOptions] = useState(() => [
    { label: 'DEPARTAMENTO', value: '' },
  ]);

  const { user } = useAuth();

  useEffect(() => {
    const api = AuthenticatedHttpClient();

    async function getOptions() {
      const [jobRolesResponse, departmentsResponse] = await Promise.all([
        await api.get(`job-role/${user.company._id}`),
        await api.get(`department/${user.company._id}`),
      ]);
      const { data: dataJobRoles } = jobRolesResponse;
      const { data: dataDepartments } = departmentsResponse;

      const optionsJobRoles = dataJobRoles.map(jobRoleInfo => ({
        label: jobRoleInfo.name,
        value: jobRoleInfo._id,
      }));

      const optionsDepartments = dataDepartments.map(departmentIndo => ({
        label: departmentIndo.name,
        value: departmentIndo._id,
      }));

      setJobRoleOptions(oldState => [...oldState, ...optionsJobRoles]);
      setDepartmentOptions(oldState => [...oldState, ...optionsDepartments]);
    }
    getOptions();
  }, [user]);

  const handleSetSearchText = useCallback(
    textValue => {
      setFilters(oldState => ({
        ...oldState,
        text: textValue,
      }));
    },
    [setFilters],
  );

  const handleSubmitFilters = useCallback(
    (values, { setSubmitting }) => {
      setFilters(values);
      setSubmitting(false);
    },
    [setFilters],
  );

  const handleValidate = useCallback(values => {
    const errors = {};

    if (values.periodStartDate && !values.periodEndDate) {
      errors.periodEndDate = 'Insira uma data final';
    }
    if (values.periodEndDate && !values.periodStartDate) {
      errors.periodStartDate = 'Insira uma data início';
    }

    if (values.periodStartDate && values.periodEndDate) {
      if (isAfter(values.periodStartDate, values.periodEndDate)) {
        errors.periodStartDate = 'Intervalo inválido';
      }
    }
    return errors;
  }, []);

  return (
    <Formik
      initialValues={filters}
      validate={handleValidate}
      onSubmit={handleSubmitFilters}
    >
      {({ submitForm, isSubmitting }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <Form>
            <Box width={1}>
              <SubTitleForm>Informe o período</SubTitleForm>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box component={ContainerInput} width="15%">
                <Field
                  component={KeyboardDatePicker}
                  name="periodStartDate"
                  label="Início do período"
                  inputVariant="filled"
                  cancelLabel="CANCELAR"
                  invalidDateMessage="Data inválida"
                  format="dd/MM/yyyy"
                  fullWidth
                />
              </Box>
              <Box component={ContainerInput} width="15%">
                <Field
                  component={KeyboardDatePicker}
                  name="periodEndDate"
                  label="Final do período"
                  inputVariant="filled"
                  cancelLabel="CANCELAR"
                  invalidDateMessage="Data inválida"
                  format="dd/MM/yyyy"
                  fullWidth
                />
              </Box>
              <Box component={ContainerInput} width="25%">
                <Field
                  component={TextField}
                  select
                  name="jobRole"
                  variant="filled"
                  label="Cargo"
                  fullWidth
                >
                  {jobRoleOptions.map(jobRole => (
                    <MenuItem value={jobRole.value} key={jobRole.value}>
                      {jobRole.label}
                    </MenuItem>
                  ))}
                </Field>
              </Box>
              <Box component={ContainerInput} width="25%">
                <Field
                  component={TextField}
                  select
                  name="department"
                  variant="filled"
                  label="Departamento"
                  fullWidth
                >
                  {departmentOptions.map(department => (
                    <MenuItem value={department.value} key={department.value}>
                      {department.label}
                    </MenuItem>
                  ))}
                </Field>
              </Box>
              <Box
                component={ContainerInput}
                width="15%"
                display="flex"
                justifyContent="space-around"
              >
                <SubmitFilters onClick={submitForm} disabled={isSubmitting}>
                  BUSCAR
                </SubmitFilters>
                {/* <IconButton
                    onClick={handleClick}
                    style={{ width: '48px', height: '48px' }}
                  >
                    <GetApp />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <StyledMenuItem onClick={handleClose}>
                      DOWNLOAD PDF
                    </StyledMenuItem>
                    <StyledMenuItem onClick={handleClose}>
                      DOWNLOAD XLSX
                    </StyledMenuItem>
                  </Menu> */}
              </Box>
            </Box>
            <StyledDivider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <SearchInput
                handleInput={handleSetSearchText}
                value={filters.text}
              />
              <Box display="flex" alignItems="center">
                <FilterText>
                  Período informado:{' '}
                  <span>
                    {format(filters.periodStartDate, 'dd/MM/yyyy')} à{' '}
                    {format(filters.periodEndDate, 'dd/MM/yyyy')}
                  </span>
                </FilterText>
                <div style={{ marginLeft: '30px' }} />
                {/* <IconButton
                    onClick={handleClick}
                    style={{ marginLeft: '20px' }}
                  >
                    <Settings />
                  </IconButton> */}
              </Box>
            </Box>
          </Form>
        </MuiPickersUtilsProvider>
      )}
    </Formik>
  );
};

TableFilter.propTypes = {
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TableFilter;
