import { Box, DialogContent, Modal, useTheme } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import AlertIcon from '../../../../assets/alert.svg';
import ApprovedIcon from '../../../../assets/approved-password.svg';
import { CollaboratorButton, Grid, Typography } from '../../../../components';
import withCollaboratorSkeleton from '../../../../components/CollaboratorSkeleton';
import { useAuth } from '../../../../hooks/auth';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import PasswordCreatedModal from './PasswordCreatedModal';
import { BorderLinearProgress, StyledSpan, StyledTextField } from './styles';

const StepCreatePassword = () => {
  const [openModal, setOpenModal] = useState(false);

  const { user, signOut } = useAuth();
  const dispatch = useDispatch();

  const formInitialValues = {
    password: '',
    confirmPassword: '',
  };

  const { palette } = useTheme();

  const validateSchema = Yup.object().shape({
    password: Yup.string()
      .required('Campo obrigatório')
      .min(8, 'Mínimo 8 digitos')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        'Fora dos requisitos',
      ),
    confirmPassword: Yup.string()
      .required('Campo obrigatório')
      .oneOf([Yup.ref('password')], 'Senhas diferentes'),
  });

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        setSubmitting(false);
        const api = AuthenticatedHttpClient();
        await api.put(`invite/create-password/${user.invite._id}`, values);
        setOpenModal(true);
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possível criar uma nova senha'),
        );
      }
    },
    [dispatch, user],
  );

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    signOut();
  }, [signOut]);

  const handlePasswordStrength = useCallback(
    password => {
      let strength = 0;
      const labels = ['Muito baixo', 'Baixo', 'Moderado', 'Forte'];
      const colors = [
        palette.error.main,
        palette.error.main,
        palette.error.main,
        palette.primary.main,
      ];

      if (password.length >= 8) {
        strength += 1;
      }
      if (/[0-9]/.test(password)) {
        strength += 1;
      }
      if (/^(?=.*[a-z])(?=.*[A-Z])/.test(password)) {
        strength += 1;
      }

      return {
        label: labels[strength],
        value: (strength / 3) * 100,
        color: colors[strength],
      };
    },
    [palette],
  );

  return (
    <>
      <Box
        component={Grid}
        container
        direction="column"
        justify="center"
        color="white"
        height="100%"
        px="10%"
      >
        <Typography fontWeight="300" fontSize="20px">
          Por favor informe sua nova senha
        </Typography>
        <Formik
          initialValues={formInitialValues}
          validationSchema={validateSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <Field
                component={StyledTextField}
                name="password"
                type="password"
                label="Senha"
                fullWidth
                variant="filled"
                color="primary"
                mt="8px"
              />
              <Field
                component={StyledTextField}
                name="confirmPassword"
                type="password"
                label="Confirmar senha"
                fullWidth
                variant="filled"
                color="primary"
              />
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" flexDirection="column" mt={2}>
                  <Typography fontWeight="300" fontSize="20px" mb="16px">
                    Sua senha deve conter:
                  </Typography>
                  <StyledSpan>
                    <img
                      src={
                        values.password.length >= 8 ? ApprovedIcon : AlertIcon
                      }
                      alt=""
                      width="20"
                    />
                    Mínimo 8 Caracteres
                  </StyledSpan>
                  <StyledSpan>
                    <img
                      src={
                        /^(?=.*[a-z])(?=.*[A-Z])/.test(values.password)
                          ? ApprovedIcon
                          : AlertIcon
                      }
                      alt=""
                      width="20"
                    />
                    Letras maiúsculas e minúsculas
                  </StyledSpan>
                  <StyledSpan>
                    <img
                      src={
                        /^(?=.*[0-9])/.test(values.password)
                          ? ApprovedIcon
                          : AlertIcon
                      }
                      alt=""
                      width="20"
                    />
                    Pelo menos um número
                  </StyledSpan>
                </Box>
                <Box display="flex" flexDirection="column" mt={2} width="50%">
                  <Typography fontWeight="300" fontSize="20px" mb="16px">
                    Nível de segurança:{' '}
                    {handlePasswordStrength(values.password).label}
                  </Typography>
                  <BorderLinearProgress
                    variant="determinate"
                    value={handlePasswordStrength(values.password).value}
                    $barColor={handlePasswordStrength(values.password).color}
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <CollaboratorButton
                  text="CRIAR SENHA"
                  type="submit"
                  disabled={isSubmitting}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <Modal open={openModal} onClose={handleCloseModal}>
        <DialogContent>
          <PasswordCreatedModal handleClickOnClose={handleCloseModal} />
        </DialogContent>
      </Modal>
    </>
  );
};

export default withCollaboratorSkeleton(StepCreatePassword);
