import { differenceInCalendarYears, parse, addDays } from 'date-fns';

export default function generateDepartureDate(values) {
  const {
    resignationDate,
    admissionDate,
    notice,
    warningDefinitions,
    increasedCompanyTime,
  } = values;

  if (!resignationDate) {
    return '';
  }

  const parsedAdmissionDate = parse(admissionDate, 'dd/MM/yyyy', new Date());

  const parsedResignationDate = parse(
    resignationDate,
    'dd/MM/yyyy',
    new Date(),
  );

  const years = differenceInCalendarYears(
    parsedResignationDate,
    parsedAdmissionDate,
  );

  if (years < 0 || Number.isNaN(years)) {
    return '';
  }

  if (notice === 'indemnifiedNotice') {
    return resignationDate;
  }

  let totalDays = 30;

  if (warningDefinitions === 'sevenDaysBeforeEndDate') {
    totalDays -= 7;
  }

  if (increasedCompanyTime === 'true') {
    if (years > 1) {
      totalDays += 3 * (years - 1);
    }
  }

  const departureDateUpdated = addDays(parsedResignationDate, totalDays);

  const formatedDate = new Date(departureDateUpdated).toLocaleDateString(
    'pt-br',
  );

  return formatedDate;
}
