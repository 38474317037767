import { subDays } from 'date-fns';
import * as Yup from 'yup';
import { COMP_TIME_HISTORY_TYPES } from '../utils/enums';
import parseDate from '../utils/parseDateString';

const compTimeSchema = Yup.object().shape({
  type: Yup.string().required('Campo obrigatório'),
  justification: Yup.string().required('Campo obrigatório'),
  compensationDate: Yup.date().when('type', {
    is: COMP_TIME_HISTORY_TYPES.COMPENSATION_PAYMENT,
    then: Yup.date()
      .transform(parseDate)
      .typeError('Data Inválida')
      .required('Campo obrigatório')
      .min(
        subDays(new Date(), 1),
        'Informe uma igual ou data posterior a data atual.',
      ),
    otherwise: Yup.date().notRequired().nullable(),
  }),
  paymentDate: Yup.date().when('type', {
    is: COMP_TIME_HISTORY_TYPES.MONEY_PAYMENT,
    then: Yup.date()
      .transform(parseDate)
      .typeError('Data Inválida')
      .required('Campo obrigatório'),
    otherwise: Yup.date().notRequired().nullable(),
  }),
  paidValue: Yup.number().when('type', {
    is: COMP_TIME_HISTORY_TYPES.MONEY_PAYMENT,
    then: Yup.number()
      .required('Campo obrigatório')
      .test('Validate number', 'Valor invalido', function validate(value) {
        return value !== 0;
      }),
    otherwise: Yup.number().notRequired().nullable(),
  }),
  dayHoursCompensated: Yup.string().when('type', {
    is: COMP_TIME_HISTORY_TYPES.COMPENSATION_PAYMENT,
    then: Yup.string()
      .required('Campo obrigatório')
      .test('HasStringValues', 'Valor inválido', function validate(value) {
        if (value) {
          const splittedString = value.split('');

          const isValid = splittedString.every(character => !!character.trim());
          return isValid;
        }
        return false;
      })
      .test('Validate string', 'Valor inválido', function validate(value) {
        if (value) {
          const splittedString = value.split('');
          const isUnValid = splittedString.every(character => {
            return character === '0' || !character || character === ':';
          });
          return !isUnValid;
        }
        return false;
      }),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  hoursPaid: Yup.string().when('type', {
    is: COMP_TIME_HISTORY_TYPES.MONEY_PAYMENT,
    then: Yup.string()
      .required('Campo obrigatório')
      .test('HasStringValues', 'Valor inválido', function validate(value) {
        if (value) {
          const splittedString = value.split('');

          const isValid = splittedString.every(character => !!character.trim());
          return isValid;
        }
        return false;
      })
      .test('Validate string', 'Valor inválido', function validate(value) {
        if (value) {
          const splittedString = value.split('');
          const isUnValid = splittedString.every(character => {
            return character === '0' || !character || character === ':';
          });
          return !isUnValid;
        }
        return false;
      }),
    otherwise: Yup.string().notRequired().nullable(),
  }),
});

export default compTimeSchema;
