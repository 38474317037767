import { ButtonBase, Divider, Grid, Paper } from '@material-ui/core';
import { TimelineDot, TimelineItem } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 24px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
export const TabContainer = styled.div`
  padding: 16px;
  padding-bottom: 50px;
`;

export const TabsPaper = styled(Paper)`
  margin-top: 16px;
`;

export const CardContainer = styled(Grid)`
  padding: ${({ padding }) => padding || '10px'};
  padding-bottom: ${({ $hasVariableHeight }) =>
    $hasVariableHeight ? '70px ' : '10px'};
  width: 100%;
  height: ${({ $hasVariableHeight }) => ($hasVariableHeight ? 'auto' : '94px')};

  margin-top: 16px 0;
  background-color: ${({ color }) => color};
  border-radius: 6px;
  opacity: 1;
  box-shadow: ${({ $hasBoxShadow }) =>
    $hasBoxShadow ? '0px 3px 6px #00000029' : ''};
  img {
    box-sizing: border-box;
    max-width: ${({ img }) => (img ? '100%' : '100%')};
    max-height: 100%;
  }
  span {
    margin-left: 8px;
    ${({ theme }) => css`
      color: ${theme.palette.secondary.main};
      font-weight: 300;
      font-size: 12px;
      margin-top: '8px'
      opacity: 0.5;
    `}
  }
`;

export const RowImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RegisterPointContainer = styled(Grid)`
  padding: 12px;
  width: 100%;
  height: 73px;
  margin-top: 16px;
  background-color: #ffffff;
  border-radius: 6px;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  span {
    margin-left: 8px;
    ${({ theme }) => css`
    color: ${theme.palette.secondary.main};
    font-weight: 300;
    font-size: 12px;
    margin-top: '8px'
    opacity: 0.5;
  `}
  }
`;

export const DividerWithMargin = styled(Divider)`
  margin: 0px 20px;
`;

export const StyledButton = styled(ButtonBase)`
  width: 151px;
  color: white;
  font-weight: 700;
  font-size: 10px;
  background-color: ${({ bgColor, theme }) =>
    bgColor || theme.palette.primary.main};
  height: 35px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
`;

export const StyledITimelineItem = styled(TimelineItem)`
  &.MuiTimelineItem-missingOppositeContent:before {
    flex: 0;
    padding: 0;
  }
`;

export const StyledTimelineDot = styled(TimelineDot)`
  background-color: ${({ color }) => color};
  border-color: transparent;
`;

export const RequestCard = styled(Grid)`
  height: ${({ hasMaxWidthOf1323Px }) =>
    hasMaxWidthOf1323Px ? '170px' : '98px'};
  background-color: #fbfbfb;
  border-radius: 6px;
`;

export const RequestButton = styled(ButtonBase)`
  width: 197px;
  color: white;
  font-weight: 700;
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 45px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
`;
