import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import SnackBar from './components/SnackBar';
import Loading from './components/Loading';

import AppProvider from './hooks';
import store from './redux/configureStore';

import GlobalStyle from './styles/globalStyle';

import Routes from './routes';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppProvider>
          <SnackBar />
          <Loading />
          <GlobalStyle />
          <Routes />
        </AppProvider>
      </Router>
    </Provider>
  );
}

export default App;
