import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button, Box } from '@material-ui/core';

import { AuthenticatedHttpClient } from '../../services/api';
import { setLoading } from '../../redux/ducks/loading';
import { setSnackbar } from '../../redux/ducks/snackbar';

import ModalSkeleton from '../ModalSkeleton';
import Typography from '../Typography';

const RefusedStepModal = React.forwardRef(
  ({ handleClickOnClose, stepTitle, stepName }, ref) => {
    const refusedStepSchema = Yup.object().shape({
      reason: Yup.string().required('Campo obrigatório'),
    });

    const dispatch = useDispatch();
    const { userId: collaboratorId } = useParams();

    const formInitialValues = {
      reason: '',
    };

    const handleRefusedStep = useCallback(
      async (values, { setSubmitting }) => {
        try {
          dispatch(setLoading(true));
          const api = AuthenticatedHttpClient();

          const step = {
            stepName,
            status: 'disapproved',
            reason: values.reason,
          };

          const response = await api.put(
            `users/update-step/${collaboratorId}`,
            step,
          );

          handleClickOnClose(response.data);
          dispatch(setSnackbar(true, 'success', 'Etapa reprovada'));
        } catch (error) {
          dispatch(setSnackbar(true, 'error', 'Falha ao reprovar etapa'));
        } finally {
          dispatch(setLoading(false));
          setSubmitting(false);
        }
      },
      [handleClickOnClose, stepName, dispatch, collaboratorId],
    );

    return (
      <ModalSkeleton
        title="Recusar Etapa"
        handleClickOnClose={handleClickOnClose}
        ref={ref}
      >
        <Formik
          initialValues={formInitialValues}
          onSubmit={handleRefusedStep}
          validationSchema={refusedStepSchema}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Box
                display="flex"
                p={3}
                flexDirection="column"
                justifyContent="flex-start"
              >
                <Typography fontWeight="bold">Etapa</Typography>
                <Typography opacity="0.6">{stepTitle}</Typography>

                <Box width={1} mt={2} height="210px">
                  <Field
                    component={TextField}
                    multiline
                    rows={8}
                    name="reason"
                    label="Motivo"
                    variant="filled"
                    fullWidth
                  />
                </Box>
                <Box
                  display="flex"
                  width={1}
                  justifyContent="flex-end"
                  alignItems="center"
                  my={3}
                >
                  <Box
                    component={Button}
                    color="secondary"
                    onClick={handleClickOnClose}
                    size="small"
                    mr={5}
                  >
                    <Typography
                      fontSize="0.9375rem"
                      fontWeight="bold"
                      opacity="0.7"
                    >
                      CANCELAR
                    </Typography>
                  </Box>
                  <Button
                    color="secondary"
                    type="submit"
                    onClick={submitForm}
                    disabled={isSubmitting}
                    size="small"
                  >
                    <Typography fontSize="0.9375rem" fontWeight="bold">
                      RECUSAR ETAPA
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </ModalSkeleton>
    );
  },
);

RefusedStepModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
  stepTitle: PropTypes.string.isRequired,
  stepName: PropTypes.string.isRequired,
};

export default RefusedStepModal;
