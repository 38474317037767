import styled, { css } from 'styled-components';
import { Paper, Grid, TextField, Box } from '@material-ui/core';

export const Container = styled.div`
  padding: 24px;
`;

export const TabContainer = styled.div`
  padding: 16px;
  padding-bottom: 50px;
`;

export const TabsPaper = styled(Paper)`
  margin-top: 16px;
`;

export const ContainerInput = styled(Grid)`
  height: 80px;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : 0)};

  input {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
export const StyledTextField = styled(TextField)`
  .MuiFilledInput-root,
  .MuiInputBase-adornedEnd,
  .MuiInputBase-adornedStart,
  .MuiFilledInput-adornedEnd,
  .MuiFilledInput-adornedStart {
    background: #f6f8f9;
  }
`;

export const Card = styled(Box)`
  transition: opacity: 0.8s;
  cursor: pointer;
  background-image: linear-gradient(to right, #40a6ff, #1773c4);
  border-radius: 10px;
  ${props =>
    !props.visible &&
    css`
      display: none;
      opacity: 0;
    `}
  img {
    max-height: 130px;
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  .MuiAccordion-root + .MuiAccordion-root {
    margin-top: 16px;
  }
`;

export const SelectDiv = styled.div`
  width: 100%;
  margin: 24px 0;
  display: flex;
  align-items: center;

  .MuiFormControl-root {
    & + .MuiFormControl-root {
      margin-left: 24px;
    }
  }
`;

export const SelectDate = styled(Grid)`
  height: 80px;
`;

export const PayslipButton = styled(Grid)`
  align-self: center;
`;

export const DivSize = styled(Grid)`
  height: 114px;
`;
