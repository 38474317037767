import * as Yup from 'yup';
import parseDate from '../utils/parseDateString';

const journeyBreaks = Yup.object().shape({
  numberOfBreaks: Yup.string().required('Campo obrigatório'),
  breaks: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().required('Campo Obrigatório'),
      description: Yup.string().required('Campo obrigatório'),
      time: Yup.string().required('Campo obrigatório'),
      start: Yup.date()
        .transform(parseDate)
        .typeError('Horário Invalido')
        .required('Campo obrigatório'),
    }),
  ),
});

export default journeyBreaks;
