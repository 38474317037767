import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import CloseX from '../../../../../assets/CloseX.svg';
import { Grid, Typography } from '../../../../../components';
import { IconCircle, StyledButton, StyledLink } from './styles';

const DisapprovedSkeleton = ({ title, reason, link }) => {
  return (
    <Grid container justify="center" alignItems="flex-start">
      <Grid item lg={12}>
        <Box display="flex" justifyContent="flex-start">
          <IconCircle>
            <img src={CloseX} alt="" />
          </IconCircle>
          <Typography fontSize="22px" color="error" fontWeight="bold" ml="10px">
            Pendente para o candidato
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={12}>
        <Typography
          fontSize="18px"
          fontWeight="bold"
          color="secondary"
          mb="20px"
          mt="20px"
        >
          {title}
        </Typography>
        <Typography
          fontSize="16px"
          fontWeight="500"
          color="secondary"
          mb="10px"
        >
          Motivo
        </Typography>
        <Typography fontSize="16px" color="secondary" mb="10px">
          {reason}
        </Typography>
      </Grid>
      <Grid item lg={12}>
        <Box component={Grid} container justifyContent="flex-end" pt="10px">
          <StyledLink to={link}>
            <StyledButton>CORRIGIR</StyledButton>
          </StyledLink>
        </Box>
      </Grid>
    </Grid>
  );
};
DisapprovedSkeleton.propTypes = {
  title: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
export default DisapprovedSkeleton;
