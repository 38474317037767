import React from 'react';
import PropTypes, { string } from 'prop-types';
import { IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import filesize from 'filesize';

import { StyledContainer, StyledSpan } from './styles';

import JpgFileImage from '../../assets/file-jpg.svg';

const Deleter = ({ file, removeFn, remove }) => {
  const { name, size } = file;
  return (
    <StyledContainer>
      <img src={JpgFileImage} alt="" />
      <Box
        display="flex"
        overflow="hidden"
        width={1}
        ml={1}
        flexDirection="column"
        alignItems="flex-start"
      >
        <Box
          fontSize={10}
          fontWeight={700}
          textOverflow="ellipsis"
          overflow="hidden"
          width={1}
          maxWidth="90%"
        >
          {name}
        </Box>
        <StyledSpan fontSize={8} fontWeight={300}>
          {filesize(size)}
        </StyledSpan>
      </Box>
      <IconButton color="inherit" size="small" onClick={removeFn}>
        <CloseIcon />
      </IconButton>
    </StyledContainer>
  );
};

Deleter.propTypes = {
  file: PropTypes.shape({
    name: string,
    size: string,
    location: string,
  }).isRequired,
  remove: PropTypes.bool,
  removeFn: PropTypes.func,
};

Deleter.defaultProps = {
  remove: false,
  removeFn: () => {},
};
export default Deleter;
