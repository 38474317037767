import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

import { StyledSelect } from './styles';

const SelectFilter = ({ selectOptions, value, handleChange }) => {
  return (
    <StyledSelect
      select
      value={value}
      onChange={event => handleChange(event.target.value)}
      variant="outlined"
    >
      {selectOptions.map(option => (
        <MenuItem key={option.label} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

SelectFilter.propTypes = {
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default SelectFilter;
