import {
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogContent,
  Grid,
  Modal,
  TextField,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  CPFInput,
  CustomAccordion,
  DateInput,
  Downloader,
  NumericInput,
  PISInput,
  RefusedStepModal,
  TabPanel,
  Typography,
} from '../../../components';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { STEPS } from '../../../utils/enums';
import ApprovalStep from '../ApprovalStep';
import {
  ContainerInput,
  StyledBox,
  StyledButtonFail,
  StyledButtonPass,
  StyledTypography,
  TabContainer,
} from '../styles';

const Documentation = props => {
  const {
    value,
    index,
    stepStatus,
    data,
    setUser,
    handleChangeTab,
    ...other
  } = props;
  const [documentsInitialValues, setDocumentsInitialValues] = useState({
    cpf: '',
    pisNumber: '',
    rgNumber: '',
    rgExpeditionDate: '',
    rgOrganUfEmitter: '',
    voterRegistrationNumber: '',
    voterRegistrationZone: '',
    voterRegistrationSection: '',
    reservistNumber: '',
  });
  const [expanded, setExpanded] = useState('panel1');
  const [documentsStatus, setDocumentsStatus] = useState({});
  const [openRefusedStepModal, setOpenRefusedStepModal] = useState(false);

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();

  useEffect(() => {
    if (Object.keys(data).length) {
      setDocumentsInitialValues(data);
    }
    if (stepStatus) {
      setDocumentsStatus(stepStatus);
    }
  }, [data, stepStatus]);

  const handleApproveStatus = useCallback(async () => {
    try {
      dispatch(setLoading(true));

      const api = AuthenticatedHttpClient();
      const response = await api.put(`users/update-step/${collaboratorId}`, {
        stepName: STEPS.DOCUMENTS,
        status: 'approved',
      });

      setUser(response.data);
      dispatch(setSnackbar(true, 'success', 'Dados aprovados'));
      handleChangeTab(null, value + 1);
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Falha ao aprovar'));
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch, collaboratorId, setUser, handleChangeTab, value]);

  const handleCloseModal = useCallback(
    userUpdated => {
      setOpenRefusedStepModal(false);
      if (userUpdated) {
        setUser(userUpdated);
      }
    },
    [setUser],
  );

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <TabPanel value={value} index={index} {...other}>
        <TabContainer>
          <StyledBox>
            <CustomAccordion
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  component="legend"
                  fontSize="16px"
                  mb="8px"
                  mt="8px"
                  width="100%"
                >
                  CPF
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justify="space-between" spacing={5}>
                  <Grid container item lg={4} direction="column">
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Nº CPF
                    </Typography>
                    <ContainerInput item>
                      <TextField
                        name="cpf"
                        label="Número do CPF"
                        variant="filled"
                        fullWidth
                        value={documentsInitialValues.cpf}
                        InputProps={{
                          inputComponent: CPFInput,
                        }}
                      />
                    </ContainerInput>
                  </Grid>

                  <Grid item lg={4}>
                    {documentsInitialValues.photoCPF && (
                      <>
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Foto do CPF
                        </Typography>
                        <Downloader
                          file={{
                            key: documentsInitialValues.photoCPF.key,
                            name: documentsInitialValues.photoCPF.name,
                            size: documentsInitialValues.photoCPF.size,
                          }}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item lg={4} />
                </Grid>
              </AccordionDetails>
            </CustomAccordion>

            <CustomAccordion
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  component="legend"
                  fontSize="16px"
                  mb="8px"
                  mt="8px"
                  width="100%"
                >
                  PIS
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justify="space-between" py="16px">
                  <Grid container item lg={4} direction="column">
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Nº PIS
                    </Typography>
                    <TextField
                      name="pisNumber"
                      label="Número"
                      variant="filled"
                      fullWidth
                      value={documentsInitialValues.pisNumber}
                      InputProps={{
                        inputComponent: PISInput,
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} />
                  <Grid item lg={4} />
                </Grid>
              </AccordionDetails>
            </CustomAccordion>

            <CustomAccordion
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  component="legend"
                  fontSize="16px"
                  mb="8px"
                  mt="8px"
                  width="100%"
                >
                  RG
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justify="space-between" spacing={5}>
                  <Grid container item lg={4} direction="column">
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Nº RG
                    </Typography>
                    <ContainerInput item>
                      <TextField
                        name="rgNumber"
                        label="Número"
                        variant="filled"
                        fullWidth
                        value={documentsInitialValues.rgNumber}
                        InputProps={{
                          inputComponent: NumericInput,
                        }}
                      />
                    </ContainerInput>
                    {documentsInitialValues.photoFrontRg && (
                      <>
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Foto do RG frente
                        </Typography>
                        <Downloader
                          file={{
                            key: documentsInitialValues.photoFrontRg.key,
                            name: documentsInitialValues.photoFrontRg.name,
                            size: documentsInitialValues.photoFrontRg.size,
                          }}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid container item lg={4} direction="column">
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Data expedição
                    </Typography>
                    <ContainerInput item>
                      <TextField
                        name="rgExpeditionDate"
                        label="Data"
                        variant="filled"
                        fullWidth
                        value={documentsInitialValues.rgExpeditionDate}
                        InputProps={{
                          inputComponent: DateInput,
                        }}
                      />
                    </ContainerInput>
                    {documentsInitialValues.photoBackRg && (
                      <>
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Foto do RG verso
                        </Typography>
                        <Downloader
                          file={{
                            key: documentsInitialValues.photoBackRg.key,
                            name: documentsInitialValues.photoBackRg.name,
                            size: documentsInitialValues.photoBackRg.size,
                          }}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item lg={4}>
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Órgão e UF emissor
                    </Typography>
                    <ContainerInput item>
                      <TextField
                        name="rgOrganUfEmitter"
                        label="Órgão + UF"
                        variant="filled"
                        value={documentsInitialValues.rgOrganUfEmitter}
                        fullWidth
                      />
                    </ContainerInput>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </CustomAccordion>

            <CustomAccordion
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  component="legend"
                  fontSize="16px"
                  mb="8px"
                  mt="8px"
                  width="100%"
                >
                  Título de eleitor
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justify="space-between" spacing={5}>
                  <Grid container item lg={4} direction="column">
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Nº do título
                    </Typography>
                    <ContainerInput item>
                      <TextField
                        name="voterRegistrationNumber"
                        label="Número"
                        variant="filled"
                        fullWidth
                        value={documentsInitialValues.voterRegistrationNumber}
                        InputProps={{
                          inputComponent: NumericInput,
                        }}
                      />
                    </ContainerInput>
                    {documentsInitialValues.photoTitle && (
                      <>
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Foto do título eleitoral
                        </Typography>
                        <Downloader
                          file={{
                            key: documentsInitialValues.photoTitle.key,
                            name: documentsInitialValues.photoTitle.name,
                            size: documentsInitialValues.photoTitle.size,
                          }}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid container item lg={4} direction="column">
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Zona eleitoral
                    </Typography>
                    <ContainerInput item>
                      <TextField
                        name="voterRegistrationZone"
                        label="Zona"
                        variant="filled"
                        fullWidth
                        value={documentsInitialValues.voterRegistrationZone}
                        InputProps={{
                          inputComponent: NumericInput,
                        }}
                      />
                    </ContainerInput>
                  </Grid>
                  <Grid item lg={4}>
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Seção eleitoral
                    </Typography>
                    <ContainerInput item>
                      <TextField
                        name="voterRegistrationSection"
                        label="Seção"
                        variant="filled"
                        fullWidth
                        value={documentsInitialValues.voterRegistrationZone}
                        InputProps={{
                          inputComponent: NumericInput,
                        }}
                      />
                    </ContainerInput>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </CustomAccordion>

            <CustomAccordion
              expanded={expanded === 'panel5'}
              onChange={handleChange('panel5')}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  component="legend"
                  fontSize="16px"
                  mb="8px"
                  mt="8px"
                  width="100%"
                >
                  Reservista
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justify="space-between" spacing={5}>
                  <Grid container item lg={4} direction="column">
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Nº Reservista
                    </Typography>
                    <ContainerInput item>
                      <TextField
                        name="reservistNumber"
                        label="Nº do certificado militar"
                        variant="filled"
                        fullWidth
                        value={documentsInitialValues.reservistNumber}
                        InputProps={{
                          inputComponent: NumericInput,
                        }}
                      />
                    </ContainerInput>
                  </Grid>

                  <Grid item lg={4}>
                    {documentsInitialValues.photoReservist && (
                      <>
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Foto da reservista
                        </Typography>
                        <Downloader
                          file={{
                            key: documentsInitialValues.photoReservist.key,
                            name: documentsInitialValues.photoReservist.name,
                            size: documentsInitialValues.photoReservist.size,
                          }}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item lg={4} />
                </Grid>
              </AccordionDetails>
            </CustomAccordion>
            {(documentsStatus.status === 'approved' ||
              documentsStatus.status === 'disapproved') && (
              <ApprovalStep
                status={documentsStatus.status}
                reason={documentsStatus.reason}
              />
            )}
            {documentsStatus.status === 'underAnalysis' && (
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <StyledButtonFail onClick={() => setOpenRefusedStepModal(true)}>
                  <StyledTypography color="error">REPROVAR</StyledTypography>
                </StyledButtonFail>
                <StyledButtonPass>
                  <StyledTypography
                    color="secondary"
                    onClick={handleApproveStatus}
                  >
                    APROVAR
                  </StyledTypography>
                </StyledButtonPass>
              </Box>
            )}
          </StyledBox>
        </TabContainer>
      </TabPanel>
      <Modal open={openRefusedStepModal} onClose={handleCloseModal}>
        <DialogContent>
          <RefusedStepModal
            stepName={STEPS.DOCUMENTS}
            stepTitle="Documentação"
            handleClickOnClose={handleCloseModal}
          />
        </DialogContent>
      </Modal>
    </>
  );
};

Documentation.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  stepStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
};

Documentation.defaultProps = {
  data: {},
};

export default Documentation;
