import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { formatISO, set } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, ModalSkeleton, Typography } from '../../../../components';
import { useAuth } from '../../../../hooks/auth';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import { HOLIDAY_MODELS, HOLIDAY_TYPES } from '../../../../utils/enums';
import addHolidaySchema from '../../../../validations/addHoliday';
import { StyledGrid } from './styles';

const AddHolidayModal = ({
  handleClose,
  formInitialValues,
  isUpdate,
  setForceGetHolidays,
}) => {
  const modelOptions = [
    {
      label: 'Período',
      value: HOLIDAY_MODELS.PERIOD,
    },
    {
      label: 'Data',
      value: HOLIDAY_MODELS.DATE,
    },
  ];
  const typeOptions = [
    {
      label: 'Nacional',
      value: HOLIDAY_TYPES.NATIONAL,
    },
    {
      label: 'Municipal',
      value: HOLIDAY_TYPES.MUNICIPAL,
    },
    {
      label: 'Estadual',
      value: HOLIDAY_TYPES.STATE,
    },
  ];

  const dispatch = useDispatch();
  const { user } = useAuth();

  const handleAddHoliday = useCallback(
    async values => {
      try {
        setLoading(true);
        const api = AuthenticatedHttpClient();
        const formValues = values;
        if (formValues.holidayModel === HOLIDAY_MODELS.PERIOD) {
          delete formValues.date;
          const formatedDateStart = set(formValues.period.start, {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          });
          const formatedDateEnd = set(formValues.period.end, {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          });

          formValues.period.start = formatISO(formatedDateStart);
          formValues.period.end = formatISO(formatedDateEnd);
        } else {
          delete formValues.period;
          const formatedDate = set(formValues.date, {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          });

          formValues.date = formatISO(formatedDate);
        }

        await api.post('holiday', {
          ...formValues,
          company: user.company._id,
        });

        dispatch(setSnackbar(true, 'success', 'Feriado adicionado'));
        setForceGetHolidays(prevState => !prevState);
        handleClose();
      } catch (error) {
        if (error.response?.data?.message) {
          dispatch(setSnackbar(true, 'error', error.response.data.message));
        } else {
          dispatch(
            setSnackbar(true, 'error', 'Não foi possível adicionar o feriado'),
          );
        }
      } finally {
        setLoading(false);
        handleClose();
      }
    },
    [handleClose, user.company._id, dispatch, setForceGetHolidays],
  );
  const handleUpdateHoliday = useCallback(
    async values => {
      try {
        setLoading(true);
        const api = AuthenticatedHttpClient();
        const formValues = values;
        if (formValues.holidayModel === HOLIDAY_MODELS.PERIOD) {
          delete formValues.date;
          const formatedDateStart = set(formValues.period.start, {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          });
          const formatedDateEnd = set(formValues.period.end, {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          });

          formValues.period.start = formatISO(formatedDateStart);
          formValues.period.end = formatISO(formatedDateEnd);
        } else {
          delete formValues.period;
          const formatedDate = set(formValues.date, {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          });

          formValues.date = formatISO(formatedDate);
        }

        await api.put(`holiday/update/${values._id}`, {
          ...formValues,
          company: user.company._id,
        });

        dispatch(setSnackbar(true, 'success', 'Feriado editado com sucesso'));
        setForceGetHolidays(prevState => !prevState);
        handleClose();
      } catch (error) {
        if (error.response?.data?.message) {
          dispatch(setSnackbar(true, 'error', error.response.data.message));
        } else {
          dispatch(
            setSnackbar(true, 'error', 'Não foi possível editar o feriado'),
          );
        }
      } finally {
        setLoading(false);
        handleClose();
      }
    },
    [user.company._id, dispatch, setForceGetHolidays, handleClose],
  );

  return (
    <ModalSkeleton title="Adicionar Feriado" handleClickOnClose={handleClose}>
      <Formik
        initialValues={formInitialValues}
        onSubmit={isUpdate ? handleUpdateHoliday : handleAddHoliday}
        validationSchema={addHolidaySchema}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <Box m={3}>
                <Grid container alignItems="center" spacing={3}>
                  <StyledGrid item lg={6}>
                    <Field
                      component={TextField}
                      name="name"
                      label="Nome do feriado"
                      variant="filled"
                      fullWidth
                    />
                  </StyledGrid>
                  <StyledGrid item lg={6}>
                    <Field
                      component={TextField}
                      select
                      name="type"
                      variant="filled"
                      fullWidth
                      label="Tipo"
                      placeholder="Tipo"
                    >
                      {typeOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </StyledGrid>
                  <StyledGrid item lg={6}>
                    <Field
                      component={TextField}
                      select
                      name="holidayModel"
                      variant="filled"
                      fullWidth
                      label="Modelo"
                      placeholder="Modelo"
                    >
                      {modelOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </StyledGrid>
                  {values.holidayModel === HOLIDAY_MODELS.DATE && (
                    <StyledGrid item lg={6}>
                      <Field
                        component={DatePicker}
                        name="date"
                        label="Data"
                        inputVariant="filled"
                        cancelLabel="CANCELAR"
                        format="dd/MM"
                        fullWidth
                      />
                    </StyledGrid>
                  )}
                  {values.holidayModel === HOLIDAY_MODELS.PERIOD && (
                    <>
                      <StyledGrid item lg={6} />
                      <StyledGrid item lg={6}>
                        <Field
                          component={DatePicker}
                          name="period.start"
                          label="Início do período"
                          inputVariant="filled"
                          cancelLabel="CANCELAR"
                          format="dd/MM"
                          fullWidth
                        />
                      </StyledGrid>
                      <StyledGrid item lg={6}>
                        <Field
                          component={DatePicker}
                          name="period.end"
                          label="Fim do período"
                          inputVariant="filled"
                          cancelLabel="CANCELAR"
                          format="dd/MM"
                          fullWidth
                        />
                      </StyledGrid>
                    </>
                  )}
                </Grid>

                <Box mt={3} mb={3}>
                  <Grid
                    container
                    item
                    lg={12}
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Box mr={4}>
                      <Button
                        color="secondary"
                        onClick={handleClose}
                        size="small"
                      >
                        <Typography
                          fontSize="0.9375rem"
                          fontWeight="bold"
                          opacity="0.7"
                        >
                          CANCELAR
                        </Typography>
                      </Button>
                    </Box>

                    <Button
                      color="secondary"
                      type="submit"
                      disabled={isSubmitting}
                      size="small"
                    >
                      <Typography fontSize="0.9375rem" fontWeight="bold">
                        REGISTRAR FERIADO
                      </Typography>
                    </Button>
                  </Grid>
                </Box>
              </Box>
            </MuiPickersUtilsProvider>
          </Form>
        )}
      </Formik>
    </ModalSkeleton>
  );
};

AddHolidayModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  formInitialValues: PropTypes.objectOf(PropTypes.any),
  isUpdate: PropTypes.bool,
  setForceGetHolidays: PropTypes.func,
};

AddHolidayModal.defaultProps = {
  formInitialValues: {
    name: '',
    type: '',
    holidayModel: '',
    date: null,
    period: {
      start: null,
      end: null,
    },
  },
  isUpdate: false,
  setForceGetHolidays: () => {},
};

export default AddHolidayModal;
