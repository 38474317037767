import {
  Box,
  ButtonBase,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { RadioGroup, TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Grid, TabPanel, Typography } from '../../../../components';
import newJourneySchema from '../../../../validations/newJorney';
import { ContainerInput } from '../styles';

const JourneyDetails = props => {
  const {
    value,
    index,
    handleSaveJourneyDetails,
    initialValues,
    isUpdate,
  } = props;

  const timeOptions = [
    {
      label: '30 min',
      value: '30',
    },
    {
      label: '25 min',
      value: '25',
    },
    {
      label: '20 min',
      value: '20',
    },
    {
      label: '15 min',
      value: '15',
    },
    {
      label: '10 min',
      value: '10',
    },
    {
      label: '5 min',
      value: '5',
    },
  ];

  const pointType = [
    {
      label: 'Normal',
      value: 'Normal',
    },
    {
      label: 'Hora extra',
      value: 'Hora extra',
    },
    {
      label: 'Banco de Horas',
      value: 'Banco de Horas',
    },
  ];

  const compensationType = [
    {
      label: 'Não Compensar',
      value: 'noCompensation',
    },
  ];

  const extraHourTimeOptions = [
    {
      label: '120 min',
      value: '120',
    },
    {
      label: '90 min',
      value: '90',
    },
    {
      label: '60 min',
      value: '60',
    },
    {
      label: '30 min',
      value: '30',
    },
  ];

  return (
    <TabPanel value={value} index={index}>
      <Box p={3}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSaveJourneyDetails}
          validationSchema={newJourneySchema}
          enableReinitialize
        >
          {({ isSubmitting, values, errors, touched, submitForm }) => (
            <Form>
              <Grid container justify="space-between" spacing={2}>
                <Grid item xs={12}>
                  <Typography color="secondary" fontSize="0.875rem">
                    Dados da Jornada
                  </Typography>
                </Grid>
                <Grid item container spacing={3} xs={6}>
                  <ContainerInput item xs={6}>
                    <Field
                      component={TextField}
                      name="name"
                      label="Nome da Jornada"
                      variant="filled"
                      fullWidth
                    />
                  </ContainerInput>
                </Grid>
              </Grid>

              <Grid container justify="space-between" spacing={2} mt="16px">
                <Grid item xs={12}>
                  <Typography color="secondary" fontSize="0.875rem">
                    Tipo de ponto
                  </Typography>
                </Grid>
                <Grid item container spacing={3} xs={6}>
                  <ContainerInput item xs={6}>
                    <Field
                      component={TextField}
                      select
                      name="pointType"
                      variant="filled"
                      fullWidth
                      label="Ponto"
                    >
                      {pointType.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </ContainerInput>
                  <ContainerInput item xs={6}>
                    <Field
                      component={TextField}
                      select
                      name="intervalCompensation"
                      variant="filled"
                      fullWidth
                      label="Compensação de intervalo incompleto"
                    >
                      {compensationType.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </ContainerInput>
                </Grid>
              </Grid>

              <Grid container justify="space-between" spacing={2} mt="16px">
                <Grid item xs={12}>
                  <Typography color="secondary" fontSize="0.875rem">
                    Sinalizar atrasos
                  </Typography>
                </Grid>
                <Grid item container spacing={3} xs={12} alignItems="center">
                  <ContainerInput item xs={3}>
                    <Field
                      component={TextField}
                      select
                      name="entryDelayTime"
                      variant="filled"
                      fullWidth
                      label="Tempo"
                    >
                      {timeOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </ContainerInput>
                  <Grid item xs={3}>
                    <Typography color="secondary" fontSize="16px" ml="40px">
                      de atraso na entrada
                    </Typography>
                  </Grid>
                  <ContainerInput item xs={3}>
                    <Field
                      component={TextField}
                      select
                      name="breakDelayTime"
                      variant="filled"
                      fullWidth
                      label="Tempo"
                    >
                      {timeOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </ContainerInput>
                  <Grid item xs={3}>
                    <Typography color="secondary" fontSize="16px" ml="40px">
                      de atraso na volta do intervalo
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container justify="space-between" spacing={2} mt="16px">
                <Grid item xs={12}>
                  <Typography color="secondary" fontSize="0.875rem">
                    Sinalizar antecipação
                  </Typography>
                </Grid>
                <Grid item container spacing={3} xs={12} alignItems="center">
                  <ContainerInput item xs={3}>
                    <Field
                      component={TextField}
                      select
                      name="anticipationExitTime"
                      variant="filled"
                      fullWidth
                      label="Tempo"
                    >
                      {timeOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </ContainerInput>
                  <Grid item xs={3}>
                    <Typography color="secondary" fontSize="16px" ml="40px">
                      na saída
                    </Typography>
                  </Grid>
                  <ContainerInput item xs={3}>
                    <Field
                      component={TextField}
                      select
                      name="breakAnticipationTime"
                      variant="filled"
                      fullWidth
                      label="Tempo"
                    >
                      {timeOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </ContainerInput>
                  <Grid item xs={3}>
                    <Typography color="secondary" fontSize="16px" ml="40px">
                      no início do intervalo
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container justify="space-between" spacing={2} mt="16px">
                <Grid item xs={12}>
                  <Typography color="secondary" fontSize="0.875rem">
                    Limitar Jornada
                  </Typography>
                </Grid>
                <Grid item container spacing={3} xs={12} alignItems="center">
                  <ContainerInput item xs={3}>
                    <FormControl error={touched.limited && !!errors.limited}>
                      <FormLabel component="legend">
                        <Typography
                          opacity="0.7"
                          color="primary"
                          fontSize="14px"
                        >
                          Deseja limitar essa jornada
                        </Typography>
                      </FormLabel>
                      <Field component={RadioGroup} name="limited" row>
                        <FormControlLabel
                          value="true"
                          control={
                            <Radio color="primary" disabled={isSubmitting} />
                          }
                          label="SIM"
                          disabled={isSubmitting}
                        />
                        <FormControlLabel
                          value="false"
                          control={
                            <Radio color="primary" disabled={isSubmitting} />
                          }
                          label="NÃO"
                          disabled={isSubmitting}
                        />
                      </Field>
                      <FormHelperText>
                        {touched.limited && errors.limited}
                      </FormHelperText>
                    </FormControl>
                  </ContainerInput>
                  <ContainerInput item xs={3}>
                    {values.limited === 'true' && (
                      <Field
                        component={TextField}
                        select
                        name="limitTime"
                        variant="filled"
                        fullWidth
                        label="Tempo"
                      >
                        {timeOptions.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    )}
                  </ContainerInput>
                  <Grid item xs={3}>
                    {values.limited === 'true' && (
                      <Typography color="secondary" fontSize="16px" ml="40px">
                        entrada
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container justify="space-between" spacing={2} mt="16px">
                <Grid item xs={12}>
                  <Typography color="secondary" fontSize="0.875rem">
                    Hora extra
                  </Typography>
                </Grid>
                <Grid item container spacing={3} xs={6} alignItems="center">
                  <ContainerInput item xs={6}>
                    <Field
                      component={TextField}
                      select
                      name="extraHourPerDay"
                      variant="filled"
                      fullWidth
                      label="Tempo"
                    >
                      {extraHourTimeOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </ContainerInput>
                  <Grid item xs={6}>
                    <Typography color="secondary" fontSize="16px" ml="40px">
                      Horas extras por dia
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container spacing={3} justify="flex-end" mt="16px">
                <ButtonBase onClick={submitForm}>
                  <Typography
                    fontSize="14px"
                    fontWeight="bold"
                    color="secondary"
                  >
                    {isUpdate ? 'ALTERAR DADOS' : 'PROXIMA ETAPA'}
                  </Typography>
                </ButtonBase>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </TabPanel>
  );
};

JourneyDetails.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  handleSaveJourneyDetails: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  isUpdate: PropTypes.bool,
};

JourneyDetails.defaultProps = {
  isUpdate: false,
};
export default JourneyDetails;
