import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ContractDownloader,
  Grid,
  TabPanel,
  Typography,
  Uploader,
} from '../../../components';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import toFormData from '../../../utils/formData';
import { TabContainer } from '../styles';

const Documents = props => {
  const { value, index, setUser, data, ...other } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [terminationTermFile, setTerminationTermFile] = useState(null);
  const [FGTSGuideFile, setFGTSGuideFile] = useState(null);
  const [
    unemploymentInsuranceGuideFile,
    setUnemploymentInsuranceGuideFile,
  ] = useState(null);
  const [paymentReceiptsFile, setPaymentReceiptsFile] = useState(null);
  const [otherDocumentsFile, setOtherDocumentsFile] = useState(null);

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();

  const {
    terminationTerm,
    FGTSGuide,
    unemploymentInsuranceGuide,
    paymentReceipts,
    otherDocuments,
  } = data;

  const submitDismissalDocuments = useCallback(async () => {
    try {
      setIsSubmitting(true);

      if (
        !terminationTermFile &&
        !FGTSGuideFile &&
        !unemploymentInsuranceGuideFile &&
        !paymentReceiptsFile &&
        !otherDocumentsFile
      ) {
        dispatch(setSnackbar(true, 'error', 'Nenhum arquivo selecionado'));
        return;
      }

      const dismissalDocuments = {
        terminationTerm: terminationTermFile,
        FGTSGuide: FGTSGuideFile,
        unemploymentInsuranceGuide: unemploymentInsuranceGuideFile,
        paymentReceipts: paymentReceiptsFile,
        otherDocuments: otherDocumentsFile,
      };

      const formObj = toFormData(dismissalDocuments);

      const api = AuthenticatedHttpClient();
      const response = await api.put(
        `users/dismissal-documents/${collaboratorId}`,
        formObj,
      );

      setUser(response.data);

      setTerminationTermFile(null);
      setFGTSGuideFile(null);
      setUnemploymentInsuranceGuideFile(null);
      setPaymentReceiptsFile(null);
      setOtherDocumentsFile(null);

      dispatch(setSnackbar(true, 'success', 'Documentos enviados'));
    } catch (error) {
      dispatch(
        setSnackbar(true, 'error', 'Não foi possível enviar os documentos'),
      );
    } finally {
      setIsSubmitting(false);
      dispatch(setLoading(false));
    }
  }, [
    terminationTermFile,
    FGTSGuideFile,
    unemploymentInsuranceGuideFile,
    paymentReceiptsFile,
    otherDocumentsFile,
    collaboratorId,
    setUser,
    dispatch,
  ]);

  const handleRemoveFile = useCallback(
    async document => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        const response = await api.delete(
          `users/dismissal-documents/${collaboratorId}?document=${document}`,
        );

        dispatch(setSnackbar(true, 'success', 'Documento removido'));
        setUser(response.data);
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao remover documento'));
      } finally {
        dispatch(setLoading(false));
      }
    },
    [collaboratorId, dispatch, setUser],
  );

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Grid container alignItems="center" spacing={2}>
          <Grid item lg={12}>
            <Typography fontSize="14px" fontWeight="400">
              Documentos
            </Typography>
          </Grid>
          <Grid item lg={3}>
            {terminationTerm ? (
              <>
                <Typography
                  fontSize="12px"
                  opacity="0.5"
                  fontWeight={300}
                  mb="8px"
                >
                  Termo de recisão
                </Typography>
                <ContractDownloader
                  file={{
                    location: terminationTerm.location,
                    name: terminationTerm.name,
                    status: terminationTerm.status,
                  }}
                  remove
                  removeFn={() => handleRemoveFile('terminationTerm')}
                  title="Termo de recisão"
                />
              </>
            ) : (
              <Uploader
                accept="application/pdf"
                dropText="Adicionar termo"
                title="Termo de recisão"
                onFileUploaded={setTerminationTermFile}
              />
            )}
          </Grid>

          <Grid item lg={3}>
            {FGTSGuide ? (
              <>
                <Typography
                  fontSize="12px"
                  opacity="0.5"
                  fontWeight={300}
                  mb="8px"
                >
                  Guias FGTS
                </Typography>
                <ContractDownloader
                  file={{
                    location: FGTSGuide.location,
                    name: FGTSGuide.name,
                    status: FGTSGuide.status,
                  }}
                  remove
                  removeFn={() => handleRemoveFile('FGTSGuide')}
                  title="Guias FGTS"
                />
              </>
            ) : (
              <Uploader
                accept="application/pdf"
                dropText="Adicionar guias"
                title="Guias FGTS"
                onFileUploaded={setFGTSGuideFile}
              />
            )}
          </Grid>

          <Grid item lg={3}>
            {unemploymentInsuranceGuide ? (
              <>
                <Typography
                  fontSize="12px"
                  opacity="0.5"
                  fontWeight={300}
                  mb="8px"
                >
                  Guias seguro desemprego
                </Typography>
                <ContractDownloader
                  file={{
                    location: unemploymentInsuranceGuide.location,
                    name: unemploymentInsuranceGuide.name,
                    status: unemploymentInsuranceGuide.status,
                  }}
                  remove
                  removeFn={() =>
                    handleRemoveFile('unemploymentInsuranceGuide')
                  }
                  title="Guias seguro desemprego"
                />
              </>
            ) : (
              <Uploader
                accept="application/pdf"
                dropText="Adicionar guias"
                title="Guias seguro desemprego"
                onFileUploaded={setUnemploymentInsuranceGuideFile}
              />
            )}
          </Grid>

          <Grid item lg={3}>
            {paymentReceipts ? (
              <>
                <Typography
                  fontSize="12px"
                  opacity="0.5"
                  fontWeight={300}
                  mb="8px"
                >
                  Comprovantes de pagamentos
                </Typography>
                <ContractDownloader
                  file={{
                    location: paymentReceipts.location,
                    name: paymentReceipts.name,
                    status: paymentReceipts.status,
                  }}
                  remove
                  removeFn={() => handleRemoveFile('paymentReceipts')}
                  title="Comprovantes de pagamentos"
                />
              </>
            ) : (
              <Uploader
                accept="application/pdf"
                dropText="Adicionar comprovantes"
                title="Comprovantes de pagamentos"
                onFileUploaded={setPaymentReceiptsFile}
              />
            )}
          </Grid>

          <Grid item lg={12}>
            <Typography fontSize="14px" fontWeight="400">
              Outros documentos
            </Typography>
          </Grid>
          <Grid item lg={3}>
            {otherDocuments ? (
              <>
                <Typography
                  fontSize="12px"
                  opacity="0.5"
                  fontWeight={300}
                  mb="8px"
                >
                  Outros
                </Typography>
                <ContractDownloader
                  file={{
                    location: otherDocuments.location,
                    name: otherDocuments.name,
                    status: otherDocuments.status,
                  }}
                  remove
                  removeFn={() => handleRemoveFile('otherDocuments')}
                  title="Outros"
                />
              </>
            ) : (
              <Uploader
                accept="application/pdf"
                dropText="Adicionar contrato"
                title="Outros"
                onFileUploaded={setOtherDocumentsFile}
              />
            )}
          </Grid>

          <Grid container item lg={12} justify="flex-end" alignItems="center">
            <Button
              color="secondary"
              onClick={submitDismissalDocuments}
              disabled={isSubmitting}
            >
              SALVAR
            </Button>
          </Grid>
        </Grid>
      </TabContainer>
    </TabPanel>
  );
};

Documents.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
};

Documents.defaultProps = {
  data: {},
};

export default Documents;
