/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Redirect, Route as ReactDOMRoute } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import { ROLES } from '../utils/enums';

const Route = ({
  isPrivate = false,
  component: Component,
  authorization,
  ...rest
}) => {
  const { user } = useAuth();

  const handlePath = useCallback(() => {
    const authenticatedPaths = {
      [ROLES.ADMISSION_PROCESS]: '/collaborator/admissions',
      [ROLES.EMPLOYEE]: '/colaborator-home',
      [ROLES.ADMINISTRATOR_COMPANY]: '/home/admissions',
      [ROLES.HUMAN_RESOURCES_MANAGMENT]: '/home/admissions',
      [ROLES.ADMINISTRATOR]: '/to-do',
      [ROLES.WAITING_CONTRACT_SIGN]: '/steps/admission-exam',
    };

    const loginPaths = {
      [ROLES.ADMISSION_PROCESS]: '/admission-process',
      [ROLES.EMPLOYEE]: '/',
      [ROLES.ADMINISTRATOR_COMPANY]: '/',
      [ROLES.HUMAN_RESOURCES_MANAGMENT]: '/',
      [ROLES.ADMINISTRATOR]: '/',
      [ROLES.WAITING_CONTRACT_SIGN]: '/admission-process',
    };

    if (user && user.role && isPrivate) {
      return loginPaths[user.role];
    }
    if (user && user.role && !isPrivate) {
      return authenticatedPaths[user.role];
    }
    if (isPrivate) {
      return loginPaths[authorization[0]];
    }

    return authenticatedPaths[authorization[0]];
  }, [user, isPrivate, authorization]);

  const isAuthorized = useCallback(() => {
    if (user) {
      if (isPrivate && authorization.includes(user.role)) {
        return true;
      }
      return false;
    }
    if (!isPrivate) return true;
    return false;
  }, [user, isPrivate, authorization]);

  const pathname = handlePath();
  const authorized = isAuthorized();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return authorized ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

Route.defaultProps = {
  isPrivate: false,
  authorization: Object.keys(ROLES),
};

Route.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.elementType.isRequired,
  authorization: PropTypes.arrayOf(PropTypes.string),
};

export default Route;
