import { Button, FormControl, Grid, MenuItem } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CPFInput, DateInput, ModalSkeleton } from '../../../../components';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import newDependentSchema from '../../../../validations/newDependent';
import { ActionsBar, ContainerModal, StyledGrid } from './styles';

const AddColaboratorModal = ({ handleClickOnClose, setNewDependent }) => {
  const initialValues = {
    dependentName: '',
    dependentType: '',
    dependentbirthDate: '',
    dependentCpf: '',
  };

  const dispatch = useDispatch();

  const handleAddDependent = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(false);
      let hasDuplicatedCpf = false;
      setNewDependent(oldState => {
        const isDuplicated = oldState.filter(({ dependentCpf }) => {
          return values.dependentCpf === dependentCpf;
        });

        if (isDuplicated.length) {
          hasDuplicatedCpf = true;

          return oldState;
        }

        return [...oldState, values];
      });
      if (hasDuplicatedCpf) {
        dispatch(
          setSnackbar(
            true,
            'error',
            'Já existe um dependente cadastrado com esse CPF',
          ),
        );
        return;
      }
      handleClickOnClose();
    },
    [handleClickOnClose, setNewDependent, dispatch],
  );

  return (
    <ModalSkeleton
      title="Adicionar novo colaborador"
      handleClickOnClose={handleClickOnClose}
    >
      <ContainerModal>
        <Formik
          initialValues={initialValues}
          validationSchema={newDependentSchema}
          onSubmit={handleAddDependent}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid
                container
                alignItems="center"
                justify="space-between"
                spacing={2}
              >
                <StyledGrid item lg={6}>
                  <Field
                    component={TextField}
                    name="dependentName"
                    label="Nome completo"
                    variant="filled"
                    fullWidth
                  />
                </StyledGrid>
                <StyledGrid item lg={6}>
                  <FormControl fullWidth variant="filled">
                    <Field
                      component={TextField}
                      select
                      name="dependentType"
                      variant="filled"
                      fullWidth
                      label="Tipo"
                    >
                      <MenuItem value="Filho(a)">Filho(a)</MenuItem>
                      <MenuItem value="Cônjuge">Cônjuge</MenuItem>
                    </Field>
                  </FormControl>
                </StyledGrid>
                <StyledGrid item lg={6}>
                  <Field
                    component={TextField}
                    name="dependentbirthDate"
                    label="Data de nascimento"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      inputComponent: DateInput,
                    }}
                  />
                </StyledGrid>
                <StyledGrid item lg={6}>
                  <Field
                    component={TextField}
                    name="dependentCpf"
                    label="CPF"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      inputComponent: CPFInput,
                    }}
                  />
                </StyledGrid>
              </Grid>
              <ActionsBar container alignItems="center" justify="flex-end">
                <Button
                  disabled={isSubmitting}
                  type="buton"
                  color="secondary"
                  onClick={handleClickOnClose}
                  size="small"
                >
                  CANCELAR
                </Button>
                <Button
                  color="secondary"
                  disabled={isSubmitting}
                  type="submit"
                  size="small"
                >
                  ADICIONAR
                </Button>
              </ActionsBar>
            </Form>
          )}
        </Formik>
      </ContainerModal>
    </ModalSkeleton>
  );
};

AddColaboratorModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
  setNewDependent: PropTypes.func.isRequired,
};

export default AddColaboratorModal;
