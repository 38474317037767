import { Box, Grid, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Promotion from '../../../assets/promotion.svg';
import { MoneyInput, TabPanel, Typography } from '../../../components/index';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import PromotionModal from '../PromotionModal';
import { Card, ContainerInput, StyledTextField, TabContainer } from '../styles';
import { Additional } from './components/index';

const HiringData = props => {
  const {
    value,
    index,
    stepStatus,
    data,
    depart,
    job,
    setUser,
    ...other
  } = props;
  const [activePromotion, setActivePromotion] = useState(null);
  const [openPromotionModal, setOpenPromotionModal] = useState(false);
  const [additionalSelected, setAdditionalSelected] = useState({
    dangerousness: false,
    risk: false,
    warning: false,
    unhealthy: false,
    unhealthyPercentage: '',
  });
  const [jobRole, setJobRole] = useState({
    name: '',
  });
  const [department, setDepartment] = useState({
    name: '',
  });

  const [hiringData, setHiringData] = useState({
    regime: '',
    salary: '',
    vt: '',
    vr: '',
    va: '',
  });

  const dispatch = useDispatch();

  const { userId: collaboratorId } = useParams();

  useEffect(() => {
    if (Object.keys(data).length) {
      setHiringData(data);
      setJobRole(job);
      setDepartment(depart);
      setAdditionalSelected(data.additional);
    }
  }, [data, depart, job]);

  useEffect(() => {
    const getCollaboratorActivePromotion = async () => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();
        const { data: promotion } = await api.get(
          `/promotion/${collaboratorId}`,
        );
        setActivePromotion(promotion);
      } catch (e) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possivel buscar as promoções.'),
        );
      } finally {
        dispatch(setLoading(false));
      }
    };

    getCollaboratorActivePromotion();
  }, [collaboratorId, dispatch]);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography fontSize="16px" color="secondary" fontWeight="300">
              Dados de contratação
            </Typography>
          </Grid>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="regime"
              label="Regime trabalhista"
              variant="filled"
              fullWidth
              value={hiringData.regime}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="jobRole"
              label="Cargo"
              variant="filled"
              fullWidth
              value={jobRole.name}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="department"
              label="Departamento"
              variant="filled"
              fullWidth
              value={department.name}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="salary"
              label="Salário"
              variant="filled"
              fullWidth
              value={hiringData.salary}
              InputProps={{
                inputComponent: MoneyInput,
              }}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="vt"
              label="Vale transporte"
              variant="filled"
              fullWidth
              value={hiringData.vt}
              InputProps={{
                inputComponent: MoneyInput,
              }}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="vr"
              label="Vale refeição"
              variant="filled"
              fullWidth
              value={hiringData.vr}
              InputProps={{
                inputComponent: MoneyInput,
              }}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="va"
              label="Vale alimentação"
              variant="filled"
              fullWidth
              value={hiringData.va}
              InputProps={{
                inputComponent: MoneyInput,
              }}
              disabled
            />
          </ContainerInput>
          {(additionalSelected.dangerousness ||
            additionalSelected.risk ||
            additionalSelected.unhealthy ||
            additionalSelected.warning) && (
            <>
              <Grid item xs={12}>
                <Typography fontSize="16px" color="secondary" fontWeight="300">
                  Adicionais
                </Typography>
              </Grid>

              {additionalSelected.warning && (
                <Grid item xs={12}>
                  <Additional
                    type="Sobreaviso"
                    context="Quem precisa estar disponível a qualquer momento do seu período de descanso para voltar ao trabalho. Essa hora de sobreaviso equivale a um terço do valor da hora regular de trabalho."
                  />
                </Grid>
              )}
              {additionalSelected.unhealthy && (
                <Grid item xs={12}>
                  <Additional
                    unhealthy
                    type="Insalubridade"
                    percentage="20%"
                    context="O adicional de insalubridade é o benefício pago quando há exposição a agentes físicos, químicos e biológicos que coloquem em risco a saúde do funcionário. As condições que são consideradas insalubres estão previstas na Norma Regulamentadora 15, da Portaria n. 3.214/78. O valor do adicional de insalubridade varia entre 10% e 40% de acordo com o nível de exposição, mas essa porcentagem é calculada sobre o valor do salário mínimo e não sobre o salário recebido."
                  />
                </Grid>
              )}
              {additionalSelected.risk && (
                <Grid item xs={12}>
                  <Additional
                    type="Risco"
                    context="Funcionários de portos que trabalham em mar ou em terra têm direito ao adicional de risco, regulado pela Lei 4.860 de 26.11.1965. O valor do benefício é de 40% sobre o valor do salário por hora de trabalho regular diurna. Segundo previsto no artigo 14 da lei, o pagamento desse adicional substitui adicionais de insalubridade e de periculosidade."
                  />
                </Grid>
              )}
              {additionalSelected.dangerousness && (
                <Grid item xs={12}>
                  <Additional
                    type="Periculosidade"
                    context="Segundo a NR 16 do Ministério do Trabalho e do Emprego, uma determinada atividade é considerada perigosa quando coloca em risco a integridade física do funcionário. Quem lida com produtos explosivos, produtos inflamáveis, energia elétrica tem direito a receber o adicional de periculosidade. Esse adicional é de 30% sobre o salário pago. A CLT estabelece, no artigo 195, que haja laudo pericial constatando o risco. Mas atenção: os adicionais de insalubridade e de periculosidade não podem ser recebidos juntos. Então, se as condições são perigosas e insalubres, o empregado receberá somente o adicional de maior valor."
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <Card
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              color="white"
              onClick={() => setOpenPromotionModal(true)}
              visible={!!activePromotion}
            >
              <Box py={2} px={3}>
                <Typography fontSize="35px" fontWeight="bold">
                  Parabéns
                </Typography>
                <Typography mb="10px" fontSize="18px" fontWeight="bold">
                  Você acabou de ser promovido!
                </Typography>
                <Typography fontSize="14px" fontWeight="300">
                  Temos uma grande novidade para você, clique neste card e veja
                  mais detalhes!
                </Typography>
              </Box>
              <Box>
                <img width="100%" src={Promotion} alt="" />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </TabContainer>
      <Modal
        open={openPromotionModal}
        onClose={() => setOpenPromotionModal(false)}
      >
        <PromotionModal
          handleClose={() => setOpenPromotionModal(false)}
          activePromotion={{
            ...activePromotion?.afterPromotion,
            _id: activePromotion?._id,
          }}
          user={activePromotion?.user}
          setActivePromotion={setActivePromotion}
        />
      </Modal>
    </TabPanel>
  );
};

HiringData.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  stepStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  depart: PropTypes.objectOf(PropTypes.any),
  job: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
};

HiringData.defaultProps = {
  data: {},
  depart: {},
  job: {},
};

export default HiringData;
