import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal, DialogContent, Box } from '@material-ui/core';

import { STEPS } from '../../../utils/enums';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';

import {
  TabPanel,
  RefusedStepModal,
  Grid,
  Downloader,
} from '../../../components';
import ApprovalStep from '../ApprovalStep';
import {
  TabContainer,
  ContainerInput,
  SubTitleForm,
  StyledButtonFail,
  StyledButtonPass,
  StyledTypography,
  StyledTextField,
} from '../styles';

const BankData = props => {
  const { value, index, stepStatus, data, setUser, ...other } = props;
  const [openRefusedStepModal, setOpenRefusedStepModal] = useState(false);
  const [bankDataStatus, setBankDataStatus] = useState({});
  const [bankData, setBankData] = useState({
    bank: '',
    agency: '',
    typeAccount: '',
    account: '',
    digit: '',
  });

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();

  useEffect(() => {
    if (Object.keys(data).length) {
      setBankData(data);
    }
    if (stepStatus) {
      setBankDataStatus(stepStatus);
    }
  }, [data, stepStatus]);

  const handleApproveStatus = useCallback(async () => {
    try {
      dispatch(setLoading(true));

      const api = AuthenticatedHttpClient();
      const response = await api.put(`users/update-step/${collaboratorId}`, {
        stepName: STEPS.BANK_DATA,
        status: 'approved',
      });

      setUser(response.data);
      dispatch(setSnackbar(true, 'success', 'Dados aprovados'));
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Falha ao aprovar'));
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch, collaboratorId, setUser]);

  const handleCloseModal = useCallback(
    userUpdated => {
      setOpenRefusedStepModal(false);
      if (userUpdated) {
        setUser(userUpdated);
      }
    },
    [setUser],
  );

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Box
          component={Grid}
          container
          alignItems="center"
          justify="space-between"
          spacing={2}
          p={1}
        >
          <SubTitleForm>Dados do Banco</SubTitleForm>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="bank"
              label="Banco"
              variant="filled"
              value={bankData.bank}
              fullWidth
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="agency"
              label="Agência"
              variant="filled"
              value={bankData.agency}
              fullWidth
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="account"
              label="Conta"
              variant="filled"
              value={bankData.account}
              fullWidth
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="digit"
              label="Digito"
              variant="filled"
              value={bankData.digit}
              fullWidth
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="typeAccount"
              label="Tipo de conta"
              variant="filled"
              value={bankData.typeAccount}
              fullWidth
            />
          </ContainerInput>
          {bankData.photoBankAccountReceipt && (
            <>
              <SubTitleForm>Foto do comprovante bancário</SubTitleForm>
              <ContainerInput item lg={3}>
                <Downloader
                  file={{
                    name: bankData.photoBankAccountReceipt.name,
                    size: bankData.photoBankAccountReceipt.size,
                    key: bankData.photoBankAccountReceipt.key,
                  }}
                />
              </ContainerInput>
            </>
          )}
        </Box>
        {(bankDataStatus.status === 'approved' ||
          bankDataStatus.status === 'disapproved') && (
          <ApprovalStep
            status={bankDataStatus.status}
            reason={bankDataStatus.reason}
          />
        )}
        {bankDataStatus.status === 'underAnalysis' && (
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <StyledButtonFail onClick={() => setOpenRefusedStepModal(true)}>
              <StyledTypography color="error">REPROVAR</StyledTypography>
            </StyledButtonFail>
            <StyledButtonPass onClick={handleApproveStatus}>
              <StyledTypography color="secondary">APROVAR</StyledTypography>
            </StyledButtonPass>
          </Box>
        )}
      </TabContainer>
      <Modal open={openRefusedStepModal} onClose={handleCloseModal}>
        <DialogContent>
          <RefusedStepModal
            stepName={STEPS.BANK_DATA}
            stepTitle="Dados Bancários"
            handleClickOnClose={handleCloseModal}
          />
        </DialogContent>
      </Modal>
    </TabPanel>
  );
};

BankData.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  stepStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
};

BankData.defaultProps = {
  data: {},
};

export default BankData;
