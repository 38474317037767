import React from 'react';
import { Switch } from 'react-router-dom';
import {
  AdmissionAuthentication,
  AdmissionHome,
  AdmissionSteps,
  Login,
} from '../pages';
import ColaboratorHome from '../pages/ColaboratorHome';
import Home from '../pages/Home';
import { ROLES } from '../utils/enums';
import Route from './Route';

const Routes = () => (
  <Switch>
    <Route component={Login} path="/" exact />
    <Route
      component={Home}
      path="/home"
      isPrivate
      authorization={[
        ROLES.ADMINISTRATOR_COMPANY,
        ROLES.HUMAN_RESOURCES_MANAGMENT,
      ]}
    />
    <Route
      component={ColaboratorHome}
      path="/colaborator-home"
      isPrivate
      authorization={[ROLES.EMPLOYEE]}
    />
    <Route component={AdmissionAuthentication} path="/admission-process" />
    <Route
      component={AdmissionHome}
      path="/collaborator"
      isPrivate
      authorization={[ROLES.ADMISSION_PROCESS, ROLES.WAITING_CONTRACT_SIGN]}
    />
    <Route
      path="/steps"
      isPrivate
      authorization={[ROLES.ADMISSION_PROCESS, ROLES.WAITING_CONTRACT_SIGN]}
      component={AdmissionSteps}
    />
  </Switch>
);

export default Routes;
