import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { formatISO, parse } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  CPFInput,
  CustomAccordion,
  DateInput,
  Downloader,
  NumericInput,
  PISInput,
  TabPanel,
  Typography,
} from '../../../components';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import checkCpfValidation from '../../../utils/checkCpfValidation';
import documentationSchema from '../../../validations/documentation';
import { ContainerInput, StyledBox, TabContainer } from '../styles';

const Documentation = props => {
  const { value, index, data, setUser, dependents, ...other } = props;
  const [documentsInitialValues, setDocumentsInitialValues] = useState({
    cpf: '',
    pisNumber: '',
    rgNumber: '',
    rgExpeditionDate: '',
    rgOrganUfEmitter: '',
    voterRegistrationNumber: '',
    voterRegistrationZone: '',
    voterRegistrationSection: '',
    reservistNumber: '',
  });
  const [expanded, setExpanded] = useState('panel1');

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();

  useEffect(() => {
    if (Object.keys(data).length) {
      const {
        cpf,
        pisNumber,
        rgNumber,
        rgExpeditionDate,
        rgOrganUfEmitter,
        voterRegistrationNumber,
        voterRegistrationZone,
        voterRegistrationSection,
        reservistNumber,
      } = data;

      let rgDate;
      if (rgExpeditionDate) {
        rgDate = new Date(rgExpeditionDate).toLocaleDateString('pt-br');
      }

      setDocumentsInitialValues({
        cpf,
        pisNumber: pisNumber || '',
        rgNumber: rgNumber || '',
        rgExpeditionDate: rgDate || '',
        rgOrganUfEmitter: rgOrganUfEmitter || '',
        voterRegistrationNumber: voterRegistrationNumber || '',
        voterRegistrationZone: voterRegistrationZone || '',
        voterRegistrationSection: voterRegistrationSection || '',
        reservistNumber: reservistNumber || '',
      });
    }
  }, [data]);

  const handleSubmitDocumentation = useCallback(
    async (values, { setSubmitting }) => {
      try {
        if (!checkCpfValidation(dependents, values.cpf)) {
          dispatch(
            setSnackbar(
              true,
              'error',
              'Já existe esse CPF entre os dependentes',
            ),
          );
          return;
        }

        dispatch(setLoading(true));

        const api = AuthenticatedHttpClient();

        const formObject = {
          ...values,
        };

        if (values.rgExpeditionDate) {
          const parsedDate = parse(
            values.rgExpeditionDate,
            'dd/MM/yyyy',
            new Date(),
          );
          formObject.rgExpeditionDate = formatISO(parsedDate);
        }

        const response = await api.put(`users/${collaboratorId}`, {
          documents: { ...formObject },
        });

        setUser(response.data);
        dispatch(setSnackbar(true, 'success', 'Dados alterados'));
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possível alterar os dados'),
        );
      } finally {
        setSubmitting(false);
        dispatch(setLoading(false));
      }
    },
    [dispatch, setUser, collaboratorId, dependents],
  );

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Formik
          initialValues={documentsInitialValues}
          onSubmit={handleSubmitDocumentation}
          validationSchema={documentationSchema}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <StyledBox>
                <CustomAccordion
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1')}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                      component="legend"
                      fontSize="16px"
                      mb="8px"
                      mt="8px"
                      width="100%"
                    >
                      CPF
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container justify="space-between" spacing={5}>
                      <Grid container item lg={4} direction="column">
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Nº CPF
                        </Typography>
                        <ContainerInput item>
                          <Field
                            component={TextField}
                            name="cpf"
                            label="Número do CPF"
                            variant="filled"
                            fullWidth
                            InputProps={{
                              inputComponent: CPFInput,
                            }}
                          />
                        </ContainerInput>
                      </Grid>

                      <Grid item lg={4}>
                        {data.photoCPF && (
                          <>
                            <Typography
                              component="legend"
                              fontSize="14px"
                              mb="16px"
                            >
                              Foto do CPF
                            </Typography>
                            <Downloader
                              file={{
                                key: data.photoCPF.key,
                                name: data.photoCPF.name,
                                size: data.photoCPF.size,
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item lg={4} />
                    </Grid>
                  </AccordionDetails>
                </CustomAccordion>

                <CustomAccordion
                  expanded={expanded === 'panel2'}
                  onChange={handleChange('panel2')}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                      component="legend"
                      fontSize="16px"
                      mb="8px"
                      mt="8px"
                      width="100%"
                    >
                      PIS
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container justify="space-between" py="16px">
                      <Grid container item lg={4} direction="column">
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Nº PIS
                        </Typography>
                        <Field
                          component={TextField}
                          name="pisNumber"
                          label="Número"
                          variant="filled"
                          fullWidth
                          InputProps={{
                            inputComponent: PISInput,
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} />
                      <Grid item lg={4} />
                    </Grid>
                  </AccordionDetails>
                </CustomAccordion>

                <CustomAccordion
                  expanded={expanded === 'panel3'}
                  onChange={handleChange('panel3')}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                      component="legend"
                      fontSize="16px"
                      mb="8px"
                      mt="8px"
                      width="100%"
                    >
                      RG
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container justify="space-between" spacing={5}>
                      <Grid container item lg={4} direction="column">
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Nº RG
                        </Typography>
                        <ContainerInput item>
                          <Field
                            component={TextField}
                            name="rgNumber"
                            label="Número"
                            variant="filled"
                            fullWidth
                            InputProps={{
                              inputComponent: NumericInput,
                            }}
                          />
                        </ContainerInput>
                        {data.photoFrontRg && (
                          <>
                            <Typography
                              component="legend"
                              fontSize="14px"
                              mb="16px"
                            >
                              Foto do RG frente
                            </Typography>
                            <Downloader
                              file={{
                                key: data.photoFrontRg.key,
                                name: data.photoFrontRg.name,
                                size: data.photoFrontRg.size,
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid container item lg={4} direction="column">
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Data expedição
                        </Typography>
                        <ContainerInput item>
                          <Field
                            component={TextField}
                            name="rgExpeditionDate"
                            label="Data"
                            variant="filled"
                            fullWidth
                            InputProps={{
                              inputComponent: DateInput,
                            }}
                          />
                        </ContainerInput>
                        {data.photoBackRg && (
                          <>
                            <Typography
                              component="legend"
                              fontSize="14px"
                              mb="16px"
                            >
                              Foto do RG verso
                            </Typography>
                            <Downloader
                              file={{
                                key: data.photoBackRg.key,
                                name: data.photoBackRg.name,
                                size: data.photoBackRg.size,
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item lg={4}>
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Órgão e UF emissor
                        </Typography>
                        <ContainerInput item>
                          <Field
                            component={TextField}
                            name="rgOrganUfEmitter"
                            label="Órgão + UF"
                            variant="filled"
                            placeholder="SSP/MG"
                            fullWidth
                          />
                        </ContainerInput>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </CustomAccordion>

                <CustomAccordion
                  expanded={expanded === 'panel4'}
                  onChange={handleChange('panel4')}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                      component="legend"
                      fontSize="16px"
                      mb="8px"
                      mt="8px"
                      width="100%"
                    >
                      Título de eleitor
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container justify="space-between" spacing={5}>
                      <Grid container item lg={4} direction="column">
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Nº do título
                        </Typography>
                        <ContainerInput item>
                          <Field
                            component={TextField}
                            name="voterRegistrationNumber"
                            label="Número"
                            variant="filled"
                            fullWidth
                            InputProps={{
                              inputComponent: NumericInput,
                            }}
                          />
                        </ContainerInput>
                        {data.photoTitle && (
                          <>
                            <Typography
                              component="legend"
                              fontSize="14px"
                              mb="16px"
                            >
                              Foto do título eleitoral
                            </Typography>
                            <Downloader
                              file={{
                                key: data.photoTitle.key,
                                name: data.photoTitle.name,
                                size: data.photoTitle.size,
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid container item lg={4} direction="column">
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Zona eleitoral
                        </Typography>
                        <ContainerInput item>
                          <Field
                            component={TextField}
                            name="voterRegistrationZone"
                            label="Zona"
                            variant="filled"
                            fullWidth
                            InputProps={{
                              inputComponent: NumericInput,
                            }}
                          />
                        </ContainerInput>
                      </Grid>
                      <Grid item lg={4}>
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Seção eleitoral
                        </Typography>
                        <ContainerInput item>
                          <Field
                            component={TextField}
                            name="voterRegistrationSection"
                            label="Seção"
                            variant="filled"
                            fullWidth
                            InputProps={{
                              inputComponent: NumericInput,
                            }}
                          />
                        </ContainerInput>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </CustomAccordion>

                <CustomAccordion
                  expanded={expanded === 'panel5'}
                  onChange={handleChange('panel5')}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                      component="legend"
                      fontSize="16px"
                      mb="8px"
                      mt="8px"
                      width="100%"
                    >
                      Reservista
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container justify="space-between" spacing={5}>
                      <Grid container item lg={4} direction="column">
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Nº Reservista
                        </Typography>
                        <ContainerInput item>
                          <Field
                            component={TextField}
                            name="reservistNumber"
                            label="Nº do certificado militar"
                            variant="filled"
                            fullWidth
                            InputProps={{
                              inputComponent: NumericInput,
                            }}
                          />
                        </ContainerInput>
                      </Grid>

                      <Grid item lg={4}>
                        {data.photoReservist && (
                          <>
                            <Typography
                              component="legend"
                              fontSize="14px"
                              mb="16px"
                            >
                              Foto da reservista
                            </Typography>
                            <Downloader
                              file={{
                                key: data.photoReservist.key,
                                name: data.photoReservist.name,
                                size: data.photoReservist.size,
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item lg={4} />
                    </Grid>
                  </AccordionDetails>
                </CustomAccordion>
              </StyledBox>
              <Box width={1} display="flex" justifyContent="flex-end" my={2}>
                <Button type="submit" color="secondary" disabled={isSubmitting}>
                  ALTERAR DADOS
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </TabContainer>
    </TabPanel>
  );
};

Documentation.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
  dependents: PropTypes.arrayOf(PropTypes.any).isRequired,
};

Documentation.defaultProps = {
  data: {},
};

export default Documentation;
