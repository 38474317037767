import {
  AccordionDetails,
  AccordionSummary,
  Box,
  useTheme,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { format, formatISO, parse, parseISO } from 'date-fns';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import backRGImg from '../../../../assets/back-rg.svg';
import frontRGImg from '../../../../assets/front-rg.svg';
import cpfImg from '../../../../assets/photo-cpf.svg';
import reservistImg from '../../../../assets/photo-reservist.svg';
import titleImg from '../../../../assets/photo-title.svg';
import {
  CollaboratorButton,
  CPFInput,
  CustomAccordion,
  DateInput,
  Grid,
  NumericInput,
  PISInput,
  Typography,
  Uploader,
  UserInfo,
} from '../../../../components';
import withCollaboratorSkeleton from '../../../../components/CollaboratorSkeleton';
import { useAuth } from '../../../../hooks/auth';
import useUser from '../../../../hooks/useUser';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import checkCpfValidation from '../../../../utils/checkCpfValidation';
import toFormData from '../../../../utils/formData';
import getFileUrlFor from '../../../../utils/getFIleUrlFor';
import documentationSchema from '../../../../validations/documentation';
import { ContainerInput, StyledBox, StyledForm } from './styles';

const StepThree = () => {
  const [expanded, setExpanded] = useState('panel1');
  const [whatsApp, setWhatsApp] = useState('');
  const [documentsInitialValues, setDocumentsInitialValues] = useState({
    cpf: '',
    pisNumber: '',
    rgNumber: '',
    rgExpeditionDate: '',
    rgOrganUfEmitter: '',
    voterRegistrationNumber: '',
    voterRegistrationZone: '',
    voterRegistrationSection: '',
    reservistNumber: '',
  });
  const [selectedPhotoCPF, setSelectedPhotoCPF] = useState(null);
  const [cpfLocation, setCpfLocation] = useState(null);
  const [selectedPhotoRgFront, setSelectedPhotoRgFront] = useState(null);
  const [rgFrontLocation, setRgFrontLocation] = useState(null);
  const [selectedPhotoRgBack, setSelectedPhotoRgBack] = useState(null);
  const [rgBackLocation, setRgBackLocation] = useState(null);
  const [selectedPhotoTitle, setSelectedPhotoTitle] = useState(null);
  const [titleLocation, setTitleLocation] = useState(null);
  const [selectedPhotoReservist, setSelectedPhotoReservist] = useState(null);
  const [reservistLocation, setReservistLocation] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const { palette } = useTheme();
  const { user } = useAuth();
  const { userInfo, user: fullUser } = useUser(user._id);

  useEffect(() => {
    if (fullUser && fullUser.documents) {
      const {
        documents: {
          cpf,
          pisNumber,
          rgNumber = '',
          rgExpeditionDate = '',
          rgOrganUfEmitter = '',
          voterRegistrationNumber = '',
          voterRegistrationZone = '',
          voterRegistrationSection = '',
          reservistNumber = '',
          photoCPF,
          photoBackRg,
          photoFrontRg,
          photoTitle,
          photoReservist,
        },
      } = fullUser;

      if (photoCPF) {
        setCpfLocation(getFileUrlFor(photoCPF.key));
      }

      if (photoBackRg && photoFrontRg) {
        setRgBackLocation(getFileUrlFor(photoBackRg.key));
        setRgFrontLocation(getFileUrlFor(photoFrontRg.key));
      }
      if (photoTitle) {
        setTitleLocation(getFileUrlFor(photoTitle.key));
      }

      if (photoReservist) {
        setReservistLocation(getFileUrlFor(photoReservist.key));
      }

      let rgDate = '';

      if (rgExpeditionDate) {
        const parsedDate = parseISO(rgExpeditionDate);
        rgDate = format(parsedDate, 'dd/MM/yyyy');
      }

      setDocumentsInitialValues({
        cpf,
        pisNumber,
        rgNumber,
        rgExpeditionDate: rgDate,
        rgOrganUfEmitter,
        voterRegistrationNumber,
        voterRegistrationZone,
        voterRegistrationSection,
        reservistNumber,
      });
    }
  }, [fullUser]);

  const handleSubmitDocuments = useCallback(
    async (values, { setSubmitting }) => {
      try {
        if (!selectedPhotoCPF && !cpfLocation) {
          // TODO: Alterar condição de documentos após fazer recurso da empresa escolher documentos obrigatórios
          dispatch(setSnackbar(true, 'error', 'A foto do CPF é obrigatória'));
          return;
        }
        if (!checkCpfValidation(fullUser?.familyData?.dependents, values.cpf)) {
          dispatch(
            setSnackbar(
              true,
              'error',
              'Já existe esse CPF entre os dependentes',
            ),
          );
          return;
        }

        dispatch(setLoading(true));

        const api = AuthenticatedHttpClient();

        const formObject =
          cpfLocation && !selectedPhotoCPF
            ? {
                ...values,
              }
            : {
                ...values,
                photoCPF: selectedPhotoCPF,
              };

        if (values.rgExpeditionDate) {
          const parsedDate = parse(
            values.rgExpeditionDate,
            'dd/MM/yyyy',
            new Date(),
          );
          formObject.rgExpeditionDate = formatISO(parsedDate);
        }

        if (whatsApp) {
          formObject.isWhatsApp = JSON.parse(whatsApp);
        }

        if (selectedPhotoRgFront && selectedPhotoRgBack) {
          formObject.photoFrontRg = selectedPhotoRgFront;
          formObject.photoBackRg = selectedPhotoRgBack;
        } else if (selectedPhotoRgFront && rgBackLocation) {
          formObject.photoFrontRg = selectedPhotoRgFront;
        } else if (selectedPhotoRgBack && rgFrontLocation) {
          formObject.photoBackRg = selectedPhotoRgBack;
        }

        if (selectedPhotoTitle) {
          formObject.photoTitle = selectedPhotoTitle;
        }

        if (selectedPhotoReservist) {
          formObject.photoReservist = selectedPhotoReservist;
        }

        const form = toFormData(formObject);

        await api.put(`users/step/documents/${user._id}`, form);

        dispatch(setSnackbar(true, 'success', 'Dados salvos com sucesso'));
        history.push(`/4`);
      } catch (error) {
        if (error.response.data) {
          const { message } = error.response.data;
          dispatch(setSnackbar(true, 'error', message));
        } else {
          dispatch(setSnackbar(true, 'error', 'Falha ao salvar os dados'));
        }
      } finally {
        dispatch(setLoading(false));
        setSubmitting(false);
      }
    },
    [
      history,
      user._id,
      dispatch,
      selectedPhotoTitle,
      selectedPhotoCPF,
      selectedPhotoReservist,
      whatsApp,
      selectedPhotoRgBack,
      selectedPhotoRgFront,
      cpfLocation,
      rgBackLocation,
      rgFrontLocation,
      fullUser,
    ],
  );

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      width={1}
      overflow="auto"
      p={5}
    >
      {userInfo && <UserInfo userData={userInfo} setValue={setWhatsApp} />}
      <Formik
        initialValues={documentsInitialValues}
        onSubmit={handleSubmitDocuments}
        validationSchema={documentationSchema}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <StyledForm>
            <StyledBox width={1}>
              <CustomAccordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography
                    component="legend"
                    fontWeight={300}
                    fontSize="16px"
                    mb="8px"
                    mt="8px"
                    width="100%"
                  >
                    CPF
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justify="space-between" spacing={5}>
                    <Grid container item lg={4} direction="column">
                      <Typography
                        component="legend"
                        fontWeight={300}
                        fontSize="16px"
                        mb="16px"
                      >
                        Nº CPF
                      </Typography>
                      <ContainerInput item>
                        <Field
                          component={TextField}
                          name="cpf"
                          label="Número do CPF"
                          variant="filled"
                          required
                          fullWidth
                          InputProps={{
                            inputComponent: CPFInput,
                          }}
                        />
                      </ContainerInput>
                    </Grid>

                    <Grid item lg={4}>
                      <Typography
                        component="legend"
                        fontWeight={300}
                        fontSize="16px"
                      >
                        Foto do CPF
                      </Typography>
                      <Uploader
                        title=""
                        defaultImage={cpfLocation || cpfImg}
                        accept="image/*"
                        dropText="Adicionar foto"
                        hasStepPhoto={cpfLocation}
                        size="md"
                        onFileUploaded={setSelectedPhotoCPF}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Typography
                        fontSize="14px"
                        textcolor={palette.primary.main}
                        mt="24px"
                      >
                        Especificações para a foto
                      </Typography>
                      <Typography fontSize="14px" fontWeight={300} mt="8px">
                        - Foto horizontal
                      </Typography>
                      <Typography fontSize="14px" fontWeight={300} mt="8px">
                        - Em boa qualidade
                      </Typography>
                      <Typography fontSize="14px" fontWeight={300} mt="8px">
                        - Dados legíveis
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </CustomAccordion>

              <CustomAccordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography
                    component="legend"
                    fontWeight={300}
                    fontSize="16px"
                    mb="8px"
                    mt="8px"
                    width="100%"
                  >
                    PIS
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justify="space-between" py="16px">
                    <Grid container item lg={4} direction="column">
                      <Typography
                        component="legend"
                        fontWeight={300}
                        fontSize="16px"
                        mb="16px"
                      >
                        Nº PIS
                      </Typography>
                      <Field
                        component={TextField}
                        name="pisNumber"
                        label="Número"
                        variant="filled"
                        fullWidth
                        InputProps={{
                          inputComponent: PISInput,
                        }}
                      />
                    </Grid>
                    <Grid item lg={4} />
                    <Grid item lg={4} />
                  </Grid>
                </AccordionDetails>
              </CustomAccordion>

              <CustomAccordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography
                    component="legend"
                    fontWeight={300}
                    fontSize="16px"
                    mb="8px"
                    mt="8px"
                    width="100%"
                  >
                    RG
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justify="space-between" spacing={5}>
                    <Grid container item lg={4} direction="column">
                      <Typography
                        component="legend"
                        fontWeight={300}
                        fontSize="16px"
                        mb="16px"
                      >
                        Nº RG
                      </Typography>
                      <ContainerInput item>
                        <Field
                          component={TextField}
                          name="rgNumber"
                          label="Número"
                          variant="filled"
                          fullWidth
                          InputProps={{
                            inputComponent: NumericInput,
                          }}
                        />
                      </ContainerInput>
                      <Typography
                        component="legend"
                        fontWeight={300}
                        fontSize="16px"
                      >
                        Foto do RG frente
                      </Typography>
                      <Uploader
                        title=""
                        defaultImage={rgFrontLocation || frontRGImg}
                        accept="image/*"
                        dropText="Adicionar foto"
                        hasStepPhoto={rgFrontLocation}
                        size="md"
                        onFileUploaded={setSelectedPhotoRgFront}
                      />
                    </Grid>
                    <Grid container item lg={4} direction="column">
                      <Typography
                        component="legend"
                        fontWeight={300}
                        fontSize="16px"
                        mb="16px"
                      >
                        Data expedição
                      </Typography>
                      <ContainerInput item>
                        <Field
                          component={TextField}
                          name="rgExpeditionDate"
                          label="Data"
                          variant="filled"
                          fullWidth
                          InputProps={{
                            inputComponent: DateInput,
                          }}
                        />
                      </ContainerInput>
                      <Typography
                        component="legend"
                        fontWeight={300}
                        fontSize="16px"
                      >
                        Foto do RG verso
                      </Typography>
                      <Uploader
                        title=""
                        defaultImage={rgBackLocation || backRGImg}
                        accept="image/*"
                        dropText="Adicionar foto"
                        hasStepPhoto={rgBackLocation}
                        size="md"
                        onFileUploaded={setSelectedPhotoRgBack}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Typography
                        component="legend"
                        fontWeight={300}
                        fontSize="16px"
                        mb="16px"
                      >
                        Órgão e UF emissor
                      </Typography>
                      <ContainerInput item>
                        <Field
                          component={TextField}
                          name="rgOrganUfEmitter"
                          label="Órgão/UF"
                          variant="filled"
                          placeholder="SSP/MG"
                          fullWidth
                        />
                      </ContainerInput>
                      <Typography
                        fontSize="14px"
                        textcolor={palette.primary.main}
                        mt="56px"
                      >
                        Especificações para as fotos
                      </Typography>
                      <Typography fontSize="14px" fontWeight={300} mt="8px">
                        - Foto horizontal
                      </Typography>
                      <Typography fontSize="14px" fontWeight={300} mt="8px">
                        - Em boa qualidade
                      </Typography>
                      <Typography fontSize="14px" fontWeight={300} mt="8px">
                        - Dados legíveis
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </CustomAccordion>

              <CustomAccordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography
                    component="legend"
                    fontWeight={300}
                    fontSize="16px"
                    mb="8px"
                    mt="8px"
                    width="100%"
                  >
                    Título de eleitor
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justify="space-between" spacing={5}>
                    <Grid container item lg={4} direction="column">
                      <Typography
                        component="legend"
                        fontWeight={300}
                        fontSize="16px"
                        mb="16px"
                      >
                        Nº do título
                      </Typography>
                      <ContainerInput item>
                        <Field
                          component={TextField}
                          name="voterRegistrationNumber"
                          label="Número"
                          variant="filled"
                          fullWidth
                          InputProps={{
                            inputComponent: NumericInput,
                          }}
                        />
                      </ContainerInput>
                      <Typography
                        component="legend"
                        fontWeight={300}
                        fontSize="16px"
                      >
                        Foto do título eleitoral
                      </Typography>
                      <Uploader
                        title=""
                        defaultImage={titleLocation || titleImg}
                        accept="image/*"
                        dropText="Adicionar foto"
                        hasStepPhoto={titleLocation}
                        size="md"
                        onFileUploaded={setSelectedPhotoTitle}
                      />
                    </Grid>
                    <Grid container item lg={4} direction="column">
                      <Typography
                        component="legend"
                        fontWeight={300}
                        fontSize="16px"
                        mb="16px"
                      >
                        Zona eleitoral
                      </Typography>
                      <ContainerInput item>
                        <Field
                          component={TextField}
                          name="voterRegistrationZone"
                          label="Zona"
                          variant="filled"
                          fullWidth
                          InputProps={{
                            inputComponent: NumericInput,
                          }}
                        />
                      </ContainerInput>
                      <Typography
                        fontSize="14px"
                        textcolor={palette.primary.main}
                        mt="56px"
                      >
                        Especificações para a foto
                      </Typography>
                      <Typography fontSize="14px" fontWeight={300} mt="8px">
                        - Foto horizontal
                      </Typography>
                      <Typography fontSize="14px" fontWeight={300} mt="8px">
                        - Em boa qualidade
                      </Typography>
                      <Typography fontSize="14px" fontWeight={300} mt="8px">
                        - Dados legíveis
                      </Typography>
                    </Grid>
                    <Grid item lg={4}>
                      <Typography
                        component="legend"
                        fontWeight={300}
                        fontSize="16px"
                        mb="16px"
                      >
                        Seção eleitoral
                      </Typography>
                      <ContainerInput item>
                        <Field
                          component={TextField}
                          name="voterRegistrationSection"
                          label="Seção"
                          variant="filled"
                          fullWidth
                          InputProps={{
                            inputComponent: NumericInput,
                          }}
                        />
                      </ContainerInput>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </CustomAccordion>

              <CustomAccordion
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography
                    component="legend"
                    fontWeight={300}
                    fontSize="16px"
                    mb="8px"
                    mt="8px"
                    width="100%"
                  >
                    Reservista
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justify="space-between" spacing={5}>
                    <Grid container item lg={4} direction="column">
                      <Typography
                        component="legend"
                        fontWeight={300}
                        fontSize="16px"
                        mb="16px"
                      >
                        Nº Reservista
                      </Typography>
                      <ContainerInput item>
                        <Field
                          component={TextField}
                          name="reservistNumber"
                          label="Nº do certificado militar"
                          variant="filled"
                          fullWidth
                          InputProps={{
                            inputComponent: NumericInput,
                          }}
                        />
                      </ContainerInput>
                    </Grid>

                    <Grid item lg={4}>
                      <Typography
                        component="legend"
                        fontWeight={300}
                        fontSize="16px"
                      >
                        Foto da reservista
                      </Typography>
                      <Uploader
                        title=""
                        defaultImage={reservistLocation || reservistImg}
                        accept="image/*"
                        dropText="Adicionar foto"
                        hasStepPhoto={reservistLocation}
                        size="md"
                        onFileUploaded={setSelectedPhotoReservist}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Typography
                        fontSize="14px"
                        textcolor={palette.primary.main}
                        mt="24px"
                      >
                        Especificações para a foto
                      </Typography>
                      <Typography fontSize="14px" fontWeight={300} mt="8px">
                        - Foto horizontal
                      </Typography>
                      <Typography fontSize="14px" fontWeight={300} mt="8px">
                        - Em boa qualidade
                      </Typography>
                      <Typography fontSize="14px" fontWeight={300} mt="8px">
                        - Dados legíveis
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </CustomAccordion>
            </StyledBox>
            <Box width={1} display="flex" justifyContent="flex-end" my={3}>
              <CollaboratorButton disabled={isSubmitting} type="submit" />
            </Box>
          </StyledForm>
        )}
      </Formik>
    </Box>
  );
};

export default withCollaboratorSkeleton(StepThree);
