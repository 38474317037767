import React, { useState } from 'react';
import PropTypes, { string } from 'prop-types';
import { Button, Box } from '@material-ui/core';
import Typography from '../Typography';
import Grid from '../Grid';

import {
  StyledArrowCircle,
  StyledArrowCircleSucess,
  StyledArrowCircleSucessContract,
  StyledDoneIcon,
  StyledButton,
} from './styles';

import DocsIcon from '../../assets/docsIcon.svg';
import ArrowDown from '../../assets/arrow-downDark.svg';

const DownloaderAndSign = ({ file, isContractList }) => {
  function handleDownload() {
    return true;
  }
  const [signContract, setSignContract] = useState(false);

  const { name, size, location } = file;

  return (
    <Box
      display="flex"
      component={Grid}
      container
      bgcolor="third.light"
      alignItems="center"
      justifyContent="space-between"
      width={1}
      height="70px"
      p={2}
    >
      <Grid item lg={7} container direction="row" alignItems="center">
        <Box component={Grid} ml="15px">
          <img src={DocsIcon} alt="" />
        </Box>
        <Box component={Grid} ml="15px">
          <Typography color="primary" fontSize="14px">
            {name}
          </Typography>
          <Typography
            component="span"
            width="100%"
            color="secondary"
            fontSize="9px"
            opacity="0.7"
          >
            {size}
          </Typography>
        </Box>
      </Grid>

      <Grid item lg={5} container direction="row" justify="flex-end">
        <Box display="flex" alignItems="center">
          {isContractList ? (
            <Box>
              <Button size="small" href={location}>
                <StyledArrowCircle>
                  <img src={ArrowDown} alt="" />
                </StyledArrowCircle>
                <Typography ml="10px" fontWeight="bold" fontSize="12px">
                  Download
                </Typography>
              </Button>
            </Box>
          ) : (
            <Box>
              <Button size="small" onClick={handleDownload}>
                <StyledArrowCircle>
                  <img src={ArrowDown} alt="" />
                </StyledArrowCircle>
                <Typography ml="10px" fontWeight="bold" fontSize="12px">
                  Download
                </Typography>
              </Button>
            </Box>
          )}

          <Box component={Grid} ml="15px">
            {signContract === false ? (
              <StyledButton onClick={() => setSignContract(true)}>
                ASSINAR
              </StyledButton>
            ) : (
              <Box display="flex" alignItems="center">
                {isContractList ? (
                  <StyledArrowCircleSucessContract>
                    <StyledDoneIcon opacity={0.5} fontSize="small" />
                  </StyledArrowCircleSucessContract>
                ) : (
                  <StyledArrowCircleSucess>
                    <StyledDoneIcon color="primary" fontSize="small" />
                  </StyledArrowCircleSucess>
                )}

                <Typography
                  color={isContractList ? 'inherit' : 'primary'}
                  opacity={isContractList ? 0.5 : 1}
                  ml="10px"
                  fontWeight="bold"
                  fontSize="12px"
                >
                  DOCUMENTO ASSINADO
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

DownloaderAndSign.propTypes = {
  file: PropTypes.shape({
    name: string,
    size: string,
    location: string,
  }).isRequired,
  isContractList: PropTypes.bool,
};

DownloaderAndSign.defaultProps = {
  isContractList: false,
};

export default DownloaderAndSign;
