import { Button, ButtonBase, Paper } from '@material-ui/core';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const StyledButtonBase = styled(ButtonBase)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({ color, theme }) =>
    color === 'error'
      ? theme.palette.error.main
      : transparentize(0.8, theme.palette.primary.main)};
  margin-right: 16px;
`;

export const UserInfo = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 65px;
    height: 65px;
    border-radius: 2px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
    color: ${({ theme }) => theme.palette.secondary.main};

    strong {
      font-size: 18px;
      font-weight: bold;
    }
    span {
      font-size: 12px;
    }
  }
`;

export const StyledButtonModalOpen = styled(Button)`
  &.MuiButton-root {
    margin: 0px 0px;
    padding: 0px 0px;
    border: none;
  }
`;

export const StyledDivModal = styled.div`
  margin-top: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledDivModalClose = styled.div`
  width: 26%;
  display: flex;
  justify-content: flex-end;

  img {
    width: 40%;
  }
`;

export const StyledImgExpanded = styled.img`
  width: 20%;
`;

export const ImageContainer = styled.div`
  ${({ image }) => css`
    width: 100%;
    position: relative;
    background: url(${image}) 50% 50% no-repeat;
    background-size: cover;
    background-clip: content-box;
  `}

  &::before {
    display: block;
    content: '';
    padding-top: 75%;
  }
`;
export const TabsPaper = styled(Paper)`
  margin-top: 16px;
  margin-left: 10px;
`;

export const TabContainer = styled.div`
  padding: 24px;
`;
