import React from 'react';
import PropTypes from 'prop-types';

import { TabPanel } from '../../../components';
import { TabContainer } from '../styles';
import ContractsList from '../components/ContractsList';

const TerminationDocuments = props => {
  const { value, index, contracts, ...other } = props;

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <ContractsList contracts={contracts} />
      </TabContainer>
    </TabPanel>
  );
};

TerminationDocuments.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  contracts: PropTypes.arrayOf(PropTypes.any).isRequired,
};
export default TerminationDocuments;
