import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { TabPanel, Grid, Downloader } from '../../../components';
import {
  TabContainer,
  ContainerInput,
  SubTitleForm,
  StyledTextField,
} from '../styles';

const BankData = props => {
  const { value, index, data, ...other } = props;
  const [bankData, setBankData] = useState({
    bank: '',
    agency: '',
    typeAccount: '',
    account: '',
    digit: '',
  });

  useEffect(() => {
    if (Object.keys(data).length) {
      setBankData(data);
    }
  }, [data]);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Box
          component={Grid}
          container
          alignItems="center"
          justify="space-between"
          spacing={2}
          p={1}
        >
          <SubTitleForm>Dados do Banco</SubTitleForm>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="bank"
              label="Banco"
              variant="filled"
              value={bankData.bank}
              fullWidth
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="agency"
              label="Agência"
              variant="filled"
              value={bankData.agency}
              fullWidth
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="account"
              label="Conta"
              variant="filled"
              value={bankData.account}
              fullWidth
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="digit"
              label="Digito"
              variant="filled"
              value={bankData.digit}
              fullWidth
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="typeAccount"
              label="Tipo de conta"
              variant="filled"
              value={bankData.typeAccount}
              fullWidth
            />
          </ContainerInput>
          {bankData.photoBankAccountReceipt && (
            <>
              <SubTitleForm>Foto do comprovante bancário</SubTitleForm>
              <ContainerInput item lg={3}>
                <Downloader
                  file={{
                    name: bankData.photoBankAccountReceipt.name,
                    size: bankData.photoBankAccountReceipt.size,
                    key: bankData.photoBankAccountReceipt.key,
                  }}
                />
              </ContainerInput>
            </>
          )}
        </Box>
      </TabContainer>
    </TabPanel>
  );
};

BankData.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

BankData.defaultProps = {
  data: {},
};

export default BankData;
