import {
  ButtonBase,
  Checkbox,
  Grid,
  IconButton,
  Menu,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import eyeIcon from '../../../assets/eye.svg';
import { EmptyState, MassImportModal, Tooltip } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import LimitDateModal from '../../AdmissionColaborator/Tabs/Modals/LimitDateModal';
import { StyledIconButton, StyledMenuItem, StyledTableCell } from './styles';

const headCells = [
  {
    id: 'fullName',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'admissionDate',
    numeric: false,
    disablePadding: false,
    label: 'Data Admissão',
  },
  { id: 'email', numeric: false, disablePadding: false, label: 'E-mail' },
  { id: 'invite', numeric: false, disablePadding: false, label: 'Convite' },
  {
    id: 'limitDate',
    numeric: false,
    disablePadding: false,
    label: 'Data Limite',
  },
  {
    id: 'percentageFilled',
    numeric: false,
    disablePadding: false,
    label: 'Preenchimento',
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Ação</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTable = ({ percentageFilledFilter, textFilter }) => {
  const [dataTable, setDataTable] = useState([]);
  const [isImportModalOpen, setImportModalOpen] = useState(false);
  const [usersCount, setUsersCount] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('fullName');
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageUsersCount, setPageUsersCount] = useState(0);
  const [limitDateModalOpen, setLimitDateModalOpen] = useState(false);
  const [collaboratorId, setCollaboratorId] = useState(null);
  const dispatch = useDispatch();
  const { user } = useAuth();
  const history = useHistory();

  const getUsers = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      setIsLoading(true);
      const api = AuthenticatedHttpClient();
      const response = await api.get(`users/company/${user.company._id}`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          status: 'ADMISSION',
          percentageFilled: percentageFilledFilter.trim(),
          text: textFilter,
          order,
          orderBy,
        },
      });

      const responseData = response.data.docs.map(userInfo => ({
        _id: userInfo._id,
        fullName: userInfo.fullName,
        admissionDate: new Date(userInfo.admissionDate).toLocaleDateString(
          'pt-br',
        ),
        limitDate: new Date(userInfo.limitDate).toLocaleDateString('pt-br'),
        email: userInfo.email,
        percentageFilled: userInfo.percentageFilled,
        isExpired: userInfo.disabled,
      }));
      setUsersCount(response.data.total);
      setPageUsersCount(responseData.length);
      setDataTable(responseData);
    } catch (error) {
      dispatch(
        setSnackbar(true, 'error', 'Não foi possível buscar os colaboradores'),
      );
    } finally {
      dispatch(setLoading(false));
      setIsLoading(false);
    }
  }, [
    user,
    rowsPerPage,
    page,
    order,
    orderBy,
    percentageFilledFilter,
    textFilter,
    dispatch,
  ]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleClickMenu = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleImportModalOpen = useCallback(() => {
    setAnchorEl(null);
    setImportModalOpen(true);
  }, []);

  const handleImportModalClose = useCallback(() => {
    setImportModalOpen(false);
  }, []);

  const handleRemoveUsers = useCallback(async () => {
    try {
      const api = AuthenticatedHttpClient();

      await api.delete('/users', {
        data: { usersId: selected },
      });

      setDataTable(prevState =>
        prevState.filter(userData => !selected.includes(userData._id)),
      );

      dispatch(setSnackbar(true, 'success', 'Operação realizada com sucesso'));
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Falha ao remover usuários'));
    } finally {
      setAnchorEl(null);
    }
  }, [dispatch, selected]);

  const handleRedirect = useCallback(
    userId => {
      history.push(`admissions/collaborator/${userId}`);
    },
    [history],
  );

  const handleLimitDateModalOpen = expiredUser => {
    setLimitDateModalOpen(true);
    setCollaboratorId(expiredUser._id);
  };
  const handleLimitDateModalClose = () => setLimitDateModalOpen(false);

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const handleSelectAllClick = useCallback(
    event => {
      if (event.target.checked) {
        const newSelecteds = dataTable.map(n => n._id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    [dataTable],
  );

  const handleClick = useCallback(
    (_, id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    },
    [selected],
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
    setSelected([]);
  }, []);

  const handleChangeRowsPerPage = useCallback(event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const isSelected = id => selected.indexOf(id) !== -1;

  const MAX_STRING_LENGTH = 35;

  if (isLoading) return '';
  return (
    <>
      {!usersCount ? (
        <EmptyState description="Nenhum colaborador encontrado!" />
      ) : (
        <Paper elevation={0}>
          <Modal open={limitDateModalOpen}>
            <LimitDateModal
              handleClose={handleLimitDateModalClose}
              collaboratorId={collaboratorId}
              getUsers={getUsers}
            />
          </Modal>
          <Grid container alignItems="center" justify="space-between">
            <Typography variant="h3">
              COLABORADORES EM PROCESSO DE ADMISSÃO
            </Typography>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClickMenu}
            >
              <Settings />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <StyledMenuItem onClick={handleRemoveUsers}>
                EXCLUIR SELECIONADOS
              </StyledMenuItem>
              <StyledMenuItem onClick={handleImportModalOpen}>
                IMPORTAR EM MASSA
              </StyledMenuItem>
            </Menu>
          </Grid>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={pageUsersCount}
              />
              <TableBody>
                {dataTable.map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          onClick={event => handleClick(event, row._id)}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" id={labelId} scope="row">
                        <ButtonBase onClick={() => handleRedirect(row._id)}>
                          <Tooltip
                            fullText={row.fullName}
                            maxStringLength={MAX_STRING_LENGTH}
                          />
                        </ButtonBase>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.admissionDate}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Tooltip
                          fullText={row.email}
                          maxStringLength={MAX_STRING_LENGTH}
                        />
                      </StyledTableCell>
                      {row.isExpired ? (
                        <StyledTableCell align="left" $expired>
                          <ButtonBase
                            onClick={() => handleLimitDateModalOpen(row)}
                          >
                            <u>Expirado</u>
                          </ButtonBase>
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          align="left"
                          opacity={row.percentageFilled === 0}
                        >
                          {row.percentageFilled === 0 ? 'Enviado' : 'Aberto'}
                        </StyledTableCell>
                      )}

                      <StyledTableCell align="left">
                        {row.limitDate}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        opacity={row.percentageFilled === 0}
                        filled={row.percentageFilled === 100}
                      >
                        {row.percentageFilled === 100 ? (
                          <b>{row.percentageFilled}%</b>
                        ) : (
                          `${row.percentageFilled}%`
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <StyledIconButton
                          onClick={() => handleRedirect(row._id)}
                        >
                          <img src={eyeIcon} alt="" />
                        </StyledIconButton>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={usersCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      <Modal open={isImportModalOpen}>
        <MassImportModal
          handleClose={handleImportModalClose}
          route="/users/upload-many"
          importType="usuários"
          modelLocation="https://siricascudo-files.s3.amazonaws.com/example-sheets/Planilha_Modelo_Importacao_em_Lote.xlsx"
        />
      </Modal>
    </>
  );
};

EnhancedTable.propTypes = {
  percentageFilledFilter: PropTypes.string.isRequired,
  textFilter: PropTypes.string.isRequired,
};

export default EnhancedTable;
