import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import { LoadingContainer, Backdrop } from './styles';

const Loading = () => {
  const loadingOpen = useSelector(state => state.loading.loadingOpen);
  const size = useSelector(state => state.loading.size);
  const color = useSelector(state => state.loading.color);

  return (
    <LoadingContainer>
      {loadingOpen && <CircularProgress color={color} size={size} />}
      {loadingOpen && <Backdrop />}
    </LoadingContainer>
  );
};

export default Loading;
