import {
  Box,
  DialogContent,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Clock from '../../../assets/clock-overtime.svg';
import { EmptyState } from '../../../components';
import formatTimeString from '../../../utils/formatTimeString';
import CompTimeModal from '../CompTimeModals';
import { StyledIconButtonBlue, StyledTableCell } from './styles';

const headCells = [
  {
    id: 'fullName',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'minutesWorkedByPeriod',
    numeric: false,
    disablePadding: false,
    label: 'Horas trabalhadas',
  },
  {
    id: 'plannedMinutesByPeriod',
    numeric: false,
    disablePadding: false,
    label: 'Horas planejadas',
  },

  {
    id: 'compTimeMinutesOnPeriod',
    numeric: false,
    disablePadding: false,
    label: 'Banco de Horas Período',
  },
  {
    id: 'allTimeCompTime',
    numeric: false,
    disablePadding: false,
    label: 'Banco de Horas Total',
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Ação</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTable = ({
  collaboratorsCompTime,
  setFilters,
  getCollaboratorsCompTime,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('fullName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [compTimeSelected, setCompTimeSelected] = useState({});

  const formattedCompTimes = collaboratorsCompTime.docs.map(record => {
    return {
      ...record,
      formattedMinutesWorkedByPeriod: formatTimeString(
        record.minutesWorkedByPeriod,
      ),
      formattedPlannedMinutesByPeriod: formatTimeString(
        record.plannedMinutesByPeriod,
      ),
      formattedCompTimeMinutesOnPeriod: formatTimeString(
        record.compTimeMinutesOnPeriod,
      ),
      formattedAllTimeCompTime: formatTimeString(record.allTimeCompTime),
    };
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilters(oldState => ({
      ...oldState,
      page: newPage + 1,
    }));
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilters(oldState => ({
      ...oldState,
      limit: parseInt(event.target.value, 10),
      page: 1,
    }));
  };

  const handleOpenCompTimeModal = compTime => {
    setCompTimeSelected(compTime);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return !formattedCompTimes.length ? (
    <EmptyState description="Nenhum colaborador encontrado!" />
  ) : (
    <Box component={Paper} elevation={0} overflow="visible" mt={2}>
      <Modal open={isModalOpen} onClose={closeModal}>
        <DialogContent>
          <CompTimeModal
            compTimeSelected={compTimeSelected}
            handleClose={closeModal}
            getCollaboratorsCompTime={getCollaboratorsCompTime}
          />
        </DialogContent>
      </Modal>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={collaboratorsCompTime.total}
          />
          <TableBody>
            {formattedCompTimes.map(row => {
              return (
                <TableRow hover tabIndex={-1} key={row._id}>
                  <StyledTableCell component="th" scope="row">
                    {row.fullName}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.formattedMinutesWorkedByPeriod}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.formattedPlannedMinutesByPeriod}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    hasAlerts={row.compTimeMinutesOnPeriod < 0}
                  >
                    {row.formattedCompTimeMinutesOnPeriod}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    hasAlerts={row.allTimeCompTime < 0}
                  >
                    {row.formattedAllTimeCompTime}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <StyledIconButtonBlue
                      onClick={() => handleOpenCompTimeModal(row)}
                    >
                      <img src={Clock} alt="" />
                    </StyledIconButtonBlue>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={collaboratorsCompTime.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
};

EnhancedTable.propTypes = {
  setFilters: PropTypes.func.isRequired,
  getCollaboratorsCompTime: PropTypes.func.isRequired,
  collaboratorsCompTime: PropTypes.shape({
    docs: PropTypes.arrayOf(PropTypes.any),
    limit: PropTypes.number,
    page: PropTypes.number,
    pages: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
};

export default EnhancedTable;
