import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import StartResignation from '../../../../assets/StartResignation.svg';
import { Grid, ModalSkeleton, Typography } from '../../../../components';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import { Numbers, StyledImg } from './styles';

const StartResignationModal = ({ handleClickOnClose }) => {
  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();
  const history = useHistory();

  const handleSubmitResignation = useCallback(async () => {
    try {
      const api = AuthenticatedHttpClient();

      await api.put(`users/dismissal/${collaboratorId}`);

      dispatch(setSnackbar(true, 'success', 'Processo de demissão iniciado'));

      history.replace('/home/dismissal');
    } catch (error) {
      dispatch(
        setSnackbar(
          true,
          'error',
          'Não foi possível iniciar a demissão do colaborador',
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  }, [collaboratorId, dispatch, history]);

  return (
    <ModalSkeleton
      title="Iniciar Processo de Demissão"
      handleClickOnClose={handleClickOnClose}
    >
      <Box m={3} pt={5}>
        <Grid container item lg={12} justify="flex-end" alignItems="center">
          <Grid item lg={6}>
            <StyledImg src={StartResignation} alt="" />
          </Grid>
          <Grid item lg={6}>
            <Typography variant="h3" fontWeight="bold" mb="30px">
              FIQUE ATENTO AO INICIAR O PROCESSO DE DEMISSÃO!
            </Typography>
            <Box display="flex" mb={3}>
              <Box>
                <Numbers>
                  <Typography textalign="center" fontSize="13px">
                    1
                  </Typography>
                </Numbers>
              </Box>
              <Box>
                <Typography opacity="0.5" ml="16px">
                  O funcionário fica alheio ao processo, ou seja, o acesso será
                  bloqueado assim que finalizar o processo de demissão.
                </Typography>
              </Box>
            </Box>
            <Box display="flex" mt={8} mb={3}>
              <Box>
                <Numbers>
                  <Typography textalign="center" fontSize="13px">
                    2
                  </Typography>
                </Numbers>
              </Box>
              <Box>
                <Typography opacity="0.5" ml="16px">
                  Apresentaremos um checkup contendo todas as informações para
                  evitar o desligamento indevido ao qual pode ser gerado multas
                  de rescisão.
                </Typography>
              </Box>
            </Box>
            <Box display="flex" mt={8} mb={3}>
              <Box>
                <Numbers>
                  <Typography textalign="center" fontSize="13px">
                    3
                  </Typography>
                </Numbers>
              </Box>
              <Box>
                <Typography opacity="0.5" ml="16px">
                  Antes de iniciar confira todas as informações necessárias e
                  documentos precisos para não se perder durante o processo como
                  também não gerar nenhum problema.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Box mr={4} mt={5}>
            <Button
              type="buton"
              color="secondary"
              onClick={handleClickOnClose}
              size="small"
            >
              <Typography fontSize="0.9375rem" fontWeight="bold" opacity="0.7">
                CANCELAR
              </Typography>
            </Button>
          </Box>
          <Box mt={5}>
            <Button
              color="secondary"
              onClick={handleSubmitResignation}
              size="small"
            >
              <Typography fontSize="0.9375rem" fontWeight="bold">
                CONCORDO EM INICIAR O PROCESSO DE DEMISSÃO
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Box>
    </ModalSkeleton>
  );
};

StartResignationModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
};

export default StartResignationModal;
