import {
  Box,
  ButtonBase,
  Grid,
  IconButton,
  Tab,
  Tabs,
} from '@material-ui/core';
import { CheckboxWithLabel } from 'formik-material-ui';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const ContainerInput = styled(Grid)`
  height: 80px;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : 0)};

  input {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const StyledButtonBase = styled(ButtonBase)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({ color, theme }) =>
    color === 'error'
      ? theme.palette.error.main
      : transparentize(0.8, theme.palette.primary.main)};
  margin-right: 16px;
`;

export const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid #e5e5e5;
`;

export const StyledTab = styled(Tab)`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const StyledBorderBox = styled(Box)`
  border-style: dashed;
  padding: 20px;
`;

export const StyledIconButton = styled(IconButton)`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 16px;
  transition: opacity 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.8;
  }

  svg {
    color: #fff;
    width: 35px;
    height: 35px;
  }
`;

export const StyledCheckbox = styled(CheckboxWithLabel)`
  & + span {
    font-size: 0.875rem;
    color: ${({ theme }) => transparentize(0.2, theme.palette.secondary.main)};
  }
`;

export const StyledCheckboxWithOpacity = styled(CheckboxWithLabel)`
  & + span {
    font-size: 0.875rem;
    color: ${({ theme }) => transparentize(0.2, theme.palette.secondary.main)};
    opacity: ${({ opacity }) => opacity};
  }
`;

export const DayOffContainer = styled(Grid)`
  max-width: 690px;
  height: 80px;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : 0)};

  input {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
