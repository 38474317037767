/* eslint-disable react/jsx-curly-newline */
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  TextField as TextFieldMaterial,
  useTheme,
} from '@material-ui/core';
import axios from 'axios';
import cep from 'cep-promise';
import { Field, Formik } from 'formik';
import { RadioGroup, TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProofOfResidence from '../../../../assets/proof-of-residence.svg';
import {
  CEPInput,
  CollaboratorButton,
  CPFInput,
  Grid,
  NumericInput,
  Typography,
  Uploader,
  UserInfo,
} from '../../../../components';
import withCollaboratorSkeleton from '../../../../components/CollaboratorSkeleton';
import { useAuth } from '../../../../hooks/auth';
import useUser from '../../../../hooks/userAdmission';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import toFormData from '../../../../utils/formData';
import getFileUrlFor from '../../../../utils/getFIleUrlFor';
import addressSchema from '../../../../validations/address';
import { ContainerInput, StyledForm } from './styles';

const StepFour = () => {
  const [initialValues, setInitialValues] = useState({
    cep: '',
    state: null,
    city: null,
    publicPlace: '',
    number: '',
    complement: '',
    neighborhood: '',
    type: '',
    proofOfAddress: '',
    cpfOwner: '',
    rgOwner: '',
  });
  const [whatsApp, setWhatsApp] = useState('');
  const [userCep, setUserCep] = useState('');
  const [selectedProofOfResidence, setSelectedProofOfResidence] = useState(
    null,
  );
  const [ufs, setUfs] = useState([]);
  const [cities, setCities] = useState([]);
  const [ufSelected, setUfSelected] = useState('');
  const [fileLocation, setFileLocation] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { user } = useAuth();
  const { userInfo, userStep } = useUser('address');

  useEffect(() => {
    async function getUserData() {
      try {
        const response = await axios.get(
          'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
        );

        const sortedOptions = response.data.sort((stateA, stateB) =>
          stateA.sigla > stateB.sigla ? 1 : -1,
        );

        const ufInitials = sortedOptions.map(uf => ({
          label: uf.nome,
          value: uf.sigla,
        }));
        setUfs(ufInitials);

        if (userStep) {
          const {
            state,
            city,
            publicPlace,
            number,
            complement,
            neighborhood,
            type,
            proofOfAddress,
            cpfOwner,
            rgOwner,
            receipt,
          } = userStep;

          if (receipt) {
            setFileLocation(getFileUrlFor(receipt.key));
          }

          const userState = ufInitials.find(uf => uf.value === state);

          try {
            const citiesResponse = await axios.get(
              `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${userStep.state}/municipios`,
            );
            const citiesOptions = citiesResponse.data.map(
              cityOption => cityOption.nome,
            );
            setCities(citiesOptions);
          } catch (error) {
            dispatch(setSnackbar(true, 'error', 'Falha ao carregar cidades'));
          }

          setInitialValues({
            cep: userStep.cep,
            state: userState,
            city,
            publicPlace,
            number,
            complement,
            neighborhood,
            type,
            proofOfAddress: String(proofOfAddress),
            cpfOwner: cpfOwner || '',
            rgOwner: rgOwner || '',
          });
        }
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao buscar informações'));
      }
    }

    getUserData();
  }, [userStep, dispatch]);

  useEffect(() => {
    if (!ufSelected) return;
    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufSelected}/municipios`,
      )
      .then(response => {
        const citiesOptions = response.data.map(city => city.nome);
        setCities(citiesOptions);
      })
      .catch(() => {
        dispatch(setSnackbar(true, 'error', 'Falha ao carregar cidades'));
      });
  }, [ufSelected, dispatch]);

  useEffect(() => {
    if (userCep.length < 8) return;
    async function getAddressByCep() {
      try {
        const response = await cep(userCep);

        const state = ufs.find(uf => uf.value === response.state);

        setUfSelected(response.state);

        setInitialValues(oldState => ({
          ...oldState,
          cep: response.cep,
          city: response.city,
          neighborhood: response.neighborhood,
          state,
          publicPlace: response.street,
        }));
      } catch (err) {
        dispatch(setSnackbar(true, 'error', 'CEP inválido ou não encontrado'));
      }
    }
    getAddressByCep();
  }, [userCep, dispatch, ufs]);

  const handleSubmitAddress = useCallback(
    async (values, { setSubmitting }) => {
      try {
        if (!selectedProofOfResidence && !fileLocation) {
          dispatch(
            setSnackbar(true, 'error', 'Comprovante de residência obrigatório'),
          );
          return;
        }
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        const formObject =
          fileLocation && !selectedProofOfResidence
            ? {
                ...values,
                state: values.state.value,
                proofOfAddress: JSON.parse(values.proofOfAddress),
              }
            : {
                ...values,
                state: values.state.value,
                proofOfAddress: JSON.parse(values.proofOfAddress),
                receipt: selectedProofOfResidence,
              };

        if (whatsApp) {
          formObject.isWhatsApp = JSON.parse(whatsApp);
        }

        const form = toFormData(formObject);

        await api.put(`users/step/address/${user._id}`, form);

        dispatch(setSnackbar(true, 'success', 'Dados salvos com sucesso'));
        history.push(`/5`);
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao salvar os dados'));
      } finally {
        dispatch(setLoading(false));
        setSubmitting(false);
      }
    },
    [history, user, selectedProofOfResidence, dispatch, whatsApp, fileLocation],
  );

  const handleChangeState = useCallback((fieldValue, setField) => {
    if (fieldValue) {
      setField('state', fieldValue);
      setField('city', null);
      setUfSelected(fieldValue.value);
    } else {
      setField('state', null);
      setField('city', null);
      setUfSelected('');
      setCities([]);
    }
  }, []);

  const handleChangeCep = useCallback((event, setField) => {
    setField('cep', event.target.value);
    setUserCep(event.target.value);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      width={1}
      overflow="auto"
      p={5}
    >
      {userInfo && <UserInfo userData={userInfo} setValue={setWhatsApp} />}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitAddress}
        validationSchema={addressSchema}
        enableReinitialize
      >
        {({ isSubmitting, errors, setFieldValue, touched, values }) => (
          <StyledForm>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              jusify="space-between"
            >
              <Grid container item lg={8} spacing={2}>
                <Typography
                  component="legend"
                  fontWeight={300}
                  fontSize="18px"
                  mb="20px"
                  mt="8px"
                  width="100%"
                >
                  Informe os dados do seu endereço
                </Typography>
                <ContainerInput item lg={6}>
                  <Field
                    component={TextField}
                    name="cep"
                    label="CEP"
                    variant="filled"
                    fullWidth
                    required
                    InputProps={{
                      inputComponent: CEPInput,
                      onChange: e => handleChangeCep(e, setFieldValue),
                    }}
                  />
                </ContainerInput>
                <ContainerInput item lg={6} />
                <ContainerInput item lg={6}>
                  <Field
                    name="state"
                    component={Autocomplete}
                    options={ufs}
                    getOptionLabel={option => option.label}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    fullWidth
                    renderInput={params => (
                      <TextFieldMaterial
                        {...params}
                        error={touched.state && !!errors.state}
                        helperText={touched.state && errors.state}
                        label="Estado"
                        variant="filled"
                        required
                        disabled={isSubmitting}
                      />
                    )}
                    onChange={(e, fieldValue) =>
                      handleChangeState(fieldValue, setFieldValue)
                    }
                  />
                </ContainerInput>
                <ContainerInput item lg={6}>
                  <Field
                    name="city"
                    component={Autocomplete}
                    options={cities}
                    fullWidth
                    renderInput={params => (
                      <TextFieldMaterial
                        {...params}
                        error={touched.city && !!errors.city}
                        helperText={touched.city && errors.city}
                        label="Cidade"
                        variant="filled"
                        required
                        disabled={isSubmitting}
                      />
                    )}
                  />
                </ContainerInput>
                <ContainerInput item lg={12}>
                  <Field
                    component={TextField}
                    name="publicPlace"
                    label="Logradouro"
                    variant="filled"
                    required
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={6}>
                  <Field
                    component={TextField}
                    name="number"
                    label="Número"
                    variant="filled"
                    fullWidth
                    required
                    InputProps={{
                      inputComponent: NumericInput,
                    }}
                  />
                </ContainerInput>
                <ContainerInput item lg={6}>
                  <Field
                    component={TextField}
                    name="complement"
                    label="Complemento"
                    variant="filled"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={6}>
                  <Field
                    component={TextField}
                    name="neighborhood"
                    label="Bairro"
                    variant="filled"
                    required
                    fullWidth
                  />
                </ContainerInput>
                {/* <ContainerInput item lg={6}>
                  <Field
                    component={TextField}
                    select
                    name="type"
                    variant="filled"
                    label="Tipo"
                    required
                    fullWidth
                  >
                    <MenuItem value="">Tipo</MenuItem>
                    <MenuItem value="Alamenda">Alamenda</MenuItem>
                    <MenuItem value="Avenida">Avenida</MenuItem>
                    <MenuItem value="Chácara">Chácara</MenuItem>
                    <MenuItem value="Condomínio">Condomínio</MenuItem>
                    <MenuItem value="Conjunto">Conjunto</MenuItem>
                    <MenuItem value="Distrito">Distrito</MenuItem>
                    <MenuItem value="Estrada">Estrada</MenuItem>
                    <MenuItem value="Rodovia">Rodovia</MenuItem>
                    <MenuItem value="Rua">Rua</MenuItem>
                  </Field>
                </ContainerInput> */}
                <ContainerInput item lg={12}>
                  <FormControl
                    error={touched.proofOfAddress && !!errors.proofOfAddress}
                    required
                  >
                    <FormLabel style={{ fontSize: '14px' }}>
                      O Comprovante de Residência está no seu nome?
                    </FormLabel>
                    <Field component={RadioGroup} name="proofOfAddress" row>
                      <FormControlLabel
                        value="true"
                        control={<Radio disabled={isSubmitting} />}
                        label="SIM"
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio disabled={isSubmitting} />}
                        label="NÃO"
                        disabled={isSubmitting}
                      />
                    </Field>
                    <FormHelperText>
                      {touched.proofOfAddress && errors.proofOfAddress}
                    </FormHelperText>
                  </FormControl>
                </ContainerInput>
                {values.proofOfAddress === 'false' && (
                  <>
                    <ContainerInput item lg={6}>
                      <Field
                        component={TextField}
                        name="cpfOwner"
                        label="CPF do proprietário"
                        variant="filled"
                        fullWidth
                        InputProps={{
                          inputComponent: CPFInput,
                        }}
                      />
                    </ContainerInput>
                    <ContainerInput item lg={6}>
                      <Field
                        component={TextField}
                        name="rgOwner"
                        label="RG do proprietário"
                        variant="filled"
                        fullWidth
                        InputProps={{
                          inputComponent: NumericInput,
                        }}
                      />
                    </ContainerInput>
                  </>
                )}
              </Grid>

              <Grid container item lg={4} direction="column">
                <Typography component="legend" fontWeight={300} fontSize="18px">
                  Comprovante de endereço
                </Typography>
                <Uploader
                  title=""
                  defaultImage={fileLocation || ProofOfResidence}
                  accept="image/*"
                  dropText="Adicionar foto"
                  hasStepPhoto={fileLocation}
                  size="lg"
                  onFileUploaded={setSelectedProofOfResidence}
                />
                <Typography
                  fontSize="14px"
                  textcolor={palette.primary.main}
                  mt="24px"
                >
                  Especificações para a foto
                </Typography>
                <Typography fontSize="14px" fontWeight={300} mt="8px">
                  - Foto vertical
                </Typography>
                <Typography fontSize="14px" fontWeight={300} mt="8px">
                  - Em boa qualidade
                </Typography>
                <Typography fontSize="14px" fontWeight={300} mt="8px">
                  - Dados legíveis
                </Typography>
              </Grid>
            </Grid>

            <Box width={1} display="flex" justifyContent="flex-end" my={3}>
              <CollaboratorButton disabled={isSubmitting} type="submit" />
            </Box>
          </StyledForm>
        )}
      </Formik>
    </Box>
  );
};

export default withCollaboratorSkeleton(StepFour);
