export default {
  palette: {
    primary: {
      main: '#40A6FF',
    },
    secondary: {
      light: 'rgba(5, 32, 42, 0.5)',
      main: '#05202A',
    },
    third: {
      light: '#FBFBFB',
      main: '#00000029',
      dark: '#05202A',
    },
    error: {
      main: '#FE7676',
      dark: '#F27171',
    },
  },
  typography: {
    fontFamily: 'Averta, sans-serif',
    h1: {
      fontSize: '3.5rem',
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 700,
      color: '#05202A',
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 700,
    },
  },
};
