import styled, { css } from 'styled-components';

export const StyledSpan = styled.span`
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};

  ${({ error }) =>
    error &&
    css`
      color: #ff6b6b;
    `}
`;
