import styled, { css } from 'styled-components';
import { LinearProgress } from '@material-ui/core';
import { TextField } from 'formik-material-ui';

export const StyledTextField = styled(TextField)`
  height: 80px;

  ${({ mt }) => css`
    margin-top: ${mt};
  `}

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    background-color: #1e3037;
    border-radius: 0;
    transition: background-color 5000s ease-in-out 0s;
  }

  input {
    color: white;
    background-color: #1e3037;
  }

  .MuiInputLabel-root {
    color: white;
  }

  ${({ theme }) => css`
    & .Mui-error {
      color: ${theme.palette.error.main};
    }
    & .Mui-focused {
      color: ${theme.palette.primary.main};
    }
  `}
`;

export const StyledSpan = styled.span`
  display: flex;
  align-items: center;

  & + span {
    margin-top: 24px;
  }

  > img {
    margin-right: 24px;
  }
`;

export const BorderLinearProgress = styled(LinearProgress)`
  height: 10px;
  border-radius: 5px;

  ${({ $barColor }) =>
    $barColor &&
    css`
      > .MuiLinearProgress-bar {
        border-radius: 5px;
        background-color: ${$barColor};
      }
    `}
`;
