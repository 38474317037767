/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, TextField as TextFieldMaterial } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import { TextField } from 'formik-material-ui';
import bankOptions from '../../../../node_modules/bancos-brasileiros/bancos.json';

import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import bankDataSchema from '../../../validations/bankData';

import { TabPanel, Grid, Downloader } from '../../../components';
import { TabContainer, ContainerInput, SubTitleForm } from '../styles';

const BankData = props => {
  const { value, index, data, setUser, ...other } = props;
  const [bankData, setBankData] = useState({
    bank: null,
    agency: '',
    typeAccount: '',
    account: '',
    digit: '',
  });

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();

  useEffect(() => {
    if (Object.keys(data).length) {
      const { bank, agency, typeAccount, account, digit } = data;

      const userBank = bankOptions.find(bankObject => bankObject.Name === bank);

      setBankData({
        bank: userBank,
        agency,
        typeAccount,
        account,
        digit,
      });
    }
  }, [data]);

  const handleSubmitBankData = useCallback(
    async (values, { setSubmitting }) => {
      try {
        dispatch(setLoading(true));

        const bankDataUpdated = {
          ...values,
          bank: values.bank.Name,
        };

        const api = AuthenticatedHttpClient();
        const response = await api.put(`users/${collaboratorId}`, {
          bankData: bankDataUpdated,
        });

        setUser(response.data);
        dispatch(setSnackbar(true, 'success', 'Dados alterados'));
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possível alterar os dados'),
        );
      } finally {
        setSubmitting(false);
        dispatch(setLoading(false));
      }
    },
    [dispatch, collaboratorId, setUser],
  );

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Formik
          initialValues={bankData}
          onSubmit={handleSubmitBankData}
          validationSchema={bankDataSchema}
          enableReinitialize
        >
          {({ isSubmitting, touched, errors }) => (
            <Form>
              <Box
                component={Grid}
                container
                alignItems="center"
                justify="space-between"
                spacing={2}
                p={1}
              >
                <SubTitleForm>Dados do Banco</SubTitleForm>
                <ContainerInput item lg={3}>
                  <Field
                    name="bank"
                    component={Autocomplete}
                    options={bankOptions}
                    getOptionLabel={option => `${option.Code} - ${option.Name}`}
                    getOptionSelected={(option, fieldValue) =>
                      option.Code === fieldValue.Code
                    }
                    fullWidth
                    renderInput={params => (
                      <TextFieldMaterial
                        {...params}
                        error={touched.bank && !!errors.bank}
                        helperText={touched.bank && errors.bank}
                        label="Banco"
                        variant="filled"
                        disabled={isSubmitting}
                      />
                    )}
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="agency"
                    label="Agência"
                    variant="filled"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="account"
                    label="Conta"
                    variant="filled"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="digit"
                    label="Digito"
                    variant="filled"
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="typeAccount"
                    label="Tipo de conta"
                    variant="filled"
                    fullWidth
                  />
                </ContainerInput>
                {data.photoBankAccountReceipt && (
                  <>
                    <SubTitleForm>Foto do comprovante bancário</SubTitleForm>
                    <ContainerInput item lg={3}>
                      <Downloader
                        file={{
                          name: data.photoBankAccountReceipt.name,
                          size: data.photoBankAccountReceipt.size,
                          key: data.photoBankAccountReceipt.key,
                        }}
                      />
                    </ContainerInput>
                  </>
                )}
              </Box>
              <Box width={1} display="flex" justifyContent="flex-end" my={2}>
                <Button type="submit" color="secondary" disabled={isSubmitting}>
                  ALTERAR DADOS
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </TabContainer>
    </TabPanel>
  );
};

BankData.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
};

BankData.defaultProps = {
  data: {},
};

export default BankData;
