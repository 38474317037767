/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Grid, Button } from '@material-ui/core';

import { useAuth } from '../../hooks/auth';
import { AuthenticatedHttpClient } from '../../services/api';
import { setLoading } from '../../redux/ducks/loading';
import { setSnackbar } from '../../redux/ducks/snackbar';

import FileDoc from '../../assets/file-doc.svg';
import FileDocOpacity from '../../assets/file-doc-opacity.svg';
import Checked from '../../assets/approved.svg';

import Typography from '../Typography';
import { StyledInput, StyledButton } from './styles';

const UploaderExam = ({ setFile, urlPath, file, fieldName }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const handleFile = useCallback(
    async event => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        const form = new FormData();
        form.append(fieldName, event.target.files[0]);

        const { data } = await api.put(`${urlPath}/${user._id}`, form);

        const { admissionExam } = data;

        setFile(admissionExam);
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao enviar arquivo'));
      } finally {
        dispatch(setLoading(false));
      }
    },
    [setFile, dispatch, user, urlPath, fieldName],
  );

  const handleFileOff = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const api = AuthenticatedHttpClient();

      await api.delete(`${urlPath}/${user._id}`);

      dispatch(setSnackbar(true, 'success', 'Arquivo removido'));
      setFile(null);
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Falha ao remover arquivo'));
    } finally {
      dispatch(setLoading(false));
    }
  }, [setFile, urlPath, user, dispatch]);

  return (
    <Box
      display="flex"
      component={Grid}
      container
      bgcolor="third.light"
      alignItems="center"
      justifyContent="space-between"
      width={1}
      height="70px"
      p={2}
    >
      <Grid item xs={9}>
        <Grid item xs={9}>
          {!file ? (
            <Box display="flex" alignItems="center">
              <img src={FileDocOpacity} alt="" />
              <Typography
                ml="5px"
                fontSize="0.875rem"
                opacity="0.5"
                color="secondary"
              >
                Nenhum arquivo anexado
              </Typography>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <img src={FileDoc} alt="" />
              <Box display="flex" alignItems="center">
                <Typography ml="5px" fontSize="0.875rem" color="primary">
                  {file.name}
                </Typography>
                <Typography
                  ml="0.875rem"
                  fontSize="0.875rem"
                  color="secondary"
                  decoration="underline"
                >
                  <Button onClick={handleFileOff} color="secondary">
                    REMOVER
                  </Button>
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      <Box
        component={Grid}
        item
        xs={3}
        display="flex"
        justifyContent="flex-end"
      >
        {!file ? (
          <>
            <StyledInput
              accept="*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleFile}
            />
            <label htmlFor="contained-button-file">
              <StyledButton
                variant="contained"
                color="primary"
                component="span"
              >
                ANEXAR RESULTADO
              </StyledButton>
            </label>
          </>
        ) : (
          <Box display="flex" alignItems="center">
            <img src={Checked} alt="" />
            <Typography
              ml="8px"
              fontSize="10px"
              fontWeight="bold"
              color="primary"
            >
              RESULTADO ANEXADO
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

UploaderExam.propTypes = {
  setFile: PropTypes.func.isRequired,
  urlPath: PropTypes.string.isRequired,
  file: PropTypes.shape({
    location: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.string,
  }),
  fieldName: PropTypes.string.isRequired,
};

UploaderExam.defaultProps = {
  file: null,
};

export default UploaderExam;
