const SET_LOADING = 'pessoalize-web/loading/SET_LOADING';

const initialState = {
  loadingOpen: false,
  color: 'primary',
  size: 70,
};

export default (state = initialState, action) => {
  const { loadingOpen } = action;
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loadingOpen,
      };
    default:
      return state;
  }
};

export const setLoading = (loadingOpen, color = 'primary', size = 100) => ({
  type: SET_LOADING,
  loadingOpen,
  color,
  size,
});
