import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import emptyState from '../../assets/emptyStates.svg';
import Typography from '../Typography';
import { ImageStyledGrid } from './styles';

const EmptyState = ({ description }) => {
  return (
    <ImageStyledGrid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <img src={emptyState} alt="empty-state" />
      </Grid>
      <Grid item>
        <Typography fontSize="14px" opacity="0.6" mb="20px">
          {description}
        </Typography>
      </Grid>
    </ImageStyledGrid>
  );
};

EmptyState.propTypes = {
  description: PropTypes.string.isRequired,
};

export default EmptyState;
