import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Paper, Typography, Button, Grid } from '@material-ui/core';
import { AddSharp } from '@material-ui/icons';

import { SearchInput, SelectFilter } from '../../components';
import TableAdmission from './TableAdmission';

import {
  Container,
  StyledTabs,
  StyledTab,
  AdmissionContainer,
  SelectDiv,
  StyledIconButton,
  StyledLink,
} from './styles';

const fillOptions = [
  {
    label: 'PREENCHIMENTO',
    value: ' ',
  },
  {
    label: '20%',
    value: '20',
  },
  {
    label: '40%',
    value: '40',
  },
  {
    label: '60%',
    value: '60',
  },
  {
    label: '80%',
    value: '80',
  },
  {
    label: '100%',
    value: '100',
  },
];

const Admission = () => {
  const { path } = useRouteMatch();
  const [searchText, setSearchText] = useState('');
  const [percentageFilled, setPercentageFilled] = useState(' ');

  return (
    <Container>
      <Typography variant="h2">Admissões</Typography>
      <Paper elevation={1}>
        <StyledTabs value={1} indicatorColor="primary">
          <StyledTab label="COLABORADORES" value={1} />
        </StyledTabs>
        <AdmissionContainer>
          <Grid container alignItems="center" justify="space-between">
            <SearchInput value={searchText} handleInput={setSearchText} />
            <div>
              <StyledLink to={`${path}/new-colaborator`}>
                <StyledIconButton>
                  <AddSharp />
                </StyledIconButton>
                <Button color="primary">ADICIONAR COLABORADOR</Button>
              </StyledLink>
            </div>
          </Grid>
          <SelectDiv>
            <SelectFilter
              selectOptions={fillOptions}
              value={percentageFilled}
              handleChange={setPercentageFilled}
            />
          </SelectDiv>
          <TableAdmission
            percentageFilledFilter={percentageFilled}
            textFilter={searchText}
          />
        </AdmissionContainer>
      </Paper>
    </Container>
  );
};

export default Admission;
