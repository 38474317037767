import { Button, MenuItem } from '@material-ui/core';
import { startOfDay } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, ModalSkeleton } from '../../../components';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { COMP_TIME_HISTORY_TYPES } from '../../../utils/enums';
import getMinutesFromStringTime from '../../../utils/getMinutesFromStringTime';
import compTimeSchema from '../../../validations/compTimeModal';
import CompensationInfo from './CompensationInfo';
import MoneyPaymentInfo from './MoneyPaymentInfo';
import { ActionsBar, ContainerModal, StyledGrid } from './styles';

const CompTimeModal = ({
  handleClose,
  compTimeSelected,
  getCollaboratorsCompTime,
}) => {
  const formInitialValues = {
    fullName: compTimeSelected?.fullName,
    formattedAllTimeCompTime: compTimeSelected?.formattedAllTimeCompTime,
    type: '',
    justification: '',
    compensationDate: null,
    paidValue: 0,
    hoursPaid: '',
    dayHoursCompensated: '',
    paymentDate: null,
  };
  const typesOptions = [
    { label: 'Pagar Horas', value: COMP_TIME_HISTORY_TYPES.MONEY_PAYMENT },
    {
      label: 'Compensar Horas',
      value: COMP_TIME_HISTORY_TYPES.COMPENSATION_PAYMENT,
    },
  ];

  const dispatch = useDispatch();

  const handleSubmit = async ({
    type,
    justification,
    compensationDate,
    dayHoursCompensated,
    hoursPaid,
    paymentDate,
    paidValue,
  }) => {
    try {
      dispatch(setLoading(true));
      const api = AuthenticatedHttpClient();
      const compensationHoursUrl = `comp-time/compensate-hours/${compTimeSelected?._id}`;
      const compensationValues = {
        justification,
        compensationDate: startOfDay(compensationDate),
        totalMinutesCompensated: getMinutesFromStringTime(dayHoursCompensated),
      };
      const moneyPaymentUrl = `comp-time/money-payment/${compTimeSelected?._id}`;
      const moneyPaymentValues = {
        justification,
        hoursPaid,
        paymentDate: startOfDay(paymentDate),
        paidValue,
      };
      const url =
        type === COMP_TIME_HISTORY_TYPES.MONEY_PAYMENT
          ? moneyPaymentUrl
          : compensationHoursUrl;

      const compTimeValues =
        type === COMP_TIME_HISTORY_TYPES.MONEY_PAYMENT
          ? moneyPaymentValues
          : compensationValues;

      await api.post(url, compTimeValues);
      handleClose();
      getCollaboratorsCompTime();
    } catch (error) {
      if (error.response?.data?.message) {
        dispatch(setSnackbar(true, 'error', error.response.data.message));
      } else {
        const errorMessage =
          type === COMP_TIME_HISTORY_TYPES.MONEY_PAYMENT
            ? 'Não foi possível pagar as horas'
            : 'Não foi possível compensar as horas';
        dispatch(setSnackbar(true, 'error', errorMessage));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <ModalSkeleton
      title="BANCO DE HORAS"
      handleClickOnClose={handleClose}
      maxWidth="916px"
    >
      <ContainerModal>
        <Formik
          validationSchema={compTimeSchema}
          initialValues={formInitialValues}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  spacing={3}
                >
                  <StyledGrid item xs={6}>
                    <Field
                      component={TextField}
                      name="fullName"
                      label="Colaborador"
                      variant="filled"
                      disabled
                      fullWidth
                    />
                  </StyledGrid>
                  <StyledGrid item xs={6}>
                    <Field
                      component={TextField}
                      name="type"
                      select
                      label="Tipo"
                      variant="filled"
                      fullWidth
                    >
                      {typesOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </StyledGrid>
                  {values.type ===
                    COMP_TIME_HISTORY_TYPES.COMPENSATION_PAYMENT && (
                    <CompensationInfo
                      values={values}
                      userId={compTimeSelected?._id}
                      setFieldValue={setFieldValue}
                      initialAllTimeCompTime={
                        compTimeSelected?.formattedAllTimeCompTime
                      }
                    />
                  )}
                  {values.type === COMP_TIME_HISTORY_TYPES.MONEY_PAYMENT && (
                    <MoneyPaymentInfo
                      initialAllTimeCompTime={
                        compTimeSelected?.formattedAllTimeCompTime
                      }
                    />
                  )}
                </Grid>
                <ActionsBar container alignItems="center" justify="flex-end">
                  <StyledGrid mt="32px">
                    <Button onClick={handleClose} size="small">
                      Cancelar
                    </Button>
                    <Button type="submit" color="secondary" size="small">
                      CONFIRMAR
                    </Button>
                  </StyledGrid>
                </ActionsBar>
              </Form>
            );
          }}
        </Formik>
      </ContainerModal>
    </ModalSkeleton>
  );
};

CompTimeModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  compTimeSelected: PropTypes.objectOf(PropTypes.any).isRequired,
  getCollaboratorsCompTime: PropTypes.func.isRequired,
};

export default CompTimeModal;
