import styled from 'styled-components';
import { Accordion } from '@material-ui/core';

export const StyledAccordion = styled(Accordion)`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;

  &.MuiAccordion-rounded:first-child {
    border-radius: 6px;
    border-radius: 6px;
  }

  &.MuiAccordion-root:before {
    display: none;
  }
`;
