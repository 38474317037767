import { Button, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalSkeleton } from '../../../../components';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import getSetDate from '../../../../utils/getSetDate';
import { ActionsBar, ContainerModal, StyledGrid } from '../styles';

const RegisterPointModal = ({
  handleClickOnClose,
  values,
  setNewPointRegistered,
}) => {
  const dispatch = useDispatch();

  const handleRegisterPoint = async () => {
    try {
      dispatch(setLoading(true));
      const api = AuthenticatedHttpClient();
      await api.post('/point-registers', {
        pointStartDate: values.pointStartDate,
        registerDate: getSetDate(),
        type: values.type,
        breakName: values.breakName,
      });
      setSnackbar(true, 'success', 'Ponto registrado com sucesso');
      setNewPointRegistered(prevState => !prevState);
    } catch (e) {
      dispatch(
        setSnackbar(true, 'error', 'Houve alguma falha ao registrar o ponto'),
      );
    } finally {
      dispatch(setLoading(false));
      handleClickOnClose();
    }
  };

  return (
    <ModalSkeleton
      title="REGISTRAR PONTO"
      handleClickOnClose={handleClickOnClose}
      maxWidth="956px"
    >
      <ContainerModal>
        <Grid container alignItems="center" justify="space-between" spacing={2}>
          <StyledGrid item xs={6}>
            <TextField
              name="date"
              label="Data"
              variant="filled"
              value={values.date}
              disabled
              fullWidth
            />
          </StyledGrid>
          <StyledGrid item xs={6}>
            <TextField
              name="time"
              label="Horario"
              variant="filled"
              value={values.time}
              disabled
              fullWidth
            />
          </StyledGrid>
          <StyledGrid item xs={12}>
            <TextField
              name="description"
              label="Descricao"
              variant="filled"
              disabled
              value={values.description}
              fullWidth
            />
          </StyledGrid>
        </Grid>
        <ActionsBar container alignItems="center" justify="flex-end">
          <StyledGrid mt="32px">
            <Button color="secondary" onClick={handleClickOnClose} size="small">
              CANCELAR
            </Button>
            <Button
              onClick={handleRegisterPoint}
              color="secondary"
              size="small"
            >
              REGISTRAR
            </Button>
          </StyledGrid>
        </ActionsBar>
      </ContainerModal>
    </ModalSkeleton>
  );
};

RegisterPointModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
  values: PropTypes.objectOf(
    PropTypes.shape({
      description: PropTypes.string,
      time: PropTypes.string,
      date: PropTypes.string,
    }),
  ).isRequired,
  setNewPointRegistered: PropTypes.func.isRequired,
};

RegisterPointModal.defaultProps = {};

export default RegisterPointModal;
