import * as Yup from 'yup';
import { SCALE_TYPES } from '../utils/enums';
import parseDate from '../utils/parseDateString';

const journeyScaleSchema = Yup.object().shape({
  scaleType: Yup.string().required('Campo obrigatório'),
  holidays: Yup.string().required('Campo obrigatório'),
  standardWeekScale: Yup.object().when('scaleType', {
    is: scaleType =>
      scaleType === SCALE_TYPES['5x2'] ||
      scaleType === SCALE_TYPES['6x1'] ||
      scaleType === SCALE_TYPES.CALL_CENTER_30 ||
      scaleType === SCALE_TYPES.CALL_CENTER_36,

    then: Yup.object().shape({
      isEqualTime: Yup.boolean().required('Campo obrigatório'),
      isMonday: Yup.boolean().required('Campo obrigatório'),
      isTuesday: Yup.boolean().required('Campo obrigatório'),
      isWednesday: Yup.boolean().required('Campo obrigatório'),
      isThursday: Yup.boolean().required('Campo obrigatório'),
      isFriday: Yup.boolean().required('Campo obrigatório'),
      isSaturday: Yup.boolean().required('Campo obrigatório'),
      isSunday: Yup.boolean().required('Campo obrigatório'),
      monday: Yup.object().when(['isEqualTime', 'isMonday'], {
        is: (isEqualTime, isMonday) => !isEqualTime && isMonday,
        then: Yup.object().shape({
          enterTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          exitTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          mainBreak: Yup.string().required('Campo obrigatório'),
        }),
        otherwise: Yup.object().notRequired(),
      }),
      tuesday: Yup.object().when(['isEqualTime', 'isTuesday'], {
        is: (isEqualTime, isTuesday) => !isEqualTime && isTuesday,
        then: Yup.object().shape({
          enterTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          exitTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          mainBreak: Yup.string().required('Campo obrigatório'),
        }),
        otherwise: Yup.object().notRequired(),
      }),
      wednesday: Yup.object().when(['isEqualTime', 'isWednesday'], {
        is: (isEqualTime, isWednesday) => !isEqualTime && isWednesday,
        then: Yup.object().shape({
          enterTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          exitTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          mainBreak: Yup.string().required('Campo obrigatório'),
        }),
        otherwise: Yup.object().notRequired(),
      }),
      thursday: Yup.object().when(['isEqualTime', 'isThursday'], {
        is: (isEqualTime, isThursday) => !isEqualTime && isThursday,
        then: Yup.object().shape({
          enterTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          exitTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          mainBreak: Yup.string().required('Campo obrigatório'),
        }),
        otherwise: Yup.object().notRequired(),
      }),
      friday: Yup.object().when(['isEqualTime', 'isFriday'], {
        is: (isEqualTime, isFriday) => !isEqualTime && isFriday,
        then: Yup.object().shape({
          enterTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          exitTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          mainBreak: Yup.string().required('Campo obrigatório'),
        }),
        otherwise: Yup.object().notRequired(),
      }),
      saturday: Yup.object().when(['isEqualTime', 'isSaturday'], {
        is: (isEqualTime, isSaturday) => !isEqualTime && isSaturday,
        then: Yup.object().shape({
          enterTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          exitTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          mainBreak: Yup.string().required('Campo obrigatório'),
        }),
        otherwise: Yup.object().notRequired(),
      }),
      sunday: Yup.object().when(['isEqualTime', 'isSunday'], {
        is: (isEqualTime, isSunday) => !isEqualTime && isSunday,
        then: Yup.object().shape({
          enterTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          exitTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          mainBreak: Yup.string().required('Campo obrigatório'),
        }),
        otherwise: Yup.object().notRequired(),
      }),
      standardTime: Yup.object().when('isEqualTime', {
        is: true,
        then: Yup.object().shape({
          enterTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          exitTime: Yup.date()
            .transform(parseDate)
            .typeError('Horário Invalido')
            .required('Campo obrigatório'),
          mainBreak: Yup.string().required('Campo obrigatório'),
        }),
        otherwise: Yup.object().notRequired(),
      }),
    }),
    otherwise: Yup.object().notRequired(),
  }),

  standardDayScale: Yup.object().when('scaleType', {
    is: SCALE_TYPES['5x1'] || SCALE_TYPES['12/36'] || SCALE_TYPES['24/48'],
    then: Yup.object().shape({
      standardDay: Yup.object().shape({
        enterTime: Yup.date()
          .transform(parseDate)
          .typeError('Horário Invalido')
          .required('Campo obrigatório'),
        exitTime: Yup.date()
          .transform(parseDate)
          .typeError('Horário Invalido')
          .required('Campo obrigatório'),
        mainBreak: Yup.string().required('Campo obrigatório'),
      }),
    }),
    otherwise: Yup.object().notRequired(),
  }),
});

export default journeyScaleSchema;
