import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import xlsImage from '../../../../assets/xls.svg';
import {
  Downloader,
  Grid,
  ModalSkeleton,
  Typography,
  Uploader,
} from '../../../../components';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import {
  ActionsBar,
  ContainerModal,
  DownloaderContainer,
  StyledGrid,
} from './styles';

export default function UserImprortModal({ handleClose }) {
  const [file, setFile] = useState();
  const [isSubmiting, setSubmiting] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      setSubmiting(true);
      const formData = new FormData();
      formData.append('sheet', file);

      const api = AuthenticatedHttpClient();
      await api.post('/users/upload-many', formData);

      dispatch(setSnackbar(true, 'success', 'Convites enviados com sucesso'));
    } catch (e) {
      try {
        const { message } = e.response.data;
        dispatch(setSnackbar(true, 'Error', JSON.stringify(message)));
      } catch (error) {
        dispatch(
          setSnackbar(true, 'Error', 'Ocorreu um erro, tente novamente'),
        );
      }
    } finally {
      setSubmiting(false);
    }
  };

  return (
    <ModalSkeleton
      title="IMPORTAR COLABORADORES"
      handleClickOnClose={handleClose}
      maxWidth="700px"
    >
      <ContainerModal>
        <Grid container>
          <StyledGrid item>
            <Grid container>
              <Box component={Grid} item>
                <Typography fontSize="14px" mb="10px" width="100%">
                  Para realizar importação em massa de colaboradores, baixe o
                  modelo de planilha abaixo, preencha os campos necessários e
                  depois faça o upload.
                </Typography>
              </Box>

              <DownloaderContainer item>
                {/* Se esse link estiver mockado, reprove o PR  */}
                <Downloader
                  file={{
                    name: 'Modelo_Importacao_em_Lote.xlsx',
                    location:
                      'https://siricascudo-files.s3.amazonaws.com/example-sheets/Planilha_Modelo_Importacao_em_Lote.xlsx',
                    size: '6.5kb',
                    image: xlsImage,
                  }}
                />
              </DownloaderContainer>
            </Grid>
          </StyledGrid>
          <Grid mb="20px" mt="20px" item xs={12}>
            <Typography fontWeight="bold">Importar</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography mb={0} opacity={0.5}>
              Planilha modelo preenchida
            </Typography>
            <Uploader
              accept=".xlsx"
              dropText="Adicionar Planilha"
              onFileUploaded={setFile}
              size="xl"
            />
          </Grid>
        </Grid>
        <ActionsBar container alignItems="center" justify="flex-end">
          <Button
            disabled={isSubmiting}
            type="buton"
            onClick={handleClose}
            size="small"
          >
            <Typography opacity="0.4" fontWeight="bold">
              CANCELAR
            </Typography>
          </Button>
          <Button
            color="secondary"
            disabled={isSubmiting}
            onClick={handleSubmit}
            size="small"
          >
            IMPORTAR COLABORADORES
          </Button>
        </ActionsBar>
      </ContainerModal>
    </ModalSkeleton>
  );
}

UserImprortModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
