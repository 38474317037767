import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Box } from '@material-ui/core';

import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';

import { Typography, Grid, ModalSkeleton } from '../../../components';

import reactiveColaborator from '../../../assets/reactiveColaborator.svg';

const ReactivateColaboratorModal = React.forwardRef(
  ({ handleClickOnClose }, ref) => {
    const dispatch = useDispatch();
    const { userId: collaboratorId } = useParams();

    const handleReactivateCollaborator = useCallback(async () => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        await api.put(`users/reactivate/${collaboratorId}`);

        dispatch(setSnackbar(true, 'success', 'Colaborador reativado'));
        handleClickOnClose(true);
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao reativar colaborador'));
      } finally {
        dispatch(setLoading(false));
      }
    }, [collaboratorId, dispatch, handleClickOnClose]);

    return (
      <ModalSkeleton
        title="Reativar Colaborador"
        handleClickOnClose={() => handleClickOnClose(false)}
        maxWidth="800px"
        ref={ref}
      >
        <Box m={4}>
          <Grid container alignItems="center" justify="center">
            <Grid item lg={5}>
              <img src={reactiveColaborator} alt="" />
            </Grid>

            <Grid item lg={7}>
              <Typography variant="h3" fontWeight="bold" align="left">
                VOCÊ TEM CERTEZA DISSO?
              </Typography>
              <Typography opacity="0.4" mt="20px" fontSize="20px">
                IIpsam neque eos ea et ullam ducimus veniam quod possimus.
                Repudiandae et corporis. Qui vitae est accusantium est quaerat
                vero. Est vel unde officia recusandae velit aut omnis.
              </Typography>
            </Grid>
          </Grid>

          <Box mt={3} mb={3}>
            <Grid container item lg={12} justify="flex-end" alignItems="center">
              <Box mr={4}>
                <Button
                  type="buton"
                  color="secondary"
                  onClick={() => handleClickOnClose(false)}
                  size="small"
                >
                  <Typography
                    fontSize="0.9375rem"
                    fontWeight="bold"
                    opacity="0.7"
                  >
                    CANCELAR
                  </Typography>
                </Button>
              </Box>

              <Button onClick={handleReactivateCollaborator}>
                <Typography fontSize="0.9375rem" fontWeight="bold">
                  CONFIRMAR
                </Typography>
              </Button>
            </Grid>
          </Box>
        </Box>
      </ModalSkeleton>
    );
  },
);

ReactivateColaboratorModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
};

export default ReactivateColaboratorModal;
