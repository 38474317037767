import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import {
  ButtonBase,
  Button,
  Paper,
  Grid,
  MenuItem,
  Divider,
  TextField,
  FormControlLabel,
  Box,
  FormLabel,
} from '@material-ui/core';
import { CheckboxWithLabel } from 'formik-material-ui';

export const Container = styled.div`
  padding: 24px;
`;

export const StyledButtonBase = styled(ButtonBase)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({ color, theme }) =>
    color === 'error'
      ? theme.palette.error.main
      : transparentize(0.8, theme.palette.primary.main)};
  margin-right: 16px;
`;

export const StyledButtonBaseDismissal = styled(ButtonBase)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 16px;
  margin-left: 50px;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledButtonBaseReactivate = styled(ButtonBase)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 16px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

export const StyledGrid = styled(Grid)`
  ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt}px;
    `}
`;

export const UserInfo = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 65px;
    height: 65px;
    border-radius: 2px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
    color: ${({ theme }) => theme.palette.secondary.main};

    strong {
      font-size: 18px;
      font-weight: bold;
    }
    span {
      font-size: 12px;
    }
  }
`;

export const StyledButton = styled(Button)`
  ${({ textcolor, theme }) =>
    textcolor &&
    css`
      color: ${theme.palette[textcolor].main};
    `}
  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize}px;
    `}
`;

export const DetailPaper = styled(Paper)`
  padding: 16px;
  margin-top: 16px;
`;

export const DetailItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  color: ${({ theme }) => theme.palette.secondary.main};

  & + div {
    margin-left: 80px;
  }

  span {
    font-size: 12px;
    font-weight: 300;
  }
  p {
    font-size: 12px;
    margin-top: 8px;
  }
`;

export const SelectStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 0.4;
  color: ${({ theme }) => theme.palette.secondary.main};

  span {
    font-size: 12px;
  }

  .MuiFormControl-root {
    margin-top: 8px;
    height: 60px;
  }
`;

export const TabsPaper = styled(Paper)`
  margin-top: 16px;
`;

export const TabContainer = styled.div`
  padding: 16px;
`;

export const ContainerInput = styled(Grid)`
  height: 80px;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : 0)};

  input {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const ActionsBar = styled(Grid)`
  margin-top: 16px;

  button {
    padding: 0 20px;
    font-weight: 700;
    font-size: 14px;
  }
`;

export const SubTitleForm = styled.legend`
  width: 100%;
  margin: 8px 0;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const FilterText = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.secondary.main};

  & + p {
    margin-left: 32px;
  }
`;

export const RecordsButton = styled(ButtonBase)`
  width: 150px;
  height: 54px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  background: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const StyledDivider = styled(Divider)`
  margin: 12px 0 42px 0;
`;

export const StyledButtonModalOpen = styled(Button)`
  &.MuiButton-root {
    margin: 0px 0px;
    padding: 0px 0px;
    border: none;
  }
`;

export const StyledDivModal = styled.div`
  margin-top: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledDivModalClose = styled.div`
  width: 26%;
  display: flex;
  justify-content: flex-end;

  img {
    width: 40%;
  }
`;

export const StyledImgExpanded = styled.img`
  width: 20%;
`;

export const StyledButtonModal = styled(Button)`
  margin-left: 20px;
  width: 100%;
  height: 55px;
  color: #fff;

  &.MuiButton-root {
    background: ${({ theme }) => theme.palette.primary.main};
  }
  &.MuiButton-root:hover {
    background: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const StyledTextField = styled(TextField)`
  .MuiFilledInput-root,
  .MuiInputBase-adornedEnd,
  .MuiInputBase-adornedStart,
  .MuiFilledInput-adornedEnd,
  .MuiFilledInput-adornedStart {
    background: #f6f8f9;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  span.MuiFormControlLabel-label {
    font-weight: 300;
    font-size: 12px;
    color: ${({ theme }) => transparentize(0.2, theme.palette.secondary.main)};
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  .MuiAccordion-root + .MuiAccordion-root {
    margin-top: 16px;
  }
`;

export const DependentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 25px;

  & + div {
    margin-top: 8px;
  }

  border: 1px solid #e6e6e6;
  border-radius: 6px;
`;

export const AdditionalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 24px;

  p {
    margin-left: 16px;
    font-weight: 300;
    font-size: 16px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const ImageContainer = styled.div`
  ${({ image }) => css`
    width: 100%;
    position: relative;
    background: url(${image}) 50% 50% no-repeat;
    background-size: cover;
    background-clip: content-box;
  `}

  &::before {
    display: block;
    content: '';
    padding-top: 75%;
  }
`;

export const StyledCheckbox = styled(CheckboxWithLabel)`
  & + span {
    font-weight: 300;
    font-size: 16px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const StyledFormLabel = styled(FormLabel)`
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.secondary.main};
`;
