import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Sidebar } from '../../components';
import { ROLES } from '../../utils/enums';
import {
  Active,
  ActiveColaborator,
  Admission,
  AdmissionColaborator,
  AdmissionNewColaborator,
  Career,
  Departments,
  Dismissal,
  DismissalColaborator,
  EditJourney,
  Holidays,
  Journey,
  NewJourney,
  RecordPoints,
} from '../index';
import PointMirror from '../RecordPoints/PointMirror';
import { Container } from './styles';

const Home = () => {
  const { path } = useRouteMatch();

  return (
    <Container>
      <Sidebar />

      <div className="content">
        <Switch>
          <Route
            path={`${path}/admissions`}
            exact
            component={Admission}
            isPrivate
            authorization={[
              ROLES.ADMINISTRATOR_COMPANY,
              ROLES.HUMAN_RESOURCES_MANAGMENT,
            ]}
          />
          <Route
            path={`${path}/admissions/new-colaborator`}
            exact
            component={AdmissionNewColaborator}
            isPrivate
          />
          <Route
            path={`${path}/admissions/collaborator/:userId`}
            exact
            component={AdmissionColaborator}
            isPrivate
            authorization={[
              ROLES.ADMINISTRATOR_COMPANY,
              ROLES.HUMAN_RESOURCES_MANAGMENT,
            ]}
          />
          <Route
            path={`${path}/active`}
            exact
            component={Active}
            isPrivate
            authorization={[
              ROLES.ADMINISTRATOR_COMPANY,
              ROLES.HUMAN_RESOURCES_MANAGMENT,
            ]}
          />
          <Route
            path={`${path}/active/collaborator/:userId`}
            exact
            component={ActiveColaborator}
            isPrivate
            authorization={[
              ROLES.ADMINISTRATOR_COMPANY,
              ROLES.HUMAN_RESOURCES_MANAGMENT,
            ]}
          />
          <Route
            path={`${path}/dismissal`}
            exact
            component={Dismissal}
            isPrivate
            authorization={[
              ROLES.ADMINISTRATOR_COMPANY,
              ROLES.HUMAN_RESOURCES_MANAGMENT,
            ]}
          />
          <Route
            path={`${path}/dismissal/collaborator/:userId`}
            exact
            component={DismissalColaborator}
            isPrivate
            authorization={[
              ROLES.ADMINISTRATOR_COMPANY,
              ROLES.HUMAN_RESOURCES_MANAGMENT,
            ]}
          />
          <Route
            path={`${path}/career`}
            exact
            component={Career}
            isPrivate
            authorization={[
              ROLES.ADMINISTRATOR_COMPANY,
              ROLES.HUMAN_RESOURCES_MANAGMENT,
            ]}
          />
          <Route
            path={`${path}/departments`}
            exact
            component={Departments}
            isPrivate
            authorization={[
              ROLES.ADMINISTRATOR_COMPANY,
              ROLES.HUMAN_RESOURCES_MANAGMENT,
            ]}
          />
          <Route
            path={`${path}/record-points`}
            exact
            component={RecordPoints}
            isPrivate
            authorization={[
              ROLES.ADMINISTRATOR_COMPANY,
              ROLES.HUMAN_RESOURCES_MANAGMENT,
            ]}
          />
          <Route
            path={`${path}/record-points/:userId/point-mirror`}
            exact
            component={PointMirror}
            isPrivate
            authorization={[
              ROLES.ADMINISTRATOR_COMPANY,
              ROLES.HUMAN_RESOURCES_MANAGMENT,
            ]}
          />
          <Route
            path={`${path}/journey`}
            exact
            component={Journey}
            isPrivate
            authorization={[
              ROLES.ADMINISTRATOR_COMPANY,
              ROLES.HUMAN_RESOURCES_MANAGMENT,
            ]}
          />
          <Route
            path={`${path}/journey/new-journey`}
            exact
            component={NewJourney}
            isPrivate
            authorization={[
              ROLES.ADMINISTRATOR_COMPANY,
              ROLES.HUMAN_RESOURCES_MANAGMENT,
            ]}
          />
          <Route
            path={`${path}/journey/edit-journey`}
            exact
            component={EditJourney}
            isPrivate
            authorization={[
              ROLES.ADMINISTRATOR_COMPANY,
              ROLES.HUMAN_RESOURCES_MANAGMENT,
            ]}
          />
          <Route
            path={`${path}/holidays`}
            exact
            component={Holidays}
            isPrivate
            authorization={[
              ROLES.ADMINISTRATOR_COMPANY,
              ROLES.HUMAN_RESOURCES_MANAGMENT,
            ]}
          />
        </Switch>
      </div>
    </Container>
  );
};

export default Home;
