import styled, { css } from 'styled-components';
import { ButtonBase } from '@material-ui/core';

export const PaySlipListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px;
  width: 100%;
`;

export const SubTitleForm = styled.legend`
  width: 100%;
  margin: 8px 0;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const PaySlipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  width: 100%;
  height: 76px;
  margin-top: 16px 0;
  background-color: #fbfbfb;
  border-radius: 6px;
  opacity: 1;
  img {
    box-sizing: border-box;
    max-width: ${({ img }) => (img ? '100%' : '100%')};
    max-height: 100%;
  }
  span {
    margin-left: 8px;
    ${({ theme }) => css`
      color: ${theme.palette.secondary.main};
      font-weight: 300;
      font-size: 12px;
      margin-top: '8px'
      opacity: 0.5;
    `}
  }
`;
export const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  margin: 0 8px;
`;

export const StyledDeleteButton = styled(ButtonBase)`
  img {
    margin-right: 12px;
  }
`;

export const StyledDownloadButton = styled(ButtonBase)`
  img {
    margin-right: 12px;
  }
`;
