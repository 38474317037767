import {
  Button,
  DialogContent,
  Grid,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core';
import { AddSharp } from '@material-ui/icons';
import React, { useState } from 'react';
import { SearchInput } from '../../components';
import AddCareerModal from './AddCareerModal';
import {
  Container,
  DepartamentsContainer,
  StyledIconButton,
  StyledTab,
  StyledTabs,
} from './styles';
import TableCareer from './TableCareer';

const Career = () => {
  const [searchText, setSearchText] = useState('');
  const [openCareerModal, setOpenCareerModal] = useState(false);
  const [newJobRole, setNewJobRole] = useState('');

  return (
    <Container>
      <Typography variant="h2">Cargos</Typography>
      <Paper elevation={1}>
        <StyledTabs value={1} indicatorColor="primary">
          <StyledTab label="CARGOS" value={1} />
        </StyledTabs>
        <DepartamentsContainer>
          <Grid container alignItems="center" justify="space-between">
            <SearchInput value={searchText} handleInput={setSearchText} />
            <div>
              <StyledIconButton onClick={() => setOpenCareerModal(true)}>
                <AddSharp />
              </StyledIconButton>
              <Button color="primary" onClick={() => setOpenCareerModal(true)}>
                ADICIONAR CARGO
              </Button>
              <Modal
                open={openCareerModal}
                onClose={() => setOpenCareerModal(false)}
                aria-labelledby="add-collaborator-title"
                aria-describedby="simple-modal-description"
              >
                <DialogContent>
                  <AddCareerModal
                    handleClickOnClose={() => setOpenCareerModal(false)}
                    handleAddJobRole={setNewJobRole}
                  />
                </DialogContent>
              </Modal>
            </div>
          </Grid>

          <TableCareer textFilter={searchText} newJobRole={newJobRole} />
        </DepartamentsContainer>
      </Paper>
    </Container>
  );
};

export default Career;
