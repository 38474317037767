import { Button, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ModalSkeleton } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { ActionsBar, ContainerModal, StyledGrid } from './styles';

const AddDepartamentModal = ({
  handleClickOnClose,
  handleAddDepartment,
  isUpdate,
  formInitialValues,
  setIsUpdated,
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const handleAddDepartamentModal = useCallback(
    async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const api = AuthenticatedHttpClient();
        const response = await api.post('department', {
          ...values,
          company: user.company._id,
        });
        handleAddDepartment(response.data._id);
        dispatch(
          setSnackbar(true, 'success', 'Departamento cadastrado com sucesso'),
        );
      } catch (error) {
        if (error.response?.data?.message) {
          dispatch(setSnackbar(true, 'error', error.response.data.message));
        } else {
          dispatch(
            setSnackbar(
              true,
              'error',
              'Não foi possivel cadastrar o departamento',
            ),
          );
        }
      } finally {
        setSubmitting(false);
        handleClickOnClose();
      }
    },
    [dispatch, handleClickOnClose, user, handleAddDepartment],
  );

  const handleUpdateDepartment = useCallback(
    async (values, { setSubmitting }) => {
      const valuesToUpdate = {
        name: values.name,
        _id: values._id,
        company: user.company._id,
      };
      try {
        setSubmitting(true);
        const api = AuthenticatedHttpClient();
        await api.post(`department/${values._id}`, valuesToUpdate);
        dispatch(setSnackbar(true, 'success', 'Departamento Atualizado'));
        setIsUpdated(prevState => !prevState);
        handleClickOnClose();
      } catch (error) {
        if (error.response?.data?.message) {
          dispatch(setSnackbar(true, 'error', error.response.data.message));
        } else {
          dispatch(
            setSnackbar(
              true,
              'error',
              'Não foi possível atualizar o departamento',
            ),
          );
        }
      } finally {
        setSubmitting(false);
        handleClickOnClose();
      }
    },
    [dispatch, handleClickOnClose, setIsUpdated, user],
  );

  const handleValidate = useCallback(values => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Nome do departamento obrigatório';
    }

    return errors;
  }, []);

  return (
    <ModalSkeleton
      title="ADICIONAR DEPARTAMENTO"
      handleClickOnClose={handleClickOnClose}
    >
      <ContainerModal>
        <Formik
          initialValues={formInitialValues}
          validate={handleValidate}
          onSubmit={
            isUpdate ? handleUpdateDepartment : handleAddDepartamentModal
          }
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid
                container
                alignItems="center"
                justify="space-between"
                spacing={2}
              >
                <StyledGrid item xs={12}>
                  <Field
                    component={TextField}
                    name="name"
                    label="Nome"
                    variant="filled"
                    fullWidth
                  />
                </StyledGrid>
              </Grid>
              <ActionsBar container alignItems="center" justify="flex-end">
                <StyledGrid mt="32px">
                  <Button
                    disabled={isSubmitting}
                    color="secondary"
                    onClick={handleClickOnClose}
                    size="small"
                  >
                    CANCELAR
                  </Button>
                  <Button
                    color="secondary"
                    disabled={isSubmitting}
                    type="submit"
                    size="small"
                  >
                    {isUpdate
                      ? 'ATUALIZAR DEPARTAMENTO'
                      : 'REGISTRAR DEPARTAMENTO'}
                  </Button>
                </StyledGrid>
              </ActionsBar>
            </Form>
          )}
        </Formik>
      </ContainerModal>
    </ModalSkeleton>
  );
};

AddDepartamentModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
  handleAddDepartment: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  formInitialValues: PropTypes.shape({
    name: PropTypes.string,
  }),
  setIsUpdated: PropTypes.func,
};

AddDepartamentModal.defaultProps = {
  isUpdate: false,
  formInitialValues: {
    name: '',
  },
  setIsUpdated: () => {},
};

export default AddDepartamentModal;
