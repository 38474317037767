import { combineReducers, createStore } from 'redux';
import snackbarReducer from './ducks/snackbar';
import loadingReducer from './ducks/loading';

const reducer = combineReducers({
  snackbar: snackbarReducer,
  loading: loadingReducer,
});

const store = createStore(reducer, {});

export default store;
