import DateFnsUtils from '@date-io/date-fns';
import { Button } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { set } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Withdrawal from '../../../../assets/Withdrawal.svg';
import {
  Grid,
  ModalSkeleton,
  Typography,
  Uploader,
} from '../../../../components';
import { useAuth } from '../../../../hooks/auth';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import { ACTIVITY_PERIOD, STATUS_ACTIVE } from '../../../../utils/enums';
import toFormData from '../../../../utils/formData';
import { StyledGridButton } from '../styles';

const InssWithdrawalModal = ({ handleClickOnClose, setUser }) => {
  const [registeredInssWithdrawal, setInssWithdrawal] = useState(false);
  const [document, setDocument] = useState(null);

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();
  const { user } = useAuth();

  const inssWithdrawalSchema = Yup.object().shape({
    startDate: Yup.date()
      .required('Campo obrigatório')
      .typeError('Data inválida'),
    finalDate: Yup.date().when('withoutForecastOfEnding', {
      is: true,
      then: Yup.date().nullable(),
      otherwise: Yup.date()
        .min(
          Yup.ref('startDate'),
          'Informe uma data posterior a data de início.',
        )
        .required('Campo obrigatório')
        .typeError('Data inválida'),
    }),
    withoutForecastOfEnding: Yup.boolean().required(),
    reason: Yup.string().required('Informe o motivo'),
  });

  const formInitialValues = {
    startDate: null,
    finalDate: null,
    withoutForecastOfEnding: false,
    reason: '',
  };

  const handleInssWithdrawal = useCallback(
    async (values, { setSubmitting }) => {
      try {
        dispatch(setLoading(true));

        if (!document) {
          dispatch(setSnackbar(true, 'error', 'Documento obrigatório'));
          return;
        }

        const {
          startDate,
          finalDate,
          withoutForecastOfEnding,
          reason,
        } = values;

        const api = AuthenticatedHttpClient();
        const formattedDateStart = set(startDate, {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        });
        const vacationDetails = {
          startDate: formattedDateStart,
          withoutForecastOfEnding,
          reason,
          user: collaboratorId,
          company: user.company._id,
          type: STATUS_ACTIVE.INSS_REMOVAL,
          document,
          period: ACTIVITY_PERIOD.MORE_THAN_ONE_DAY,
        };

        if (finalDate) {
          const formattedDateEnd = set(finalDate, {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          });
          if (formattedDateStart.getTime() === formattedDateEnd.getTime()) {
            dispatch(
              setSnackbar(
                true,
                'error',
                'A data de inicio e fim não podem ser iguais',
              ),
            );
            return;
          }
          vacationDetails.finalDate = formattedDateEnd;
        }

        const form = toFormData(vacationDetails);

        const response = await api.post('activity', form);

        dispatch(
          setSnackbar(true, 'success', 'Afastamento registrado com sucesso'),
        );
        setUser(response.data);
        setInssWithdrawal(true);
      } catch (error) {
        dispatch(setSnackbar(true, 'error', error?.response?.data?.message));
      } finally {
        setSubmitting(false);
        dispatch(setLoading(false));
      }
    },
    [collaboratorId, dispatch, document, setUser, user.company._id],
  );

  const handleChangeCheckbox = useCallback((e, setFieldValue) => {
    if (e.target.value) {
      setFieldValue('finalDate', null);
    }
  }, []);

  return (
    <ModalSkeleton
      title="REGISTRAR AFASTAMENTO"
      handleClickOnClose={handleClickOnClose}
      maxWidth="850px"
    >
      {registeredInssWithdrawal ? (
        <Grid container pa="18px">
          <Grid>
            <Grid container justify="center" alignItems="space" spacing={5}>
              <Grid mt="50px">
                <Grid item leg={6}>
                  <img src={Withdrawal} alt="" />
                </Grid>
              </Grid>
              <Grid item lg={6}>
                <Typography
                  mt="50px"
                  variant="h3"
                  fontWeight="bold"
                  align="left"
                >
                  AFASTAMENTO REGISTRADO COM SUCESSO!
                </Typography>
                <Typography opacity="0.4" mt="20px" fontSize="20px">
                  Informe ao colaborador sobre a <br /> data do afastamento
                </Typography>
              </Grid>
            </Grid>
            <StyledGridButton container justify="flex-end" alignItems="center">
              <Grid item lg={2}>
                <Button onClick={() => handleClickOnClose(false)}>
                  <Typography variant="h3" fontWeight="bold">
                    CONFIRMAR
                  </Typography>
                </Button>
              </Grid>
            </StyledGridButton>
          </Grid>
        </Grid>
      ) : (
        <Grid pa="18px" mt="24px">
          <Grid
            container
            justify="center"
            alignContent="space-between"
            spacing={3}
          />
          <Formik
            initialValues={formInitialValues}
            validationSchema={inssWithdrawalSchema}
            onSubmit={handleInssWithdrawal}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <Form>
                  <Grid
                    container
                    justify="flex-start"
                    alignContent="center"
                    spacing={3}
                  >
                    <Grid item lg={6}>
                      <Field
                        component={KeyboardDatePicker}
                        name="startDate"
                        label="Data início do afastamento"
                        inputVariant="filled"
                        cancelLabel="CANCELAR"
                        invalidDateMessage="Data inválida"
                        format="dd/MM/yyyy"
                        fullWidth
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <Field
                        component={KeyboardDatePicker}
                        name="finalDate"
                        label="Data término do afastamento"
                        inputVariant="filled"
                        cancelLabel="CANCELAR"
                        invalidDateMessage="Data inválida"
                        format="dd/MM/yyyy"
                        disabled={values.withoutForecastOfEnding}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <Field
                        component={CheckboxWithLabel}
                        name="withoutForecastOfEnding"
                        checked={values.withoutForecastOfEnding}
                        color="primary"
                        type="checkbox"
                        onClick={e => handleChangeCheckbox(e, setFieldValue)}
                        Label={{
                          label: 'Sem previsão de término',
                        }}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <Field
                        component={TextField}
                        multiline
                        rows={5}
                        name="reason"
                        label="Motivo"
                        variant="filled"
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Typography fontSize="14px">Anexar documento</Typography>
                      <Uploader
                        accept="application/pdf"
                        dropText="Anexar"
                        onFileUploaded={setDocument}
                      />
                    </Grid>
                  </Grid>

                  <StyledGridButton
                    container
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button onClick={() => handleClickOnClose(false)}>
                        <Typography opacity="0.4" fontWeight="bold">
                          CANCELAR
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button type="submit" disabled={isSubmitting}>
                        <Typography fontWeight="bold">
                          CONFIRMAR AFASTAMENTO
                        </Typography>
                      </Button>
                    </Grid>
                  </StyledGridButton>
                </Form>
              </MuiPickersUtilsProvider>
            )}
          </Formik>
        </Grid>
      )}
    </ModalSkeleton>
  );
};

InssWithdrawalModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
};

export default InssWithdrawalModal;
