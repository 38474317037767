import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { EmptyState, Grid, TabPanel, Typography } from '../../../components';
import BreaksList from '../components/BreaksList';
import { JourneyData } from '../styles';

const Breaks = props => {
  const { value, index, journeyBreaks } = props;

  const [totalHours, setTotalHours] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);

  useEffect(() => {
    if (journeyBreaks.breaks) {
      const summedMinutes = journeyBreaks.breaks.reduce((acc, actualBreak) => {
        return Number(acc) + Number(actualBreak.time);
      }, 0);

      const hours = Math.floor(summedMinutes / 60);
      const minutes = summedMinutes % 60;
      setTotalHours(hours);
      setTotalMinutes(minutes);
    }
  }, [journeyBreaks.breaks]);

  const formatTime = (hours, minutes) => {
    if (!hours) {
      return `${minutes} minutos`;
    }
    return minutes > 10
      ? ` 0${hours}:${minutes} horas`
      : `0${hours}:0${minutes} horas`;
  };

  return (
    <TabPanel value={value} index={index}>
      {Object.keys(journeyBreaks).length === 0 &&
      journeyBreaks.constructor === Object ? (
        <EmptyState description="Não há pausas para essa jornada" />
      ) : (
        <Box p={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="secondary" fontSize="0.875rem">
                Detalhes da pausa
              </Typography>
            </Grid>
            <JourneyData container xs={12}>
              <Grid container alignItems="center" ml="16px" mr="16px" xs={12}>
                <Grid xs={3}>
                  <Typography
                    color="secondary"
                    opacity={0.5}
                    fontSize="0.875rem"
                  >
                    Total de pausas diárias:
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center" ml="16px" mr="16px" xs={12}>
                <Grid xs={3}>
                  <Typography
                    color="secondary"
                    fontWeight="bold"
                    opacity={0.5}
                    fontSize="1.25rem"
                  >
                    {formatTime(totalHours, totalMinutes)}
                  </Typography>
                </Grid>
              </Grid>
            </JourneyData>
          </Grid>
          <Grid container mt="40px">
            <Grid item xs={12}>
              <Typography color="secondary" fontSize="0.875rem">
                Outras pausas
              </Typography>
            </Grid>
          </Grid>
          <BreaksList breaks={journeyBreaks.breaks} />
        </Box>
      )}
    </TabPanel>
  );
};

Breaks.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  journeyBreaks: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Breaks;
