import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ContractDownloader,
  EmptyState,
  Typography,
} from '../../../../components';

const ContractsList = ({ contracts }) => {
  const hasContracts = contracts.filter(contract => {
    return Object.keys(contract).length > 1;
  });

  return (
    <>
      {hasContracts.length !== 0 ? (
        contracts.map((contract, index) => {
          if (Object.keys(contract).length > 1) {
            return (
              <Box mt={index === 0 ? 0 : 2} key={contract.key}>
                <Typography fontSize="14px" opacity="0.6" mb="20px">
                  {contract.documentDisplayName}
                </Typography>

                <ContractDownloader
                  file={{
                    location: contract.location,
                    status: contract.status,
                  }}
                  title={contract.documentDisplayName}
                  isCollaborator
                />
              </Box>
            );
          }
          return '';
        })
      ) : (
        <EmptyState description="Nenhum contrato encontrado!" />
      )}
    </>
  );
};

ContractsList.propTypes = {
  contracts: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ContractsList;
