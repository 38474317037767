import {
  Box,
  Button,
  DialogContent,
  Grid,
  IconButton,
  Modal,
} from '@material-ui/core';
import { formatISO, parse } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import plusIcon from '../../../assets/plus.svg';
import trashIcon from '../../../assets/trash-red.svg';
import { TabPanel, Typography } from '../../../components';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import checkCpfValidation from '../../../utils/checkCpfValidation';
import formatCpf from '../../../utils/formatCpf';
import familyDataSchema from '../../../validations/familyDataSchema';
import { AddDependent } from '../Modals';
import {
  ContainerInput,
  DependentButton,
  DependentContainer,
  StyledCheckbox,
  SubTitleForm,
  TabContainer,
} from '../styles';

const FamilyData = props => {
  const { value, index, data, setUser, collaboratorCpf, ...other } = props;
  const [openModal, setOpenModal] = useState(false);
  const [familyData, setFamilyData] = useState({
    maritalStatus: '',
    mothersName: '',
    fathersName: '',
    hasDependents: false,
  });
  const [dependents, setDependents] = useState([]);

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();

  useEffect(() => {
    if (Object.keys(data).length) {
      const { maritalStatus, mothersName, fathersName, hasDependents } = data;

      if (data.dependents.length > 0) {
        const dependentsFormated = data.dependents.map(dependent => ({
          ...dependent,
          dependentbirthDate: new Date(
            dependent.dependentbirthDate,
          ).toLocaleDateString('pt-br'),
        }));

        setFamilyData({
          maritalStatus,
          mothersName,
          fathersName,
          hasDependents,
        });
        setDependents(dependentsFormated);
      } else {
        setFamilyData({
          maritalStatus,
          mothersName,
          fathersName,
          hasDependents,
        });
      }
    }
  }, [data]);

  const handleSubmitFamilyData = useCallback(
    async (values, { setSubmitting }) => {
      try {
        dispatch(setLoading(true));

        if (values.hasDependents) {
          if (dependents.length === 0) {
            dispatch(setSnackbar(true, 'error', 'Adicione os dependentes'));
            return;
          }
        }

        const api = AuthenticatedHttpClient();

        const familyDataUpdated = values;

        if (dependents.length > 0 && values.hasDependents) {
          const formatedDependents = dependents.map(dependent => {
            const parsedDate = parse(
              dependent.dependentbirthDate,
              'dd/MM/yyyy',
              new Date(),
            );

            return {
              ...dependent,
              dependentbirthDate: formatISO(parsedDate),
            };
          });
          if (!checkCpfValidation(formatedDependents, collaboratorCpf)) {
            dispatch(
              setSnackbar(
                true,
                'error',
                'O colaborador possui o mesmo CPF de um dos dependentes',
              ),
            );
            return;
          }
          familyDataUpdated.dependents = formatedDependents;
        }

        const response = await api.put(`users/${collaboratorId}`, {
          familyData: familyDataUpdated,
        });

        setUser(response.data);
        dispatch(setSnackbar(true, 'success', 'Dados alterados'));
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possível alterar os dados'),
        );
      } finally {
        dispatch(setLoading(false));
        setSubmitting(false);
      }
    },
    [dispatch, collaboratorId, setUser, dependents],
  );

  const handleRemoveDependent = useCallback(
    cpf => {
      const newDependents = dependents.filter(
        dependent => dependent.dependentCpf !== cpf,
      );
      setDependents(newDependents);
    },
    [dependents],
  );

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <>
      <TabPanel value={value} index={index} {...other}>
        <TabContainer>
          <Formik
            initialValues={familyData}
            onSubmit={handleSubmitFamilyData}
            validationSchema={familyDataSchema}
            enableReinitialize
          >
            {({ isSubmitting, values }) => (
              <Form>
                <Box
                  component={Grid}
                  container
                  alignItems="center"
                  justify="space-between"
                  spacing={2}
                  p={1}
                >
                  <SubTitleForm>Estado civil e Filiação</SubTitleForm>

                  <ContainerInput item lg={3}>
                    <Field
                      component={TextField}
                      name="maritalStatus"
                      label="Estado civil"
                      variant="filled"
                      fullWidth
                    />
                  </ContainerInput>
                  <ContainerInput item lg={3}>
                    <Field
                      component={TextField}
                      name="mothersName"
                      label="Nome da mãe"
                      variant="filled"
                      fullWidth
                    />
                  </ContainerInput>
                  <ContainerInput item lg={3}>
                    <Field
                      component={TextField}
                      name="fathersName"
                      label="Nome do pai"
                      variant="filled"
                      fullWidth
                    />
                  </ContainerInput>
                  <ContainerInput item lg={3} />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    width={1}
                    mt="-8px"
                  >
                    <Field
                      component={StyledCheckbox}
                      name="hasDependents"
                      color="primary"
                      type="checkbox"
                      Label={{
                        label: 'Possui dependentes',
                      }}
                    />
                  </Box>

                  <SubTitleForm>Dependentes</SubTitleForm>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="600px"
                    mb={2}
                  >
                    {values.hasDependents ? (
                      <>
                        {dependents.map(dependent => (
                          <DependentContainer key={dependent.dependentCpf}>
                            <Box display="flex" flexDirection="column">
                              <Typography opacity="0.5" fontSize="14px">
                                {dependent.dependentName}
                              </Typography>
                              <Box display="flex" flexDirection="row">
                                <Typography
                                  opacity="0.5"
                                  fontSize="12px"
                                  fontWeight={300}
                                  mr="16px"
                                >
                                  Tipo: {dependent.dependentType}
                                </Typography>
                                <Typography
                                  opacity="0.5"
                                  fontSize="12px"
                                  fontWeight={300}
                                  mr="16px"
                                >
                                  Data de nascimento:{' '}
                                  {dependent.dependentbirthDate}
                                </Typography>
                                <Typography
                                  opacity="0.5"
                                  fontSize="12px"
                                  fontWeight={300}
                                  mr="16px"
                                >
                                  CPF: {formatCpf(dependent.dependentCpf)}
                                </Typography>
                              </Box>
                            </Box>
                            <IconButton
                              onClick={() =>
                                handleRemoveDependent(dependent.dependentCpf)
                              }
                            >
                              <img src={trashIcon} alt="" />
                            </IconButton>
                          </DependentContainer>
                        ))}
                        <DependentButton onClick={() => setOpenModal(true)}>
                          <img src={plusIcon} alt="" />
                          Adicionar dependente
                        </DependentButton>
                      </>
                    ) : (
                      <Typography
                        fontSize="18px"
                        fontWeight="bold"
                        opacity={0.5}
                        mt="16px"
                        ml="8px"
                      >
                        Sem dependentes
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box width={1} display="flex" justifyContent="flex-end" my={2}>
                  <Button
                    type="submit"
                    color="secondary"
                    disabled={isSubmitting}
                  >
                    ALTERAR DADOS
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </TabContainer>
      </TabPanel>
      <Modal open={openModal} onClose={handleCloseModal}>
        <DialogContent>
          <AddDependent
            handleClickOnClose={handleCloseModal}
            setNewDependent={setDependents}
          />
        </DialogContent>
      </Modal>
    </>
  );
};

FamilyData.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  collaboratorCpf: PropTypes.string.isRequired,
  setUser: PropTypes.func.isRequired,
};

FamilyData.defaultProps = {
  data: {},
};

export default FamilyData;
