import { ButtonBase, Divider, Grid, Paper } from '@material-ui/core';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
`;

export const StyledButtonBase = styled(ButtonBase)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({ color, theme }) =>
    color === 'error'
      ? theme.palette.error.main
      : transparentize(0.8, theme.palette.primary.main)};
  margin-right: 16px;
`;

export const TabsPaper = styled(Paper)`
  margin-top: 16px;
  min-height: 850px;
`;

export const JourneyData = styled(Grid)`
  padding: 10px;
  height: 76px;
  margin-top: 16px 0;
  background-color: #fbfbfb;
  border-radius: 6px;
  opacity: 1;
`;

export const DividerWithMargin = styled(Divider)`
  margin-right: 20px;
`;

export const StyledButton = styled(ButtonBase)`
  width: 151px;
  color: white;
  font-weight: 700;
  font-size: 10px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 35px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
`;
