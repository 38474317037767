import { FormControlLabel, Grid } from '@material-ui/core';
import styled from 'styled-components';

export const DivCheckbox = styled.div`
  width: 90%;
  height: 70px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 20px;
  }
`;

export const StyledGridButton = styled(Grid)`
  margin-top: 51px;
  margin-bottom: 23px;
`;
