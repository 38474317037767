import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Dot = styled.div`
  background-color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  opacity: 0.3;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `}
`;

export const StyledLink = styled(Link)`
  & + a {
    margin-left: 20px;
  }
`;
