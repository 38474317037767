import * as Yup from 'yup';
import parseDate from '../utils/parseDateString';

const personalDataSchema = Yup.object().shape({
  nationality: Yup.string()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  state: Yup.string()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  cityOfBirth: Yup.string()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  birthDate: Yup.date()
    .transform(parseDate)
    .min(new Date(1900, 0, 1), 'Informe uma data válida')
    .max(new Date(), 'Informe uma data válida')
    .required('Campo obrigatório')
    .typeError('Data inválida'),
  gender: Yup.string().required('Campo obrigatório'),
  raceColor: Yup.string().required('Campo obrigatório'),
  deficiency: Yup.string().required('Campo obrigatório'),
});

export default personalDataSchema;
