import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { StyledStep, StyledStatus, StyledConector } from './styles';
import ApprovedIcon from '../../assets/approved.svg';
import DisapprovedIcon from '../../assets/repproved.svg';
import UnderAnalysisIcon from '../../assets/underAnalysisIcon.svg';

const statusIcon = {
  approved: ApprovedIcon,
  disapproved: DisapprovedIcon,
  underAnalysis: UnderAnalysisIcon,
  notFilled: UnderAnalysisIcon,
};

const returnStatus = status =>
  status === 'disapproved' ? 'Reprovado' : 'Aprovado';

const Stepper = ({ statusStepper }) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        width="100%"
        fontSize="13px"
        px={1}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <StyledStep status={statusStepper.HIRING_DATA}>
            <span>1</span>
          </StyledStep>
        </Box>
        <StyledConector />
        <Box display="flex" flexDirection="column" alignItems="center">
          <StyledStep status={statusStepper.PERSONAL_DATA}>
            <span>2</span>
          </StyledStep>
        </Box>
        <StyledConector />
        <StyledStep status={statusStepper.FAMILY_DATA}>
          <span>3</span>
        </StyledStep>
        <StyledConector />
        <StyledStep status={statusStepper.DOCUMENTS}>
          <span>4</span>
        </StyledStep>
        <StyledConector />
        <StyledStep status={statusStepper.ADDRESS}>
          <span>5</span>
        </StyledStep>
        <StyledConector />
        <StyledStep status={statusStepper.BANK_DATA}>
          <span>6</span>
        </StyledStep>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={1}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <img src={statusIcon[statusStepper.HIRING_DATA]} alt="" />

          <StyledStatus status={statusStepper.HIRING_DATA}>
            {returnStatus(statusStepper.HIRING_DATA)}
          </StyledStatus>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
          <img src={statusIcon[statusStepper.PERSONAL_DATA]} alt="" />

          <StyledStatus status={statusStepper.PERSONAL_DATA}>
            {returnStatus(statusStepper.PERSONAL_DATA)}
          </StyledStatus>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
          <img src={statusIcon[statusStepper.FAMILY_DATA]} alt="" />

          <StyledStatus status={statusStepper.FAMILY_DATA}>
            {returnStatus(statusStepper.FAMILY_DATA)}
          </StyledStatus>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
          <img src={statusIcon[statusStepper.DOCUMENTS]} alt="" />

          <StyledStatus status={statusStepper.DOCUMENTS}>
            {returnStatus(statusStepper.DOCUMENTS)}
          </StyledStatus>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
          <img src={statusIcon[statusStepper.ADDRESS]} alt="" />

          <StyledStatus status={statusStepper.ADDRESS}>
            {returnStatus(statusStepper.ADDRESS)}
          </StyledStatus>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
          <img src={statusIcon[statusStepper.BANK_DATA]} alt="" />

          <StyledStatus status={statusStepper.BANK_DATA}>
            {returnStatus(statusStepper.BANK_DATA)}
          </StyledStatus>
        </Box>
      </Box>
    </>
  );
};

Stepper.propTypes = {
  statusStepper: PropTypes.shape({
    HIRING_DATA: PropTypes.oneOf([
      'approved',
      'disapproved',
      'underAnalysis',
      'notFilled',
    ]),
    PERSONAL_DATA: PropTypes.oneOf([
      'approved',
      'disapproved',
      'underAnalysis',
      'notFilled',
    ]),
    FAMILY_DATA: PropTypes.oneOf([
      'approved',
      'disapproved',
      'underAnalysis',
      'notFilled',
    ]),
    ADDRESS: PropTypes.oneOf([
      'approved',
      'disapproved',
      'underAnalysis',
      'notFilled',
    ]),
    DOCUMENTS: PropTypes.oneOf([
      'approved',
      'disapproved',
      'underAnalysis',
      'notFilled',
    ]),
    BANK_DATA: PropTypes.oneOf([
      'approved',
      'disapproved',
      'underAnalysis',
      'notFilled',
    ]),
  }).isRequired,
};

export default Stepper;
