import * as Yup from 'yup';
import { HOLIDAY_MODELS } from '../utils/enums';
import parseDate from '../utils/parseDateString';

const addHolidaySchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  type: Yup.string().required('Campo obrigatório'),
  holidayModel: Yup.string().required('Campo obrigatório'),
  date: Yup.date().when('holidayModel', {
    is: HOLIDAY_MODELS.DATE,
    then: Yup.date()
      .transform(parseDate)
      .typeError('Data Invalida')
      .required('Campo obrigatório'),
    otherwise: Yup.date().nullable(),
  }),
  period: Yup.object().when('holidayModel', {
    is: HOLIDAY_MODELS.PERIOD,
    then: Yup.object().shape({
      start: Yup.date()
        .transform(parseDate)
        .typeError('Data Invalida')
        .required('Campo obrigatório'),
      end: Yup.date()
        .transform(parseDate)
        .min(Yup.ref('start'), 'Informe uma data posterior a de entrada')
        .typeError('Data Invalida')
        .required('Campo obrigatório'),
    }),
    otherwise: Yup.object().shape({
      start: Yup.date().nullable(),
      end: Yup.date().nullable(),
    }),
  }),
});

export default addHolidaySchema;
