import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Container, TabsPaper } from './styles';
import useUser from '../../hooks/useUser';
import { Typography, Tab, Tabs } from '../../components';
import {
  EffectiveContracts,
  OtherDocuments,
  TerminationDocuments,
} from './Tabs';
import { STATUS } from '../../utils/enums';

const ColaboratorContracts = () => {
  const [tabValue, setTabValue] = useState(0);
  const [isDismissal, setIsDismissal] = useState(false);
  const { userId: collaboratorId } = useParams();
  const [effectiveContracts, setEffectiveContracts] = useState([]);
  const [otherContracts, setOtherContracts] = useState([]);
  const [terminationDocuments, setTerminationDocuments] = useState([]);
  const { user: userData } = useUser(collaboratorId);

  useEffect(() => {
    if (userData) {
      const { contracts } = userData;
      const { dismissalDocuments } = userData;

      if (contracts) {
        setEffectiveContracts([
          {
            ...contracts.employementContract,
            documentDisplayName: 'Contrato de Trabalho',
          },
          {
            ...contracts.effectiveTrialPeriod,
            documentDisplayName: 'Efetivação de período de experiência',
          },
          {
            ...contracts.extensionTrialPeriod,
            documentDisplayName: 'Prorrogação de período de experiência',
          },
          {
            ...contracts.discountAuthorization,
            documentDisplayName: 'Autorização de desconto em folha',
          },
        ]);
        setOtherContracts([
          {
            ...contracts.otherDocuments,
            documentDisplayName: 'Outros Documentos',
          },
          {
            ...contracts.individualOrCollectiveAgreements,
            documentDisplayName: 'Acordos Individuais ou Coletivos',
          },
          {
            ...contracts.terminationExperienceContract,
            documentDisplayName: 'Rescisão de contrato de experiência',
          },
        ]);
      }

      if (dismissalDocuments) {
        setTerminationDocuments([
          {
            ...dismissalDocuments.FGTSGuide,
            documentDisplayName: 'Guia FGTS',
          },

          {
            ...dismissalDocuments.terminationTerm,
            documentDisplayName: 'Termo de rescisão',
          },

          {
            ...dismissalDocuments.unemploymentInsuranceGuide,
            documentDisplayName: 'Guia seguro desemprego',
          },

          {
            ...dismissalDocuments.paymentReceipts,
            documentDisplayName: 'Comprovantes de pagamento',
          },

          {
            ...dismissalDocuments.otherDocuments,
            documentDisplayName: 'Outros Documentos',
          },
        ]);
      }
      setIsDismissal(userData.status === STATUS.DISMISSAL);
    }
  }, [userData]);

  const handleChangeTab = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);
  return (
    <Container>
      <Box py={2}>
        <Typography fontSize="40px" fontWeight="bold">
          Contratos
        </Typography>
      </Box>
      <Box>
        <TabsPaper square elevation={1}>
          <Tabs value={tabValue} onChange={handleChangeTab}>
            <Tab label="CONTRATOS DE EFETIVAÇÃO" index={0} />
            <Tab label="OUTROS DOCUMENTOS" index={1} />
            <Tab disabled={!isDismissal} label="RESCISÃO" index={2} />
          </Tabs>
          <EffectiveContracts
            contracts={effectiveContracts}
            value={tabValue}
            index={0}
          />
          <OtherDocuments
            contracts={otherContracts}
            value={tabValue}
            index={1}
          />
          <TerminationDocuments
            contracts={terminationDocuments}
            value={tabValue}
            index={2}
          />
        </TabsPaper>
      </Box>
    </Container>
  );
};

export default ColaboratorContracts;
