import styled from 'styled-components';

export const RenderBox = styled.div`
  width: 700px;
  min-height: 200px;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  padding: 20px 20px 10px 40px;
  overflow-y: auto;
`;
