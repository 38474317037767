import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container } from './styles';
import { Sidebar } from '../../components';
import {
  ColaboratorScore,
  ColaboratorData,
  ColaboratorContracts,
  ColaboratorJourney,
} from '../index';

const ColaboratorHome = () => {
  return (
    <Container>
      <Sidebar colaborator />
      <div className="content">
        <Switch>
          <Route
            path="/colaborator-home/score/:userId"
            exact
            component={ColaboratorScore}
            isPrivate
            authorization={[]}
          />
          <Route
            path="/colaborator-home/score/journey/:userId"
            exact
            component={ColaboratorJourney}
            isPrivate
            authorization={[]}
          />
          <Route
            path="/colaborator-home/data/:userId"
            exact
            component={ColaboratorData}
            isPrivate
            authorization={[]}
          />

          <Route
            path="/colaborator-home/contracts/:userId"
            exact
            component={ColaboratorContracts}
            isPrivate
            authorization={[]}
          />
        </Switch>
      </div>
    </Container>
  );
};

export default ColaboratorHome;
