import DateFnsUtils from '@date-io/date-fns';
import { MenuItem, TextField as TextFieldMaterialUi } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { addHours, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardTimePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import Clock from '../../../../../assets/Clock.svg';
import { Grid, Typography } from '../../../../../components';
import { SCALE_TYPES } from '../../../../../utils/enums';
import {
  calculateNightAdditional,
  calculateTimeDifference,
} from '../../../../../utils/journeyScaleCalculateFunctions';
import { ContainerInput } from '../../styles';

const StandardDayScale = props => {
  const { timeOptions, values, setFieldValue } = props;
  const isScaleWithFixedTime =
    values.scaleType === SCALE_TYPES['12/36'] ||
    values.scaleType === SCALE_TYPES['24/48'];
  const DAYS_WORKED_ON_WEEK = values.scaleType === SCALE_TYPES['12/36'] ? 4 : 6;
  const calculateExitTime = useCallback(
    enterTime => {
      if (values.scaleType === SCALE_TYPES['12/36']) {
        return addHours(enterTime, 12);
      }
      return addHours(enterTime, 24);
    },
    [values.scaleType],
  );

  useEffect(() => {
    if (isScaleWithFixedTime) {
      if (isValid(values.standardDayScale.standardDay.enterTime)) {
        setFieldValue(
          'standardDayScale.standardDay.exitTime',
          calculateExitTime(values.standardDayScale.standardDay.enterTime),
        );
      } else {
        setFieldValue('standardDayScale.standardDay.exitTime', '');
      }
      setFieldValue('holidays', 'true');
    }
  }, [
    setFieldValue,
    calculateExitTime,
    isScaleWithFixedTime,
    values.standardDayScale.standardDay.enterTime,
  ]);

  useEffect(() => {
    const { scaleType } = values;
    const { standardDay } = values.standardDayScale;
    if (scaleType === SCALE_TYPES['24/48']) {
      setFieldValue('totalHours', '72:00');
      setFieldValue('nightAdditional', '21:00');
    } else if (scaleType === SCALE_TYPES['12/36']) {
      setFieldValue('totalHours', '48:00');
    } else {
      const timeDifference = calculateTimeDifference(
        standardDay.enterTime,
        standardDay.exitTime,
        standardDay.mainBreak,
        DAYS_WORKED_ON_WEEK,
      );
      const weekWorkedHours = timeDifference.hours;
      const weekWorkedMinutes = timeDifference.minutes;
      const formattedStringTime = `${weekWorkedHours
        .toString()
        .padStart(2, '0')}:${weekWorkedMinutes.toString().padStart(2, '0')}`;
      setFieldValue('totalHours', formattedStringTime);
    }

    if (scaleType !== SCALE_TYPES['24/48']) {
      const mainBreak =
        scaleType === SCALE_TYPES['12/36'] ? 0 : standardDay.mainBreak;
      const nightAdditional = calculateNightAdditional(
        standardDay.enterTime,
        standardDay.exitTime,
        mainBreak,
        DAYS_WORKED_ON_WEEK,
      );
      const weekNightWorkedHours = nightAdditional.hours;
      const weekNightWorkedMinutes = nightAdditional.minutes;
      const formattedStringTime = `${weekNightWorkedHours
        .toString()
        .padStart(2, '0')}:${weekNightWorkedMinutes
        .toString()
        .padStart(2, '0')}`;
      setFieldValue('nightAdditional', formattedStringTime);
    }
  }, [setFieldValue, values, DAYS_WORKED_ON_WEEK]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <Grid container spacing={3} alignItems="center" mt="40px">
        <Grid item xs={3}>
          <Typography color="secondary" fontSize="0.875rem">
            Dias e Horários
          </Typography>
        </Grid>
        <Grid
          container
          spacing={3}
          item
          xs={9}
          alignItems="center"
          justify="flex-end"
        >
          <Grid item>
            <Typography color="secondary" fontSize="0.875rem">
              Total de horas semanais:
            </Typography>
          </Grid>
          <Grid item>
            <Typography opacity={0.5} color="secondary" fontSize="1.25rem">
              {values.totalHours} horas
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="secondary" fontSize="0.875rem">
              Adicional noturno:
            </Typography>
          </Grid>
          <Grid item>
            <Typography opacity={0.5} color="secondary" fontSize="1.25rem">
              {values.nightAdditional} horas
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justify="space-between" mt="40px">
        <Grid container item xs={6} spacing={3} alignItems="center">
          <Grid item xs={2}>
            <Typography color="secondary" fontSize="0.875rem">
              {(values.scaleType === SCALE_TYPES['12/36'] ||
                values.scaleType === SCALE_TYPES['24/48']) &&
                'Dia trabalhado'}

              {values.scaleType === SCALE_TYPES['5x1'] && 'Horário 5 dias'}
            </Typography>
          </Grid>

          <ContainerInput item xs>
            <Field
              component={KeyboardTimePicker}
              keyboardIcon={<img src={Clock} alt="" />}
              name="standardDayScale.standardDay.enterTime"
              label="Entrada"
              inputVariant="filled"
              cancelLabel="CANCELAR"
              invalidDateMessage="Horário Invalido"
              ampm={false}
              placeholder="00:00"
              fullWidth
            />
          </ContainerInput>
          <ContainerInput item xs>
            <Field
              component={KeyboardTimePicker}
              keyboardIcon={
                isScaleWithFixedTime ? null : <img src={Clock} alt="" />
              }
              name="standardDayScale.standardDay.exitTime"
              label="Saida"
              inputVariant="filled"
              cancelLabel="CANCELAR"
              invalidDateMessage="Horário Invalido"
              disabled={isScaleWithFixedTime}
              ampm={false}
              placeholder="00:00"
              fullWidth
            />
          </ContainerInput>
          <ContainerInput item xs>
            <Field
              component={TextField}
              select
              name="standardDayScale.standardDay.mainBreak"
              label="Intervalo principal"
              variant="filled"
              fullWidth
            >
              {timeOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>
          </ContainerInput>
        </Grid>
      </Grid>

      <Grid container item xs={6} spacing={3} alignItems="center" mt="40px">
        <Grid item xs={2}>
          <Typography color="secondary" fontSize="0.875rem">
            {values.scaleType === SCALE_TYPES['12/36'] && 'Próximo dia'}
            {values.scaleType === SCALE_TYPES['24/48'] && 'Próximos dois dias'}
            {values.scaleType === SCALE_TYPES['5x1'] && '6º dia'}
          </Typography>
        </Grid>
        <ContainerInput item xs>
          <TextFieldMaterialUi
            disabled
            label={
              values.scaleType === SCALE_TYPES['24/48']
                ? 'FOLGA A PARTIR 08:00 hrs'
                : 'FOLGA'
            }
            variant="filled"
            fullWidth
          />
        </ContainerInput>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

StandardDayScale.propTypes = {
  timeOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default StandardDayScale;
