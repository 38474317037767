import * as Yup from 'yup';

const bankDataSchema = Yup.object().shape({
  bank: Yup.string()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  typeAccount: Yup.string().required('Campo obrigatório'),
  account: Yup.string()
    .required('Campo obrigatório')
    .max(13, 'A conta deve ter um máximo de 13 números'),
  agency: Yup.string()
    .required('Campo obrigatório')
    .max(5, 'A agencia deve tem um máximo de 5 números'),
  digit: Yup.string()
    .required('Campo obrigatório')
    .max(2, 'O dígito verificador da conta deve ter no máximo 2 alfanúmericos'),
});

export default bankDataSchema;
