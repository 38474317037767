import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '../Typography';
import { StyledDialogContent } from './styles';

const DeleteModal = ({
  title,
  description,
  modalStatus,
  handleClose,
  handleDelete,
}) => (
  <Dialog open={modalStatus} onClose={handleClose}>
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <StyledDialogContent>
      <DialogContentText id="alert-dialog-description">
        {description}
      </DialogContentText>
    </StyledDialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary" size="small">
        <Typography fontSize="0.9375rem" fontWeight="bold" opacity="0.7">
          CANCELAR
        </Typography>
      </Button>
      <Button onClick={handleDelete} color="secondary" size="small">
        <Typography fontSize="0.9375rem" fontWeight="bold">
          Confirmar
        </Typography>
      </Button>
    </DialogActions>
  </Dialog>
);

DeleteModal.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  modalStatus: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default DeleteModal;
