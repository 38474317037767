import DateFnsUtils from '@date-io/date-fns';
import { TextField as TextFieldMaterialUi } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { isValid } from 'date-fns/esm';
import { ptBR } from 'date-fns/locale';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import formatTimeString from '../../../../utils/formatTimeString';
import getMinutesFromStringTime from '../../../../utils/getMinutesFromStringTime';
import { StyledGrid } from '../styles';

const CompensationInfo = ({
  values,
  initialAllTimeCompTime,
  userId,
  setFieldValue,
}) => {
  const isValidDate = isValid(values.compensationDate);
  const dispatch = useDispatch();
  useEffect(() => {
    async function getPlannedHoursFromSelectedDay() {
      try {
        if (isValidDate) {
          const api = AuthenticatedHttpClient();
          const { data: minutesWorkedOnDay } = await api.get(
            `comp-time/workTimeByDay/${userId}`,
            {
              params: {
                date: values.compensationDate,
              },
            },
          );
          const formattedHoursCompensated = formatTimeString(
            minutesWorkedOnDay,
          );
          setFieldValue('dayHoursCompensated', formattedHoursCompensated);

          const newCollaboratorCompTime =
            getMinutesFromStringTime(initialAllTimeCompTime) -
            minutesWorkedOnDay;
          if (newCollaboratorCompTime >= 0) {
            const formattedCollaboratorCompTime = formatTimeString(
              newCollaboratorCompTime,
            );
            setFieldValue(
              'formattedAllTimeCompTime',
              formattedCollaboratorCompTime,
            );
          }
        }
      } catch (error) {
        if (error.response?.data?.message) {
          dispatch(setSnackbar(true, 'error', error.response.data.message));
        } else {
          dispatch(
            setSnackbar(
              true,
              'error',
              'Não foi possivel calcular o horario compensado',
            ),
          );
        }
        setFieldValue('dayHoursCompensated', '00:00');
        setFieldValue('formattedAllTimeCompTime', initialAllTimeCompTime);
      }
    }
    getPlannedHoursFromSelectedDay();
  }, [
    dispatch,
    userId,
    values.compensationDate,
    isValidDate,
    setFieldValue,
    initialAllTimeCompTime,
  ]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <>
        <StyledGrid item xs={6}>
          <TextFieldMaterialUi
            name="formattedAllTimeCompTime"
            label="Total de horas no banco"
            variant="filled"
            value={`${values.formattedAllTimeCompTime} horas`}
            disabled
            fullWidth
          />
        </StyledGrid>
        <StyledGrid item xs={6}>
          <Field
            component={KeyboardDatePicker}
            name="compensationDate"
            label="Data da compensação"
            inputVariant="filled"
            cancelLabel="CANCELAR"
            disablePast
            invalidDateMessage="Data inválida"
            format="dd/MM/yyyy"
            fullWidth
          />
        </StyledGrid>
        {isValidDate && (
          <StyledGrid item xs={12}>
            <Field
              component={TextField}
              name="dayHoursCompensated"
              label="Horas que serão compensadas"
              variant="filled"
              fullWidth
              disabled
            />
          </StyledGrid>
        )}

        <StyledGrid item xs={12}>
          <Field
            component={TextField}
            name="justification"
            label="Justificativa"
            variant="filled"
            fullWidth
          />
        </StyledGrid>
      </>
    </MuiPickersUtilsProvider>
  );
};

CompensationInfo.propTypes = {
  initialAllTimeCompTime: PropTypes.string.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  userId: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default CompensationInfo;
