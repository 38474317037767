import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import eyeIcon from '../../../assets/eye.svg';
import { EmptyState, Tooltip, Typography } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { STATUS } from '../../../utils/enums';
import { StyledIconButton, StyledTableCell, TypoGrid } from './styles';

const headCells = [
  {
    id: 'fullName',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'jobRole.name',
    numeric: false,
    disablePadding: false,
    label: 'Cargo',
  },
  {
    id: 'departament.name',
    numeric: false,
    disablePadding: false,
    label: 'Departamento',
  },
  {
    id: 'resignationDate',
    numeric: false,
    disablePadding: false,
    label: 'Data de demissão',
  },
  {
    id: 'statusDismissal',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'departureDate',
    numeric: false,
    disablePadding: false,
    label: 'Data da saída',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Ação</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTable = ({
  textFilter,
  jobRoleFilter,
  departmentFilter,
  statusFilter,
}) => {
  const [dataTable, setDataTable] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const dispatch = useDispatch();
  const { user } = useAuth();
  const history = useHistory();

  const statusDismissal = useCallback(
    status =>
      ({
        FULFILLING_NOTICE: 'Cumprindo aviso',
        START_OF_THE_PROCESS: 'Início do processo',
        DISMISSED: 'Desligado',
      }[status]),
    [],
  );

  useEffect(() => {
    async function getUsers() {
      try {
        dispatch(setLoading(true));
        setIsLoading(true);
        const api = AuthenticatedHttpClient();
        const response = await api.get(`users/company/${user.company._id}`, {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            status: STATUS.DISMISSAL,
            text: textFilter,
            jobRole: jobRoleFilter.trim(),
            department: departmentFilter.trim(),
            statusDismissal: statusFilter.trim(),
            order,
            orderBy,
          },
        });

        const responseData = response.data.docs.map(userInfo => {
          const {
            dismissalDetails: { shutDownData },
          } = userInfo;

          let resignationDate = '---';
          let departureDate = '---';

          if (shutDownData && shutDownData.resignationDate) {
            resignationDate = new Date(
              shutDownData.resignationDate,
            ).toLocaleDateString('pt-br');
          }

          if (shutDownData && shutDownData.departureDate) {
            departureDate = new Date(
              shutDownData.departureDate,
            ).toLocaleDateString('pt-br');
          }

          return {
            _id: userInfo._id,
            fullName: userInfo.fullName,
            jobRole: userInfo.jobRole.name,
            department: (userInfo.department && userInfo.department.name) || '',
            resignationDate,
            statusDismissal: statusDismissal(userInfo.statusDismissal) || '',
            departureDate,
          };
        });
        setUsersCount(response.data.total);
        setDataTable(responseData);
      } catch (error) {
        dispatch(
          setSnackbar(
            true,
            'error',
            'Não foi possível buscar os colaboradores',
          ),
        );
      } finally {
        dispatch(setLoading(false));
        setIsLoading(false);
      }
    }

    getUsers();
  }, [
    user,
    rowsPerPage,
    page,
    order,
    orderBy,
    textFilter,
    statusDismissal,
    jobRoleFilter,
    departmentFilter,
    statusFilter,
    dispatch,
  ]);

  const handleRedirect = useCallback(
    userId => {
      history.push(`dismissal/collaborator/${userId}`);
    },
    [history],
  );

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading) return '';
  const MAX_STRING_LENGTH = 35;

  return (
    <>
      {!usersCount ? (
        <EmptyState description="Nenhum colaborador encontrado!" />
      ) : (
        <div>
          <Paper elevation={0}>
            <TypoGrid container alignItems="center" justify="space-between">
              <Typography variant="h3">
                COLABORADORES DESLIGADOS OU EM PROCESSO DE DEMISSÃO
              </Typography>
            </TypoGrid>

            <TableContainer>
              <Table size="small">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {dataTable.map(row => {
                    return (
                      <TableRow hover tabIndex={-1} key={row._id}>
                        <StyledTableCell component="th" scope="row">
                          <Tooltip
                            fullText={row.fullName}
                            maxStringLength={MAX_STRING_LENGTH}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Tooltip
                            fullText={row.jobRole}
                            maxStringLength={MAX_STRING_LENGTH}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Tooltip
                            fullText={row.department}
                            maxStringLength={MAX_STRING_LENGTH}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.resignationDate}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.statusDismissal}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          opacity={row.departureDate === '---'}
                        >
                          {row.departureDate}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          <StyledIconButton
                            onClick={() => handleRedirect(row._id)}
                          >
                            <img src={eyeIcon} alt="" />
                          </StyledIconButton>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={usersCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}
    </>
  );
};

EnhancedTable.propTypes = {
  textFilter: PropTypes.string.isRequired,
  jobRoleFilter: PropTypes.string.isRequired,
  departmentFilter: PropTypes.string.isRequired,
  statusFilter: PropTypes.string.isRequired,
};

export default EnhancedTable;
