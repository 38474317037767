import { Button, Grid, Modal, Paper, Typography } from '@material-ui/core';
import { AddSharp } from '@material-ui/icons';
import React, { useState } from 'react';
import { SearchInput, SelectFilter } from '../../components';
import { HOLIDAY_MODELS, HOLIDAY_TYPES } from '../../utils/enums';
import TableHolidays from './Components/TableHolidays';
import AddHolidayModal from './Modals/AddHolidayModal';
import {
  Container,
  HolidaysContainer,
  SelectDiv,
  StyledIconButton,
  StyledTab,
  StyledTabs,
} from './styles';

const typeOptions = [
  {
    label: 'TIPO',
    value: ' ',
  },
  {
    label: 'NACIONAL',
    value: HOLIDAY_TYPES.NATIONAL,
  },
  {
    label: 'MUNICIPAL',
    value: HOLIDAY_TYPES.MUNICIPAL,
  },
  {
    label: 'ESTADUAL',
    value: HOLIDAY_TYPES.STATE,
  },
];

const modelOptions = [
  {
    label: 'MODELO',
    value: ' ',
  },
  {
    label: 'Período',
    value: HOLIDAY_MODELS.PERIOD,
  },
  {
    label: 'Data',
    value: HOLIDAY_MODELS.DATE,
  },
];

const Holidays = () => {
  const [searchText, setSearchText] = useState('');
  const [openAddHolidayModal, setOpenAddHolidayModal] = useState(false);

  const [typeSelected, setTypeSelected] = useState(' ');
  const [modelSelected, setModelSelected] = useState(' ');
  const [forceGetHolidays, setForceGetHolidays] = useState(false);

  return (
    <Container>
      <Typography variant="h2">Feriados</Typography>
      <Paper elevation={1}>
        <StyledTabs value={1} indicatorColor="primary">
          <StyledTab label="FERIADOS" value={1} />
        </StyledTabs>
        <HolidaysContainer>
          <Grid container alignItems="center" justify="space-between">
            <SearchInput value={searchText} handleInput={setSearchText} />
            <div>
              <StyledIconButton onClick={() => setOpenAddHolidayModal(true)}>
                <AddSharp />
              </StyledIconButton>
              <Button
                color="primary"
                onClick={() => setOpenAddHolidayModal(true)}
              >
                ADICIONAR NOVO FERIADO
              </Button>
              <Modal
                open={openAddHolidayModal}
                onClose={() => setOpenAddHolidayModal(false)}
                aria-labelledby="start-resignation-title"
                aria-describedby="simple-modal-description"
              >
                <AddHolidayModal
                  handleClose={() => setOpenAddHolidayModal(false)}
                  setForceGetHolidays={setForceGetHolidays}
                />
              </Modal>
            </div>
          </Grid>
          <SelectDiv>
            <SelectFilter
              value={typeSelected}
              handleChange={setTypeSelected}
              selectOptions={typeOptions}
            />
            <SelectFilter
              value={modelSelected}
              handleChange={setModelSelected}
              selectOptions={modelOptions}
            />
          </SelectDiv>

          <TableHolidays
            textFilter={searchText}
            typeFilter={typeSelected}
            modelFilter={modelSelected}
            newHolidayAdded={forceGetHolidays}
          />
        </HolidaysContainer>
      </Paper>
    </Container>
  );
};

export default Holidays;
