import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 15px;
  width: 100%;
  height: 52px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;

  img {
    width: 100%;
    max-width: 19px;
    max-height: 100%;
  }
`;

export const StyledSpan = styled.span`
  ${props => css`
    font-weight: ${props.fontWeight};
    font-size: ${props.fontSize}px;
  `}
`;
