import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box, ButtonBase, MenuItem, TextField } from '@material-ui/core';
import { format, getMonth, getYear } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import arrowLeftCalendar from '../../../assets/arrow-left-calendar.svg';
import arrowRightCalendar from '../../../assets/arrow-right-calendar.svg';
import { Grid, TabPanel, Typography } from '../../../components';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { DividerWithMargin, StyledButton } from '../styles';

const ACTIONS_TYPES = {
  SET_MONTH_AND_YEAR: 'SET_MONTH_AND_YEAR',
};

const DateReducer = (currentDate, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.SET_MONTH_AND_YEAR:
      return { ...currentDate, year: action.year, month: action.month };

    default:
      return { currentDate };
  }
};

const Scale = props => {
  const { value, index } = props;

  const calendarRef = useRef();
  const [actualGridOption, setActualGridOption] = useState('month');
  const gridOptions = [
    { label: 'Mês', value: 'month' },
    { label: 'Semana', value: 'week' },
  ];
  const [events, setEvents] = useState([]);

  const [actualDate, dispatchDate] = useReducer(DateReducer, {
    month: getMonth(new Date()),
    year: getYear(new Date()),
  });
  const [actualFormattedDate, setActualFormattedDate] = useState(null);
  const [height, setHeight] = useState('800px');
  const [isTodayButtonVisible, setIsTodayButtonVisible] = useState(true);
  const dispatch = useDispatch();

  const formatScaleEvents = scale => {
    const scaleZonedTime = scale.map(actualEvent => {
      return {
        ...actualEvent,
        start: utcToZonedTime(actualEvent.start, 'America/Sao_Paulo'),
        end: utcToZonedTime(actualEvent.end, 'America/Sao_Paulo'),
      };
    });

    const scaleHolidaysAndDayOff = scaleZonedTime.filter(actualEvent => {
      return actualEvent.title !== 'Dia de trabalho';
    });

    const scaleWorkday = scaleZonedTime.filter(actualEvent => {
      return actualEvent.title === 'Dia de trabalho';
    });

    const holidaysAndDayOffEvents = scaleHolidaysAndDayOff.map(actualEvent => {
      const startDay = new Date(actualEvent.start)
        .toISOString()
        .replace(/T.*$/, '');
      const endDay = new Date(actualEvent.end)
        .toISOString()
        .replace(/T.*$/, '');
      return actualEvent.title !== 'Feriado'
        ? {
            ...actualEvent,
            start: startDay,
            end: endDay,
            display: 'background',
            color: '#ccc',
          }
        : {
            ...actualEvent,
            start: startDay,
            end: endDay,
            display: 'background',
            color: '#AEE9BC',
          };
    });

    return scaleWorkday.concat(holidaysAndDayOffEvents);
  };

  useEffect(() => {
    const getCollaboratorScale = async () => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();
        const { data: collaboratorScale } = await api.get(
          `/scale/month-scale`,
          {
            params: {
              month: actualDate.month + 1,
              year: actualDate.year,
            },
          },
        );
        const eventsFormatted = formatScaleEvents(collaboratorScale);
        setEvents(eventsFormatted);
      } catch (e) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possivel buscar a escala'),
        );
      } finally {
        dispatch(setLoading(false));
      }
    };
    getCollaboratorScale();
  }, [dispatch, actualDate]);

  const handleAdjustTitle = date => {
    const namedDate = format(date, "MMMM 'de'  yyyy", {
      locale: ptBR,
    });
    const formatedDate = namedDate.charAt(0).toUpperCase() + namedDate.slice(1);
    setActualFormattedDate(formatedDate);
    const today = new Date();

    const visible =
      date.getMonth() === today.getMonth() &&
      date.getYear() === today.getYear();

    setIsTodayButtonVisible(!visible);
  };

  useEffect(() => {
    if (value !== 1) {
      setActualGridOption('month');
      setHeight('800px');
      handleAdjustTitle(new Date());
    }
  }, [value]);

  const handlePrevious = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    handleAdjustTitle(calendarApi.getDate());
    dispatchDate({
      type: ACTIONS_TYPES.SET_MONTH_AND_YEAR,
      month: getMonth(calendarApi.getDate()),
      year: getYear(calendarApi.getDate()),
    });
  };

  const handleNext = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    handleAdjustTitle(calendarApi.getDate());
    dispatchDate({
      type: ACTIONS_TYPES.SET_MONTH_AND_YEAR,
      month: getMonth(calendarApi.getDate()),
      year: getYear(calendarApi.getDate()),
    });
  };

  const handleToday = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    handleAdjustTitle(calendarApi.getDate());
    dispatchDate({
      type: ACTIONS_TYPES.SET_MONTH_AND_YEAR,
      month: getMonth(calendarApi.getDate()),
      year: getYear(calendarApi.getDate()),
    });
  };

  const handleGridChange = event => {
    const optionSelected =
      event.target.value === 'week' ? 'timeGridWeek' : 'dayGridMonth';

    const actualHeight = event.target.value === 'week' ? 'auto' : '800px';
    setHeight(actualHeight);
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(optionSelected);

    setActualGridOption(event.target.value);
  };

  return (
    <TabPanel value={value} index={index}>
      <Box p={3}>
        <Grid
          container
          xs={12}
          justify="space-between"
          alignItems="center"
          mt="16px"
        >
          <Grid item xs={5} xl={3}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={1}>
                <ButtonBase onClick={handlePrevious}>
                  <img src={arrowLeftCalendar} alt="previous Button" />
                </ButtonBase>
              </Grid>
              <Grid item xs={1}>
                <ButtonBase onClick={handleNext}>
                  <img src={arrowRightCalendar} alt="next Button" />
                </ButtonBase>
              </Grid>
              <Grid item xs={5}>
                <Typography display="inline">{actualFormattedDate} </Typography>
              </Grid>
              <DividerWithMargin flexItem orientation="vertical" />
              {isTodayButtonVisible && (
                <Grid item xs={4}>
                  <StyledButton
                    size="small"
                    onClick={handleToday}
                    variant="contained"
                  >
                    MES ATUAL
                  </StyledButton>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <TextField
              select
              name="gridType"
              variant="filled"
              fullWidth
              onChange={handleGridChange}
              value={actualGridOption}
            >
              {gridOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container xs={12} mt="16px">
          <Grid item xs={12}>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin]}
              height={height}
              headerToolbar={false}
              initialView="dayGridMonth"
              ref={calendarRef}
              locale="pt-br"
              firstDay={1}
              allDayText="Dia todo"
              events={events}
            />
          </Grid>
        </Grid>
      </Box>
    </TabPanel>
  );
};

Scale.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default Scale;
