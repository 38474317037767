import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import eyeIcon from '../../../assets/eye.svg';
import { EmptyState, Tooltip } from '../../../components';
import { StyledIconButtonBlue, StyledTableCell } from './styles';

const headCells = [
  {
    id: 'fullName',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'workday',
    numeric: false,
    disablePadding: false,
    label: 'Jornada',
  },
  {
    id: 'workedDays',
    numeric: true,
    disablePadding: false,
    label: 'Dias trabalhados',
  },
  {
    id: 'faults',
    numeric: true,
    disablePadding: false,
    label: 'Faltas',
  },
  {
    id: 'plannedMinutesByPeriod',
    numeric: false,
    disablePadding: false,
    label: 'Horas planejadas',
  },
  {
    id: 'minutesWorkedByPeriod',
    numeric: true,
    disablePadding: false,
    label: 'Horas trabalhadas',
  },
  {
    id: 'alerts',
    numeric: true,
    disablePadding: false,
    label: 'Alertas',
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Ação</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTable = ({ setFilters, recordsData }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('fullName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const history = useHistory();
  const { path } = useRouteMatch();

  const formattedRecordsData = recordsData.docs.map(record => {
    const hoursWorked = Math.floor(record.minutesWorkedByPeriod / 60);
    const minutesWorked = record.minutesWorkedByPeriod % 60;

    const hoursPlanned = Math.floor(record.plannedMinutesByPeriod / 60);
    const minutesPlanned = record.plannedMinutesByPeriod % 60;

    return {
      ...record,
      minutesWorkedByPeriod: `${String(hoursWorked).padStart(2, '0')}:${String(
        minutesWorked,
      ).padStart(2, '0')}`,
      plannedMinutesByPeriod: `${String(hoursPlanned).padStart(
        2,
        '0',
      )}:${String(minutesPlanned).padStart(2, '0')}`,
    };
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setFilters(oldState => ({
      ...oldState,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilters(oldState => ({
      ...oldState,
      page: newPage + 1,
    }));
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilters(oldState => ({
      ...oldState,
      limit: parseInt(event.target.value, 10),
      page: 1,
    }));
  };

  const handleGoToUserPointMirror = userData => {
    history.push({
      pathname: `${path}/${userData._id}/point-mirror`,
      state: { user: userData },
    });
  };

  const MAX_STRING_LENGTH = 35;

  return !formattedRecordsData.length ? (
    <EmptyState description="Nenhum colaborador encontrado!" />
  ) : (
    <Box component={Paper} elevation={0} overflow="visible" mt={2}>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={recordsData.total}
          />
          <TableBody>
            {formattedRecordsData.map(row => {
              return (
                <TableRow key={row._id}>
                  <StyledTableCell align="left">
                    <Tooltip
                      fullText={row.fullName}
                      maxStringLength={MAX_STRING_LENGTH}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Tooltip
                      fullText={row.workday}
                      maxStringLength={MAX_STRING_LENGTH}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.workedDays}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.faults}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.plannedMinutesByPeriod}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.minutesWorkedByPeriod}
                  </StyledTableCell>
                  <StyledTableCell align="left" hasAlerts={row.alerts > 0}>
                    {row.alerts}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <StyledIconButtonBlue
                      onClick={() => handleGoToUserPointMirror(row)}
                    >
                      <img src={eyeIcon} alt="" />
                    </StyledIconButtonBlue>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={recordsData.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
};

EnhancedTable.propTypes = {
  setFilters: PropTypes.func.isRequired,
  recordsData: PropTypes.shape({
    docs: PropTypes.arrayOf(PropTypes.any),
    limit: PropTypes.number,
    page: PropTypes.number,
    pages: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
};

export default EnhancedTable;
