import { TextField } from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ALERT_TYPES, POINT_REGISTER_TYPE } from '../../../../utils/enums';
import { StyledGrid } from '../styles';

const PointAdjustInfo = ({ values }) => {
  const { pointAlert, justification, registerDate } = values;
  const alertTypeLabel = {
    [ALERT_TYPES.FAULT]: 'Falta',
    [ALERT_TYPES.PENDENT]: 'Pendência de ponto',
  };

  const pointTypeLabels = {
    [POINT_REGISTER_TYPE.BREAK_ENTRY]: 'Ida',
    [POINT_REGISTER_TYPE.BREAK_EXIT]: 'Volta',
    [POINT_REGISTER_TYPE.ENTRY]: 'Entrada',
    [POINT_REGISTER_TYPE.EXIT]: 'Saída',
    [POINT_REGISTER_TYPE.MAIN_BREAK_ENTRY]: 'Intervalo principal - Ida',
    [POINT_REGISTER_TYPE.MAIN_BREAK_EXIT]: 'Intervalo principal - Volta',
  };

  const labelPoint = useMemo(() => {
    let description = 'Nenhum registro de ponto';

    if (pointAlert.alertType === ALERT_TYPES.PENDENT && pointAlert.breakName) {
      description = `${pointAlert.breakName} - ${
        pointTypeLabels[pointAlert.pointType]
      }`;
    } else if (
      pointAlert.alertType === ALERT_TYPES.PENDENT &&
      !pointAlert.breakName
    ) {
      description = pointTypeLabels[pointAlert.pointType];
    }

    return description;
  }, [
    pointAlert.alertType,
    pointAlert.breakName,
    pointAlert.pointType,
    pointTypeLabels,
  ]);

  return (
    <>
      <StyledGrid item xs={6}>
        <TextField
          name="pointType"
          label="Ponto"
          variant="filled"
          disabled
          value={labelPoint}
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item xs={6}>
        <TextField
          name="alerType"
          label="Tipo do Alerta"
          variant="filled"
          disabled
          value={alertTypeLabel[pointAlert.alertType]}
          fullWidth
        />
      </StyledGrid>
      {registerDate ? (
        <StyledGrid item xs={12}>
          <TextField
            name="registerDate"
            label="Horário desejado"
            variant="filled"
            disabled
            value={format(new Date(registerDate), 'dd/MM/yyyy HH:mm')}
            fullWidth
          />
        </StyledGrid>
      ) : (
        <StyledGrid item xs={12}>
          <TextField
            name="pointStartDate"
            label="Data que será ajustada"
            variant="filled"
            disabled
            value={format(new Date(pointAlert.pointStartDate), 'dd/MM/yyyy ')}
            fullWidth
          />
        </StyledGrid>
      )}

      <StyledGrid item xs={12}>
        <TextField
          name="justification"
          label="Justificativa"
          variant="filled"
          disabled
          value={justification}
          fullWidth
        />
      </StyledGrid>
    </>
  );
};

PointAdjustInfo.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PointAdjustInfo;
