import { DialogContent, Modal } from '@material-ui/core';
import { Timeline, TimelineContent, TimelineSeparator } from '@material-ui/lab';
import {
  addMinutes,
  format,
  isAfter,
  isBefore,
  isWithinInterval,
  set,
  subMinutes,
} from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Grid, Typography } from '../../../../components';
import { POINT_REGISTER_TYPE, SITUATION_POINTS } from '../../../../utils/enums';
import getSetDate from '../../../../utils/getSetDate';
import RegisterPointModal from '../../Modals/RegisterPointModal';
import {
  DividerWithMargin,
  RegisterPointContainer,
  StyledButton,
  StyledITimelineItem,
  StyledTimelineConnector,
  StyledTimelineDot,
} from './styles';

const situationLabel = {
  [SITUATION_POINTS.REGISTERED]: 'Registrado com sucesso!',
  [SITUATION_POINTS.PENDING]: 'Pendente',
  [SITUATION_POINTS.WAITING]: 'Aguardando registro',
  [SITUATION_POINTS.ENTRY_NOT_REGISTERED]: 'Ida para pausa não registrada',
};

const RegisterPointCard = props => {
  const { points, setNewPointRegistered } = props;
  const [registerPointModalState, setRegisterPointModalState] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState({});
  const exitPoint = points.find(point => {
    return point.type === POINT_REGISTER_TYPE.EXIT;
  });
  const entryPoint = points.find(point => {
    return point.type === POINT_REGISTER_TYPE.ENTRY;
  });
  const mainBreakEntryPoint = points.find(point => {
    return point.type === POINT_REGISTER_TYPE.MAIN_BREAK_ENTRY;
  });

  const handleOpenRegisterPointModal = point => {
    const formatedDate = format(new Date(), 'dd/MM/YYY');
    const formatedTime = format(new Date(), 'HH:mm:ss');
    const fullPoint = {
      ...point,
      date: formatedDate,
      description: point.title,
      time: formatedTime,
      pointStartDate: set(new Date(entryPoint.expectedTime), {
        seconds: 0,
        milliseconds: 0,
      }),
    };
    setSelectedPoint(fullPoint);
    setRegisterPointModalState(true);
  };

  const verifyIfHasEnterForMainBreak = () => {
    const mainBreakEntry = points.find(point => {
      return point.type === POINT_REGISTER_TYPE.MAIN_BREAK_ENTRY;
    });
    return !!mainBreakEntry.register;
  };

  const verifyIfHasEnterForBreak = actualPoint => {
    const breakEntry = points.find(point => {
      return (
        point.type === POINT_REGISTER_TYPE.BREAK_ENTRY &&
        actualPoint.breakName === point.breakName
      );
    });
    return !!breakEntry.register;
  };

  const canPointsBeRegistered = () => {
    if (entryPoint.limited && !entryPoint.register) {
      return !isAfter(
        getSetDate(),
        addMinutes(new Date(entryPoint.expectedTime), entryPoint.limitTime),
      );
    }
    return true;
  };

  return (
    <>
      <Modal
        open={registerPointModalState}
        onClose={() => setRegisterPointModalState(false)}
      >
        <DialogContent>
          <RegisterPointModal
            handleClickOnClose={() => setRegisterPointModalState(false)}
            values={selectedPoint}
            setNewPointRegistered={setNewPointRegistered}
          />
        </DialogContent>
      </Modal>
      <Timeline>
        {points.map(point => {
          const { expectedTime, type, situation, anticipationTime } = point;
          let limitTime = 5;
          let minutesToOpenBefore = 6;
          const isMainBreak =
            type === POINT_REGISTER_TYPE.MAIN_BREAK_ENTRY ||
            type === POINT_REGISTER_TYPE.MAIN_BREAK_EXIT;

          const isAfterExit = isAfter(
            new Date(expectedTime),
            new Date(exitPoint.expectedTime),
          );

          if (type === POINT_REGISTER_TYPE.EXIT) {
            limitTime = 120;
            minutesToOpenBefore = anticipationTime;
          }

          const hasEnterRegistered = !!entryPoint.register;
          const isIn5MinutesRange = isWithinInterval(new Date(expectedTime), {
            start: subMinutes(getSetDate(), limitTime),
            end: addMinutes(getSetDate(), limitTime),
          });
          const isEntry = type === POINT_REGISTER_TYPE.ENTRY;

          const isAlreadyRegistered = situation === SITUATION_POINTS.REGISTERED;

          let canRegister =
            ((hasEnterRegistered && isIn5MinutesRange) || isEntry) &&
            !isAlreadyRegistered;

          let pointSituation = situation;
          if (type === POINT_REGISTER_TYPE.MAIN_BREAK_EXIT) {
            const canRegisterMainBreakExit = verifyIfHasEnterForMainBreak();
            canRegister = canRegister && canRegisterMainBreakExit;
            if (
              !canRegisterMainBreakExit &&
              pointSituation !== SITUATION_POINTS.PENDING
            ) {
              pointSituation = SITUATION_POINTS.ENTRY_NOT_REGISTERED;
            }
          }

          if (type === POINT_REGISTER_TYPE.BREAK_EXIT) {
            const canRegisterBreakExit = verifyIfHasEnterForBreak(point);
            canRegister = canRegister && canRegisterBreakExit;
            if (
              !canRegisterBreakExit &&
              pointSituation !== SITUATION_POINTS.PENDING
            ) {
              pointSituation = SITUATION_POINTS.ENTRY_NOT_REGISTERED;
            }
          }

          const isBeforePointTime = isBefore(
            new Date(expectedTime),
            addMinutes(getSetDate(), minutesToOpenBefore),
          );

          const isCardEnabled =
            (hasEnterRegistered || isEntry) &&
            isBeforePointTime &&
            !isAfterExit;

          return isCardEnabled && canPointsBeRegistered() ? (
            <StyledITimelineItem key={point.id}>
              <TimelineSeparator>
                <StyledTimelineConnector />
                <StyledTimelineDot situation={pointSituation} />
                <StyledTimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <RegisterPointContainer
                  container
                  justify="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography
                      fontWeight={700}
                      fontSize="14px"
                      textcolor="#263238"
                      ml="3px"
                    >
                      {point.title}
                    </Typography>
                    {pointSituation === SITUATION_POINTS.PENDING ? (
                      <Typography fontSize="13px" textcolor="#FF6B6B" ml="3px">
                        {situationLabel[pointSituation]}
                      </Typography>
                    ) : (
                      <Typography fontSize="13px" textcolor="#05202A" ml="3px">
                        {situationLabel[pointSituation]}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    {canRegister ? (
                      <Grid container>
                        <Grid item>
                          <Typography fontSize="20px" textcolor="#05202A">
                            {!isMainBreak
                              ? format(new Date(point.expectedTime), 'HH:mm')
                              : '__:__'}
                          </Typography>
                        </Grid>
                        <DividerWithMargin flexItem orientation="vertical" />
                        <Grid item>
                          <StyledButton
                            $isWaiting
                            onClick={() => handleOpenRegisterPointModal(point)}
                          >
                            REGISTRAR
                          </StyledButton>
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography fontSize="20px" textcolor="#05202A">
                        {pointSituation === SITUATION_POINTS.REGISTERED &&
                          format(
                            new Date(point.register.registerDate),
                            'HH:mm',
                          )}
                        {pointSituation !== SITUATION_POINTS.REGISTERED &&
                          !isMainBreak &&
                          format(new Date(point.expectedTime), 'HH:mm')}

                        {pointSituation !== SITUATION_POINTS.REGISTERED &&
                          isMainBreak &&
                          '__:__'}
                      </Typography>
                    )}
                  </Grid>
                </RegisterPointContainer>
              </TimelineContent>
            </StyledITimelineItem>
          ) : (
            <StyledITimelineItem key={point.id}>
              <TimelineSeparator>
                <StyledTimelineConnector />
                <StyledTimelineDot situation={SITUATION_POINTS.WAITING} />
                <StyledTimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <RegisterPointContainer
                  container
                  justify="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography
                      fontWeight={700}
                      fontSize="14px"
                      textcolor="#263238"
                      ml="3px"
                      opacity={0.3}
                    >
                      {point.title}
                    </Typography>

                    <Typography
                      fontSize="13px"
                      textcolor="#05202A"
                      ml="3px"
                      opacity={0.3}
                    >
                      {canPointsBeRegistered()
                        ? situationLabel[pointSituation]
                        : 'Ponto não pode ser registrado'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      fontSize="20px"
                      textcolor="#05202A"
                      opacity={0.3}
                    >
                      {!isMainBreak || mainBreakEntryPoint.register
                        ? format(new Date(point.expectedTime), 'HH:mm')
                        : '__:__'}
                    </Typography>
                  </Grid>
                </RegisterPointContainer>
              </TimelineContent>
            </StyledITimelineItem>
          );
        })}
      </Timeline>
    </>
  );
};

RegisterPointCard.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      situation: PropTypes.string,
      textSituation: PropTypes.string,
      time: PropTypes.string,
      compareTime: PropTypes.number,
    }),
  ).isRequired,
  setNewPointRegistered: PropTypes.func.isRequired,
};

export default RegisterPointCard;
