import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { ExpandMore } from '@material-ui/icons';
import { Grid, AccordionDetails, AccordionSummary } from '@material-ui/core';

import {
  Downloader,
  CPFInput,
  PISInput,
  NumericInput,
  TabPanel,
  CustomAccordion,
  Typography,
} from '../../../components/index';
import {
  TabContainer,
  ContainerInput,
  StyledTextField,
  StyledBox,
} from '../styles';

const Documentation = props => {
  const { value, index, stepStatus, data, setUser, ...other } = props;
  const [expanded, setExpanded] = useState('');
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [dateRgExpeditionDate, setDateRgExpeditionDate] = useState({});
  const [documents, setDocuments] = useState({
    cpf: '',
    pisNumber: '',
    rgNumber: '',
    rgExpeditionDate: '',
    rgOrganUfEmitter: '',
    voterRegistrationNumber: '',
    voterRegistrationZone: '',
    voterRegistrationSection: '',
    reservistNumber: '',
  });
  const [photoCpf, setPhotoCpf] = useState({});
  const [photoFrontRg, setPhotoFrontRg] = useState({});
  const [photoBackRg, setPhotoBackRg] = useState({});
  const [photoTitle, setPhotoTitle] = useState({});
  const [photoReservist, setPhotoReservist] = useState({});

  useEffect(() => {
    if (Object.keys(data).length) {
      setDocuments(data);
      if (documents.rgNumber) {
        const newData = parseISO(data.rgExpeditionDate);
        const formatedData = format(newData, 'dd/MM/yyyy');
        setDateRgExpeditionDate(formatedData);
      }
      setPhotoCpf(data.photoCPF);
      setPhotoFrontRg(data.photoFrontRg);
      setPhotoBackRg(data.photoBackRg);
      setPhotoReservist(data.photoReservist);
      setPhotoTitle(data.photoTitle);
    }
  }, [data, documents.rgNumber]);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <StyledBox>
          <CustomAccordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography
                component="legend"
                fontSize="16px"
                mb="8px"
                mt="8px"
                width="100%"
              >
                CPF
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justify="flex-start" spacing={5}>
                <Grid container item lg={4} direction="column">
                  <Typography component="legend" fontSize="14px" mb="16px">
                    Nº CPF
                  </Typography>
                  <ContainerInput item>
                    <StyledTextField
                      name="cpf"
                      label="Número"
                      variant="filled"
                      fullWidth
                      value={documents.cpf}
                      InputProps={{
                        inputComponent: CPFInput,
                      }}
                      disabled
                    />
                  </ContainerInput>
                </Grid>
                <Grid container item lg={4} xl={2} direction="column">
                  <Typography component="legend" fontSize="14px" mb="16px">
                    Foto do CPF
                  </Typography>
                  <ContainerInput item>
                    <Downloader
                      file={{
                        key: photoCpf.key,
                        name: photoCpf.name,
                        size: photoCpf.size,
                      }}
                    />
                  </ContainerInput>
                </Grid>
              </Grid>
            </AccordionDetails>
          </CustomAccordion>
          {documents.pisNumber && (
            <CustomAccordion
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  component="legend"
                  fontSize="16px"
                  mb="8px"
                  mt="8px"
                  width="100%"
                >
                  PIS
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justify="flex-start" py="16px">
                  <Grid container item lg={4} direction="column">
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Nº PIS
                    </Typography>
                    <StyledTextField
                      name="pisNumber"
                      label="Número"
                      variant="filled"
                      fullWidth
                      value={documents.pisNumber}
                      InputProps={{
                        inputComponent: PISInput,
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={4} />
                  <Grid item lg={4} />
                </Grid>
              </AccordionDetails>
            </CustomAccordion>
          )}
          {photoFrontRg && photoBackRg && documents.rgNumber && (
            <CustomAccordion
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  component="legend"
                  fontSize="16px"
                  mb="8px"
                  mt="8px"
                  width="100%"
                >
                  RG
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justify="flex-start" spacing={4}>
                  <Grid container item lg={4} direction="column">
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Nº RG
                    </Typography>
                    <ContainerInput item>
                      <StyledTextField
                        name="rgNumber"
                        label="Número"
                        variant="filled"
                        fullWidth
                        value={documents.rgNumber}
                        InputProps={{
                          inputComponent: NumericInput,
                        }}
                        disabled
                      />
                    </ContainerInput>
                  </Grid>
                  <Grid container item lg={4} direction="column">
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Data expedição
                    </Typography>
                    <ContainerInput item>
                      <StyledTextField
                        name="dateRgExpeditionDate"
                        label="Data"
                        variant="filled"
                        fullWidth
                        value={dateRgExpeditionDate}
                        disabled
                      />
                    </ContainerInput>
                  </Grid>
                  <Grid item lg={4}>
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Órgão e UF emissor
                    </Typography>
                    <ContainerInput item>
                      <StyledTextField
                        name="rgOrganUfEmitter"
                        label="Orgão + UF"
                        variant="filled"
                        fullWidth
                        value={documents.rgOrganUfEmitter}
                        disabled
                      />
                    </ContainerInput>
                  </Grid>
                  {photoFrontRg && photoBackRg && (
                    <>
                      <Grid container item lg={4} xl={2} direction="column">
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Foto do RG frente
                        </Typography>
                        <ContainerInput item>
                          <Downloader
                            file={{
                              key: photoFrontRg.key,
                              name: photoFrontRg.name,
                              size: photoFrontRg.size,
                            }}
                          />
                        </ContainerInput>
                      </Grid>

                      <Grid container item lg={4} xl={2} direction="column">
                        <Typography
                          component="legend"
                          fontSize="14px"
                          mb="16px"
                        >
                          Foto do RG verso
                        </Typography>
                        <ContainerInput item>
                          <Downloader
                            file={{
                              key: photoBackRg.key,
                              name: photoBackRg.name,
                              size: photoBackRg.size,
                            }}
                          />
                        </ContainerInput>
                      </Grid>
                    </>
                  )}
                </Grid>
              </AccordionDetails>
            </CustomAccordion>
          )}

          {photoTitle && documents.voterRegistrationNumber && (
            <CustomAccordion
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  component="legend"
                  fontSize="16px"
                  mb="8px"
                  mt="8px"
                  width="100%"
                >
                  Título de eleitor
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justify="flex-start" spacing={5}>
                  <Grid container item lg={4} direction="column">
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Nº do título
                    </Typography>
                    <ContainerInput item>
                      <StyledTextField
                        name="voterRegistrationNumber"
                        label="Número"
                        variant="filled"
                        fullWidth
                        value={documents.voterRegistrationNumber}
                        InputProps={{
                          inputComponent: NumericInput,
                        }}
                        disabled
                      />
                    </ContainerInput>
                  </Grid>

                  <Grid container item lg={4} direction="column">
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Zona eleitoral
                    </Typography>
                    <ContainerInput item>
                      <StyledTextField
                        name="voterRegistrationZone"
                        label="Zona"
                        variant="filled"
                        fullWidth
                        value={documents.voterRegistrationZone}
                        InputProps={{
                          inputComponent: NumericInput,
                        }}
                        disabled
                      />
                    </ContainerInput>
                  </Grid>
                  <Grid item lg={4}>
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Seção eleitoral
                    </Typography>
                    <ContainerInput item>
                      <StyledTextField
                        name="voterRegistrationSection"
                        label="Seção"
                        variant="filled"
                        fullWidth
                        value={documents.voterRegistrationSection}
                        InputProps={{
                          inputComponent: NumericInput,
                        }}
                        disabled
                      />
                    </ContainerInput>
                  </Grid>
                  {photoTitle && (
                    <Grid container item lg={4} xl={2} direction="column">
                      <Typography component="legend" fontSize="14px" mb="16px">
                        Foto do título eleitoral
                      </Typography>
                      <ContainerInput item>
                        <Downloader
                          file={{
                            key: photoTitle.key,
                            name: photoTitle.name,
                            size: photoTitle.size,
                          }}
                        />
                      </ContainerInput>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </CustomAccordion>
          )}
          {photoReservist && documents.reservistNumber && (
            <CustomAccordion
              expanded={expanded === 'panel5'}
              onChange={handleChange('panel5')}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  component="legend"
                  fontSize="16px"
                  mb="8px"
                  mt="8px"
                  width="100%"
                >
                  Reservista
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justify="flex-start" spacing={5}>
                  <Grid container item lg={4} direction="column">
                    <Typography component="legend" fontSize="14px" mb="16px">
                      Nº Reservista
                    </Typography>
                    <ContainerInput item>
                      <StyledTextField
                        name="reservistNumber"
                        label="Nº do certificado militar"
                        variant="filled"
                        fullWidth
                        value={documents.reservistNumber}
                        InputProps={{
                          inputComponent: NumericInput,
                        }}
                        disabled
                      />
                    </ContainerInput>
                  </Grid>
                  {photoReservist && (
                    <Grid container item lg={4} xl={2} direction="column">
                      <Typography component="legend" fontSize="14px" mb="16px">
                        Foto da reservista
                      </Typography>
                      <ContainerInput item>
                        <Downloader
                          file={{
                            key: photoReservist.key,
                            name: photoReservist.name,
                            size: photoReservist.size,
                          }}
                        />
                      </ContainerInput>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </CustomAccordion>
          )}
        </StyledBox>
      </TabContainer>
    </TabPanel>
  );
};

Documentation.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  stepStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
};

Documentation.defaultProps = {
  data: {},
};

export default Documentation;
