import { addDays, format, isValid, parse } from 'date-fns';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { DateInput } from '../../../components';
import { ContainerInput } from '../styles';

const CalculatedInputs = ({ admissionDate }) => {
  const { values, setFieldValue } = useFormikContext();

  const calculateEndOfFirstPeriod = (firstDate, numberOfDays) => {
    const formatedDate = parse(firstDate, 'dd/MM/yyyy', new Date());
    if (isValid(formatedDate)) {
      const updatedDate = addDays(formatedDate, numberOfDays);
      return format(updatedDate, 'dd/MM/yyyy');
    }
    return '';
  };

  useEffect(() => {
    if (values.experiencePeriod === '30/60') {
      setFieldValue(
        'endOfFirstPeriod',
        calculateEndOfFirstPeriod(values.beginningOfFirstPeriod, 30),
      );
      setFieldValue(
        'startOfSecondPeriod',
        calculateEndOfFirstPeriod(values.endOfFirstPeriod, 30),
      );
      setFieldValue(
        'endOfSecondPeriod',
        calculateEndOfFirstPeriod(values.startOfSecondPeriod, 30),
      );
    } else {
      setFieldValue(
        'endOfFirstPeriod',
        calculateEndOfFirstPeriod(values.beginningOfFirstPeriod, 45),
      );
      setFieldValue(
        'startOfSecondPeriod',
        calculateEndOfFirstPeriod(values.endOfFirstPeriod, 45),
      );
      setFieldValue(
        'endOfSecondPeriod',
        calculateEndOfFirstPeriod(values.startOfSecondPeriod, 45),
      );
    }
  }, [
    setFieldValue,
    values.beginningOfFirstPeriod,
    values.experiencePeriod,
    values.endOfFirstPeriod,
    values.startOfSecondPeriod,
  ]);

  useEffect(() => {
    setFieldValue('beginningOfFirstPeriod', admissionDate);
  }, [admissionDate, setFieldValue]);

  return (
    <>
      <ContainerInput item lg={3}>
        <Field
          component={TextField}
          name="endOfFirstPeriod"
          disabled
          label="Fim do primeiro período de experiência"
          variant="filled"
          fullWidth
          InputProps={{
            inputComponent: DateInput,
          }}
        />
      </ContainerInput>

      <ContainerInput item lg={3}>
        <Field
          component={TextField}
          name="endOfSecondPeriod"
          disabled
          label="Fim do contrato de experiência"
          variant="filled"
          fullWidth
          InputProps={{
            inputComponent: DateInput,
          }}
        />
      </ContainerInput>

      <ContainerInput item lg={3}>
        <Field
          component={TextField}
          style={{ display: 'none' }}
          name="startOfSecondPeriod"
          disabled
          label={
            values.experiencePeriod === '30/60'
              ? 'Início dos 60 dias finais'
              : 'Início dos 45 dias finais'
          }
          variant="filled"
          fullWidth
          InputProps={{
            inputComponent: DateInput,
          }}
        />
      </ContainerInput>
    </>
  );
};

export default CalculatedInputs;

CalculatedInputs.propTypes = {
  admissionDate: PropTypes.string.isRequired,
};
