import React from 'react';
import PropTypes from 'prop-types';

import { StyledTab } from './styles';

const Tabs = ({ index, label, ...rest }) => (
  <StyledTab {...rest} label={label} />
);

Tabs.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

export default Tabs;
