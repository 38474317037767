import { TextField } from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { Downloader, Grid, Typography } from '../../../../components';
import { REQUEST_CHANGES } from '../../../../utils/enums';
import { StyledGrid } from '../styles';

const MedicalCertificateOrDayOffInfo = ({ values }) => {
  const {
    justification,
    attachment,
    solicitationDate,
    reason,
    pointAlert,
  } = values;
  const isDayOffRequest = reason === REQUEST_CHANGES.REQUEST_DAY_OFF;
  const date = isDayOffRequest ? solicitationDate : pointAlert.pointStartDate;
  return (
    <>
      <StyledGrid item xs={6}>
        <TextField
          name="solicitationDate"
          label={
            isDayOffRequest
              ? 'Data da Folga'
              : 'Data em que o atestado substituirá'
          }
          variant="filled"
          disabled
          value={format(new Date(date), 'dd/MM/yyyy')}
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <TextField
          name="justification"
          label="Justificativa"
          variant="filled"
          disabled
          value={justification}
          fullWidth
        />
      </StyledGrid>
      {attachment && (
        <Grid item xs={6}>
          <Typography opacity={0.5} fontSize="14px" mb="10px">
            Anexo
          </Typography>
          <Downloader
            file={{
              key: attachment.key,
              name: attachment.name,
              size: attachment.size,
            }}
          />
        </Grid>
      )}
    </>
  );
};

MedicalCertificateOrDayOffInfo.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MedicalCertificateOrDayOffInfo;
