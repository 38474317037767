import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Typography from '../../../Typography';
import { AcordionDiv, StyledButton } from './styles';

const AcordionItem = ({ link, src, text, colaborator, disabled }) => {
  const location = useLocation().pathname;

  const Wrapper = disabled ? Box : Link;
  const WrapperProps = disabled ? null : { to: link };

  return (
    <Wrapper {...WrapperProps}>
      <Grid>
        {colaborator ? (
          <StyledButton disabled={disabled} $ativo={location === link}>
            <AcordionDiv py={2} px={2}>
              {src && <img src={src} alt="" />}
              <Typography
                ml="14px"
                color="secondary"
                fontWeight="bold"
                fontSize="14px"
              >
                {text}
              </Typography>
            </AcordionDiv>
          </StyledButton>
        ) : (
          <StyledButton disabled={disabled} $ativo={location === link}>
            <AcordionDiv py={2} px={2}>
              <Typography
                ml="5px"
                color="secondary"
                fontWeight="bold"
                opacity="0.7"
                fontSize="12px"
              >
                {text}
              </Typography>
            </AcordionDiv>
          </StyledButton>
        )}
      </Grid>
    </Wrapper>
  );
};

AcordionItem.defaultProps = {
  src: '',
  colaborator: false,
  disabled: false,
};

AcordionItem.propTypes = {
  link: PropTypes.string.isRequired,
  src: PropTypes.string,
  text: PropTypes.string.isRequired,
  colaborator: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default AcordionItem;
