import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

export const StyledButton = styled(Button)`
  border-radius: 23px;
  opacity: 1;
  color: #fff;
  &.MuiButtonBase-root {
    width: 197px;
    height: 45px;

    ${({ theme }) => css`
      background: ${theme.palette.primary.main} 0% 0% no-repeat padding-box;
    `}
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
