import React from 'react';
import styled, { css } from 'styled-components';
import { Button, TableCell } from '@material-ui/core';

export const StyledIconButtonBlue = styled(Button)`
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 16px;
  transition: opacity 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.8;
  }

  span {
    width: 25px;
    height: 25px;
  }

  img {
    filter: brightness(100);
    width: 15px;
    height: 15px;
  }
`;

export const StyledIconButtonRed = styled(Button)`
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.error.main};
  margin-right: 16px;
  transition: opacity 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.error.main};
    opacity: 0.8;
  }

  span {
    width: 25px;
    height: 25px;
  }

  img {
    filter: brightness(100);
    width: 15px;
    height: 15px;
  }
`;

export const StyledTableCell = styled(({ hasAlerts, ...props }) => (
  <TableCell {...props} />
))`
  font-weight: 300;
  ${({ hasAlerts, theme }) =>
    hasAlerts &&
    css`
      color: ${theme.palette.error.main};
      font-weight: bold;
    `}
`;
