import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import React from 'react';
import { StyledGrid } from '../styles';

const ChangeDayOff = () => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <StyledGrid item lg={6} />
      <StyledGrid item lg={6}>
        <Field
          component={KeyboardDatePicker}
          name="changeDayOff.dayOffDate"
          label="Data da folga"
          invalidDateMessage="Data inválida"
          inputVariant="filled"
          cancelLabel="CANCELAR"
          format="dd/MM/yyyy"
          placeholder="dd/mm/aaaa"
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item lg={6}>
        <Field
          component={KeyboardDatePicker}
          name="changeDayOff.workDate"
          label="Data da troca (que você irá trabalhar)"
          invalidDateMessage="Data inválida"
          inputVariant="filled"
          cancelLabel="CANCELAR"
          format="dd/MM/yyyy"
          placeholder="dd/mm/aaaa"
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item lg={12}>
        <Field
          component={TextField}
          name="changeDayOff.description"
          label="Descrição"
          variant="filled"
          fullWidth
        />
      </StyledGrid>
    </MuiPickersUtilsProvider>
  );
};

export default ChangeDayOff;
