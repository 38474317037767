import { addDays, addMinutes, isAfter, isBefore, set } from 'date-fns';
import { SCALE_TYPES } from './enums';

const validateBreaksTime = breaks => {
  const breaksOrderedByTime = breaks.sort(
    (firstBreakToCompare, secondBreakToCompare) =>
      firstBreakToCompare.start.getTime() -
      secondBreakToCompare.start.getTime(),
  );
  const arrayWithInvalidTimes = breaksOrderedByTime.filter(
    (actualBreak, index, selfArray) => {
      if (index !== selfArray.length - 1) {
        const endOfActualBreak = addMinutes(
          actualBreak.start,
          Number(actualBreak.time),
        );
        return isBefore(selfArray[index + 1].start, endOfActualBreak);
      }
      return false;
    },
  );

  return arrayWithInvalidTimes.length === 0;
};

const validateBreaksName = breaks => {
  const breakNames = breaks.map(actualBreak =>
    actualBreak.description.toUpperCase(),
  );
  return new Set(breakNames).size === breakNames.length;
};

const formatDayFactory = time => {
  const [hours, minutes] = time.split(':');

  return set(new Date(), {
    hours,
    minutes,
    seconds: 0,
  });
};

const validateBreaksAtBusinessTime = (breaks, startDayTime, endDayTime) => {
  const breaksOrderedByTime = breaks.sort(
    (firstBreakToCompare, secondBreakToCompare) =>
      firstBreakToCompare.start.getTime() -
      secondBreakToCompare.start.getTime(),
  );

  if (breaksOrderedByTime.length > 0) {
    const endLastPause = addMinutes(
      breaksOrderedByTime[breaksOrderedByTime.length - 1].start,
      Number(breaksOrderedByTime[breaksOrderedByTime.length - 1].time),
    );
    return (
      isBefore(startDayTime, breaksOrderedByTime[0].start) &&
      isAfter(endDayTime, endLastPause)
    );
  }

  return true;
};

const validateBreaksAtNight = (breaks, startDayTime, endDayTime) => {
  const breaksOrganizedByTime = breaks.map(actualBreak => {
    if (isBefore(actualBreak.start, endDayTime)) {
      return {
        ...actualBreak,
        start: addDays(actualBreak.start, 1),
      };
    }
    return actualBreak;
  });

  const breaksOrderedByTime = breaksOrganizedByTime.sort(
    (firstBreakToCompare, secondBreakToCompare) =>
      firstBreakToCompare.start.getTime() -
      secondBreakToCompare.start.getTime(),
  );

  const endDay = addDays(endDayTime, 1);

  if (breaksOrderedByTime.length > 0) {
    const endLastPause = addMinutes(
      breaksOrderedByTime[breaksOrderedByTime.length - 1].start,
      Number(breaksOrderedByTime[breaksOrderedByTime.length - 1].time),
    );
    return (
      isBefore(startDayTime, breaksOrderedByTime[0].start) &&
      isAfter(endDay, endLastPause)
    );
  }

  return true;
};

const validateStartAndFinalTime = (breaks, scale) => {
  if (
    scale.type === SCALE_TYPES['5x1'] ||
    scale.type === SCALE_TYPES['12/36']
  ) {
    const start = formatDayFactory(scale.times.standardDay.enterTime);
    const end = formatDayFactory(scale.times.standardDay.exitTime);
    if (
      set(end, { seconds: 0, milliseconds: 0 }).getTime() <
      set(start, { seconds: 0, milliseconds: 0 }).getTime()
    ) {
      const isValid = validateBreaksAtNight(breaks, start, end);
      return isValid;
    }
    const isValid = validateBreaksAtBusinessTime(breaks, start, end);
    return isValid;
  }
  if (scale.times.isEqualTime) {
    const start = formatDayFactory(scale.times.standardTime.enterTime);
    const end = formatDayFactory(scale.times.standardTime.exitTime);

    if (
      set(end, { seconds: 0, milliseconds: 0 }).getTime() <
      set(start, { seconds: 0, milliseconds: 0 }).getTime()
    ) {
      const isValid = validateBreaksAtNight(breaks, start, end);
      return isValid;
    }
    const isValid = validateBreaksAtBusinessTime(breaks, start, end);
    return isValid;
  }

  return true;
};

export const validateBreaks = (breaks, scale) => {
  const hasValidNames = validateBreaksName(breaks);
  if (!hasValidNames) {
    return {
      valid: false,
      message: 'Há pausas com nomes repetidos',
    };
  }
  const hasValidTimes = validateBreaksTime(breaks);
  if (!hasValidTimes) {
    return {
      valid: hasValidTimes,
      message: 'Há uma ou mais pausas com horarios inválidos',
    };
  }
  if (scale) {
    const hasValidStartAndFinalTime = validateStartAndFinalTime(breaks, scale);

    return {
      valid: hasValidStartAndFinalTime,
      message: hasValidStartAndFinalTime
        ? ''
        : 'Há pausas fora do horário de trabalho',
    };
  }
  return {
    valid: true,
    message: '',
  };
};
