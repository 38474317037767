import { Box, Checkbox, DialogContent, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  CEPInput,
  CPFInput,
  Downloader,
  Grid,
  RefusedStepModal,
  TabPanel,
} from '../../../components';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { STEPS } from '../../../utils/enums';
import ApprovalStep from '../ApprovalStep';
import {
  ContainerInput,
  StyledButtonFail,
  StyledButtonPass,
  StyledFormControlLabel,
  StyledTextField,
  StyledTypography,
  SubTitleForm,
  TabContainer,
} from '../styles';

const Address = props => {
  const {
    value,
    index,
    stepStatus,
    data,
    setUser,
    handleChangeTab,
    ...other
  } = props;
  const [openRefusedStepModal, setOpenRefusedStepModal] = useState(false);
  const [addressStatus, setAddressStatus] = useState({});
  const [address, setAddress] = useState({
    cep: '',
    state: '',
    city: '',
    publicPlace: '',
    number: '',
    complement: '',
    neighborhood: '',
    type: '',
    cpfOwner: '',
    rgOwner: '',
    proofOfAddress: false,
  });

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();

  useEffect(() => {
    if (Object.keys(data).length) {
      setAddress(data);
    }
    if (stepStatus) {
      setAddressStatus(stepStatus);
    }
  }, [data, stepStatus]);

  const handleApproveStatus = useCallback(async () => {
    try {
      dispatch(setLoading(true));

      const api = AuthenticatedHttpClient();
      const response = await api.put(`users/update-step/${collaboratorId}`, {
        stepName: STEPS.ADDRESS,
        status: 'approved',
      });
      setUser(response.data);
      dispatch(setSnackbar(true, 'success', 'Dados aprovados'));
      handleChangeTab(null, value + 1);
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Falha ao aprovar'));
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch, collaboratorId, setUser, handleChangeTab, value]);

  const handleCloseModal = useCallback(
    userUpdated => {
      setOpenRefusedStepModal(false);
      if (userUpdated) {
        setUser(userUpdated);
      }
    },
    [setUser],
  );

  return (
    <>
      <TabPanel value={value} index={index} {...other}>
        <TabContainer>
          <Box
            component={Grid}
            container
            alignItems="center"
            justify="space-between"
            spacing={2}
            p={1}
          >
            <SubTitleForm>Endereço do colaborador</SubTitleForm>
            <ContainerInput item lg={3}>
              <StyledTextField
                name="cep"
                label="CEP"
                variant="filled"
                fullWidth
                value={address.cep}
                InputProps={{
                  inputComponent: CEPInput,
                }}
              />
            </ContainerInput>
            <ContainerInput item lg={3}>
              <StyledTextField
                name="type"
                label="Tipo"
                variant="filled"
                fullWidth
                value={address.type}
              />
            </ContainerInput>
            <ContainerInput item lg={6}>
              <StyledTextField
                name="publicPlace"
                label="Logradouro"
                variant="filled"
                fullWidth
                value={address.publicPlace}
              />
            </ContainerInput>
            <ContainerInput item lg={3}>
              <StyledTextField
                name="number"
                label="Número"
                variant="filled"
                value={address.number}
                fullWidth
              />
            </ContainerInput>
            <ContainerInput item lg={3}>
              <StyledTextField
                name="complement"
                label="Complemento"
                variant="filled"
                value={address.complement}
                fullWidth
              />
            </ContainerInput>
            <ContainerInput item lg={3}>
              <StyledTextField
                name="district"
                label="Bairro"
                variant="filled"
                value={address.neighborhood}
                fullWidth
              />
            </ContainerInput>
            <ContainerInput item lg={3}>
              <StyledTextField
                name="city"
                label="Cidade"
                variant="filled"
                value={address.city}
                fullWidth
              />
            </ContainerInput>
            <ContainerInput item lg={3}>
              <StyledTextField
                name="state"
                label="Estado"
                variant="filled"
                value={address.state}
                fullWidth
              />
            </ContainerInput>
            {!address.proofOfAddress && (
              <>
                <ContainerInput item lg={3}>
                  <StyledTextField
                    name="cpfOwner"
                    label="CPF do Proprietário"
                    variant="filled"
                    fullWidth
                    value={address.cpfOwner}
                    InputProps={{
                      inputComponent: CPFInput,
                    }}
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <StyledTextField
                    name="rgOwner"
                    label="RG do Proprietário"
                    variant="filled"
                    value={address.rgOwner}
                    fullWidth
                  />
                </ContainerInput>
                <ContainerInput item lg={3} />
              </>
            )}
            <Box w={1} alignItems="center">
              <StyledFormControlLabel
                control={
                  <Checkbox
                    checked={address.proofOfAddress}
                    name="proofOfAddress"
                    color="primary"
                  />
                }
                label="Comprovante está em nome do colaborador"
              />
            </Box>
            {address.receipt && (
              <>
                <SubTitleForm>Comprovante de endereço</SubTitleForm>
                <ContainerInput item lg={3}>
                  <Downloader
                    file={{
                      name: address.receipt.name,
                      size: address.receipt.size,
                      key: address.receipt.key,
                    }}
                  />
                </ContainerInput>
              </>
            )}
          </Box>
          {(addressStatus.status === 'approved' ||
            addressStatus.status === 'disapproved') && (
            <ApprovalStep
              status={addressStatus.status}
              reason={addressStatus.reason}
            />
          )}
          {addressStatus.status === 'underAnalysis' && (
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <StyledButtonFail onClick={() => setOpenRefusedStepModal(true)}>
                <StyledTypography color="error">REPROVAR</StyledTypography>
              </StyledButtonFail>
              <StyledButtonPass onClick={handleApproveStatus}>
                <StyledTypography color="secondary">APROVAR</StyledTypography>
              </StyledButtonPass>
            </Box>
          )}
        </TabContainer>
      </TabPanel>
      <Modal open={openRefusedStepModal} onClose={handleCloseModal}>
        <DialogContent>
          <RefusedStepModal
            stepName={STEPS.ADDRESS}
            stepTitle="Endereço"
            handleClickOnClose={handleCloseModal}
          />
        </DialogContent>
      </Modal>
    </>
  );
};

Address.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  stepStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
};

Address.defaultProps = {
  data: {},
};

export default Address;
