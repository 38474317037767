import { Button, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ModalSkeleton } from '../../../components';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { ActionsBar, ContainerModal, StyledGrid } from './styles';

const ResetPasswordModal = ({ handleOnClose, formInitialValues, userId }) => {
  const dispatch = useDispatch();

  const handleResetPassword = useCallback(
    async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const api = AuthenticatedHttpClient();
        await api.put(`/users/reset-password/`, {
          ...values,
          userId,
        });

        dispatch(setSnackbar(true, 'success', 'Senha resetada com sucesso'));
      } catch (e) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possível resetar a senha'),
        );
      } finally {
        setSubmitting(false);
        handleOnClose();
      }
    },
    [dispatch, handleOnClose, userId],
  );

  const handleValidate = useCallback(values => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Email obrigatório';
    }

    return errors;
  }, []);

  return (
    <ModalSkeleton title="RESETAR  SENHA" handleClickOnClose={handleOnClose}>
      <ContainerModal>
        <Formik
          initialValues={formInitialValues}
          validate={handleValidate}
          onSubmit={handleResetPassword}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid
                container
                alignItems="center"
                justify="space-between"
                spacing={2}
              >
                <StyledGrid item xs={12}>
                  <Field
                    component={TextField}
                    name="email"
                    label="Email"
                    variant="filled"
                    fullWidth
                  />
                </StyledGrid>
              </Grid>
              <ActionsBar container alignItems="center" justify="flex-end">
                <StyledGrid mt="32px">
                  <Button
                    disabled={isSubmitting}
                    color="secondary"
                    onClick={handleOnClose}
                    size="small"
                  >
                    CANCELAR
                  </Button>
                  <Button
                    color="secondary"
                    disabled={isSubmitting}
                    type="submit"
                    size="small"
                  >
                    RESETAR
                  </Button>
                </StyledGrid>
              </ActionsBar>
            </Form>
          )}
        </Formik>
      </ContainerModal>
    </ModalSkeleton>
  );
};

ResetPasswordModal.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  formInitialValues: PropTypes.shape({
    email: PropTypes.string,
  }),
  userId: PropTypes.string.isRequired,
};

ResetPasswordModal.defaultProps = {
  formInitialValues: {
    email: '',
  },
};

export default ResetPasswordModal;
