import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { Box, Button } from '@material-ui/core';
import * as Yup from 'yup';

import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';

import { TabPanel, Grid, Typography } from '../../../components';

import { TabContainer, StyledCheckbox } from '../styles';

const checkupSchema = Yup.object().shape({
  pregnant: Yup.boolean().required('Campo obrigatório'),
  workAccident: Yup.boolean().required('Campo obrigatório'),
  sicknessAid: Yup.boolean().required('Campo obrigatório'),
  suspendedContract: Yup.boolean().required('Campo obrigatório'),
  representativeEmployee: Yup.boolean().required('Campo obrigatório'),
  otherConditions: Yup.boolean().required('Campo obrigatório'),
  approvedCheckup: Yup.boolean().required('Campo obrigatório'),
});

const Checkup = props => {
  const { value, index, data, setUser, ...other } = props;
  const [checkupData, setCheckupData] = useState({
    pregnant: false,
    workAccident: false,
    sicknessAid: false,
    suspendedContract: false,
    representativeEmployee: false,
    otherConditions: false,
    approvedCheckup: false,
  });

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();

  useEffect(() => {
    const { checkup, approvedCheckup } = data;
    if (checkup && Object.keys(checkup).length) {
      const {
        pregnant,
        workAccident,
        sicknessAid,
        suspendedContract,
        representativeEmployee,
        otherConditions,
      } = checkup;

      setCheckupData({
        pregnant,
        workAccident,
        sicknessAid,
        suspendedContract,
        representativeEmployee,
        otherConditions,
        approvedCheckup: approvedCheckup || false,
      });
    }
  }, [data]);

  const handleSubmitCheckup = useCallback(
    async (values, { setSubmitting }) => {
      try {
        dispatch(setLoading(true));

        const { approvedCheckup, ...rest } = values;

        const api = AuthenticatedHttpClient();

        const form = {
          approvedCheckup: values.approvedCheckup,
          checkup: rest,
        };

        const response = await api.put(
          `users/dismissal-details/${collaboratorId}`,
          form,
        );

        setUser(response.data);
        dispatch(setSnackbar(true, 'success', 'Dados alterados'));
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possível alterar os dados'),
        );
      } finally {
        dispatch(setLoading(false));
        setSubmitting(false);
      }
    },
    [dispatch, collaboratorId, setUser],
  );

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Formik
          initialValues={checkupData}
          onSubmit={handleSubmitCheckup}
          validationSchema={checkupSchema}
          enableReinitialize
        >
          {({ isSubmitting, values }) => (
            <Form>
              <Box component={Grid} m={1}>
                <Typography fontSize="18px" fontWeight="400">
                  Check up do funcionário
                </Typography>
                <Typography mt="10px" opacity="0.7" fontSize="16px">
                  Evite multas de rescisão. Avalie as condições em que é{' '}
                  <b>vedada</b> a dispensa sem justa causa. Caso encontre algum
                  caso na lista abaixo, por favor marque o campo em que contém
                  algo que possa vedar a demissão sem justa causa e logo o
                  processo será interrompido, reativando o colaborador:
                </Typography>
                <Box mt={2} mb={2}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center">
                      <Field
                        component={StyledCheckbox}
                        disabled={values.approvedCheckup}
                        name="pregnant"
                        type="checkbox"
                        color="primary"
                        checked={values.pregnant}
                        Label={{
                          label: (
                            <Typography>
                              <b>Gestante</b>, desde a confirmação da gravidez
                              até o <b>5º mês após o parto</b>.
                            </Typography>
                          ),
                        }}
                      />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Field
                        component={StyledCheckbox}
                        disabled={values.approvedCheckup}
                        name="workAccident"
                        type="checkbox"
                        color="primary"
                        Label={{
                          label: (
                            <Typography>
                              <b>Acidente de trabalho</b>, até <b>1 ano</b> após
                              a cessação do auxílio-doença.
                            </Typography>
                          ),
                        }}
                      />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Field
                        component={StyledCheckbox}
                        disabled={values.approvedCheckup}
                        name="sicknessAid"
                        type="checkbox"
                        color="primary"
                        Label={{
                          label: (
                            <Typography>
                              Funcionário no gozo de
                              <b>
                                auxílio doença (que não por acidente de
                                trabalho)
                              </b>
                              , até <b>30 dias depois da cessação</b>, podendo
                              se estender as vezes até <b>120 dias</b> conforme
                              cláusula em convenção coletiva.
                            </Typography>
                          ),
                        }}
                      />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Field
                        component={StyledCheckbox}
                        disabled={values.approvedCheckup}
                        name="suspendedContract"
                        type="checkbox"
                        color="primary"
                        Label={{
                          label: (
                            <Typography>
                              Em caso de <b>contrato suspenso</b>.
                            </Typography>
                          ),
                        }}
                      />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Field
                        component={StyledCheckbox}
                        disabled={values.approvedCheckup}
                        name="representativeEmployee"
                        type="checkbox"
                        color="primary"
                        Label={{
                          label: (
                            <Typography>
                              Funcionário{' '}
                              <b>representante (titular ou suplente)</b>, em:
                              Comissão de Conciliação Prévia, Sindicato,
                              Associação ou CIPA, da candidatura até{' '}
                              <b>1 ano</b> após seu mandato.
                            </Typography>
                          ),
                        }}
                      />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Field
                        component={StyledCheckbox}
                        disabled={values.approvedCheckup}
                        name="otherConditions"
                        type="checkbox"
                        color="primary"
                        Label={{
                          label: (
                            <Typography>
                              <b>Outras condições</b> previstas em convenções
                              coletivas ou sentenças normativas.
                            </Typography>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Grid container justify="flex-end" alignItems="center">
                  <Field
                    component={StyledCheckbox}
                    disabled={
                      values.pregnant ||
                      values.otherConditions ||
                      values.representativeEmployee ||
                      values.suspendedContract ||
                      values.sicknessAid ||
                      values.workAccident
                    }
                    name="approvedCheckup"
                    type="checkbox"
                    color="primary"
                    Label={{
                      label: (
                        <Typography mr="60px">
                          <b>
                            Concordo e estou ciente que não foi identificado
                            nenhuma das situações apresentadas acima
                          </b>
                        </Typography>
                      ),
                    }}
                  />

                  <Button type="submit" disabled={isSubmitting}>
                    <Typography fontSize="16px" fontWeight="bold">
                      SALVAR
                    </Typography>
                  </Button>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </TabContainer>
    </TabPanel>
  );
};

Checkup.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
};

Checkup.defaultProps = {
  data: {},
};

export default Checkup;
