import styled, { css } from 'styled-components';
import {
  Drawer,
  Grid,
  Box,
  Accordion,
  AccordionDetails,
} from '@material-ui/core';

export const StyledDrawer = styled(Drawer)`
  width: 230px;

  & .MuiPaper-root {
    ${({ theme }) => css`
      background-color: ${theme.palette.third.light};
    `}
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    max-width: 230px;
  }

  a {
    text-decoration: none;
  }
`;

export const ButtonDiv = styled(Box)`
  display: flex;
  cursor: pointer;
`;

export const StyledBoxLine = styled(Box)`
  border-bottom: 1px solid #fff;
`;

export const StyledGridConfig = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-top: 6px;

  & img {
    margin-left: 10px;
    margin-right: 19px;
  }
`;

export const StyledAccordionPanel = styled(Accordion)`
  &.Mui-expanded {
    margin: 0;
  }

  &.MuiPaper-root {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0px;
  }
`;

export const StyledAccordionPanelDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0px 0px;
    display: block;
  }
`;
