import * as Yup from 'yup';
import parseDate from '../utils/parseDateString';

const shutdownSchema = Yup.object().shape({
  justCause: Yup.boolean().required('Campo obrigatório'),
  dismissalOrigin: Yup.string().required('Campo obrigatório'),
  notice: Yup.string().required('Campo obrigatório'),
  warningDefinitions: Yup.string().when('notice', {
    is: notice => notice === 'complyNotice',
    then: Yup.string().required('Campo Obrigatório'),
  }),
  increasedCompanyTime: Yup.boolean().required('Campo obrigatório'),
  admissionDate: Yup.date()
    .transform(parseDate)
    .required('Campo obrigatório')
    .typeError('Data inválida'),
  resignationDate: Yup.date()
    .transform(parseDate)
    .min(Yup.ref('admissionDate'), 'Informe uma data posterior')
    .required('Campo obrigatório')
    .typeError('Data inválida'),
  departureDate: Yup.date()
    .transform(parseDate)
    .required('Campo obrigatório')
    .typeError('Data inválida'),
  reason: Yup.string().required('Campo obrigatório'),
  observations: Yup.string().required('Campo obrigatório'),
});

export default shutdownSchema;
