import { Box, Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ContractDownloader,
  TabPanel,
  Typography,
  Uploader,
} from '../../../components';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import toFormData from '../../../utils/formData';
import { SubTitleForm, TabContainer } from '../styles';

const Contracts = props => {
  const { value, index, data, setUser, ...other } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [employementContractFile, setEmployementContractFile] = useState(null);
  const [effectiveTrialPeriodFile, setEffectiveTrialPeriodFile] = useState(
    null,
  );
  const [extensionTrialPeriodFile, setExtensionTrialPeriodFile] = useState(
    null,
  );
  const [discountAuthorizationFile, setDiscountAuthorizationFile] = useState(
    null,
  );
  const [
    terminationExperienceContractFile,
    setTerminationExperienceContractFile,
  ] = useState(null);
  const [
    individualOrCollectiveAgreementsFile,
    setIndividualOrCollectiveAgreementsFile,
  ] = useState(null);
  const [otherDocumentsFile, setOtherDocumentsFile] = useState(null);

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();

  const {
    employementContract,
    effectiveTrialPeriod,
    extensionTrialPeriod,
    discountAuthorization,
    terminationExperienceContract,
    individualOrCollectiveAgreements,
    otherDocuments,
  } = data?.contracts || {};

  const hasAdmissionExam = data?.admissionExam;

  const needsToxicologicalExam = data?.jobRole?.toxycologicalExam;

  const needsAndHaveToxicologicalExam =
    needsToxicologicalExam && data?.toxicologicalExam;

  const submitContracts = useCallback(async () => {
    try {
      setIsSubmitting(true);
      dispatch(setLoading(true));

      if (
        !employementContractFile &&
        !effectiveTrialPeriodFile &&
        !extensionTrialPeriodFile &&
        !discountAuthorizationFile &&
        !terminationExperienceContractFile &&
        !individualOrCollectiveAgreementsFile &&
        !otherDocumentsFile
      ) {
        dispatch(setSnackbar(true, 'error', 'Nenhum arquivo selecionado'));
        return;
      }

      const contracts = {
        employementContract: employementContractFile,
        effectiveTrialPeriod: effectiveTrialPeriodFile,
        extensionTrialPeriod: extensionTrialPeriodFile,
        discountAuthorization: discountAuthorizationFile,
        terminationExperienceContract: terminationExperienceContractFile,
        individualOrCollectiveAgreements: individualOrCollectiveAgreementsFile,
        otherDocuments: otherDocumentsFile,
      };

      const formObj = toFormData(contracts);

      const api = AuthenticatedHttpClient();
      const response = await api.put(
        `users/contracts/${collaboratorId}`,
        formObj,
      );

      setUser(response.data);

      setEmployementContractFile(null);
      setEffectiveTrialPeriodFile(null);
      setExtensionTrialPeriodFile(null);
      setDiscountAuthorizationFile(null);
      setTerminationExperienceContractFile(null);
      setIndividualOrCollectiveAgreementsFile(null);
      setOtherDocumentsFile(null);

      dispatch(setSnackbar(true, 'success', 'Dados alterados'));
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Não foi possível alterar os dados'));
    } finally {
      setIsSubmitting(false);
      dispatch(setLoading(false));
    }
  }, [
    employementContractFile,
    effectiveTrialPeriodFile,
    extensionTrialPeriodFile,
    discountAuthorizationFile,
    terminationExperienceContractFile,
    individualOrCollectiveAgreementsFile,
    otherDocumentsFile,
    collaboratorId,
    setUser,
    dispatch,
  ]);

  const handleRemoveFile = useCallback(
    async contract => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        const response = await api.delete(
          `users/contracts/${collaboratorId}?contract=${contract}`,
        );

        dispatch(setSnackbar(true, 'success', 'Contrato removido'));
        setUser(response.data);
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao remover contrato'));
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, collaboratorId, setUser],
  );

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <SubTitleForm>Contratos de efetivação</SubTitleForm>
        <Grid container alignItems="center" justify="space-between" spacing={1}>
          <Grid item lg={3}>
            {employementContract ? (
              <>
                <Typography
                  fontSize="12px"
                  opacity="0.5"
                  fontWeight={300}
                  mb="8px"
                >
                  Contrato de trabalho
                </Typography>
                <ContractDownloader
                  file={{
                    location: employementContract.location,
                    status: employementContract.status,
                    size: employementContract.size,
                  }}
                  remove
                  removeFn={() => handleRemoveFile('employementContract')}
                  title="Contrato de trabalho"
                />
              </>
            ) : (
              <Uploader
                accept="application/pdf"
                dropText={
                  !hasAdmissionExam || needsAndHaveToxicologicalExam
                    ? 'Necessário exame admissional'
                    : 'Adicionar contrato'
                }
                title="Contrato de trabalho"
                disabled={!hasAdmissionExam || needsAndHaveToxicologicalExam}
                onFileUploaded={setEmployementContractFile}
              />
            )}
          </Grid>
          <Grid item lg={3}>
            {effectiveTrialPeriod ? (
              <>
                <Typography
                  fontSize="12px"
                  opacity="0.5"
                  fontWeight={300}
                  mb="8px"
                >
                  Efetivação de experiência
                </Typography>
                <ContractDownloader
                  file={{
                    location: effectiveTrialPeriod.location,
                    status: effectiveTrialPeriod.status,
                    size: effectiveTrialPeriod.size,
                  }}
                  remove
                  removeFn={() => handleRemoveFile('effectiveTrialPeriod')}
                  title="Efetivação de experiência"
                />
              </>
            ) : (
              <Uploader
                accept="application/pdf"
                dropText="Adicionar contrato"
                title="Efetivação de experiência"
                onFileUploaded={setEffectiveTrialPeriodFile}
              />
            )}
          </Grid>
          <Grid item lg={3}>
            {extensionTrialPeriod ? (
              <>
                <Typography
                  fontSize="12px"
                  opacity="0.5"
                  fontWeight={300}
                  mb="8px"
                >
                  Prorrogação de experiência
                </Typography>
                <ContractDownloader
                  file={{
                    location: extensionTrialPeriod.location,
                    status: extensionTrialPeriod.status,
                    size: extensionTrialPeriod.size,
                  }}
                  remove
                  removeFn={() => handleRemoveFile('extensionTrialPeriod')}
                  title="Prorrogação de experiência"
                />
              </>
            ) : (
              <Uploader
                accept="application/pdf"
                dropText="Adicionar contrato"
                title="Prorrogação de experiência"
                onFileUploaded={setExtensionTrialPeriodFile}
              />
            )}
          </Grid>
          <Grid item lg={3}>
            {discountAuthorization ? (
              <>
                <Typography
                  fontSize="12px"
                  opacity="0.5"
                  fontWeight={300}
                  mb="8px"
                >
                  Autorização de desconto em folha
                </Typography>
                <ContractDownloader
                  file={{
                    location: discountAuthorization.location,
                    status: discountAuthorization.status,
                    size: discountAuthorization.size,
                  }}
                  remove
                  removeFn={() => handleRemoveFile('discountAuthorization')}
                  title="Autorização de desconto em folha"
                />
              </>
            ) : (
              <Uploader
                accept="application/pdf"
                dropText="Adicionar contrato"
                title="Autorização de desconto em folha"
                onFileUploaded={setDiscountAuthorizationFile}
              />
            )}
          </Grid>
        </Grid>
        <Box mt={3}>
          <SubTitleForm>Contratos de rescisões</SubTitleForm>
        </Box>
        <Grid container alignItems="center" justify="flex-start" spacing={1}>
          <Grid item lg={3}>
            {terminationExperienceContract ? (
              <>
                <Typography
                  fontSize="12px"
                  opacity="0.5"
                  fontWeight={300}
                  mb="8px"
                >
                  Rescisão de contrato de experiência
                </Typography>
                <ContractDownloader
                  file={{
                    location: terminationExperienceContract.location,
                    status: terminationExperienceContract.status,
                    size: terminationExperienceContract.size,
                  }}
                  remove
                  removeFn={() =>
                    handleRemoveFile('terminationExperienceContract')
                  }
                  title="Rescisão de contrato de experiência"
                />
              </>
            ) : (
              <Uploader
                accept="application/pdf"
                dropText="Adicionar contrato"
                title="Rescisão de contrato de experiência"
                onFileUploaded={setTerminationExperienceContractFile}
              />
            )}
          </Grid>
        </Grid>
        <Box mt={3}>
          <SubTitleForm>Outros documentos</SubTitleForm>
        </Box>
        <Grid container alignItems="center" justify="flex-start" spacing={1}>
          <Grid item lg={3}>
            {hasAdmissionExam ? (
              <>
                <Typography
                  fontSize="12px"
                  opacity="0.5"
                  fontWeight={300}
                  mb="8px"
                >
                  Exame admissional
                </Typography>
                <ContractDownloader
                  file={{
                    location: data?.admissionExam?.location,
                    status: data?.admissionExam?.status,
                    size: data?.admissionExam?.size,
                  }}
                  title="Exame admissional"
                />
              </>
            ) : (
              <Uploader
                accept="application/pdf"
                dropText="Exame não enviado"
                disabled
                title="Exame admissional"
                onFileUploaded={() => null}
              />
            )}
          </Grid>
          <Grid item lg={3}>
            {individualOrCollectiveAgreements ? (
              <>
                <Typography
                  fontSize="12px"
                  opacity="0.5"
                  fontWeight={300}
                  mb="8px"
                >
                  Acordos individuais ou coletivos
                </Typography>
                <ContractDownloader
                  file={{
                    location: individualOrCollectiveAgreements.location,
                    status: individualOrCollectiveAgreements.status,
                    size: individualOrCollectiveAgreements.size,
                  }}
                  remove
                  removeFn={() =>
                    handleRemoveFile('individualOrCollectiveAgreements')
                  }
                  title="Acordos individuais ou coletivos"
                />
              </>
            ) : (
              <Uploader
                accept="application/pdf"
                dropText="Adicionar contrato"
                title="Acordos individuais ou coletivos"
                onFileUploaded={setIndividualOrCollectiveAgreementsFile}
              />
            )}
          </Grid>
          <Grid item lg={3}>
            {otherDocuments ? (
              <>
                <Typography
                  fontSize="12px"
                  opacity="0.5"
                  fontWeight={300}
                  mb="8px"
                >
                  Outros
                </Typography>
                <ContractDownloader
                  file={{
                    location: otherDocuments.location,
                    status: otherDocuments.status,
                    size: otherDocuments.size,
                  }}
                  remove
                  removeFn={() => handleRemoveFile('otherDocuments')}
                  title="Outros"
                />
              </>
            ) : (
              <Uploader
                accept="application/pdf"
                dropText="Adicionar contrato"
                title="Outros"
                onFileUploaded={setOtherDocumentsFile}
              />
            )}
          </Grid>
        </Grid>
        <Box width={1} display="flex" justifyContent="flex-end" my={2} mb="8px">
          <Button
            onClick={submitContracts}
            color="secondary"
            disabled={isSubmitting}
          >
            ENVIAR DOCUMENTOS
          </Button>
        </Box>
      </TabContainer>
    </TabPanel>
  );
};

Contracts.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
};

Contracts.defaultProps = {
  data: {},
};

export default Contracts;
