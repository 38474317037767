import DateFnsUtils from '@date-io/date-fns';
import { Button, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Doctor from '../../../../assets/online-doctor.svg';
import {
  Grid,
  ModalSkeleton,
  Typography,
  Uploader,
} from '../../../../components';
import { useAuth } from '../../../../hooks/auth';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import { ACTIVITY_PERIOD, STATUS_ACTIVE } from '../../../../utils/enums';
import toFormData from '../../../../utils/formData';
import getISOStartOfDayDate from '../../../../utils/getISOStartOfDayDate';
import parseDate from '../../../../utils/parseDateString';
import { StyledGridButton } from '../styles';

const MedicalCertificateModal = ({ handleClickOnClose, setUser }) => {
  const [
    registeredMedicalCertificate,
    setRegisteredMedicalCertificate,
  ] = useState(false);
  const [document, setDocument] = useState(null);

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();
  const { user } = useAuth();

  const medicalCertificateSchema = Yup.object().shape({
    period: Yup.string().required('Campo obrigatório'),
    date: Yup.date().when('period', {
      is: ACTIVITY_PERIOD.ONE_DAY,
      then: Yup.date()
        .transform(parseDate)
        .typeError('Data Invalida')
        .required('Campo obrigatório'),
      otherwise: Yup.date().nullable(),
    }),
    startDate: Yup.date().when('period', {
      is: ACTIVITY_PERIOD.MORE_THAN_ONE_DAY,
      then: Yup.date()

        .typeError('Data Invalida')
        .required('Campo obrigatório'),
      otherwise: Yup.date().nullable(),
    }),
    finalDate: Yup.date().when('period', {
      is: ACTIVITY_PERIOD.MORE_THAN_ONE_DAY,
      then: Yup.date()
        .min(
          Yup.ref('startDate'),
          'Informe uma data posterior a data de início. ',
        )
        .typeError('Data Invalida')
        .required('Campo obrigatório'),
      otherwise: Yup.date().nullable(),
    }),
  });

  const formInitialValues = {
    startDate: null,
    finalDate: null,
    period: '',
    date: null,
  };

  const handleMedicalCertificate = useCallback(
    async (values, { setSubmitting }) => {
      try {
        dispatch(setLoading(true));

        if (!document) {
          dispatch(setSnackbar(true, 'error', 'Documento obrigatório'));
          return;
        }

        const { startDate, finalDate, date, period } = values;

        const api = AuthenticatedHttpClient();
        const medicalCertificateDetails = {
          user: collaboratorId,
          company: user.company._id,
          type: STATUS_ACTIVE.MEDICAL_CERTIFICATE,
          period,
          document,
        };

        if (values.period === ACTIVITY_PERIOD.ONE_DAY) {
          const formattedDate = getISOStartOfDayDate(date);
          medicalCertificateDetails.date = formattedDate;
        } else {
          const formattedDateStart = getISOStartOfDayDate(startDate);
          const formattedDateEnd = getISOStartOfDayDate(finalDate);
          if (
            new Date(formattedDateStart).getTime() ===
            new Date(formattedDateEnd).getTime()
          ) {
            dispatch(
              setSnackbar(
                true,
                'error',
                'A data de inicio e fim não podem ser iguais',
              ),
            );
            return;
          }

          medicalCertificateDetails.startDate = formattedDateStart;
          medicalCertificateDetails.finalDate = formattedDateEnd;
        }

        const form = toFormData(medicalCertificateDetails);

        const response = await api.post('activity', form);

        dispatch(
          setSnackbar(true, 'success', 'Atestado registrado com sucesso'),
        );
        setUser(response.data);
        setRegisteredMedicalCertificate(true);
      } catch (error) {
        dispatch(setSnackbar(true, 'error', error?.response?.data?.message));
      } finally {
        setSubmitting(false);
        dispatch(setLoading(false));
      }
    },
    [collaboratorId, dispatch, document, setUser, user.company._id],
  );

  const periodOptions = [
    {
      label: '1 dia',
      value: ACTIVITY_PERIOD.ONE_DAY,
    },
    { label: 'Mais de 1 dia', value: ACTIVITY_PERIOD.MORE_THAN_ONE_DAY },
  ];

  return (
    <ModalSkeleton
      title={
        registeredMedicalCertificate
          ? 'ATESTADO REGISTRADO'
          : 'REGISTRAR ATESTADO'
      }
      handleClickOnClose={handleClickOnClose}
      maxWidth="850px"
    >
      {registeredMedicalCertificate ? (
        <Grid container pa="18px">
          <Grid>
            <Grid container justify="center" alignItems="space" spacing={5}>
              <Grid mt="50px">
                <Grid item leg={6}>
                  <img src={Doctor} alt="" />
                </Grid>
              </Grid>
              <Grid item lg={6}>
                <Typography
                  mt="50px"
                  variant="h3"
                  fontWeight="bold"
                  align="left"
                >
                  Atestado registrado com sucesso!
                </Typography>
                <Typography opacity="0.4" mt="20px" fontSize="20px">
                  Informe ao colaborador sobre a data do atestado
                </Typography>
              </Grid>
            </Grid>
            <StyledGridButton container justify="flex-end" alignItems="center">
              <Grid item lg={2}>
                <Button onClick={() => handleClickOnClose(false)}>
                  <Typography variant="h3" fontWeight="bold">
                    CONFIRMAR
                  </Typography>
                </Button>
              </Grid>
            </StyledGridButton>
          </Grid>
        </Grid>
      ) : (
        <Grid pa="18px" mt="24px">
          <Grid
            container
            justify="center"
            alignContent="space-between"
            spacing={3}
          />
          <Formik
            initialValues={formInitialValues}
            validationSchema={medicalCertificateSchema}
            onSubmit={handleMedicalCertificate}
          >
            {({ isSubmitting, values }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <Form>
                  <Grid
                    container
                    justify="flex-start"
                    alignContent="center"
                    spacing={3}
                  >
                    <Grid item lg={12}>
                      <Field
                        component={TextField}
                        select
                        name="period"
                        variant="filled"
                        fullWidth
                        label="Período do Atestado"
                        placeholder="Período do Atestado"
                      >
                        {periodOptions.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    {values.period === ACTIVITY_PERIOD.ONE_DAY && (
                      <Grid item lg={12}>
                        <Field
                          component={KeyboardDatePicker}
                          name="date"
                          label="Data do atestado"
                          inputVariant="filled"
                          cancelLabel="CANCELAR"
                          invalidDateMessage="Data inválida"
                          format="dd/MM/yyyy"
                          fullWidth
                        />
                      </Grid>
                    )}
                    {values.period === ACTIVITY_PERIOD.MORE_THAN_ONE_DAY && (
                      <>
                        <Grid item lg={6}>
                          <Field
                            component={KeyboardDatePicker}
                            name="startDate"
                            label="Data início do atestado"
                            inputVariant="filled"
                            cancelLabel="CANCELAR"
                            invalidDateMessage="Data inválida"
                            format="dd/MM/yyyy"
                            fullWidth
                          />
                        </Grid>

                        <Grid item lg={6}>
                          <Field
                            component={KeyboardDatePicker}
                            name="finalDate"
                            label="Data término do atestado"
                            inputVariant="filled"
                            cancelLabel="CANCELAR"
                            invalidDateMessage="Data inválida"
                            format="dd/MM/yyyy"
                            fullWidth
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item lg={6}>
                      <Typography fontSize="14px">Anexar atestado</Typography>
                      <Uploader
                        accept="application/pdf"
                        dropText="Anexar"
                        onFileUploaded={setDocument}
                      />
                    </Grid>
                  </Grid>

                  <StyledGridButton
                    container
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button onClick={() => handleClickOnClose(false)}>
                        <Typography opacity="0.4" fontWeight="bold">
                          CANCELAR
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button type="submit" disabled={isSubmitting}>
                        <Typography fontWeight="bold">
                          CONFIRMAR ATESTADO
                        </Typography>
                      </Button>
                    </Grid>
                  </StyledGridButton>
                </Form>
              </MuiPickersUtilsProvider>
            )}
          </Formik>
        </Grid>
      )}
    </ModalSkeleton>
  );
};

MedicalCertificateModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
};

export default MedicalCertificateModal;
