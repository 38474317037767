import {
  DialogContent,
  Modal,
  Paper,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import searchIcon from '../../../../assets/search-icon.svg';
import { EmptyState, EnhancedTableHead } from '../../../../components';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import { REQUEST_STATUS } from '../../../../utils/enums';
import formatTimeString from '../../../../utils/formatTimeString';
import RequestStatusModal from '../../Modals/RequestStatusModal';
import {
  StyledIconButtonBlue,
  StyledTableCell,
  TableWithMorePaddingRightForTheLastElement,
} from './styles';

const headCells = [
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: true,
    label: 'Data',
  },
  {
    id: 'reason',
    numeric: false,
    disablePadding: false,
    disableSort: true,
    label: 'Motivo',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    disableSort: true,
    label: 'Status',
  },
];

const EnhancedTable = ({ newPointSolicitation, status, reason }) => {
  const [pointSolicitations, setPointSolicitations] = useState([]);
  const [pointSolicitationsCount, setPointSolicitationsCount] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  const [requestStatusModalState, setRequestStatusModalState] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const dispatch = useDispatch();

  useEffect(() => {
    const api = AuthenticatedHttpClient();

    async function getPointSolicitations() {
      try {
        dispatch(setLoading(true));
        const { data } = await api.get(`point-solicitation`, {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            order,
            orderBy,
            status: status.trim(),
            reason: reason.trim(),
          },
        });

        const formattedPointSolicitations = data.docs.map(
          pointSolicitation => ({
            _id: pointSolicitation._id,
            date: format(new Date(pointSolicitation.createdAt), 'dd/MM/yyyy'),
            reason: pointSolicitation.reason,
            status: pointSolicitation.status,
            justification: pointSolicitation.justification,
            disapprovalReason: pointSolicitation.disapprovalReason,
            pointAlert: pointSolicitation.pointAlert,
            registerDate: pointSolicitation.registerDate,
            solicitationDate: pointSolicitation.solicitationDate,
            attachment: pointSolicitation.attachment,
            dayHoursCompensated: formatTimeString(
              pointSolicitation.totalMinutesCompensated,
            ),
            compensationDate: pointSolicitation.compensationDate,
          }),
        );
        setPointSolicitationsCount(data.total);
        setPointSolicitations(formattedPointSolicitations);
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao buscar solicitações'));
      } finally {
        dispatch(setLoading(false));
      }
    }

    getPointSolicitations();
  }, [
    order,
    orderBy,
    page,
    rowsPerPage,
    status,
    reason,
    newPointSolicitation,
    dispatch,
  ]);

  const requestStatusLabel = {
    APPROVED: 'Aprovado',
    DISAPPROVED: 'Reprovado',
    IN_ANALYSIS: 'Em analise',
  };

  const requestChangesLabel = {
    CHANGE_DAY_OFF: 'Troca de folga',
    REQUEST_DAY_OFF: 'Pedido de folga',
    HOURS_COMPENSATION: 'Compensação de horas',
    POINTS_ADJUST: 'Ajuste do ponto',
    MEDICAL_CERTIFICATE: 'Atestado',
    PARTIAL_MEDICAL_CERTIFICATE: 'Atestado parcial',
  };

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [orderBy, order],
  );

  const handleChangeRowsPerPage = useCallback(event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleOpenRequestStatusModal = selectedValues => {
    setSelectedRequest(selectedValues);
    setRequestStatusModalState(true);
  };

  return pointSolicitationsCount ? (
    <Paper elevation={0}>
      <Modal
        open={requestStatusModalState}
        onClose={() => setRequestStatusModalState(false)}
      >
        <DialogContent>
          <RequestStatusModal
            handleClickOnClose={() => setRequestStatusModalState(false)}
            values={selectedRequest}
            reasonLabel={requestChangesLabel}
          />
        </DialogContent>
      </Modal>
      <TableContainer>
        <TableWithMorePaddingRightForTheLastElement size="small">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {pointSolicitations.map(row => (
              <TableRow hover tabIndex={-1} key={row._id}>
                <StyledTableCell component="th" scope="row">
                  {row.date}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {requestChangesLabel[row.reason]}
                </StyledTableCell>
                {row.status === REQUEST_STATUS.DISAPPROVED ? (
                  <StyledTableCell align="left" $reproved>
                    {requestStatusLabel[row.status]}
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="left">
                    {requestStatusLabel[row.status]}
                  </StyledTableCell>
                )}
                <StyledTableCell align="right">
                  <StyledIconButtonBlue
                    onClick={() => handleOpenRequestStatusModal(row)}
                  >
                    <img src={searchIcon} alt="" />
                  </StyledIconButtonBlue>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableWithMorePaddingRightForTheLastElement>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={pointSolicitationsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  ) : (
    <EmptyState description="Nenhuma solicitação encontrada!" />
  );
};

EnhancedTable.propTypes = {
  status: PropTypes.string,
  reason: PropTypes.string,
  newPointSolicitation: PropTypes.string,
};

EnhancedTable.defaultProps = {
  status: '',
  reason: '',
  newPointSolicitation: '',
};

export default EnhancedTable;
