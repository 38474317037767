import {
  ButtonBase,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { isAfter, isBefore, set } from 'date-fns';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useState } from 'react';
import alertIcon from '../../../../assets/alert-table.svg';
import approvedIcon from '../../../../assets/approved-table.svg';
import solicitationIcon from '../../../../assets/tableSolicitation.svg';
// import searchIcon from '../../../../assets/search-icon.svg';
import { EnhancedTableHead, Tooltip } from '../../../../components';
import { REQUEST_CHANGES, REQUEST_STATUS } from '../../../../utils/enums';
import getFileUrlFor from '../../../../utils/getFIleUrlFor';
// import PointMirrorModal from '../../Modals/PointMirrorModal';
import {
  // StyledIconButtonBlue,
  StyledTableCell,
  TableRowWithFixedHeight,
  TableWithMorePaddingRightForTheLastElement,
} from './styles';

const headCells = [
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Data',
  },

  {
    id: 'enter',
    numeric: false,
    disablePadding: false,
    label: 'Entrada',
  },
  {
    id: 'exit',
    numeric: false,
    disablePadding: false,
    label: 'Saída',
  },
  {
    id: 'hours',
    numeric: false,
    disablePadding: false,
    label: 'Horas',
  },
  {
    id: 'breaks',
    numeric: false,
    disablePadding: false,
    label: 'Pausas',
  },

  // {
  //   id: 'extraHours',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'HE',
  // },
  {
    id: 'justification',
    numeric: false,
    disablePadding: false,
    label: 'Justificativa',
  },

  {
    id: 'attachments',
    numeric: false,
    disablePadding: false,
    label: 'Anexos',
  },
  {
    id: 'reason',
    numeric: false,
    disablePadding: false,
    label: 'Motivos',
  },
  {
    id: 'statusRequest',
    numeric: false,
    disablePadding: false,
    label: 'Aprovação',
  },
];

const Alert = ({ showAlertForPastDate, hasEnterTime, alert }) => {
  if (showAlertForPastDate) {
    return <img src={alertIcon} alt="" />;
  }
  if (hasEnterTime) {
    return <img src={alert ? alertIcon : approvedIcon} alt="" />;
  }
  return <></>;
};

Alert.propTypes = {
  showAlertForPastDate: PropTypes.bool.isRequired,
  hasEnterTime: PropTypes.bool.isRequired,
  alert: PropTypes.bool.isRequired,
};

const EnhancedTable = ({ points }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  // const [
  //   pointMirrorStatusModalState,
  //   setPointMirrorStatusModalState,
  // ] = useState(false);
  // const [selectedPoint, setSelectedPoint] = useState({});

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [orderBy, order],
  );

  const reasonLabel = {
    [REQUEST_CHANGES.PARTIAL_MEDICAL_CERTIFICATE]: 'Atestado Parcial',
    [REQUEST_CHANGES.MEDICAL_CERTIFICATE]: 'Atestado',
    [REQUEST_CHANGES.POINTS_ADJUST]: 'Justificado',
    [REQUEST_CHANGES.REQUEST_DAY_OFF]: 'Folga',
    [REQUEST_CHANGES.HOURS_COMPENSATION]: 'Compensação',
  };

  const statusRequestLabel = {
    [REQUEST_STATUS.APPROVED]: 'Aprovado',
    [REQUEST_STATUS.IN_ANALYSIS]: 'Em análise',
    [REQUEST_STATUS.DISAPPROVED]: 'Reprovado',
  };

  // const handleOpenPointMirrorStatusModal = selectedValues => {
  //   setSelectedPoint(selectedValues);
  //   setPointMirrorStatusModalState(true);
  // };

  const startOfToday = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const MAX_STRING_LENGTH = 35;

  const rowData = row => {
    return (
      <>
        <StyledTableCell component="th" scope="row">
          {row.dateFormatted}
        </StyledTableCell>

        <StyledTableCell align="left" $alert={row.enterTime?.alert}>
          {row.enterTime?.value || '-----'}
        </StyledTableCell>

        <StyledTableCell align="left" $alert={row.exitTime?.alert}>
          {row.exitTime?.value || '-----'}
        </StyledTableCell>

        <StyledTableCell align="left" $alert={row.totalHours?.alert}>
          {row.totalHours?.value || '-----'}
        </StyledTableCell>

        <StyledTableCell align="left" $alert={row.totalBreaks?.alert}>
          {row.totalBreaks?.value ? <u>{row.totalBreaks?.value}</u> : '-----'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row.justification ? (
            <Tooltip
              fullText={row.justification}
              maxStringLength={MAX_STRING_LENGTH}
              underline
            />
          ) : (
            '-----'
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row.attachment ? (
            <ButtonBase download href={getFileUrlFor(row.attachment.key)}>
              <Tooltip
                fullText={row.attachment.name}
                maxStringLength={MAX_STRING_LENGTH}
                underline
              />
            </ButtonBase>
          ) : (
            '-----'
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
          {reasonLabel[row.reason] || '-----'}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          $alert={row.statusRequest === REQUEST_STATUS.DISAPPROVED}
        >
          {statusRequestLabel[row.statusRequest] || '-----'}
        </StyledTableCell>

        {/* <StyledTableCell align="left" $alert={row.extraHours?.alert}>
          <u>{row.extraHours?.value}</u>
        </StyledTableCell> */}
        {/* <StyledTableCell align="right">
          <StyledIconButtonBlue
            onClick={() => handleOpenPointMirrorStatusModal(row)}
          >
            <img src={searchIcon} alt="" />
          </StyledIconButtonBlue>
        </StyledTableCell> */}
      </>
    );
  };

  return (
    <Paper elevation={0}>
      {/* <Modal
        open={pointMirrorStatusModalState}
        onClose={() => setPointMirrorStatusModalState(false)}
      >
        <DialogContent>
          <PointMirrorModal
            handleClose={() => setPointMirrorStatusModalState(false)}
            selectedPoint={selectedPoint}
          />
        </DialogContent>
      </Modal> */}
      <TableContainer>
        <TableWithMorePaddingRightForTheLastElement size="small">
          <EnhancedTableHead
            pointMirror
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {points.map(row => {
              const showAlertForPastDate =
                isBefore(row.date, startOfToday) && !row.enterTime;

              const isSolicitationReprovedAndAfterToday =
                isAfter(row.date, startOfToday) &&
                row.statusRequest === REQUEST_STATUS.DISAPPROVED;

              return (
                <Fragment key={row.id}>
                  {row.isDayOff && (
                    <TableRowWithFixedHeight hover tabIndex={-1}>
                      <StyledTableCell opacity>
                        <img src={approvedIcon} alt="" />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row" opacity>
                        {row.dateFormatted}
                      </StyledTableCell>
                      <StyledTableCell align="left" />
                      <StyledTableCell align="left" opacity>
                        -----
                      </StyledTableCell>
                      <StyledTableCell align="left" opacity>
                        -----
                      </StyledTableCell>
                      <StyledTableCell align="left" opacity>
                        -----
                      </StyledTableCell>
                      <StyledTableCell align="left" opacity>
                        -----
                      </StyledTableCell>
                      <StyledTableCell align="left" opacity>
                        -----
                      </StyledTableCell>
                      <StyledTableCell align="left" opacity>
                        -----
                      </StyledTableCell>
                      <StyledTableCell align="left" opacity>
                        -----
                      </StyledTableCell>
                      {/* <StyledTableCell align="left" />
                      <StyledTableCell align="left" /> */}
                    </TableRowWithFixedHeight>
                  )}
                  {(!row.reason ||
                    (row.statusRequest === REQUEST_STATUS.DISAPPROVED &&
                      isBefore(row.date, startOfToday))) &&
                    !row.isDayOff && (
                      <TableRow hover tabIndex={-1}>
                        <TableCell onClick={() => {}}>
                          <Alert
                            alert={row.alert || false}
                            hasEnterTime={!!row.enterTime}
                            showAlertForPastDate={showAlertForPastDate}
                          />
                        </TableCell>
                        {rowData(row)}
                      </TableRow>
                    )}
                  {!row.isDayOff &&
                    row.reason &&
                    (row.statusRequest === REQUEST_STATUS.APPROVED ||
                      row.statusRequest === REQUEST_STATUS.IN_ANALYSIS ||
                      isSolicitationReprovedAndAfterToday) && (
                      <TableRow hover tabIndex={-1}>
                        <TableCell onClick={() => {}}>
                          <img src={solicitationIcon} alt="" />
                        </TableCell>
                        {rowData(row)}
                      </TableRow>
                    )}
                </Fragment>
              );
            })}
          </TableBody>
        </TableWithMorePaddingRightForTheLastElement>
      </TableContainer>
    </Paper>
  );
};

EnhancedTable.propTypes = {
  points: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default EnhancedTable;
