import {
  Box,
  Button,
  DialogContent,
  Grid,
  MenuItem,
  Modal,
  TextField,
} from '@material-ui/core';
import { AccountBox } from '@material-ui/icons';
import { format, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AlertGray from '../../assets/alert-gray.svg';
import ArrowDown from '../../assets/arrow-down.svg';
import Return from '../../assets/arrow-left.svg';
import CloseX from '../../assets/CloseX.svg';
import Star from '../../assets/star.svg';
import { Tab, Tabs, Typography } from '../../components';
import useUser from '../../hooks/useUser';
import { setSnackbar } from '../../redux/ducks/snackbar';
import { REGIME_TYPE, STATUS_ACTIVE } from '../../utils/enums';
import getActivityFieldData from '../../utils/getActivityFieldData';
import getFileUrlFor from '../../utils/getFIleUrlFor';
import handleStatusActive from '../../utils/handleStatusActive';
import {
  InssWithdrawalModal,
  MaternityLeaveModal,
  MedicalCertificateModal,
  PaternityLeaveModal,
  PromoteCollaboratorModal,
  StartResignationModal,
  SuspensionModal,
  UnpaidLeaveModal,
} from './Modals';
import DayOffModal from './Modals/DayOffModal';
import VacationsModal from './Modals/VacationsModal';
import {
  Container,
  DetailItem,
  DetailPaper,
  ImageContainer,
  StarContainer,
  StyledButton,
  StyledButtonBase,
  StyledButtonModalOpen,
  StyledDivModal,
  StyledDivModalClose,
  StyledGrid,
  StyledImgExpanded,
  TabsPaper,
  UserInfo,
} from './styles';
import {
  Address,
  BankData,
  Contracts,
  Documentation,
  FamilyData,
  HiringData,
  PaySlip,
  PersonalData,
} from './Tabs/index';

const ActiveColaborator = () => {
  const dispatch = useDispatch();
  const [openStartResignationModal, setOpenStartResignationModal] = useState(
    false,
  );
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [away, setAway] = useState(false);
  const [actualActivity, setActualActivity] = useState({
    startDate: '',
    finalDate: '',
    withoutForecastOfEnding: false,
  });
  const [details, setDetails] = useState({
    experiencePeriod: '',
    status: '',
    admissionDate: '',
    regime: '',
    eSocial: '',
    points: '',
    workday: '',
    scale: '',
    startDate: '',
    finalDate: '',
    withoutForecastOfEnding: false,
  });
  const [status, setStatus] = useState('');
  const [
    openPromoteCollaboratorModal,
    setOpenPromoteCollaboratorModal,
  ] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [user, setUser] = useState({});
  const [userInfo, setUserInfo] = useState({});

  const history = useHistory();
  const { userId: collaboratorId } = useParams();
  const { user: userData, userInfo: userInfoData } = useUser(collaboratorId);

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
    if (userInfoData) {
      setUserInfo(userInfoData);
    }
  }, [userData, userInfoData]);

  const getActualActivity = useCallback(async () => {
    const startedIn = await getActivityFieldData(
      user,
      'startDate',
      user.statusActive,
    );
    const endsIn = await getActivityFieldData(
      user,
      'finalDate',
      user.statusActive,
    );

    const activityFormatted = {
      startDate: startedIn,
      finalDate: endsIn,
    };
    setActualActivity(activityFormatted);
  }, [user]);

  useEffect(() => {
    const {
      email,
      fullName,
      isWhatsApp,
      phone,
      hiringData,
      admissionDate,
      statusActive,
      recordPoints,
      activity,
    } = user;

    const isCLT = hiringData?.regime === REGIME_TYPE.CLT;

    let experiencePeriod = '----';

    if (hiringData) {
      const parsedAdmissionDate = parseISO(admissionDate);
      if (isCLT) {
        const beginningOfExperiencePeriod = parseISO(
          hiringData.beginningOfFirstPeriod,
        );
        const endOfExperiencePeriod = parseISO(hiringData.endOfSecondPeriod);
        const formatedBeginning = format(
          beginningOfExperiencePeriod,
          'dd/MM/yyyy',
          new Date(),
        );
        const formatedEnd = format(
          endOfExperiencePeriod,
          'dd/MM/yyyy',
          new Date(),
        );

        experiencePeriod = `${formatedBeginning} - ${formatedEnd}`;
      }

      if (activity.length) {
        getActualActivity();
      }

      setDetails({
        experiencePeriod,
        status: handleStatusActive(statusActive),
        admissionDate: format(parsedAdmissionDate, 'dd/MM/yyyy', new Date()),
        regime: hiringData.regime,
        eSocial: '???',
        points: recordPoints ? 'Ativo' : 'Desativado',
        workday: hiringData.workday.name,
        scale: hiringData.workday.scaleType,
      });

      if (
        statusActive !== STATUS_ACTIVE.HIRED &&
        statusActive !== STATUS_ACTIVE.WORK_EXPERIENCE
      ) {
        setAway(true);
      }
    }

    setUserInfo({
      email,
      fullName,
      isWhatsApp,
      phone,
    });
  }, [user, getActualActivity]);

  const modalSelect = {
    dayOff: (
      <DayOffModal
        handleClickOnClose={() => setOpenSelectModal(false)}
        setUser={setUser}
        message={{
          success: 'Folga registrada com sucesso',
          error: 'Falha no registro da folga',
        }}
        modalType="Folga"
        modalValue={STATUS_ACTIVE.DAY_OFF}
      />
    ),

    vacation: (
      <VacationsModal
        handleClickOnClose={() => setOpenSelectModal(false)}
        setUser={setUser}
        message={{
          success: 'Férias registradas com sucesso',
          error: 'Falha no registro das férias',
        }}
        modalType="Férias"
        modalValue={STATUS_ACTIVE.VACATION}
      />
    ),

    suspension: (
      <SuspensionModal
        handleClickOnClose={() => setOpenSelectModal(false)}
        setUser={setUser}
        message={{
          success: 'Suspensão registrada com sucesso',
          error: 'Falha no registro da suspensão',
        }}
        modalType="Suspensão"
        modalValue={STATUS_ACTIVE.SUSPENSION}
      />
    ),
    unpaidLeave: (
      <UnpaidLeaveModal
        handleClickOnClose={() => setOpenSelectModal(false)}
        setUser={setUser}
        message={{
          success: 'Licença não remunerada registrada com sucesso',
          error: 'Falha no registro da Licença não remunerada',
        }}
        modalType="Licença não remunerada"
        modalValue={STATUS_ACTIVE.UNPAID_LEAVE}
      />
    ),
    INSSWithdrawal: (
      <InssWithdrawalModal
        handleClickOnClose={() => setOpenSelectModal(false)}
        setUser={setUser}
      />
    ),
    medicalCertificate: (
      <MedicalCertificateModal
        handleClickOnClose={() => setOpenSelectModal(false)}
        setUser={setUser}
      />
    ),
    maternityLeave: (
      <MaternityLeaveModal
        handleClickOnClose={() => setOpenSelectModal(false)}
        setUser={setUser}
      />
    ),
    paternityLeave: (
      <PaternityLeaveModal
        handleClickOnClose={() => setOpenSelectModal(false)}
        setUser={setUser}
      />
    ),
  };

  const statusOptions = [
    {
      label: 'Selecione',
      value: '',
    },
    {
      label: 'Atestado',
      value: 'medicalCertificate',
    },
    {
      label: 'Afastamento INSS',
      value: 'INSSWithdrawal',
    },
    {
      label: 'Folga',
      value: 'dayOff',
    },
    {
      label: 'Férias',
      value: 'vacation',
    },
    {
      label: 'Suspensão',
      value: 'suspension',
    },
    {
      label: 'Licença maternidade',
      value: 'maternityLeave',
    },
    {
      label: 'Licença paternidade',
      value: 'paternityLeave',
    },
    {
      label: 'Licença não remunerada',
      value: 'unpaidLeave',
    },
  ];

  const setOpenAllModals = useCallback(() => {
    if (status) {
      setOpenSelectModal(true);
    } else {
      dispatch(setSnackbar(true, 'error', 'Por favor, selecione um status'));
    }
  }, [status, dispatch]);

  const handleChangeTab = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleOpenPhotoExpanded = () => {
    setOpen(true);
  };

  const handleClosePhotoExpanded = () => {
    setOpen(false);
  };

  const handleChangeStatus = useCallback(event => {
    setStatus(event.target.value);
  }, []);

  const photoExpanded = (
    <StyledDivModal>
      <StyledDivModalClose>
        <Button onClick={handleClosePhotoExpanded}>
          <img src={CloseX} alt="" />
        </Button>
      </StyledDivModalClose>
      {user.personalData && user.personalData.profilePicture && (
        <StyledImgExpanded
          src={getFileUrlFor(user.personalData.profilePicture.key)}
          alt=""
        />
      )}
    </StyledDivModal>
  );

  return (
    <Container>
      <StyledButtonBase onClick={() => history.goBack()}>
        <img src={Return} alt="" />
      </StyledButtonBase>
      <Grid container alignItems="center" justify="space-between">
        {user._id && (
          <UserInfo>
            {user.personalData && user.personalData.profilePicture ? (
              <StyledButtonModalOpen onClick={handleOpenPhotoExpanded}>
                {user.personalData && user.personalData.profilePicture && (
                  <ImageContainer
                    image={getFileUrlFor(user.personalData.profilePicture.key)}
                  />
                )}
              </StyledButtonModalOpen>
            ) : (
              <AccountBox color="primary" fontSize="large" />
            )}
            <Modal open={open} onClose={handleClosePhotoExpanded}>
              {photoExpanded}
            </Modal>
            <div>
              <strong>{user.fullName.toUpperCase()}</strong>
              <span>{user.jobRole.name.toUpperCase()}</span>
              <span style={{ fontWeight: 300 }}>
                {user.department.name.toUpperCase()}
              </span>
            </div>
          </UserInfo>
        )}
        <Box display="flex" alignItems="center">
          <div>
            <StarContainer>
              <StyledButtonBase
                onClick={() => setOpenPromoteCollaboratorModal(true)}
                color="secondary"
              >
                <img src={Star} alt="" />
              </StyledButtonBase>
              <Button
                color="secondary"
                onClick={() => setOpenPromoteCollaboratorModal(true)}
              >
                PROMOVER COLABORADOR
              </Button>
            </StarContainer>
            <StyledButtonBase
              onClick={() => setOpenStartResignationModal(true)}
              color="error"
            >
              <img src={ArrowDown} alt="" />
            </StyledButtonBase>
            <StyledButton
              textcolor="error"
              onClick={() => setOpenStartResignationModal(true)}
            >
              INICIAR DEMISSÃO
            </StyledButton>
          </div>
        </Box>
      </Grid>
      <DetailPaper elevation={1}>
        <Typography color="primary" variant="h3">
          DETALHES
        </Typography>
        <StyledGrid
          container
          alignItems="center"
          justify="space-between"
          alignContent="center"
          mt={16}
        >
          <Box width={0.6} display="flex" flexDirection="column" pr={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <DetailItem>
                <span>Período de Experiência</span>
                <p>{details.experiencePeriod}</p>
              </DetailItem>
              <DetailItem>
                <span>Status</span>
                <Typography
                  fontWeight={away ? 700 : 400}
                  color={away ? 'error' : 'secondary'}
                >
                  {details.status}
                </Typography>
              </DetailItem>
              <DetailItem>
                <span>Data admissão</span>
                <p>{details.admissionDate}</p>
              </DetailItem>
              <DetailItem>
                <span>Regime trabalhista</span>
                <p>{details.regime}</p>
              </DetailItem>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={3}
            >
              <DetailItem>
                <span>Qualificação E-social</span>
                <p>{details.eSocial}</p>
              </DetailItem>
              <DetailItem>
                <span>Módulo de ponto</span>
                <p>{details.points}</p>
              </DetailItem>
              <DetailItem>
                <span>Jornada</span>
                <p>{details.workday}</p>
              </DetailItem>
              <DetailItem>
                <span>Escala</span>
                <p>{details.scale}</p>
              </DetailItem>
            </Box>
          </Box>
          <Box width={0.4} display="flex" flexDirection="column" pl={2}>
            <span style={{ marginBottom: '8px' }}>Alterar status</span>
            <TextField
              select
              value={status}
              onChange={handleChangeStatus}
              variant="filled"
              fullWidth
              label="Selecione"
            >
              {statusOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {away && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                mt={1}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  fontWeight={300}
                  fontSize="12px"
                  opacity="0.5"
                >
                  <img src={AlertGray} alt="" style={{ marginRight: '8px' }} />
                  Início afastamento: {actualActivity.startDate}
                </Box>
                <Box fontWeight={300} fontSize="12px" opacity="0.5">
                  Retorno em: {actualActivity.finalDate}
                </Box>
              </Box>
            )}
            <Box display="flex" flexDirection="row-reverse" mt={2}>
              <Button color="secondary" onClick={setOpenAllModals}>
                APLICAR NOVO STATUS
              </Button>
            </Box>
          </Box>
        </StyledGrid>
      </DetailPaper>
      <TabsPaper square elevation={1}>
        <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable">
          <Tab label="DADOS CONTRATAÇÃO" index={0} />
          <Tab label="DADOS PESSOAIS" index={1} />
          <Tab label="DADOS DA FAMÍLIA" index={2} />
          <Tab label="DOCUMENTOS" index={3} />
          <Tab label="ENDEREÇO" index={4} />
          <Tab label="DADOS BANCÁRIOS" index={5} />
          <Tab label="CONTRATOS" index={6} />
          {user?.hiringData?.regime === REGIME_TYPE.CLT && (
            <Tab label="HOLERITES" index={7} />
          )}
        </Tabs>
        {user._id && (
          <>
            <HiringData
              value={tabValue}
              index={0}
              setUser={setUser}
              data={user.hiringData}
              baseSalary={user.jobRole.baseSalary}
            />
            <PersonalData
              value={tabValue}
              index={1}
              setUser={setUser}
              data={user.personalData}
              userInfo={userInfo}
            />
            <FamilyData
              value={tabValue}
              index={2}
              data={user.familyData}
              collaboratorCpf={user?.documents?.cpf}
              setUser={setUser}
            />
            <Documentation
              value={tabValue}
              index={3}
              data={user.documents}
              dependents={user?.familyData?.dependents}
              setUser={setUser}
            />
            <Address
              value={tabValue}
              index={4}
              data={user.address}
              setUser={setUser}
            />
            <BankData
              value={tabValue}
              index={5}
              data={user.bankData}
              setUser={setUser}
            />
            <Contracts
              value={tabValue}
              index={6}
              data={user}
              setUser={setUser}
            />
            {user?.hiringData?.regime === REGIME_TYPE.CLT && (
              <PaySlip
                admissionDate={user.admissionDate}
                value={tabValue}
                index={7}
              />
            )}
          </>
        )}
        <Modal open={openSelectModal} onClose={() => setOpenSelectModal(false)}>
          <DialogContent>{modalSelect[status]}</DialogContent>
        </Modal>
        <Modal
          open={openStartResignationModal}
          onClose={() => setOpenStartResignationModal(false)}
        >
          <DialogContent>
            <StartResignationModal
              handleClickOnClose={() => setOpenStartResignationModal(false)}
            />
          </DialogContent>
        </Modal>
        <Modal
          open={openPromoteCollaboratorModal}
          onClose={() => setOpenPromoteCollaboratorModal(false)}
        >
          <DialogContent>
            <PromoteCollaboratorModal
              handleOnClose={() => setOpenPromoteCollaboratorModal(false)}
            />
          </DialogContent>
        </Modal>
      </TabsPaper>
    </Container>
  );
};

export default ActiveColaborator;
