import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import contractSign from '../../../../assets/contractSign.svg';
import { CollaboratorButton, Typography } from '../../../../components';
import withCollaboratorSkeleton from '../../../../components/CollaboratorSkeleton';
import { StyledLink } from './styles';

const StepContract = ({ userData, getUserData }) => {
  const [isContractSigned, setContractSigned] = useState(false);

  useEffect(() => {
    if (userData?.contracts?.employementContract?.status === 'approved') {
      setContractSigned(true);
    }
  }, [userData]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      width={1}
      overflow="auto"
      p={5}
    >
      <Box>
        <Typography fontWeight="bold" fontSize="25px" mb="40px">
          Assinar contrato
        </Typography>
        <Box textAlign="center">
          <img src={contractSign} alt="" />
        </Box>
        <Typography fontSize="14px" opacity="0.6" mb="20px">
          Contrato de trabalho
        </Typography>

        <Box bgcolor="#fbfbfb" borderRadius="6px" padding="16px">
          {!isContractSigned ? (
            <Typography>
              Aguarde o recebimento, <strong>no seu e-mail</strong>, de um link
              para a assinatura do seu
              <strong> contrato de trabalho</strong>, por favor acesse o link e
              registre sua assinatura para darmos continuidade no seu acesso ao
              sistema!
            </Typography>
          ) : (
            <Typography>
              Seu contrato foi assinado, clique no botão abaixo para prosseguir
              com o cadastro.
            </Typography>
          )}
        </Box>
      </Box>
      {isContractSigned ? (
        <Box width={1} display="flex" justifyContent="flex-end" mt="auto">
          <StyledLink to="/create-password">
            <CollaboratorButton text="CRIAR MINHA SENHA DE ACESSO" />
          </StyledLink>
        </Box>
      ) : (
        <Box width={1} display="flex" justifyContent="flex-end" mt="auto">
          <CollaboratorButton
            onClick={getUserData}
            text="VERIFICAR ASSINATURA"
          />
        </Box>
      )}
    </Box>
  );
};

StepContract.propTypes = {
  userData: PropTypes.objectOf(PropTypes.any).isRequired,
  getUserData: PropTypes.func.isRequired,
};

export default withCollaboratorSkeleton(StepContract);
