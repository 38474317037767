import {
  Checkbox,
  Grid,
  IconButton,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import Delete from '../../../assets/deleteIcon.svg';
import searchIcon from '../../../assets/search-icon.svg';
import { DeleteModal, EmptyState, Tooltip } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { SCALE_TYPES } from '../../../utils/enums';
import { StyledMenuItem } from '../styles';
import {
  StyledIconButtonBlue,
  StyledIconButtonRed,
  StyledTableCell,
} from './styles';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'scale',
    numeric: false,
    disablePadding: false,
    label: 'Escala',
  },
  {
    id: 'pointType',
    numeric: false,
    disablePadding: false,
    label: 'Tipo de ponto',
  },
  { id: 'holidays', numeric: false, disablePadding: false, label: 'Feriados' },
  {
    id: 'collaborators',
    numeric: false,
    disablePadding: false,
    label: 'Colaboradores',
  },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Ação</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTable = ({
  textFilter,
  holidayFilter,
  statusFilter,
  pointTypeFilter,
  scaleFilter,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [journeys, setJourneys] = useState([]);
  const [journeyCount, setJourneyCount] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedJourney, setSelectedJourney] = useState({});
  const [forceGetJourneys, setForceGetJourneys] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getJourneys() {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        const { data: receivedJourneys } = await api.get(
          `workday/paginate/${user.company._id}`,
          {
            params: {
              page: page + 1,
              limit: rowsPerPage,
              order,
              orderBy,
              text: textFilter,
              holidayFilter: holidayFilter.trim(),
              statusFilter: statusFilter.trim(),
              pointTypeFilter: pointTypeFilter.trim(),
              scaleFilter: scaleFilter.trim(),
            },
          },
        );

        const paginatedJourneys = receivedJourneys.docs.map(journey => ({
          _id: journey._id,
          name: journey.name,
          company: journey.company,
          holidays: journey.holidays,
          pointType: journey.pointType,
          intervalCompensation: journey.intervalCompensation,
          entryDelayTime: journey.entryDelayTime,
          breakDelayTime: journey.breakDelayTime,
          anticipationExitTime: journey.anticipationExitTime,
          breakAnticipationTime: journey.breakAnticipationTime,
          limited: journey.limited,
          limitTime: journey.limitTime,
          extraHourPerDay: journey.extraHourPerDay,
          scaleType: journey.scaleType,
          standardWeekScale: journey.standardWeekScale,
          standardDayScale: journey.standardDayScale,
          numberOfBreaks: journey.numberOfBreaks,
          breaks: journey.breaks,
          status: journey.status,
          totalCollaborators: journey.totalEmployees,
          createdAt: journey.createdAt,
          nightAdditional: journey.nightAdditional,
          totalHours: journey.totalHours,
        }));

        setJourneyCount(receivedJourneys.total);
        setJourneys(paginatedJourneys);
      } catch (e) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possivel obter as jornadas!'),
        );
      } finally {
        dispatch(setLoading(false));
      }
    }

    getJourneys();
  }, [
    user,
    order,
    orderBy,
    page,
    rowsPerPage,
    textFilter,
    holidayFilter,
    statusFilter,
    pointTypeFilter,
    scaleFilter,
    dispatch,
    forceGetJourneys,
  ]);

  const handleOpenModalDeleteJourney = journey => {
    setOpenDeleteModal(true);
    setSelectedJourney(journey);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = journeys.map(n => n._id);
      setSelected(newSelecteds);

      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteJourney = useCallback(
    async id => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        await api.delete(`/workday/${id}`);
        setForceGetJourneys(prevState => !prevState);
        dispatch(setSnackbar(true, 'success', 'Jornada removida com sucesso'));
      } catch (error) {
        const errorMessage =
          error?.response?.data?.message || 'Falha ao remover jornada';
        dispatch(setSnackbar(true, 'error', errorMessage));
      } finally {
        dispatch(setLoading(false));
        setOpenDeleteModal(false);
      }
    },
    [dispatch],
  );

  const handleDeleteSelectedsJourneys = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const api = AuthenticatedHttpClient();

      await api.delete(`/workday`, {
        data: { selectedJourneys: selected },
      });

      dispatch(
        setSnackbar(true, 'success', 'Jornada(s) removida(s) com sucesso'),
      );
      setForceGetJourneys(prevState => !prevState);
      setSelected([]);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || 'Falha ao remover jornada(s)';
      dispatch(setSnackbar(true, 'error', errorMessage));
    } finally {
      dispatch(setLoading(false));
      handleCloseMenu();
    }
  }, [dispatch, setForceGetJourneys, selected]);

  const handleUpdateSelectedsJourneys = useCallback(
    async status => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        await api.put(`/workday`, { selectedJourneys: selected, status });

        dispatch(
          setSnackbar(true, 'success', 'Jornada(s) atualizada(s) com sucesso'),
        );
        setForceGetJourneys(prevState => !prevState);
        setSelected([]);
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao atualizar jornada(s)'));
      } finally {
        dispatch(setLoading(false));
        handleCloseMenu();
      }
    },
    [dispatch, setForceGetJourneys, selected],
  );

  const scaleLabel = {
    [SCALE_TYPES['5x2']]: 'Padrão semanal 5x2',
    [SCALE_TYPES['6x1']]: 'Padrão Semanal - 6x1',
    [SCALE_TYPES['12/36']]: 'Escala - 12/36',
    [SCALE_TYPES['24/48']]: 'Escala - 24/48',
    [SCALE_TYPES['5x1']]: 'Escala - 5x1',
    [SCALE_TYPES.CALL_CENTER_36]: 'Call Center - 36 horas',
    [SCALE_TYPES.CALL_CENTER_30]: 'Call Center - 30 horas',
  };

  const { path } = useRouteMatch();

  const MAX_STRING_LENGTH = 35;

  return !journeyCount ? (
    <EmptyState description="Nenhuma jornada encontrada!" />
  ) : (
    <Paper elevation={0}>
      <Grid container alignItems="center" justify="space-between">
        <Typography variant="h3">RELAÇÃO DE JORNADAS CADASTRADAS</Typography>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOpenMenu}
        >
          <Settings />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <StyledMenuItem onClick={() => handleUpdateSelectedsJourneys(true)}>
            ATIVAR SELECIONADOS
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleUpdateSelectedsJourneys(false)}>
            DESATIVAR SELECIONADOS
          </StyledMenuItem>
          <StyledMenuItem onClick={handleDeleteSelectedsJourneys}>
            EXCLUIR SELECTIONADOS
          </StyledMenuItem>
        </Menu>
      </Grid>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={journeys.length}
          />

          <TableBody>
            {journeys.map((row, index) => {
              const isItemSelected = isSelected(row._id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row._id}
                  selected={isItemSelected}
                >
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      onClick={event => handleClick(event, row._id)}
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    opacity={!row.status}
                  >
                    <Tooltip
                      fullText={row.name}
                      maxStringLength={MAX_STRING_LENGTH}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left" opacity={!row.status}>
                    {scaleLabel[row.scaleType]}
                  </StyledTableCell>
                  <StyledTableCell align="left" opacity={!row.status}>
                    {row.pointType}
                  </StyledTableCell>
                  <StyledTableCell align="left" opacity={!row.status}>
                    {row.holidays ? 'Aplicado' : 'Não aplicado'}
                  </StyledTableCell>
                  <StyledTableCell align="left" opacity={!row.status}>
                    {row.totalCollaborators}
                  </StyledTableCell>
                  <StyledTableCell align="left" opacity={!row.status}>
                    {row.status ? 'Ativo' : 'Desativado'}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    <StyledIconButtonBlue
                      component={Link}
                      disabled={row.totalCollaborators > 0}
                      opacity={row.totalCollaborators > 0 ? 0.5 : 1}
                      to={{
                        pathname: `${path}/edit-journey`,
                        state: { selectedJourney: row },
                      }}
                    >
                      <img src={searchIcon} alt="" />
                    </StyledIconButtonBlue>
                    <StyledIconButtonRed
                      onClick={() => handleOpenModalDeleteJourney(row)}
                    >
                      <img src={Delete} alt="" />
                    </StyledIconButtonRed>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={journeyCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <DeleteModal
        title="Remover Jornada"
        description={`Tem certeza que deseja remover a jornada ${selectedJourney.name}?`}
        modalStatus={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleDelete={() => handleDeleteJourney(selectedJourney._id)}
      />
    </Paper>
  );
};

EnhancedTable.propTypes = {
  textFilter: PropTypes.string,
  holidayFilter: PropTypes.string,
  statusFilter: PropTypes.string,
  pointTypeFilter: PropTypes.string,
  scaleFilter: PropTypes.string,
};

EnhancedTable.defaultProps = {
  textFilter: '',
  holidayFilter: '',
  statusFilter: '',
  pointTypeFilter: '',
  scaleFilter: '',
};

export default EnhancedTable;
