import { Grid, useTheme } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import backgroundLogo from '../../assets/bg-login.svg';
import { useAuth } from '../../hooks/auth';
import {
  StyledButton,
  StyledCheckbox,
  StyledGrid,
  StyledImg,
  StyledLink,
  StyledTextField,
  StyledTypography,
} from './styles';

const loginInitialValues = {
  email: '',
  password: '',
  rememberPassword: false,
};

const Login = () => {
  const theme = useTheme();
  const { signIn } = useAuth();

  const validateLogin = useCallback(values => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Campo obrigatório';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Endereço de e-mail inválido';
    }
    if (!values.password) {
      errors.password = 'Digite uma senha';
    }

    return errors;
  }, []);

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      await signIn({
        email: values.email,
        password: values.password,
      });
      setSubmitting(false);
    },
    [signIn],
  );

  return (
    <StyledGrid
      height="100vh"
      container
      direction="row"
      justify="space-between"
      alignItems="stretch"
    >
      <StyledGrid
        container
        item
        lg={7}
        bgcolor={theme.palette.primary.main}
        alignItems="center"
        justify="center"
      >
        <Grid container direction="column" alignItems="center" justify="center">
          <StyledTypography variant="h1">
            Olá,
            <b> bem vindo!</b>
          </StyledTypography>
          <Grid item lg={6}>
            <StyledTypography
              component="p"
              align="center"
              mt="20px"
              weight={300}
            >
              Sistema completo de controle de ponto e gestão de RH
            </StyledTypography>
          </Grid>
          <StyledImg src={backgroundLogo} alt="" />
        </Grid>
      </StyledGrid>
      <StyledGrid
        container
        item
        lg={5}
        bgcolor={theme.palette.secondary.main}
        alignItems="center"
        justify="center"
      >
        <StyledGrid container direction="column" width="80%">
          <StyledTypography variant="h1">
            <b>LOGIN</b>
          </StyledTypography>
          <StyledTypography component="p" mb={20} weight={300}>
            Por favor informe os dados de acesso abaixo.
          </StyledTypography>
          <Formik
            initialValues={loginInitialValues}
            validate={validateLogin}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field
                  component={StyledTextField}
                  name="email"
                  type="email"
                  label="E-mail"
                  fullWidth
                  variant="filled"
                  color="primary"
                  mt="80px"
                />
                <br />
                <Field
                  component={StyledTextField}
                  name="password"
                  type="password"
                  label="Password"
                  fullWidth
                  variant="filled"
                  color="primary"
                />
                <br />
                <Grid container alignItems="center" justify="space-between">
                  <Field
                    component={StyledCheckbox}
                    name="rememberPassword"
                    type="checkbox"
                    color="primary"
                    Label={{ label: 'Lembrar e-mail e senha' }}
                  />
                  <StyledLink to="">Esqueci minha senha</StyledLink>
                </Grid>
                <StyledButton
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                >
                  ACESSAR
                </StyledButton>
              </Form>
            )}
          </Formik>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

Login.propTypes = {};

export default Login;
