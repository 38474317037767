import styled from 'styled-components';

export const Numbers = styled.div`
  color: #fff;
  padding-top: 5px;
  display: block;
  width: 30px;
  height: 30px;
  background: ${({ theme }) =>
    `${theme.palette.primary.main} 0% 0% no-repeat padding-box`};
  border-radius: 50%;

  opacity: 1;
`;

export const StyledImg = styled.img`
  width: 95%;
`;
