import { Box, Divider, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Grid, ModalSkeleton, Typography } from '../../../../components';
import { REQUEST_CHANGES, REQUEST_STATUS } from '../../../../utils/enums';
import ChangeDayOffInfo from '../../Components/ChangeDayOffInfo';
import HoursCompensationInfo from '../../Components/HoursCompensationInfo';
import MedicalCertificateInfo from '../../Components/MedicalCertificateInfo';
import PointAdjustInfo from '../../Components/PointAdjustInfo';
import RequestDayOffInfo from '../../Components/RequestDayOffInfo';
import { ContainerModal } from './styles';

const RequestStatusModal = ({ handleClickOnClose, values, reasonLabel }) => {
  return (
    <ModalSkeleton
      title="SOLICITAÇÃO"
      handleClickOnClose={handleClickOnClose}
      maxWidth="916px"
    >
      <ContainerModal>
        <Grid container alignItems="center" justify="space-between" spacing={2}>
          {values.status === REQUEST_STATUS.APPROVED && (
            <>
              <Box px={3} py={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize="14px">
                    Status
                  </Typography>
                  <Typography opacity={0.5} fontSize="16px">
                    Aprovado
                  </Typography>
                </Grid>
              </Box>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}

          {values.status === REQUEST_STATUS.DISAPPROVED && (
            <>
              <Box px={3} py={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize="14px">
                    Status
                  </Typography>
                  <Typography textcolor="#FF6B6B" fontSize="16px">
                    Reprovado
                  </Typography>
                </Grid>
              </Box>
              <Grid item xs={12}>
                <TextField
                  multiline={3}
                  name="disapprovalReason"
                  label="Motivo da Reprovação"
                  variant="filled"
                  value={values.disapprovalReason}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {values.reason === REQUEST_CHANGES.MEDICAL_CERTIFICATE && (
            <MedicalCertificateInfo values={values} reasonLabel={reasonLabel} />
          )}
          {values.reason === REQUEST_CHANGES.REQUEST_DAY_OFF && (
            <RequestDayOffInfo values={values} reasonLabel={reasonLabel} />
          )}
          {values.reason === REQUEST_CHANGES.POINTS_ADJUST && (
            <PointAdjustInfo values={values} reasonLabel={reasonLabel} />
          )}
          {values.reason === REQUEST_CHANGES.HOURS_COMPENSATION && (
            <HoursCompensationInfo values={values} reasonLabel={reasonLabel} />
          )}
          {values.reason === REQUEST_CHANGES.CHANGE_DAY_OFF && (
            <ChangeDayOffInfo values={values} reasonLabel={reasonLabel} />
          )}
        </Grid>
      </ContainerModal>
    </ModalSkeleton>
  );
};

RequestStatusModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  reasonLabel: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default RequestStatusModal;
