/* eslint-disable react/prop-types */
import React from 'react';
import NumberFormat from 'react-number-format';

export const MoneyInput = props => {
  const { inputRef, name, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      name={name}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
      prefix="R$ "
    />
  );
};

export const DateInput = props => {
  const { inputRef, name, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      name={name}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.formattedValue,
          },
        });
      }}
      format="##/##/####"
    />
  );
};

export const TimeInput = ({ inputRef, name, onChange, ...other }) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      name={name}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.formattedValue,
          },
        });
      }}
      format="##:##"
    />
  );
};

export const CPFInput = props => {
  const { inputRef, name, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      name={name}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      format="###.###.###-##"
    />
  );
};

export const PISInput = props => {
  const { inputRef, name, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      name={name}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      format="###.#####.##-#"
    />
  );
};

export const NumericInput = props => {
  const { inputRef, name, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      name={name}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      isNumericString
      allowLeadingZeros
    />
  );
};

export const CEPInput = props => {
  const { inputRef, name, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      name={name}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      format="##.###-###"
    />
  );
};

export const PhoneInput = props => {
  const { inputRef, name, onChange, ...other } = props;

  function telephone(v) {
    return v
      .replace(/\D/g, '')
      .replace(/^(\d\d)(\d)/g, '($1) $2')
      .replace(/(\d{4,5})(\d{4})/, '$1-$2');
  }

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      name={name}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      format={telephone}
      maxLength={15}
    />
  );
};

export const PercentageInput = props => {
  const { inputRef, name, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      name={name}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      isNumericString
      allowNegative={false}
      suffix="%"
      fixedDecimalScale={false}
    />
  );
};
