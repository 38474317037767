import { Button, Grid } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerModal = styled.div`
  padding: 40px 40px 5px 40px;
`;

export const StyledGrid = styled(Grid)`
  height: 80px;
  margin-top: ${({ mt }) => mt || 0}px;
`;

export const ActionsBar = styled(Grid)`
  margin-top: 62px;

  button {
    padding: 0 20px;
    font-weight: 700;
    font-size: 14px;
  }
`;

export const ButtonWithColor = styled(Button)`
  color: #fe7676;
  font-weight: bold;
  opacity: 1;
`;
