import { useTheme } from '@material-ui/core';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import StepContractImg from '../../assets/acceptTerms.svg';
import admissionExam from '../../assets/admissionExam.svg';
import StepPasswordImg from '../../assets/password-step.svg';
import Step1to6 from '../../assets/skeleton-background.svg';
import Route from '../../routes/Route';
import { ROLES } from '../../utils/enums';
import AdmissionExam from './Steps/AdmissionExam';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Step6 from './Steps/Step6';
import StepContract from './Steps/StepContract';
import StepCreatePassword from './Steps/StepCreatePassword';
import StepEmailConfirm from './Steps/StepEmailConfirm';

const AdmissionSteps = () => {
  const { palette } = useTheme();

  return (
    <Router basename="/steps">
      <Route
        path="/1"
        exact
        isPrivate
        authorization={[ROLES.ADMISSION_PROCESS, ROLES.WAITING_CONTRACT_SIGN]}
        component={() => (
          <Step1
            visibilityStep="active"
            stepNumber={1}
            visibilityProgress="active"
            skeletonImg={Step1to6}
            stepTitle="Sobre você"
            stepDescription="Para iniciarmos o formulário de admissão precisamos que você informe seus dados pessoais."
          />
        )}
      />
      <Route
        path="/2"
        exact
        isPrivate
        authorization={[ROLES.ADMISSION_PROCESS, ROLES.WAITING_CONTRACT_SIGN]}
        component={() => (
          <Step2
            visibilityStep="active"
            stepNumber={2}
            visibilityProgress="active"
            skeletonImg={Step1to6}
            stepTitle="Dados da família"
            stepDescription="Para o segundo passo informe os dados de sua família, seu estado civil e se possui dependentes."
          />
        )}
      />
      <Route
        path="/3"
        exact
        isPrivate
        authorization={[ROLES.ADMISSION_PROCESS, ROLES.WAITING_CONTRACT_SIGN]}
        component={() => (
          <Step3
            visibilityStep="active"
            stepNumber={3}
            visibilityProgress="active"
            skeletonImg={Step1to6}
            stepTitle="Documentos"
            stepDescription="Agora nesta etapa precisamos que você informe os dados e anexe uma foto de cada documento solicitado ao lado, importante verificar as especificações de cada foto."
          />
        )}
      />
      <Route
        path="/4"
        exact
        isPrivate
        authorization={[ROLES.ADMISSION_PROCESS, ROLES.WAITING_CONTRACT_SIGN]}
        component={() => (
          <Step4
            visibilityStep="active"
            stepNumber={4}
            visibilityProgress="active"
            skeletonImg={Step1to6}
            stepTitle="Endereço"
            stepDescription="Informe agora as informações de onde você reside e anexe um comprovante de endereço."
          />
        )}
      />
      <Route
        path="/5"
        exact
        isPrivate
        authorization={[ROLES.ADMISSION_PROCESS, ROLES.WAITING_CONTRACT_SIGN]}
        component={() => (
          <Step5
            visibilityStep="active"
            stepNumber={5}
            visibilityProgress="active"
            progress={80}
            skeletonImg={Step1to6}
            stepTitle="Dados Bancários"
            stepDescription="Para questões salariais precisamos que você informe seus dados bancários, o mesmo será utilizado para os pagamentos."
          />
        )}
      />
      <Route
        path="/6"
        exact
        isPrivate
        authorization={[ROLES.ADMISSION_PROCESS, ROLES.WAITING_CONTRACT_SIGN]}
        component={() => (
          <Step6
            visibilityStep="active"
            stepNumber={6}
            visibilityProgress="active"
            progress={100}
            skeletonImg={Step1to6}
            step="Formulário"
            stepTitle="Concluído!"
            stepDescription="Parabéns, você preencheu todos os formulários, agora basta aguardar enquanto analisamos todos os dados!"
          />
        )}
      />
      <Route
        path="/admission-exam"
        exact
        isPrivate
        authorization={[ROLES.ADMISSION_PROCESS, ROLES.WAITING_CONTRACT_SIGN]}
        component={() => (
          <AdmissionExam
            skeletonImg={admissionExam}
            step="Exame"
            stepTitle="Admissional"
            stepDescription="Para nosso último passo, precisamos que verifique com seu encarregado direto os detalhes para que você possa realizar seus exames admissionais e após realiza-los anexar os resultados aqui, ou seja, basta tirar uma foto do resultado e anexa-lo."
          />
        )}
      />
      <Route
        path="/contract"
        exact
        isPrivate
        authorization={[ROLES.ADMISSION_PROCESS, ROLES.WAITING_CONTRACT_SIGN]}
        component={() => (
          <StepContract
            skeletonImg={StepContractImg}
            step="Assinatura do"
            stepTitle="Contrato de trabalho"
            stepDescription="Para assinar o contrato é simples, basta clicar no botão assinar e você será redirecionado para uma plataforma onde você irá assinar utilizando o mouse do seu computador ou com o dedo indicador caso sua tela seja touch screen!"
          />
        )}
      />
      <Route
        path="/email-confirm"
        exact
        isPrivate
        authorization={[ROLES.ADMISSION_PROCESS, ROLES.WAITING_CONTRACT_SIGN]}
        component={() => (
          <StepEmailConfirm
            skeletonImg={StepPasswordImg}
            stepTitle="Vamos criar sua senha"
            stepDescription="Primeiramente confirme o seu e-mail, é o mesmo que você recebeu o link para o processo de admissão."
            warning="Este será o mesmo e-mail para acessar o seu módulo de colaborador."
            contentBgColor={palette.secondary.main}
          />
        )}
      />
      <Route
        path="/create-password"
        exact
        isPrivate
        authorization={[ROLES.ADMISSION_PROCESS, ROLES.WAITING_CONTRACT_SIGN]}
        component={() => (
          <StepCreatePassword
            skeletonImg={StepPasswordImg}
            stepTitle="Vamos criar sua senha"
            stepDescription="Pronto, e-mail confirmado! Agora informe sua nova senha e confirme para continuarmos o seu acesso ao módulo colaborador!"
            contentBgColor={palette.secondary.main}
          />
        )}
      />
    </Router>
  );
};

export default AdmissionSteps;
