import styled, { css } from 'styled-components';
import { TextField } from '@material-ui/core';

export const StyledSelect = styled(TextField)`
  &.MuiSelect-selectMenu {
    ${props => css`
      margin: ${props.ma};
      margin-right: ${props.mr};
      margin-top: ${props.mt};
      margin-left: ${props.ml};
      margin-bottom: ${props.mb};
    `}
  }
  .MuiOutlinedInput-root {
    height: 45px;
    width: 211px;
    border-radius: 23px;
    font-weight: 700;

    ${({ theme }) => css`
      color: ${theme.palette.primary.main};

      & fieldset {
        border-color: ${theme.palette.primary.main};
      }
      &:hover fieldset {
        border-color: ${theme.palette.primary.main};
      }
      &.mui-focused fieldset {
        border-color: ${theme.palette.primary.main};
      }

      svg {
        color: ${theme.palette.primary.main};
      }
    `}

    .MuiSelect-select:focus {
      background: white;
    }
  }
`;
