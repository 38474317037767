import {
  Button,
  DialogContent,
  Grid,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core';
import { AddSharp } from '@material-ui/icons';
import React, { useState } from 'react';
import { SearchInput } from '../../components';
import AddDepartamentModal from './AddDepartamentModal';
import {
  Container,
  DepartamentsContainer,
  StyledIconButton,
  StyledTab,
  StyledTabs,
} from './styles';
import TableDepartments from './TableDepartments';

const Departments = () => {
  const [searchText, setSearchText] = useState('');
  const [openDepartamentsModal, setOpenDepartamentsModal] = useState(false);
  const [newDepartment, setNewDepartment] = useState('');

  return (
    <Container>
      <Typography variant="h2">Departamentos</Typography>
      <Paper elevation={1}>
        <StyledTabs value={1} indicatorColor="primary">
          <StyledTab label="Departamentos" value={1} />
        </StyledTabs>
        <DepartamentsContainer>
          <Grid container alignItems="center" justify="space-between">
            <SearchInput value={searchText} handleInput={setSearchText} />
            <div>
              <StyledIconButton onClick={() => setOpenDepartamentsModal(true)}>
                <AddSharp />
              </StyledIconButton>
              <Button
                color="primary"
                onClick={() => setOpenDepartamentsModal(true)}
              >
                ADICIONAR DEPARTAMENTO
              </Button>
              <Modal
                open={openDepartamentsModal}
                onClose={() => setOpenDepartamentsModal(false)}
                aria-labelledby="add-collaborator-title"
                aria-describedby="simple-modal-description"
              >
                <DialogContent>
                  <AddDepartamentModal
                    handleClickOnClose={() => setOpenDepartamentsModal(false)}
                    handleAddDepartment={setNewDepartment}
                  />
                </DialogContent>
              </Modal>
            </div>
          </Grid>

          <TableDepartments
            textFilter={searchText}
            newDepartment={newDepartment}
          />
        </DepartamentsContainer>
      </Paper>
    </Container>
  );
};

export default Departments;
