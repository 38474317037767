/* eslint-disable react/jsx-curly-newline */
import {
  Box,
  MenuItem,
  TextField as TextFieldMaterial,
  useTheme,
} from '@material-ui/core';
import axios from 'axios';
import { format, formatISO, parse, parseISO } from 'date-fns';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Selfie from '../../../../assets/selfie.svg';
import {
  CollaboratorButton,
  DateInput,
  Grid,
  Typography,
  Uploader,
  UserInfo,
} from '../../../../components';
import withCollaboratorSkeleton from '../../../../components/CollaboratorSkeleton';
import { useAuth } from '../../../../hooks/auth';
import useUser from '../../../../hooks/userAdmission';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import toFormData from '../../../../utils/formData';
import getFileUrlFor from '../../../../utils/getFIleUrlFor';
import personalSchema from '../../../../validations/personalData';
import countryList from './countryList';
import {
  RenderAutoCompleteField,
  RenderRawAutocompleteField,
  RenderTextField,
} from './RenderField';
import { ContainerInput, SyledForm } from './styles';

const StepOne = () => {
  const [personalDataValues, setPersonalData] = useState({
    nationality: '',
    state: '',
    cityOfBirth: '',
    birthDate: '',
    gender: '',
    raceColor: '',
    deficiency: '',
  });
  const [whatsApp, setWhatsApp] = useState('');
  const [ufs, setUfs] = useState([]);
  const [cities, setCities] = useState([]);
  const [ufSelected, setUfSelected] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileLocation, setFileLocation] = useState(null);
  const history = useHistory();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { userInfo, userStep } = useUser('personalData');
  const [isBrazil, setIsBrazil] = useState(false);

  const getBrazilianStates = useCallback(async () => {
    try {
      const response = await axios.get(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
      );

      const sortedOptions = response.data.sort((stateA, stateB) =>
        stateA.sigla > stateB.sigla ? 1 : -1,
      );

      const ufInitials = sortedOptions.map(uf => ({
        label: uf.nome,
        value: uf.sigla,
      }));
      setUfs(ufInitials);
    } catch (e) {
      dispatch(setSnackbar(true, 'error', 'Falha ao buscar informações'));
    }
  }, [dispatch]);

  const getBrazilianStateCities = useCallback(async () => {
    try {
      const citiesResponse = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${userStep.state}/municipios`,
      );
      const citiesOptions = citiesResponse.data.map(city => city.nome);
      setCities(citiesOptions);
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Falha ao carregar cidades'));
    }
  }, [dispatch, userStep]);

  useEffect(() => {
    if (isBrazil) {
      getBrazilianStates();
    }
    if (isBrazil && ufs.length && userStep) {
      getBrazilianStateCities();
    }
  }, [
    isBrazil,
    getBrazilianStates,
    getBrazilianStateCities,
    ufs.length,
    userStep,
  ]);

  useEffect(() => {
    if (personalDataValues?.nationality === 'Brasil') {
      setIsBrazil(true);
    }
  }, [personalDataValues]);

  useEffect(() => {
    async function getUserData() {
      try {
        if (userStep) {
          const {
            nationality,
            state,
            cityOfBirth,
            birthDate,
            gender,
            raceColor,
            deficiency,
            profilePicture,
          } = userStep;

          if (profilePicture) {
            setFileLocation(getFileUrlFor(profilePicture.key));
          }

          const parsedDate = parseISO(birthDate);

          const userState = ufs.find(uf => uf.value === state) || state;

          setPersonalData({
            nationality,
            state: userState,
            cityOfBirth,
            birthDate: format(parsedDate, 'dd/MM/yyyy'),
            gender,
            raceColor,
            deficiency,
          });
        }
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao buscar informações'));
      }
    }

    getUserData();
  }, [userStep, dispatch, ufs]);

  useEffect(() => {
    if (!ufSelected) return;
    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufSelected}/municipios`,
      )
      .then(response => {
        const citiesOptions = response.data.map(city => city.nome);
        setCities(citiesOptions);
      })
      .catch(() => {
        dispatch(setSnackbar(true, 'error', 'Falha ao carregar cidades'));
      });
  }, [ufSelected, dispatch]);

  const handleSubmitRegistrationData = useCallback(
    async (values, { setSubmitting }) => {
      try {
        if (!selectedFile && !fileLocation) {
          dispatch(setSnackbar(true, 'error', 'Foto obrigatória'));
          return;
        }
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        const parsedDate = parse(values.birthDate, 'dd/MM/yyyy', new Date());

        const formObject =
          fileLocation && !selectedFile
            ? {
                ...values,
                state: values.state.value || values.state,
                birthDate: formatISO(parsedDate),
              }
            : {
                ...values,
                state: values.state.value || values.state,
                birthDate: formatISO(parsedDate),
                profilePicture: selectedFile,
              };

        if (whatsApp) {
          formObject.isWhatsApp = JSON.parse(whatsApp);
        }

        const form = toFormData(formObject);

        await api.put(`users/step/personal-data/${user._id}`, form);

        dispatch(setSnackbar(true, 'success', 'Dados salvos com sucesso'));
        history.push(`/2`);
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possível salvar os dados'),
        );
      } finally {
        dispatch(setLoading(false));
        setSubmitting(false);
      }
    },
    [history, user, selectedFile, dispatch, whatsApp, fileLocation],
  );

  const handleChangeState = useCallback((fieldValue, setField) => {
    if (fieldValue) {
      setField('state', fieldValue);
      setField('cityOfBirth', null);
      setUfSelected(fieldValue.value);
    } else {
      setField('state', null);
      setField('cityOfBirth', null);
      setUfSelected('');
      setCities([]);
    }
  }, []);

  const handleChangeCityOfBirth = useCallback((fieldValue, setField, name) => {
    if (fieldValue) {
      setField(name, fieldValue);
    }
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      width={1}
      overflow="auto"
      p={5}
    >
      {userInfo && <UserInfo userData={userInfo} setValue={setWhatsApp} />}

      <Formik
        initialValues={personalDataValues}
        onSubmit={handleSubmitRegistrationData}
        validationSchema={personalSchema}
        enableReinitialize
      >
        {({ isSubmitting, setFieldValue, touched, errors }) => (
          <SyledForm>
            <Grid container spacing={2} justify="space-between">
              <Grid container item lg={8} direction="column">
                <Typography
                  component="legend"
                  fontWeight={300}
                  fontSize="18px"
                  mb="20px"
                >
                  Dados pessoais
                </Typography>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  justify="space-between"
                >
                  <ContainerInput item lg={6}>
                    <Field
                      component={Autocomplete}
                      options={countryList}
                      name="nationality"
                      label="Nacionalidade"
                      variant="filled"
                      fullWidth
                      disableClearable
                      renderInput={params => (
                        <TextFieldMaterial
                          {...params}
                          error={touched.state && !!errors.state}
                          helperText={touched.state && errors.state}
                          label="País que nasceu"
                          variant="filled"
                          required
                          disabled={isSubmitting}
                        />
                      )}
                      required
                      onChange={(_e, value) => {
                        setFieldValue('nationality', value);
                        if (personalDataValues.nationality === value) {
                          const userState =
                            ufs.find(
                              uf => uf.value === personalDataValues.state,
                            ) || personalDataValues.state;
                          setFieldValue('state', userState);
                          setFieldValue(
                            'cityOfBirth',
                            personalDataValues.cityOfBirth,
                          );
                        } else {
                          setFieldValue('state', '');
                          setFieldValue('cityOfBirth', '');
                        }
                        setIsBrazil(value.toLowerCase() === 'brasil');
                      }}
                    />
                  </ContainerInput>
                  <ContainerInput item lg={6} />
                  <ContainerInput item lg={6}>
                    {isBrazil ? (
                      <RenderAutoCompleteField
                        options={ufs}
                        touched={touched}
                        handleChangeState={handleChangeState}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        isSubmitting={isSubmitting}
                        name="state"
                        label="Estado que nasceu"
                      />
                    ) : (
                      RenderTextField('state', 'Estado que nasceu')
                    )}
                  </ContainerInput>
                  <ContainerInput item lg={6}>
                    {isBrazil
                      ? RenderRawAutocompleteField(
                          cities,
                          touched,
                          errors,
                          isSubmitting,
                          'cityOfBirth',
                          'Cidade que nasceu',
                          handleChangeCityOfBirth,
                          setFieldValue,
                        )
                      : RenderTextField('cityOfBirth', 'Cidade que nasceu')}
                  </ContainerInput>
                  <ContainerInput item lg={6}>
                    <Field
                      component={TextField}
                      name="birthDate"
                      label="Data de nascimento"
                      variant="filled"
                      fullWidth
                      required
                      InputProps={{
                        inputComponent: DateInput,
                      }}
                    />
                  </ContainerInput>
                  <ContainerInput item lg={6}>
                    <Field
                      component={TextField}
                      select
                      name="gender"
                      variant="filled"
                      label="Gênero"
                      required
                      fullWidth
                    >
                      <MenuItem value="">Gênero</MenuItem>
                      <MenuItem value="Masculino">Masculino</MenuItem>
                      <MenuItem value="Feminino">Feminino</MenuItem>
                      <MenuItem value="Não-Binário">Não-Binário</MenuItem>
                      <MenuItem value="Transgenero">Transgenero</MenuItem>
                    </Field>
                  </ContainerInput>
                  <ContainerInput item lg={6}>
                    <Field
                      component={TextField}
                      select
                      name="raceColor"
                      variant="filled"
                      label="Cor ou Raça"
                      required
                      fullWidth
                    >
                      <MenuItem value="">Cor ou Raça</MenuItem>
                      <MenuItem value="Branca">Branca</MenuItem>
                      <MenuItem value="Preta">Preta</MenuItem>
                      <MenuItem value="Parda">Parda</MenuItem>
                      <MenuItem value="Amarela">Amarela</MenuItem>
                      <MenuItem value="Indígena">Indígena</MenuItem>
                    </Field>
                  </ContainerInput>
                  <ContainerInput item lg={6}>
                    <Field
                      component={TextField}
                      select
                      name="deficiency"
                      variant="filled"
                      label="Possui alguma deficiência?"
                      required
                      fullWidth
                    >
                      <MenuItem value="Nenhuma">Nenhuma</MenuItem>
                      <MenuItem value="Múltipla">Múltipla</MenuItem>
                      <MenuItem value="Intelectual">Intelectual</MenuItem>
                      <MenuItem value="Fala">Fala</MenuItem>
                      <MenuItem value="Física">Física</MenuItem>
                      <MenuItem value="Auditiva">Auditiva</MenuItem>
                      <MenuItem value="Visual">Visual</MenuItem>
                      <MenuItem value="Mental">Mental</MenuItem>
                    </Field>
                  </ContainerInput>
                </Grid>
              </Grid>

              <Grid container item lg={4} direction="column">
                <Typography component="legend" fontWeight={300} fontSize="18px">
                  Envie uma selfie
                </Typography>
                <Uploader
                  title=""
                  defaultImage={fileLocation || Selfie}
                  accept="image/*"
                  dropText="Adicionar foto"
                  hasStepPhoto={fileLocation}
                  size="md"
                  onFileUploaded={setSelectedFile}
                />
                <Typography
                  fontSize="14px"
                  textcolor={palette.primary.main}
                  mt="24px"
                >
                  Especificações para a selfie
                </Typography>
                <Typography fontSize="14px" fontWeight={300} mt="8px">
                  - Fundo branco
                </Typography>
                <Typography fontSize="14px" fontWeight={300} mt="8px">
                  - Em boa qualidade
                </Typography>
                <Typography fontSize="14px" fontWeight={300} mt="8px">
                  - Do ombro para cima
                </Typography>
              </Grid>
            </Grid>

            <Box width={1} display="flex" justifyContent="flex-end" my={3}>
              <CollaboratorButton disabled={isSubmitting} type="submit" />
            </Box>
          </SyledForm>
        )}
      </Formik>
    </Box>
  );
};

export default withCollaboratorSkeleton(StepOne);
