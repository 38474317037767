import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { Box, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { mask } from 'remask';
import { Typography, TabPanel } from '../../../components/index';
import { TabContainer, ContainerInput, StyledTextField } from '../styles';

const AboutYou = props => {
  const phone = ['(99) 99999-9999', '(99) 9999-9999'];
  const {
    value,
    index,
    stepStatus,
    data,
    setUser,
    fullName,
    emailAddress,
    personalPhone,
    isWhatsApp,
    ...other
  } = props;

  const [name, setName] = useState({});
  const [email, setEmail] = useState({});
  const [telephone, setTelephone] = useState({});
  const [whatsApp, setWhatsApp] = useState({});
  const [birthDayDate, setBirthDayDate] = useState('');
  const [aboutYou, setAboutYou] = useState({
    nationality: '',
    state: '',
    cityOfBirth: '',
    gender: '',
    raceColor: '',
    deficiency: '',
  });
  useEffect(() => {
    if (Object.keys(data).length) {
      setAboutYou(data);
      setName(fullName);
      setEmail(emailAddress);
      setTelephone(personalPhone);
      const newData = parseISO(data.birthDate);
      const formatedData = format(newData, 'dd/MM/yyyy');
      setBirthDayDate(formatedData);
      setWhatsApp(isWhatsApp);
    }
  }, [data, emailAddress, fullName, isWhatsApp, personalPhone]);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-start"
          spacing={4}
        >
          <Grid item xs={12}>
            <Typography fontSize="16px" color="secondary" fontWeight="300">
              Dados pessoais
            </Typography>
          </Grid>
          <ContainerInput item xs={6}>
            <StyledTextField
              name="name"
              label="Nome completo"
              variant="filled"
              fullWidth
              value={name}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="email"
              label="E-mail"
              variant="filled"
              fullWidth
              value={email}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="telephone"
              label="Telefone ou Celular"
              variant="filled"
              fullWidth
              value={mask(telephone, phone)}
              disabled
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  name="checkReceipt"
                  color="primary"
                  checked={whatsApp}
                />
              }
              label={
                <Typography opacity="0.5" fontSize="13px" fontWeight="300">
                  Whatsapp
                </Typography>
              }
            />
          </ContainerInput>
          <Box width="100%" py={1} />
          <ContainerInput item xs={3}>
            <StyledTextField
              name="nationality"
              label="Nacionalidade"
              variant="filled"
              fullWidth
              value={aboutYou.nationality}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="state"
              label="UF"
              variant="filled"
              fullWidth
              value={aboutYou.state}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="cityOfBirth"
              label="Cidade nascimento"
              variant="filled"
              fullWidth
              value={aboutYou.cityOfBirth}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="birthDayDate"
              label="Data nascimento"
              variant="filled"
              fullWidth
              value={birthDayDate}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="gender"
              label="Gênero"
              variant="filled"
              fullWidth
              value={aboutYou.gender}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="raceColor"
              label="Cor ou raça"
              variant="filled"
              fullWidth
              value={aboutYou.raceColor}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="deficiency"
              label="Possui deficiência?"
              variant="filled"
              fullWidth
              value={aboutYou.deficiency}
              disabled
            />
          </ContainerInput>
        </Grid>
      </TabContainer>
    </TabPanel>
  );
};

AboutYou.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  stepStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
  fullName: PropTypes.objectOf(PropTypes.any),
  emailAddress: PropTypes.objectOf(PropTypes.any),
  personalPhone: PropTypes.objectOf(PropTypes.any),
  isWhatsApp: PropTypes.objectOf(PropTypes.any),
};

AboutYou.defaultProps = {
  data: {},
  fullName: {},
  emailAddress: {},
  personalPhone: {},
  isWhatsApp: {},
};

export default AboutYou;
