export default function handleStatusActive(status) {
  const statusOptions = {
    WORK_EXPERIENCE: 'Período de Experiência',
    HIRED: 'Efetivado',
    MEDICAL_CERTIFICATE: 'Atestado',
    INSS_REMOVAL: 'Afastamento INSS',
    DAY_OFF: 'Folga',
    VACATION: 'Férias',
    WEEKLY_REST: 'Descanço Semanal',
    SUSPENSION: 'Suspensão',
    MATERNITY_LEAVE: 'Licença maternidade',
    PATERNITY_LEAVE: 'Licença paternidade',
    UNPAID_LEAVE: 'Licença não remunerada',
  };

  return statusOptions[status];
}
