import { Box, Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EmptyState, TabPanel } from '../../../components/index';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import formatTimeString from '../../../utils/formatTimeString';
import CollaboratorFilter from '../Components/ColaboratorFilter';
import PointMirrorTable from '../Components/PointMirrorTable';
import { TabContainer } from '../styles';

const PointMirror = props => {
  const { value, index, ...other } = props;
  const [points, setPoints] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    periodStartDate: '',
    periodEndDate: '',
    actualMonthDate: new Date(),
    isOnlyAlerts: false,
  });
  const [workedHours, setWorkedHours] = useState('');

  const dispatch = useDispatch();

  const formatWorkedHours = workedMinutes => {
    return formatTimeString(workedMinutes);
  };

  const formatPointMirror = pointMirrorRegisters => {
    const formattedPointMirrorRegisters = pointMirrorRegisters.map(
      (dayDetail, arrayIndex) => {
        const zonedDate = utcToZonedTime(dayDetail.date, 'America/Sao_Paulo');
        const formattedBrazilDate = format(zonedDate, 'dd/MM - eee', {
          locale: ptBR,
        }).toUpperCase();
        const formattedDayDetail = {
          ...dayDetail,
          id: arrayIndex,
          date: zonedDate,
          dateFormatted: formattedBrazilDate,
        };

        if (dayDetail.enterTime) {
          const zonedEnterTime = utcToZonedTime(
            dayDetail.enterTime?.value,
            'America/Sao_Paulo',
          );

          formattedDayDetail.enterTime = {
            ...formattedDayDetail.enterTime,
            value: format(zonedEnterTime, 'HH:mm'),
          };
        }

        if (dayDetail.exitTime) {
          const zonedExitTime = utcToZonedTime(
            dayDetail.exitTime?.value,
            'America/Sao_Paulo',
          );

          formattedDayDetail.exitTime = {
            ...formattedDayDetail.exitTime,
            value: format(zonedExitTime, 'HH:mm'),
          };
        }

        return formattedDayDetail;
      },
    );
    return formattedPointMirrorRegisters;
  };

  useEffect(() => {
    async function getPointMirror() {
      if (
        (filterOptions.periodStartDate && !filterOptions.periodEndDate) ||
        (!filterOptions.periodStartDate && filterOptions.periodEndDate)
      ) {
        return;
      }
      try {
        const api = AuthenticatedHttpClient();
        dispatch(setLoading(true));

        const { data } = await api.get('/point-registers/point-mirror', {
          params: filterOptions,
        });

        const pointMirrorRegisters = data.registers;
        const { totalMinutesWorkedByPeriod } = data;

        setPoints(formatPointMirror(pointMirrorRegisters));
        setWorkedHours(formatWorkedHours(totalMinutesWorkedByPeriod));
      } catch (error) {
        dispatch(
          setSnackbar(
            true,
            'error',
            'Não foi possível buscar o espelho de ponto.',
          ),
        );
      } finally {
        dispatch(setLoading(false));
      }
    }
    getPointMirror();
  }, [dispatch, filterOptions]);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-start"
          spacing={4}
        >
          <Grid item xs={12}>
            <CollaboratorFilter
              setFilterOptions={setFilterOptions}
              workedHours={workedHours}
            />
          </Grid>
          {points.length ? (
            <Grid item xs={12}>
              <Box p={3}>
                <PointMirrorTable points={points} />
              </Box>
            </Grid>
          ) : (
            <EmptyState description="Não há registros no espelho de ponto!" />
          )}
        </Grid>
      </TabContainer>
    </TabPanel>
  );
};

PointMirror.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default PointMirror;
