import React from 'react';
import PropTypes from 'prop-types';
import { mask } from 'remask';
import { Box } from '@material-ui/core';
import { Typography } from '../../../../../components/index';
import { DependentsContainer } from './styles';

const Dependents = ({ name, type, birth, cpf }) => {
  const cpfNumber = ['999.999.999-99'];
  const date = ['99/99/9999'];
  return (
    <DependentsContainer py={2} px={1}>
      <Typography fontSize="12px" fontWeight="500" opacity="0.5">
        {name}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="10px" opacity="0.5">
          {`Tipo: ${type}`}
        </Typography>
        <Typography fontSize="10px" opacity="0.5">
          {`Data nascimento: ${mask(birth, date)}`}
        </Typography>
        <Typography fontSize="10px" opacity="0.5">
          {`CPF: ${mask(cpf, cpfNumber)}`}
        </Typography>
      </Box>
    </DependentsContainer>
  );
};

Dependents.defaultProps = {
  name: 'Your name here',
  type: 'Son',
  birth: '25012019',
  cpf: '99999999999',
};

Dependents.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  birth: PropTypes.string,
  cpf: PropTypes.string,
};
export default Dependents;
