import differenceInDays from 'date-fns/differenceInDays';
import isValid from 'date-fns/isValid';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useEffect } from 'react';
import { Grid } from '../../../../components';
import { ACTIVITY_PERIOD } from '../../../../utils/enums';

const NumberOfDaysInput = () => {
  const { values, setFieldValue } = useFormikContext();

  const calculateNumberOfDays = (startDate, finalDate) => {
    if (isValid(startDate) && isValid(finalDate)) {
      const differenceBetweenDates = differenceInDays(finalDate, startDate);
      return differenceBetweenDates > 0 ? differenceBetweenDates + 1 : 0;
    }
    return '';
  };

  useEffect(() => {
    if (values.period === ACTIVITY_PERIOD.ONE_DAY) {
      setFieldValue('numberOfDays', 1);
    } else {
      setFieldValue(
        'numberOfDays',
        calculateNumberOfDays(values.startDate, values.finalDate),
      );
    }
  }, [values.startDate, values.finalDate, setFieldValue, values.period]);

  return (
    <Grid item lg={6}>
      <Field
        component={TextField}
        disabled
        name="numberOfDays"
        label="Quantidade de dias"
        variant="filled"
        fullWidth
      />
    </Grid>
  );
};

export default NumberOfDaysInput;
