import { TextField } from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { StyledGrid } from '../styles';

const CompensationHoursInfo = ({ values }) => {
  const { justification, compensationDate, dayHoursCompensated } = values;

  return (
    <>
      <StyledGrid item xs={6}>
        <TextField
          name="compensationDate"
          label="Dia da compensação"
          variant="filled"
          disabled
          value={format(new Date(compensationDate), 'dd/MM/yyyy')}
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <TextField
          name="dayHoursCompensated"
          label="Horas a serem compensadas"
          variant="filled"
          disabled
          value={dayHoursCompensated}
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <TextField
          name="justification"
          label="Justificativa"
          variant="filled"
          disabled
          value={justification}
          fullWidth
        />
      </StyledGrid>
    </>
  );
};

CompensationHoursInfo.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CompensationHoursInfo;
