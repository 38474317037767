import { Button, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { ModalSkeleton, MoneyInput } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { ActionsBar, ContainerModal, StyledGrid } from './styles';

const AddCareerModal = ({
  handleClickOnClose,
  handleAddJobRole,
  isUpdate,
  formInitialValues,
  setIsUpdated,
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const handleAddCareerModal = useCallback(
    async (values, { setSubmitting }) => {
      try {
        setSubmitting(false);
        const api = AuthenticatedHttpClient();
        const response = await api.post('job-role', {
          ...values,
          company: user.company._id,
        });
        handleAddJobRole(response.data._id);
        dispatch(setSnackbar(true, 'success', 'Cargo adicionado'));
      } catch (error) {
        if (error.response?.data?.message) {
          dispatch(setSnackbar(true, 'error', error.response.data.message));
        } else {
          dispatch(
            setSnackbar(true, 'error', 'Não foi possível adicionar o cargo'),
          );
        }
      } finally {
        handleClickOnClose();
      }
    },
    [dispatch, user, handleClickOnClose, handleAddJobRole],
  );

  const carrerModalSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    baseSalary: Yup.string().required('Campo obrigatório'),
  });

  const handleUpdateCarrer = useCallback(
    async (values, { setSubmitting }) => {
      const valuesToUpdate = {
        name: values.name,
        baseSalary: values.baseSalary,
        toxicologicalExam: values.toxicologicalExam,
        company: user.company._id,
        _id: values._id,
      };
      try {
        setSubmitting(false);
        const api = AuthenticatedHttpClient();
        await api.post(`job-role/${values._id}`, valuesToUpdate);
        dispatch(setSnackbar(true, 'success', 'Cargo Atualizado'));
        setIsUpdated(prevState => !prevState);
      } catch (error) {
        if (error.response?.data?.message) {
          dispatch(setSnackbar(true, 'error', error.response.data.message));
        } else {
          dispatch(
            setSnackbar(true, 'error', 'Não foi possível atualizar o cargo'),
          );
        }
      } finally {
        handleClickOnClose();
      }
    },
    [dispatch, handleClickOnClose, setIsUpdated, user],
  );

  return (
    <ModalSkeleton
      title="ADICIONAR CARGO"
      handleClickOnClose={handleClickOnClose}
    >
      <ContainerModal>
        <Formik
          initialValues={formInitialValues}
          onSubmit={isUpdate ? handleUpdateCarrer : handleAddCareerModal}
          validationSchema={carrerModalSchema}
        >
          {({ values, isSubmitting }) => {
            return (
              <Form>
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  spacing={2}
                >
                  <StyledGrid item lg={6}>
                    <Field
                      component={TextField}
                      name="name"
                      label="Nome"
                      variant="filled"
                      fullWidth
                    />
                  </StyledGrid>
                  <StyledGrid item lg={6}>
                    <Field
                      component={TextField}
                      name="baseSalary"
                      label="Base salarial"
                      variant="filled"
                      fullWidth
                      InputProps={{
                        inputComponent: MoneyInput,
                      }}
                    />
                  </StyledGrid>
                  <StyledGrid item lg={6}>
                    <Field
                      component={CheckboxWithLabel}
                      name="toxicologicalExam"
                      type="checkbox"
                      checked={values.toxicologicalExam}
                      Label={{
                        label: 'Solicitar exame toxicológico para este cargo',
                      }}
                    />
                  </StyledGrid>
                </Grid>
                <ActionsBar container alignItems="center" justify="flex-end">
                  <StyledGrid mt="32px">
                    <Button
                      disabled={isSubmitting}
                      type="buton"
                      color="secondary"
                      onClick={handleClickOnClose}
                      size="small"
                    >
                      CANCELAR
                    </Button>
                    <Button
                      color="secondary"
                      disabled={isSubmitting}
                      type="submit"
                      size="small"
                    >
                      {isUpdate ? 'ATUALIZAR CARGO' : 'REGISTRAR CARGO'}
                    </Button>
                  </StyledGrid>
                </ActionsBar>
              </Form>
            );
          }}
        </Formik>
      </ContainerModal>
    </ModalSkeleton>
  );
};

AddCareerModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
  handleAddJobRole: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  formInitialValues: PropTypes.shape({
    name: PropTypes.string,
    baseSalary: PropTypes.string,
    toxicologicalExam: PropTypes.bool,
  }),
  setIsUpdated: PropTypes.func,
};

AddCareerModal.defaultProps = {
  isUpdate: false,
  formInitialValues: {
    name: '',
    baseSalary: '',
    toxicologicalExam: false,
  },
  setIsUpdated: () => {},
};

export default AddCareerModal;
