import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox } from '@material-ui/core';

import {
  TabPanel,
  Downloader,
  CEPInput,
  CPFInput,
  Grid,
} from '../../../components';

import {
  TabContainer,
  ContainerInput,
  SubTitleForm,
  StyledTextField,
  StyledFormControlLabel,
} from '../styles';

const Address = props => {
  const { value, index, data, ...other } = props;
  const [address, setAddress] = useState({
    cep: '',
    state: '',
    city: '',
    publicPlace: '',
    number: '',
    complement: '',
    neighborhood: '',
    type: '',
    cpfOwner: '',
    rgOwner: '',
    proofOfAddress: false,
  });

  useEffect(() => {
    if (Object.keys(data).length) {
      setAddress(data);
    }
  }, [data]);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Box
          component={Grid}
          container
          alignItems="center"
          justify="space-between"
          spacing={2}
          p={1}
        >
          <SubTitleForm>Endereço do colaborador</SubTitleForm>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="cep"
              label="CEP"
              variant="filled"
              fullWidth
              value={address.cep}
              InputProps={{
                inputComponent: CEPInput,
              }}
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="type"
              label="Tipo"
              variant="filled"
              fullWidth
              value={address.type}
            />
          </ContainerInput>
          <ContainerInput item lg={6}>
            <StyledTextField
              name="publicPlace"
              label="Logradouro"
              variant="filled"
              fullWidth
              value={address.publicPlace}
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="number"
              label="Número"
              variant="filled"
              value={address.number}
              fullWidth
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="complement"
              label="Complemento"
              variant="filled"
              value={address.complement}
              fullWidth
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="district"
              label="Bairro"
              variant="filled"
              value={address.neighborhood}
              fullWidth
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="city"
              label="Cidade"
              variant="filled"
              value={address.city}
              fullWidth
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="state"
              label="Estado"
              variant="filled"
              value={address.state}
              fullWidth
            />
          </ContainerInput>
          {!address.proofOfAddress && (
            <>
              <ContainerInput item lg={3}>
                <StyledTextField
                  name="cpfOwner"
                  label="CPF do Proprietário"
                  variant="filled"
                  fullWidth
                  value={address.cpfOwner}
                  InputProps={{
                    inputComponent: CPFInput,
                  }}
                />
              </ContainerInput>
              <ContainerInput item lg={3}>
                <StyledTextField
                  name="rgOwner"
                  label="RG do Proprietário"
                  variant="filled"
                  value={address.rgOwner}
                  fullWidth
                />
              </ContainerInput>
              <ContainerInput item lg={3} />
            </>
          )}
          <Box w={1} alignItems="center">
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={address.proofOfAddress}
                  name="proofOfAddress"
                  color="primary"
                />
              }
              label="Comprovante está em nome do colaborador"
            />
          </Box>
          {address.receipt && (
            <>
              <SubTitleForm>Comprovante de endereço</SubTitleForm>
              <ContainerInput item lg={3}>
                <Downloader
                  file={{
                    name: address.receipt.name,
                    size: address.receipt.size,
                    key: address.receipt.key,
                  }}
                />
              </ContainerInput>
            </>
          )}
        </Box>
      </TabContainer>
    </TabPanel>
  );
};

Address.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

Address.defaultProps = {
  data: {},
};

export default Address;
