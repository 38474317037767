import { ButtonBase, Divider } from '@material-ui/core';
import styled from 'styled-components';
import { Grid } from '../../../../components';

export const DividerWithMargin = styled(Divider)`
  margin: 0px 20px;
`;

export const StyledButton = styled(ButtonBase)`
  width: 156px;
  color: white;
  font-weight: 700;
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 56px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
`;

export const GridWithSelfAlign = styled(Grid)`
  align-self: center;
`;
