import { Box, Checkbox, Grid, MenuItem } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  DateInput,
  MoneyInput,
  PercentageInput,
  TabPanel,
  Typography
} from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { AuthenticatedHttpClient } from '../../../services/api';
import {
  AdditionalContainer,
  ContainerInput,
  StyledFormControlLabel,
  StyledTextField,
  SubTitleForm,
  TabContainer
} from '../styles';

const HiringData = props => {
  const { value, index, data, ...other } = props;
  const [workdayOptions, setWorkdayOptions] = useState([]);
  const [hiringData, setHiringData] = useState({
    salary: '',
    vt: '',
    va: '',
    vr: '',
    hasStandardSalary: false,
    experiencePeriod: '',
    beginningOfFirstPeriod: '',
    endOfFirstPeriod: '',
    startOfSecondPeriod: '',
    endOfSecondPeriod: '',
    workday: '',
    additional: {
      warning: false,
      unhealthy: false,
      unhealthyPercentage: '',
      risk: false,
      dangerousness: false,
    },
  });

  const { user } = useAuth();

  useEffect(() => {
    async function getData() {
      const api = AuthenticatedHttpClient();

      const workDayResponse = await api.get(`workday/${user.company._id}`);

      setWorkdayOptions(workDayResponse.data);

      if (Object.keys(data).length) {
        delete data.createdAt;
        delete data.updatedAt;

        if (data.beginningOfFirstPeriod) {
          const beginningOfFirstPeriod = parseISO(data.beginningOfFirstPeriod);
          const endOfFirstPeriod = parseISO(data.endOfFirstPeriod);
          const startOfSecondPeriod = parseISO(data.startOfSecondPeriod);
          const endOfSecondPeriod = parseISO(data.endOfSecondPeriod);

          setHiringData(state => ({
            ...state,
            ...data,
            workday: data.workday._id,
            beginningOfFirstPeriod: format(
              beginningOfFirstPeriod,
              'dd/MM/yyyy',
            ),
            endOfFirstPeriod: format(endOfFirstPeriod, 'dd/MM/yyyy'),
            startOfSecondPeriod: format(startOfSecondPeriod, 'dd/MM/yyyy'),
            endOfSecondPeriod: format(endOfSecondPeriod, 'dd/MM/yyyy'),
          }));
        } else {
          setHiringData(state => ({
            ...state,
            ...data,
          }));
        }
      }
    }

    getData();
  }, [user, data]);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Box
          component={Grid}
          container
          alignItems="center"
          justify="space-between"
          spacing={2}
          p={1}
        >
          <SubTitleForm>Dados de contratação</SubTitleForm>

          <ContainerInput item lg={3}>
            <StyledTextField
              name="salary"
              label="Salário"
              variant="filled"
              fullWidth
              value={hiringData.salary}
              disabled
              InputProps={{
                inputComponent: MoneyInput,
              }}
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="vt"
              label="Vale transporte"
              variant="filled"
              fullWidth
              value={hiringData.vt}
              disabled
              InputProps={{
                inputComponent: MoneyInput,
              }}
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="vr"
              label="Vale Refeição"
              variant="filled"
              value={hiringData.vr}
              fullWidth
              disabled
              InputProps={{
                inputComponent: MoneyInput,
              }}
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="va"
              label="Vale Alimentação"
              variant="filled"
              value={hiringData.va}
              fullWidth
              disabled
              InputProps={{
                inputComponent: MoneyInput,
              }}
            />
          </ContainerInput>
          <Box mt="-8px" ml={1} mb={2}>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={hiringData.hasStandardSalary}
                  name="hasStandardSalary"
                  color="primary"
                />
              }
              label="Padrão ao cargo"
            />
          </Box>
          <Typography
            fontWeight="700"
            width="100%"
            fontSize="18px"
            mb="24px"
            color="secondary"
          >
            Antes de ativar o colaborador, por favor informe os dados de
            contratação abaixo:
          </Typography>

          <SubTitleForm>Informe o período de experiência</SubTitleForm>
          <ContainerInput item lg={3}>
            <StyledTextField
              select
              name="experiencePeriod"
              label="Período de experiência"
              value={hiringData.experiencePeriod}
              variant="filled"
              fullWidth
            >
              <MenuItem value="30/60">30 + 60 dias</MenuItem>
              <MenuItem value="45/45">45 + 45 dias</MenuItem>
            </StyledTextField>
          </ContainerInput>
          <ContainerInput item lg={9} />
          {hiringData.experiencePeriod && (
            <>
              <ContainerInput item lg={3}>
                <StyledTextField
                  name="beginningOfFirstPeriod"
                  label={
                    hiringData.experiencePeriod === '30/60'
                      ? 'Início dos primeiros 30 dias'
                      : 'Início dos primeiros 45 dias'
                  }
                  variant="filled"
                  value={hiringData.beginningOfFirstPeriod}
                  fullWidth
                  InputProps={{
                    inputComponent: DateInput,
                  }}
                />
              </ContainerInput>
              <ContainerInput item lg={3}>
                <StyledTextField
                  name="endOfFirstPeriod"
                  label={
                    hiringData.experiencePeriod === '30/60'
                      ? 'Fim dos primeiros 30 dias'
                      : 'Fim dos primeiros 45 dias'
                  }
                  variant="filled"
                  value={hiringData.endOfFirstPeriod}
                  fullWidth
                  InputProps={{
                    inputComponent: DateInput,
                  }}
                />
              </ContainerInput>
              <ContainerInput item lg={3}>
                <StyledTextField
                  name="startOfSecondPeriod"
                  label={
                    hiringData.experiencePeriod === '30/60'
                      ? 'Início dos 60 dias finais'
                      : 'Início dos 45 dias finais'
                  }
                  variant="filled"
                  value={hiringData.startOfSecondPeriod}
                  fullWidth
                  InputProps={{
                    inputComponent: DateInput,
                  }}
                />
              </ContainerInput>
              <ContainerInput item lg={3}>
                <StyledTextField
                  name="endOfSecondPeriod"
                  label={
                    hiringData.experiencePeriod === '30/60'
                      ? 'Fim dos 60 dias finais'
                      : 'Fim dos 45 dias finais'
                  }
                  variant="filled"
                  value={hiringData.endOfSecondPeriod}
                  fullWidth
                  InputProps={{
                    inputComponent: DateInput,
                  }}
                />
              </ContainerInput>
            </>
          )}
          <SubTitleForm>Selecione a jornada de trabalho</SubTitleForm>
          <ContainerInput item lg={3}>
            <StyledTextField
              select
              name="workday"
              label="Selecione"
              variant="filled"
              value={hiringData.workday}
              fullWidth
            >
              {workdayOptions.map(option => (
                <MenuItem value={option._id} key={option._id}>
                  {option.name}
                </MenuItem>
              ))}
            </StyledTextField>
          </ContainerInput>

          <SubTitleForm>Adicionais</SubTitleForm>
          <Typography mt="16px" mb="8px" color="secondary" fontSize="14px">
            Sobreaviso
          </Typography>
          <AdditionalContainer>
            <Box display="flex" alignItems="center">
              <Checkbox
                name="additional.warning"
                checked={hiringData.additional.warning}
                type="checkbox"
                color="primary"
              />
              <p>
                Quem precisa estar disponível a qualquer momento do seu período
                de descanso para voltar ao trabalho. Essa hora de sobreaviso
                equivale a um terço do valor da hora regular de trabalho.
              </p>
            </Box>
          </AdditionalContainer>
          <Typography mt="16px" mb="8px" color="secondary" fontSize="14px">
            Insalubridade
          </Typography>
          <AdditionalContainer>
            <Box display="flex" alignItems="center">
              <Checkbox
                name="additional.unhealthy"
                checked={hiringData.additional.unhealthy}
                type="checkbox"
                color="primary"
              />
              <p>
                O adicional de insalubridade é o benefício pago quando há
                exposição a agentes físicos, químicos e biológicos que coloquem
                em risco a saúde do funcionário. As condições que são
                consideradas insalubres estão previstas na Norma Regulamentadora
                15, da Portaria n. 3.214/78. O valor do adicional de
                insalubridade varia entre 10% e 40% de acordo com o nível de
                exposição, mas essa porcentagem é calculada sobre o valor do
                salário mínimo e não sobre o salário recebido.
              </p>
            </Box>
            <Box width={1} maxWidth="250px" ml={3} height="60px">
              <StyledTextField
                name="additional.unhealthyPercentage"
                label="Porcentagem"
                variant="filled"
                value={hiringData.additional.unhealthyPercentage}
                fullWidth
                placeholder="Informe de 10 a 40%"
                InputProps={{
                  style: { backgroundColor: '#ECECEC' },
                  inputComponent: PercentageInput,
                }}
              />
            </Box>
          </AdditionalContainer>
          <Typography mt="16px" mb="8px" color="secondary" fontSize="14px">
            Risco
          </Typography>
          <AdditionalContainer>
            <Box display="flex" alignItems="center">
              <Checkbox
                name="additional.risk"
                checked={hiringData.additional.risk}
                type="checkbox"
                color="primary"
              />
              <p>
                Funcionários de portos que trabalham em mar ou em terra têm
                direito ao adicional de risco, regulado pela Lei 4.860 de
                26.11.1965. O valor do benefício é de 40% sobre o valor do
                salário por hora de trabalho regular diurna. Segundo previsto no
                artigo 14 da lei, o pagamento desse adicional substitui
                adicionais de insalubridade e de periculosidade.
              </p>
            </Box>
          </AdditionalContainer>
          <Typography mt="16px" mb="8px" color="secondary" fontSize="14px">
            Periculosidade
          </Typography>
          <AdditionalContainer>
            <Box display="flex" alignItems="center">
              <Checkbox
                name="additional.dangerousness"
                checked={hiringData.additional.dangerousness}
                type="checkbox"
                color="primary"
              />
              <p>
                Segundo a NR 16 do Ministério do Trabalho e do Emprego, uma
                determinada atividade é considerada perigosa quando coloca em
                risco a integridade física do funcionário. Quem lida com
                produtos explosivos, produtos inflamáveis, energia elétrica tem
                direito a receber o adicional de periculosidade. Esse adicional
                é de 30% sobre o salário pago. A CLT estabelece, no artigo 195,
                que haja laudo pericial constatando o risco. Mas atenção: os
                adicionais de insalubridade e de periculosidade não podem ser
                recebidos juntos. Então, se as condições são perigosas e
                insalubres, o empregado receberá somente o adicional de maior
                valor.
              </p>
            </Box>
          </AdditionalContainer>
        </Box>
      </TabContainer>
    </TabPanel>
  );
};

HiringData.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default HiringData;
