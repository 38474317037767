import { Box, Button, DialogContent, Modal } from '@material-ui/core';
import { AccountBox } from '@material-ui/icons';
import { formatDistance, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Return from '../../assets/arrow-left.svg';
import ArrowUp from '../../assets/arrow-up.svg';
import Checked from '../../assets/checked.svg';
import CloseX from '../../assets/CloseX.svg';
import { Grid, Tab, Tabs, Typography } from '../../components';
import useUser from '../../hooks/useUser';
import { STATUS_DISMISSAL } from '../../utils/enums';
import getFileUrlFor from '../../utils/getFIleUrlFor';
import handleStatus from '../../utils/handleStatusDismissal';
import ReactivateColaboratorModal from './ReactivateColaboratorModal';
import ResignationColaboratorModal from './ResignationColaboratorModal';
import {
  Container,
  DetailItem,
  DetailPaper,
  ImageContainer,
  StyledButton,
  StyledButtonBase,
  StyledButtonBaseDismissal,
  StyledButtonBaseReactivate,
  StyledButtonModalOpen,
  StyledDivModal,
  StyledDivModalClose,
  StyledGrid,
  StyledImgExpanded,
  TabsPaper,
  UserInfo,
} from './styles';
import {
  Address,
  BankData,
  Checkup,
  Documentation,
  Documents,
  FamilyData,
  HiringData,
  PersonalData,
  Shutdown,
} from './Tabs/index';

const DismissalColaborator = () => {
  const [tabValue, setTabValue] = useState(1);
  const [open, setOpen] = useState(false);
  const [openReactivateModal, setOpenReactivateModal] = useState(false);
  const [openResignationModal, setOpenResignationModal] = useState(false);
  const [user, setUser] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [details, setDetails] = useState({
    shutDownStartDate: '',
    statusDismissal: '',
    resignationDate: '',
    companyTime: '',
    departureDate: '',
  });

  const history = useHistory();
  const { userId: collaboratorId } = useParams();

  const { user: userData, userInfo: userInfoData } = useUser(collaboratorId);

  useEffect(() => {
    if (userData) {
      const { dismissalDetails } = userData;

      const shutDownStartDate = new Date(
        dismissalDetails.shutDownStartDate,
      ).toLocaleDateString('pt-br');

      let resignationDate = 'À definir';
      if (dismissalDetails.resignationDate) {
        resignationDate = new Date(
          dismissalDetails.resignationDate,
        ).toLocaleDateString('pt-br');
      }

      let departureDate = 'À definir';
      if (dismissalDetails.departureDate) {
        departureDate = new Date(
          dismissalDetails.departureDate,
        ).toLocaleDateString('pt-br');
      }

      const companyTime = formatDistance(
        parseISO(dismissalDetails.shutDownStartDate),
        parseISO(userData.admissionDate),
        {
          locale: ptBR,
        },
      );

      setDetails({
        shutDownStartDate,
        statusDismissal: handleStatus(userData.statusDismissal),
        resignationDate,
        companyTime,
        departureDate,
      });
      setUser(userData);
    }
    if (userInfoData) {
      setUserInfo(userInfoData);
    }
  }, [userData, userInfoData]);

  const handleChangeTab = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const handleOpenPhotoExpanded = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClosePhotoExpanded = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpenReactivateModal = useCallback(() => {
    setOpenReactivateModal(true);
  }, []);

  const handleCloseReactivateModal = useCallback(
    redirect => {
      setOpenReactivateModal(false);
      if (redirect) {
        history.replace('/home/active');
      }
    },
    [history],
  );

  const handleOpenResignationModal = useCallback(() => {
    setOpenResignationModal(true);
  }, []);

  const handleCloseResignationModal = useCallback(
    redirect => {
      setOpenResignationModal(false);
      if (redirect) {
        history.replace('/home/dismissal');
      }
    },
    [history],
  );

  const photoExpanded = (
    <StyledDivModal>
      <StyledDivModalClose>
        <Button onClick={handleClosePhotoExpanded}>
          <img src={CloseX} alt="" />
        </Button>
      </StyledDivModalClose>
      {user.personalData && user.personalData.profilePicture && (
        <StyledImgExpanded
          src={getFileUrlFor(user.personalData.profilePicture.key)}
          alt=""
        />
      )}
    </StyledDivModal>
  );

  return (
    <Container>
      <StyledButtonBase onClick={() => history.goBack()}>
        <img src={Return} alt="" />
      </StyledButtonBase>
      <Grid container alignItems="center" justify="space-between">
        {user._id && (
          <UserInfo>
            {user.personalData && user.personalData.profilePicture ? (
              <StyledButtonModalOpen onClick={handleOpenPhotoExpanded}>
                {user.personalData && user.personalData.profilePicture && (
                  <ImageContainer
                    image={getFileUrlFor(user.personalData.profilePicture.key)}
                  />
                )}
              </StyledButtonModalOpen>
            ) : (
              <AccountBox color="primary" fontSize="large" />
            )}
            <Modal open={open} onClose={handleClosePhotoExpanded}>
              {photoExpanded}
            </Modal>
            <div>
              <strong>{user.fullName.toUpperCase()}</strong>
              <span>DEMISSÃO</span>
            </div>
          </UserInfo>
        )}
        <Box display="flex">
          <Box>
            <StyledButtonBaseReactivate onClick={handleOpenReactivateModal}>
              <img src={ArrowUp} alt="" />
            </StyledButtonBaseReactivate>
            <StyledButton
              textcolor="secondary"
              onClick={handleOpenReactivateModal}
            >
              REATIVAR COLABORADOR
            </StyledButton>
            <Modal
              open={openReactivateModal}
              onClose={handleCloseReactivateModal}
            >
              <DialogContent>
                <ReactivateColaboratorModal
                  handleClickOnClose={handleCloseReactivateModal}
                />
              </DialogContent>
            </Modal>
          </Box>
          {details.statusDismissal !==
            handleStatus(STATUS_DISMISSAL.DISMISSED) && (
            <Box>
              <StyledButtonBaseDismissal onClick={handleOpenResignationModal}>
                <img src={Checked} alt="" />
              </StyledButtonBaseDismissal>
              <StyledButton
                textcolor="primary"
                onClick={handleOpenResignationModal}
              >
                CONCLUIR PROCESSO DE DEMISSÃO
              </StyledButton>
              <Modal
                open={openResignationModal}
                onClose={handleCloseResignationModal}
              >
                <DialogContent>
                  <ResignationColaboratorModal
                    handleClickOnClose={handleCloseResignationModal}
                  />
                </DialogContent>
              </Modal>
            </Box>
          )}
        </Box>
      </Grid>
      <DetailPaper elevation={1}>
        <Typography color="primary" variant="h3">
          DETALHES DA DEMISSÃO
        </Typography>
        <StyledGrid container alignItems="center" alignContent="center" mt={16}>
          {userData && (
            <>
              <DetailItem>
                <span>Início do desligamento</span>
                <p>{details.shutDownStartDate}</p>
              </DetailItem>
              <DetailItem>
                <span>Status</span>
                <p>{details.statusDismissal}</p>
              </DetailItem>
              <DetailItem>
                <span>Data de demissão</span>
                <p>{details.resignationDate}</p>
              </DetailItem>
              <DetailItem>
                <span>Tempo de empresa</span>
                <p>{details.companyTime}</p>
              </DetailItem>
              <DetailItem>
                <span>Data de saída</span>
                <p>{details.departureDate}</p>
              </DetailItem>
            </>
          )}
        </StyledGrid>
      </DetailPaper>
      <TabsPaper square elevation={1}>
        <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable">
          <Tab label="CHECKUP" index={0} />
          <Tab label="DESLIGAMENTO" index={1} />
          <Tab label="DOCUMENTOS RECISÃO" index={2} />
          <Tab label="DADOS CONTRATAÇÃO" index={3} />
          <Tab label="DADOS PESSOAIS" index={4} />
          <Tab label="DADOS DA FAMÍLIA" index={5} />
          <Tab label="DOCUMENTOS" index={6} />
          <Tab label="ENDEREÇO" index={7} />
          <Tab label="DADOS BANCÁRIOS" index={8} />
        </Tabs>
        {user.dismissalDetails && (
          <>
            <Checkup
              value={tabValue}
              index={0}
              data={user.dismissalDetails}
              setUser={setUser}
            />
            <Shutdown
              value={tabValue}
              index={1}
              data={user.dismissalDetails.shutDownData}
              admissionDate={user.admissionDate}
              setUser={setUser}
            />
            <Documents
              value={tabValue}
              index={2}
              data={user.dismissalDocuments}
              setUser={setUser}
            />
            <HiringData value={tabValue} index={3} data={user.hiringData} />
            <PersonalData
              value={tabValue}
              index={4}
              data={user.personalData}
              userInfo={userInfo}
            />
            <FamilyData value={tabValue} index={5} data={user.familyData} />
            <Documentation value={tabValue} index={6} data={user.documents} />
            <Address value={tabValue} index={7} data={user.address} />
            <BankData value={tabValue} index={8} data={user.bankData} />
          </>
        )}
      </TabsPaper>
    </Container>
  );
};

export default DismissalColaborator;
