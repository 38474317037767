import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { AddSharp } from '@material-ui/icons';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { SearchInput, SelectFilter } from '../../components';
import { SCALE_TYPES } from '../../utils/enums';
import {
  Container,
  JourneyContainer,
  SelectDiv,
  StyledIconButton,
  StyledLink,
  StyledTab,
  StyledTabs,
} from './styles';
import TableJourney from './TableJourney';

const scaleOptions = [
  {
    label: 'ESCALA',
    value: ' ',
  },
  {
    label: 'Padrão Semanal - 5x2',
    value: SCALE_TYPES['5x2'],
  },
  {
    label: 'Padrão Semanal - 6x1',
    value: SCALE_TYPES['6x1'],
  },
  {
    label: 'Escala - 12/36',
    value: SCALE_TYPES['12/36'],
  },
  {
    label: 'Escala - 24/48',
    value: SCALE_TYPES['24/48'],
  },
  { label: 'Escala - 5x1', value: SCALE_TYPES['5x1'] },
  {
    label: 'Call Center - 36 horas',
    value: SCALE_TYPES.CALL_CENTER_36,
  },
  {
    label: 'Call Center - 30 horas',
    value: SCALE_TYPES.CALL_CENTER_30,
  },
];

const pointTypeOptions = [
  {
    label: 'TIPO DE PONTO',
    value: ' ',
  },
  {
    label: 'Normal',
    value: 'Normal',
  },
  {
    label: 'Hora extra',
    value: 'Hora extra',
  },
  {
    label: 'Banco de Horas',
    value: 'Banco de Horas',
  },
];

const holidaysOptions = [
  {
    label: 'FERIADOS',
    value: ' ',
  },
  {
    label: 'Aplicada',
    value: 'Aplicada',
  },
  {
    label: 'Não aplicada',
    value: 'Nao aplicada',
  },
];

const statusOptions = [
  {
    label: 'STATUS',
    value: ' ',
  },
  {
    label: 'Ativado',
    value: 'Ativado',
  },
  {
    label: 'Desativado',
    value: 'Desativado',
  },
];

const Admission = () => {
  const [searchText, setSearchText] = useState('');
  const [scaleSelected, setScaleSelected] = useState(' ');
  const [pointTypeSelected, setPointTypeSelected] = useState(' ');
  const [holidaySelected, setHolidaySelected] = useState(' ');
  const [statusSelected, setStatusSelected] = useState(' ');

  const { path } = useRouteMatch();

  return (
    <Container>
      <Typography variant="h2">Jornadas</Typography>
      <Paper elevation={1}>
        <StyledTabs value={1} indicatorColor="primary">
          <StyledTab label="JORNADAS" value={1} />
        </StyledTabs>
        <JourneyContainer>
          <Grid container alignItems="center" justify="space-between">
            <SearchInput value={searchText} handleInput={setSearchText} />
            <div>
              <StyledLink to={`${path}/new-journey`}>
                <StyledIconButton onClick={() => {}}>
                  <AddSharp />
                </StyledIconButton>
                <Button color="primary" onClick={() => {}}>
                  ADICIONAR NOVA JORNADA
                </Button>
              </StyledLink>
            </div>
          </Grid>
          <SelectDiv>
            <SelectFilter
              selectOptions={scaleOptions}
              value={scaleSelected}
              handleChange={setScaleSelected}
            />
            <SelectFilter
              selectOptions={pointTypeOptions}
              value={pointTypeSelected}
              handleChange={setPointTypeSelected}
            />
            <SelectFilter
              selectOptions={holidaysOptions}
              value={holidaySelected}
              handleChange={setHolidaySelected}
            />
            <SelectFilter
              selectOptions={statusOptions}
              value={statusSelected}
              handleChange={setStatusSelected}
            />
          </SelectDiv>

          <TableJourney
            textFilter={searchText}
            holidayFilter={holidaySelected}
            statusFilter={statusSelected}
            pointTypeFilter={pointTypeSelected}
            scaleFilter={scaleSelected}
          />
        </JourneyContainer>
      </Paper>
    </Container>
  );
};

export default Admission;
