import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes, { string } from 'prop-types';
import React from 'react';
import { Tooltip } from '..';
import DownloadImage from '../../assets/download.svg';
import fileDoc from '../../assets/file-doc.svg';
import { StyledContainer } from './styles';

const ContractDownloader = ({
  file,
  remove,
  removeFn,
  title,
  isCollaborator,
}) => {
  const { location, status } = file;

  const colorMap = {
    sent: '#FFB300',
    approved: '#00be5f',
    rejected: '#fe7676',
  };

  const statusMap = {
    sent: 'Aguardando assinatura',
    approved: 'Assinado',
    rejected: 'Rejeitado',
  };

  const MAX_STRING_LENGTH = 20;

  return (
    <StyledContainer isCollaborator={isCollaborator} color={colorMap[status]}>
      <img src={fileDoc} alt="" />
      <Box
        display="flex"
        overflow="hidden"
        width={1}
        ml={1}
        flexDirection="column"
        height="100%"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Box
          fontSize={isCollaborator ? 12 : 10}
          fontWeight={700}
          marginBottom="2px"
          textOverflow="ellipsis"
          overflow="hidden"
          width={1}
          maxWidth="90%"
        >
          <Tooltip fullText={title} maxStringLength={MAX_STRING_LENGTH} bold />
        </Box>
        <Box
          fontSize={isCollaborator ? 12 : 10}
          textOverflow="ellipsis"
          overflow="hidden"
          width={1}
          maxWidth="90%"
          color={colorMap[status]}
        >
          {statusMap[status]}
        </Box>
      </Box>
      <IconButton size="small" download target="_blank" href={location}>
        <img src={DownloadImage} alt="" />
      </IconButton>
      {remove && (
        <IconButton color="inherit" size="small" onClick={removeFn}>
          <CloseIcon />
        </IconButton>
      )}
    </StyledContainer>
  );
};

ContractDownloader.propTypes = {
  file: PropTypes.shape({
    name: string,
    location: string,
    status: string,
  }).isRequired,
  remove: PropTypes.bool,
  removeFn: PropTypes.func,
  title: PropTypes.string.isRequired,
  isCollaborator: PropTypes.bool,
};

ContractDownloader.defaultProps = {
  remove: false,
  removeFn: () => {},
  isCollaborator: false,
};

export default ContractDownloader;
