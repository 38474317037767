import { Box, useTheme } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../hooks/auth';
import { setLoading } from '../../redux/ducks/loading';
import { setSnackbar } from '../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../services/api';
import { Grid, ProgressBar, Typography } from '../index';
import { Dot, StyledLink } from './styles';

const Skeleton = Component =>
  function Comp(props) {
    const {
      visibilityStep,
      stepNumber,
      step,
      stepTitle,
      stepDescription,
      visibilityProgress,
      skeletonImg,
      warning,
      contentBgColor,
    } = props;
    const [userData, setUserData] = useState({});
    const dispatch = useDispatch();

    const { palette } = useTheme();
    const { user } = useAuth();

    const getUserData = useCallback(async () => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();
        const response = await api.get(`users/${user._id}`);
        setUserData(response.data);
      } catch (e) {
        dispatch(setSnackbar(true, 'error', 'Erro ao recuperar seus dados'));
      } finally {
        dispatch(setLoading(false));
      }
    }, [user, dispatch]);

    useEffect(() => {
      getUserData();
    }, [getUserData]);

    return (
      <Box display="flex" flexDirection="row" width="100vw" height="100vh">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          flexWrap="nowrap"
          height="100vh"
          width={1 / 3}
          pt="40px"
          pb="40px"
          bgcolor={palette.primary.main}
          style={{ overflowY: 'auto' }}
        >
          <img src={skeletonImg} alt="" />
          <Box width="350px">
            <Typography
              textcolor="white"
              fontWeight={300}
              fontSize="28px"
              mt="32px"
              lineheight={1}
              component="h1"
            >
              {step}
            </Typography>
            <Typography
              textcolor="white"
              fontWeight={900}
              fontSize="36px"
              lineheight={1}
              component="h1"
            >
              {stepTitle}
            </Typography>
            <Typography
              textcolor="white"
              fontWeight={300}
              fontSize="16px"
              mt="32px"
            >
              {stepDescription}
            </Typography>
            {warning && (
              <Typography
                textcolor="white"
                fontWeight={300}
                fontSize="16px"
                mt="32px"
              >
                <b>Atenção:</b> {warning}
              </Typography>
            )}
            {visibilityStep === 'active' && (
              <Grid container alignItems="center" mt="40px">
                <StyledLink to="/1">
                  <Dot active={stepNumber === 1} />
                </StyledLink>
                <StyledLink to="/2">
                  <Dot active={stepNumber === 2} />
                </StyledLink>
                <StyledLink to="/3">
                  <Dot active={stepNumber === 3} />
                </StyledLink>
                <StyledLink to="/4">
                  <Dot active={stepNumber === 4} />
                </StyledLink>
                <StyledLink to="/5">
                  <Dot active={stepNumber === 5} />
                </StyledLink>
                <StyledLink to="/6">
                  <Dot active={stepNumber === 6} />
                </StyledLink>
              </Grid>
            )}
            {visibilityProgress === 'active' && (
              <Box mt={7} height={52}>
                <Typography
                  fontWeight={300}
                  textcolor="white"
                  align="center"
                  mb="16px"
                >
                  Progresso <b>{userData.percentageFilled || 0}%</b>
                </Typography>
                <ProgressBar
                  value={Number(userData.percentageFilled) || 0}
                  width="100%"
                  height="12px"
                  color="white"
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={2 / 3}
          height={1}
          bgcolor={contentBgColor || 'white'}
        >
          <Component userData={userData} getUserData={getUserData} />
        </Box>
      </Box>
    );
  };
export default Skeleton;
