import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import approvedIcon from '../../../assets/approved.svg';
import disapprovedIcon from '../../../assets/repproved.svg';

import { Typography } from '../../../components';
import { StyledTextField } from '../styles';

const ApprovalStep = ({ status, reason }) => {
  if (status === 'approved')
    return (
      <Box width={1} display="flex" justifyContent="flex-end" mt={4} mb={2}>
        <img src={approvedIcon} width="20" alt="" />
        <Typography color="primary" fontWeight="700" ml="8px">
          Etapa aprovada!
        </Typography>
      </Box>
    );
  if (status === 'disapproved')
    return (
      <Box
        width={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        mt={4}
        mb={2}
      >
        <Box width={1} display="flex" mb={1}>
          <img src={disapprovedIcon} width="20" alt="" />
          <Typography color="error" fontWeight="700" ml="8px">
            Etapa reprovada
          </Typography>
        </Box>
        <StyledTextField
          multiline
          rows={2}
          name="reason"
          label="Motivo"
          variant="filled"
          disabled
          value={reason}
          fullWidth
        />
      </Box>
    );
};

ApprovalStep.propTypes = {
  status: PropTypes.string.isRequired,
  reason: PropTypes.string,
};

ApprovalStep.defaultProps = {
  reason: '',
};

export default ApprovalStep;
