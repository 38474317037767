const TOKEN_KEY = '@pessoalize:token';
const USER_KEY = '@pessoalize:user';

const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

const login = ({ user, token }) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

const getToken = () => localStorage.getItem(TOKEN_KEY);

const getUser = () => {
  const userData = localStorage.getItem('@pessoalize:user');
  return JSON.parse(userData);
};

const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_KEY);
};

export { TOKEN_KEY, isAuthenticated, getToken, login, logout, getUser };
