import { ButtonBase, Divider, MenuItem, Paper } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
`;

export const TabsPaper = styled(Paper)`
  margin-top: 16px;
`;

export const TabContainer = styled.div`
  padding: 24px;
`;

export const ContainerInput = styled.div`
  height: 80px;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : 0)};

  & + div {
    margin-left: 16px;
  }

  input {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const SubTitleForm = styled.legend`
  width: 100%;
  margin: 8px 0;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const FilterText = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.secondary.main};

  & + p {
    margin-left: 32px;
  }
  span {
    font-weight: 300;
    margin-left: 12px;
  }
`;

export const RecordsButton = styled(ButtonBase)`
  width: 150px;
  height: 54px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  background: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const StyledDivider = styled(Divider)`
  margin: 12px 0 24px 0;
`;

export const RequestsCount = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 17px;
  height: 17px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 12px;
  margin-right: 8px;
  background-color: #e5e5e5;
  color: ${({ theme }) => theme.palette.primary.main};
`;
