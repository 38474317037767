import styled, { css } from 'styled-components';
import { Typography } from '@material-ui/core';

export const StyledTypography = styled(Typography)`
  ${props => css`
    font-size: ${props.fontSize};
    font-weight: ${props.fontWeight};
    opacity: ${props.opacity};
    text-align: ${props.textalign};
    text-decoration: ${props.decoration};
    width: ${props.width};
    line-height: ${props.lineheight};
    margin: ${props.ma};
    margin-left: ${props.ml};
    margin-right: ${props.mr};
    margin-bottom: ${props.mb};
    margin-top: ${props.mt};
    padding: ${props.pa};
    padding-top: ${props.pt};
    padding-left: ${props.pl};
    padding-right: ${props.pr};
    padding-bottom: ${props.pb};
  `}
  ${props =>
    props.textcolor &&
    css`
      color: ${props.textcolor};
    `}
`;
