import { TextField as TextFieldMaterial } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';
import PropTypes from 'prop-types';
import React from 'react';

export const RenderTextField = (name, label) => {
  return (
    <Field
      component={TextField}
      name={name}
      label={label}
      variant="filled"
      fullWidth
      required
    />
  );
};

export const RenderAutoCompleteField = ({
  options,
  touched,
  handleChangeState,
  setFieldValue,
  errors,
  isSubmitting,
  name,
  label,
}) => {
  return (
    <Field
      name={name}
      component={Autocomplete}
      options={options}
      getOptionLabel={option => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      fullWidth
      renderInput={params => (
        <TextFieldMaterial
          {...params}
          error={touched[name] && !!errors[name]}
          helperText={touched[name] && errors[name]}
          label={label}
          variant="filled"
          required
          disabled={isSubmitting}
        />
      )}
      onChange={(e, fieldValue) => handleChangeState(fieldValue, setFieldValue)}
    />
  );
};

RenderAutoCompleteField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeState: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export const RenderRawAutocompleteField = (
  options,
  touched,
  errors,
  isSubmitting,
  name,
  label,
  handleChangeState,
  setFieldValue,
) => {
  return (
    <Field
      name={name}
      component={Autocomplete}
      options={options}
      fullWidth
      renderInput={params => (
        <TextFieldMaterial
          {...params}
          error={touched[name] && !!errors[name]}
          helperText={touched[name] && errors[name]}
          label={label}
          required
          variant="filled"
          disabled={isSubmitting}
        />
      )}
      onChange={(e, fieldValue) =>
        handleChangeState(fieldValue, setFieldValue, name)
      }
    />
  );
};
