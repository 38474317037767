import styled, { css } from 'styled-components';
import { TextField } from 'formik-material-ui';

export const StyledTextField = styled(TextField)`
  height: 80px;

  ${({ mt }) => css`
    margin-top: ${mt};
  `}

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    background-color: #1e3037;
    border-radius: 0;
    transition: background-color 5000s ease-in-out 0s;
  }

  input {
    color: white;
    background-color: #1e3037;
  }

  .MuiInputLabel-root {
    color: white;
  }

  ${({ theme }) => css`
    & .Mui-error {
      color: ${theme.palette.error.main};
    }
    & .Mui-focused {
      color: ${theme.palette.primary.main};
    }
  `}
`;
