import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';

import formatCpf from '../../../utils/formatCpf';

import { TabPanel, Typography } from '../../../components';

import {
  TabContainer,
  ContainerInput,
  SubTitleForm,
  StyledTextField,
  DependentContainer,
} from '../styles';

const FamilyData = props => {
  const { value, index, data, setUser, ...other } = props;
  const [familyData, setFamilyData] = useState({
    maritalStatus: '',
    mothersName: '',
    fathersName: '',
    hasDependents: false,
    dependents: [
      {
        dependentName: '',
        dependentType: '',
        dependentbirthDate: '',
        dependentCpf: '',
      },
    ],
  });

  useEffect(() => {
    if (Object.keys(data).length) {
      if (data.dependents.length > 0) {
        const dependentsFormated = data.dependents.map(dependent => ({
          ...dependent,
          dependentbirthDate: new Date(
            dependent.dependentbirthDate,
          ).toLocaleDateString('pt-br'),
        }));

        setFamilyData({
          ...data,
          dependents: dependentsFormated,
        });
      } else {
        setFamilyData(data);
      }
    }
  }, [data]);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Box
          component={Grid}
          container
          alignItems="center"
          justify="space-between"
          spacing={2}
          p={1}
        >
          <SubTitleForm>Estado civil e Filiação</SubTitleForm>

          <ContainerInput item lg={3}>
            <StyledTextField
              name="maritalStatus"
              label="Estado civil"
              variant="filled"
              fullWidth
              value={familyData.maritalStatus}
              disabled
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="mothersName"
              label="Nome da mãe"
              variant="filled"
              fullWidth
              value={familyData.mothersName}
              disabled
            />
          </ContainerInput>
          <ContainerInput item lg={3}>
            <StyledTextField
              name="fathersName"
              label="Nome do pai"
              variant="filled"
              fullWidth
              value={familyData.fathersName}
              disabled
            />
          </ContainerInput>
          <ContainerInput item lg={3} />

          <SubTitleForm>Dependentes</SubTitleForm>
          <Box display="flex" flexDirection="column" width="600px" mb={2}>
            {familyData.hasDependents ? (
              familyData.dependents.map(dependent => (
                <DependentContainer key={dependent.dependentCpf}>
                  <Box display="flex" flexDirection="column">
                    <Typography opacity="0.5" fontSize="14px">
                      {dependent.dependentName}
                    </Typography>
                    <Box display="flex" flexDirection="row">
                      <Typography
                        opacity="0.5"
                        fontSize="12px"
                        fontWeight={300}
                        mr="16px"
                      >
                        Tipo: {dependent.dependentType}
                      </Typography>
                      <Typography
                        opacity="0.5"
                        fontSize="12px"
                        fontWeight={300}
                        mr="16px"
                      >
                        Data de nascimento: {dependent.dependentbirthDate}
                      </Typography>
                      <Typography
                        opacity="0.5"
                        fontSize="12px"
                        fontWeight={300}
                        mr="16px"
                      >
                        CPF: {formatCpf(dependent.dependentCpf)}
                      </Typography>
                    </Box>
                  </Box>
                </DependentContainer>
              ))
            ) : (
              <Typography
                fontSize="18px"
                fontWeight="bold"
                opacity={0.5}
                mt="16px"
                ml="8px"
              >
                Sem dependentes
              </Typography>
            )}
          </Box>
        </Box>
      </TabContainer>
    </TabPanel>
  );
};

FamilyData.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

FamilyData.defaultProps = {
  data: {},
};

export default FamilyData;
