import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ContainerModal, ModalTitleBar } from './styles';

const ModalSkeleton = React.forwardRef(
  ({ children, title, handleClickOnClose, maxWidth }, ref) => {
    return (
      <ContainerModal maxWidth={maxWidth} ref={ref}>
        <ModalTitleBar>
          <h1>{title}</h1>
          <IconButton color="secondary" onClick={handleClickOnClose}>
            <CloseIcon />
          </IconButton>
        </ModalTitleBar>
        <div>{children}</div>
      </ContainerModal>
    );
  },
);

ModalSkeleton.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  handleClickOnClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
};

ModalSkeleton.defaultProps = {
  maxWidth: '1150px',
};

export default ModalSkeleton;
