import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { ModalSkeleton } from '../../../components';
import { ActionsBar, ContainerModal, StyledGrid } from './styles';

const ConfirmReturnFromActivityModal = ({
  title,
  handleClose,
  handleConfirmChange,
  activity,
  statusActiveLabel,
}) => (
  <ModalSkeleton
    title={title}
    handleClickOnClose={handleClose}
    maxWidth="570px"
  >
    <ContainerModal>
      <p>
        Você realmente deseja voltar o colaborador da{' '}
        {statusActiveLabel(activity.type)} ?
      </p>

      <ActionsBar container alignItems="center" justify="flex-end">
        <StyledGrid mt="32px">
          <Button color="secondary" onClick={handleClose} size="small">
            CANCELAR
          </Button>
          <Button onClick={handleConfirmChange} color="secondary" size="small">
            CONFIRMAR
          </Button>
        </StyledGrid>
      </ActionsBar>
    </ContainerModal>
  </ModalSkeleton>
);

ConfirmReturnFromActivityModal.propTypes = {
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirmChange: PropTypes.func.isRequired,
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  statusActiveLabel: PropTypes.func.isRequired,
};

export default ConfirmReturnFromActivityModal;
