import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Paper,
  Radio,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import { RadioGroup, TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Return from '../../assets/arrow-left.svg';
import NewColaborator from '../../assets/import-colaborator.svg';
import {
  CollaboratorButton,
  Grid,
  MoneyInput,
  PhoneInput,
  Typography,
} from '../../components';
import { useAuth } from '../../hooks/auth';
import { setLoading } from '../../redux/ducks/loading';
import { setSnackbar } from '../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../services/api';
import newColaboratorSchema from '../../validations/newColaborator';
import UserImprortModal from './components/UserImportModal';
import {
  ContainerInput,
  StyledBorderBox,
  StyledButtonBase,
  StyledImg,
  StyledTab,
  StyledTabs,
} from './styles';

const AdmissionNewColaborator = () => {
  const initialValues = {
    fullName: '',
    email: '',
    phone: '',
    jobRole: '',
    department: '',
    hasVt: '',
    hasVa: '',
    hasVr: '',
    hiringData: {
      regime: '',
      hasStandardSalary: '',
      salary: 0,
      vt: 0,
      va: 0,
      vr: 0,
    },
    limitDate: null,
    admissionDate: null,
  };
  const [jobRoles, setJobRoles] = useState([]);
  const [departments, setDepartaments] = useState([]);
  const [isUserImportModalOpen, setUserImportModal] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useAuth();

  const handleUserImportModalOpen = () => setUserImportModal(true);
  const handleUserImportModalClose = () => setUserImportModal(false);

  useEffect(() => {
    const api = AuthenticatedHttpClient();

    async function getOptions() {
      try {
        dispatch(setLoading(true));
        const [jobRolesResponse, departmentsResponse] = await Promise.all([
          await api.get(`job-role/${user.company._id}`),
          await api.get(`department/${user.company._id}`),
        ]);
        const { data: dataJobRoles } = jobRolesResponse;
        const { data: dataDepartments } = departmentsResponse;

        const optionsJobRoles = dataJobRoles.map(jobRoleInfo => ({
          label: jobRoleInfo.name,
          value: jobRoleInfo._id,
          baseSalary: jobRoleInfo.baseSalary,
        }));

        const optionsDepartments = dataDepartments.map(departmentIndo => ({
          label: departmentIndo.name,
          value: departmentIndo._id,
        }));

        setJobRoles(optionsJobRoles);
        setDepartaments(optionsDepartments);
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Falha ao buscar cargos e departamentos'),
        );
      } finally {
        dispatch(setLoading(false));
      }
    }
    getOptions();
  }, [user, dispatch]);

  const regimeOptions = [
    {
      label: 'CLT',
      value: 'CLT',
    },
    {
      label: 'PJ',
      value: 'PJ',
    },
  ];

  const handleSetStandartSalary = useCallback(
    (jobRoleId, setField) => {
      const jobRoleSalary = jobRoles.find(item => item.value === jobRoleId);
      if (jobRoleSalary) {
        setField('hiringData.salary', jobRoleSalary.baseSalary);
      }
    },
    [jobRoles],
  );

  const handleChangeJobRole = useCallback(
    ({ standard, jobRoleId, setField }) => {
      if (standard === 'true') {
        handleSetStandartSalary(jobRoleId, setField);
      }
    },
    [handleSetStandartSalary],
  );

  const handleAddColaborator = useCallback(
    async (values, { setSubmitting, resetForm }) => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        const collaboratorRequest = {
          ...values,
          company: user.company._id,
        };

        delete collaboratorRequest.hasVa;
        delete collaboratorRequest.hasVr;
        delete collaboratorRequest.hasVt;

        const limitDateObject = new Date(collaboratorRequest.limitDate);
        limitDateObject.setHours(0, 0, 0, 0);

        collaboratorRequest.limitDate = limitDateObject;

        await api.post('users/employee', collaboratorRequest);

        resetForm();

        dispatch(setSnackbar(true, 'success', 'Colaborador cadastrado!'));
      } catch (error) {
        dispatch(
          setSnackbar(
            true,
            'error',
            'Não foi possível cadastrar o colaborador',
          ),
        );
      } finally {
        dispatch(setLoading(false));
        setSubmitting(false);
      }
    },
    [dispatch, user],
  );

  return (
    <Box p={3}>
      <StyledButtonBase onClick={() => history.goBack()}>
        <img src={Return} alt="" />
      </StyledButtonBase>
      <Box mt={3}>
        <Paper elevation={1}>
          <StyledTabs value={1} indicatorColor="primary">
            <StyledTab label="ADICIONAR NOVO COLABORADOR" value={1} />
          </StyledTabs>
          <Box p={3}>
            <Formik
              validationSchema={newColaboratorSchema}
              initialValues={initialValues}
              onSubmit={handleAddColaborator}
              enableReinitialize
            >
              {({
                submitForm,
                isSubmitting,
                values,
                errors,
                setFieldValue,
                touched,
              }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <Form>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <Typography color="secondary" fontSize="0.875rem">
                          Informe os dados do colaborador
                        </Typography>
                      </Grid>
                      <Grid item container spacing={2} xs={6}>
                        <ContainerInput item xs={12}>
                          <Field
                            component={TextField}
                            name="fullName"
                            label="Nome completo"
                            variant="filled"
                            fullWidth
                          />
                        </ContainerInput>
                        <ContainerInput item xs={6}>
                          <Field
                            component={TextField}
                            name="email"
                            label="E-mail"
                            variant="filled"
                            fullWidth
                          />
                        </ContainerInput>
                        <ContainerInput item xs={6}>
                          <Field
                            component={TextField}
                            name="phone"
                            label="Telefone"
                            variant="filled"
                            fullWidth
                            InputProps={{
                              inputComponent: PhoneInput,
                            }}
                          />
                        </ContainerInput>
                        <Grid item xs={12}>
                          <Box borderTop={1} borderColor="third.main" my={1} />
                          <Typography color="secondary" fontSize="0.875rem">
                            Informe os dados do cargo
                          </Typography>
                        </Grid>
                        <ContainerInput item xs={6}>
                          <Field
                            component={TextField}
                            select
                            name="hiringData.regime"
                            variant="filled"
                            fullWidth
                            label="Regime Trabalhista"
                          >
                            {regimeOptions.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </ContainerInput>
                        <ContainerInput item xs={6} />
                        <ContainerInput item xs={6}>
                          <Field
                            component={TextField}
                            select
                            name="department"
                            variant="filled"
                            fullWidth
                            label="Departamento"
                          >
                            {departments.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </ContainerInput>
                        <ContainerInput item xs={6}>
                          <Field
                            component={TextField}
                            select
                            name="jobRole"
                            variant="filled"
                            fullWidth
                            label="Cargo"
                            InputProps={{
                              onChange: e => {
                                handleChangeJobRole({
                                  standard: values.hiringData.hasStandardSalary,
                                  jobRoleId: e.target.value,
                                  setField: setFieldValue,
                                });
                                setFieldValue('jobRole', e.target.value);
                              },
                            }}
                          >
                            {jobRoles.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </ContainerInput>
                        <ContainerInput item xs={6}>
                          <FormControl
                            error={
                              touched.hiringData &&
                              errors.hiringData &&
                              !!errors.hiringData.hasStandardSalary
                            }
                          >
                            <FormLabel component="legend">
                              <Typography
                                opacity="0.7"
                                color="secondary"
                                fontSize="14px"
                              >
                                Definir salário padrão ao cargo?
                              </Typography>
                            </FormLabel>
                            <Field
                              component={RadioGroup}
                              name="hiringData.hasStandardSalary"
                              row
                            >
                              <FormControlLabel
                                value="true"
                                control={<Radio disabled={isSubmitting} />}
                                label="SIM"
                                disabled={isSubmitting || !values.jobRole}
                                onChange={() =>
                                  handleSetStandartSalary(
                                    values.jobRole,
                                    setFieldValue,
                                  )
                                }
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio disabled={isSubmitting} />}
                                label="NÃO"
                                disabled={isSubmitting || !values.jobRole}
                              />
                            </Field>
                            <FormHelperText>
                              {touched.hiringData &&
                                errors.hiringData &&
                                errors.hiringData.hasStandardSalary}
                            </FormHelperText>
                          </FormControl>
                        </ContainerInput>
                        <ContainerInput item xs={6}>
                          {values.hiringData.hasStandardSalary && (
                            <Field
                              component={TextField}
                              name="hiringData.salary"
                              label="Valor"
                              variant="filled"
                              fullWidth
                              disabled={
                                values.hiringData.hasStandardSalary === 'true'
                              }
                              InputProps={{
                                inputComponent: MoneyInput,
                              }}
                            />
                          )}
                        </ContainerInput>
                        <ContainerInput item xs={6}>
                          <FormControl error={touched.hasVt && !!errors.hasVt}>
                            <FormLabel component="legend">
                              <Typography
                                opacity="0.7"
                                color="secondary"
                                fontSize="14px"
                              >
                                Vale transporte?
                              </Typography>
                            </FormLabel>
                            <Field component={RadioGroup} name="hasVt" row>
                              <FormControlLabel
                                value="true"
                                control={<Radio disabled={isSubmitting} />}
                                label="SIM"
                                disabled={isSubmitting}
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio disabled={isSubmitting} />}
                                label="NÃO"
                                disabled={isSubmitting}
                              />
                            </Field>
                            <FormHelperText>
                              {touched.hasVt && errors.hasVt}
                            </FormHelperText>
                          </FormControl>
                        </ContainerInput>
                        <ContainerInput item xs={6}>
                          {values.hasVt === 'true' && (
                            <Field
                              component={TextField}
                              name="hiringData.vt"
                              label="Valor"
                              variant="filled"
                              fullWidth
                              InputProps={{
                                inputComponent: MoneyInput,
                              }}
                            />
                          )}
                        </ContainerInput>
                        <ContainerInput item xs={6}>
                          <FormControl error={touched.hasVa && !!errors.hasVa}>
                            <FormLabel component="legend">
                              <Typography
                                opacity="0.7"
                                color="secondary"
                                fontSize="14px"
                              >
                                Vale Alimentação?
                              </Typography>
                            </FormLabel>
                            <Field component={RadioGroup} name="hasVa" row>
                              <FormControlLabel
                                value="true"
                                control={<Radio disabled={isSubmitting} />}
                                label="SIM"
                                disabled={isSubmitting}
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio disabled={isSubmitting} />}
                                label="NÃO"
                                disabled={isSubmitting}
                              />
                            </Field>
                            <FormHelperText>
                              {touched.hasVa && errors.hasVa}
                            </FormHelperText>
                          </FormControl>
                        </ContainerInput>
                        <ContainerInput item xs={6}>
                          {values.hasVa === 'true' && (
                            <Field
                              component={TextField}
                              name="hiringData.va"
                              label="Valor"
                              variant="filled"
                              fullWidth
                              InputProps={{
                                inputComponent: MoneyInput,
                              }}
                            />
                          )}
                        </ContainerInput>
                        <ContainerInput item xs={6}>
                          <FormControl error={touched.hasVr && !!errors.hasVr}>
                            <FormLabel component="legend">
                              <Typography
                                opacity="0.7"
                                color="secondary"
                                fontSize="14px"
                              >
                                Vale Refeição?
                              </Typography>
                            </FormLabel>
                            <Field component={RadioGroup} name="hasVr" row>
                              <FormControlLabel
                                value="true"
                                control={<Radio disabled={isSubmitting} />}
                                label="SIM"
                                disabled={isSubmitting}
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio disabled={isSubmitting} />}
                                label="NÃO"
                                disabled={isSubmitting}
                              />
                            </Field>
                            <FormHelperText>
                              {touched.hasVr && errors.hasVr}
                            </FormHelperText>
                          </FormControl>
                        </ContainerInput>
                        <ContainerInput item xs={6}>
                          {values.hasVr === 'true' && (
                            <Field
                              component={TextField}
                              name="hiringData.vr"
                              label="Valor"
                              variant="filled"
                              fullWidth
                              InputProps={{
                                inputComponent: MoneyInput,
                              }}
                            />
                          )}
                        </ContainerInput>
                      </Grid>
                      <Grid item xs={6}>
                        <StyledBorderBox
                          border={1}
                          width="100%"
                          borderColor="primary.main"
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          m={2}
                        >
                          <StyledImg src={NewColaborator} alt="" />
                          <Typography
                            mt="20px"
                            color="secondary"
                            fontWeight="bold"
                            fontSize="12px"
                            textalign="center"
                          >
                            Precisa adicionar mais de um ou vários colaboradores
                            de uma vez só?
                          </Typography>
                          <Typography
                            mt="10px"
                            mb="28px"
                            color="secondary"
                            fontSize="11px"
                            textalign="center"
                          >
                            Utilize nossa ferramenta de importação em massa!
                          </Typography>
                          <CollaboratorButton
                            text="IMPORTAR COLABORADORES"
                            mb="20px"
                            onClick={handleUserImportModalOpen}
                          />
                        </StyledBorderBox>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={6}>
                          <Box borderTop={1} borderColor="third.main" my={1} />
                        </Grid>
                        <Typography color="secondary" fontSize="0.875rem">
                          Datas
                        </Typography>
                      </Grid>
                      <Grid item container justify="space-between" xs={12}>
                        <Grid item container spacing={2} xs={6}>
                          <ContainerInput item lg={6}>
                            <Field
                              component={KeyboardDatePicker}
                              name="admissionDate"
                              label="Data admissão"
                              inputVariant="filled"
                              cancelLabel="CANCELAR"
                              format="dd/MM/yyyy"
                              invalidDateMessage="Formato de data inválido"
                              fullWidth
                            />
                          </ContainerInput>
                          <ContainerInput item lg={6}>
                            <Field
                              component={KeyboardDatePicker}
                              name="limitDate"
                              label="Prazo preenchimento"
                              inputVariant="filled"
                              disablePast
                              cancelLabel="CANCELAR"
                              format="dd/MM/yyyy"
                              invalidDateMessage="Formato de data inválido"
                              fullWidth
                            />
                          </ContainerInput>
                        </Grid>
                        <Grid
                          item
                          container
                          justify="flex-end"
                          alignItems="center"
                          xs={6}
                          spacing={3}
                        >
                          <Grid item>
                            <Button>
                              <Typography
                                opacity="0.5"
                                fontSize="14px"
                                fontWeight="bold"
                                color="secondary"
                              >
                                CANCELAR
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={submitForm}
                              disabled={isSubmitting}
                            >
                              <Typography
                                fontSize="14px"
                                fontWeight="bold"
                                color="secondary"
                              >
                                ENVIAR CONVITE
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                </MuiPickersUtilsProvider>
              )}
            </Formik>
          </Box>
        </Paper>
      </Box>
      <Modal open={isUserImportModalOpen}>
        <UserImprortModal handleClose={handleUserImportModalClose} />
      </Modal>
    </Box>
  );
};

export default AdmissionNewColaborator;
