import { Box, Button, Grid, MenuItem } from '@material-ui/core';
import { format, formatISO, parse, parseISO } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { Checkbox, TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  DateInput,
  MoneyInput,
  PercentageInput,
  TabPanel,
  Typography,
} from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { REGIME_TYPE } from '../../../utils/enums';
import hiringDataCLTSchema from '../../../validations/hiringDataCLTSchema';
import hiringDataPJSchema from '../../../validations/hiringDataPJSchema';
import {
  AdditionalContainer,
  ContainerInput,
  StyledCheckbox,
  SubTitleForm,
  TabContainer,
} from '../styles';

const HiringData = props => {
  const { value, index, baseSalary, setUser, data, ...other } = props;
  const [workdayOptions, setWorkdayOptions] = useState([]);
  const [hiringData, setHiringData] = useState({
    salary: '',
    vt: '',
    va: '',
    vr: '',
    hasStandardSalary: false,
    experiencePeriod: '',
    beginningOfFirstPeriod: '',
    endOfFirstPeriod: '',
    startOfSecondPeriod: '',
    endOfSecondPeriod: '',
    workday: '',
    additional: {
      warning: false,
      unhealthy: false,
      unhealthyPercentage: '',
      risk: false,
      dangerousness: false,
    },
  });

  const { userId: collaboratorId } = useParams();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const thirtySixtyExperiencePeriod = '30/60';

  useEffect(() => {
    async function getData() {
      const api = AuthenticatedHttpClient();

      const workDayResponse = await api.get(`workday/${user.company._id}`);

      setWorkdayOptions(workDayResponse.data);

      if (Object.keys(data).length) {
        delete data.createdAt;
        delete data.updatedAt;

        if (data.beginningOfFirstPeriod) {
          const beginningOfFirstPeriod = parseISO(data.beginningOfFirstPeriod);
          const endOfFirstPeriod = parseISO(data.endOfFirstPeriod);
          const startOfSecondPeriod = parseISO(data.startOfSecondPeriod);
          const endOfSecondPeriod = parseISO(data.endOfSecondPeriod);

          setHiringData(state => ({
            ...state,
            ...data,
            workday: data.workday._id,
            beginningOfFirstPeriod: format(
              beginningOfFirstPeriod,
              'dd/MM/yyyy',
            ),
            endOfFirstPeriod: format(endOfFirstPeriod, 'dd/MM/yyyy'),
            startOfSecondPeriod: format(startOfSecondPeriod, 'dd/MM/yyyy'),
            endOfSecondPeriod: format(endOfSecondPeriod, 'dd/MM/yyyy'),
          }));
        } else {
          setHiringData(state => ({
            ...state,
            ...data,
            workday: data.workday._id,
          }));
        }
      }
    }

    getData();
  }, [user, data]);

  const isCLT = data?.regime === REGIME_TYPE.CLT;

  const handleSubmitHiringData = useCallback(
    async (formikValues, { setSubmitting }) => {
      const values = formikValues;
      try {
        dispatch(setLoading(true));

        const api = AuthenticatedHttpClient();

        if (isCLT) {
          values.beginningOfFirstPeriod = formatISO(
            parse(values.beginningOfFirstPeriod, 'dd/MM/yyyy', new Date()),
          );
          values.endOfFirstPeriod = formatISO(
            parse(values.endOfFirstPeriod, 'dd/MM/yyyy', new Date()),
          );
          values.startOfSecondPeriod = formatISO(
            parse(values.startOfSecondPeriod, 'dd/MM/yyyy', new Date()),
          );
          values.endOfSecondPeriod = formatISO(
            parse(values.endOfSecondPeriod, 'dd/MM/yyyy', new Date()),
          );
        }

        const formObject = {
          hiringData: {
            ...values,
          },
        };

        const response = await api.put(`users/${collaboratorId}`, formObject);

        setUser(response.data);
        dispatch(setSnackbar(true, 'success', 'Dados alterados'));
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possível alterar os dados'),
        );
      } finally {
        dispatch(setLoading(false));
        setSubmitting(false);
      }
    },
    [dispatch, collaboratorId, setUser, isCLT],
  );

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Formik
          initialValues={hiringData}
          onSubmit={handleSubmitHiringData}
          validationSchema={isCLT ? hiringDataCLTSchema : hiringDataPJSchema}
          enableReinitialize
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <Box
                component={Grid}
                container
                alignItems="center"
                justify="space-between"
                spacing={2}
                p={1}
              >
                <SubTitleForm>Dados de contratação</SubTitleForm>

                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="salary"
                    label="Salário"
                    variant="filled"
                    fullWidth
                    disabled={!!values.hasStandardSalary}
                    InputProps={{
                      inputComponent: MoneyInput,
                    }}
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="vt"
                    label="Vale transporte"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      inputComponent: MoneyInput,
                    }}
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="vr"
                    label="Vale Refeição"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      inputComponent: MoneyInput,
                    }}
                  />
                </ContainerInput>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    name="va"
                    label="Vale Alimentação"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      inputComponent: MoneyInput,
                    }}
                  />
                </ContainerInput>
                <Box mt="-8px" ml={1} mb={2}>
                  <Field
                    component={StyledCheckbox}
                    name="hasStandardSalary"
                    checked={values.hasStandardSalary}
                    color="primary"
                    type="checkbox"
                    onClick={e => {
                      if (e.target.value) {
                        setFieldValue('salary', baseSalary);
                      }
                    }}
                    Label={{
                      label: 'Padrão ao cargo',
                    }}
                  />
                </Box>
                {isCLT && (
                  <>
                    <SubTitleForm>Período de experiência</SubTitleForm>
                    <ContainerInput item lg={3}>
                      <Field
                        component={TextField}
                        select
                        name="experiencePeriod"
                        label="Período de experiência"
                        value={0}
                        variant="filled"
                        fullWidth
                      >
                        <MenuItem value="30/60">30 + 60 dias</MenuItem>
                        <MenuItem value="45/45">45 + 45 dias</MenuItem>
                      </Field>
                    </ContainerInput>
                    <ContainerInput item lg={9} />
                    {values.experiencePeriod && (
                      <>
                        <ContainerInput item lg={3}>
                          <Field
                            component={TextField}
                            name="beginningOfFirstPeriod"
                            label="Início do contrato de experiência"
                            variant="filled"
                            fullWidth
                            InputProps={{
                              inputComponent: DateInput,
                            }}
                          />
                        </ContainerInput>
                        <ContainerInput item lg={3}>
                          <Field
                            component={TextField}
                            name="endOfFirstPeriod"
                            label="Fim do primeiro período de experiência"
                            variant="filled"
                            fullWidth
                            InputProps={{
                              inputComponent: DateInput,
                            }}
                          />
                        </ContainerInput>
                        <ContainerInput item lg={3}>
                          <Field
                            component={TextField}
                            name="endOfSecondPeriod"
                            label="Fim do contrato de experiência"
                            variant="filled"
                            fullWidth
                            InputProps={{
                              inputComponent: DateInput,
                            }}
                          />
                        </ContainerInput>
                        <ContainerInput item lg={3}>
                          <Field
                            component={TextField}
                            style={{ display: 'none' }}
                            name="startOfSecondPeriod"
                            label={
                              values.experiencePeriod ===
                              thirtySixtyExperiencePeriod
                                ? 'Início dos 60 dias finais'
                                : 'Início dos 45 dias finais'
                            }
                            variant="filled"
                            fullWidth
                            InputProps={{
                              inputComponent: DateInput,
                            }}
                          />
                        </ContainerInput>
                      </>
                    )}
                  </>
                )}
                <SubTitleForm>Jornada de trabalho</SubTitleForm>
                <ContainerInput item lg={3}>
                  <Field
                    component={TextField}
                    select
                    name="workday"
                    label="Selecione"
                    variant="filled"
                    fullWidth
                  >
                    {workdayOptions.map(option => (
                      <MenuItem value={option._id} key={option._id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Field>
                </ContainerInput>

                <SubTitleForm>Adicionais</SubTitleForm>
                <Typography
                  mt="16px"
                  mb="8px"
                  color="secondary"
                  fontSize="14px"
                >
                  Sobreaviso
                </Typography>
                <AdditionalContainer>
                  <Box display="flex" alignItems="center">
                    <Field
                      component={Checkbox}
                      name="additional.warning"
                      checked={values.additional.warning}
                      type="checkbox"
                      color="primary"
                    />
                    <p>
                      Quem precisa estar disponível a qualquer momento do seu
                      período de descanso para voltar ao trabalho. Essa hora de
                      sobreaviso equivale a um terço do valor da hora regular de
                      trabalho.
                    </p>
                  </Box>
                </AdditionalContainer>
                <Typography
                  mt="16px"
                  mb="8px"
                  color="secondary"
                  fontSize="14px"
                >
                  Insalubridade
                </Typography>
                <AdditionalContainer>
                  <Box display="flex" alignItems="center">
                    <Field
                      component={Checkbox}
                      name="additional.unhealthy"
                      checked={values.additional.unhealthy}
                      type="checkbox"
                      color="primary"
                    />
                    <p>
                      O adicional de insalubridade é o benefício pago quando há
                      exposição a agentes físicos, químicos e biológicos que
                      coloquem em risco a saúde do funcionário. As condições que
                      são consideradas insalubres estão previstas na Norma
                      Regulamentadora 15, da Portaria n. 3.214/78. O valor do
                      adicional de insalubridade varia entre 10% e 40% de acordo
                      com o nível de exposição, mas essa porcentagem é calculada
                      sobre o valor do salário mínimo e não sobre o salário
                      recebido.
                    </p>
                  </Box>
                  <Box width={1} maxWidth="250px" ml={3} height="60px">
                    <Field
                      component={TextField}
                      name="additional.unhealthyPercentage"
                      label="Porcentagem"
                      variant="filled"
                      fullWidth
                      placeholder="Informe de 10 a 40%"
                      InputProps={{
                        style: { backgroundColor: '#ECECEC' },
                        inputComponent: PercentageInput,
                      }}
                    />
                  </Box>
                </AdditionalContainer>
                <Typography
                  mt="16px"
                  mb="8px"
                  color="secondary"
                  fontSize="14px"
                >
                  Risco
                </Typography>
                <AdditionalContainer>
                  <Box display="flex" alignItems="center">
                    <Field
                      component={Checkbox}
                      name="additional.risk"
                      checked={values.additional.risk}
                      type="checkbox"
                      color="primary"
                    />
                    <p>
                      Funcionários de portos que trabalham em mar ou em terra
                      têm direito ao adicional de risco, regulado pela Lei 4.860
                      de 26.11.1965. O valor do benefício é de 40% sobre o valor
                      do salário por hora de trabalho regular diurna. Segundo
                      previsto no artigo 14 da lei, o pagamento desse adicional
                      substitui adicionais de insalubridade e de periculosidade.
                    </p>
                  </Box>
                </AdditionalContainer>
                <Typography
                  mt="16px"
                  mb="8px"
                  color="secondary"
                  fontSize="14px"
                >
                  Periculosidade
                </Typography>
                <AdditionalContainer>
                  <Box display="flex" alignItems="center">
                    <Field
                      component={Checkbox}
                      name="additional.dangerousness"
                      checked={values.additional.dangerousness}
                      type="checkbox"
                      color="primary"
                    />
                    <p>
                      Segundo a NR 16 do Ministério do Trabalho e do Emprego,
                      uma determinada atividade é considerada perigosa quando
                      coloca em risco a integridade física do funcionário. Quem
                      lida com produtos explosivos, produtos inflamáveis,
                      energia elétrica tem direito a receber o adicional de
                      periculosidade. Esse adicional é de 30% sobre o salário
                      pago. A CLT estabelece, no artigo 195, que haja laudo
                      pericial constatando o risco. Mas atenção: os adicionais
                      de insalubridade e de periculosidade não podem ser
                      recebidos juntos. Então, se as condições são perigosas e
                      insalubres, o empregado receberá somente o adicional de
                      maior valor.
                    </p>
                  </Box>
                </AdditionalContainer>
                <Box width={1} display="flex" justifyContent="flex-end" my={2}>
                  <Button
                    type="submit"
                    color="secondary"
                    disabled={isSubmitting}
                  >
                    ALTERAR DADOS
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </TabContainer>
    </TabPanel>
  );
};

HiringData.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  baseSalary: PropTypes.number.isRequired,
  setUser: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default HiringData;
