import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import {
  createMuiTheme,
  ThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

import { AuthProvider } from './auth';

import theme from '../styles/theme';

const appTheme = createMuiTheme(theme, ptBR);

const AppProvider = ({ children }) => (
  <AuthProvider>
    <ThemeProvider theme={appTheme}>
      <StyledThemeProvider theme={appTheme}>
        <StylesProvider injectFirst>{children}</StylesProvider>
      </StyledThemeProvider>
    </ThemeProvider>
  </AuthProvider>
);

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
