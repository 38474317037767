import {
  DialogContent,
  IconButton,
  Menu,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Delete from '../../../assets/deleteIcon.svg';
import Edit from '../../../assets/editIcon.svg';
import {
  DeleteModal,
  EmptyState,
  Grid,
  MassImportModal,
  Tooltip,
} from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { StyledMenuItem } from '../../Career/TableCareer/styles';
import AddDepartmentModal from '../AddDepartamentModal';
import {
  StyledIconButtonBlue,
  StyledIconButtonRed,
  StyledTableCell,
} from './styles';

const headCells = [
  {
    id: 'code',
    numeric: false,
    disablePadding: true,
    label: 'Código',
  },
  {
    id: 'departament',
    numeric: false,
    disablePadding: false,
    label: 'Departamento',
  },
  {
    id: 'totalEmployees',
    numeric: true,
    disablePadding: false,
    label: 'Total de colaboradores',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = useCallback(
    property => event => {
      onRequestSort(event, property);
    },
    [onRequestSort],
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Ação</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTable = ({ textFilter, newDepartment }) => {
  const [departaments, setDepartaments] = useState([]);
  const [departamentsCount, setDepartamentsCount] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDepartmentsModal, setOpenDepartmentsModal] = useState(false);
  const [departmentSelected, setDepartmentSelected] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isImportModalOpen, setImportModalOpen] = useState(false);

  const handleImportModalOpen = useCallback(() => {
    setAnchorEl(null);
    setImportModalOpen(true);
  }, []);
  const handleImportModalClose = useCallback(
    () => setImportModalOpen(false),
    [],
  );

  const handleClickMenu = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    const api = AuthenticatedHttpClient();

    async function getdepartments() {
      const { data } = await api.get(
        `department/paginate/${user.company._id}`,
        {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            order,
            orderBy,
            text: textFilter,
          },
        },
      );

      const responseData = data.docs.map(departmentInfo => ({
        _id: departmentInfo._id,
        code: departmentInfo.code,
        name: departmentInfo.name,
        totalEmployees: departmentInfo.totalEmployees,
      }));
      setDepartamentsCount(data.total);
      setDepartaments(responseData);
    }

    getdepartments();
  }, [
    user,
    order,
    orderBy,
    page,
    rowsPerPage,
    textFilter,
    newDepartment,
    isUpdated,
  ]);

  const handleRemoveDepartment = useCallback(
    async id => {
      try {
        const api = AuthenticatedHttpClient();

        await api.delete(`/department/${id}`);

        setDepartaments(prevState =>
          prevState.filter(departmentInfo => departmentInfo._id !== id),
        );
        setDepartamentsCount(prevState => prevState - 1);

        dispatch(
          setSnackbar(true, 'success', 'Operação realizada com sucesso'),
        );
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao remover departamento'));
      } finally {
        setOpenDeleteModal(false);
      }
    },
    [dispatch],
  );

  const handleClickRemove = useCallback(department => {
    setOpenDeleteModal(true);
    setDepartmentSelected(department);
  }, []);

  const handleClickEdit = useCallback(department => {
    setOpenDepartmentsModal(true);
    setDepartmentSelected(department);
  }, []);

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const MAX_STRING_LENGTH = 35;

  return (
    <>
      {!departamentsCount ? (
        <EmptyState description="Nenhum departamento encontrado!" />
      ) : (
        <>
          <Paper elevation={0}>
            <Grid container alignItems="center" justify="space-between">
              <Typography variant="h3">RELAÇÃO DE DEPARTAMENTOS</Typography>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClickMenu}
              >
                <Settings />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <StyledMenuItem onClick={handleImportModalOpen}>
                  IMPORTAR EM MASSA
                </StyledMenuItem>
              </Menu>
            </Grid>
            <Modal open={isImportModalOpen}>
              <MassImportModal
                handleClose={handleImportModalClose}
                route="/department/upload-many"
                importType="Departamento"
                modelLocation="https://siricascudo-files.s3.amazonaws.com/example-sheets/departamento.xlsx"
                onSuccess={() => setIsUpdated(state => !state)}
              />
            </Modal>
            <TableContainer>
              <Table size="small">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={departamentsCount}
                />
                <TableBody>
                  {departaments.map(row => (
                    <TableRow hover tabIndex={-1} key={row._id}>
                      <StyledTableCell component="th" scope="row">
                        {row.code}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Tooltip
                          fullText={row.name}
                          maxStringLength={MAX_STRING_LENGTH}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.totalEmployees}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        <StyledIconButtonBlue
                          onClick={() => handleClickEdit(row)}
                        >
                          <img src={Edit} alt="" />
                        </StyledIconButtonBlue>
                        <StyledIconButtonRed
                          onClick={() => handleClickRemove(row)}
                        >
                          <img src={Delete} alt="" />
                        </StyledIconButtonRed>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={departamentsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <Modal
            open={openDepartmentsModal}
            onClose={() => setOpenDepartmentsModal(false)}
            aria-labelledby="add-collaborator-title"
            aria-describedby="simple-modal-description"
          >
            <DialogContent>
              <AddDepartmentModal
                handleClickOnClose={() => setOpenDepartmentsModal(false)}
                handleAddDepartment={() => {}}
                isUpdate
                formInitialValues={{
                  ...departmentSelected,
                }}
                setIsUpdated={setIsUpdated}
              />
            </DialogContent>
          </Modal>
          <DeleteModal
            title="Remover Departamento"
            description={`Tem certeza que deseja remover o departamento ${departmentSelected.name}?`}
            modalStatus={openDeleteModal}
            handleClose={() => setOpenDeleteModal(false)}
            handleDelete={() => handleRemoveDepartment(departmentSelected._id)}
          />
        </>
      )}
    </>
  );
};

EnhancedTable.propTypes = {
  textFilter: PropTypes.string,
  newDepartment: PropTypes.string,
};

EnhancedTable.defaultProps = {
  textFilter: '',
  newDepartment: '',
};

export default EnhancedTable;
