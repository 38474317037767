import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { SearchInput, SelectFilter, TabPanel } from '../../../components';
import { REQUEST_CHANGES } from '../../../utils/enums';
import { TabContainer } from '../styles';
import TableRequest from '../TableRequests';

const reasonOptions = [
  {
    label: 'Motivo',
    value: ' ',
  },
  {
    label: 'Atestado',
    value: REQUEST_CHANGES.MEDICAL_CERTIFICATE,
  },
  {
    label: 'Ajuste de ponto',
    value: REQUEST_CHANGES.POINTS_ADJUST,
  },
  {
    label: 'Folga',
    value: REQUEST_CHANGES.REQUEST_DAY_OFF,
  },
];

const Requests = props => {
  const {
    value,
    index,
    setNotificationsCount,
    setForceGetCollaboratorsCompTime,
    ...other
  } = props;
  const [searchText, setSearchText] = useState('');
  const [reasonSelected, setReasonSelected] = useState(' ');

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <SearchInput value={searchText} handleInput={setSearchText} />
        <Box display="flex" flexDirection="column" my={2}>
          <SelectFilter
            selectOptions={reasonOptions}
            value={reasonSelected}
            handleChange={setReasonSelected}
          />
          <Box mt={2}>
            <TableRequest
              setNotificationsCount={setNotificationsCount}
              searchText={searchText}
              reason={reasonSelected}
              setForceGetCollaboratorsCompTime={
                setForceGetCollaboratorsCompTime
              }
            />
          </Box>
        </Box>
      </TabContainer>
    </TabPanel>
  );
};

Requests.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  setNotificationsCount: PropTypes.func.isRequired,
  setForceGetCollaboratorsCompTime: PropTypes.func.isRequired,
};

export default Requests;
