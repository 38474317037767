export const ROLES = {
  ADMINISTRATOR: 'ADMINISTRATOR',
  ADMINISTRATOR_COMPANY: 'ADMINISTRATOR_COMPANY',
  HUMAN_RESOURCES_MANAGMENT: 'HUMAN_RESOURCES_MANAGMENT',
  EMPLOYEE: 'EMPLOYEE',
  ADMISSION_PROCESS: 'ADMISSION_PROCESS',
  WAITING_CONTRACT_SIGN: 'WAITING_CONTRACT_SIGN',
};

export const STEPS = {
  HIRING_DATA: 'HIRING_DATA',
  PERSONAL_DATA: 'PERSONAL_DATA',
  FAMILY_DATA: 'FAMILY_DATA',
  DOCUMENTS: 'DOCUMENTS',
  ADDRESS: 'ADDRESS',
  BANK_DATA: 'BANK_DATA',
};

export const STEP_STATUS = {
  APPROVED: 'approved',
  UNDER_ANALYSIS: 'underAnalysis',
  NOT_FILLED: 'notFilled',
  DISAPPROVED: 'disapproved',
};

export const STATUS = {
  ADMISSION: 'ADMISSION',
  DISMISSAL: 'DISMISSAL',
  ACTIVE: 'ACTIVE',
};

export const STATUS_ACTIVE = {
  WORK_EXPERIENCE: 'WORK_EXPERIENCE',
  HIRED: 'HIRED',
  MEDICAL_CERTIFICATE: 'MEDICAL_CERTIFICATE',
  INSS_REMOVAL: 'INSS_REMOVAL',
  DAY_OFF: 'DAY_OFF',
  VACATION: 'VACATION',
  WEEKLY_REST: 'WEEKLY_REST',
  SUSPENSION: 'SUSPENSION',
  MATERNITY_LEAVE: 'MATERNITY_LEAVE',
  PATERNITY_LEAVE: 'PATERNITY_LEAVE',
  UNPAID_LEAVE: 'UNPAID_LEAVE',
};

export const STATUS_DISMISSAL = {
  FULFILLING_NOTICE: 'FULFILLING_NOTICE',
  START_OF_THE_PROCESS: 'START_OF_THE_PROCESS',
  DISMISSED: 'DISMISSED',
};

export const SITUATION_POINTS = {
  REGISTERED: 'REGISTERED',
  PENDING: 'PENDING',
  WAITING: 'WAITING',
  ENTRY_NOT_REGISTERED: 'ENTRY_NOT_REGISTERED',
};

export const REQUEST_CHANGES = {
  CHANGE_DAY_OFF: 'CHANGE_DAY_OFF',
  REQUEST_DAY_OFF: 'REQUEST_DAY_OFF',
  HOURS_COMPENSATION: 'HOURS_COMPENSATION',
  POINTS_ADJUST: 'POINTS_ADJUST',
  MEDICAL_CERTIFICATE: 'MEDICAL_CERTIFICATE',
  PARTIAL_MEDICAL_CERTIFICATE: 'PARTIAL_MEDICAL_CERTIFICATE',
};

export const REQUEST_STATUS = {
  APPROVED: 'APPROVED',
  IN_ANALYSIS: 'IN_ANALYSIS',
  DISAPPROVED: 'DISAPPROVED',
};

export const ALERT_TYPES = {
  PENDENT: 'PENDENT',
  FAULT: 'FAULT',
};

export const HOLIDAY_TYPES = {
  NATIONAL: 'NATIONAL',
  STATE: 'STATE',
  MUNICIPAL: 'MUNICIPAL',
};

export const HOLIDAY_MODELS = {
  PERIOD: 'PERIOD',
  DATE: 'DATE',
};

export const SCALE_TYPES = {
  '5x2': '5x2',
  '6x1': '6x1',
  '5x1': '5x1',
  CALL_CENTER_30: 'CALL_CENTER_30',
  CALL_CENTER_36: 'CALL_CENTER_36',
  '12/36': '12/36',
  '24/48': '24/48',
};

export const BREAK_PERIOD_TIMES = {
  AFTER_ENTRY: 'AFTER_ENTRY',
  BEFORE_MAIN_PAUSE: 'BEFORE_MAIN_PAUSE',
  AFTER_MAIN_PAUSE: 'CALL_CENTER_36',
  BEFORE_EXIT: 'BEFORE_EXIT',
};

export const POINT_REGISTER_TYPE = {
  ENTRY: 'ENTRY',
  EXIT: 'EXIT',
  MAIN_BREAK_ENTRY: 'MAIN_BREAK_ENTRY',
  MAIN_BREAK_EXIT: 'MAIN_BREAK_EXIT',
  BREAK_ENTRY: 'BREAK_ENTRY',
  BREAK_EXIT: 'BREAK_EXIT',
};

export const REGIME_TYPE = {
  PJ: 'PJ',
  CLT: 'CLT',
};

export const ACTIVITY_PERIOD = {
  ONE_DAY: 'ONE_DAY',
  MORE_THAN_ONE_DAY: 'MORE_THAN_ONE_DAY',
};

export const COMP_TIME_HISTORY_TYPES = {
  COMPENSATION_PAYMENT: 'COMPENSATION_PAYMENT',
  MONEY_PAYMENT: 'MONEY_PAYMENT',
  ADDED_HOURS: 'ADDED_HOURS',
  DEBTOR_HOURS: 'DEBTOR_HOURS',
  FAULT: 'FAULT',
  FAULT_JUSTIFIED: 'FAULT_JUSTIFIED',
};
