import {
  Box,
  ButtonBase,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { RadioGroup, TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Grid, TabPanel, Typography } from '../../../../components';
import { SCALE_TYPES } from '../../../../utils/enums';
import journeyScaleSchema from '../../../../validations/journeyScale';
import { StandardDayScale, StandardWeekScale } from '../ScaleComponents';
import { ContainerInput } from '../styles';

const Scale = props => {
  const {
    value,
    index,
    handleSaveScale,
    handleBack,
    initialValues,
    isUpdate,
  } = props;
  const [opacity, setOpacity] = useState(0.5);

  const scaleTypeOptions = [
    {
      label: 'Padrão Semanal - 5x2',
      value: SCALE_TYPES['5x2'],
    },
    {
      label: 'Padrão Semanal - 6x1',
      value: SCALE_TYPES['6x1'],
    },
    {
      label: 'Escala - 12/36',
      value: SCALE_TYPES['12/36'],
    },
    {
      label: 'Escala - 24/48',
      value: SCALE_TYPES['24/48'],
    },
    { label: 'Escala - 5x1', value: SCALE_TYPES['5x1'] },
    {
      label: 'Call Center - 36 horas',
      value: SCALE_TYPES.CALL_CENTER_36,
    },
    {
      label: 'Call Center - 30 horas',
      value: SCALE_TYPES.CALL_CENTER_30,
    },
  ];

  const timeOptions = [
    {
      label: '120 min',
      value: '120',
    },
    {
      label: '90 min',
      value: '90',
    },
    {
      label: '60 min',
      value: '60',
    },
    {
      label: '30 min',
      value: '30',
    },
  ];

  const handleOpacity = e => {
    if (e.target.value === 'true') {
      setOpacity(0.5);
    } else {
      setOpacity(1);
    }
  };

  return (
    <TabPanel value={value} index={index}>
      <Box p={3}>
        <Formik
          initialValues={initialValues}
          validationSchema={journeyScaleSchema}
          onSubmit={handleSaveScale}
          enableReinitialize
        >
          {({
            values,
            submitForm,
            setFieldValue,
            touched,
            errors,
            isSubmitting,
          }) => (
            <Form>
              <Grid container justify="space-between" spacing={2}>
                <Grid item xs={12}>
                  <Typography color="secondary" fontSize="0.875rem">
                    Tipo de escala
                  </Typography>
                </Grid>
                <Grid item container spacing={3} xs={6}>
                  <ContainerInput item xs={6}>
                    <Field
                      component={TextField}
                      select
                      name="scaleType"
                      variant="filled"
                      fullWidth
                      label="Escala"
                    >
                      {scaleTypeOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </ContainerInput>
                  <ContainerInput item xs={6}>
                    <FormControl error={touched.holidays && !!errors.holidays}>
                      <FormLabel>
                        <Typography color="primary" fontSize="14px">
                          A jornada se aplica para feriados?
                        </Typography>
                      </FormLabel>
                      <Field component={RadioGroup} name="holidays" row>
                        <FormControlLabel
                          value="true"
                          control={
                            <Radio
                              color="primary"
                              disabled={
                                isSubmitting ||
                                values.scaleType === SCALE_TYPES['12/36'] ||
                                values.scaleType === SCALE_TYPES['24/48']
                              }
                            />
                          }
                          label="SIM"
                          disabled={
                            isSubmitting ||
                            values.scaleType === SCALE_TYPES['12/36'] ||
                            values.scaleType === SCALE_TYPES['24/48']
                          }
                        />
                        <FormControlLabel
                          value="false"
                          control={
                            <Radio
                              color="primary"
                              disabled={
                                isSubmitting ||
                                values.scaleType === SCALE_TYPES['12/36'] ||
                                values.scaleType === SCALE_TYPES['24/48']
                              }
                            />
                          }
                          label="NÃO"
                          disabled={
                            isSubmitting ||
                            values.scaleType === SCALE_TYPES['12/36'] ||
                            values.scaleType === SCALE_TYPES['24/48']
                          }
                        />
                      </Field>
                      <FormHelperText>
                        {touched.holidays && errors.holidays}
                      </FormHelperText>
                    </FormControl>
                  </ContainerInput>
                </Grid>
              </Grid>
              {(values.scaleType === SCALE_TYPES['5x2'] ||
                values.scaleType === SCALE_TYPES['6x1'] ||
                values.scaleType === SCALE_TYPES.CALL_CENTER_30 ||
                values.scaleType === SCALE_TYPES.CALL_CENTER_36) && (
                <StandardWeekScale
                  opacity={opacity}
                  values={values}
                  handleOpacity={handleOpacity}
                  timeOptions={timeOptions}
                  setFieldValue={setFieldValue}
                />
              )}
              {(values.scaleType === SCALE_TYPES['12/36'] ||
                values.scaleType === SCALE_TYPES['5x1'] ||
                values.scaleType === SCALE_TYPES['24/48']) && (
                <StandardDayScale
                  timeOptions={timeOptions}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              )}

              <Box mt={16}>
                {isUpdate ? (
                  <Grid container spacing={3} justify="flex-end">
                    <Grid item>
                      <ButtonBase onClick={submitForm}>
                        <Typography
                          fontSize="14px"
                          fontWeight="bold"
                          color="secondary"
                        >
                          ALTERAR DADOS
                        </Typography>
                      </ButtonBase>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={3} justify="flex-end">
                    <Grid item>
                      <ButtonBase onClick={handleBack}>
                        <Typography
                          fontSize="14px"
                          fontWeight="bold"
                          color="secondary"
                          opacity={0.5}
                        >
                          VOLTAR
                        </Typography>
                      </ButtonBase>
                    </Grid>
                    <Grid item>
                      <ButtonBase onClick={submitForm}>
                        <Typography
                          fontSize="14px"
                          fontWeight="bold"
                          color="secondary"
                        >
                          PROXIMA ETAPA
                        </Typography>
                      </ButtonBase>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </TabPanel>
  );
};

Scale.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  handleSaveScale: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  isUpdate: PropTypes.bool,
};

Scale.defaultProps = {
  isUpdate: false,
};

export default Scale;
