import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  position: absolute;
  background: #fff;
  border-radius: 6px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ModalTitleBar = styled.div`
  border-bottom: 1px solid #e5e5e5;
  height: 60px;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    color: ${({ theme }) => theme.palette.secondary.main};
    font-size: 16px;
    display: inline;
  }
`;
