import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const ContainerModal = styled.div`
  padding: 40px;
`;

export const StyledGrid = styled(Grid)`
  height: 80px;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : 0)};
`;

export const ActionsBar = styled(Grid)`
  margin-top: 16px;

  button {
    padding: 0 20px;
    font-weight: 700;
    font-size: 14px;
  }
  button:nth-child(1) {
    opacity: 0.5;
  }
`;
