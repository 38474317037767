import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { AuthenticatedHttpClient } from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { setLoading } from '../../../../redux/ducks/loading';

import { Grid, Typography, CollaboratorButton } from '../../../../components';
import withCollaboratorSkeleton from '../../../../components/CollaboratorSkeleton';
import { StyledTextField } from './styles';

const StepEmailConfirm = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const history = useHistory();

  const formInitialValues = {
    email: '',
  };

  const validateSchema = Yup.object().shape({
    email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  });

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();
        await api.put(`/invite/email-confirm/${user.invite._id}`, values);

        dispatch(setSnackbar(true, 'success', 'E-mail verificado'));

        history.push('/create-password');
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'E-mail inválido'));
      } finally {
        setSubmitting(false);
        dispatch(setLoading(false));
      }
    },
    [user, dispatch, history],
  );

  return (
    <Box
      component={Grid}
      container
      direction="column"
      justify="center"
      color="white"
      height="100%"
      px={10}
    >
      <Typography fontWeight="300" fontSize="20px">
        Confirme seu e-mail
      </Typography>
      <Formik
        initialValues={formInitialValues}
        validationSchema={validateSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              component={StyledTextField}
              name="email"
              type="text"
              label="E-mail"
              fullWidth
              variant="filled"
              color="primary"
              mt="8px"
            />
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <CollaboratorButton type="submit" disabled={isSubmitting} />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default withCollaboratorSkeleton(StepEmailConfirm);
