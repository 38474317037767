import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes, { string } from 'prop-types';
import React from 'react';
import DownloadImage from '../../assets/download.svg';
import JpgFileImage from '../../assets/file-jpg.svg';
import getFileUrlFor from '../../utils/getFIleUrlFor';
import { StyledContainer, StyledSpan } from './styles';

const Downloader = ({ file, remove, removeFn }) => {
  const { name, size, key, image, location } = file;

  const downloadUrl = location || getFileUrlFor(key);

  return (
    <StyledContainer>
      <img src={image || JpgFileImage} alt="" />
      <Box
        display="flex"
        overflow="hidden"
        width={1}
        ml={1}
        flexDirection="column"
        alignItems="flex-start"
      >
        <Box
          fontSize={10}
          fontWeight={700}
          textOverflow="ellipsis"
          overflow="hidden"
          width={1}
          maxWidth="90%"
        >
          {name}
        </Box>
        <StyledSpan fontSize={8} fontWeight={300}>
          {size}
        </StyledSpan>
      </Box>
      <IconButton size="small" download href={downloadUrl}>
        <img src={DownloadImage} alt="" />
      </IconButton>
      {remove && (
        <IconButton color="inherit" size="small" onClick={removeFn}>
          <CloseIcon />
        </IconButton>
      )}
    </StyledContainer>
  );
};

Downloader.propTypes = {
  file: PropTypes.shape({
    name: string,
    size: string,
    key: string,
    image: string,
    location: string,
  }).isRequired,
  remove: PropTypes.bool,
  removeFn: PropTypes.func,
};

Downloader.defaultProps = {
  remove: false,
  removeFn: () => {},
};

export default Downloader;
