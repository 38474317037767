import * as Yup from 'yup';
import parseDate from '../utils/parseDateString';

const hiringDataCLTSchema = Yup.object().shape({
  salary: Yup.number().required('Campo obrigatório'),
  vt: Yup.number().required('Campo obrigatório'),
  va: Yup.number().required('Campo obrigatório'),
  vr: Yup.number().required('Campo obrigatório'),
  hasStandardSalary: Yup.boolean().required('Campo obrigatório'),
  workday: Yup.string().required('Campo obrigatório'),
  experiencePeriod: Yup.string().required('Campo obrigatório'),
  beginningOfFirstPeriod: Yup.date()
    .transform(parseDate)
    .typeError('Data inválida')
    .required('Campo obrigatório'),
  endOfFirstPeriod: Yup.date()
    .transform(parseDate)
    .typeError('Data inválida')
    .required('Campo obrigatório'),
  startOfSecondPeriod: Yup.date()
    .transform(parseDate)
    .typeError('Data inválida')
    .required('Campo obrigatório'),
  endOfSecondPeriod: Yup.date()
    .transform(parseDate)
    .typeError('Data inválida')
    .required('Campo obrigatório'),
  additional: Yup.object().shape({
    warning: Yup.boolean(),
    unhealthy: Yup.boolean(),
    unhealthyPercentage: Yup.number().when('unhealthy', {
      is: true,
      then: Yup.number()
        .required('Campo obrigatório')
        .min(10, 'Minímo 10%')
        .max(40, 'Máximo 40%'),
    }),
    risk: Yup.boolean(),
    dangerousness: Yup.boolean(),
  }),
});

export default hiringDataCLTSchema;
