import React, { useState, useEffect } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';

import { AuthenticatedHttpClient } from '../../services/api';
import { useAuth } from '../../hooks/auth';

import { SearchInput, SelectFilter } from '../../components';
import TableDismissal from './TableDismissal';

import {
  Container,
  StyledTabs,
  StyledTab,
  AdmissionContainer,
  SelectDiv,
} from './styles';

const statusOptions = [
  {
    label: 'STATUS',
    value: ' ',
  },
  {
    label: 'Cumprindo aviso',
    value: 'FULFILLING_NOTICE',
  },
  {
    label: 'Início do processo',
    value: 'START_OF_THE_PROCESS',
  },
  {
    label: 'Desligado',
    value: 'DISMISSED',
  },
];

const Dismissal = () => {
  const [jobRoleOptions, setJobRoleOptions] = useState(() => [
    { label: 'CARGO', value: ' ' },
  ]);
  const [departamentOptions, setDepartamentOptions] = useState(() => [
    { label: 'DEPARTAMENTO', value: ' ' },
  ]);
  const [searchText, setSearchText] = useState('');
  const [jobRole, setJobRole] = useState(' ');
  const [department, setDepartment] = useState(' ');
  const [status, setStatus] = useState(' ');

  const { user } = useAuth();

  useEffect(() => {
    const api = AuthenticatedHttpClient();

    async function getOptions() {
      const [jobRolesResponse, departmentsResponse] = await Promise.all([
        await api.get(`job-role/${user.company._id}`),
        await api.get(`department/${user.company._id}`),
      ]);
      const { data: dataJobRoles } = jobRolesResponse;
      const { data: dataDepartments } = departmentsResponse;

      const optionsJobRoles = dataJobRoles.map(jobRoleInfo => ({
        label: jobRoleInfo.name,
        value: jobRoleInfo._id,
      }));

      const optionsDepartments = dataDepartments.map(departmentIndo => ({
        label: departmentIndo.name,
        value: departmentIndo._id,
      }));

      setJobRoleOptions(oldState => [...oldState, ...optionsJobRoles]);
      setDepartamentOptions(oldState => [...oldState, ...optionsDepartments]);
    }
    getOptions();
  }, [user]);

  return (
    <Container>
      <Typography variant="h2">Demissão</Typography>
      <Paper elevation={1}>
        <StyledTabs value={1} indicatorColor="primary">
          <StyledTab label="COLABORADORES" value={1} />
        </StyledTabs>
        <AdmissionContainer>
          <Grid container alignItems="center" justify="space-between">
            <SearchInput value={searchText} handleInput={setSearchText} />
          </Grid>
          <SelectDiv>
            <SelectFilter
              selectOptions={jobRoleOptions}
              value={jobRole}
              handleChange={setJobRole}
            />
            <SelectFilter
              selectOptions={departamentOptions}
              value={department}
              handleChange={setDepartment}
            />
            <SelectFilter
              selectOptions={statusOptions}
              value={status}
              handleChange={setStatus}
            />
          </SelectDiv>
          <TableDismissal
            textFilter={searchText}
            jobRoleFilter={jobRole}
            departmentFilter={department}
            statusFilter={status}
          />
        </AdmissionContainer>
      </Paper>
    </Container>
  );
};

export default Dismissal;
