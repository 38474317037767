import { Box } from '@material-ui/core';
import {
  addMinutes,
  differenceInCalendarDays,
  format,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
  subMinutes,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Alert from '../../../assets/alert-black.svg';
import Approved from '../../../assets/approved-password.svg';
import {
  EmptyState,
  Grid,
  TabPanel,
  Typography,
} from '../../../components/index';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import {
  ALERT_TYPES,
  POINT_REGISTER_TYPE,
  SITUATION_POINTS,
} from '../../../utils/enums';
import formatTimeString from '../../../utils/formatTimeString';
import getSetDate from '../../../utils/getSetDate';
import AlertCard from '../Components/AlertCard';
// import NotifyCard from '../Components/NotifyCard';
import RegisterPointCard from '../Components/RegisterPointCard';
import { CardContainer, RowImageContainer, TabContainer } from '../styles';

const pointTypeLabels = {
  [POINT_REGISTER_TYPE.BREAK_ENTRY]: 'Ida',
  [POINT_REGISTER_TYPE.BREAK_EXIT]: 'Volta',
  [POINT_REGISTER_TYPE.ENTRY]: 'Entrada',
  [POINT_REGISTER_TYPE.EXIT]: 'Saída',
  [POINT_REGISTER_TYPE.MAIN_BREAK_ENTRY]: 'Intervalo principal - Ida',
  [POINT_REGISTER_TYPE.MAIN_BREAK_EXIT]: 'Intervalo principal - Volta',
};

const Records = props => {
  const { value, index, ...other } = props;
  const [alerts, setAlerts] = useState([]);
  // const [notifications, setNotifications] = useState([]);
  const [points, setPoints] = useState([]);
  const [newPointRegistered, setNewPointRegistered] = useState(false);
  const [bankOfHoursValue, setBankOfHoursValue] = useState('NEGATIVE');
  const [userTotalCompTime, setUserTotalCompTime] = useState([]);
  const { userId: collaboratorId } = useParams();

  const bankOfHoursPositive = [
    {
      cardColor: '#05202A',
      textColor: '#FFFFFF',
      icon: Approved,
      text: 'Banco de Horas',
      hours: formatTimeString(userTotalCompTime),
    },
  ];

  const bankOfHoursNegative = [
    {
      cardColor: '#FF6B6B',
      textColor: '#FFFFFF',
      icon: Alert,
      text: 'Horas a ser compensadas',
      hours: formatTimeString(userTotalCompTime),
    },
  ];

  const typeMapBankOfHours = {
    POSITIVE: bankOfHoursPositive,
    NEGATIVE: bankOfHoursNegative,
  };

  useEffect(() => {
    const api = AuthenticatedHttpClient();
    async function getUserAlerts() {
      const { data: pointAlerts } = await api.get('/point-alert');

      const formattedAlerts = pointAlerts.map(alert => {
        const { pointStartDate, breakName, alertType, pointType } = alert;
        const parsedDate = parseISO(pointStartDate);

        let day = '';

        if (isSameDay(parsedDate, new Date())) {
          day = 'Hoje';
        } else if (differenceInCalendarDays(parsedDate, new Date()) === 1) {
          day = 'Ontem';
        } else {
          const weekday = format(parsedDate, 'iiii', {
            locale: ptBR,
          });
          day = `${weekday[0].toUpperCase()}${weekday.slice(1)}`;
        }

        let description = 'Nenhum registro de ponto';

        if (alertType === ALERT_TYPES.PENDENT && breakName) {
          description = `${breakName} - ${pointTypeLabels[pointType]}`;
        } else if (alertType === ALERT_TYPES.PENDENT && !breakName) {
          description = pointTypeLabels[pointType];
        }

        return {
          _id: alert._id,
          day,
          date: format(parsedDate, 'dd/MM/yyyy'),
          description,
          type: alert.alertType,
        };
      });
      setAlerts(formattedAlerts);
    }

    getUserAlerts();
    const getUserAlertsInterval = setInterval(getUserAlerts, 60000);

    return () => clearInterval(getUserAlertsInterval);
  }, [newPointRegistered]);

  useEffect(() => {
    // setNotifications([
    //   {
    //     id: 1,
    //     fullName: 'Beatriz Ribeiro Costa',
    //     job: 'Atendente de telemarketing',
    //     description: 'Solicitou uma troca de folga no dia 01/08/2020',
    //     dayOffDate: '01/08/2020',
    //     workDate: '08/08/2020',
    //   },
    // ]);
  }, []);

  const dispatch = useDispatch();

  async function getTotalCompTime() {
    const api = AuthenticatedHttpClient();

    try {
      dispatch(setLoading(true));
      const { data } = await api.get(`/comp-time/${collaboratorId}`);
      setUserTotalCompTime(data);
      const bankOfHoursBooleanValue = data >= 0 ? 'POSITIVE' : 'NEGATIVE';
      setBankOfHoursValue(bankOfHoursBooleanValue);
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Falha ao buscar banco de horas'));
    } finally {
      dispatch(setLoading(false));
    }
  }

  useEffect(() => {
    getTotalCompTime();
  });

  const entryPointDay = () => {
    const entryPoint = points.find(point => {
      return point.type === POINT_REGISTER_TYPE.ENTRY;
    });
    if (entryPoint) {
      return entryPoint.expectedTime;
    }
    return new Date();
  };

  const formatPoints = useCallback(collaboratorPoints => {
    const exitPoint = collaboratorPoints.find(point => {
      return point.type === POINT_REGISTER_TYPE.EXIT;
    });
    const entryPoint = collaboratorPoints.find(point => {
      return point.type === POINT_REGISTER_TYPE.ENTRY;
    });

    const mainBreakEnterPoint = collaboratorPoints.find(point => {
      return point.type === POINT_REGISTER_TYPE.MAIN_BREAK_ENTRY;
    });

    const formattedPoints = collaboratorPoints.map((point, pointIndex) => {
      const isBreak =
        point.type === POINT_REGISTER_TYPE.BREAK_ENTRY ||
        point.type === POINT_REGISTER_TYPE.BREAK_EXIT;
      const title = isBreak
        ? `${point.breakName} - ${pointTypeLabels[point.type]}`
        : `${pointTypeLabels[point.type]}`;

      const isMainBreak =
        point.type === POINT_REGISTER_TYPE.MAIN_BREAK_ENTRY ||
        point.type === POINT_REGISTER_TYPE.MAIN_BREAK_EXIT;

      let situation = SITUATION_POINTS.REGISTERED;
      let { expectedTime } = point;
      if (!point.register) {
        if (isMainBreak) {
          const isAfterExitMinusMainBreakDuration = isAfter(
            getSetDate(),
            subMinutes(
              new Date(exitPoint.expectedTime),
              Number(point.duration),
            ),
          );
          const isBeforeEnter = isBefore(
            getSetDate(),
            new Date(entryPoint.expectedTime),
          );

          situation = isAfterExitMinusMainBreakDuration
            ? SITUATION_POINTS.PENDING
            : SITUATION_POINTS.WAITING;

          expectedTime = getSetDate();

          if (isBeforeEnter) {
            expectedTime = entryPoint.expectedTime;
          }
          if (isAfterExitMinusMainBreakDuration) {
            expectedTime = subMinutes(
              new Date(exitPoint.expectedTime),
              Number(point.duration),
            );
          }
          if (
            point.type === POINT_REGISTER_TYPE.MAIN_BREAK_EXIT &&
            mainBreakEnterPoint.register
          ) {
            expectedTime = addMinutes(
              new Date(mainBreakEnterPoint.register.registerDate),
              point.duration,
            );

            const isAfterExit = isAfter(
              expectedTime,
              subMinutes(new Date(exitPoint.expectedTime), 1),
            );

            if (isAfterExit) {
              expectedTime = subMinutes(new Date(exitPoint.expectedTime), 5);
            }

            const isMainBreakExitAfter = isAfter(
              addMinutes(expectedTime, 5),
              getSetDate(),
            );

            situation = isMainBreakExitAfter
              ? SITUATION_POINTS.WAITING
              : SITUATION_POINTS.PENDING;
          }
        } else {
          const pointDate = new Date(point.expectedTime);
          let limitTime = 5;
          if (point.type === POINT_REGISTER_TYPE.EXIT) {
            limitTime = 120;
          }
          const isPointAfter = isAfter(
            addMinutes(pointDate, limitTime),
            getSetDate(),
          );

          situation = isPointAfter
            ? SITUATION_POINTS.WAITING
            : SITUATION_POINTS.PENDING;
        }
      } else if (isMainBreak) {
        expectedTime = point.register.registerDate;
      }

      return {
        ...point,
        id: pointIndex,
        title,
        situation,
        expectedTime,
      };
    });

    return formattedPoints;
  }, []);

  useEffect(() => {
    const api = AuthenticatedHttpClient();

    const getUserPoints = async () => {
      const { data: userPoints } = await api.get('/point-registers', {
        params: {
          date: zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
        },
      });

      const formattedPoints = formatPoints(userPoints);

      setPoints(formattedPoints);
    };

    getUserPoints();
    const getUserPointsInterval = setInterval(getUserPoints, 60000);

    return () => clearInterval(getUserPointsInterval);
  }, [formatPoints, newPointRegistered]);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Box p={3} mt={1}>
          <Grid container spacing={3}>
            <Grid item lg={12} xl={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {typeMapBankOfHours[bankOfHoursValue].map(element => {
                    return (
                      <CardContainer
                        key={element}
                        color={element.cardColor}
                        container
                        spacing={1}
                      >
                        <Grid item xs={12}>
                          <RowImageContainer>
                            <img src={element.icon} alt="icon_bankOfHours" />
                            <Typography
                              fontSize="14px"
                              textcolor={element.textColor}
                              ml="5px"
                            >
                              {element.text}
                            </Typography>
                          </RowImageContainer>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            fontWeight={700}
                            fontSize="22px"
                            textcolor={element.textColor}
                          >
                            {element.hours}
                          </Typography>
                        </Grid>
                      </CardContainer>
                    );
                  })}
                </Grid>
                <Grid item xs={12}>
                  <CardContainer
                    color="#FFFFFF"
                    container
                    spacing={1}
                    $hasBoxShadow
                    $hasVariableHeight
                  >
                    <Grid item xs={12}>
                      <RowImageContainer>
                        <img src={Alert} alt="Alert" />
                        <Typography
                          fontSize="14px"
                          textcolor="#05202A"
                          ml="5px"
                        >
                          Alertas e notificações
                        </Typography>
                      </RowImageContainer>
                    </Grid>
                    <Grid item xs={12}>
                      {alerts.length ? (
                        <AlertCard alerts={alerts} />
                      ) : (
                        <EmptyState description="Nenhum alerta encontrado!" />
                      )}
                    </Grid>
                    {/* <Grid item xs={12}>
                      <NotifyCard notifications={notifications} />
                    </Grid> */}
                  </CardContainer>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} xl={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <CardContainer
                    color="#FFFFFF"
                    container
                    spacing={1}
                    $hasBoxShadow
                    $hasVariableHeight
                    padding="20px"
                  >
                    <Grid item xs={12}>
                      <Typography
                        fontWeight={700}
                        fontSize="16px"
                        textcolor="#05202A"
                      >
                        Registro de ponto
                      </Typography>
                    </Grid>
                    {points.length ? (
                      <>
                        <Grid item xs={12}>
                          <Typography
                            fontSize="12px"
                            textcolor="#05202A"
                            opacity={0.5}
                          >
                            Entrada -{' '}
                            {format(new Date(entryPointDay()), 'dd/MM/yyyy')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <RegisterPointCard
                            setNewPointRegistered={setNewPointRegistered}
                            points={points}
                          />
                        </Grid>{' '}
                      </>
                    ) : (
                      <EmptyState description="Nenhum registro de ponto encontrado!" />
                    )}
                  </CardContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </TabContainer>
    </TabPanel>
  );
};

Records.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default Records;
