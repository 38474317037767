import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const DependentsContainer = styled(Box)`
  font-weight: 300;
  width: 100%;
  background-color: transparent;
  border: solid 1px #e6e6e6;
  border-radius: 5px;
  color: ${({ theme }) => theme.palette.secondary.dark};
`;
