import { Button, Grid, MenuItem } from '@material-ui/core';
import { startOfDay } from 'date-fns';
import { formatISO } from 'date-fns/esm';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalSkeleton } from '../../../../components';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import { REQUEST_CHANGES } from '../../../../utils/enums';
import toFormData from '../../../../utils/formData';
import getMinutesFromStringTime from '../../../../utils/getMinutesFromStringTime';
import requestChangeModalSchema from '../../../../validations/requestChangeModal';
import ChangeDayOff from '../../Components/ChangeDayOff';
import HoursCompensation from '../../Components/HoursCompensation';
import RequestDayOff from '../../Components/RequestDayOff';
import { ActionsBar, ContainerModal, StyledGrid } from '../styles';

const RequestChangeModal = ({ handleClickOnClose }) => {
  const [attachment, setAttachment] = useState(null);

  const dispatch = useDispatch();

  const reasonOptions = [
    // {
    //   label: 'Trocar Folga',
    //   value: REQUEST_CHANGES.CHANGE_DAY_OFF,
    // },
    {
      label: 'Pedir Folga',
      value: REQUEST_CHANGES.REQUEST_DAY_OFF,
    },
    {
      label: 'Compensação de horas',
      value: REQUEST_CHANGES.HOURS_COMPENSATION,
    },
  ];

  const formInitialValues = {
    reason: '',
    changeDayOff: {
      dayOffDate: null,
      workDate: null,
      description: '',
    },
    requestDayOff: {
      date: null,
      justification: '',
    },
    hoursCompensation: {
      date: null,
      justification: '',
      dayHoursCompensated: '',
    },
  };

  const handleSubmitForm = useCallback(
    async values => {
      try {
        dispatch(setLoading(true));

        const api = AuthenticatedHttpClient();

        const {
          reason,
          requestDayOff,
          // changeDayOff,
          hoursCompensation,
        } = values;

        const routeParams = {
          [REQUEST_CHANGES.REQUEST_DAY_OFF]: 'day-off',
          [REQUEST_CHANGES.CHANGE_DAY_OFF]: 'to-do',
          [REQUEST_CHANGES.HOURS_COMPENSATION]: 'hours-compensation',
        };

        const requestBody = {};

        let solicitation;

        if (reason === REQUEST_CHANGES.REQUEST_DAY_OFF) {
          if (attachment) {
            Object.assign(requestBody, {
              reason,
              justification: requestDayOff.justification,
              solicitationDate: formatISO(requestDayOff.date),
              attachment,
            });
            const formData = toFormData(requestBody);

            solicitation = await api.post(
              `point-solicitation/${routeParams[reason]}`,
              formData,
            );
          } else {
            Object.assign(requestBody, {
              reason,
              justification: requestDayOff.justification,
              solicitationDate: formatISO(requestDayOff.date),
            });

            solicitation = await api.post(
              `point-solicitation/${routeParams[reason]}`,
              requestBody,
            );
          }
        }

        if (reason === REQUEST_CHANGES.HOURS_COMPENSATION) {
          Object.assign(requestBody, {
            justification: hoursCompensation.justification,
            compensationDate: startOfDay(hoursCompensation.date),
            totalMinutesCompensated: getMinutesFromStringTime(
              hoursCompensation.dayHoursCompensated,
            ),
          });
          solicitation = await api.post(
            `point-solicitation/${routeParams[reason]}`,
            requestBody,
          );
        }
        // TO-DO: Solicitações de compensação de horas e troca de folga

        handleClickOnClose(solicitation.data._id);
      } catch (error) {
        if (error.response?.data?.message) {
          dispatch(setSnackbar(true, 'error', error.response.data.message));
        } else {
          dispatch(
            setSnackbar(
              true,
              'error',
              'Não foi possível enviar a solicitação.',
            ),
          );
        }
        console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    },
    [attachment, dispatch, handleClickOnClose],
  );

  return (
    <ModalSkeleton
      title="SOLICITAR"
      handleClickOnClose={handleClickOnClose}
      maxWidth="900px"
    >
      <ContainerModal>
        <Formik
          initialValues={formInitialValues}
          onSubmit={handleSubmitForm}
          validationSchema={requestChangeModalSchema}
        >
          {({ values, isSubmitting }) => {
            return (
              <Form>
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  spacing={2}
                >
                  <StyledGrid item lg={6}>
                    <Field
                      component={TextField}
                      select
                      name="reason"
                      label="Motivo"
                      variant="filled"
                      fullWidth
                    >
                      {reasonOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </StyledGrid>
                  {values.reason === REQUEST_CHANGES.CHANGE_DAY_OFF && (
                    <ChangeDayOff />
                  )}
                  {values.reason === REQUEST_CHANGES.HOURS_COMPENSATION && (
                    <HoursCompensation />
                  )}
                  {values.reason === REQUEST_CHANGES.REQUEST_DAY_OFF && (
                    <RequestDayOff setRequestDayOffFile={setAttachment} />
                  )}
                </Grid>
                <ActionsBar container alignItems="center" justify="flex-end">
                  <StyledGrid mt="32px">
                    <Button
                      disabled={isSubmitting}
                      type="buton"
                      color="secondary"
                      onClick={handleClickOnClose}
                      size="small"
                    >
                      CANCELAR
                    </Button>
                    <Button
                      color="secondary"
                      disabled={isSubmitting}
                      type="submit"
                      size="small"
                    >
                      SOLICITAR
                    </Button>
                  </StyledGrid>
                </ActionsBar>
              </Form>
            );
          }}
        </Formik>
      </ContainerModal>
    </ModalSkeleton>
  );
};

RequestChangeModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
};

RequestChangeModal.defaultProps = {};

export default RequestChangeModal;
