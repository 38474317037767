import { getToken } from '../services/auth';

export default function getFileUrlFor(key) {
  const token = getToken();
  const {
    REACT_APP_DOCUMENT_RETRIEVER_URL: documentRetrieverURL,
    REACT_APP_APPLICATION_ID: applicationId,
  } = process.env;
  const params = new URLSearchParams();
  params.append('path', key);
  params.append('token', token);
  params.append('applicationId', applicationId);

  const url = `${documentRetrieverURL}/file?${params.toString()}`;

  return url;
}
