import * as Yup from 'yup';
import { ALERT_TYPES, REQUEST_CHANGES } from '../utils/enums';
import parseDate from '../utils/parseDateString';

const alertModalSchema = Yup.object().shape({
  date: Yup.string().required('Campo obrigatório'),
  type: Yup.string().required('Campo obrigatório'),
  alertDescription: Yup.string().required('Campo obrigatório'),
  reason: Yup.string().required('Campo obrigatório'),
  justification: Yup.string().required('Campo obrigatório'),
  point: Yup.string().when('type', {
    is: ALERT_TYPES.PENDENT,
    then: Yup.string().required('Campo obrigatório'),
    otherwise: Yup.string().notRequired(),
  }),
  registerTime: Yup.date().when(['reason', 'type'], {
    is: (reason, type) =>
      reason === REQUEST_CHANGES.POINTS_ADJUST && type === ALERT_TYPES.PENDENT,
    then: Yup.date()
      .transform(parseDate)
      .typeError('Horário Invalido')
      .required('Campo obrigatório'),
    otherwise: Yup.date().notRequired().nullable(),
  }),

  partialMedicalCertificateStartHour: Yup.date().when('reason', {
    is: REQUEST_CHANGES.PARTIAL_MEDICAL_CERTIFICATE,
    then: Yup.date()
      .transform(parseDate)
      .typeError('Horário Invalido')
      .required('Campo obrigatório'),
    otherwise: Yup.date().notRequired().nullable(),
  }),
  partialMedicalCertificateEndHour: Yup.date().when('reason', {
    is: REQUEST_CHANGES.PARTIAL_MEDICAL_CERTIFICATE,
    then: Yup.date()
      .transform(parseDate)
      .typeError('Horário Invalido')
      .required('Campo obrigatório'),
    otherwise: Yup.date().notRequired().nullable(),
  }),
});

export default alertModalSchema;
