import { Box, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { EmptyState, Grid, TabPanel, Typography } from '../../../components';
import { JourneyData } from '../styles';

const JourneyDetails = props => {
  const { value, index, journeyDetails } = props;

  return (
    <TabPanel value={value} index={index}>
      {Object.keys(journeyDetails).length === 0 &&
      journeyDetails.constructor === Object ? (
        <EmptyState description="Não há detalhes para essa jornada" />
      ) : (
        <Box p={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="secondary" fontSize="0.875rem">
                Dados da jornada
              </Typography>
            </Grid>
            <JourneyData container xs={12}>
              <Grid container alignItems="center" ml="8px" xs={12}>
                <Grid xs={4}>
                  <Typography
                    color="secondary"
                    opacity={0.5}
                    fontSize="0.875rem"
                  >
                    Nome da Jornada
                  </Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    color="secondary"
                    opacity={0.5}
                    fontSize="0.875rem"
                  >
                    Jornada se aplica para feriados?
                  </Typography>
                </Grid>
              </Grid>
              <Grid container ml="8px" alignItems="center" xs={12}>
                <Grid xs={4}>
                  <Typography
                    color="secondary"
                    fontWeight="bold"
                    opacity={0.5}
                    fontSize="1.25rem"
                  >
                    {journeyDetails.name}
                  </Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    color="secondary"
                    fontWeight="bold"
                    opacity={0.5}
                    fontSize="1.25rem"
                  >
                    {journeyDetails.holidays ? 'SIM' : 'NÃO'}
                  </Typography>
                </Grid>
              </Grid>
            </JourneyData>
          </Grid>
          <Grid container mt="40px">
            <Grid item xs={12}>
              <Typography color="secondary" fontSize="0.875rem">
                Atrasos
              </Typography>
            </Grid>
            <Grid container alignItems="center" xs={12} mt="16px">
              <Grid xs={3}>
                <Typography color="secondary" opacity={0.5} fontSize="0.875rem">
                  Atraso na entrada
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography color="secondary" opacity={0.5} fontSize="0.875rem">
                  Atraso na volta do intervalo
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" xs={12}>
              <Grid xs={3}>
                <Typography
                  color="secondary"
                  fontWeight="bold"
                  opacity={0.5}
                  fontSize="1.25rem"
                >
                  {journeyDetails.entryDelayTime} minutos
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography
                  color="secondary"
                  fontWeight="bold"
                  opacity={0.5}
                  fontSize="1.25rem"
                >
                  {journeyDetails.breakDelayTime} minutos
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Box mt={3}>
            <Divider />
          </Box>
          <Grid container mt="24px">
            <Grid item xs={12}>
              <Typography color="secondary" fontSize="0.875rem">
                Antecipação
              </Typography>
            </Grid>
            <Grid container alignItems="center" xs={12} mt="16px">
              <Grid xs={3}>
                <Typography color="secondary" opacity={0.5} fontSize="0.875rem">
                  Antecipação na saída
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography color="secondary" opacity={0.5} fontSize="0.875rem">
                  Antecipação no início do intervalo
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" xs={12}>
              <Grid xs={3}>
                <Typography
                  color="secondary"
                  fontWeight="bold"
                  opacity={0.5}
                  fontSize="1.25rem"
                >
                  {journeyDetails.anticipationExitTime} minutos
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography
                  color="secondary"
                  fontWeight="bold"
                  opacity={0.5}
                  fontSize="1.25rem"
                >
                  {journeyDetails.breakAnticipationTime} minutos
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Box mt={3}>
            <Divider />
          </Box>
          {journeyDetails.limited ? (
            <>
              <Grid container mt="24px">
                <Grid item xs={12}>
                  <Typography color="secondary" fontSize="0.875rem">
                    Jornada limitada
                  </Typography>
                </Grid>
                <Grid container alignItems="center" xs={12} mt="16px">
                  <Grid xs={3}>
                    <Typography
                      color="secondary"
                      opacity={0.5}
                      fontSize="0.875rem"
                    >
                      Limite na entrada
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" xs={12}>
                  <Grid xs={3}>
                    <Typography
                      color="secondary"
                      fontWeight="bold"
                      opacity={0.5}
                      fontSize="1.25rem"
                    >
                      {journeyDetails.limitTime} minutos
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Box mt={3}>
                <Divider />
              </Box>
            </>
          ) : null}

          <Grid container mt="24px">
            <Grid item xs={12}>
              <Typography color="secondary" fontSize="0.875rem">
                Hora extra
              </Typography>
            </Grid>
            <Grid container alignItems="center" xs={12} mt="16px">
              <Grid xs={3}>
                <Typography color="secondary" opacity={0.5} fontSize="0.875rem">
                  Max. por dia
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" xs={12}>
              <Grid xs={3}>
                <Typography color="secondary" opacity={0.5} fontSize="1.25rem">
                  {journeyDetails.extraHourPerDay} minutos
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </TabPanel>
  );
};

JourneyDetails.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  journeyDetails: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default JourneyDetails;
