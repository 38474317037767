import { subDays } from 'date-fns';
import * as Yup from 'yup';
import { REQUEST_CHANGES } from '../utils/enums';
import parseDate from '../utils/parseDateString';

const requestChangeModalSchema = Yup.object().shape({
  reason: Yup.string().required('Campo obrigatório'),
  changeDayOff: Yup.object().when('reason', {
    is: REQUEST_CHANGES.CHANGE_DAY_OFF,
    then: Yup.object().shape({
      dayOffDate: Yup.date()
        .transform(parseDate)
        .typeError('Data Invalida')
        .required('Campo obrigatório'),
      workDate: Yup.date()
        .transform(parseDate)
        .typeError('Data Invalida')
        .required('Campo obrigatório'),
      description: Yup.string().required('Campo obrigatório'),
    }),
    otherwise: Yup.object().notRequired(),
  }),
  requestDayOff: Yup.object().when('reason', {
    is: REQUEST_CHANGES.REQUEST_DAY_OFF,
    then: Yup.object().shape({
      date: Yup.date()
        .transform(parseDate)
        .typeError('Data Invalida')
        .required('Campo obrigatório'),
      justification: Yup.string().required('Campo obrigatório'),
    }),
    otherwise: Yup.object().notRequired(),
  }),
  hoursCompensation: Yup.object().when('reason', {
    is: REQUEST_CHANGES.HOURS_COMPENSATION,
    then: Yup.object().shape({
      date: Yup.date()
        .transform(parseDate)
        .typeError('Data Inválida')
        .required('Campo obrigatório')
        .min(
          subDays(new Date(), 1),
          'Informe uma igual ou data posterior a data atual.',
        ),
      dayHoursCompensated: Yup.string()
        .required('Campo obrigatório')
        .test('HasStringValues', 'Valor inválido', function validate(value) {
          if (value) {
            const splittedString = value.split('');

            const isValid = splittedString.every(
              character => !!character.trim(),
            );
            return isValid;
          }
          return false;
        })
        .test('Validate string', 'Valor inválido', function validate(value) {
          if (value) {
            const splittedString = value.split('');
            const isUnValid = splittedString.every(character => {
              return character === '0' || !character || character === ':';
            });
            return !isUnValid;
          }
          return false;
        }),
      justification: Yup.string().required('Campo obrigatório'),
    }),
  }),
});

export default requestChangeModalSchema;
