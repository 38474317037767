import DateFnsUtils from '@date-io/date-fns';
import { TextField as TextFieldMaterialUi } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React from 'react';
import { MoneyInput } from '../../../../components';
import { TimeInput } from '../../../../components/MaskedInput';
import { StyledGrid } from '../styles';

const MoneyPaymentInfo = ({ initialAllTimeCompTime }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <>
        <StyledGrid item xs={6}>
          <TextFieldMaterialUi
            name="formattedAllTimeCompTime"
            label="Total de horas no banco"
            variant="filled"
            value={`${initialAllTimeCompTime} horas`}
            disabled
            fullWidth
          />
        </StyledGrid>
        <StyledGrid item xs={6}>
          <Field
            component={TextField}
            name="hoursPaid"
            label="Total de horas que deseja pagar"
            variant="filled"
            fullWidth
            placeholder="00:00"
            InputProps={{
              inputComponent: TimeInput,
            }}
          />
        </StyledGrid>
        <StyledGrid item xs={6}>
          <Field
            component={TextField}
            name="paidValue"
            label="Valor Pago"
            variant="filled"
            fullWidth
            InputProps={{
              inputComponent: MoneyInput,
            }}
          />
        </StyledGrid>

        <StyledGrid item xs={6}>
          <Field
            component={KeyboardDatePicker}
            name="paymentDate"
            label="Data do pagamento"
            inputVariant="filled"
            cancelLabel="CANCELAR"
            invalidDateMessage="Data inválida"
            format="dd/MM/yyyy"
            fullWidth
          />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <Field
            component={TextField}
            name="justification"
            label="Justificativa"
            variant="filled"
            fullWidth
          />
        </StyledGrid>
      </>
    </MuiPickersUtilsProvider>
  );
};

MoneyPaymentInfo.propTypes = {
  initialAllTimeCompTime: PropTypes.string.isRequired,
};

export default MoneyPaymentInfo;
