import DateFnsUtils from '@date-io/date-fns';
import { Box, ButtonBase, Divider, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { Field, FieldArray, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Grid, TabPanel, Typography } from '../../../../components';
import journeyBreaksSchema from '../../../../validations/journeyBreaks';
import BreaksInput from '../BreaksInput';
import { ContainerInput } from '../styles';

const Break = props => {
  const {
    value,
    index,
    handleSubmitJourney,
    handleBack,
    initialValues,
    isUpdate,
  } = props;

  const numberOfBreaksOptions = [
    {
      label: '4 Pausas + Principal',
      value: '4Breaks',
    },
    {
      label: '3 Pausas + Principal',
      value: '3Breaks',
    },
    {
      label: '2 Pausas + Principal',
      value: '2Breaks',
    },
    {
      label: '1 Pausa + Principal',
      value: '1Breaks',
    },
    {
      label: 'Somente Principal',
      value: '0Breaks',
    },
  ];

  const breakOptions = [
    {
      label: '30 min',
      value: '30',
    },
    {
      label: '25 min',
      value: '25',
    },
    {
      label: '20 min',
      value: '20',
    },
    {
      label: '15 min',
      value: '15',
    },
    {
      label: '10 min',
      value: '10',
    },
    {
      label: '5 min',
      value: '5',
    },
  ];

  const periodOptions = [
    {
      label: 'Após a entrada',
      value: 'afterEntry',
    },
    {
      label: 'Antes da pausa principal',
      value: 'beforeMainPause',
    },

    {
      label: 'Após da pausa principal',
      value: 'afterMainPause',
    },
    {
      label: 'Antes da saída',
      value: 'beforeExit',
    },
  ];

  const handleAddPause = (event, setFieldValue, values) => {
    const selectedPause = event.target.value;

    if (selectedPause === '0Breaks') {
      setFieldValue('breaks', []);
      return;
    }

    if (selectedPause === '4Breaks') {
      if (values.numberOfBreaks === '3Breaks') {
        setFieldValue('breaks', [
          ...values.breaks,
          {
            id: 4,
            description: '',
            time: '',
            period: '',
            start: null,
          },
        ]);
      } else if (values.numberOfBreaks === '2Breaks') {
        setFieldValue('breaks', [
          ...values.breaks,
          {
            id: 3,
            description: '',
            time: '',
            period: '',
            start: null,
          },
          {
            id: 4,
            description: '',
            time: '',
            period: '',
            start: null,
          },
        ]);
      } else if (values.numberOfBreaks === '1Breaks') {
        setFieldValue('breaks', [
          ...values.breaks,
          {
            id: 2,
            description: '',
            time: '',
            period: '',
            start: null,
          },
          {
            id: 3,
            description: '',
            time: '',
            period: '',
            start: null,
          },
          {
            id: 4,
            description: '',
            time: '',
            period: '',
            start: null,
          },
        ]);
      } else {
        setFieldValue('breaks', [
          {
            id: 1,
            description: '',
            time: '',
            period: '',
            start: null,
          },
          {
            id: 2,
            description: '',
            time: '',
            period: '',
            start: null,
          },
          {
            id: 3,
            description: '',
            time: '',
            period: '',
            start: null,
          },
          {
            id: 4,
            description: '',
            time: '',
            period: '',
            start: null,
          },
        ]);
      }
    }

    if (selectedPause === '3Breaks') {
      if (values.numberOfBreaks === '4Breaks') {
        const newBreaks = [...values.breaks];
        newBreaks.pop();
        setFieldValue('breaks', newBreaks);
      } else if (values.numberOfBreaks === '2Breaks') {
        setFieldValue('breaks', [
          ...values.breaks,
          {
            id: 3,
            description: '',
            time: '',
            period: '',
            start: null,
          },
        ]);
      } else if (values.numberOfBreaks === '1Breaks') {
        setFieldValue('breaks', [
          ...values.breaks,
          {
            id: 2,
            description: '',
            time: '',
            period: '',
            start: null,
          },
          {
            id: 3,
            description: '',
            time: '',
            period: '',
            start: null,
          },
        ]);
      } else {
        setFieldValue('breaks', [
          {
            id: 1,
            description: '',
            time: '',
            period: '',
            start: null,
          },
          {
            id: 2,
            description: '',
            time: '',
            period: '',
            start: null,
          },
          {
            id: 3,
            description: '',
            time: '',
            period: '',
            start: null,
          },
        ]);
      }
    }

    if (selectedPause === '2Breaks') {
      if (values.numberOfBreaks === '4Breaks') {
        const newBreaks = [...values.breaks];
        newBreaks.splice(newBreaks.length - 2, 2);
        setFieldValue('breaks', newBreaks);
      } else if (values.numberOfBreaks === '3Breaks') {
        const newBreaks = [...values.breaks];
        newBreaks.pop();
        setFieldValue('breaks', newBreaks);
      } else if (values.numberOfBreaks === '1Breaks') {
        setFieldValue('breaks', [
          ...values.breaks,
          {
            id: 2,
            description: '',
            time: '',
            period: '',
            start: null,
          },
        ]);
      } else {
        setFieldValue('breaks', [
          {
            id: 1,
            description: '',
            time: '',
            period: '',
            start: null,
          },
          {
            id: 2,
            description: '',
            time: '',
            period: '',
            start: null,
          },
        ]);
      }
    }

    if (selectedPause === '1Breaks') {
      if (values.numberOfBreaks === '4Breaks') {
        const newBreaks = [...values.breaks];
        newBreaks.splice(newBreaks.length - 3, 3);
        setFieldValue('breaks', newBreaks);
      } else if (values.numberOfBreaks === '3Breaks') {
        const newBreaks = [...values.breaks];
        newBreaks.splice(newBreaks.length - 2, 2);
        setFieldValue('breaks', newBreaks);
      } else if (values.numberOfBreaks === '2Breaks') {
        const newBreaks = [...values.breaks];
        newBreaks.pop();
        setFieldValue('breaks', newBreaks);
      } else {
        setFieldValue('breaks', [
          {
            id: 1,
            description: '',
            time: '',
            period: '',
            start: null,
          },
        ]);
      }
    }
  };

  return (
    <TabPanel value={value} index={index}>
      <Box p={3}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmitJourney}
          validationSchema={journeyBreaksSchema}
          enableReinitialize
        >
          {({ values, submitForm, setFieldValue }) => (
            <Form>
              <Grid container justify="space-between" spacing={2}>
                <Grid item xs={12}>
                  <Typography color="secondary" fontSize="0.875rem">
                    Quantidade de pausas
                  </Typography>
                </Grid>
                <Grid item container spacing={3} xs={6}>
                  <ContainerInput item xs={6}>
                    <Field
                      component={TextField}
                      select
                      name="numberOfBreaks"
                      variant="filled"
                      fullWidth
                      label="Quantidade"
                      onClick={e => handleAddPause(e, setFieldValue, values)}
                    >
                      {numberOfBreaksOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </ContainerInput>
                </Grid>
              </Grid>
              <Box mt={3}>
                <Divider />
              </Box>

              <Grid container justify="space-between" spacing={2} mt="24px">
                <Grid item xs={12}>
                  <Typography color="secondary" fontSize="0.875rem">
                    Outras pausas
                  </Typography>
                </Grid>
                {values.numberOfBreaks === '0Breaks' && (
                  <Grid item xs={12}>
                    <Typography color="secondary" opacity={0.5} fontSize="1rem">
                      Nenhuma pausa extra foi selecionada
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Box mt={3}>
                <FieldArray
                  name="breaks"
                  render={() => (
                    <>
                      {values.breaks && values.breaks.length !== 0 ? (
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={ptBR}
                        >
                          <BreaksInput
                            breakOptions={breakOptions}
                            periodOptions={periodOptions}
                            values={values}
                          />
                        </MuiPickersUtilsProvider>
                      ) : null}
                    </>
                  )}
                />
              </Box>
              <Box mt={7}>
                {isUpdate ? (
                  <Grid container spacing={3} justify="flex-end">
                    <Grid item>
                      <ButtonBase onClick={submitForm}>
                        <Typography
                          fontSize="14px"
                          fontWeight="bold"
                          color="secondary"
                        >
                          ALTERAR DADOS
                        </Typography>
                      </ButtonBase>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={3} justify="flex-end">
                    <Grid item>
                      <ButtonBase onClick={handleBack}>
                        <Typography
                          fontSize="14px"
                          fontWeight="bold"
                          color="secondary"
                          opacity={0.5}
                        >
                          VOLTAR
                        </Typography>
                      </ButtonBase>
                    </Grid>
                    <Grid item>
                      <ButtonBase onClick={submitForm}>
                        <Typography
                          fontSize="14px"
                          fontWeight="bold"
                          color="secondary"
                        >
                          CONCLUIR
                        </Typography>
                      </ButtonBase>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </TabPanel>
  );
};

Break.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  handleSubmitJourney: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  isUpdate: PropTypes.bool,
};

Break.defaultProps = {
  isUpdate: false,
};

export default Break;
