import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerModal = styled.div`
  padding: 20px 40px 5px 40px;
`;

export const StyledGrid = styled(Grid)`
  background-color: #eee;
  padding: 10px 20px;
`;

export const ActionsBar = styled(Grid)`
  margin-top: 20px;
  margin-bottom: 20px;

  button {
    padding: 0 20px;
    font-weight: 700;
    font-size: 14px;
  }
  button:nth-child(1) {
    opacity: 0.5;
  }
`;

export const DownloaderContainer = styled(Grid)`
  margin-bottom: 10px;
`;
