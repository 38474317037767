import * as Yup from 'yup';

const promoteCollaboratorSchema = Yup.object().shape({
  jobRole: Yup.string().required('Campo obrigatório'),
  regime: Yup.string().required('Campo obrigatório'),
  department: Yup.string().required('Campo obrigatório'),
  hasStandardSalary: Yup.bool()
    .required('Campo obrigatório')
    .typeError('Campo obigatório'),
  hasVt: Yup.bool().required('Campo obrigatório').typeError('Campo obigatório'),
  hasVa: Yup.bool().required('Campo obrigatório').typeError('Campo obigatório'),
  hasVr: Yup.bool().required('Campo obrigatório').typeError('Campo obigatório'),
  salary: Yup.mixed().test(
    'Validate number',
    'Valor invalido',
    function validate() {
      const objectToValidate = this.options.from[0].value;
      if (objectToValidate.hasStandardSalary !== 'true') {
        return objectToValidate.salary !== 0 && !!objectToValidate.salary;
      }
      return true;
    },
  ),
  vt: Yup.mixed().test(
    'Validate number',
    'Valor invalido',
    function validate() {
      const objectToValidate = this.options.from[0].value;
      if (objectToValidate.hasVt === 'true') {
        return objectToValidate.vt !== 0 && !!objectToValidate.vt;
      }
      return true;
    },
  ),
  va: Yup.string().test(
    'Validate number',
    'Valor invalido',
    function validate() {
      const objectToValidate = this.options.from[0].value;

      if (objectToValidate.hasVa === 'true') {
        return objectToValidate.va !== 0 && !!objectToValidate.va;
      }
      return true;
    },
  ),
  vr: Yup.mixed().test(
    'Validate number',
    'Valor invalido',
    function validate() {
      const objectToValidate = this.options.from[0].value;
      if (objectToValidate.hasVr === 'true') {
        return objectToValidate.vr !== 0 && !!objectToValidate.vr;
      }
      return true;
    },
  ),
});

export default promoteCollaboratorSchema;
