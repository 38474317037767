import { validateBr } from 'js-brasil';
import * as Yup from 'yup';
import parseDate from '../utils/parseDateString';
import validateOnlyLetters from '../utils/validateOnlyLetters';

const newDependent = Yup.object().shape({
  dependentName: Yup.string()
    .required('Campo obrigatório')
    .test('isNameCompleted', 'Informe o nome completo', name => {
      try {
        return name.trim().indexOf(' ') !== -1;
      } catch (e) {
        return false;
      }
    })
    .test(
      'Validate only letters',
      'Somente letras são aceitas',
      validateOnlyLetters,
    ),
  dependentType: Yup.string().required('Campo obrigatório'),
  dependentbirthDate: Yup.date()
    .transform(parseDate)
    .min(new Date(1900, 0, 1), 'Informe uma data válida')
    .max(new Date(), 'Informe uma data válida')
    .required('Campo obrigatório')
    .typeError('Data inválida'),
  dependentCpf: Yup.string()
    .required('Campo obrigatório')
    .test('isCpfValid', 'CPF inválido', val => {
      try {
        return validateBr.cpf(val);
      } catch (error) {
        return false;
      }
    })
    .length(11, 'Campo inválido'),
});

export default newDependent;
