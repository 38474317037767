import styled from 'styled-components';
import { Tabs, Tab, ButtonBase, Box, Grid } from '@material-ui/core';
import { transparentize } from 'polished';

export const ContainerInput = styled(Grid)`
  height: 80px;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : 0)};

  input {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const StyledButtonBase = styled(ButtonBase)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({ color, theme }) =>
    color === 'error'
      ? theme.palette.error.main
      : transparentize(0.8, theme.palette.primary.main)};
  margin-right: 16px;
`;

export const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid #e5e5e5;
`;

export const StyledTab = styled(Tab)`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const StyledBorderBox = styled(Box)`
  border-style: dashed;
  padding: 20px;
`;

export const StyledImg = styled.img`
  @media (min-width: 1440px) and (max-width: 2880px) {
    width: 70%;
  }
  @media (min-width: 992px) and (max-width: 1438px) {
    width: 100%;
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
  }
  @media only screen and (max-width: 479px) {
    width: 100%;
  }
  @media (max-width: 320px) {
    width: 100%;
  }
`;
