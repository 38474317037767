import styled, { css } from 'styled-components';
import { Button, Grid } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

export const GreyGrid = styled(Grid)`
  display: flex;
  align-content: center;
  justify-content: space-around;
  width: 100%;
  height: 76px;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
`;
export const StyledArrowCircle = styled.div`
  width: 18px;
  height: 18px;
  border: 2px solid #000000;
  border-radius: 50%;
  img {
    margin-bottom: 2px;
  }
`;
export const StyledArrowCircleSucess = styled.div`
  width: 18px;
  height: 18px;
  border: 2px solid #40a6ff;
  border-radius: 50%;
`;

export const StyledArrowCircleSucessContract = styled.div`
  width: 18px;
  height: 18px;
  opacity: 0.5;
  border: 2px solid #000;
  border-radius: 50%;
`;

export const StyledButton = styled(Button)`
  color: #fff;

  &.MuiButtonBase-root {
    width: 170px;
    height: 45px;
    border-radius: 4px;
    opacity: 1;
    ${({ theme }) => css`
      background: ${theme.palette.primary.main} 0% 0% no-repeat padding-box;
    `}
  }
`;

export const StyledDoneIcon = styled(DoneIcon)`
  padding-right: 6px;
  padding-bottom: 5px;
`;
