import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Typography, Uploader } from '../../../../components';
import { StyledGrid } from '../styles';

const RequestDayOff = ({ setRequestDayOffFile }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <StyledGrid item lg={6}>
        <Field
          component={KeyboardDatePicker}
          name="requestDayOff.date"
          label="Data"
          invalidDateMessage="Data inválida"
          cancelLabel="CANCELAR"
          inputVariant="filled"
          format="dd/MM/yyyy"
          placeholder="dd/mm/aaaa"
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item lg={12}>
        <Field
          component={TextField}
          name="requestDayOff.justification"
          label="Justificativa"
          variant="filled"
          fullWidth
        />
      </StyledGrid>
      <Grid item lg={12}>
        <Typography opacity={0.5} fontSize="14px">
          Anexo(Não obrigatório)
        </Typography>
        <Uploader
          accept="application/pdf"
          dropText="Anexar"
          onFileUploaded={setRequestDayOffFile}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

RequestDayOff.propTypes = {
  setRequestDayOffFile: PropTypes.func.isRequired,
};

export default RequestDayOff;
