import { Box } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Return from '../../assets/arrow-left.svg';
import { Grid, Tab, Tabs, Typography } from '../../components';
import { setLoading } from '../../redux/ducks/loading';
import { setSnackbar } from '../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../services/api';
import { Container, StyledButtonBase, TabsPaper } from './styles';
import { Breaks, JourneyDetails, Scale } from './Tabs';

const ColaboratorJourney = () => {
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);
  const [journeyDetails, setJourneyDetails] = useState({});
  const [journeyBreaks, setJourneyBreaks] = useState({});
  const { userId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserJourney = async () => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();
        const { data: userJourney } = await api.get(
          `/workday/${userId}/journey`,
        );
        setJourneyDetails({
          name: userJourney.name,
          holidays: userJourney.holidays,
          pointType: userJourney.pointType,
          intervalCompensation: userJourney.intervalCompensation,
          entryDelayTime: userJourney.entryDelayTime,
          breakDelayTime: userJourney.breakDelayTime,
          anticipationExitTime: userJourney.anticipationExitTime,
          breakAnticipationTime: userJourney.breakAnticipationTime,
          limited: userJourney.limited,
          limitTime: userJourney.limitTime,
          extraHourPerDay: userJourney.extraHourPerDay,
        });
        setJourneyBreaks({
          numberOfBreaks: userJourney.numberOfBreaks,
          breaks: userJourney.breaks,
        });
      } catch (e) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possivel buscar a jornada'),
        );
      } finally {
        dispatch(setLoading(false));
      }
    };
    getUserJourney();
  }, [dispatch, userId]);

  const handleChangeTab = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  return (
    <Container>
      <Box mt={2}>
        <Grid container alignItems="center">
          <StyledButtonBase onClick={() => history.goBack()}>
            <img src={Return} alt="" />
          </StyledButtonBase>
          <Typography color="secondary" fontSize="25px" fontStyle="bold">
            Minha Jornada
          </Typography>
        </Grid>
      </Box>
      <Box mt={3}>
        <TabsPaper square elevation={1}>
          <Tabs value={tabValue} onChange={handleChangeTab}>
            <Tab label="DETALHES DA JORNADA" index={0} />
            <Tab label="ESCALA" index={1} />
            <Tab label="PAUSAS" index={2} />
          </Tabs>

          <JourneyDetails
            journeyDetails={journeyDetails}
            value={tabValue}
            index={0}
          />
          <Scale value={tabValue} index={1} />
          <Breaks value={tabValue} index={2} journeyBreaks={journeyBreaks} />
        </TabsPaper>
      </Box>
    </Container>
  );
};

export default ColaboratorJourney;
