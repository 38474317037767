import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { RadioGroup, TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import celebration from '../../../../assets/Celebration-rafiki.svg';
import {
  Grid,
  ModalSkeleton,
  MoneyInput,
  Typography,
} from '../../../../components';
import { useAuth } from '../../../../hooks/auth';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import { REGIME_TYPE } from '../../../../utils/enums';
import promoteCollaboratorSchema from '../../../../validations/promoteCollaboratorSchema';
import { StyledGrid, StyledImg } from './styles';

const PromoteCollaboratorModal = ({ handleOnClose }) => {
  const [jobRoles, setJobRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const { user } = useAuth();
  const { userId: collaboratorId } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const api = AuthenticatedHttpClient();

    async function getOptions() {
      try {
        dispatch(setLoading(true));
        const [jobRolesResponse, departmentsResponse] = await Promise.all([
          api.get(`job-role/${user.company._id}`),
          api.get(`department/${user.company._id}`),
        ]);
        const { data: dataJobRoles } = jobRolesResponse;
        const { data: dataDepartments } = departmentsResponse;

        const optionsJobRoles = dataJobRoles.map(jobRoleInfo => ({
          label: jobRoleInfo.name,
          value: jobRoleInfo._id,
          baseSalary: jobRoleInfo.baseSalary,
        }));

        const optionsDepartments = dataDepartments.map(departmentInfo => ({
          label: departmentInfo.name,
          value: departmentInfo._id,
        }));

        setJobRoles(optionsJobRoles);
        setDepartments(optionsDepartments);
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Falha ao buscar cargos e departamentos'),
        );
      } finally {
        dispatch(setLoading(false));
      }
    }
    getOptions();
  }, [user, dispatch]);

  const regimeOptions = [
    {
      label: 'CLT',
      value: REGIME_TYPE.CLT,
    },
    {
      label: 'PJ',
      value: REGIME_TYPE.PJ,
    },
  ];

  const formInitialValues = {
    jobRole: '',
    regime: '',
    department: '',
    hasStandardSalary: '',
    hasVt: '',
    hasVa: '',
    hasVr: '',
    salary: 0,
    vt: 0,
    va: 0,
    vr: 0,
  };

  const getBenefitValue = (hasBenefit, benefit) => {
    return hasBenefit === 'false' ? 0 : benefit;
  };

  const handlePromoteCollaborator = useCallback(
    async values => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();
        const afterPromotion = { ...values };
        afterPromotion.va = getBenefitValue(
          afterPromotion.hasVa,
          afterPromotion.va,
        );
        afterPromotion.vt = getBenefitValue(
          afterPromotion.hasVt,
          afterPromotion.vt,
        );
        afterPromotion.vr = getBenefitValue(
          afterPromotion.hasVr,
          afterPromotion.vr,
        );
        delete afterPromotion.hasVa;
        delete afterPromotion.hasVr;
        delete afterPromotion.hasVt;
        await api.post(`/promotion/${collaboratorId}`, {
          afterPromotion,
        });
        dispatch(
          setSnackbar(
            true,
            'success',
            'O colaborador foi promovido com sucesso!',
          ),
        );
        handleOnClose();
        history.goBack();
      } catch (error) {
        if (error.response?.data?.message) {
          dispatch(setSnackbar(true, 'error', error.response.data.message));
        } else {
          dispatch(
            setSnackbar(
              true,
              'error',
              'Não foi possivel promover o colaborador.',
            ),
          );
        }
      } finally {
        dispatch(setLoading(false));
      }
    },
    [collaboratorId, dispatch, handleOnClose, history],
  );

  const handleSetStandardSalary = useCallback(
    (jobRoleId, setField) => {
      const jobRoleSalary = jobRoles.find(item => item.value === jobRoleId);
      if (jobRoleSalary) {
        setField('salary', jobRoleSalary.baseSalary);
      }
    },
    [jobRoles],
  );

  const handleChangeJobRole = useCallback(
    ({ standard, jobRoleId, setField }) => {
      if (standard === 'true') {
        handleSetStandardSalary(jobRoleId, setField);
      }
    },
    [handleSetStandardSalary],
  );
  return (
    <ModalSkeleton title="Promover Colaborador" handleOnClose={handleOnClose}>
      <Box m={3} pt={6}>
        <Grid container spacing={3} justify="flex-end" alignItems="center">
          <Grid item lg={6}>
            <StyledImg src={celebration} alt="" />
          </Grid>
          <Grid item lg={6}>
            <Formik
              initialValues={formInitialValues}
              onSubmit={handlePromoteCollaborator}
              validationSchema={promoteCollaboratorSchema}
            >
              {({
                submitForm,
                isSubmitting,
                touched,
                errors,
                values,
                setFieldValue,
              }) => (
                <Form>
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item lg={12}>
                      <Typography
                        color="secondary"
                        fontWeight="bold"
                        opacity="1"
                        fontSize="14px"
                      >
                        Informe os novos dados sobre a promoção:
                      </Typography>
                    </Grid>
                    <StyledGrid item lg={6}>
                      <Field
                        component={TextField}
                        select
                        name="regime"
                        variant="filled"
                        fullWidth
                        label="Regime Trabalhista"
                        placeholder="Regime Trabalhista"
                      >
                        {regimeOptions.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </StyledGrid>
                    <StyledGrid item lg={6}>
                      <Field
                        component={TextField}
                        select
                        name="jobRole"
                        variant="filled"
                        fullWidth
                        placeholder="Cargo"
                        label="Cargo"
                        InputProps={{
                          onChange: e => {
                            handleChangeJobRole({
                              standard: values.hasStandardSalary,
                              jobRoleId: e.target.value,
                              setField: setFieldValue,
                            });
                            setFieldValue('jobRole', e.target.value);
                          },
                        }}
                      >
                        {jobRoles.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </StyledGrid>
                    <StyledGrid item lg={6}>
                      <Field
                        component={TextField}
                        select
                        name="department"
                        label="Departamento"
                        variant="filled"
                        fullWidth
                        placeholder="Departamento"
                      >
                        {departments.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </StyledGrid>
                    <StyledGrid item lg={6} />
                    <StyledGrid item xs={6}>
                      <FormControl error={!!errors.hasStandardSalary}>
                        <FormLabel component="legend">
                          <Typography
                            opacity="0.7"
                            color="secondary"
                            fontSize="14px"
                          >
                            Definir salário padrão ao cargo?
                          </Typography>
                        </FormLabel>
                        <Field
                          component={RadioGroup}
                          name="hasStandardSalary"
                          row
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio disabled={isSubmitting} />}
                            label="SIM"
                            disabled={isSubmitting || !values.jobRole}
                            onChange={() =>
                              handleSetStandardSalary(
                                values.jobRole,
                                setFieldValue,
                              )
                            }
                          />
                          <FormControlLabel
                            value="false"
                            control={
                              <Radio
                                disabled={isSubmitting || !values.jobRole}
                              />
                            }
                            label="NÃO"
                            disabled={isSubmitting}
                          />
                        </Field>
                        <FormHelperText>
                          {errors.hasStandardSalary}
                        </FormHelperText>
                      </FormControl>
                    </StyledGrid>
                    <StyledGrid item xs={6}>
                      {values.hasStandardSalary && (
                        <Field
                          component={TextField}
                          name="salary"
                          label="Valor"
                          variant="filled"
                          fullWidth
                          disabled={values.hasStandardSalary === 'true'}
                          InputProps={{
                            inputComponent: MoneyInput,
                          }}
                        />
                      )}
                    </StyledGrid>
                    <StyledGrid item lg={6}>
                      <FormControl error={touched.hasVt && !!errors.hasVt}>
                        <FormLabel component="legend">
                          <Typography
                            opacity="0.7"
                            color="secondary"
                            fontSize="14px"
                          >
                            Vale Transporte?
                          </Typography>
                        </FormLabel>
                        <Field component={RadioGroup} name="hasVt" row>
                          <FormControlLabel
                            value="true"
                            control={<Radio disabled={isSubmitting} />}
                            label="SIM"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio disabled={isSubmitting} />}
                            label="NÃO"
                            disabled={isSubmitting}
                          />
                        </Field>
                        <FormHelperText>
                          {touched.hasVt && errors.hasVt}
                        </FormHelperText>
                      </FormControl>
                    </StyledGrid>
                    <StyledGrid item lg={6}>
                      {values.hasVt === 'true' && (
                        <Field
                          component={TextField}
                          name="vt"
                          label="Valor"
                          variant="filled"
                          fullWidth
                          InputProps={{
                            inputComponent: MoneyInput,
                          }}
                        />
                      )}
                    </StyledGrid>
                    <StyledGrid item lg={6}>
                      <FormControl error={touched.hasVa && !!errors.hasVa}>
                        <FormLabel component="legend">
                          <Typography
                            opacity="0.7"
                            color="secondary"
                            fontSize="14px"
                          >
                            Vale Alimentação?
                          </Typography>
                        </FormLabel>
                        <Field component={RadioGroup} name="hasVa" row>
                          <FormControlLabel
                            value="true"
                            control={<Radio disabled={isSubmitting} />}
                            label="SIM"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio disabled={isSubmitting} />}
                            label="NÃO"
                            disabled={isSubmitting}
                          />
                        </Field>
                        <FormHelperText>
                          {touched.hasVa && errors.hasVa}
                        </FormHelperText>
                      </FormControl>
                    </StyledGrid>
                    <StyledGrid item lg={6}>
                      {values.hasVa === 'true' && (
                        <Field
                          component={TextField}
                          name="va"
                          label="Valor"
                          variant="filled"
                          fullWidth
                          InputProps={{
                            inputComponent: MoneyInput,
                          }}
                        />
                      )}
                    </StyledGrid>
                    <StyledGrid item lg={6}>
                      <FormControl error={touched.hasVr && !!errors.hasVr}>
                        <FormLabel component="legend">
                          <Typography
                            opacity="0.7"
                            color="secondary"
                            fontSize="14px"
                          >
                            Vale Refeição?
                          </Typography>
                        </FormLabel>
                        <Field component={RadioGroup} name="hasVr" row>
                          <FormControlLabel
                            value="true"
                            control={<Radio disabled={isSubmitting} />}
                            label="SIM"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio disabled={isSubmitting} />}
                            label="NÃO"
                            disabled={isSubmitting}
                          />
                        </Field>
                        <FormHelperText>
                          {touched.hasVr && errors.hasVr}
                        </FormHelperText>
                      </FormControl>
                    </StyledGrid>
                    <StyledGrid item lg={6}>
                      {values.hasVr === 'true' && (
                        <Field
                          component={TextField}
                          name="vr"
                          label="Valor"
                          variant="filled"
                          fullWidth
                          InputProps={{
                            inputComponent: MoneyInput,
                          }}
                        />
                      )}
                    </StyledGrid>
                  </Grid>

                  <Box mt={3} mb={3}>
                    <Grid
                      container
                      item
                      lg={12}
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Box mr={4} mt={5}>
                        <Button
                          type="buton"
                          color="secondary"
                          onClick={handleOnClose}
                          size="small"
                        >
                          <Typography
                            fontSize="0.9375rem"
                            fontWeight="bold"
                            opacity="0.7"
                          >
                            CANCELAR
                          </Typography>
                        </Button>
                      </Box>
                      <Box mt={5}>
                        <Button
                          color="secondary"
                          type="submit"
                          onClick={submitForm}
                          disabled={isSubmitting}
                          size="small"
                        >
                          <Typography fontSize="0.9375rem" fontWeight="bold">
                            CONCORDO EM PROMOVER ESTE COLABORADOR
                          </Typography>
                        </Button>
                      </Box>
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </ModalSkeleton>
  );
};

PromoteCollaboratorModal.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
};

export default PromoteCollaboratorModal;
