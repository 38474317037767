import * as Yup from 'yup';
import { validateBr } from 'js-brasil';
import parseDate from '../utils/parseDateString';

const documentationSchema = Yup.object().shape({
  cpf: Yup.string()
    .required('Campo obrigatório')
    .test('isCpfValid', 'CPF inválido', val => {
      try {
        return validateBr.cpf(val);
      } catch (error) {
        return false;
      }
    }),
  pisNumber: Yup.string().test('isPisValid', 'PIS inválido', val => {
    try {
      if (!val) return true;
      return validateBr.pispasep(val);
    } catch (error) {
      return false;
    }
  }),
  rgNumber: Yup.string().max(10, 'Informe um número válido'),
  rgExpeditionDate: Yup.date()
    .transform(parseDate)
    .min(new Date(1900, 0, 1), 'Informe uma data válida')
    .max(new Date(), 'Informe uma data válida')
    .typeError('Data inválida'),
  rgOrganUfEmitter: Yup.string(),
  voterRegistrationNumber: Yup.string().max(13, 'Informe um número válido'),
  voterRegistrationZone: Yup.string(),
  voterRegistrationSection: Yup.string(),
  reservistNumber: Yup.string(),
});

export default documentationSchema;
