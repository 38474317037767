import PropTypes from 'prop-types';
import React from 'react';
import deleteCircle from '../../assets/deleteCircle.svg';
import downloadCircle from '../../assets/downloadCircle.svg';
import FileDoc from '../../assets/file-doc.svg';
import getFileUrlFor from '../../utils/getFIleUrlFor';
import Typography from '../Typography';
import {
  ActionContainer,
  ActionsContainer,
  PaySlipContainer,
  PaySlipListContainer,
  StyledDeleteButton,
  StyledDownloadButton,
  SubTitleForm,
} from './styles';

const PaySlipList = ({ defaultImage, paySlips, removeFn, colaborator }) => {
  return (
    <PaySlipListContainer>
      {paySlips.map(element => {
        return (
          <>
            <SubTitleForm>{element.date}</SubTitleForm>
            <PaySlipContainer>
              <img src={defaultImage} alt="" />
              <span>
                <Typography color="primary" fontSize="13px" fontWeight="500">
                  {element.payslip.name}
                </Typography>
              </span>
              <ActionsContainer>
                {!colaborator && (
                  <ActionContainer>
                    <StyledDeleteButton onClick={() => removeFn(element._id)}>
                      <img src={deleteCircle} alt="" />
                      <Typography
                        color="error"
                        fontSize="12px"
                        fontWeight="500"
                      >
                        Remover
                      </Typography>
                    </StyledDeleteButton>
                  </ActionContainer>
                )}
                <ActionContainer>
                  <StyledDownloadButton
                    href={getFileUrlFor(element.payslip.key)}
                    download
                  >
                    <img src={downloadCircle} alt="" />
                    <Typography
                      color="#05202A"
                      fontSize="12px"
                      fontWeight="500"
                    >
                      Download
                    </Typography>
                  </StyledDownloadButton>
                </ActionContainer>
              </ActionsContainer>
            </PaySlipContainer>
          </>
        );
      })}
    </PaySlipListContainer>
  );
};

PaySlipList.propTypes = {
  defaultImage: PropTypes.string,
  paySlips: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      payslip: PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
  removeFn: PropTypes.func,
  colaborator: PropTypes.bool,
};

PaySlipList.defaultProps = {
  colaborator: false,
  defaultImage: FileDoc,
  removeFn: () => {},
};

export default PaySlipList;
