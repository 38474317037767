import { Box } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AnalizingData from '../../../../assets/analizingData.svg';
import { Typography } from '../../../../components';
import withCollaboratorSkeleton from '../../../../components/CollaboratorSkeleton';
import { useAuth } from '../../../../hooks/auth';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import { STEPS, STEP_STATUS } from '../../../../utils/enums';
import ApproveSkeleton from './ApproveSkeleton';
import DisapprovedSkeleton from './DisapprovedSkeleton';
import { RenderBox } from './styles';

const StepSix = () => {
  const [status, setStatus] = useState('underAnalysis');
  const [disapprovedSteps, setDisapprovedSteps] = useState([]);

  const { user } = useAuth();
  const dispatch = useDispatch();

  const getDisapprovedSteps = useCallback(steps => {
    const disapprovedItems = [];
    const properties = Object.keys(steps);
    properties.forEach(propName => {
      if (steps[propName].status === STEP_STATUS.DISAPPROVED) {
        let title = 'Etapa 1 - Dados Cadastrais';
        let link = 1;
        if (propName === STEPS.FAMILY_DATA) {
          title = 'Etapa 2 - RG';
          link = 2;
        }
        if (propName === STEPS.DOCUMENTS) {
          title = 'Etapa 3 - Documentos';
          link = 3;
        }

        if (propName === STEPS.ADDRESS) {
          title = 'Etapa 4 - Endereço';
          link = 4;
        }

        if (propName === STEPS.BANK_DATA) {
          title = 'Etapa 5 - Dados Bancários';
          link = 5;
        }
        disapprovedItems.push({
          title,
          reason: steps[propName].reason,
          link,
        });
      }
    });
    return disapprovedItems;
  }, []);
  const checkStepsStatus = steps => {
    const isDisapproved = Object.values(steps).some(
      step => step.status === STEP_STATUS.DISAPPROVED,
    );

    if (isDisapproved) {
      return STEP_STATUS.DISAPPROVED;
    }
    const isUnderAnalysis = Object.values(steps).some(
      step =>
        step.status === STEP_STATUS.UNDER_ANALYSIS ||
        step.status === STEP_STATUS.NOT_FILLED,
    );
    if (isUnderAnalysis) {
      return STEP_STATUS.UNDER_ANALYSIS;
    }

    return STEP_STATUS.APPROVED;
  };

  useEffect(() => {
    async function getSteps() {
      try {
        const api = AuthenticatedHttpClient();
        const { data } = await api.get(`users/steps/${user._id}`);
        const steps = data;
        const actualStatus = checkStepsStatus(steps);
        if (actualStatus === STEP_STATUS.DISAPPROVED) {
          const stepsRefused = getDisapprovedSteps(steps);
          setDisapprovedSteps(stepsRefused);
        }
        setStatus(actualStatus);
        if (actualStatus === STEP_STATUS.APPROVED) {
          localStorage.clear();
          window.location.reload();
        }
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao buscar informações'));
      }
    }

    getSteps();
  }, [dispatch, user._id, getDisapprovedSteps]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      width={1}
      overflow="auto"
      p={5}
    >
      <Box width={1} display="flex" justifyContent="center">
        <img src={AnalizingData} alt="" />
      </Box>
      <Box width={1} textAlign="center">
        <Typography
          variant="h3"
          fontWeight="bold"
          mb="20px"
          pt="30px"
          color="secondary"
          fontSize="25px"
          textalign="center"
        >
          Pronto, estamos analisando seus dados!
        </Typography>
        <Typography color="secondary" fontWeight="light">
          Fique atento caso encontrarmos algum erro, pois você será notificado e
          deverá corrigi-lo.
        </Typography>
      </Box>
      <Box width={1} pt="30px" display="flex" justifyContent="center">
        <RenderBox>
          {status === 'approved' && <ApproveSkeleton />}
          {status === 'disapproved' &&
            disapprovedSteps.map(step => (
              <DisapprovedSkeleton
                title={step.title}
                reason={step.reason}
                link={`/${step.link}`}
              />
            ))}
          {status === 'underAnalysis' && (
            <Box display="flex" alignItems="center" textAlign="center">
              <Typography
                color="secondary"
                opacity="0.4"
                fontSize="25px"
                fontWeight="bold"
              >
                Em análise...
              </Typography>
            </Box>
          )}
        </RenderBox>
      </Box>
    </Box>
  );
};

export default withCollaboratorSkeleton(StepSix);
