import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { REQUEST_STATUS } from '../../../../utils/enums';
import { StyledGrid } from '../styles';

const ChangeDayOffInfo = ({ values, reasonLabel }) => {
  return (
    <>
      <StyledGrid item xs={6}>
        <TextField
          name="dayOffDate"
          label="Data da folga"
          variant="filled"
          value={values.dayOffDate}
          disabled
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item xs={6}>
        <TextField
          name="workDate"
          label="Data da troca(que você vai trabalhar)"
          variant="filled"
          value={values.workDate}
          disabled
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item xs={6}>
        <TextField
          name="reason"
          label="Motivo"
          variant="filled"
          value={reasonLabel[values.reason]}
          disabled
          fullWidth
        />
      </StyledGrid>
      {values.status === REQUEST_STATUS.APPROVED && (
        <StyledGrid item xs={6}>
          <TextField
            name="personName"
            label="Nome da pessoa que aceitou a troca"
            variant="filled"
            value={values.personName}
            disabled
            fullWidth
          />
        </StyledGrid>
      )}

      <StyledGrid item xs={12}>
        <TextField
          name="description"
          label="Descrição"
          variant="filled"
          disabled
          value={values.description}
          fullWidth
        />
      </StyledGrid>
    </>
  );
};

ChangeDayOffInfo.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  reasonLabel: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ChangeDayOffInfo;
