import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';

export const StyledInput = styled.input`
  display: none;
`;

export const StyledButton = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.palette.third.light};
  `}

  &.MuiButtonBase-root {
    width: 197px;
    height: 45px;
    border-radius: 4px;
    opacity: 1;
  }
  &.MuiButtonBase-root:hover {
    background-color: rgba(64, 166, 255, 0.5);
  }
`;
