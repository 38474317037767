import { validateBr } from 'js-brasil';
import * as Yup from 'yup';

const addressSchema = Yup.object().shape({
  cep: Yup.string().min(8, 'CEP inválido.').required('Campo obrigatório'),
  state: Yup.string()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  city: Yup.string()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  publicPlace: Yup.string().required('Campo obrigatório'),
  number: Yup.string().required('Campo obrigatório'),
  complement: Yup.string(),
  neighborhood: Yup.string().required('Campo obrigatório'),
  proofOfAddress: Yup.boolean().required('Campo obrigatório'),
  cpfOwner: Yup.string().when('proofOfAddress', {
    is: false,
    then: Yup.string()
      .required('Campo obrigatório')
      .test('is-valid', 'CPF inválido', val => {
        try {
          return validateBr.cpf(val);
        } catch (error) {
          return false;
        }
      }),
  }),
  rgOwner: Yup.string().when('proofOfAddress', {
    is: false,
    then: Yup.string()
      .required('Campo obrigatório')
      .min(8, 'Campo obrigatório'),
  }),
});

export default addressSchema;
