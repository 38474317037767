export default function truncateString(name, num) {
  let truncated = '';
  if (!name) return truncated;
  if (name.length > num) {
    truncated = `${name.substring(0, num)}...`;
  } else {
    truncated = name;
  }
  return truncated;
}
