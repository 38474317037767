/* eslint-disable react/jsx-curly-newline */
import {
  Box,
  MenuItem,
  TextField as TextFieldMaterial,
  useTheme,
} from '@material-ui/core';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import bankOptions from '../../../../../node_modules/bancos-brasileiros/bancos.json';
import photoBankAccount from '../../../../assets/photo-bank-account.svg';
import {
  CollaboratorButton,
  Grid,
  NumericInput,
  Typography,
  Uploader,
  UserInfo,
} from '../../../../components';
import withCollaboratorSkeleton from '../../../../components/CollaboratorSkeleton';
import { useAuth } from '../../../../hooks/auth';
import useUser from '../../../../hooks/userAdmission';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import toFormData from '../../../../utils/formData';
import getFileUrlFor from '../../../../utils/getFIleUrlFor';
import bankSchema from '../../../../validations/bankData';
import { ContainerInput, StyledForm } from './styles';

const StepFive = () => {
  const [bankInitialData, setBankInitialData] = useState({
    bank: null,
    agency: '',
    typeAccount: '',
    account: '',
    digit: '',
  });
  const [selectedBankReceipt, setSelectedBankReceipt] = useState(null);
  const [fileLocation, setFileLocation] = useState(null);
  const [whatsApp, setWhatsApp] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { palette } = useTheme();
  const { userInfo, userStep } = useUser('bankData');

  useEffect(() => {
    if (userInfo) {
      setWhatsApp(userInfo.isWhatsApp);
    }

    if (userStep) {
      const {
        bank,
        agency,
        typeAccount,
        account,
        digit,
        photoBankAccountReceipt,
      } = userStep;

      if (photoBankAccountReceipt) {
        setFileLocation(getFileUrlFor(photoBankAccountReceipt.key));
      }

      const userBank = bankOptions.find(bankObject => bankObject.Name === bank);

      setBankInitialData({
        bank: userBank,
        agency,
        typeAccount,
        account,
        digit,
      });
    }
  }, [userStep, userInfo]);

  const handlesubmitBankData = useCallback(
    async (values, { setSubmitting }) => {
      try {
        if (!selectedBankReceipt && !fileLocation) {
          dispatch(
            setSnackbar(true, 'error', 'Comprovante bancário obrigatório'),
          );
          return;
        }

        if (!whatsApp) {
          dispatch(
            setSnackbar(true, 'error', 'Confirme se o número é WhatsApp'),
          );
          return;
        }

        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        const objForm =
          fileLocation && !selectedBankReceipt
            ? {
                ...values,
                bank: values.bank.Name,
                isWhatsApp: JSON.parse(whatsApp),
              }
            : {
                ...values,
                bank: values.bank.Name,
                photoBankAccountReceipt: selectedBankReceipt,
                isWhatsApp: JSON.parse(whatsApp),
              };

        const form = toFormData(objForm);

        await api.put(`users/step/bank-data/${user._id}`, form);

        dispatch(setSnackbar(true, 'success', 'Dados salvos com sucesso'));
        history.push('/6');
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao salvar os dados'));
      } finally {
        dispatch(setLoading(false));
        setSubmitting(false);
      }
    },
    [history, user, dispatch, selectedBankReceipt, whatsApp, fileLocation],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      width={1}
      overflow="auto"
      p={5}
    >
      {userInfo && <UserInfo userData={userInfo} setValue={setWhatsApp} />}
      <Formik
        initialValues={bankInitialData}
        onSubmit={handlesubmitBankData}
        validationSchema={bankSchema}
        enableReinitialize
      >
        {({ isSubmitting, touched, errors }) => (
          <StyledForm>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              jusify="space-between"
            >
              <Grid container item lg={8} spacing={2}>
                <Typography
                  component="legend"
                  fontWeight={300}
                  fontSize="18px"
                  mb="20px"
                  mt="8px"
                  width="100%"
                >
                  Dados bancários
                </Typography>
                <ContainerInput item lg={6}>
                  <Field
                    name="bank"
                    component={Autocomplete}
                    options={bankOptions}
                    getOptionLabel={option => `${option.Code} - ${option.Name}`}
                    getOptionSelected={(option, value) =>
                      option.Code === value.Code
                    }
                    fullWidth
                    renderInput={params => (
                      <TextFieldMaterial
                        {...params}
                        error={touched.bank && !!errors.bank}
                        helperText={touched.bank && errors.bank}
                        label="Banco"
                        variant="filled"
                        required
                        disabled={isSubmitting}
                      />
                    )}
                  />
                </ContainerInput>
                <ContainerInput item lg={6}>
                  <Field
                    component={TextField}
                    name="agency"
                    label="Agência"
                    variant="filled"
                    required
                    fullWidth
                    InputProps={{
                      inputComponent: NumericInput,
                    }}
                  />
                </ContainerInput>
                <ContainerInput item lg={6}>
                  <Field
                    component={TextField}
                    select
                    name="typeAccount"
                    label="Tipo de conta"
                    variant="filled"
                    required
                    fullWidth
                  >
                    <MenuItem value="">Tipo de conta</MenuItem>
                    <MenuItem value="Conta corrente">Conta corrente</MenuItem>
                    <MenuItem value="Conta salário">Conta salário</MenuItem>
                  </Field>
                </ContainerInput>
                <ContainerInput item lg={6} />
                <ContainerInput item lg={6}>
                  <Field
                    component={TextField}
                    name="account"
                    label="Conta"
                    variant="filled"
                    fullWidth
                    required
                    InputProps={{
                      inputComponent: NumericInput,
                    }}
                  />
                </ContainerInput>
                <ContainerInput item lg={6}>
                  <Field
                    component={TextField}
                    name="digit"
                    label="Dígito"
                    variant="filled"
                    required
                    fullWidth
                  />
                </ContainerInput>
              </Grid>
              <Grid container item lg={4} spacing={1}>
                <Typography component="legend" fontWeight={300} fontSize="18px">
                  Comprovante de conta bancária
                </Typography>
                <Uploader
                  title=""
                  defaultImage={fileLocation || photoBankAccount}
                  accept="image/*"
                  dropText="Adicionar foto"
                  hasStepPhoto={fileLocation}
                  size="md"
                  onFileUploaded={setSelectedBankReceipt}
                />
                <Typography
                  fontSize="14px"
                  textcolor={palette.primary.main}
                  mt="24px"
                >
                  Tipos de comprovante válidos:
                </Typography>
                <Typography fontSize="14px" fontWeight={300} mt="8px">
                  - Foto do cartão constando os dados agência e conta
                </Typography>
                <Typography fontSize="14px" fontWeight={300} mt="8px">
                  - Print dos dados bancários no aplicativo
                </Typography>
                <Typography fontSize="14px" fontWeight={300} mt="8px">
                  - comprovante de depósito constando os dados agência e conta
                </Typography>
              </Grid>
            </Grid>
            <Box width={1} display="flex" justifyContent="flex-end" my={3}>
              <CollaboratorButton
                disabled={isSubmitting}
                type="submit"
                text="FINALIZAR"
              />
            </Box>
          </StyledForm>
        )}
      </Formik>
    </Box>
  );
};

export default withCollaboratorSkeleton(StepFive);
