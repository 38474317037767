import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const IconCircle = styled.div`
  margin-top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  ${({ theme }) => css`
    background: ${theme.palette.error.dark} 0% 0% no-repeat padding-box;
  `}
  padding-left: 6px;

  img {
    width: 7px;
    padding-bottom: 2px;
  }
`;
export const StyledButton = styled(Button)`
  color: #fff;
  font-size: 12px;
  &.MuiButtonBase-root {
    width: 250px;
    height: 45px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 23px;
    opacity: 1;
    ${({ theme }) => css`
      background: ${theme.palette.secondary.main} 0% 0% no-repeat padding-box;
    `}
  }
  &.MuiButtonBase-root:hover {
    background: rgba(5, 32, 42, 0.7);
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
