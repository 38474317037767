import { subDays } from 'date-fns';
import * as Yup from 'yup';
import validateOnlyLetters from '../utils/validateOnlyLetters';

const newCollaborator = Yup.object().shape({
  fullName: Yup.string()
    .required('Campo obrigatório')
    .test(
      'Validate only letters',
      'Somente letras são aceitas',
      validateOnlyLetters,
    ),
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: Yup.string()
    .required('Campo obrigatório')
    .min(10, 'Telefone inválido')
    .max(13, 'Telefone inválido'),
  department: Yup.string().required('Campo obrigatório'),
  jobRole: Yup.string().required('Campo obrigatório'),
  hasVt: Yup.bool().required('Campo obrigatório').typeError('Campo obigatório'),
  hasVa: Yup.bool().required('Campo obrigatório').typeError('Campo obigatório'),
  hasVr: Yup.bool().required('Campo obrigatório').typeError('Campo obigatório'),
  hiringData: Yup.object().shape({
    regime: Yup.string().required('Campo obrigatório'),
    hasStandardSalary: Yup.bool()
      .required('Campo obrigatório')
      .typeError('Campo obigatório'),
    salary: Yup.mixed().test(
      'Validate number',
      'Valor invalido',
      function validate() {
        const objectToValidate = this.options.from[1].value;
        if (objectToValidate.hiringData.hasStandardSalary !== 'true') {
          return (
            objectToValidate.hiringData.salary !== 0 &&
            !!objectToValidate.hiringData.salary
          );
        }
        return true;
      },
    ),
    vt: Yup.mixed().test(
      'Validate number',
      'Valor invalido',
      function validate() {
        const objectToValidate = this.options.from[1].value;
        if (objectToValidate.hasVt === 'true') {
          return (
            objectToValidate.hiringData.vt !== 0 &&
            !!objectToValidate.hiringData.vt
          );
        }
        return true;
      },
    ),
    va: Yup.string().test(
      'Validate number',
      'Valor invalido',
      function validate() {
        const objectToValidate = this.options.from[1].value;

        if (objectToValidate.hasVa === 'true') {
          return (
            objectToValidate.hiringData.va !== 0 &&
            !!objectToValidate.hiringData.va
          );
        }
        return true;
      },
    ),
    vr: Yup.mixed().test(
      'Validate number',
      'Valor invalido',
      function validate() {
        const objectToValidate = this.options.from[1].value;
        if (objectToValidate.hasVr === 'true') {
          return (
            objectToValidate.hiringData.vr !== 0 &&
            !!objectToValidate.hiringData.vr
          );
        }
        return true;
      },
    ),
  }),
  admissionDate: Yup.date()
    .required('Campo obrigatório')
    .typeError('Data inválida'),
  limitDate: Yup.date()
    .min(
      Yup.ref('admissionDate'),
      'A data de admissão não pode ser maior que o preenchimento.',
    )
    .required('Campo obrigatório')
    .typeError('Data inválida'),
});

export default newCollaborator;
