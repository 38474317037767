import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { TabContainer, ContainerInput, StyledTextField } from '../styles';
import { Typography, TabPanel, Downloader } from '../../../components/index';

const BankData = props => {
  const { value, index, stepStatus, data, setUser, ...other } = props;
  const [bankAccountReceipt, setBankAccountReceipt] = useState({});
  const [bankData, setBankData] = useState({
    account: '',
    agency: '',
    bank: '',
    digit: '',
    typeAccount: '',
  });

  useEffect(() => {
    if (Object.keys(data).length) {
      setBankData(data);
      setBankAccountReceipt(data.photoBankAccountReceipt);
    }
  }, [data]);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography fontSize="16px" color="secondary" fontWeight="300">
              Meus dados bancários
            </Typography>
          </Grid>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="bank"
              label="Banco"
              variant="filled"
              fullWidth
              value={bankData.bank}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="agency"
              label="Agência"
              variant="filled"
              fullWidth
              value={bankData.agency}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="accountAndDigit"
              label="Conta - Dígito"
              variant="filled"
              fullWidth
              value={`${bankData.account}-${bankData.digit}`}
              disabled
            />
          </ContainerInput>
          <ContainerInput item xs={3}>
            <StyledTextField
              name="typeAccount"
              label="Tipo de conta"
              variant="filled"
              fullWidth
              value={bankData.typeAccount}
              disabled
            />
          </ContainerInput>

          <Grid item xs={12}>
            <Typography fontSize="16px" color="secondary" fontWeight="300">
              Foto do comprovante Bancário
            </Typography>
          </Grid>
          <ContainerInput item lg={4} xl={2}>
            <Downloader
              file={{
                key: bankAccountReceipt.key,
                name: bankAccountReceipt.name,
                size: bankAccountReceipt.size,
              }}
            />
          </ContainerInput>
        </Grid>
      </TabContainer>
    </TabPanel>
  );
};

BankData.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  stepStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  setUser: PropTypes.func.isRequired,
};

BankData.defaultProps = {
  data: {},
};

export default BankData;
