import { useTheme } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import backgroundLogo from '../../assets/authentication-photo.svg';
import { Grid, Typography } from '../../components';
import { useAuth } from '../../hooks/auth';
import {
  StyledButton,
  StyledGrid,
  StyledImg,
  StyledSpan,
  StyledTextField,
} from './styles';

const loginInitialValues = {
  email: '',
  password: '',
};

const AdmissionAuthentication = () => {
  const theme = useTheme();
  const { signInInvite } = useAuth();

  const authenticationSchema = Yup.object().shape({
    email: Yup.string().required('Campo obrigatório'),
    password: Yup.string().required('Senha incorreta'),
  });

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      await signInInvite(values);
      setSubmitting(false);
    },
    [signInInvite],
  );

  return (
    <StyledGrid
      height="100vh"
      container
      direction="row"
      justify="space-between"
      alignItems="stretch"
    >
      <StyledGrid
        container
        item
        lg={7}
        bgcolor={theme.palette.primary.main}
        alignItems="center"
        justify="center"
      >
        <Grid container direction="column" alignItems="center" justify="center">
          <Typography variant="h1" fontWeight="500">
            Bem-vindo!
          </Typography>
          <Grid item lg={6}>
            <Typography
              fontSize="20px"
              component="p"
              align="center"
              mt="20px"
              weight={300}
            >
              Acesso colaborador
            </Typography>
          </Grid>
          <StyledImg src={backgroundLogo} alt="" />
        </Grid>
      </StyledGrid>
      <StyledGrid
        container
        item
        lg={5}
        bgcolor={theme.palette.secondary.main}
        alignItems="center"
        justify="center"
      >
        <StyledGrid container direction="column" width="80%">
          <Typography variant="h1" fontWeight="bold">
            PROCESSO DE ADMISSÃO
          </Typography>

          <Typography
            component="p"
            mt="40px"
            mb="36px"
            weight={300}
            fontSize="18px"
          >
            <StyledSpan>Parabéns você foi aprovado </StyledSpan>
            em nosso processo seletivo e agora estamos prontos para iniciarmos a
            sua admissão!
          </Typography>

          <Formik
            initialValues={loginInitialValues}
            validationSchema={authenticationSchema}
            onSubmit={handleSubmit}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <Typography mt="20px" fontSize="16px" opacity="0.5">
                  Enviamos junto ao e-mail sua primeira senha de acesso, por
                  favor informe-a abaixo para darmos continuidade em sua
                  admissão:
                </Typography>
                <br />
                <Field
                  component={StyledTextField}
                  name="email"
                  type="email"
                  label="E-mail"
                  fullWidth
                  variant="filled"
                  color="primary"
                />
                <Field
                  component={StyledTextField}
                  name="password"
                  type="password"
                  label="Senha"
                  fullWidth
                  variant="filled"
                  color="primary"
                />
                <br />
                <StyledButton
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  ACESSAR
                </StyledButton>
              </Form>
            )}
          </Formik>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default AdmissionAuthentication;
