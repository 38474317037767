import {
  Checkbox,
  DialogContent,
  Grid,
  IconButton,
  Menu,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Delete from '../../../../assets/deleteIcon.svg';
import EditIcon from '../../../../assets/editIcon.svg';
import {
  DeleteModal,
  EmptyState,
  MassImportModal,
  Tooltip,
} from '../../../../components';
import { useAuth } from '../../../../hooks/auth';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import { HOLIDAY_MODELS, HOLIDAY_TYPES } from '../../../../utils/enums';
import AddHolidayModal from '../../Modals/AddHolidayModal';
import {
  StyledIconButtonBlue,
  StyledIconButtonRed,
  StyledMenuItem,
  StyledTableCell,
} from './styles';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome feriado',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Tipo',
  },
  {
    id: 'holidayModel',
    numeric: false,
    disablePadding: false,
    label: 'Modelo',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Data ou período',
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Ação</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTable = ({
  textFilter,
  modelFilter,
  typeFilter,
  newHolidayAdded,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openAddHolidayModal, setOpenAddHolidayModal] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [holidaysCount, setHolidaysCount] = useState(0);
  const [forceGetHolidays, setForceGetHolidays] = useState(false);
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [isImportModalOpen, setImportModalOpen] = useState(false);

  const handleImportModalOpen = useCallback(() => {
    setAnchorEl(null);
    setImportModalOpen(true);
  }, []);
  const handleImportModalClose = useCallback(
    () => setImportModalOpen(false),
    [],
  );

  useEffect(() => {
    async function getHolidays() {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();
        if (modelFilter.trim() || typeFilter.trim()) {
          setPage(0);
        }
        const { data } = await api.get(`holiday/paginate/${user.company._id}`, {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            order,
            orderBy,
            text: textFilter,
            modelFilter: modelFilter.trim(),
            typeFilter: typeFilter.trim(),
          },
        });

        const responseData = data.docs.map(holidayInfo => ({
          _id: holidayInfo._id,
          name: holidayInfo.name,
          holidayModel: holidayInfo.holidayModel,
          type: holidayInfo.type,
          period: holidayInfo.period,
          date: holidayInfo.date,
        }));
        setHolidaysCount(data.total);
        setHolidays(responseData);
      } catch (e) {
        dispatch(
          setSnackbar(true, 'error', 'Não foi possivel obter os feriados!'),
        );
      } finally {
        dispatch(setLoading(false));
      }
    }

    getHolidays();
  }, [
    user,
    order,
    orderBy,
    page,
    rowsPerPage,
    textFilter,
    dispatch,
    typeFilter,
    modelFilter,
    forceGetHolidays,
    newHolidayAdded,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = holidays.map(n => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = id => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = newPage => {
    setPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = holidayName => selected.indexOf(holidayName) !== -1;

  const handleEditHoliday = holiday => {
    setOpenAddHolidayModal(true);
    if (holiday.date) {
      setSelectedHoliday({
        ...holiday,
        date: new Date(holiday.date),
        period: { start: null, end: null },
      });
    } else {
      setSelectedHoliday({
        ...holiday,
        period: {
          ...holiday.period,
          start: new Date(holiday.period.start),
          end: new Date(holiday.period.end),
        },
        date: null,
      });
    }
  };
  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenModalDeleteHoliday = holiday => {
    setOpenDeleteModal(true);
    setSelectedHoliday(holiday);
  };
  const handleDeleteHoliday = useCallback(
    async id => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        await api.delete(`/holiday/${id}`);

        dispatch(setSnackbar(true, 'success', 'Feriado removido com sucesso'));
        setForceGetHolidays(prevState => !prevState);
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao remover feriado'));
      } finally {
        dispatch(setLoading(false));
        setOpenDeleteModal(false);
      }
    },
    [dispatch],
  );

  const handleDeleteSelectedsHoliday = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const api = AuthenticatedHttpClient();

      await api.delete(`/holiday`, {
        data: { selectedHolidays: selected },
      });

      dispatch(
        setSnackbar(true, 'success', 'Feriado(s) removido(s) com sucesso'),
      );
      setForceGetHolidays(prevState => !prevState);
    } catch (error) {
      dispatch(setSnackbar(true, 'error', 'Falha ao remover feriado(s)'));
    } finally {
      dispatch(setLoading(false));
      handleCloseMenu();
    }
  }, [dispatch, setForceGetHolidays, selected]);
  const typeLabel = {
    [HOLIDAY_TYPES.MUNICIPAL]: 'Municipal',
    [HOLIDAY_TYPES.STATE]: 'Estadual',
    [HOLIDAY_TYPES.NATIONAL]: 'Nacional',
  };

  const modelLabel = {
    [HOLIDAY_MODELS.DATE]: 'Data',
    [HOLIDAY_MODELS.PERIOD]: 'Periodo',
  };

  const MAX_STRING_LENGTH = 35;

  return !holidaysCount ? (
    <EmptyState description="Nenhum feriado encontrado!" />
  ) : (
    <>
      <Paper elevation={0}>
        <Grid container alignItems="center" justify="space-between">
          <Typography variant="h3">RELAÇÃO DE FERIADOS CADASTRADAS</Typography>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClickMenu}
          >
            <Settings />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <StyledMenuItem onClick={handleDeleteSelectedsHoliday}>
              EXCLUIR SELECIONADOS
            </StyledMenuItem>
            <StyledMenuItem onClick={handleImportModalOpen}>
              IMPORTAR EM MASSA
            </StyledMenuItem>
            <Modal open={isImportModalOpen}>
              <MassImportModal
                handleClose={handleImportModalClose}
                route="/holiday/upload-many"
                importType="Feriados"
                modelLocation="https://siricascudo-files.s3.amazonaws.com/example-sheets/feriados.xlsx"
                onSuccess={() => setForceGetHolidays(state => !state)}
              />
            </Modal>
          </Menu>
        </Grid>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={holidays.length}
            />
            <TableBody>
              {holidays.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                  >
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        onClick={event => handleClick(event, row._id)}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" id={labelId} scope="row">
                      <Tooltip
                        fullText={row.name}
                        maxStringLength={MAX_STRING_LENGTH}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {typeLabel[row.type]}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {modelLabel[row.holidayModel]}
                    </StyledTableCell>
                    {row.date && (
                      <StyledTableCell align="left">
                        {format(new Date(row.date), 'dd/MM')}
                      </StyledTableCell>
                    )}
                    {row.period && (
                      <StyledTableCell align="left">
                        {format(new Date(row.period.start), 'dd/MM')} -{' '}
                        {format(new Date(row.period.end), 'dd/MM')}
                      </StyledTableCell>
                    )}

                    <StyledTableCell align="left">
                      <StyledIconButtonBlue
                        onClick={() => handleEditHoliday(row)}
                      >
                        <img src={EditIcon} alt="" />
                      </StyledIconButtonBlue>
                      <StyledIconButtonRed
                        onClick={() => handleOpenModalDeleteHoliday(row)}
                      >
                        <img src={Delete} alt="" />
                      </StyledIconButtonRed>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={holidaysCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Modal
        open={openAddHolidayModal}
        onClose={() => setOpenAddHolidayModal(false)}
      >
        <DialogContent>
          <AddHolidayModal
            handleClose={() => setOpenAddHolidayModal(false)}
            isUpdate
            formInitialValues={selectedHoliday}
            setForceGetHolidays={setForceGetHolidays}
          />
        </DialogContent>
      </Modal>
      <DeleteModal
        title="Remover Feriado"
        description={`Tem certeza que deseja remover o feriado ${selectedHoliday.name}?`}
        modalStatus={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleDelete={() => handleDeleteHoliday(selectedHoliday._id)}
      />
    </>
  );
};

EnhancedTable.propTypes = {
  textFilter: PropTypes.string,
  modelFilter: PropTypes.string,
  typeFilter: PropTypes.string,
  newHolidayAdded: PropTypes.bool,
};

EnhancedTable.defaultProps = {
  textFilter: '',
  modelFilter: '',
  typeFilter: '',
  newHolidayAdded: false,
};

export default EnhancedTable;
