import styled, { css } from 'styled-components';
import { ButtonBase } from '@material-ui/core';

export const StyledButton = styled(ButtonBase)`
  width: 250px;
  color: white;
  font-weight: 700;
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 45px;
  border-radius: 23px;
  box-shadow: 0px 3px 6px #00000029;

  ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt};
    `};
  ${props => css`
    margin: ${props.ma};
    margin-left: ${props.ml};
    margin-right: ${props.mr};
    margin-bottom: ${props.mb};
    margin-top: ${props.mt};

    padding: ${props.pa};
    padding-top: ${props.pt};
    padding-left: ${props.pl};
    padding-right: ${props.pr};
    padding-bottom: ${props.pb};
  `}
`;
