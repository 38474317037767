import { Box } from '@material-ui/core';
import { endOfMonth, startOfMonth } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TabPanel } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { setLoading } from '../../../redux/ducks/loading';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import { FilterText, TabContainer } from '../styles';
import TableBankOfHours from '../TableBankOfHours';
import TableFilter from '../TableFilter';

const BankOfHours = props => {
  const { value, index, forceGetCollaboratorsCompTime, ...other } = props;

  const [filters, setFilters] = useState({
    department: '',
    jobRole: '',
    limit: 5,
    page: 1,
    order: 'asc',
    orderBy: 'fullName',
    periodStartDate: startOfMonth(new Date()),
    periodEndDate: endOfMonth(new Date()),
    text: '',
  });

  const [collaboratorsCompTime, setCollaboratorsCompTime] = useState({
    docs: [],
    page: 1,
    limit: 5,
    pages: 1,
    total: 0,
  });

  const { user } = useAuth();

  const dispatch = useDispatch();
  const getCollaboratorsCompTime = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const api = AuthenticatedHttpClient();
      const { data } = await api.get(`/comp-time/users/${user.company._id}`, {
        params: filters,
      });
      setCollaboratorsCompTime(data);
    } catch (error) {
      if (error.response?.data?.message) {
        dispatch(setSnackbar(true, 'error', error.response.data.message));
      } else {
        dispatch(setSnackbar(true, 'error', 'Falha ao buscar dados'));
      }
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch, filters, user.company._id]);

  useEffect(() => {
    getCollaboratorsCompTime();
  }, [getCollaboratorsCompTime, forceGetCollaboratorsCompTime]);

  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleClick = useCallback(event => {
  //   setAnchorEl(event.currentTarget);
  // }, []);

  // const handleClose = useCallback(() => {
  //   setAnchorEl(null);
  // }, []);

  return (
    <TabPanel value={value} index={index} {...other}>
      <TabContainer>
        <TableFilter setFilters={setFilters} filters={filters} />

        <Box mt={4}>
          <FilterText>Colaboradores</FilterText>
          <TableBankOfHours
            collaboratorsCompTime={collaboratorsCompTime}
            setFilters={setFilters}
            getCollaboratorsCompTime={getCollaboratorsCompTime}
          />
        </Box>
      </TabContainer>
    </TabPanel>
  );
};

BankOfHours.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  forceGetCollaboratorsCompTime: PropTypes.bool.isRequired,
};

export default BankOfHours;
