import * as Yup from 'yup';
import { ACTIVITY_PERIOD } from '../utils/enums';

const dayOffSchema = Yup.object().shape({
  period: Yup.string().required('Campo obrigatório'),
  date: Yup.date().when('period', {
    is: ACTIVITY_PERIOD.ONE_DAY,
    then: Yup.date().typeError('Data inválida').required('Campo obrigatório'),
    otherwise: Yup.date().nullable(),
  }),
  startDate: Yup.date().when('period', {
    is: ACTIVITY_PERIOD.MORE_THAN_ONE_DAY,
    then: Yup.date().typeError('Data inválida').required('Campo obrigatório'),
    otherwise: Yup.date().nullable(),
  }),
  finalDate: Yup.date().when('period', {
    is: ACTIVITY_PERIOD.MORE_THAN_ONE_DAY,
    then: Yup.date()
      .min(Yup.ref('startDate'), 'Informe uma data posterior a data de início.')
      .typeError('Data inválida')
      .required('Campo obrigatório'),
    otherwise: Yup.date().nullable(),
  }),
  numberOfDays: Yup.number()
    .required('Informe a quantidade de dias')
    .min(1, 'Quantidade inválida')
    .max(999, 'Quantidade inválida'),
});

export default dayOffSchema;
