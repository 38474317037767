import styled, { css } from 'styled-components';

export const StyledStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  font-weight: bold;
  ${({ status, theme }) => {
    if (status === 'approved')
      return css`
        color: white;
        background-color: ${theme.palette.primary.main};
      `;
    if (status === 'disapproved')
      return css`
        color: white;
        background: ${theme.palette.error.main};
      `;
    return css`
      border: 1px solid ${theme.palette.primary.main};
      color: ${theme.palette.primary.main};
    `;
  }}
`;
export const StyledConector = styled.div`
  width: 30px;
  border: 1px solid #f5f5f5;
  @media screen and (min-width: 1600px) {
    width: 60px;
  }
`;
export const StyledStatus = styled.span`
  font-size: 8px;
  margin-left: 2px;
  font-weight: bold;
  ${({ status, theme }) => {
    if (status === 'approved')
      return css`
        color: ${theme.palette.primary.main};
      `;
    if (status === 'disapproved')
      return css`
        color: ${theme.palette.error.main};
      `;
    return css`
      opacity: 0.2;
    `;
  }}
`;
