import Styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  progress{
    height: ${props => props.$height};
    width: ${props => props.$width};
    margin: 0 10px;
  }

  progress[value] {
    -webkit-appearance: none;
    appearance: none;
  }

  progress[value]::-webkit-progress-bar {
    border-radius: 9px;
    background-color: ${props => transparentize(0.8, props.$color)};
    opacity: 1;
  }

  progress[value]::-webkit-progress-value {
    border-radius: 9px;
    background-color: ${props => props.$color};
`;
