import { addDays, differenceInMinutes, isValid, set } from 'date-fns';
import { SCALE_TYPES } from './enums';

const scaleTypesForMessage = {
  '5x2': 'Padrão semanal - 5x2',
  '6x1': 'Padrão semanal - 6x1',
  '5x1': 'Escala - 5x1',
  CALL_CENTER_30: 'Call Center - 30',
  CALL_CENTER_36: 'Call Center - 36',
  '12/36': 'Escala - 12/36',
  '24/48': 'Escala - 24/48',
};

const validateTotalHours = values => {
  const { scaleType, totalHours } = values;

  const scaleLimit = {
    '5x2': 44,
    '6x1': 44,
    '5x1': 44,
    CALL_CENTER_30: 30,
    CALL_CENTER_36: 36,
  };

  const [totalHoursWithoutMinutes, totalMinutes] = totalHours.split(':');

  let isValidJourney = true;
  if (
    scaleType !== SCALE_TYPES['12/36'] &&
    scaleType !== SCALE_TYPES['24/48']
  ) {
    if (Number(totalHoursWithoutMinutes) === scaleLimit[scaleType]) {
      isValidJourney = Number(totalMinutes) === 0;
    } else {
      isValidJourney =
        Number(totalHoursWithoutMinutes) < scaleLimit[scaleType] &&
        Number(totalHoursWithoutMinutes) !== 0;
    }
  }

  return {
    valid: isValidJourney,
    message: isValidJourney
      ? ''
      : `A escala selecionada (${scaleTypesForMessage[scaleType]}) possui um total de horas semanais maior do que o permitido para essa escala (${scaleLimit[scaleType]}) ou há inconsistência no horario`,
  };
};

const calculateTimeDifferenceInMinutes = (start, end, mainBreak) => {
  if (isValid(start) && isValid(end)) {
    let endDay = end;
    if (
      set(end, { seconds: 0, milliseconds: 0 }).getTime() <
      set(start, { seconds: 0, milliseconds: 0 }).getTime()
    ) {
      endDay = addDays(end, 1);
    }

    const absoluteTimeDifferenceInMinutes = differenceInMinutes(
      new Date(endDay).setSeconds(0, 0),
      new Date(start).setSeconds(0, 0),
    );

    const timeDifferenceExcludingMainBreak =
      absoluteTimeDifferenceInMinutes - Number(mainBreak);
    if (timeDifferenceExcludingMainBreak < 0) {
      return 0;
    }
    return timeDifferenceExcludingMainBreak;
  }
  return 0;
};

const validateHoursPerDay = values => {
  const {
    scaleType,
    standardWeekScale: {
      isFriday,
      isMonday,
      isSunday,
      isThursday,
      isTuesday,
      isWednesday,
      isSaturday,
      monday,
      friday,
      saturday,
      sunday,
      thursday,
      tuesday,
      wednesday,
      isEqualTime,
    },
  } = values;

  let isValidJourney = true;
  const scaleLimit = {
    '5x2': 528,
    '6x1': 440,
    '5x1': 440,
    CALL_CENTER_30: 360,
    CALL_CENTER_36: 360,
  };

  if (
    scaleType === SCALE_TYPES['5x2'] ||
    scaleType === SCALE_TYPES['6x1'] ||
    scaleType === SCALE_TYPES.CALL_CENTER_30 ||
    scaleType === SCALE_TYPES.CALL_CENTER_36
  ) {
    if (!isEqualTime) {
      if (isMonday && monday && isValidJourney) {
        const allMinutes = calculateTimeDifferenceInMinutes(
          monday.enterTime,
          monday.exitTime,
          monday.mainBreak,
        );

        isValidJourney =
          allMinutes <= scaleLimit[scaleType] && allMinutes !== 0;
      }
      if (isTuesday && tuesday && isValidJourney) {
        const allMinutes = calculateTimeDifferenceInMinutes(
          tuesday.enterTime,
          tuesday.exitTime,
          tuesday.mainBreak,
        );
        isValidJourney =
          allMinutes <= scaleLimit[scaleType] && allMinutes !== 0;
      }
      if (isWednesday && wednesday && isValidJourney) {
        const allMinutes = calculateTimeDifferenceInMinutes(
          wednesday.enterTime,
          wednesday.exitTime,
          wednesday.mainBreak,
        );
        isValidJourney =
          allMinutes <= scaleLimit[scaleType] && allMinutes !== 0;
      }
      if (isThursday && thursday && isValidJourney) {
        const allMinutes = calculateTimeDifferenceInMinutes(
          thursday.enterTime,
          thursday.exitTime,
          thursday.mainBreak,
        );

        isValidJourney =
          allMinutes <= scaleLimit[scaleType] && allMinutes !== 0;
      }
      if (isFriday && friday && isValidJourney) {
        const allMinutes = calculateTimeDifferenceInMinutes(
          friday.enterTime,
          friday.exitTime,
          friday.mainBreak,
        );
        isValidJourney =
          allMinutes <= scaleLimit[scaleType] && allMinutes !== 0;
      }
      if (isSaturday && saturday && isValidJourney) {
        const allMinutes = calculateTimeDifferenceInMinutes(
          saturday.enterTime,
          saturday.exitTime,
          saturday.mainBreak,
        );
        isValidJourney =
          allMinutes <= scaleLimit[scaleType] && allMinutes !== 0;
      }
      if (isSunday && sunday && isValidJourney) {
        const allMinutes = calculateTimeDifferenceInMinutes(
          sunday.enterTime,
          sunday.exitTime,
          sunday.mainBreak,
        );
        isValidJourney =
          allMinutes <= scaleLimit[scaleType] && allMinutes !== 0;
      }
    }
  }
  return {
    valid: isValidJourney,
    message: isValidJourney
      ? ''
      : 'A escala possui inconsistência de horario em algum(ns) dia(s) ',
  };
};

const validateDaysQuantity = values => {
  const {
    scaleType,
    standardWeekScale: {
      isFriday,
      isMonday,
      isSunday,
      isThursday,
      isTuesday,
      isWednesday,
      isSaturday,
    },
  } = values;

  let isValidJourney = true;
  let message = '';
  if (
    scaleType === SCALE_TYPES['5x2'] ||
    scaleType === SCALE_TYPES['6x1'] ||
    scaleType === SCALE_TYPES.CALL_CENTER_30 ||
    scaleType === SCALE_TYPES.CALL_CENTER_36
  ) {
    const allDays = {
      isMonday,
      isTuesday,
      isWednesday,
      isThursday,
      isFriday,
      isSaturday,
      isSunday,
    };
    const daysWorked = Object.keys(allDays).filter(
      property => allDays[property],
    );

    const countDaysWorked = daysWorked.length;

    const scaleLimit = {
      '5x2': 5,
      '6x1': 6,
      CALL_CENTER_30: 5,
      CALL_CENTER_36: 6,
    };

    isValidJourney = countDaysWorked === scaleLimit[scaleType];
    message = isValidJourney
      ? ''
      : `O número de dias selecionado(${countDaysWorked}) não é igual ao número de dias necessários na escala ${scaleTypesForMessage[scaleType]} que são ${scaleLimit[scaleType]} dias`;
  }
  return {
    valid: isValidJourney,
    message,
  };
};

const validateJourney = values => {
  const validatedTotalHours = validateTotalHours(values);
  if (!validatedTotalHours.valid) {
    return validatedTotalHours;
  }
  const validatedHoursPerDay = validateHoursPerDay(values);
  if (!validatedHoursPerDay.valid) {
    return validatedHoursPerDay;
  }
  const validatedDaysSelected = validateDaysQuantity(values);
  return validatedDaysSelected;
};

export default validateJourney;
