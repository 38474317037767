import styled, { css } from 'styled-components';
import { Typography, Button, Grid } from '@material-ui/core';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { Link } from 'react-router-dom';

export const StyledImg = styled.img`
  width: 100%;
  margin-top: 80px;
  max-width: 550px;
  @media screen and (min-width: 1600px) {
    max-width: 750px;
  }
`;

export const StyledGrid = styled(Grid)`
  width: 100%;

  ${props => css`
    width: ${props.width};
    height: ${props.height};
    background-color: ${props.bgcolor};
  `}
  color: #fff;
`;

export const StyledButton = styled(Button)`
  width: 250px;
  height: 45px;
  color: #fff;
  margin-top: 36px;
  border-radius: 23px;
`;

export const StyledTypography = styled(Typography)`
  color: white;
  ${({ mt, weight }) => css`
    margin-top: ${mt};
    font-weight: ${weight};
  `}
`;

export const StyledCheckbox = styled(CheckboxWithLabel)`
  color: white;
  & + span {
    font-weight: 300;
  }
`;

export const StyledTextField = styled(TextField)`
  height: 80px;

  ${({ mt }) => css`
    margin-top: ${mt};
  `}

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    background-color: #1e3037;
    border-radius: 0;
    transition: background-color 5000s ease-in-out 0s;
  }

  input {
    color: white;
    background-color: #1e3037;
  }

  .MuiInputLabel-root {
    color: white;
  }

  ${({ theme }) => css`
    & .Mui-error {
      color: ${theme.palette.error.main};
    }
    & .Mui-focused {
      color: ${theme.palette.primary.main};
    }
  `}
`;

export const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`;

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`;
