import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { ModalSkeleton } from '../../../../components';
import { ActionsBar, ContainerModal, StyledGrid } from './styles';

const ConfirmChangeModal = ({ title, handleClose, handleConfirmChange }) => (
  <ModalSkeleton
    title={title}
    handleClickOnClose={handleClose}
    maxWidth="700px"
  >
    <ContainerModal>
      <p>
        Há pausas fora do intervalo de horário informado, portanto ao alterar o
        horário da <br /> escala essas pausas serão excluídas, deseja continuar
        assim mesmo?
      </p>

      <ActionsBar container alignItems="center" justify="flex-end">
        <StyledGrid mt="32px">
          <Button color="secondary" onClick={handleClose} size="small">
            CANCELAR
          </Button>
          <Button onClick={handleConfirmChange} color="secondary" size="small">
            CONFIRMAR
          </Button>
        </StyledGrid>
      </ActionsBar>
    </ContainerModal>
  </ModalSkeleton>
);

ConfirmChangeModal.propTypes = {
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirmChange: PropTypes.func.isRequired,
};

export default ConfirmChangeModal;
