import DateFnsUtils from '@date-io/date-fns';
import { Button } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { set } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Family from '../../../../assets/family-rafiki.svg';
import {
  Grid,
  ModalSkeleton,
  Typography,
  Uploader,
} from '../../../../components';
import { useAuth } from '../../../../hooks/auth';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import { ACTIVITY_PERIOD, STATUS_ACTIVE } from '../../../../utils/enums';
import toFormData from '../../../../utils/formData';
import { StyledGridButton } from '../styles';

const MaternityLeaveModal = ({ handleClickOnClose, setUser }) => {
  const [registeredMaternityLeave, setRegisteredMaternityLeave] = useState(
    false,
  );
  const [document, setDocument] = useState(null);

  const dispatch = useDispatch();
  const { userId: collaboratorId } = useParams();
  const { user } = useAuth();

  const maternityLeaveSchema = Yup.object().shape({
    startDate: Yup.date()
      .required('Campo obrigatório')
      .typeError('Data inválida'),
    finalDate: Yup.date()
      .min(Yup.ref('startDate'), 'Informe uma data posterior a data de início.')
      .required('Campo obrigatório')
      .typeError('Data inválida'),
  });

  const formInitialValues = {
    startDate: null,
    finalDate: null,
  };

  const handleMaternityLeave = useCallback(
    async (values, { setSubmitting }) => {
      try {
        dispatch(setLoading(true));

        if (!document) {
          dispatch(setSnackbar(true, 'error', 'Documento obrigatório'));
          return;
        }

        const { startDate, finalDate } = values;
        const formattedDateStart = set(startDate, {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        });

        const api = AuthenticatedHttpClient();

        const maternityLeaveDetails = {
          startDate: formattedDateStart,
          user: collaboratorId,
          company: user.company._id,
          type: STATUS_ACTIVE.MATERNITY_LEAVE,
          document,
          period: ACTIVITY_PERIOD.MORE_THAN_ONE_DAY,
        };

        if (finalDate) {
          const formattedDateEnd = set(finalDate, {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          });
          if (formattedDateStart.getTime() === formattedDateEnd.getTime()) {
            dispatch(
              setSnackbar(
                true,
                'error',
                'A data de inicio e fim não podem ser iguais',
              ),
            );
            return;
          }
          maternityLeaveDetails.finalDate = formattedDateEnd;
        }

        const form = toFormData(maternityLeaveDetails);

        const response = await api.post('activity', form);

        setUser(response.data);
        setRegisteredMaternityLeave(true);
        dispatch(
          setSnackbar(
            true,
            'success',
            'Licença Maternidade registrada com sucesso',
          ),
        );
      } catch (error) {
        dispatch(setSnackbar(true, 'error', error?.response?.data?.message));
      } finally {
        setSubmitting(false);
        dispatch(setLoading(false));
      }
    },
    [collaboratorId, dispatch, document, setUser, user.company._id],
  );

  return (
    <ModalSkeleton
      title={
        registeredMaternityLeave
          ? 'LICENÇA MATERNIDADE REGISTRADA'
          : 'REGISTRAR LICENÇA MATERNIDADE'
      }
      handleClickOnClose={handleClickOnClose}
      maxWidth="850px"
    >
      {registeredMaternityLeave ? (
        <Grid container pa="18px">
          <Grid>
            <Grid container justify="center" alignItems="space" spacing={5}>
              <Grid mt="50px">
                <Grid item leg={6}>
                  <img src={Family} alt="" />
                </Grid>
              </Grid>
              <Grid item lg={6}>
                <Typography
                  mt="50px"
                  variant="h3"
                  fontWeight="bold"
                  align="left"
                >
                  LICENÇA MATERNIDADE REGISTRADA COM SUCESSO!
                </Typography>
                <Typography opacity="0.4" mt="20px" fontSize="20px">
                  Informe ao colaborador sobre <br /> a sua licença!
                </Typography>
              </Grid>
            </Grid>
            <StyledGridButton container justify="flex-end" alignItems="center">
              <Grid item lg={2}>
                <Button onClick={() => handleClickOnClose(false)}>
                  <Typography variant="h3" fontWeight="bold">
                    CONFIRMAR
                  </Typography>
                </Button>
              </Grid>
            </StyledGridButton>
          </Grid>
        </Grid>
      ) : (
        <Grid pa="18px" mt="24px">
          <Grid
            container
            justify="center"
            alignContent="space-between"
            spacing={3}
          />
          <Formik
            initialValues={formInitialValues}
            validationSchema={maternityLeaveSchema}
            onSubmit={handleMaternityLeave}
          >
            {({ isSubmitting }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <Form>
                  <Grid
                    container
                    justify="flex-start"
                    alignContent="center"
                    spacing={3}
                  >
                    <Grid item lg={6}>
                      <Field
                        component={KeyboardDatePicker}
                        name="startDate"
                        label="Data início da licença maternidade"
                        invalidDateMessage="Data inválida"
                        inputVariant="filled"
                        cancelLabel="CANCELAR"
                        format="dd/MM/yyyy"
                        fullWidth
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <Field
                        component={KeyboardDatePicker}
                        name="finalDate"
                        label="Data término da licença maternidade"
                        invalidDateMessage="Data inválida"
                        inputVariant="filled"
                        cancelLabel="CANCELAR"
                        format="dd/MM/yyyy"
                        fullWidth
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <Typography fontSize="14px">
                        Anexar atestado da licença
                      </Typography>
                      <Uploader
                        accept="application/pdf"
                        dropText="Anexar"
                        onFileUploaded={setDocument}
                      />
                    </Grid>
                  </Grid>

                  <StyledGridButton
                    container
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button onClick={() => handleClickOnClose(false)}>
                        <Typography opacity="0.4" fontWeight="bold">
                          CANCELAR
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button type="submit" disabled={isSubmitting}>
                        <Typography fontWeight="bold">
                          CONFIRMAR LICENÇA
                        </Typography>
                      </Button>
                    </Grid>
                  </StyledGridButton>
                </Form>
              </MuiPickersUtilsProvider>
            )}
          </Formik>
        </Grid>
      )}
    </ModalSkeleton>
  );
};

MaternityLeaveModal.propTypes = {
  handleClickOnClose: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
};

export default MaternityLeaveModal;
