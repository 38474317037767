import {
  DialogContent,
  IconButton,
  Menu,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Delete from '../../../assets/deleteIcon.svg';
import Edit from '../../../assets/editIcon.svg';
import {
  DeleteModal,
  EmptyState,
  Grid,
  MassImportModal,
  Tooltip,
} from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { setSnackbar } from '../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../services/api';
import formatMoney from '../../../utils/formatMoney';
import AddCareerModal from '../AddCareerModal';
import {
  StyledIconButtonBlue,
  StyledIconButtonRed,
  StyledMenuItem,
  StyledTableCell,
} from './styles';

const headCells = [
  {
    id: 'code',
    numeric: false,
    disablePadding: true,
    label: 'Código',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Cargo',
  },
  {
    id: 'baseSalary',
    numeric: false,
    disablePadding: false,
    label: 'Base salarial',
  },
  {
    id: 'totalEmployees',
    numeric: false,
    disablePadding: false,
    label: 'Total de colaboradores',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = useCallback(
    property => event => {
      onRequestSort(event, property);
    },
    [onRequestSort],
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Ação</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTable = ({ textFilter, newJobRole }) => {
  const [jobRoles, setJobRoles] = useState([]);
  const [jobRolesCount, setJobRolesCount] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCareerModal, setOpenCareerModal] = useState(false);
  const [jobRoleSelected, setJobRoleSelected] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [isImportModalOpen, setImportModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const handleImportModalOpen = useCallback(() => {
    setAnchorEl(null);
    setImportModalOpen(true);
  }, []);
  const handleImportModalClose = useCallback(
    () => setImportModalOpen(false),
    [],
  );

  const handleClickMenu = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    const api = AuthenticatedHttpClient();

    async function getJobRoles() {
      const { data } = await api.get(`job-role/paginate/${user.company._id}`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          order,
          orderBy,
          text: textFilter,
        },
      });

      const responseData = data.docs.map(jobRoleInfo => ({
        _id: jobRoleInfo._id,
        code: jobRoleInfo.code,
        name: jobRoleInfo.name,
        baseSalary: jobRoleInfo.baseSalary,
        totalEmployees: jobRoleInfo.totalEmployees,
        toxicologicalExam: jobRoleInfo.toxicologicalExam,
      }));
      setJobRolesCount(data.total);
      setJobRoles(responseData);
    }

    getJobRoles();
  }, [
    user,
    order,
    orderBy,
    page,
    rowsPerPage,
    textFilter,
    newJobRole,
    isUpdated,
  ]);

  const handleRemoveJobRole = useCallback(
    async id => {
      try {
        const api = AuthenticatedHttpClient();

        await api.delete(`/job-role/${id}`);

        setJobRoles(prevState =>
          prevState.filter(jobRoleInfo => jobRoleInfo._id !== id),
        );
        setJobRolesCount(prevState => prevState - 1);

        dispatch(
          setSnackbar(true, 'success', 'Operação realizada com sucesso'),
        );
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao remover cargo'));
      } finally {
        setOpenDeleteModal(false);
      }
    },
    [dispatch],
  );

  const handleClickRemove = useCallback(jobRole => {
    setOpenDeleteModal(true);
    setJobRoleSelected(jobRole);
  }, []);

  const handleClickEdit = useCallback(jobRole => {
    setOpenCareerModal(true);
    setJobRoleSelected(jobRole);
  }, []);

  const handleRequestSort = useCallback(
    (_, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [orderBy, order],
  );

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const MAX_STRING_LENGTH = 35;

  return (
    <>
      {!jobRolesCount ? (
        <EmptyState description="Nenhum cargo encontrado!" />
      ) : (
        <>
          <Paper elevation={0}>
            <Grid container alignItems="center" justify="space-between">
              <Typography variant="h3">RELAÇÃO DE CARGOS</Typography>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClickMenu}
              >
                <Settings />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <StyledMenuItem onClick={handleImportModalOpen}>
                  IMPORTAR EM MASSA
                </StyledMenuItem>
              </Menu>
            </Grid>
            <Modal open={isImportModalOpen}>
              <MassImportModal
                handleClose={handleImportModalClose}
                route="/job-role/upload-many"
                importType="Cargo"
                modelLocation="https://siricascudo-files.s3.amazonaws.com/example-sheets/cargo.xlsx"
                onSuccess={() => setIsUpdated(state => !state)}
              />
            </Modal>
            <TableContainer>
              <Table size="small">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={jobRolesCount}
                />
                <TableBody>
                  {jobRoles.map(row => (
                    <TableRow hover tabIndex={-1} key={row.code}>
                      <StyledTableCell component="th" scope="row">
                        {row.code}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Tooltip
                          fullText={row.name}
                          maxStringLength={MAX_STRING_LENGTH}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {formatMoney(row.baseSalary)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.totalEmployees}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        <StyledIconButtonBlue
                          onClick={() => handleClickEdit(row)}
                        >
                          <img src={Edit} alt="" />
                        </StyledIconButtonBlue>
                        <StyledIconButtonRed
                          onClick={() => handleClickRemove(row)}
                        >
                          <img src={Delete} alt="" />
                        </StyledIconButtonRed>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={jobRolesCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <Modal
            open={openCareerModal}
            onClose={() => setOpenCareerModal(false)}
            aria-labelledby="add-collaborator-title"
            aria-describedby="simple-modal-description"
          >
            <DialogContent>
              <AddCareerModal
                handleClickOnClose={() => setOpenCareerModal(false)}
                handleAddJobRole={() => {}}
                isUpdate
                formInitialValues={{
                  ...jobRoleSelected,
                  toxicologicalExam: jobRoleSelected.toxicologicalExam,
                }}
                setIsUpdated={setIsUpdated}
              />
            </DialogContent>
          </Modal>
          <DeleteModal
            title="Remover Cargo"
            description={`Tem certeza que deseja remover o departamento ${jobRoleSelected.name}?`}
            modalStatus={openDeleteModal}
            handleClose={() => setOpenDeleteModal(false)}
            handleDelete={() => handleRemoveJobRole(jobRoleSelected._id)}
          />
        </>
      )}
    </>
  );
};

EnhancedTable.propTypes = {
  textFilter: PropTypes.string,
  newJobRole: PropTypes.string,
};

EnhancedTable.defaultProps = {
  textFilter: '',
  newJobRole: '',
};

export default EnhancedTable;
