import { Box } from '@material-ui/core';
import React from 'react';
import Checked from '../../../../../assets/checked.svg';
import { Grid, Typography } from '../../../../../components';
import { IconCircle } from './styles';

const ApproveSkeleton = () => {
  return (
    <Grid container justify="center" alignItems="flex-start">
      <Grid item lg={12}>
        <Box display="flex" justifyContent="flex-start">
          <IconCircle>
            <img src={Checked} alt="" />
          </IconCircle>
          <Typography
            fontSize="22px"
            color="primary"
            fontWeight="bold"
            ml="10px"
          >
            Aprovado!
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={12}>
        <Typography
          fontSize="16px"
          fontWeight="500"
          color="secondary"
          mt="28px"
          mb="10px"
        >
          Tudo Pronto!
        </Typography>
        <Typography fontSize="16px" color="secondary" mb="10px">
          A partir de agora você já faz parte do nosso time!
          <br />
          Você será redirecionado para o seu acesso, mas antes precisamos que
          você realize os exames admissionais, assine seu contrato de admissão e
          crie sua senha de acesso.
        </Typography>
      </Grid>
      {/* <Grid item lg={12}>
        <Box component={Grid} container justifyContent="flex-end" pt="10px">
          <StyledLink to="/admission-exam">
            <StyledButton>VAMOS LÁ</StyledButton>
          </StyledLink>
        </Box>
      </Grid> */}
    </Grid>
  );
};

export default ApproveSkeleton;
