/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FileDoc from '../../assets/file-doc.svg';
import Typography from '../Typography';
import { ContainerDropzone, UploaderContainer } from './styles';

const Uploader = ({
  title,
  defaultImage,
  accept,
  dropText,
  disabled = false,
  onFileUploaded,
  size,
  hasStepPhoto,
}) => {
  const [fileSelected, setFileSelected] = useState('');

  const onDrop = useCallback(
    acceptedFiles => {
      const file = acceptedFiles[0];
      const fileUrl = URL.createObjectURL(file);

      onFileUploaded(file);
      if (accept.includes('image')) {
        setFileSelected(fileUrl);
      } else {
        setFileSelected(file.name);
      }
    },
    [accept, onFileUploaded],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple: false,
    disabled,
  });

  return (
    <UploaderContainer size={size} disabled={disabled}>
      <span>{title}</span>
      <ContainerDropzone
        {...getRootProps()}
        img={fileSelected}
        disabled={disabled}
        size={size}
      >
        <input {...getInputProps()} />
        {fileSelected ? (
          <>
            {accept.includes('image') ? (
              <img src={fileSelected} alt="" />
            ) : (
              <p style={{ fontSize: '10px' }}>{fileSelected}</p>
            )}
          </>
        ) : (
          <>
            <img width="25px" src={defaultImage} alt="" />
            {hasStepPhoto ? (
              ''
            ) : (
              <span>
                <Typography color="primary" fontSize="13px" fontWeight="500">
                  {dropText}
                </Typography>
              </span>
            )}
          </>
        )}
      </ContainerDropzone>
    </UploaderContainer>
  );
};

Uploader.propTypes = {
  title: PropTypes.string,
  defaultImage: PropTypes.string,
  accept: PropTypes.string.isRequired,
  dropText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onFileUploaded: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  hasStepPhoto: PropTypes.bool,
};

Uploader.defaultProps = {
  title: '',
  defaultImage: FileDoc,
  disabled: false,
  size: 'sm',
  hasStepPhoto: false,
};

export default Uploader;
