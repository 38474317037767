const formatTimeString = totalMinutes => {
  if (totalMinutes >= 0) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0',
    )}`;
  }
  const absoluteTotalMinutes = Math.abs(totalMinutes);
  const hours = Math.floor(absoluteTotalMinutes / 60);
  const minutes = absoluteTotalMinutes % 60;
  return `-${String(hours).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0',
  )}`;
};

export default formatTimeString;
