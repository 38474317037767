import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../services/api';
import { useAuth } from './auth';

export default function useUserAdmission(step) {
  const [userInfo, setUserInfo] = useState(null);
  const [userStep, setUserStep] = useState(null);

  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    async function getUserData() {
      try {
        const api = AuthenticatedHttpClient();

        const { data } = await api.get(`users/${user._id}`);

        const { fullName, email, phone, isWhatsApp } = data;

        setUserInfo({
          fullName,
          phone,
          email,
          isWhatsApp: isWhatsApp ? String(isWhatsApp) : '',
        });

        if (data[step]) {
          delete data[step].createdAt;
          delete data[step].updatedAt;

          setUserStep(data[step]);
        }
      } catch (error) {
        dispatch(setSnackbar(true, 'error', 'Falha ao buscar informações'));
      }
    }

    getUserData();
  }, [dispatch, step, user]);

  return { userInfo, userStep };
}
