import styled from 'styled-components';
import { Tabs, Tab, IconButton } from '@material-ui/core';

export const Container = styled.div`
  padding: 24px;

  .MuiPaper-root {
    margin-top: 24px;
  }
`;

export const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid #e5e5e5;
`;

export const StyledTab = styled(Tab)`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const AdmissionContainer = styled.section`
  padding: 24px;
`;

export const StyledIconButton = styled(IconButton)`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 16px;
  transition: opacity 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.8;
  }

  svg {
    color: #fff;
    width: 35px;
    height: 35px;
  }
`;

export const SelectDiv = styled.div`
  width: 100%;
  margin: 24px 0;
  display: flex;
  align-items: center;

  .MuiFormControl-root {
    & + .MuiFormControl-root {
      margin-left: 24px;
    }
  }
`;
