import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
} from '@material-ui/core';

import { StyledIconButtonBlue, StyledTableCell } from './styles';

import Dollar from '../../../assets/dollar.svg';

function createData(name, workedhours, plannedHours, missingHours, extraHours) {
  return {
    name,
    workedhours,
    plannedHours,
    missingHours,
    extraHours,
  };
}

const rows = [
  createData('MICHAEL BROWN', '168:00', '160:00', '00:00', '08:00'),
  createData('MICHAEL RED', '168:00', '160:00', '00:00', '08:00'),
  createData('MICHAEL WHITE', '168:00', '160:00', '00:00', '08:00'),
  createData('MICHAEL PINK', '168:00', '160:00', '00:00', '08:00'),
  createData('MICHAEL BLUE', '168:00', '160:00', '00:00', '08:00'),
];

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'workedhours',
    numeric: false,
    disablePadding: false,
    label: 'Horas trabalhadas',
  },
  {
    id: 'plannedHours',
    numeric: false,
    disablePadding: false,
    label: 'Horas planejadas',
  },
  {
    id: 'missingHours',
    numeric: false,
    disablePadding: false,
    label: 'Horas faltantes',
  },
  {
    id: 'extraHours',
    numeric: false,
    disablePadding: false,
    label: 'Horas extras',
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Ação</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTable = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box component={Paper} elevation={0} overflow="visible" mt={2}>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow key={row.name}>
                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.workedhours}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.plannedHours}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.missingHours}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.extraHours}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <StyledIconButtonBlue>
                        <img src={Dollar} alt="" />
                      </StyledIconButtonBlue>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default EnhancedTable;
