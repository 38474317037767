import {
  Box,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Radio,
} from '@material-ui/core';
import { format, formatISO, parse, parseISO } from 'date-fns';
import { Field, Formik } from 'formik';
import { RadioGroup, TextField } from 'formik-material-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import plusIcon from '../../../../assets/plus.svg';
import trashIcon from '../../../../assets/trash-red.svg';
import {
  CollaboratorButton,
  Typography,
  UserInfo,
} from '../../../../components';
import withCollaboratorSkeleton from '../../../../components/CollaboratorSkeleton';
import { useAuth } from '../../../../hooks/auth';
import useUser from '../../../../hooks/useUser';
import { setLoading } from '../../../../redux/ducks/loading';
import { setSnackbar } from '../../../../redux/ducks/snackbar';
import { AuthenticatedHttpClient } from '../../../../services/api';
import checkCpfValidation from '../../../../utils/checkCpfValidation';
import formatCpf from '../../../../utils/formatCpf';
import familyDataSchema from '../../../../validations/familyDataSchema';
import AddDependentModal from './AddDependent';
import {
  ContainerInput,
  DependentButton,
  DependentContainer,
  StyledForm,
} from './styles';

const StepTwo = () => {
  const [openModal, setOpenModal] = useState(false);
  const [whatsApp, setWhatsApp] = useState('');
  const [familyData, setFamilyData] = useState({
    maritalStatus: '',
    mothersName: '',
    fathersName: '',
    hasDependents: '',
  });
  const [dependents, setDependents] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useAuth();
  const { userInfo, user: fullUser } = useUser(user._id);

  useEffect(() => {
    if (fullUser && fullUser.familyData) {
      const {
        familyData: {
          maritalStatus,
          mothersName,
          fathersName,
          hasDependents,
          dependents: userDependents,
        },
      } = fullUser;

      setFamilyData({
        maritalStatus,
        mothersName,
        fathersName,
        hasDependents: String(hasDependents),
      });

      if (userDependents.length) {
        const formatedDependents = userDependents.map(dependent => {
          const parsedDate = parseISO(dependent.dependentbirthDate);

          return {
            ...dependent,
            dependentbirthDate: format(parsedDate, 'dd/MM/yyyy'),
          };
        });

        setDependents(formatedDependents);
      }
    }
  }, [fullUser]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleRemoveDependent = useCallback(
    cpf => {
      const newDependents = dependents.filter(
        dependent => dependent.dependentCpf !== cpf,
      );
      setDependents(newDependents);
    },
    [dependents],
  );

  const handleSubmitGeneralRecord = useCallback(
    async (values, { setSubmitting }) => {
      try {
        dispatch(setLoading(true));
        const api = AuthenticatedHttpClient();

        const formObject = {
          ...values,
          hasDependents: JSON.parse(values.hasDependents),
        };

        if (whatsApp) {
          formObject.isWhatsApp = JSON.parse(whatsApp);
        }

        if (JSON.parse(values.hasDependents)) {
          if (dependents.length === 0) {
            dispatch(setSnackbar(true, 'error', 'Adicione os dependentes'));
            return;
          }
          const formatedDependents = dependents.map(dependent => {
            const parsedDate = parse(
              dependent.dependentbirthDate,
              'dd/MM/yyyy',
              new Date(),
            );

            return {
              ...dependent,
              dependentbirthDate: formatISO(parsedDate),
            };
          });

          if (!checkCpfValidation(formatedDependents, fullUser.documents.cpf)) {
            dispatch(
              setSnackbar(
                true,
                'error',
                'O colaborador possui o mesmo CPF de um dos dependentes',
              ),
            );
            return;
          }

          formObject.dependents = JSON.stringify(formatedDependents);
        } else {
          formObject.dependents = JSON.stringify([]);
        }

        await api.put(`users/step/family-data/${user._id}`, formObject);

        dispatch(setSnackbar(true, 'success', 'Dados salvos com sucesso'));
        history.push(`/3`);
      } catch (error) {
        if (error.response) {
          const {
            data: { message },
          } = error.response;
          dispatch(setSnackbar(true, 'error', message));
        } else {
          dispatch(
            setSnackbar(true, 'error', 'Não foi possível salvar os dados'),
          );
        }
      } finally {
        dispatch(setLoading(false));
        setSubmitting(false);
      }
    },
    [history, user, dispatch, whatsApp, dependents],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      width={1}
      overflow="auto"
      p={5}
    >
      {userInfo && <UserInfo userData={userInfo} setValue={setWhatsApp} />}
      <Formik
        initialValues={familyData}
        onSubmit={handleSubmitGeneralRecord}
        validationSchema={familyDataSchema}
        enableReinitialize
      >
        {({ isSubmitting, errors, values, touched }) => (
          <StyledForm>
            <Box width={2 / 3}>
              <Typography
                component="legend"
                fontWeight={300}
                fontSize="18px"
                mb="20px"
              >
                Estado civil e Filiação
              </Typography>
              <ContainerInput item lg={6}>
                <Field
                  component={TextField}
                  name="maritalStatus"
                  label="Estado civil"
                  select
                  variant="filled"
                  fullWidth
                  required
                >
                  <MenuItem value="Solteiro(a)">Solteiro(a)</MenuItem>
                  <MenuItem value="Casado(a)">Casado(a)</MenuItem>
                  <MenuItem value="Viúvo(a)">Viúvo(a)</MenuItem>
                </Field>
              </ContainerInput>
              <ContainerInput item>
                <Field
                  component={TextField}
                  name="mothersName"
                  label="Nome da mãe"
                  variant="filled"
                  fullWidth
                  required
                />
              </ContainerInput>
              <ContainerInput item>
                <Field
                  component={TextField}
                  name="fathersName"
                  label="Nome do pai"
                  variant="filled"
                  fullWidth
                />
              </ContainerInput>
              <Typography
                component="legend"
                fontWeight={300}
                fontSize="18px"
                mb="20px"
              >
                Dependentes
              </Typography>
              <ContainerInput item>
                <FormControl error={!!errors.hasDependents} required>
                  <FormLabel style={{ fontSize: '14px' }}>
                    Possui dependentes?
                  </FormLabel>
                  <Field component={RadioGroup} name="hasDependents" row>
                    <FormControlLabel
                      value="true"
                      control={<Radio disabled={isSubmitting} />}
                      label="SIM"
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio disabled={isSubmitting} />}
                      label="NÃO"
                      disabled={isSubmitting}
                    />
                  </Field>
                  <FormHelperText>
                    {touched.hasDependents && errors.hasDependents}
                  </FormHelperText>
                </FormControl>
              </ContainerInput>
              {values.hasDependents === 'true' && (
                <>
                  {dependents.map(dependent => (
                    <DependentContainer key={dependent.dependentCpf}>
                      <Box display="flex" flexDirection="column">
                        <Typography opacity="0.5" fontSize="14px">
                          {dependent.dependentName}
                        </Typography>
                        <Box display="flex" flexDirection="row">
                          <Typography
                            opacity="0.5"
                            fontSize="12px"
                            fontWeight={300}
                            mr="16px"
                          >
                            Tipo: {dependent.dependentType}
                          </Typography>
                          <Typography
                            opacity="0.5"
                            fontSize="12px"
                            fontWeight={300}
                            mr="16px"
                          >
                            Data de nascimento: {dependent.dependentbirthDate}
                          </Typography>
                          <Typography
                            opacity="0.5"
                            fontSize="12px"
                            fontWeight={300}
                            mr="16px"
                          >
                            CPF: {formatCpf(dependent.dependentCpf)}
                          </Typography>
                        </Box>
                      </Box>
                      <IconButton
                        onClick={() =>
                          handleRemoveDependent(dependent.dependentCpf)
                        }
                      >
                        <img src={trashIcon} alt="" />
                      </IconButton>
                    </DependentContainer>
                  ))}

                  <DependentButton onClick={() => setOpenModal(true)}>
                    <img src={plusIcon} alt="" />
                    Adicionar dependente
                  </DependentButton>
                </>
              )}
            </Box>
            <Box width={1} display="flex" justifyContent="flex-end" my={3}>
              <CollaboratorButton disabled={isSubmitting} type="submit" />
            </Box>
          </StyledForm>
        )}
      </Formik>
      <Modal open={openModal} onClose={handleCloseModal}>
        <DialogContent>
          <AddDependentModal
            handleClickOnClose={handleCloseModal}
            setNewDependent={setDependents}
          />
        </DialogContent>
      </Modal>
    </Box>
  );
};

export default withCollaboratorSkeleton(StepTwo);
