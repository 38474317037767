import React from 'react';
import { format } from 'date-fns';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Downloader, Grid, Typography } from '../../../../components';
import { StyledGrid } from '../styles';

const RequestDayOffInfo = ({ values, reasonLabel }) => {
  const { date, reason, solicitationDate, justification, attachment } = values;

  const formatedSolicitationDate = format(
    new Date(solicitationDate),
    'dd/MM/yyyy',
  );
  return (
    <>
      <StyledGrid item xs={6}>
        <TextField
          name="date"
          label="Data da solicitação"
          variant="filled"
          value={date}
          disabled
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item xs={6}>
        <TextField
          name="reason"
          label="Motivo"
          variant="filled"
          value={reasonLabel[reason]}
          disabled
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item xs={6}>
        <TextField
          name="solicitationDate"
          label="Data do pedido de folga"
          variant="filled"
          value={formatedSolicitationDate}
          disabled
          fullWidth
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <TextField
          name="justification"
          label="Justificativa"
          variant="filled"
          disabled
          value={justification}
          fullWidth
        />
      </StyledGrid>
      {attachment && (
        <Grid item xs={6}>
          <Typography opacity={0.5} fontSize="14px" mb="10px">
            Anexo
          </Typography>
          <Downloader
            file={{
              key: attachment.key,
              name: attachment.name,
              size: attachment.size,
            }}
          />
        </Grid>
      )}
    </>
  );
};

RequestDayOffInfo.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  reasonLabel: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default RequestDayOffInfo;
