import styled from 'styled-components';
import { Grid, ButtonBase } from '@material-ui/core';
import { Form } from 'formik';

export const ContainerInput = styled(Grid)`
  height: 80px;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : 0)};

  input {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const DependentButton = styled(ButtonBase)`
  display: flex;
  align-items: center;
  padding: 25px;
  justify-content: flex-start;
  margin-top: 8px;
  width: 100%;

  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  outline: none;
  border: 0;
  border-radius: 6px;

  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.main};

  img {
    margin-right: 16px;
  }
`;

export const DependentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 25px;

  & + div {
    margin-top: 8px;
  }

  border: 1px solid #e6e6e6;
  border-radius: 6px;
`;
